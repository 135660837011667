
import Vue from 'vue';

export default Vue.observable({
    allfens: [
{"gid": 0, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2P5/1P3NP1/P2PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2p5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/Q1p5/5NP1/PP1PPPBP/RNB1K2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1p5/5NP1/PP1PPPBP/RNB1K2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/2Q5/5NP1/PP1PPPBP/RNB1K2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"r1bqkb1r/1ppn1ppp/p3pn2/8/2Q5/5NP1/PP1PPPBP/RNB1K2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2p5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/Q1p5/5NP1/PP1PPPBP/RNB1K2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1p5/5NP1/PP1PPPBP/RNB1K2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/2Q5/5NP1/PP1PPPBP/RNB1K2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"r1bqkb1r/pp1n1ppp/4pn2/2p5/2Q5/5NP1/PP1PPPBP/RNB1K2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2p5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/Q1p5/5NP1/PP1PPPBP/RNB1K2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1p5/5NP1/PP1PPPBP/RNB1K2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1p5/5NP1/PP1PPPBP/RNB2RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2P5/5NP1/PPQPPP1P/RNB1KB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqk2r/pp2bppp/4pn2/2pp4/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqk2r/pp2bppp/2p1pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2P5/1P3NP1/P2PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbq1rk1/pp2bppp/4pn2/2pp4/2P5/1P3NP1/P2PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbq1rk1/pp2bppp/4pn2/2pp4/2P5/1P3NP1/PB1PPPBP/RN1Q1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"r1bq1rk1/pp2bppp/2n1pn2/2pp4/2P5/1P3NP1/PB1PPPBP/RN1Q1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"r1bq1rk1/pp2bppp/2n1pn2/2pp4/2P5/1P2PNP1/PB1P1PBP/RN1Q1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2P5/1P3NP1/P2PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbq1rk1/p1p1bppp/1p2pn2/3p4/2P5/1P3NP1/P2PPPBP/RNBQ1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbq1rk1/p1p1bppp/1p2pn2/3p4/2P5/1P3NP1/PB1PPPBP/RN1Q1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/2P5/1P3NP1/PB1PPPBP/RN1Q1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/2P5/1P2PNP1/PB1P1PBP/RN1Q1RK1", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/1P3N2/PB1PPPPP/RN1QKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2P5/1P3N2/PB1PPPPP/RN1QKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2P5/1P2PN2/PB1P1PPP/RN1QKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 0, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2P5/1P2PN2/PB1P1PPP/RN1QKB1R", "treename":"English Opening, Agincourt Defense"},
{"gid": 1, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/pp1p2pp/2p1pn2/5p2/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/pp1p2pp/2p1pn2/5p2/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/pp4pp/2p1pn2/3p1p2/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 1, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Anglo-Dutch Defense"},
{"gid": 2, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/8/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqk2r/ppp2pbp/6p1/3np3/8/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 2, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/Q1P5/2N2N2/PP1PPPPP/R1B1KB1R", "treename":"English Opening, Anglo-Grunfeld Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/8/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/8/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/1n4p1/8/8/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/4P3/2N2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/4P3/2n2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/4P3/2P2N2/PP3PPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnb1kb1r/ppp1pp1p/6p1/8/4P3/2P2N2/PP3PPP/R1BqKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnb1kb1r/ppp1pp1p/6p1/8/4P3/2P2N2/PP3PPP/R1BK1B1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/Q7/2N2N2/PP1PPPPP/R1B1KB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/8/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/8/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/8/2n3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/8/2P3P1/P2PPPBP/R1BQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/ppp1ppbp/6p1/8/8/2P3P1/P2PPPBP/R1BQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/ppp1ppbp/6p1/8/8/2P3P1/P2PPPBP/1RBQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p1P3/2P5/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p1P3/2P5/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p1P3/2P5/2N2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/2p1P3/2P5/2N2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/2p1P3/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/4P3/2Pp4/2N2N2/PP3PPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/4P3/2PN4/2N5/PP3PPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"r1bqkbnr/pp1p1ppp/4p3/4n3/2PN4/2N5/PP3PPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p1P3/2P5/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p1P3/2P5/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p1P3/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppp1p/5np1/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppp1p/5np1/8/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/ppppppbp/5np1/8/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/ppppppbp/5np1/8/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbq1rk1/ppppppbp/5np1/8/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bP5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bP5/2N2N2/PPQPPPPP/R1B1KB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bP5/2N2N2/PPQPPPPP/R1B1KB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bP5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bP5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2P5/2N2NP1/PP1PPPBP/R1BQR1K1", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2P1P3/2N2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2P1P3/2N2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2P1P3/2NB1N2/PP1P1PPP/R1BQK2R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pp1ppppp/2p2n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pp1ppppp/2p2n2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/4p3/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pp1ppppp/2p2n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pp1ppppp/2p2n2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppp1p/5np1/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppp1p/5np1/8/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/ppppppbp/5np1/8/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/ppppppbp/5np1/8/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbq1rk1/ppppppbp/5np1/8/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbq1rk1/ppppppbp/5np1/8/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppp1p/5np1/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppp1p/5np1/8/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/ppppppbp/5np1/8/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/ppppppbp/5np1/8/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbq1rk1/ppppppbp/5np1/8/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbq1rk1/ppppppbp/5np1/8/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppp1p/5np1/8/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqkb1r/pppppp1p/5np1/8/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 3, "fen":"rnbqk2r/ppppppbp/5np1/8/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, Anglo-Indian Defense"},
{"gid": 5, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Carls-Bremen System"},
{"gid": 5, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Carls-Bremen System"},
{"gid": 5, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Carls-Bremen System"},
{"gid": 5, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Carls-Bremen System"},
{"gid": 5, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Carls-Bremen System"},
{"gid": 5, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Carls-Bremen System"},
{"gid": 6, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/2P3b1/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/2P3b1/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkb1r/pp1ppppp/2p2n2/8/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkb1r/pp1ppppp/2p2n2/8/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2P5/3P1NP1/PP2PPBP/RNBQ1RK1", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkb1r/pp1ppppp/2p2n2/8/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkb1r/pp1ppppp/2p2n2/8/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2P3b1/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2P3b1/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/4PN2/PP1P1PPP/RNBQKB1R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/4PN2/PP1P1PPP/RNBQKB1R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 6, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/2N1PN2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Caro-Kann Defensive System"},
{"gid": 7, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Closed"},
{"gid": 7, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Closed"},
{"gid": 7, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Closed"},
{"gid": 7, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Closed"},
{"gid": 7, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Closed"},
{"gid": 7, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Closed"},
{"gid": 7, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Closed"},
{"gid": 7, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Closed"},
{"gid": 7, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Closed"},
{"gid": 7, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Closed"},
{"gid": 7, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2N1P1P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, Closed"},
{"gid": 7, "fen":"r1bqk2r/ppp2pbp/2np2pn/4p3/2P5/2N1P1P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, Closed"},
{"gid": 8, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Four Knights System"},
{"gid": 8, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Four Knights System"},
{"gid": 8, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Four Knights System"},
{"gid": 8, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Four Knights System"},
{"gid": 8, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Four Knights System"},
{"gid": 8, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Four Knights System"},
{"gid": 8, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P1P3/2N2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Four Knights System"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/8/2P1p3/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/6N1/2P1p3/2N5/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/p1pp1ppp/5n2/1p4N1/2P1p3/2N5/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P1P3/2NP2P1/PP3PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp2pbp/2np1np1/4p3/2P1P3/2NP2P1/PP3PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp2pbp/2np1np1/4p3/2P1P3/2NP1NP1/PP3PBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2P1P3/2NP1NP1/PP3PBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2P1P3/2NP1NP1/PP3PBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P1P3/2NP2P1/PP3PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp1npbp/2np2p1/4p3/2P1P3/2NP2P1/PP3PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp1npbp/2np2p1/4p3/2P1P3/2NP2P1/PP2NPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P1P3/2NP2P1/PP3PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp2pbp/2np1np1/4p3/2P1P3/2NP2P1/PP3PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp2pbp/2np1np1/4p3/2P1P3/2NP2P1/PP2NPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2P1P3/2NP2P1/PP2NPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2P1P3/2NP2P1/PP2NPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2N1P1P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r2qk1nr/ppp2pbp/2npb1p1/4p3/2P5/2N1P1P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r2qk1nr/ppp2pbp/2npb1p1/4p3/2P5/2NPP1P1/PP2NPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP1NP1/PP2PPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp2pbp/2np1np1/4p3/2P5/2NP1NP1/PP2PPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp2pbp/2np1np1/4p3/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2P5/2NP1NP1/PP2PPBP/1RBQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/1pp2pbp/2np1np1/p3p3/2P5/2NP1NP1/PP2PPBP/1RBQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/1pp2pbp/2np1np1/p3p3/2P5/P1NP1NP1/1P2PPBP/1RBQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/1pp2pb1/2np1npp/p3p3/2P5/P1NP1NP1/1P2PPBP/1RBQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP2P1/PP2PPBP/1RBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp3bp/2np2p1/4pp2/2P5/2NP2P1/PP2PPBP/1RBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP2P1/PP2PPBP/1RBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/1pp2pbp/2np2p1/p3p3/2P5/2NP2P1/PP2PPBP/1RBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NPP1P1/PP3PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppppnpbp/2n3p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2PPp3/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp3/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n5/3np3/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n5/3np3/8/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r2qkb1r/ppp2ppp/2n1b3/3np3/8/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp3/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n5/3np3/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n5/3np3/8/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/1nn5/4p3/8/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/1nn5/4p3/8/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp1bppp/1nn5/4p3/8/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp1bppp/1nn5/4p3/8/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/ppp1bppp/1nn5/4p3/8/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bP5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bP5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/pppp1ppp/2n2n2/4p3/1bP5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/pppp1ppp/2n2n2/4p3/1bP5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/pppp1ppp/2n2n2/8/1bP1p3/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/ppp2ppp/2np1n2/2b1p3/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/ppp2ppp/2np1n2/2b1p3/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bP5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/3Np3/1bP5/5NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp3/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n5/3np3/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n5/3np3/8/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/1nn5/4p3/8/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/1nn5/4p3/8/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp1bppp/1nn5/4p3/8/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp1bppp/1nn5/4p3/8/P1N2NP1/1P1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp3/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n5/3np3/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/2n5/3np3/8/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/1nn5/4p3/8/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/ppp2ppp/1nn5/4p3/8/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp1bppp/1nn5/4p3/8/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/ppp1bppp/1nn5/4p3/8/2N2NP1/PP1PPPBP/1RBQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1p1p/2n2np1/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1p1p/2n2np1/4p3/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2NP1N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/P1N2N2/1P1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N1PN2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bP5/2N1PN2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bP5/2N1PN2/PPQP1PPP/R1B1KB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/2P5/2b1PN2/PPQP1PPP/R1B1KB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N1PN2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bP5/2N1PN2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bP5/2N1PN2/PPQP1PPP/R1B1KB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/pppp1ppp/2n2n2/4p3/1bP5/2N1PN2/PPQP1PPP/R1B1KB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bq1rk1/pppp1ppp/2n2n2/3Np3/1bP5/4PN2/PPQP1PPP/R1B1KB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqr1k1/pppp1ppp/2n2n2/3Np3/1bP5/4PN2/PPQP1PPP/R1B1KB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqr1k1/pppp1ppp/2n2n2/3NpQ2/1bP5/4PN2/PP1P1PPP/R1B1KB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2Pp4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/8/1bPN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/6B1/1bPN4/2N5/PP2PPPP/R2QKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1pp1/2n2n1p/6B1/1bPN4/2N5/PP2PPPP/R2QKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1pp1/2n2n1p/8/1bPN3B/2N5/PP2PPPP/R2QKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1pp1/2n2n1p/8/2PN3B/2b5/PP2PPPP/R2QKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1pp1/2n2n1p/8/2PN3B/2P5/P3PPPP/R2QKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1pp1/5n1p/4n3/2PN3B/2P5/P3PPPP/R2QKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/1RBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/1ppp1pbp/2n3p1/p3p3/2P5/2N3P1/PP1PPPBP/1RBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/4p3/1bP5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/3Np3/1bP5/8/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/b2Np3/2P5/8/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/4p3/1bP5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/3Np3/1bP5/8/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/2bNp3/2P5/8/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/4p3/1bP5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/3Np3/1bP5/8/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/1ppp1ppp/8/p2Np3/1bP5/8/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/4p3/1bP5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/4p3/1bP5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/4p3/2P5/2b3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/4p3/2P5/2P3P1/P2PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/4p3/1bP5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1ppp/8/3Np3/1bP5/8/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/ppppbppp/8/3Np3/2P5/8/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/ppppbppp/8/3Np3/2PP4/8/PP2PPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp2pp/2n5/4pp2/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp2pp/2n5/4pp2/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp2pp/2n2n2/4pp2/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp2pp/2n2n2/4pp2/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp3p/2n2np1/4pp2/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N1P3/PP1P1PPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N1P3/PP1P1PPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bP5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/3Np3/1bP5/6P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp2pp/2n5/4pp2/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp2pp/2n5/4pp2/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp2pp/2n2n2/4pp2/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp2pp/2n2n2/4pp2/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1p1p/6p1/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1p1p/6p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1pbp/6p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1pbp/6p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp2pp/8/4pp2/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp2pp/8/4pp2/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp2pp/5n2/4pp2/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp2pp/5n2/4pp2/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rn1qkbnr/ppp2ppp/3p4/4p3/2P3b1/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1pbp/2n3p1/4p3/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/ppp2ppp/2np4/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp2pp/2n5/4pp2/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp2pp/2n5/4pp2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp2pp/2n5/5p2/2PPp3/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqk1nr/pppp1ppp/2n5/4p3/1bP5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/8/2Pp4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1p1p/2n3p1/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/ppp2ppp/2np4/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkbnr/ppp2ppp/2np4/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r2qkbnr/ppp2ppp/2npb3/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r2qkbnr/ppp2ppp/2npb3/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1pbp/5np1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1pbp/5np1/4p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1pbp/5np1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1pbp/5np1/4p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2P5/2NP1NP1/PP2PPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2P5/2NP1NP1/PP2PPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbq1rk1/pp3pbp/2pp1np1/4p3/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1pbp/5np1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1pbp/5np1/4p3/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2P5/2N1P1P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2P5/2N1P1P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1pbp/5np1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1pbp/5np1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1pbp/5np1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1pbp/5np1/4p3/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2P1P3/2N3P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2P1P3/2N3P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2P1P3/2NP2P1/PP2NPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/8/2P1p3/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp3ppp/2p2n2/3pp3/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp3ppp/2p2n2/3Pp3/8/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp3ppp/5n2/3pp3/8/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp3ppp/2pp1n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp3ppp/2pp1n2/4p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/ppp2ppp/5n2/3Pp3/8/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/ppp2ppp/8/3np3/8/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/ppp2ppp/8/3np3/8/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/ppp2ppp/1n6/4p3/8/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1ppp/5n2/4p3/1bP5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1ppp/5n2/4p3/1bP5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1ppp/5n2/4p3/2P5/2b3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1ppp/5n2/4p3/1bP5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1ppp/5n2/4p3/1bP5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbq1rk1/pppp1ppp/5n2/4p3/1bP5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbq1rk1/pppp1ppp/5n2/4p3/1bP1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N1P3/PP1P1PPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1ppp/5n2/4p3/1bP5/2N1P3/PP1P1PPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2p1p/3p2p1/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/4pp2/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/4pp2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/5p2/2PPp3/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/5pN1/2PPp3/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pp4pp/2pp4/5pN1/2PPp3/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/4pp2/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/4pp2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/5p2/2PPp3/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/5pN1/2PPp3/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/ppp3pp/3p1n2/5pN1/2PPp3/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/4pp2/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/4pp2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/5p2/2PPp3/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/5pN1/2PPp3/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/ppp1b1pp/3p4/5pN1/2PPp3/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1p1p/6p1/4p3/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1p1p/6p1/4p3/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk1nr/pppp1pbp/6p1/4p3/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/6P1/PP1PPP1P/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/4p3/2P5/6P1/PP1PPPBP/RNBQK1NR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/4pp2/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/4pp2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/5p2/2PPp3/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkbnr/ppp3pp/3p4/5p2/2PPp3/2N5/PP1NPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/ppp3pp/3p1n2/5p2/2PPp3/2N5/PP1NPPPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 9, "fen":"rnbqkb1r/ppp3pp/3p1n2/5p2/2PPp3/2N1P3/PP1N1PPP/R1BQKB1R", "treename":"English Opening, King's English Variation"},
{"gid": 11, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/2P5/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4p3/3pP3/2P1n3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/2P5/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/4P3/2Pp4/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pP2/8/2Pp4/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pP2/8/2P5/2p5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pP2/8/2P5/2P5/P2P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnb1kb1r/ppp2ppp/4pq2/8/2P5/2P5/P2P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnb1kb1r/ppp2ppp/4pq2/8/2PP4/2P5/P4PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnb1kb1r/ppp2ppp/5q2/4p3/2PP4/2P5/P4PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/2P5/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/4P3/2Pp4/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pP2/8/2Pp4/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pP2/8/2P5/2p5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pP2/8/2P5/2P5/P2P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnb1kb1r/ppp2ppp/4pq2/8/2P5/2P5/P2P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnb1kb1r/ppp2ppp/4pq2/8/2PP4/2P5/P4PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnb1kb1r/pp3ppp/4pq2/2p5/2PP4/2P5/P4PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/2P5/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/4P3/2Pp4/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pP2/8/2Pp4/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pP2/8/2P5/2p5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pP2/8/2P5/2P5/P2P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnb1kb1r/ppp2ppp/4pq2/8/2P5/2P5/P2P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnb1kb1r/ppp2ppp/4pq2/8/2P5/2P2N2/P2P1PPP/R1BQKB1R", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 11, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/4P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Mikenas-Carls Variation"},
{"gid": 12, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Neo-Catalan"},
{"gid": 12, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Neo-Catalan"},
{"gid": 12, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Neo-Catalan"},
{"gid": 12, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Neo-Catalan"},
{"gid": 12, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Neo-Catalan"},
{"gid": 12, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Neo-Catalan"},
{"gid": 12, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Neo-Catalan"},
{"gid": 12, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Neo-Catalan"},
{"gid": 12, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Neo-Catalan"},
{"gid": 13, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Romanishin Gambit"},
{"gid": 13, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Romanishin Gambit"},
{"gid": 13, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Romanishin Gambit"},
{"gid": 13, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Romanishin Gambit"},
{"gid": 13, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Romanishin Gambit"},
{"gid": 13, "fen":"rnbqkb1r/1ppp1ppp/p3pn2/8/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Romanishin Gambit"},
{"gid": 13, "fen":"rnbqkb1r/1ppp1ppp/p3pn2/8/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Romanishin Gambit"},
{"gid": 13, "fen":"rnbqkb1r/2pp1ppp/p3pn2/1p6/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Romanishin Gambit"},
{"gid": 14, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2PQ4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb2bppp/1p1ppn2/8/2PQ4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb2bppp/1p1ppn2/8/2PQ4/2N2NP1/PP2PPBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/1b2bppp/pp1ppn2/8/2PQ4/2N2NP1/PP2PPBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/1b2bppp/pp1ppn2/8/2PQ4/1PN2NP1/P3PPBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"r2qk2r/1b1nbppp/pp1ppn2/8/2PQ4/1PN2NP1/P3PPBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"r2qk2r/1b1nbppp/pp1ppn2/8/2PQP3/1PN2NP1/P4PBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"r2q1rk1/1b1nbppp/pp1ppn2/8/2PQP3/1PN2NP1/P4PBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2PQ4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb2bppp/1p1ppn2/8/2PQ4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/pb2bppp/1p1ppn2/8/2PQ4/2N2NP1/PP2PPBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/1b2bppp/pp1ppn2/8/2PQ4/2N2NP1/PP2PPBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"rn1qk2r/1b2bppp/pp1ppn2/8/2PQ4/1PN2NP1/P3PPBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"r2qk2r/1b1nbppp/pp1ppn2/8/2PQ4/1PN2NP1/P3PPBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"r2qk2r/1b1nbppp/pp1ppn2/8/2PQP3/1PN2NP1/P4PBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 14, "fen":"r3k2r/1bqnbppp/pp1ppn2/8/2PQP3/1PN2NP1/P4PBP/R1BR2K1", "treename":"English Opening, Symmetrical"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N3P1/PP2PP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1b1kb1r/pp1p1ppp/1qn1pn2/8/2PN4/2N3P1/PP2PP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1b1kb1r/pp1p1ppp/1qn1pn2/8/2P5/1NN3P1/PP2PP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1b1kb1r/pp1p1ppp/1q2pn2/4n3/2P5/1NN3P1/PP2PP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N3P1/PP2PP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/8/1bPN4/2N3P1/PP2PP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/1N6/2P5/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/1N6/1bP5/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N3P1/PP2PP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2b5/2PN4/2N3P1/PP2PP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnb1kb1r/ppqp1ppp/4pn2/8/2PN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/1N6/2P5/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp3ppp/2n1pn2/1N1p4/2P5/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp3ppp/2n1pn2/1N1p4/2P2B2/2N5/PP2PPPP/R2QKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp3ppp/2n2n2/1N1pp3/2P2B2/2N5/PP2PPPP/R2QKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp3ppp/2n2n2/1N1Pp3/5B2/2N5/PP2PPPP/R2QKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp3ppp/2n2n2/1N1P4/5p2/2N5/PP2PPPP/R2QKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp3ppp/2P2n2/1N6/5p2/2N5/PP2PPPP/R2QKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/p4ppp/2p2n2/1N6/5p2/2N5/PP2PPPP/R2QKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bQkb1r/p4ppp/2p2n2/1N6/5p2/2N5/PP2PPPP/R3KB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bk1b1r/p4ppp/2p2n2/1N6/5p2/2N5/PP2PPPP/R3KB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/P1N5/1P2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2b5/2PN4/P1N5/1P2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPN4/2N5/PP1BPPPP/R2QKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/8/1bPN4/2N5/PP1BPPPP/R2QKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/2PN4/2N3P1/PP2PP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/4P3/PP3PPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PN4/6P1/PP2PPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp3ppp/5n2/3pp3/2PN4/6P1/PP2PPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp3ppp/5n2/3pp3/2P5/5NP1/PP2PPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N1P3/PP3PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqk2r/pp1p1ppp/4pn2/1N6/1bP5/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/6B1/2PN4/2N5/PP2PPPP/R2QKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/2PN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/2PN4/6P1/PP2PPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPN4/1QN5/PP2PPPP/R1B1KB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN1B2/2N5/PP2PPPP/R2QKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnb1kb1r/pp1p1ppp/1q2pn2/8/2PN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnb1kb1r/pp1p1ppp/1q2pn2/8/2PN4/6P1/PP2PPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/6P1/PP2PP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/6P1/PP2PPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1ppp1p/2n2np1/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/5n2/4p3/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/5n2/1N2p3/2P5/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppp1p/5np1/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppp1p/5np1/2pP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppp1p/5np1/8/2PN4/8/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppp1p/5np1/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqk2r/pp1pppbp/5np1/8/2PN4/2N5/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqk2r/pp1pppbp/5np1/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppp1p/5np1/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppp1p/5np1/2p5/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp2ppbp/2np1np1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp2ppbp/2np1np1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P1P3/2NP2P1/PP2NPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqnrk1/pp2ppbp/2np2p1/2p5/2P1P3/2NP2P1/PP2NPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp2ppbp/2np1np1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp2ppbp/2np1np1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P1P3/2NP2P1/PP2NPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"1rbq1rk1/pp2ppbp/2np1np1/2p5/2P1P3/2NP2P1/PP2NPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp2ppbp/2np1np1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp2ppbp/2np1np1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P1P3/2NP2P1/PP2NPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/1p2ppbp/p1np1np1/2p5/2P1P3/2NP2P1/PP2NPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P1P3/2N3P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2NP1NP1/PP2PPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pnpbp/2n3p1/2p1p3/2P5/2NP1NP1/PP2PPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/P1N2NP1/1P1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp3pbp/2np2p1/2p1p3/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp3pbp/2np2p1/2p1p3/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp2npbp/2np2p1/2p1p3/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp2npbp/2np2p1/2p1p3/2P5/P1NP1NP1/1P2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pnpbp/2n3p1/2p1p3/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pnpbp/2n3p1/2p1p3/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pnpbp/2n3p1/2p1p3/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pnpbp/2n3p1/2p1p3/2P5/P1NP1NP1/1P2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/1PN2NP1/P2PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2n2np1/2pp4/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/1p1pppbp/p1n2np1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P5/P1NP1NP1/1P2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/1p2ppbp/p1np1np1/2p5/2P5/P1NP1NP1/1P2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P5/2NP1NP1/PP1BPPBP/R2Q1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P5/2NP1NP1/PP2PPBP/1RBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1ppppp/2n2n2/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1ppppp/2n2n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1ppppp/2n2n2/2p5/2P5/2N1PN2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/2p1p3/2P5/2N1PN2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1ppppp/2n2n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1ppppp/2n2n2/2p5/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1ppppp/2n2n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp1ppppp/2n2n2/2p5/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp2pppp/2n2n2/2pp4/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkb1r/pp2pppp/2n2n2/2pP4/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp2ppbp/2np1np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/P1N2NP1/1P1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2P5/P1N2NP1/1P1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2PQ4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r2qk2r/pb1pbppp/1pn1pn2/8/2PQ4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2PQ4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/8/2PQ4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/8/2PQ4/2N2NP1/PP2PPBP/R1BR2K1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r2q1rk1/pb1pbppp/1pn1pn2/8/2PQ4/2N2NP1/PP2PPBP/R1BR2K1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQR1K1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb2bppp/1p2pn2/2pp4/2P5/2N2NP1/PP1PPPBP/R1BQR1K1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2P5/1PN2NP1/P2PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/2p5/2P5/1PN2NP1/P2PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/2p5/2P5/1PN2NP1/PB1PPPBP/R2Q1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1q1rk1/pb2bppp/1p1ppn2/2p5/2P5/1PN2NP1/PB1PPPBP/R2Q1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2PQ4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb2bppp/1p1ppn2/8/2PQ4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb2bppp/1p1ppn2/8/2PQ4/1PN2NP1/P3PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r2qk2r/pb1nbppp/1p1ppn2/8/2PQ4/1PN2NP1/P3PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r2qk2r/pb1nbppp/1p1ppn2/1N6/2PQ4/1P3NP1/P3PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PN4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PN4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PN4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n3p1/8/2PN2n1/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n3p1/8/2PN2n1/2N1P1P1/PP3PBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/2np2p1/8/2PN2n1/2N1P1P1/PP3PBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PN4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/5np1/8/2Pn4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/5np1/8/2PQ4/2N3P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2PQ4/2N3P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2P5/2NQ2P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"1rbq1rk1/pp2ppbp/3p1np1/8/2P5/2NQ2P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PN4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/5np1/8/2Pn4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/5np1/8/2PQ4/2N3P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2PQ4/2N3P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2P5/2NQ2P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r2q1rk1/pp2ppbp/3p1np1/5b2/2P5/2NQ2P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PN4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1b2rk1/pp1pppbp/2n2np1/q7/2PN4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PN4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/5np1/8/2Pn4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/5np1/8/2PQ4/2N3P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2PQ4/2N3P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2P5/2N3P1/PP1QPPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PN4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/5np1/8/2Pn4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/5np1/8/2PQ4/2N3P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2PQ4/2N3P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2P5/2NQ2P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/1p2ppbp/p2p1np1/8/2P5/2NQ2P1/PP2PPBP/R1B2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/1p2ppbp/p2p1np1/8/2P5/2NQ2P1/PP1BPPBP/R4RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"1rbq1rk1/1p2ppbp/p2p1np1/8/2P5/2NQ2P1/PP1BPPBP/R4RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"1rbq1rk1/1p2ppbp/p2p1np1/8/2P5/2NQ2P1/PP1BPPBP/2R2RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PN4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1b2rk1/pp1pppbp/1qn2np1/8/2PN4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1b2rk1/pp1pppbp/1qn2np1/8/2P5/2N3P1/PPN1PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppp1p/5np1/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/p2ppppp/1p6/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/p2ppppp/1p6/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkbnr/pb1ppppp/1p6/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2P5/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/8/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/8/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/8/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/ppn1pppp/8/2p5/8/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P5/2N1P1P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pnpbp/2n1p1p1/2p5/2P5/2N1P1P1/PP1PNPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/P1N3P1/1P1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/2P5/P1N3P1/1P1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/P1N3P1/1P1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P5/P1N3P1/1P1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2NP2P1/PP2PPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/1PN3P1/P2PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/3n4/3p4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2p5/3P4/2n2N2/PP2PPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/4P3/2N2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2p5/1n2P3/2N2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/1Bp5/1n2P3/2N2N2/PP1P1PPP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/8/2N2NP1/PP1PPP1P/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/4P3/2N2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2p5/1n2P3/2N2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2p5/1nB1P3/2N2N2/PP1P1PPP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/8/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2pn4/4P3/2N2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2p5/4P3/2n2N2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp2pppp/8/2p5/4P3/2P2N2/PP3PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnb1kb1r/pp2pppp/8/2p5/4P3/2P2N2/PP3PPP/R1BqKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnb1kb1r/pp2pppp/8/2p5/4P3/2P2N2/PP3PPP/R1BK1B1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/1p1pppbp/p1n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp2ppbp/2np2pn/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pnpbp/2n1p1p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pnpbp/2n1p1p1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pnpbp/2n1p1p1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bq1rk1/pp1pnpbp/2n1p1p1/2p5/2P5/2NP1NP1/PP1BPPBP/R2Q1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n3pn/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pppbp/2n3pn/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P5/2N1PNP1/PP1P1PBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pnpbp/2n1p1p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk2r/pp1pnpbp/2n1p1p1/2p5/2P5/2N1PNP1/PP1P1PBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P5/2N1PN2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N1PN2/PP1P1PPP/R1BQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppp1p/1p3np1/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppp1p/1p3np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pppbp/1p3np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/1b1p1ppp/pp2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppp1p/1p3np1/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppp1p/1p3np1/2p5/2P5/1P3NP1/P2PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pppbp/1p3np1/2p5/2P5/1P3NP1/P2PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qk2r/pb1pppbp/1p3np1/2p5/2P5/1P3NP1/PB1PPPBP/RN1Q1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1q1rk1/pb1pppbp/1p3np1/2p5/2P5/1P3NP1/PB1PPPBP/RN1Q1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1q1rk1/pb1pppbp/1p3np1/2p5/2P5/1PN2NP1/PB1PPPBP/R2Q1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1q1rk1/pb2ppbp/1p3np1/2pp4/2P5/1PN2NP1/PB1PPPBP/R2Q1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/p2ppppp/1p6/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/p2ppppp/1p6/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkbnr/pb1ppppp/1p6/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkbnr/pb1ppppp/1p6/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb3ppp/1p1ppn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb3ppp/1p1ppn2/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPP1P/RNBQKB1R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQK2R", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/5NP1/PP1PPPBP/RNBQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb3ppp/1p1ppn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 15, "fen":"rn1qkb1r/pb3ppp/1p1ppn2/2p5/2P5/1PN2NP1/P2PPPBP/R1BQ1RK1", "treename":"English Opening, Symmetrical Variation"},
{"gid": 10, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Main Line"},
{"gid": 10, "fen":"rnbqkbnr/p1pppppp/1p6/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Main Line"},
{"gid": 10, "fen":"rnbqkbnr/p1pppppp/1p6/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Main Line"},
{"gid": 10, "fen":"rn1qkbnr/pbpppppp/1p6/8/2P5/2N5/PP1PPPPP/R1BQKBNR", "treename":"English Opening, Main Line"},
{"gid": 10, "fen":"rn1qkbnr/pbpppppp/1p6/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Main Line"},
{"gid": 10, "fen":"rn1qkbnr/pbpp1ppp/1p2p3/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"English Opening, Main Line"},
{"gid": 4, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3N2/PB1PPPPP/RN1QKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pp1p/2p2np1/3p4/2P5/1P3N2/PB1PPPPP/RN1QKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pp1p/2p2np1/3p4/2P5/1P2PN2/PB1P1PPP/RN1QKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/2P5/1P2PN2/PB1P1PPP/RN1QKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/2P3b1/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3N2/PB1PPPPP/RN1QKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2P3b1/1P3N2/PB1PPPPP/RN1QKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3NP1/P2PPP1P/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2P5/1P3NP1/P2PPP1P/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2P5/1P3NP1/P2PPPBP/RNBQK2R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2P5/1P3NP1/P2PPPBP/RNBQK2R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2P5/1P3NP1/PB1PPPBP/RN1QK2R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3NP1/P2PPP1P/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2P3b1/1P3NP1/P2PPP1P/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2P3b1/1P3NP1/P2PPPBP/RNBQK2R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2P3b1/1P3NP1/P2PPPBP/RNBQK2R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2P3b1/1P3NP1/PB1PPPBP/RN1QK2R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3N2/PB1PPPPP/RN1QKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 4, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2P5/1P3N2/PB1PPPPP/RN1QKB1R", "treename":"English Opening, Anglo-Slav Variation"},
{"gid": 16, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Balogh Variation"},
{"gid": 16, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Balogh Variation"},
{"gid": 16, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Balogh Variation"},
{"gid": 16, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Balogh Variation"},
{"gid": 16, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Balogh Variation"},
{"gid": 16, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Balogh Variation"},
{"gid": 16, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/2BP4/8/PPP2PPP/RNBQK1NR", "treename":"Alekhine Defense, Balogh Variation"},
{"gid": 17, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/ppp1pppp/1n1p4/4P3/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/ppp1pppp/1n1P4/8/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/ppp2ppp/1n1p4/8/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/ppp2ppp/1n1p4/8/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqk2r/ppp1bppp/1n1p4/8/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/ppp1pppp/1n1p4/4P3/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/ppp1pppp/1n1P4/8/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pp2pppp/1n1p4/8/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pp2pppp/1n1p4/8/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pp2pp1p/1n1p2p1/8/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 17, "fen":"rnbqkb1r/pp2pp1p/1n1p2p1/8/2PP4/2N1B3/PP3PPP/R2QKBNR", "treename":"Alekhine Defense, Exchange Variation"},
{"gid": 18, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/1n1p4/4P3/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/1n1p4/4P3/2PP1P2/8/PP4PP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/1n6/4p3/2PP1P2/8/PP4PP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/1n6/4P3/2PP4/8/PP4PP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"r1bqkb1r/ppp1pppp/1nn5/4P3/2PP4/8/PP4PP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"r1bqkb1r/ppp1pppp/1nn5/4P3/2PP4/4B3/PP4PP/RN1QKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/1n1p4/4P3/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/1n1p4/4P3/2PP1P2/8/PP4PP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rn1qkb1r/ppp1pppp/1n1p4/4Pb2/2PP1P2/8/PP4PP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/1n1p4/4P3/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/1n1p4/4P3/2PP1P2/8/PP4PP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/1n6/4p3/2PP1P2/8/PP4PP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/ppp1pppp/1n6/4P3/2PP4/8/PP4PP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 18, "fen":"rnbqkb1r/pp2pppp/1n6/2p1P3/2PP4/8/PP4PP/RNBQKBNR", "treename":"Alekhine Defense, Four Pawns Attack"},
{"gid": 19, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Kmoch Variation"},
{"gid": 19, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Kmoch Variation"},
{"gid": 19, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Kmoch Variation"},
{"gid": 19, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Kmoch Variation"},
{"gid": 19, "fen":"rnbqkb1r/pppppppp/8/3nP3/2B5/8/PPPP1PPP/RNBQK1NR", "treename":"Alekhine Defense, Kmoch Variation"},
{"gid": 19, "fen":"rnbqkb1r/pppppppp/1n6/4P3/2B5/8/PPPP1PPP/RNBQK1NR", "treename":"Alekhine Defense, Kmoch Variation"},
{"gid": 19, "fen":"rnbqkb1r/pppppppp/1n6/4P3/8/1B6/PPPP1PPP/RNBQK1NR", "treename":"Alekhine Defense, Kmoch Variation"},
{"gid": 19, "fen":"rnbqkb1r/pp1ppppp/1n6/2p1P3/8/1B6/PPPP1PPP/RNBQK1NR", "treename":"Alekhine Defense, Kmoch Variation"},
{"gid": 19, "fen":"rnbqkb1r/pp1ppppp/1n6/2p1P3/8/1B1P4/PPP2PPP/RNBQK1NR", "treename":"Alekhine Defense, Kmoch Variation"},
{"gid": 21, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pp1p/3p2p1/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pp1p/3p2p1/3nP3/2BP4/5N2/PPP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pp1p/1n1p2p1/4P3/2BP4/5N2/PPP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pp1p/1n1p2p1/4P3/3P4/1B3N2/PPP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqk2r/ppp1ppbp/1n1p2p1/4P3/3P4/1B3N2/PPP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqk2r/ppp1ppbp/1n1p2p1/4P1N1/3P4/1B6/PPP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/2PP2b1/5N2/PP3PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/1n1p4/4P3/2PP2b1/5N2/PP3PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/1n1P4/8/2PP2b1/5N2/PP3PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/pp2pppp/1n1p4/8/2PP2b1/5N2/PP3PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pp1p/3p2p1/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pp1p/3p2p1/3nP3/2BP4/5N2/PPP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pp1p/1n1p2p1/4P3/2BP4/5N2/PPP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pp1p/1n1p2p1/4P3/3P4/1B3N2/PPP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqk2r/ppp1ppbp/1n1p2p1/4P3/3P4/1B3N2/PPP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqk2r/ppp1ppbp/1n1p2p1/4P3/P2P4/1B3N2/1PP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/8/3np3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/8/3nN3/3P4/8/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pp1p/6p1/3nN3/3P4/8/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pp1p/6p1/3nN3/2BP4/8/PPP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pp2pp1p/2p3p1/3nN3/2BP4/8/PPP2PPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pp2pp1p/2p3p1/3nN3/2BP4/8/PPP2PPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"r1bqkb1r/ppp1pppp/2np4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"r2qkb1r/ppp1pppp/2np4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp2ppp/3pp3/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp2ppp/3pp3/3nP3/3P2b1/5N2/PPP1BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/3pp3/3nP3/3P2b1/5N2/PPP1BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/3pp3/3nP3/2PP2b1/5N2/PP2BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/1n1pp3/4P3/2PP2b1/5N2/PP2BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/1n1pp3/4P3/2PP2b1/5N1P/PP2BPP1/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/1n1pp3/4P2b/2PP4/5N1P/PP2BPP1/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/1n1pp3/4P2b/2PP4/2N2N1P/PP2BPP1/R1BQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1q1rk1/ppp1bppp/1n1pp3/4P2b/2PP4/2N2N1P/PP2BPP1/R1BQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1q1rk1/ppp1bppp/1n1pp3/4P2b/2PP4/2N1BN1P/PP2BPP1/R2Q1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1q1rk1/ppp1bppp/1n2p3/3pP2b/2PP4/2N1BN1P/PP2BPP1/R2Q1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp2ppp/3pp3/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp2ppp/3pp3/3nP3/3P2b1/5N2/PPP1BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/3pp3/3nP3/3P2b1/5N2/PPP1BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/3pp3/3nP3/2PP2b1/5N2/PP2BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/1n1pp3/4P3/2PP2b1/5N2/PP2BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/1n1pp3/4P3/2PP2b1/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1q1rk1/ppp1bppp/1n1pp3/4P3/2PP2b1/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1q1rk1/ppp1bppp/1n1pp3/4P3/2PP2b1/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"r2q1rk1/ppp1bppp/1nnpp3/4P3/2PP2b1/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"r2q1rk1/ppp1bppp/1nnPp3/8/2PP2b1/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"r2q1rk1/pp2bppp/1nnpp3/8/2PP2b1/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp2ppp/3pp3/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qkb1r/ppp2ppp/3pp3/3nP3/3P2b1/5N2/PPP1BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/3pp3/3nP3/3P2b1/5N2/PPP1BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/3pp3/3nP3/2PP2b1/5N2/PP2BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/1n1pp3/4P3/2PP2b1/5N2/PP2BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rn1qk2r/ppp1bppp/1n1Pp3/8/2PP2b1/5N2/PP2BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/1n1p4/4P3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pp2pppp/2pp4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 21, "fen":"rnbqkb1r/pp2pppp/2pp4/3nP3/2PP4/5N2/PP3PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern Variation"},
{"gid": 22, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rn1qkb1r/pp2pppp/2pp4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rn1qkb1r/pp2pppp/2pp4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQ1RK1", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rn1qkb1r/pp2pppp/2pp4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 22, "fen":"rn1qkb1r/pp2pppp/2pp4/3nP3/2PP2b1/5N2/PP2BPPP/RNBQK2R", "treename":"Alekhine Defense, Modern,Flohr Variation"},
{"gid": 23, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Samisch Attack"},
{"gid": 23, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Samisch Attack"},
{"gid": 23, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Samisch Attack"},
{"gid": 23, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Samisch Attack"},
{"gid": 23, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Samisch Attack"},
{"gid": 23, "fen":"rnbqkb1r/pppppppp/8/4P3/8/2n5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Samisch Attack"},
{"gid": 23, "fen":"rnbqkb1r/pppppppp/8/4P3/8/2P5/PPP2PPP/R1BQKBNR", "treename":"Alekhine Defense, Samisch Attack"},
{"gid": 24, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/5n2/3pP3/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/8/3pP3/4n3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/8/3n4/2B5/2N5/PPPP1PPP/R1BQK1NR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/1n6/8/2B5/2N5/PPPP1PPP/R1BQK1NR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/8/3N4/8/8/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnb1kb1r/ppp1pppp/8/3q4/8/8/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/8/3n4/2B5/2N5/PPPP1PPP/R1BQK1NR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp2ppp/4p3/3n4/2B5/2N5/PPPP1PPP/R1BQK1NR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 24, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Alekhine Defense, Scandinavian Variation"},
{"gid": 25, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Spielmann Gambit"},
{"gid": 25, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Spielmann Gambit"},
{"gid": 25, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Spielmann Gambit"},
{"gid": 25, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Spielmann Gambit"},
{"gid": 25, "fen":"rnbqkb1r/ppp1pppp/5n2/3pP3/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Spielmann Gambit"},
{"gid": 25, "fen":"rnbqkb1r/pppnpppp/8/3pP3/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Spielmann Gambit"},
{"gid": 25, "fen":"rnbqkb1r/pppnpppp/4P3/3p4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Alekhine Defense, Spielmann Gambit"},
{"gid": 26, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Two Pawn Attack"},
{"gid": 26, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Two Pawn Attack"},
{"gid": 26, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Two Pawn Attack"},
{"gid": 26, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Two Pawn Attack"},
{"gid": 26, "fen":"rnbqkb1r/pppppppp/8/3nP3/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Alekhine Defense, Two Pawn Attack"},
{"gid": 26, "fen":"rnbqkb1r/pppppppp/1n6/4P3/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Alekhine Defense, Two Pawn Attack"},
{"gid": 26, "fen":"rnbqkb1r/pppppppp/1n6/2P1P3/8/8/PP1P1PPP/RNBQKBNR", "treename":"Alekhine Defense, Two Pawn Attack"},
{"gid": 26, "fen":"rnbqkb1r/pppppppp/8/2PnP3/8/8/PP1P1PPP/RNBQKBNR", "treename":"Alekhine Defense, Two Pawn Attack"},
{"gid": 26, "fen":"rnbqkb1r/pppppppp/8/2PnP3/8/2N5/PP1P1PPP/R1BQKBNR", "treename":"Alekhine Defense, Two Pawn Attack"},
{"gid": 20, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Main Line"},
{"gid": 20, "fen":"rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Main Line"},
{"gid": 20, "fen":"rnbqkb1r/pppppppp/5n2/4P3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Main Line"},
{"gid": 20, "fen":"rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR", "treename":"Alekhine Defense, Main Line"},
{"gid": 20, "fen":"rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Main Line"},
{"gid": 20, "fen":"rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Main Line"},
{"gid": 20, "fen":"rnbqkb1r/ppp1pppp/3P4/3n4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Alekhine Defense, Main Line"},
{"gid": 27, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Berlin Defense"},
{"gid": 27, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Berlin Defense"},
{"gid": 27, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR", "treename":"Bishop's Opening, Berlin Defense"},
{"gid": 27, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR", "treename":"Bishop's Opening, Berlin Defense"},
{"gid": 27, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/3P4/PPP2PPP/RNBQK1NR", "treename":"Bishop's Opening, Berlin Defense"},
{"gid": 27, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/4p3/2B1P3/3P4/PPP2PPP/RNBQK1NR", "treename":"Bishop's Opening, Berlin Defense"},
{"gid": 27, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Bishop's Opening, Berlin Defense"},
{"gid": 28, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 28, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 28, "fen":"rnbqkbnr/pppp1ppp/8/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 28, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 28, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2BPP3/8/PPP2PPP/RNBQK1NR", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 28, "fen":"rnbqkb1r/pppp1ppp/5n2/8/2BpP3/8/PPP2PPP/RNBQK1NR", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 28, "fen":"rnbqkb1r/pppp1ppp/5n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 28, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/2BpP3/5N2/PPP2PPP/RNBQK2R", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 28, "fen":"rnbqkb1r/ppp2ppp/5n2/3P4/2Bp4/5N2/PPP2PPP/RNBQK2R", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 28, "fen":"rnbqk2r/ppp2ppp/5n2/3P4/1bBp4/5N2/PPP2PPP/RNBQK2R", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 28, "fen":"rnbqk2r/ppp2ppp/5n2/3P4/1bBp4/2P2N2/PP3PPP/RNBQK2R", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 28, "fen":"rnb1k2r/ppp1qppp/5n2/3P4/1bBp4/2P2N2/PP3PPP/RNBQK2R", "treename":"Bishop's Opening, Urusov Gambit"},
{"gid": 29, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bB1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bB1P3/2NP4/PPP1NPPP/R1BQK2R", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqk2r/ppp2ppp/3p1n2/2b1p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqk2r/ppp2ppp/3p1n2/2b1p3/2B1PP2/2NP4/PPP3PP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqk2r/ppp2ppp/3p1n2/2b1p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqk2r/ppp2ppp/3p1n2/2b1p3/N1B1P3/3P4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1PP2/2NP4/PPP3PP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1PP2/2NP4/PPP3PP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1PP2/2NP1N2/PPP3PP/R1BQK2R", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r2qk2r/ppp2ppp/2np1n2/2b1p3/2B1PPb1/2NP1N2/PPP3PP/R1BQK2R", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/ppppbppp/2n2n2/4p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1PP2/2NP4/PPP3PP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1PP2/2NP4/PPP3PP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1PP2/2NP1N2/PPP3PP/R1BQK2R", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/1pp2ppp/p1np1n2/2b1p3/2B1PP2/2NP1N2/PPP3PP/R1BQK2R", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bB1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p1B1/1bB1P3/2NP4/PPP2PPP/R2QK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p1B1/2B1P3/2NP4/PPP2PPP/R2QK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/5n2/n3p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/5n2/n3p3/2B1P3/2NP4/PPP1NPPP/R1BQK2R", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bB1P3/2NP4/PPP2PPP/R1BQK1NR", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 29, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1bB1P3/2NP1N2/PPP2PPP/R1BQK2R", "treename":"Bishop's Opening, Vienna Hybrid"},
{"gid": 30, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P1P3/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P1P3/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp2pppp/8/3p4/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp2pppp/8/3P4/8/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkb1r/pp2pppp/5n2/3P4/8/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkb1r/pp2pppp/5n2/3P4/8/2N5/PP1P1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkb1r/pp2pppp/8/3n4/8/2N5/PP1P1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P1P3/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P1P3/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp2pppp/8/3p4/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp2pppp/8/3P4/8/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkb1r/pp2pppp/5n2/3P4/8/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkb1r/pp2pppp/5n2/1B1P4/8/8/PP1P1PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp1ppppp/2p5/8/2P1P3/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2P1P3/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/4P3/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 30, "fen":"rnbqkbnr/pp2pppp/8/3p4/4P3/8/PP1P1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Accelerated Panov Attack"},
{"gid": 31, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance"},
{"gid": 31, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance"},
{"gid": 31, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance"},
{"gid": 31, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance"},
{"gid": 31, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance"},
{"gid": 31, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance"},
{"gid": 31, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance"},
{"gid": 31, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance"},
{"gid": 31, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/2P5/PP2BPPP/RNBQK1NR", "treename":"Caro-Kann Defense, Advance"},
{"gid": 32, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P2P1/8/PPP2P1P/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/8/2ppP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/8/2PpP3/8/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"r1bqkbnr/pp2pppp/2n5/2PpP3/8/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/5N2/PPP1BPPP/RNBQK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/4p3/2ppPb2/3P4/5N2/PPP1BPPP/RNBQK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/4p3/2ppPb2/3P4/4BN2/PPP1BPPP/RN1QK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkb1r/pp2nppp/4p3/2ppPb2/3P4/4BN2/PPP1BPPP/RN1QK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/5N2/PPP1BPPP/RNBQK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"r2qkbnr/pp1n1ppp/2p1p3/3pPb2/3P4/5N2/PPP1BPPP/RNBQK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/5N2/PPP1BPPP/RNBQK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/4p3/2ppPb2/3P4/5N2/PPP1BPPP/RNBQK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/4p3/2ppPb2/3P4/5N2/PPP1BPPP/RNBQ1RK1", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkb1r/pp2nppp/4p3/2ppPb2/3P4/5N2/PPP1BPPP/RNBQ1RK1", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/5N2/PPP1BPPP/RNBQK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/4p3/2ppPb2/3P4/5N2/PPP1BPPP/RNBQK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/4p3/2ppPb2/3P4/4BN2/PPP1BPPP/RN1QK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"r2qkbnr/pp1n1ppp/4p3/2ppPb2/3P4/4BN2/PPP1BPPP/RN1QK2R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"r2qkbnr/pp1n1ppp/4p3/2ppPb2/3P4/4BN2/PPP1BPPP/RN1Q1RK1", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P3P/8/PPP2PP1/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn2kbnr/pp2pppp/1qp5/3pPb2/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2ppp1/2p5/3pPb1p/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P2P1/2N5/PPP2P1P/R1BQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p1b1/3pP3/3P2P1/2N5/PPP2P1P/R1BQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/2p1p1b1/3pP3/3P2P1/2N5/PPP1NP1P/R1BQKB1R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp3ppp/4p1b1/2ppP3/3P2P1/2N5/PPP1NP1P/R1BQKB1R", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 32, "fen":"rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Advance Variation"},
{"gid": 33, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/3P4/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/4P3/3P4/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/3P4/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pp3ppp/2p5/3pp3/4P3/3P4/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqkbnr/pp3ppp/2p5/3pp3/4P3/3P1N2/PPPN1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqk1nr/pp3ppp/2pb4/3pp3/4P3/3P1N2/PPPN1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 33, "fen":"rnbqk1nr/pp3ppp/2pb4/3pp3/4P3/3P1NP1/PPPN1P1P/R1BQKB1R", "treename":"Caro-Kann Defense, Breyer Variation"},
{"gid": 34, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2N2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/3P4/2P5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rn1qkb1r/pp2pp1p/2p2p2/5b2/3P4/2P5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rn1qkb1r/pp2pp1p/2p2p2/5b2/3P4/2P2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rn1qkb1r/pp3p1p/2p1pp2/5b2/3P4/2P2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2N2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rn1qkb1r/pp2pp1p/2p2p2/5b2/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2N2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/3P4/2P5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rn1qkb1r/pp2pp1p/2p2p2/5b2/3P4/2P5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rn1qkb1r/pp2pp1p/2p2p2/5b2/3P4/2P5/PP2NPPP/R1BQKB1R", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2N2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/3P4/8/PPP1NPPP/R1BQKB1R", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2N2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/3P4/3Q4/PPP2PPP/R1B1KBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pppp/2p2N2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 34, "fen":"rnbqkb1r/pp2pp1p/2p2p2/8/2BP4/8/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Bronstein-Larsen Variation"},
{"gid": 35, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p3bp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p3bp/7P/3P4/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1npppb/2p4p/7P/3P4/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1npppb/2p4p/7P/3P4/3B1NN1/PPP2PP1/R1BQK2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p4p/7P/3P4/3b1NN1/PPP2PP1/R1BQK2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p4p/7P/3P4/3Q1NN1/PPP2PP1/R1B1K2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r3kbnr/ppqnppp1/2p4p/7P/3P4/3Q1NN1/PPP2PP1/R1B1K2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r3kbnr/ppqnppp1/2p4p/7P/3P4/3Q1NN1/PPPB1PP1/R3K2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r3kbnr/ppqn1pp1/2p1p2p/7P/3P4/3Q1NN1/PPPB1PP1/R3K2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r3kbnr/ppqn1pp1/2p1p2p/7P/3P4/3Q1NN1/PPPB1PP1/2KR3R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r3kb1r/ppqn1pp1/2p1pn1p/7P/3P4/3Q1NN1/PPPB1PP1/2KR3R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r3kb1r/ppqn1pp1/2p1pn1p/7P/3PN3/3Q1N2/PPPB1PP1/2KR3R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"2kr1b1r/ppqn1pp1/2p1pn1p/7P/3PN3/3Q1N2/PPPB1PP1/2KR3R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"2kr1b1r/ppqn1pp1/2p1pn1p/7P/3PN3/3Q1NP1/PPPB1P2/2KR3R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"2kr1b1r/ppqn1pp1/2p1p2p/7P/3Pn3/3Q1NP1/PPPB1P2/2KR3R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p3bp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p3bp/7P/3P4/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1npppb/2p4p/7P/3P4/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1npppb/2p4p/7P/3P4/3B1NN1/PPP2PP1/R1BQK2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p4p/7P/3P4/3b1NN1/PPP2PP1/R1BQK2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p4p/7P/3P4/3Q1NN1/PPP2PP1/R1B1K2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkb1r/pp1nppp1/2p2n1p/7P/3P4/3Q1NN1/PPP2PP1/R1B1K2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p3bp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p3bp/7P/3P4/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1npppb/2p4p/7P/3P4/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1npppb/2p4p/7P/3P4/3B1NN1/PPP2PP1/R1BQK2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p4p/7P/3P4/3b1NN1/PPP2PP1/R1BQK2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1nppp1/2p4p/7P/3P4/3Q1NN1/PPP2PP1/R1B1K2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1n1pp1/2p1p2p/7P/3P4/3Q1NN1/PPP2PP1/R1B1K2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 35, "fen":"r2qkbnr/pp1n1pp1/2p1p2p/7P/3P1B2/3Q1NN1/PPP2PP1/R3K2R", "treename":"Caro-Kann Defense, Classical"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp3pp1/2p1p1bp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkb1r/pp2ppp1/2p2nbp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkb1r/pp2ppp1/2p2nbp/7P/3P4/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkb1r/pp2pppb/2p2n1p/7P/3P4/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P1P1P/6N1/PPP3P1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6NN/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkb1r/pp2ppp1/2p2nbp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkb1r/pp2ppp1/2p2nbp/4N3/3P3P/6N1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkb1r/pp2pppb/2p2n1p/4N3/3P3P/6N1/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkb1r/pp2pppb/2p2n1p/4N3/3P3P/3B2N1/PPP2PP1/R1BQK2R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/8/3P3P/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2ppp1/2p3bp/7P/3P4/6N1/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P1P2/6N1/PPP3PP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/5NN1/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"r2qkbnr/pp1npppp/2p3b1/8/3P4/5NN1/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"r2qkbnr/pp1npppp/2p3b1/8/3P4/3B1NN1/PPP2PPP/R1BQK2R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"r2qkbnr/pp1n1ppp/2p1p1b1/8/3P4/3B1NN1/PPP2PPP/R1BQK2R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/2N2b2/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/p3pppp/1pp5/2N2b2/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/2BP4/6N1/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp3ppp/2p1p1b1/8/2BP4/6N1/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp3ppp/2p1p1b1/8/2BP4/6N1/PPP1NPPP/R1BQK2R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkb1r/pp3ppp/2p1pnb1/8/2BP4/6N1/PPP1NPPP/R1BQK2R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP1NPPP/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p5/5b2/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 36, "fen":"rn1qkbnr/pp2pppp/2p3b1/8/3P4/6NN/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Classical Variation"},
{"gid": 37, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Edinburgh Variation"},
{"gid": 37, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Edinburgh Variation"},
{"gid": 37, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Edinburgh Variation"},
{"gid": 37, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Edinburgh Variation"},
{"gid": 37, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Edinburgh Variation"},
{"gid": 37, "fen":"rnb1kbnr/pp2pppp/1qp5/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Edinburgh Variation"},
{"gid": 38, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkbnr/pp2pppp/2n5/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkbnr/pp2pppp/2n5/3p4/3P4/2PB4/PP3PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2PB4/PP3PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2PB4/PP3PPP/RN1QK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r2qkb1r/pp2pppp/2n2n2/3p4/3P1Bb1/2PB4/PP3PPP/RN1QK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r2qkb1r/pp2pppp/2n2n2/3p4/3P1Bb1/1QPB4/PP3PPP/RN2K1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1q1kb1r/pp2pppp/2n2n2/3p4/3P1Bb1/1QPB4/PP3PPP/RN2K1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkbnr/pp2pppp/2n5/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkbnr/pp2pppp/2n5/3p4/3P4/2PB4/PP3PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2PB4/PP3PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2PB4/PP3PPP/RN1QK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r2qkb1r/pp2pppp/2n2n2/3p4/3P1Bb1/2PB4/PP3PPP/RN1QK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r2qkb1r/pp2pppp/2n2n2/3p4/3P1Bb1/1QPB4/PP3PPP/RN2K1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r3kb1r/pp1qpppp/2n2n2/3p4/3P1Bb1/1QPB4/PP3PPP/RN2K1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnb1kbnr/pp2pppp/2p5/3q4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkbnr/pp2pppp/2n5/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkbnr/pp2pppp/2n5/3p4/3P4/2PB4/PP3PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkbnr/pp2pp1p/2n3p1/3p4/3P4/2PB4/PP3PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkbnr/pp2pppp/2n5/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkbnr/pp2pppp/2n5/3p4/3P4/2PB4/PP3PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2PB4/PP3PPP/RNBQK1NR", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 38, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2PB1N2/PP3PPP/RNBQK2R", "treename":"Caro-Kann Defense, Exchange Variation"},
{"gid": 39, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Forgacs Variation"},
{"gid": 39, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Forgacs Variation"},
{"gid": 39, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Forgacs Variation"},
{"gid": 39, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Forgacs Variation"},
{"gid": 39, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Forgacs Variation"},
{"gid": 39, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Forgacs Variation"},
{"gid": 39, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Forgacs Variation"},
{"gid": 39, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Forgacs Variation"},
{"gid": 39, "fen":"rnbqkb1r/pp2pppp/2p2N2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Forgacs Variation"},
{"gid": 39, "fen":"rnbqkb1r/pp3ppp/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Forgacs Variation"},
{"gid": 39, "fen":"rnbqkb1r/pp3ppp/2p2p2/8/2BP4/8/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Forgacs Variation"},
{"gid": 40, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze Counterattack"},
{"gid": 40, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze Counterattack"},
{"gid": 40, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze Counterattack"},
{"gid": 40, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze Counterattack"},
{"gid": 40, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze Counterattack"},
{"gid": 40, "fen":"rnbqkbnr/p3pppp/2p5/1p1p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze Counterattack"},
{"gid": 41, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3pP3/3P1P2/2N5/PPP3PP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk2r/pp2ppbp/2p3pn/3pP3/3P1P2/2N5/PPP3PP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N4P/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3p4/3PP3/2N4P/PPP2PP1/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3P4/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3pP3/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3p4/3PP3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/8/3Pp3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/8/3PN3/5N1P/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3p4/3PP3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/3PP3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 41, "fen":"rnbqk2r/pp2ppbp/2p2np1/3pP3/3P4/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Gurgenidze System"},
{"gid": 42, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/6N1/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2n2/6N1/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2n2/6N1/3P4/3B4/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/6N1/3P4/3B4/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/6N1/3P4/3B1N2/PPP2PPP/R1BQK2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/6N1/3P4/3B1N2/PPP2PPP/R1BQK2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/6N1/3P4/3B1N2/PPP1QPPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1pp1/2pbpn1p/6N1/3P4/3B1N2/PPP1QPPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1pp1/2pbpn1p/8/3PN3/3B1N2/PPP1QPPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1pp1/2pbp2p/8/3Pn3/3B1N2/PPP1QPPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1pp1/2pbp2p/8/3PQ3/3B1N2/PPP2PPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp3pp1/2pbpn1p/8/3PQ3/3B1N2/PPP2PPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/6N1/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2n2/6N1/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2n2/6N1/3P4/3B4/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/6N1/3P4/3B4/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/6N1/3P4/3B1N2/PPP2PPP/R1BQK2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/6N1/3P4/3B1N2/PPP2PPP/R1BQK2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/6N1/3P4/3B1N2/PPP1QPPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1pp1/2pbpn1p/6N1/3P4/3B1N2/PPP1QPPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1pp1/2pbpn1p/8/3PN3/3B1N2/PPP1QPPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1pp1/2pbp2p/8/3Pn3/3B1N2/PPP1QPPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqk2r/pp1n1pp1/2pbp2p/8/3PQ3/3B1N2/PPP2PPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1b1k2r/ppqn1pp1/2pbp2p/8/3PQ3/3B1N2/PPP2PPP/R1B1K2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2n2/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2n2/8/3P4/5NN1/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/3P4/5NN1/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/8/2BPN3/8/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2n2/8/2BPN3/8/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2n2/6N1/2BP4/8/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/6N1/2BP4/8/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/6N1/2BP4/8/PPP1QPPP/R1B1K1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp3ppp/1np1pn2/6N1/2BP4/8/PPP1QPPP/R1B1K1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp3ppp/1np1pn2/6N1/3P4/1B6/PPP1QPPP/R1B1K1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/8/2BPN3/8/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2n2/8/2BPN3/8/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2n2/6N1/2BP4/8/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/6N1/2BP4/8/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/6N1/2BP4/8/PPP1QPPP/R1B1K1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp3ppp/1np1pn2/6N1/2BP4/8/PPP1QPPP/R1B1K1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp3ppp/1np1pn2/6N1/3P4/3B4/PPP1QPPP/R1B1K1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp3pp1/1np1pn1p/6N1/3P4/3B4/PPP1QPPP/R1B1K1NR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkbnr/pp1npppp/2p5/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2n2/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp1npppp/2p2N2/8/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp2pppp/2p2n2/8/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 42, "fen":"r1bqkb1r/pp2pppp/2p2n2/8/2BP4/5N2/PPP2PPP/R1BQK2R", "treename":"Caro-Kann Defense, Karpov Variation"},
{"gid": 43, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Main Line"},
{"gid": 43, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Main Line"},
{"gid": 43, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Main Line"},
{"gid": 43, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Main Line"},
{"gid": 43, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Main Line"},
{"gid": 43, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Main Line"},
{"gid": 43, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Main Line"},
{"gid": 43, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Main Line"},
{"gid": 43, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/3P4/6N1/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Main Line"},
{"gid": 44, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/5P2/PPP3PP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/5P2/PPP3PP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PP3/8/PPP3PP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp3ppp/2p5/4p3/3PP3/8/PPP3PP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp3ppp/2p5/4p3/3PP3/5N2/PPP3PP/RNBQKB1R", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp3ppp/2p5/8/3pP3/5N2/PPP3PP/RNBQKB1R", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp3ppp/2p5/8/2BpP3/5N2/PPP3PP/RNBQK2R", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/5P2/PPP3PP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 44, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/3PP3/5P2/PPP3PP/RNBQKBNR", "treename":"Caro-Kann Defense, Maroczy Variation"},
{"gid": 45, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/8/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3p4/3PP3/2P2N2/PP1N1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 45, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3p4/3PP3/5N1P/PPPN1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Modern Variation"},
{"gid": 46, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p2B1/2PP4/2N5/PP3PPP/R2QKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp3ppp/2n1pn2/3p2B1/2PP4/2N5/PP3PPP/R2QKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp3ppp/2n1pn2/3p2B1/2PP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqk2r/pp2bppp/2n1pn2/3p2B1/2PP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqk2r/pp2bppp/2n1pn2/2Pp2B1/3P4/2N2N2/PP3PPP/R2QKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bq1rk1/pp2bppp/2n1pn2/2Pp2B1/3P4/2N2N2/PP3PPP/R2QKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bq1rk1/pp2bppp/2n1pn2/1BPp2B1/3P4/2N2N2/PP3PPP/R2QK2R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p2B1/2PP4/2N5/PP3PPP/R2QKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1b1kb1r/pp2pppp/2n2n2/q2p2B1/2PP4/2N5/PP3PPP/R2QKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n2n2/3p4/2PP2b1/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n2n2/3P4/3P2b1/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n5/3n4/3P2b1/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n5/3n4/3P2b1/1QN2N2/PP3PPP/R1B1KB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n5/3n4/3P4/1QN2b2/PP3PPP/R1B1KB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n5/3n4/3P4/1QN2P2/PP3P1P/R1B1KB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/1nn5/8/3P4/1QN2P2/PP3P1P/R1B1KB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n2n2/3p4/2PP2b1/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n2n2/3P4/3P2b1/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n5/3n4/3P2b1/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n5/3n4/3P2b1/1QN2N2/PP3PPP/R1B1KB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n5/3n4/3P4/1QN2b2/PP3PPP/R1B1KB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n5/3n4/3P4/1QN2P2/PP3P1P/R1B1KB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp3ppp/2n1p3/3n4/3P4/1QN2P2/PP3P1P/R1B1KB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p2B1/2PP4/2N5/PP3PPP/R2QKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r2qkb1r/pp2pppp/2n1bn2/3p2B1/2PP4/2N5/PP3PPP/R2QKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n2n2/3P4/3P4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp2pppp/2n5/3n4/3P4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"r1bqkb1r/pp3ppp/2n1pn2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqk2r/pp2bppp/4pn2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqk2r/pp2bppp/4pn2/3p4/2PP4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp3ppp/4p3/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqk2r/pp2bppp/4pn2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqk2r/pp2bppp/4pn2/3P4/3P4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqk2r/pp2bppp/4p3/3n4/3P4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 46, "fen":"rnbqk2r/pp2bppp/4p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Caro-Kann Defense, Panov Attack"},
{"gid": 47, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pp1p/5np1/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pp1p/5np1/3p4/2PP4/1QN5/PP3PPP/R1B1KBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqk2r/pp2ppbp/5np1/3p4/2PP4/1QN5/PP3PPP/R1B1KBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqk2r/pp2ppbp/5np1/3P4/3P4/1QN5/PP3PPP/R1B1KBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbq1rk1/pp2ppbp/5np1/3P4/3P4/1QN5/PP3PPP/R1B1KBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbq1rk1/pp2ppbp/5np1/3P4/3P4/1QN5/PP2BPPP/R1B1K1NR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"r1bq1rk1/pp1nppbp/5np1/3P4/3P4/1QN5/PP2BPPP/R1B1K1NR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pp1p/5np1/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pp1p/5np1/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pp1p/5np1/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pp1p/5np1/3P4/3P4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pp1p/6p1/3n4/3P4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 47, "fen":"rnbqkb1r/pp2pp1p/6p1/3n4/2BP4/2N5/PP3PPP/R1BQK1NR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense"},
{"gid": 48, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 48, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 48, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 48, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 48, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 48, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 48, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 48, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 48, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 48, "fen":"rnbqkb1r/pp2pp1p/5np1/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 48, "fen":"rnbqkb1r/pp2pp1p/5np1/3P4/3P4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 48, "fen":"rnbqk2r/pp2ppbp/5np1/3P4/3P4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit"},
{"gid": 49, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p2B1/2PP4/2N5/PP3PPP/R2QKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"r1bqkb1r/pp2pppp/2n2n2/6B1/2pP4/2N5/PP3PPP/R2QKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"r1bqkb1r/pp2pppp/2n2n2/3P2B1/2p5/2N5/PP3PPP/R2QKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 49, "fen":"r1bqkb1r/pp2pppp/5n2/n2P2B1/2p5/2N5/PP3PPP/R2QKBNR", "treename":"Caro-Kann Defense, Panov-Botvinnik"},
{"gid": 50, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Rasa-Studier Gambit"},
{"gid": 50, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Rasa-Studier Gambit"},
{"gid": 50, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Rasa-Studier Gambit"},
{"gid": 50, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Rasa-Studier Gambit"},
{"gid": 50, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Rasa-Studier Gambit"},
{"gid": 50, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Rasa-Studier Gambit"},
{"gid": 50, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR", "treename":"Caro-Kann Defense, Rasa-Studier Gambit"},
{"gid": 51, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Tartakower Variation"},
{"gid": 51, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Tartakower Variation"},
{"gid": 51, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Tartakower Variation"},
{"gid": 51, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Tartakower Variation"},
{"gid": 51, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Tartakower Variation"},
{"gid": 51, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Tartakower Variation"},
{"gid": 51, "fen":"rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Tartakower Variation"},
{"gid": 51, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Tartakower Variation"},
{"gid": 51, "fen":"rnbqkb1r/pp2pppp/2p2N2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Tartakower Variation"},
{"gid": 51, "fen":"rnbqkb1r/pp3ppp/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Tartakower Variation"},
{"gid": 51, "fen":"rnbqkb1r/pp3ppp/2p2p2/8/3P4/2P5/PP3PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Tartakower Variation"},
{"gid": 52, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2b1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2b1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp3ppp/2p1p3/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp3ppp/2p1p3/3p4/4P3/2NP1Q1P/PPP2PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"r2qkbnr/pp1n1ppp/2p1p3/3p4/4P3/2NP1Q1P/PPP2PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2b1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp3ppp/2p1p3/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp3ppp/2p1p3/3p4/4P3/2N2QPP/PPPP1P2/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/4P3/2N2QPP/PPPP1P2/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2b1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp3ppp/2p1p3/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp3ppp/2p1p3/3p4/4P3/2NP1Q1P/PPP2PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/4P3/2NP1Q1P/PPP2PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/4P3/P1NP1Q1P/1PP2PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2b1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp3ppp/2p1p3/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp3ppp/2p1p3/3p4/3PP3/2N2Q1P/PPP2PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2b1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp3ppp/2p1p3/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp3ppp/2p1p3/3p4/4P3/2NP1Q1P/PPP2PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/4P3/2NP1Q1P/PPP2PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/4P3/2NP1Q1P/PPPB1PP1/R3KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2b1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/8/4p3/2N2Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/8/4N3/5Q1P/PPPP1PP1/R1B1KB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3p3b/4P3/2N2N1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/2p5/3P3b/8/2N2N1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/8/3p3b/8/2N2N1P/PPPP1PP1/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rn1qkbnr/pp2pppp/8/1B1p3b/8/2N2N1P/PPPP1PP1/R1BQK2R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"r2qkbnr/pp2pppp/2n5/1B1p3b/8/2N2N1P/PPPP1PP1/R1BQK2R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"r2qkbnr/pp2pppp/2n5/1B1p3b/6P1/2N2N1P/PPPP1P2/R1BQK2R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"r2qkbnr/pp2pppp/2n3b1/1B1p4/6P1/2N2N1P/PPPP1P2/R1BQK2R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/8/4p3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkbnr/pp2pppp/2p5/8/4N3/5N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 52, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/4N3/5N2/PPPP1PPP/R1BQKB1R", "treename":"Caro-Kann Defense, Two Knights Attack"},
{"gid": 53, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, von Hennig Gambit"},
{"gid": 53, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Caro-Kann Defense, von Hennig Gambit"},
{"gid": 53, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, von Hennig Gambit"},
{"gid": 53, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Caro-Kann Defense, von Hennig Gambit"},
{"gid": 53, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, von Hennig Gambit"},
{"gid": 53, "fen":"rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Caro-Kann Defense, von Hennig Gambit"},
{"gid": 53, "fen":"rnbqkbnr/pp2pppp/2p5/8/2BPp3/2N5/PPP2PPP/R1BQK1NR", "treename":"Caro-Kann Defense, von Hennig Gambit"},
{"gid": 54, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkb1r/pp2pppp/2pp1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkb1r/pp2pppp/2pp1n2/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnb1kb1r/pp2pppp/2pp1n2/q7/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkb1r/pp2pppp/2pp1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Czech Defense, Main Line"},
{"gid": 54, "fen":"rnbqkb1r/pp2pppp/2pp1n2/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Czech Defense, Main Line"},
{"gid": 55, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1Bb1p3/4P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1Bb1N3/4P3/2N5/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bq1rk1/pppp1ppp/5n2/1Bb1n3/4P3/2N5/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bq1rk1/pppp1ppp/5n2/1Bb1n3/3PP3/2N5/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bq1rk1/pppp1ppp/3b1n2/1B2n3/3PP3/2N5/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bq1rk1/pppp1ppp/3b1n2/1B2n3/3PPP2/2N5/PPP3PP/R1BQ1RK1", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bq1rk1/pppp1ppp/2nb1n2/1B6/3PPP2/2N5/PPP3PP/R1BQ1RK1", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bq1rk1/pppp1ppp/2nb1n2/1B2P3/3P1P2/2N5/PPP3PP/R1BQ1RK1", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 55, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2P3/1b1P1P2/2N5/PPP3PP/R1BQ1RK1", "treename":"Four Knights Game, Bardeleben Variation"},
{"gid": 56, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Gunsberg Variation"},
{"gid": 56, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Gunsberg Variation"},
{"gid": 56, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Gunsberg Variation"},
{"gid": 56, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Gunsberg Variation"},
{"gid": 56, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Gunsberg Variation"},
{"gid": 56, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Gunsberg Variation"},
{"gid": 56, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/P1N2N2/1PPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Gunsberg Variation"},
{"gid": 57, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Italian Variation"},
{"gid": 57, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Italian Variation"},
{"gid": 58, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/4P3/2bP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/4P3/2PP1N2/P1P2PPP/R1BQ1RK1", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p3/4P3/2PP1N2/P1P2PPP/R1BQ1RK1", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 58, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p3/4P3/2PP1N2/P1P2PPP/R1BQR1K1", "treename":"Four Knights Game, Janowski Variation"},
{"gid": 60, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 60, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 60, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 60, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 60, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 60, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 60, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 60, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 60, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 60, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1Bb1p3/4P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 60, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1Bb1N3/4P3/2N5/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 60, "fen":"r1bq1rk1/pppp1ppp/5n2/1Bb1N3/3nP3/2N5/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Marshall Variation"},
{"gid": 61, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Nimzowitsch (Paulsen)"},
{"gid": 61, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Nimzowitsch (Paulsen)"},
{"gid": 61, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Nimzowitsch (Paulsen)"},
{"gid": 61, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Nimzowitsch (Paulsen)"},
{"gid": 61, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Nimzowitsch (Paulsen)"},
{"gid": 61, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Nimzowitsch (Paulsen)"},
{"gid": 61, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Nimzowitsch (Paulsen)"},
{"gid": 61, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Nimzowitsch (Paulsen)"},
{"gid": 61, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Nimzowitsch (Paulsen)"},
{"gid": 61, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Nimzowitsch (Paulsen)"},
{"gid": 61, "fen":"r1bq1rk1/pppp1ppp/2B2n2/4p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Nimzowitsch (Paulsen)"},
{"gid": 62, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Rubinstein Countergambit"},
{"gid": 62, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Rubinstein Countergambit"},
{"gid": 62, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Rubinstein Countergambit"},
{"gid": 62, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Rubinstein Countergambit"},
{"gid": 62, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Rubinstein Countergambit"},
{"gid": 62, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Rubinstein Countergambit"},
{"gid": 62, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Rubinstein Countergambit"},
{"gid": 62, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Rubinstein Countergambit"},
{"gid": 62, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Rubinstein Countergambit"},
{"gid": 63, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3pP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkb1r/pppp1ppp/2n2n2/3N4/3pP3/5N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqk2r/ppppbppp/2n2n2/3N4/3pP3/5N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1b1PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqk2r/pppp1ppp/2n2n2/4N3/1b1PP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkb1r/ppp2ppp/2np1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqk2r/pppp1ppp/2n2n2/4p3/1b1PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 63, "fen":"r1bqk2r/pppp1ppp/2n2n2/3Pp3/1b2P3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation"},
{"gid": 64, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3pP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b5/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3pP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/ppp2ppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3pP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/pppp1ppp/2n2n2/8/1b1NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/pppp1ppp/2N2n2/8/1b2P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1pp1ppp/2p2n2/8/1b2P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1pp1ppp/2p2n2/8/1b2P3/2NB4/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1p2ppp/2p2n2/3p4/1b2P3/2NB4/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1p2ppp/2p2n2/3P4/1b6/2NB4/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1p2ppp/5n2/3p4/1b6/2NB4/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1p2ppp/5n2/3p4/1b6/2NB4/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bq1rk1/p1p2ppp/5n2/3p4/1b6/2NB4/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bq1rk1/p1p2ppp/5n2/3p2B1/1b6/2NB4/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bq1rk1/p4ppp/2p2n2/3p2B1/1b6/2NB4/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bq1rk1/p4ppp/2p2n2/3p2B1/1b6/2NB1Q2/PPP2PPP/R4RK1", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bq1rk1/p3bppp/2p2n2/3p2B1/8/2NB1Q2/PPP2PPP/R4RK1", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3pP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/pppp1ppp/2n2n2/8/1b1NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/pppp1ppp/2N2n2/8/1b2P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1pp1ppp/2p2n2/8/1b2P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1pp1ppp/2p2n2/8/1b2P3/2NB4/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1p2ppp/2p2n2/3p4/1b2P3/2NB4/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1p2ppp/2p2n2/3P4/1b6/2NB4/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1p2ppp/5n2/3p4/1b6/2NB4/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bqk2r/p1p2ppp/5n2/3p4/1b6/2NB4/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bq1rk1/p1p2ppp/5n2/3p4/1b6/2NB4/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bq1rk1/p1p2ppp/5n2/3p2B1/1b6/2NB4/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bq1rk1/p4ppp/2p2n2/3p2B1/1b6/2NB4/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 64, "fen":"r1bq1rk1/p4ppp/2p2n2/3p2B1/Nb6/3B4/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Scotch Variation Accepted"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1N3/4P3/2N5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2N3/3nP3/2N5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1b1kb1r/ppppqppp/5n2/1B2N3/3nP3/2N5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1b1kb1r/ppppqppp/5n2/1B2N3/3nPP2/2N5/PPPP2PP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/4p3/B2nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/4p3/B3P3/2N2n2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/4p3/B2nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pp1p1ppp/2p2n2/4p3/B2nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pp1p1ppp/2p2n2/4N3/B2nP3/2N5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/4p3/2BnP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/4p3/B2nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqk2r/pppp1ppp/5n2/2b1p3/B2nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqk2r/pppp1ppp/5n2/2b1N3/B2nP3/2N5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2p3/3NP3/2N5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B6/3pP3/2N5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2P3/3p4/2N5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2P3/8/2p5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5P2/1B6/8/2p5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1b1kb1r/pppp1ppp/5q2/1B6/8/2p5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1b1kb1r/pppp1ppp/5q2/1B6/8/2P5/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1b1kb1r/pppp1ppp/8/1B2q3/8/2P5/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2p3/3NP3/2N5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B6/3pP3/2N5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2P3/3p4/2N5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5n2/1B2P3/8/2p5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/5P2/1B6/8/2p5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1b1kb1r/pppp1ppp/5q2/1B6/8/2p5/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1b1kb1r/pppp1ppp/5q2/1B6/8/2P5/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1b1k2r/pppp1ppp/5q2/1Bb5/8/2P5/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/1b2P3/2NP1N2/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/4P3/2bP1N2/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2Q1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/ppp2pp1/2np1n1p/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2Q1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/1b2P3/2NP1N2/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/4P3/2bP1N2/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2Q1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1b2rk1/ppp1qppp/2np1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2Q1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1b2rk1/ppp1qppp/2np1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bn1rk1/ppp1qppp/3p1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bn1rk1/ppp1qppp/3p1n2/1B2p1B1/3PP3/2P2N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r2n1rk1/ppp1qppp/3p1n2/1B2p1B1/3PP1b1/2P2N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p3/1b2P3/3P1N2/PPP1NPPP/R1BQ1RK1", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 65, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/3PP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Four Knights Game, Spanish Variation"},
{"gid": 66, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/1b2P3/2NP1N2/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp1nppp/3p1n2/1B2p1B1/1b2P3/2NP1N2/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp1nppp/3p1n2/1B2p1B1/1b2P2N/2NP4/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/pp2nppp/2pp1n2/1B2p1B1/1b2P2N/2NP4/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/pp2nppp/2pp1n2/4p1B1/1bB1P2N/2NP4/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/pp2nppp/2p2n2/3pp1B1/1bB1P2N/2NP4/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/pp2nppp/2p2n2/3pp1B1/1b2P2N/1BNP4/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1b2rk1/pp2nppp/2pq1n2/3pp1B1/1b2P2N/1BNP4/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/1b2P3/2NP1N2/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/4P3/2bP1N2/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1b2rk1/ppp1qppp/2np1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1b2rk1/ppp1qppp/2np1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bn1rk1/ppp1qppp/3p1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bn1rk1/ppp1qppp/3p1n2/1B2p1B1/3PP3/2P2N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1b2rk1/ppp1qppp/3pnn2/1B2p1B1/3PP3/2P2N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1b2rk1/ppp1qppp/3pnn2/1B2p3/3PP3/2P2N2/P1P2PPP/R1BQR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1b2rk1/pp2qppp/3pnn2/1Bp1p3/3PP3/2P2N2/P1P2PPP/R1BQR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/1b2P3/2NP1N2/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/4P3/2bP1N2/PPP2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bq1rk1/ppp2ppp/2np1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1b2rk1/ppp1qppp/2np1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2Q1RK1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1b2rk1/ppp1qppp/2np1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bn1rk1/ppp1qppp/3p1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1bn1rk1/ppp1qppp/3p1n2/1B2p1B1/3PP3/2P2N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1b2rk1/ppp1qppp/3pnn2/1B2p1B1/3PP3/2P2N2/P1P2PPP/R2QR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1b2rk1/ppp1qppp/3pnn2/1B2p3/3PP3/2P2N2/P1P2PPP/R1BQR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 66, "fen":"r1br2k1/ppp1qppp/3pnn2/1B2p3/3PP3/2P2N2/P1P2PPP/R1BQR1K1", "treename":"Four Knights Game, Symmetrical"},
{"gid": 59, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPPBPPP/R1BQK2R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2NP1/PPPP1P1P/R1BQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/4P3/2N2NP1/PPPP1P1P/R1BQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/4P3/2N2NP1/PPPP1PBP/R1BQK2R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/4P3/2N2NP1/PPPP1PBP/R1BQK2R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/4P3/2NP1NP1/PPP2PBP/R1BQK2R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqk2r/1pp2ppp/p1np1n2/2b1p3/4P3/2NP1NP1/PPP2PBP/R1BQK2R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2NP1/PPPP1P1P/R1BQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 59, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp3/4P3/2N2NP1/PPPP1P1P/R1BQKB1R", "treename":"Four Knights Game, Main Line"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r2qkbnr/pp1b1ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r2qkbnr/pp1b1ppp/2n1p3/2ppP3/3P4/2P2N2/PP2BPPP/RNBQK2R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r2qkb1r/pp1bnppp/2n1p3/2ppP3/3P4/2P2N2/PP2BPPP/RNBQK2R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r2qkbnr/pp1b1ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r2qkbnr/pp1b1ppp/2n1p3/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kb1r/pp3ppp/1qn1p2n/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r3kbnr/pp1b1ppp/1qn1p3/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/3pP3/2pP4/P1P2N2/1P3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/3pP3/2pP4/P1P2N2/1P1N1PPP/R1BQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/2PB1N2/PP3PPP/RNBQK2R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkb1r/pp2nppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/2P2N2/PP2BPPP/RNBQK2R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/3pP3/3p4/2P2N2/PP2BPPP/RNBQK2R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"r1b1kbnr/pp3ppp/1qn1p3/3pP3/3P4/5N2/PP2BPPP/RNBQK2R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/3pP3/3p4/5N2/PPP2PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/3pP3/3p4/3B1N2/PPP2PPP/RNBQK2R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnb1kbnr/pp3ppp/1q2p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnb1kbnr/pp3ppp/1q2p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rn2kbnr/pp1b1ppp/1q2p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rn2kbnr/pp1b1ppp/1q2p3/2ppP3/3P4/2P2N2/PP2BPPP/RNBQK2R", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/p1p2ppp/1p2p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/p1p2ppp/1p2p3/3pP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnb1kbnr/p1pq1ppp/1p2p3/3pP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 67, "fen":"rnbqkb1r/ppp1nppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Advance Variation"},
{"gid": 68, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/ppp1bppp/4pn2/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/pppn1ppp/4p3/3pP1b1/3P3P/2N5/PPP2PP1/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/pppn1ppp/4p3/3pP1P1/3P4/2N5/PPP2PP1/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnb1k2r/pppn1ppp/4p3/3pP1q1/3P4/2N5/PPP2PP1/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnb1k2r/pppn1ppp/4p3/3pP1q1/3P4/2N4N/PPP2PP1/R2QKB1R", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N4N/PPP2PP1/R2QKB1R", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P1N2/2N5/PPP2PP1/R2QKB1R", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/ppp1bppp/4pn2/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/pp1nbppp/4p3/2ppP1B1/3P3P/2N5/PPP2PP1/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/pp1nBppp/4p3/2ppP3/3P3P/2N5/PPP2PP1/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbq3r/pp1nkppp/4p3/2ppP3/3P3P/2N5/PPP2PP1/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/ppp1bppp/4pn2/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 68, "fen":"rnbq1rk1/pppnbppp/4p3/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR", "treename":"French Defense, Alekhine-Chatard Attack"},
{"gid": 69, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"r1bqkb1r/pppn1ppp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"r1bqkb1r/pppn1ppp/4pn2/6B1/3PN3/5N2/PPP2PPP/R2QKB1R", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"r1bqk2r/pppnbppp/4pn2/6B1/3PN3/5N2/PPP2PPP/R2QKB1R", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"r1bqk2r/pppnbppp/4pN2/6B1/3P4/5N2/PPP2PPP/R2QKB1R", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"r1bqk2r/ppp1bppp/4pn2/6B1/3P4/5N2/PPP2PPP/R2QKB1R", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqk2r/ppp1bppp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqk2r/ppp1bppp/4pB2/8/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 69, "fen":"rnbqk2r/ppp2ppp/4pb2/8/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Burn Variation"},
{"gid": 70, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnbqk2r/ppp1bppp/4pn2/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnbqk2r/pppnBppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P1P2/2N5/PPP3PP/R2QKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnb2rk1/pppnqppp/4p3/3pP3/3P1P2/2N5/PPP3PP/R2QKBNR", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnb2rk1/pppnqppp/4p3/3pP3/3P1P2/2N2N2/PPP3PP/R2QKB1R", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnb2rk1/pp1nqppp/4p3/2ppP3/3P1P2/2N2N2/PPP3PP/R2QKB1R", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"rnb2rk1/pp1nqppp/4p3/2ppP3/3P1P2/2N2N2/PPPQ2PP/R3KB1R", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"r1b2rk1/pp1nqppp/2n1p3/2ppP3/3P1P2/2N2N2/PPPQ2PP/R3KB1R", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"r1b2rk1/pp1nqppp/2n1p3/2ppP3/3P1P2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"French Defense, Classical"},
{"gid": 70, "fen":"r1b2rk1/pp1nqppp/2n1p3/3pP3/2pP1P2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"French Defense, Classical"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnBppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/1N1pP3/3P4/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pB2/8/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bp1p/4pp2/8/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bp1p/4pp2/8/3PN3/5N2/PPP2PPP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bp1p/4p3/5p2/3PN3/5N2/PPP2PPP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pB2/8/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bp1p/4pp2/8/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bp1p/4pp2/8/3PN3/5N2/PPP2PPP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/p1p1bp1p/1p2pp2/8/3PN3/5N2/PPP2PPP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/p1p1bp1p/1p2pp2/8/2BPN3/5N2/PPP2PPP/R2QK2R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pB2/8/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bp1p/4pp2/8/3PN3/8/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bp1p/4pp2/8/3PN3/5N2/PPP2PPP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/p1p1bp1p/1p2pp2/8/3PN3/5N2/PPP2PPP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/p1p1bp1p/1p2pp2/8/3PN3/3B1N2/PPP2PPP/R2QK2R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnBppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P2Q1/2N5/PPP2PPP/R3KBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnBppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N5/PPPQ1PPP/R3KBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnBppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P1P2/2N5/PPP3PP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb2rk1/pppnqppp/4p3/3pP3/3P1P2/2N5/PPP3PP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb2rk1/pppnqppp/4p3/3pP3/3P1P2/2N2N2/PPP3PP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb2rk1/pp1nqppp/4p3/2ppP3/3P1P2/2N2N2/PPP3PP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb2rk1/pp1nqppp/4p3/2ppP3/3P1P2/2N2N2/PPPQ2PP/R3KB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"r1b2rk1/pp1nqppp/2n1p3/2ppP3/3P1P2/2N2N2/PPPQ2PP/R3KB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"r1b2rk1/pp1nqppp/2n1p3/2PpP3/5P2/2N2N2/PPPQ2PP/R3KB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"r1b2rk1/pp1n1ppp/2n1p3/2qpP3/5P2/2N2N2/PPPQ2PP/R3KB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/pp1n1ppp/4p3/2PpP3/8/2N2N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnBppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P1P2/2N5/PPP3PP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/1ppnqppp/p3p3/3pP3/3P1P2/2N5/PPP3PP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/1ppnqppp/p3p3/3pP3/3P1P2/2N2N2/PPP3PP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/1p1nqppp/p3p3/2ppP3/3P1P2/2N2N2/PPP3PP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/1p1nqppp/p3p3/2PpP3/5P2/2N2N2/PPP3PP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnbppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/pppnBppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb1k2r/pppnqppp/4p3/3pP3/3P1P2/2N5/PPP3PP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb2rk1/pppnqppp/4p3/3pP3/3P1P2/2N5/PPP3PP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb2rk1/pppnqppp/4p3/3pP3/3P1P2/2N2N2/PPP3PP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb2rk1/pp1nqppp/4p3/2ppP3/3P1P2/2N2N2/PPP3PP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnb2rk1/pp1nqppp/4p3/2PpP3/5P2/2N2N2/PPP3PP/R2QKB1R", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2NB4/PPP2PPP/R1BQK1NR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4pn2/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1bppp/4p3/3pP1B1/3Pn3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 71, "fen":"rnbqk2r/ppp1Bppp/4p3/3pP3/3Pn3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, Classical Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqk2r/ppp2ppp/5n2/3p4/1bPP4/2N5/PP3PPP/R1BQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqk2r/ppp2ppp/5n2/3p4/1bPP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/2PP4/5N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqk2r/ppp1bppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqk2r/ppp1bppp/5n2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnb1kbnr/ppp2ppp/4p3/3q4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqk1nr/ppp2ppp/8/3p4/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqk1nr/ppp2ppp/8/3p4/1b1P4/2NB4/PPP2PPP/R1BQK1NR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"r1bqk1nr/ppp2ppp/2n5/3p4/1b1P4/2NB4/PPP2PPP/R1BQK1NR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqk1nr/ppp2ppp/3b4/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqk1nr/ppp2ppp/3b4/3p4/3P4/3B1N2/PPP2PPP/RNBQK2R", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqk2r/ppp2ppp/3b1n2/3p4/3P4/3B1N2/PPP2PPP/RNBQK2R", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"r1bqkbnr/ppp2ppp/2n5/3p4/3P4/3B4/PPP2PPP/RNBQK1NR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P1B2/8/PPP2PPP/RN1QKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/3B1N2/PPP2PPP/RNBQK2R", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqk1nr/ppp2ppp/3b4/3p4/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"French Defense, Exchange Variation"},
{"gid": 72, "fen":"rnbqk1nr/ppp2ppp/3b4/3p4/2PP4/5N2/PP3PPP/RNBQKB1R", "treename":"French Defense, Exchange Variation"},
{"gid": 73, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/4P3/3P4/PPPN1PPP/R1BQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/4P3/3P4/PPPN1PPP/R1BQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/4P3/3P1N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkb1r/p1p2ppp/1p2pn2/3p4/4P3/3P1N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/4P3/3P4/PPPN1PPP/R1BQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/4P3/3P4/PPPN1PPP/R1BQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/4P3/3P4/PPP1QPPP/RNB1KBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/4P3/3P4/PPP1QPPP/RNB1KBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/4P3/3P4/PPP2PPP/RNBQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/4P3/3P4/PPPN1PPP/R1BQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/4P3/3P4/PPPN1PPP/R1BQKBNR", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/4P3/3P1N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3p4/4P3/3P1N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, King's Indian Attack"},
{"gid": 73, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3p4/4P3/2PP1N2/PP1N1PPP/R1BQKB1R", "treename":"French Defense, King's Indian Attack"},
{"gid": 74, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1b1PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P3B/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1b1PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1b1PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/3P4/2b5/PPPB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/3P4/2P5/P1PB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4p2p/3pP3/3Pn3/2P5/P1PB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4p2p/3pP3/3Pn1Q1/2P5/P1PB1PPP/R3KBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbq1k1r/ppp2pp1/4p2p/3pP3/3Pn1Q1/2P5/P1PB1PPP/R3KBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbq1k1r/ppp2pp1/4p2p/3pP3/3Pn1Q1/2P5/P1P2PPP/R1B1KBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1b1PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P4/2N1B3/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1b1PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/3P4/2b5/PPPB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/3P4/2P5/P1PB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4p2p/3pP3/3Pn3/2P5/P1PB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4p2p/3pP3/3Pn1Q1/2P5/P1PB1PPP/R3KBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2p2/4p1pp/3pP3/3Pn1Q1/2P5/P1PB1PPP/R3KBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2p2/4p1pp/3pP3/3Pn1Q1/2PB4/P1PB1PPP/R3K1NR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2p2/4p1pp/3pP3/3P2Q1/2PB4/P1Pn1PPP/R3K1NR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2p2/4p1pp/3pP3/3P2Q1/2PB4/P1PK1PPP/R5NR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/pp3p2/4p1pp/2ppP3/3P2Q1/2PB4/P1PK1PPP/R5NR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/pp3p2/4p1pp/2ppP3/3P2Q1/2PB1N2/P1PK1PPP/R6R", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"r1bqk2r/pp3p2/2n1p1pp/2ppP3/3P2Q1/2PB1N2/P1PK1PPP/R6R", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1b1PP3/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2ppp/4pn2/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/3P4/2b5/PPPB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4pn1p/3pP3/3P4/2P5/P1PB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4p2p/3pP3/3Pn3/2P5/P1PB1PPP/R2QKBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbqk2r/ppp2pp1/4p2p/3pP3/3Pn1Q1/2P5/P1PB1PPP/R3KBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbq1k1r/ppp2pp1/4p2p/3pP3/3Pn1Q1/2P5/P1PB1PPP/R3KBNR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 74, "fen":"rnbq1k1r/ppp2pp1/4p2p/3pP3/3Pn1Q1/2PB4/P1PB1PPP/R3K1NR", "treename":"French Defense, MacCutcheon Variation"},
{"gid": 75, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"rnbqk1nr/ppp1bppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Paulsen Variation"},
{"gid": 75, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3p4/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Paulsen Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkbnr/pppn1ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkbnr/pppn1ppp/4p3/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqk1nr/pppnbppp/4p3/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkbnr/pppn1ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkbnr/pppn1ppp/4p3/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/3PN3/3B1N2/PPP2PPP/R1BQK2R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkbnr/pppn1ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkbnr/pppn1ppp/4p3/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/pppn1ppp/4pN2/8/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/ppp2ppp/4pn2/8/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/ppp2ppp/4pn2/6B1/3P4/5N2/PPP2PPP/R2QKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkbnr/pppn1ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkbnr/pppn1ppp/4p3/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/pppn1ppp/4pN2/8/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/ppp2ppp/4pn2/8/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/ppp2ppp/4pn2/8/3P4/3B1N2/PPP2PPP/R1BQK2R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/pp3ppp/4pn2/2p5/3P4/3B1N2/PPP2PPP/R1BQK2R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/pp3ppp/4pn2/2P5/8/3B1N2/PPP2PPP/R1BQK2R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkbnr/pppn1ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkbnr/pppn1ppp/4p3/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/pppn1ppp/4pN2/8/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/ppp2ppp/4pn2/8/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r1bqkb1r/ppp2ppp/4pn2/4N3/3P4/8/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rn1qkbnr/pppb1ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rn1qkbnr/pppb1ppp/4p3/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rn1qkbnr/ppp2ppp/2b1p3/8/3PN3/5N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rn1qkbnr/ppp2ppp/2b1p3/8/3PN3/3B1N2/PPP2PPP/R1BQK2R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r2qkbnr/pppn1ppp/2b1p3/8/3PN3/3B1N2/PPP2PPP/R1BQK2R", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r2qkbnr/pppn1ppp/2b1p3/8/3PN3/3B1N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r2qkb1r/pppn1ppp/2b1pn2/8/3PN3/3B1N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"r2qkb1r/pppn1ppp/2b1pn2/8/3P4/3B1NN1/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 76, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/3PN3/8/PPP2PPP/R1BQKBNR", "treename":"French Defense, Rubinstein Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P1P2/2N5/PPP3PP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2N5/PPP3PP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2N2N2/PPP3PP/R1BQKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2N2N2/PPP3PP/R1BQKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2N1BN2/PPP3PP/R2QKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/1p1n1ppp/p1n1p3/2ppP3/3P1P2/2N1BN2/PPP3PP/R2QKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/1p1n1ppp/p1n1p3/2ppP3/3P1P2/2N1BN2/PPPQ2PP/R3KB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/3n1ppp/p1n1p3/1pppP3/3P1P2/2N1BN2/PPPQ2PP/R3KB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P1P2/2N5/PPP3PP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2N5/PPP3PP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2N2N2/PPP3PP/R1BQKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2N2N2/PPP3PP/R1BQKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2N1BN2/PPP3PP/R2QKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3p1P2/2N1BN2/PPP3PP/R2QKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3N1P2/2N1B3/PPP3PP/R2QKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqk2r/pp1n1ppp/2n1p3/2bpP3/3N1P2/2N1B3/PPP3PP/R2QKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P1P2/2N5/PPP3PP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2N5/PPP3PP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2N2N2/PPP3PP/R1BQKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2N2N2/PPP3PP/R1BQKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2N1BN2/PPP3PP/R2QKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3p1P2/2N1BN2/PPP3PP/R2QKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3N1P2/2N1B3/PPP3PP/R2QKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/3pP3/3N1P2/2N1B3/PPP3PP/R2QKB1R", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 77, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P2Q1/2N5/PPP2PPP/R1B1KBNR", "treename":"French Defense, Steinitz Variation"},
{"gid": 78, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnb1kbnr/pp3ppp/4p3/2pq4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnb1kbnr/pp3ppp/4p3/2pq4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnb1kbnr/pp3ppp/4p3/3q4/3p4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnb1kbnr/pp3ppp/4p3/3q4/2Bp4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/4p3/8/2Bp4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/8/1Bpp4/3P4/8/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rn1qkbnr/pp1b1ppp/8/1Bpp4/3P4/8/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rn1qkbnr/pp1b1ppp/8/1Bpp4/3P4/8/PPPNQPPP/R1B1K1NR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rn1qk1nr/pp1bbppp/8/1Bpp4/3P4/8/PPPNQPPP/R1B1K1NR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/8/1Bpp4/3P4/8/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"r1bqkbnr/pp3ppp/2n5/1Bpp4/3P4/8/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"r1bqkbnr/pp3ppp/2n5/1Bpp4/3P4/8/PPPNQPPP/R1B1K1NR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkb1r/pp3ppp/5n2/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkb1r/pp3ppp/5n2/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rn1qkb1r/pp1b1ppp/5n2/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rn1qkb1r/pp1B1ppp/5n2/2pp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"r2qkb1r/pp1n1ppp/5n2/2pp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"r2qkb1r/pp1n1ppp/5n2/2pp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"r2qk2r/pp1nbppp/5n2/2pp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"r2qk2r/pp1nbppp/5n2/2Pp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"r2qk2r/pp2bppp/5n2/2np4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"r2qk2r/pp2bppp/5n2/2np4/8/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/1p3ppp/p7/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch"},
{"gid": 78, "fen":"rnbqkbnr/1p3ppp/p7/2pp4/3P4/5N2/PPPNBPPP/R1BQK2R", "treename":"French Defense, Tarrasch"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/p2n1ppp/1p2p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/2pq4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/2pq4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/3q4/3p4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/3q4/2Bp4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/3qp3/8/2Bp4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/3qp3/8/2Bp4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kb1r/pp3ppp/3qpn2/8/2Bp4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kb1r/pp3ppp/3qpn2/8/2Bp4/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/2nqpn2/8/2Bp4/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/2nqpn2/8/2BN4/5N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/3qpn2/8/2Bn4/5N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/3qpn2/8/2BN4/8/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/1p3ppp/p2qpn2/8/2BN4/8/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/1p3ppp/p2qpn2/8/2BN4/2P5/PP3PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/2pq4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/2pq4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/3q4/3p4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/3q4/2Bp4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/3qp3/8/2Bp4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/3qp3/8/2Bp4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kb1r/pp3ppp/3qpn2/8/2Bp4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kb1r/pp3ppp/3qpn2/8/2Bp4/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/2nqpn2/8/2Bp4/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/2nqpn2/8/2BN4/5N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/3qpn2/8/2Bn4/5N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/3qpn2/8/2BN4/8/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/1p3ppp/p2qpn2/8/2BN4/8/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/1p3ppp/p2qpn2/8/2BN4/8/PPP2PPP/R1BQR1K1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/2pq4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/2pq4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/3q4/3p4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/4p3/3q4/2Bp4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/3qp3/8/2Bp4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kbnr/pp3ppp/3qp3/8/2Bp4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kb1r/pp3ppp/3qpn2/8/2Bp4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnb1kb1r/pp3ppp/3qpn2/8/2Bp4/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/2nqpn2/8/2Bp4/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/2nqpn2/8/2BN4/5N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/3qpn2/8/2Bn4/5N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/3qpn2/8/2BQ4/5N2/PPP2PPP/R1B2RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp3ppp/4pn2/8/2Bq4/5N2/PPP2PPP/R1B2RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3p4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/3pP3/3P4/3B1N2/PP2NPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n2pp/1qn1pp2/3pP3/3P4/3B1N2/PP2NPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3p4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n2pp/2n1pp2/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n2pp/2n1pp2/3pP3/3P1N2/3B4/PP1N1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3p4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n2pp/2n1pp2/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n2pp/2n1pP2/3p4/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp4pp/2n1pn2/3p4/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp4pp/2n1pn2/3p4/3P4/3B4/PP1NNPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp4pp/2nbpn2/3p4/3P4/3B4/PP1NNPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp4pp/2nbpn2/3p4/3P4/3B1N2/PP2NPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1k2r/pp4pp/1qnbpn2/3p4/3P4/3B1N2/PP2NPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1k2r/pp4pp/1qnbpn2/3p4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3p4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n2pp/2n1pp2/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n2pp/2n1pP2/3p4/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp4pp/2n1pn2/3p4/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp4pp/2n1pn2/3p4/3P4/3B4/PP1NNPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp4pp/2nbpn2/3p4/3P4/3B4/PP1NNPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp4pp/2nbpn2/3p4/3P4/3B1N2/PP2NPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bq1rk1/pp4pp/2nbpn2/3p4/3P4/3B1N2/PP2NPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bq1rk1/pp4pp/2nbpn2/3p4/3P1B2/3B1N2/PP2NPPP/R2Q1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3p4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n2pp/2n1pp2/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n2pp/2n1pP2/3p4/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp4pp/2n1pn2/3p4/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp4pp/2n1pn2/3p4/3P4/3B4/PP1NNPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp4pp/2nbpn2/3p4/3P4/3B4/PP1NNPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp4pp/2nbpn2/3p4/3P4/3B1N2/PP2NPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1k2r/ppq3pp/2nbpn2/3p4/3P4/3B1N2/PP2NPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1k2r/ppq3pp/2nbpn2/3p2B1/3P4/3B1N2/PP2NPPP/R2Q1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/1p1n1ppp/2n1p3/p1ppP3/3P4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB1N2/PP1N1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp1nbppp/2n1p3/2ppP3/3P4/2PB1N2/PP1N1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp1nbppp/2n1p3/2ppP3/3P4/2PB1N2/PP1N1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp1nbp1p/2n1p3/2ppP1p1/3P4/2PB1N2/PP1N1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB1N2/PP3PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/2n1p3/q1ppP3/3P4/2PB1N2/PP3PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB1N2/PP1N1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/2ppP3/3P4/2PB1N2/PP1N1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/2ppP3/3P4/2PB1N2/PP1N1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/2P5/PP1N1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3pP3/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pppn1ppp/2n1p3/3pP3/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pppn1ppp/2n1p3/3pP3/3P4/1N3N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pppnbppp/2n1p3/3pP3/3P4/1N3N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3pP3/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pppn1ppp/2n1p3/3pP3/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pppn1ppp/2n1p3/3pP3/3P4/1N3N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/1ppn1ppp/2n1p3/p2pP3/3P4/1N3N2/PPP2PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3pP3/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pppn1ppp/2n1p3/3pP3/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pppn1ppp/2n1p3/3pP3/3P4/5N2/PPPNBPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pppn2pp/2n1pp2/3pP3/3P4/5N2/PPPNBPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3pP3/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pppn1ppp/2n1p3/3pP3/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pppn1ppp/2n1p3/1B1pP3/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/3pP3/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pppn1ppp/2n1p3/3pP3/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pppn1ppp/2n1p3/3pP3/3P4/2P2N2/PP1N1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/2P5/PP1N1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/ppp2ppp/2n5/3pp3/3PP3/2P5/PP1N1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp3pp/4p3/3p1p2/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3p4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp3ppp/1nn1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp3ppp/1nn1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r2qkb1r/pp1b1ppp/1nn1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3p4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp3ppp/1nn1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp3ppp/1nn1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/1p3ppp/1nn1p3/p2pP3/3P4/3B4/PP1NNPPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3p4/2PB4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp3ppp/1nn1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp3ppp/1nn1p3/3pP3/3P4/3B1N2/PP2NPPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/1pp2ppp/p3p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/1pp2ppp/p3p3/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqk1nr/ppp1bppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqk1nr/ppp1bppp/4p3/3p4/3PP3/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqk1nr/pp2bppp/4p3/2pp4/3PP3/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqk1nr/pp2bppp/4p3/2Pp4/4P3/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqk2r/pp2bppp/4pn2/2Pp4/4P3/3B4/PPPN1PPP/R1BQK1NR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqk1nr/ppp1bppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqk1nr/ppp1bppp/4p3/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/3PP3/3B1N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n1p3/2pp4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/1p3ppp/p3p3/2pp4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/3p4/3pP3/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1BPp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/1bn5/1B1p4/8/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/1B1p4/3p4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1BPp4/8/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2n5/1Bbp4/8/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2n5/1Bbp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1BPp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/2P2N2/PP1N1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bq1rk1/pp2nppp/2n5/1Bbp4/8/2P2N2/PP1N1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bq1rk1/pp2nppp/2n5/1Bbp4/8/1NP2N2/PP3PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bq1rk1/pp2nppp/2nb4/1B1p4/8/1NP2N2/PP3PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1BPp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1B1p4/8/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1B1p4/8/1N3N2/PPP2PPP/R1BQR1K1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bq1rk1/pp2nppp/2nb4/1B1p4/8/1N3N2/PPP2PPP/R1BQR1K1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bq1rk1/pp2nppp/2nb4/1B1p2B1/8/1N3N2/PPP2PPP/R2QR1K1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r2q1rk1/pp2nppp/2nb4/1B1p2B1/6b1/1N3N2/PPP2PPP/R2QR1K1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1BPp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1B1p4/8/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1B1p2B1/8/1N3N2/PPP2PPP/R2Q1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1BPp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1B1p4/8/1N3N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1B1p4/3N4/5N2/PPP2PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkbnr/pp3ppp/2n5/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQK2R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk1nr/pp3ppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1Bpp4/3P4/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2nb4/1BPp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/5N2/PPPN1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqk2r/pp2nppp/2n5/1Bbp4/8/2P2N2/PP1N1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bq1rk1/pp2nppp/2n5/1Bbp4/8/2P2N2/PP1N1PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bq1rk1/pp2nppp/2n5/1Bbp4/8/1NP2N2/PP3PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bq1rk1/pp2nppp/1bn5/1B1p4/8/1NP2N2/PP3PPP/R1BQ1RK1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bq1rk1/pp2nppp/1bn5/1B1p4/8/1NP2N2/PP3PPP/R1BQR1K1", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/2P5/PP1N1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pp3ppp/4p3/2Pp4/4P3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P1P2/8/PPPN2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/8/PPPN2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2P5/PP1N2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2P5/PP1N2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2P2N2/PP4PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3p1P2/2P2N2/PP4PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P1P2/8/PPPN2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/8/PPPN2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2P5/PP1N2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2P5/PP1N2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2P2N2/PP4PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/2ppP3/3P1P2/2P2N2/PP4PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/2ppP3/3P1P2/2P2NP1/PP5P/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/3pP3/3p1P2/2P2NP1/PP5P/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/3pP3/3P1P2/5NP1/PP5P/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P1P2/8/PPPN2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/8/PPPN2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2P5/PP1N2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2P5/PP1N2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2P2N2/PP4PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/2ppP3/3P1P2/2P2N2/PP4PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/2ppP3/3P1P1P/2P2N2/PP4P1/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/3pP3/3p1P1P/2P2N2/PP4P1/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/3pP3/3P1P1P/5N2/PP4P1/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P4/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pppn1ppp/4p3/3pP3/3P1P2/8/PPPN2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/8/PPPN2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2P5/PP1N2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2P5/PP1N2PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2P2N2/PP4PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/2ppP3/3P1P2/2P2N2/PP4PP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/2ppP3/3P1P2/2P2N2/PP2N1PP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/3pP3/3p1P2/2P2N2/PP2N1PP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"r1b1kb1r/pp1n1ppp/1qn1p3/3pP3/3P1P2/5N2/PP2N1PP/R1BQKB1R", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/p1p2ppp/1p2p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 79, "fen":"rnbqkbnr/ppp2ppp/4p3/8/3Pp3/8/PPPN1PPP/R1BQKBNR", "treename":"French Defense, Tarrasch Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P2Q1/2N5/PPP2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/1b1P2Q1/2N5/PPP2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2PpP3/1b4Q1/2N5/PPP2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/p1p2ppp/1p2p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/p1p2ppp/1p2p3/3pP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/p1p2ppp/1p2p3/3pP3/3P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/pp3ppp/4p3/q1ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp1nppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp1nppp/4p3/3pP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/p1p1nppp/1p2p3/3pP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/P2P4/2P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k2r/pp2nppp/4p3/q1ppP3/P2P4/2P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2PpP3/1b6/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P3P/P1P5/2P2PP1/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bqk2r/pp2nppp/2n1p3/2ppP3/3P3P/P1P5/2P2PP1/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bqk2r/pp2nppp/2n1p3/2ppP3/3P3P/P1P2N2/2P2PP1/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P3P/P1P5/2P2PP1/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k2r/ppq1nppp/4p3/2ppP3/3P3P/P1P5/2P2PP1/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k2r/ppq1nppp/4p3/2ppP3/3P3P/P1P2N2/2P2PP1/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/p1p2ppp/1p2p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/p1p2ppp/1p2p3/3pP3/1b1P2Q1/2N5/PPP2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/p1p2ppp/1p2p3/3pP3/3P2Q1/2N5/PPP2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/p1p2ppp/1p2p3/3pP1B1/3P2Q1/2N5/PPP2PPP/R3KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp1nppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp1nppp/4p3/3pP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp1nppp/4p3/3pP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp1nppp/4p3/3pP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/p1p1nppp/1p2p3/3pP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/p1p1nppp/1p2p3/3pP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/1b1Pp3/2N5/PPP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/1b1Pp3/P1N5/1PP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/3Pp3/P1b5/1PP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/3Pp3/P1N5/1PP2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bqk1nr/ppp2ppp/2n1p3/8/3Pp3/P1N5/1PP2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bqk1nr/ppp2ppp/2n1p3/1B6/3Pp3/P1N5/1PP2PPP/R1BQK2R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1b1PP3/2N5/PPP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bqk1nr/ppp2ppp/2n1p3/3p4/1b1PP3/2N5/PPP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/1b1Pp3/2N5/PPP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/1b1Pp3/P1N5/1PP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp1bppp/4p3/8/3Pp3/P1N5/1PP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp1bppp/4p3/8/3PN3/P7/1PP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp1bppp/4pn2/8/3PN3/P7/1PP1NPPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp1bppp/4pn2/8/3PN3/P2Q4/1PP1NPPP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/3pP3/1b1p4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2PpP3/1b6/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/1NppP3/1b1P4/8/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/1b1P4/P1N5/1PPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/ppq2ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/ppq2ppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/ppq3pp/4pp2/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/ppq2ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/ppq2ppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/ppq3pp/4p3/2ppPp2/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/8/3p4/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/8/3p4/1b1P4/2NB4/PPP2PPP/R1BQK1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp1nppp/8/3p4/1b1P4/2NB4/PPP2PPP/R1BQK1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp1nppp/8/3p3Q/1b1P4/2NB4/PPP2PPP/R1B1K1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/1b1Pp3/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/1b1Pp1Q1/2N5/PPPB1PPP/R3KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/ppp2ppp/4p3/8/1b1qp1Q1/2N5/PPPB1PPP/R3KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/1b1Pp3/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/1b1Pp1Q1/2N5/PPPB1PPP/R3KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp2ppp/4pn2/8/1b1Pp1Q1/2N5/PPPB1PPP/R3KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2NB4/PPP2PPP/R1BQK1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2pp4/1b1PP3/2NB4/PPP2PPP/R1BQK1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2pP4/1b1P4/2NB4/PPP2PPP/R1BQK1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/pp3ppp/4p3/2pq4/1b1P4/2NB4/PPP2PPP/R1BQK1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/pp3ppp/4p3/2pq4/1b1P4/2NB4/PPPB1PPP/R2QK1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/pppq1ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/pppq1ppp/4p3/3pP3/1b1P4/2N5/PPPB1PPP/R2QKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/pppq1ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/pppq1ppp/4p3/3pP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/pppq1ppp/4p3/3pP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/pppq1ppp/4p3/3pP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/p1pq1ppp/1p2p3/3pP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1nr/p1pq1ppp/1p2p3/3pP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k2r/ppq1nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k2r/ppq1npQp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1r1/ppq1npQp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1r1/ppq1np1Q/4p3/2ppP3/3P4/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1r1/ppq1np1Q/4p3/3pP3/3p4/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1r1/ppq1np1Q/4p3/3pP3/3p4/P1P5/2P1NPPP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1b1k1r1/ppq1np1Q/2n1p3/3pP3/3p4/P1P5/2P1NPPP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1b1k1r1/ppq1np1Q/2n1p3/3pP3/3p1P2/P1P5/2P1N1PP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r3k1r1/ppqbnp1Q/2n1p3/3pP3/3p1P2/P1P5/2P1N1PP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r3k1r1/ppqbnp2/2n1p3/3pP3/3p1P2/P1PQ4/2P1N1PP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r3k1r1/ppqbnp2/2n1p3/3pP3/5P2/P1pQ4/2P1N1PP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r3k1r1/ppqbnp2/2n1p3/3pP3/5P2/P1NQ4/2P3PP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k2r/ppq1nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k2r/ppq1npQp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1r1/ppq1npQp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1r1/ppq1np1Q/4p3/2ppP3/3P4/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1r1/ppq1np1Q/4p3/3pP3/3p4/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k1r1/ppq1np1Q/4p3/3pP3/3p4/P1P5/2P2PPP/R1BK1BNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbq1rk1/pp2nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbq1rk1/pp2nppp/4p3/2ppP3/3P2Q1/P1PB4/2P2PPP/R1B1K1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bq1rk1/pp2nppp/2n1p3/2ppP3/3P2Q1/P1PB4/2P2PPP/R1B1K1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbq1rk1/pp2nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbq1rk1/pp2nppp/4p3/2ppP3/3P2Q1/P1P2N2/2P2PPP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bq1rk1/pp2nppp/2n1p3/2ppP3/3P2Q1/P1P2N2/2P2PPP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbq1k1r/pp2nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/3pP3/3p2Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bqk2r/pp2nppp/2n1p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bqk2r/pp2nppp/2n1p3/2ppP3/P2P4/2P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r2qk2r/pp1bnppp/2n1p3/2ppP3/P2P4/2P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k2r/ppq1nppp/4p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k2r/ppq1nppp/4p3/2ppP3/P2P4/2P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k2r/p1q1nppp/1p2p3/2ppP3/P2P4/2P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnb1k2r/pp2nppp/4p3/q1ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bqk2r/pp2nppp/2n1p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bqk2r/pp2nppp/2n1p3/2ppP3/P2P4/2P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1b1k2r/pp2nppp/2n1p3/q1ppP3/P2P4/2P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1b1k2r/pp2nppp/2n1p3/q1ppP3/P2P4/2P2N2/2PQ1PPP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r3k2r/pp1bnppp/2n1p3/q1ppP3/P2P4/2P2N2/2PQ1PPP/R1B1KB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/p3nppp/1p2p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/p3nppp/1p2p3/2ppP3/P2P4/2P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/p3nppp/1p2p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/p3nppp/1p2p3/1BppP3/3P4/P1P2N2/2P2PPP/R1BQK2R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bqk2r/pp2nppp/2n1p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1bqk2r/pp2nppp/2n1p3/2ppP3/P2P4/2P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1b1k2r/pp2nppp/2n1p3/q1ppP3/P2P4/2P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r1b1k2r/pp2nppp/2n1p3/q1ppP3/P2P4/2P2N2/2PB1PPP/R2QKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r3k2r/pp1bnppp/2n1p3/q1ppP3/P2P4/2P2N2/2PB1PPP/R2QKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"r3k2r/pp1bnppp/2n1p3/qBppP3/P2P4/2P2N2/2PB1PPP/R2QK2R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rn1qk2r/pp1bnppp/4p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rn1qk2r/pp1bnppp/4p3/2ppP3/P2P4/2P2N2/2P2PPP/R1BQKB1R", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/b1ppP3/3P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/b1ppP3/1P1P4/P1N5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/b2pP3/1P1p4/P1N5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/bN1pP3/1P1p4/P7/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppb2ppp/4p3/1N1pP3/1P1p4/P7/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppb2ppp/4p3/1N1pP3/1P1p1P2/P7/2P3PP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rn1qk1nr/ppbb1ppp/4p3/1N1pP3/1P1p1P2/P7/2P3PP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/b1ppP3/3P4/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/b1ppP3/1P1P4/P1N5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/b2pP3/1P1p4/P1N5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/b2pP3/1P1p2Q1/P1N5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/b2pP3/1P1p2Q1/P1N5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/pp2nppp/4p3/P2pP3/3p2Q1/P1N5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/3PP3/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/3PP3/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/3Pp3/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/3Pp1Q1/P1P5/2P2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/P1N5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/3PP3/P1b5/1PP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/3PP3/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/3Pp3/P1P5/2P2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/3Pp3/P1P2P2/2P3PP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/pp3ppp/4p3/2p5/3Pp3/P1P2P2/2P3PP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP1Q1/2N5/PPP2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2NB4/PPP2PPP/R1BQK1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/1b1Pp3/2NB4/PPP2PPP/R1BQK1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/1b1PB3/2N5/PPP2PPP/R1BQK1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk2r/ppp2ppp/4pn2/8/1b1PB3/2N5/PPP2PPP/R1BQK1NR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2NQ4/PPP2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 80, "fen":"rnbqk1nr/ppp2ppp/4p3/8/1b1Pp3/2NQ4/PPP2PPP/R1B1KBNR", "treename":"French Defense, Winawer Variation"},
{"gid": 81, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Wing Gambit"},
{"gid": 81, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"French Defense, Wing Gambit"},
{"gid": 81, "fen":"rnbqkbnr/pppp1ppp/4p3/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"French Defense, Wing Gambit"},
{"gid": 81, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"French Defense, Wing Gambit"},
{"gid": 81, "fen":"rnbqkbnr/ppp2ppp/4p3/3pP3/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"French Defense, Wing Gambit"},
{"gid": 81, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"French Defense, Wing Gambit"},
{"gid": 81, "fen":"rnbqkbnr/pp3ppp/4p3/2ppP3/1P6/5N2/P1PP1PPP/RNBQKB1R", "treename":"French Defense, Wing Gambit"},
{"gid": 82, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Giuoco Piano, Main Line"},
{"gid": 82, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Giuoco Piano, Main Line"},
{"gid": 82, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Giuoco Piano, Main Line"},
{"gid": 82, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Giuoco Piano, Main Line"},
{"gid": 82, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Giuoco Piano, Main Line"},
{"gid": 82, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Giuoco Piano, Main Line"},
{"gid": 82, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Giuoco Piano, Main Line"},
{"gid": 82, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Giuoco Piano, Main Line"},
{"gid": 82, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Giuoco Piano, Main Line"},
{"gid": 83, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQ1RK1", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bq1rk1/ppp2ppp/2np1n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQ1RK1", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/1ppp1ppp/p1n2n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1P3/2PP1N2/PP1N1PPP/R1BQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2BPP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b5/2BpP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1P3/2Bp4/2P2N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/ppp2ppp/2n2n2/2bpP3/2Bp4/2P2N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/ppp2ppp/2n2n2/1BbpP3/3p4/2P2N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/ppp2ppp/2n5/1BbpP3/3pn3/2P2N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/ppp2ppp/2n5/1BbpP3/3Pn3/5N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk2r/ppp2ppp/2n5/1B1pP3/1b1Pn3/5N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/ppp2ppp/2np4/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/ppp2ppp/2np4/2b1p3/2BPP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/ppp2ppp/2np4/2b5/2BpP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/ppp2ppp/2np4/2b5/2BPP3/5N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 83, "fen":"r1bqk1nr/ppp2ppp/1bnp4/8/2BPP3/5N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Classical Variation"},
{"gid": 84, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/2P2N2/P2P1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/ppppbppp/2n5/4p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/ppppbppp/2n5/4p3/2BPP3/2P2N2/P4PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/ppppbppp/8/n3p3/2BPP3/2P2N2/P4PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/2P2N2/P2P1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/ppppbppp/2n5/4p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/ppppbppp/2n5/4p3/2B1P3/1QP2N2/P2P1PPP/RNB1K2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/2P2N2/P2P1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/b3p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/b3p3/2B1P3/1QP2N2/P2P1PPP/RNB1K2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/2P2N2/P2P1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2nb4/4p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/2P2N2/P2P1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/b3p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/pppp1ppp/2n5/b3p3/2BPP3/2P2N2/P4PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/ppp2ppp/2np4/b3p3/2BPP3/2P2N2/P4PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1bqk1nr/ppp2ppp/2np4/b3p3/2BPP3/1QP2N2/P4PPP/RNB1K2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1b1k1nr/pppq1ppp/2np4/b3p3/2BPP3/1QP2N2/P4PPP/RNB1K2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1b1k1nr/pppq1ppp/2np4/b3P3/2B1P3/1QP2N2/P4PPP/RNB1K2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 84, "fen":"r1b1k1nr/pppq1ppp/1bnp4/4P3/2B1P3/1QP2N2/P4PPP/RNB1K2R", "treename":"Italian Game, Evans Gambit"},
{"gid": 85, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit Declined"},
{"gid": 85, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Evans Gambit Declined"},
{"gid": 85, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit Declined"},
{"gid": 85, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Evans Gambit Declined"},
{"gid": 85, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit Declined"},
{"gid": 85, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit Declined"},
{"gid": 85, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit Declined"},
{"gid": 85, "fen":"r1bqk1nr/pppp1ppp/1bn5/4p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit Declined"},
{"gid": 85, "fen":"r1bqk1nr/pppp1ppp/1bn5/4p3/PPB1P3/5N2/2PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit Declined"},
{"gid": 85, "fen":"r1bqk1nr/1ppp1ppp/pbn5/4p3/PPB1P3/5N2/2PP1PPP/RNBQK2R", "treename":"Italian Game, Evans Gambit Declined"},
{"gid": 85, "fen":"r1bqk1nr/1ppp1ppp/pbn5/4p3/PPB1P3/2N2N2/2PP1PPP/R1BQK2R", "treename":"Italian Game, Evans Gambit Declined"},
{"gid": 86, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2NP1N2/PPP2PPP/R1BQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1P3/2NP1N2/PPP2PPP/R1BQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p1B1/2B1P3/2NP1N2/PPP2PPP/R2QK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/ppp2pp1/2np1n1p/2b1p1B1/2B1P3/2NP1N2/PPP2PPP/R2QK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2NP1N2/PPP2PPP/R1BQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1P3/2NP1N2/PPP2PPP/R1BQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1P3/2NPBN2/PPP2PPP/R2QK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 86, "fen":"r1bqk2r/ppp2ppp/2np1n2/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Giuoco Pianissimo"},
{"gid": 87, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Giuoco Piano"},
{"gid": 87, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Giuoco Piano"},
{"gid": 87, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Giuoco Piano"},
{"gid": 87, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Giuoco Piano"},
{"gid": 87, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Giuoco Piano"},
{"gid": 87, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Giuoco Piano"},
{"gid": 87, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Giuoco Piano"},
{"gid": 87, "fen":"r1b1k1nr/ppppqppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Giuoco Piano"},
{"gid": 87, "fen":"r1b1k1nr/ppppqppp/2n5/2b1p3/2BPP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Giuoco Piano"},
{"gid": 87, "fen":"r1b1k1nr/ppppqppp/1bn5/4p3/2BPP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Giuoco Piano"},
{"gid": 87, "fen":"r1b1k1nr/ppppqppp/1bn5/4p1B1/2BPP3/2P2N2/PP3PPP/RN1QK2R", "treename":"Italian Game, Giuoco Piano"},
{"gid": 88, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Hungarian Defense"},
{"gid": 88, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Hungarian Defense"},
{"gid": 88, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Hungarian Defense"},
{"gid": 88, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Hungarian Defense"},
{"gid": 88, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Hungarian Defense"},
{"gid": 88, "fen":"r1bqk1nr/ppppbppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Hungarian Defense"},
{"gid": 88, "fen":"r1bqk1nr/ppppbppp/2n5/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Hungarian Defense"},
{"gid": 88, "fen":"r1bqk1nr/ppp1bppp/2np4/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Hungarian Defense"},
{"gid": 90, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Schilling-Kostic Gambit"},
{"gid": 90, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Schilling-Kostic Gambit"},
{"gid": 90, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Schilling-Kostic Gambit"},
{"gid": 90, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Schilling-Kostic Gambit"},
{"gid": 90, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Schilling-Kostic Gambit"},
{"gid": 90, "fen":"r1bqkbnr/pppp1ppp/8/1B2p3/3nP3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Schilling-Kostic Gambit"},
{"gid": 90, "fen":"r1bqkbnr/pppp1ppp/8/4p3/2BnP3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Schilling-Kostic Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/ppp2ppp/2n5/3p4/2Bpn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/ppp2ppp/2n5/3B4/3pn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/3q4/3pn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/3q4/3pn3/2N2N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/q7/3pn3/2N2N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/q7/3pN3/5N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r3kb1r/ppp2ppp/2n1b3/q7/3pN3/5N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r3kb1r/ppp2ppp/2n1b3/q5N1/3p4/5N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"2kr1b1r/ppp2ppp/2n1b3/q5N1/3p4/5N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"2kr1b1r/ppp2ppp/2n1N3/q7/3p4/5N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"2kr1b1r/ppp3pp/2n1p3/q7/3p4/5N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"2kr1b1r/ppp3pp/2n1R3/q7/3p4/5N2/PPP2PPP/R1BQ2K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"2kr3r/ppp3pp/2nbR3/q7/3p4/5N2/PPP2PPP/R1BQ2K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/ppp2ppp/2n5/3p4/2Bpn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/ppp2ppp/2n5/3B4/3pn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/3q4/3pn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/3q4/3pn3/2N2N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/q7/3pn3/2N2N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/q7/3pN3/5N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r3kb1r/ppp2ppp/2n1b3/q7/3pN3/5N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r3kb1r/ppp2ppp/2n1b3/q7/3pN3/5N2/PPPB1PPP/R2QR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r3kb1r/ppp2ppp/2n1b3/5q2/3pN3/5N2/PPPB1PPP/R2QR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/ppp2ppp/2n5/3p4/2Bpn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/ppp2ppp/2n5/3B4/3pn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/3q4/3pn3/5N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/3q4/3pn3/2N2N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/7q/3pn3/2N2N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1b1kb1r/ppp2ppp/2n5/7q/3pN3/5N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r3kb1r/ppp2ppp/2n1b3/7q/3pN3/5N2/PPP2PPP/R1BQR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r3kb1r/ppp2ppp/2n1b3/6Bq/3pN3/5N2/PPP2PPP/R2QR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r3k2r/ppp2ppp/2nbb3/6Bq/3pN3/5N2/PPP2PPP/R2QR1K1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 91, "fen":"r1bqkb1r/ppp2ppp/2np1n2/8/2BpP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Scotch Gambit"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2p2n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2P2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/nB2p1N1/8/5Q2/PPPP1PPP/RNB1K2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"1rbqkb1r/p4ppp/2p2n2/nB2p1N1/8/5Q2/PPPP1PPP/RNB1K2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/3Pp1N1/2Bn4/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/3Pp1N1/2Bn4/2P5/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p1p2ppp/5n2/1p1Pp1N1/2Bn4/2P5/PP1P1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p1p2ppp/5n2/1p1Pp1N1/3n4/2P5/PP1P1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p1p2ppp/8/1p1np1N1/3n4/2P5/PP1P1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p1p2ppp/8/1p1np1N1/3P4/8/PP1P1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1pp1/2n2n1p/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/ppppbppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/ppppbppp/2n2n2/4p3/4P3/1B1P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bq1rk1/ppppbppp/2n2n2/4p3/4P3/1B1P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/ppppbppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/ppppbppp/2n2n2/4p3/2B1P3/2PP1N2/PP3PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/ppppbppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/ppppbppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bq1rk1/ppppbppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bq1rk1/ppppbppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQR1K1", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/ppppbppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/ppppbppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bq1rk1/ppppbppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bq1rk1/ppppbppp/2n2n2/4p3/4P3/1B1P1N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bq1rk1/ppp1bppp/2np1n2/4p3/4P3/1B1P1N2/PPP2PPP/RNBQ1RK1", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bq1rk1/ppp1bppp/2np1n2/4p3/4P3/1BPP1N2/PP3PPP/RNBQ1RK1", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2p2n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2P2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/nB2p1N1/8/5Q2/PPPP1PPP/RNB1K2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1b1kb1r/p1q2ppp/2p2n2/nB2p1N1/8/5Q2/PPPP1PPP/RNB1K2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1b1kb1r/p1q2ppp/2p2n2/n3p1N1/8/3B1Q2/PPPP1PPP/RNB1K2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/6N1/2BpP3/8/PPP2PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r2qkb1r/pppb1ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2p2n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2P2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/n3p1N1/8/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3p1N1/8/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3p3/8/5N2/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n7/4p3/5N2/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3N3/4p3/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1b1kb1r/p1q2pp1/2p2n1p/n3N3/4p3/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2p2n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2P2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/n3p1N1/8/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3p1N1/8/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3p3/8/5N2/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n7/4p3/5N2/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3N3/4p3/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/p4pp1/2p2n1p/n1b1N3/4p3/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2p2n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2P2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/n3p1N1/8/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3p1N1/8/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3p3/8/5N2/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n7/4p3/5N2/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3N3/4p3/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/p4pp1/2pb1n1p/n3N3/4p3/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/p4pp1/2pb1n1p/n3N3/4pP2/8/PPPPB1PP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/p4pp1/2pb1n1p/n3N3/8/5p2/PPPPB1PP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2p2n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2P2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/n3p1N1/8/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3p1N1/8/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3p3/8/5N2/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n7/4p3/5N2/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3N3/4p3/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/p4pp1/2pb1n1p/n3N3/4p3/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/p4pp1/2pb1n1p/n3N3/3Pp3/8/PPP1BPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/p4pp1/2pb1n1p/n3N3/8/3p4/PPP1BPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqk2r/p4pp1/2pb1n1p/n7/8/3N4/PPP1BPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1b1k2r/p1q2pp1/2pb1n1p/n7/8/3N4/PPP1BPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1b1k2r/p1q2pp1/2pb1n1p/n7/8/1P1N4/P1P1BPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2p2n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pp3ppp/2P2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/nB2p1N1/8/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4ppp/2p2n2/n3p1N1/8/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3p1N1/8/8/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p4pp1/2p2n1p/n3p3/8/7N/PPPPBPPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 92, "fen":"r1bqkb1r/p1p2ppp/2n2n2/1p1Pp1N1/2B5/8/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Two Knights Defense"},
{"gid": 89, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Main Line"},
{"gid": 89, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Italian Game, Main Line"},
{"gid": 89, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Main Line"},
{"gid": 89, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Italian Game, Main Line"},
{"gid": 89, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Main Line"},
{"gid": 89, "fen":"r1bqkbnr/ppp2ppp/2np4/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Italian Game, Main Line"},
{"gid": 93, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit, Falkbeer Countergambit"},
{"gid": 93, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit, Falkbeer Countergambit"},
{"gid": 93, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit, Falkbeer Countergambit"},
{"gid": 93, "fen":"rnbqkbnr/ppp2ppp/8/3pp3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit, Falkbeer Countergambit"},
{"gid": 93, "fen":"rnbqkbnr/ppp2ppp/8/3Pp3/5P2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit, Falkbeer Countergambit"},
{"gid": 93, "fen":"rnbqkbnr/ppp2ppp/8/3P4/5p2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit, Falkbeer Countergambit"},
{"gid": 94, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/ppp2ppp/8/3p4/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/ppp2ppp/8/3P4/5p2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkb1r/ppp2ppp/5n2/3P4/5p2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkb1r/ppp2ppp/5n2/3P4/2B2p2/5N2/PPPP2PP/RNBQK2R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/ppp2ppp/8/3p4/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkbnr/ppp2ppp/8/3P4/5p2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkb1r/ppp2ppp/5n2/3P4/5p2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkb1r/ppp2ppp/5n2/1B1P4/5p2/5N2/PPPP2PP/RNBQK2R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkb1r/pp3ppp/2p2n2/1B1P4/5p2/5N2/PPPP2PP/RNBQK2R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"rnbqkb1r/pp3ppp/2P2n2/1B6/5p2/5N2/PPPP2PP/RNBQK2R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 94, "fen":"r1bqkb1r/pp3ppp/2n2n2/1B6/5p2/5N2/PPPP2PP/RNBQK2R", "treename":"King's Gambit Accepted, Abbazia Defense"},
{"gid": 95, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Becker Defense"},
{"gid": 95, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Becker Defense"},
{"gid": 95, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Becker Defense"},
{"gid": 95, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Becker Defense"},
{"gid": 95, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Becker Defense"},
{"gid": 95, "fen":"rnbqkbnr/pppp1pp1/7p/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Becker Defense"},
{"gid": 96, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/ppp2ppp/8/3p4/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/ppp2ppp/8/3B4/4Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkb1r/ppp2ppp/5n2/3B4/4Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/ppp2ppp/8/3p4/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/ppp2ppp/8/3P4/2B2p2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkb1r/pppp1ppp/5n2/8/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkb1r/pppp1ppp/5n2/8/2B1Pp2/2N5/PPPP2PP/R1BQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkb1r/pp1p1ppp/2p2n2/8/2B1Pp2/2N5/PPPP2PP/R1BQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnb1kbnr/pppp1ppp/8/8/2B1Pp1q/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnb1kbnr/pppp1ppp/8/8/2B1Pp1q/8/PPPP2PP/RNBQ1KNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnb1kbnr/p1pp1ppp/8/1p6/2B1Pp1q/8/PPPP2PP/RNBQ1KNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pp1p1ppp/2p5/8/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkb1r/pppp1ppp/5n2/8/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkb1r/pppp1ppp/5n2/8/2B1Pp2/2N5/PPPP2PP/R1BQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqk2r/pppp1ppp/5n2/8/1bB1Pp2/2N5/PPPP2PP/R1BQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqk2r/pppp1ppp/5n2/4P3/1bB2p2/2N5/PPPP2PP/R1BQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkbnr/pppp1ppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 96, "fen":"rnbqkb1r/ppppnppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR", "treename":"King's Gambit Accepted, Bishop's Gambit"},
{"gid": 97, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bonsch-Osmolovsky Variation"},
{"gid": 97, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Bonsch-Osmolovsky Variation"},
{"gid": 97, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bonsch-Osmolovsky Variation"},
{"gid": 97, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Bonsch-Osmolovsky Variation"},
{"gid": 97, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Bonsch-Osmolovsky Variation"},
{"gid": 97, "fen":"rnbqkb1r/ppppnppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Bonsch-Osmolovsky Variation"},
{"gid": 98, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Cunningham Defense"},
{"gid": 98, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Cunningham Defense"},
{"gid": 98, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Cunningham Defense"},
{"gid": 98, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Cunningham Defense"},
{"gid": 98, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Cunningham Defense"},
{"gid": 98, "fen":"rnbqk1nr/ppppbppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Cunningham Defense"},
{"gid": 98, "fen":"rnbqk1nr/ppppbppp/8/8/2B1Pp2/5N2/PPPP2PP/RNBQK2R", "treename":"King's Gambit Accepted, Cunningham Defense"},
{"gid": 98, "fen":"rnbqk2r/ppppbppp/5n2/8/2B1Pp2/5N2/PPPP2PP/RNBQK2R", "treename":"King's Gambit Accepted, Cunningham Defense"},
{"gid": 99, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/ppp2ppp/3p4/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/ppp2ppp/3p4/8/3PPp2/5N2/PPP3PP/RNBQKB1R", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/ppp2p1p/3p4/6p1/3PPp2/5N2/PPP3PP/RNBQKB1R", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/ppp2p1p/3p4/6p1/3PPp1P/5N2/PPP3P1/RNBQKB1R", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/ppp2p1p/3p4/8/3PPppP/5N2/PPP3P1/RNBQKB1R", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/ppp2p1p/3p4/8/3PPppP/8/PPP3P1/RNBQKBNR", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/ppp2ppp/3p4/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 99, "fen":"rnbqkbnr/ppp2ppp/3p4/8/2B1Pp2/5N2/PPPP2PP/RNBQK2R", "treename":"King's Gambit Accepted, Fischer Defense"},
{"gid": 100, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1p1p/8/6p1/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1p1p/8/6p1/4Pp1P/5N2/PPPP2P1/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1p1p/8/8/4PppP/5N2/PPPP2P1/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1p1p/8/4N3/4PppP/8/PPPP2P1/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/ppp2p1p/3p4/4N3/4PppP/8/PPPP2P1/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1p1p/8/6p1/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1p1p/8/6p1/4Pp1P/5N2/PPPP2P1/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1p1p/8/8/4PppP/5N2/PPPP2P1/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"rnbqkbnr/pppp1p1p/8/4N3/4PppP/8/PPPP2P1/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 100, "fen":"r1bqkbnr/pppp1p1p/2n5/4N3/4PppP/8/PPPP2P1/RNBQKB1R", "treename":"King's Gambit Accepted, Kieseritzky Gambit"},
{"gid": 101, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Modern Defense"},
{"gid": 101, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Modern Defense"},
{"gid": 101, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Modern Defense"},
{"gid": 101, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Modern Defense"},
{"gid": 101, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Modern Defense"},
{"gid": 101, "fen":"rnbqkbnr/ppp2ppp/8/3p4/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Modern Defense"},
{"gid": 101, "fen":"rnbqkbnr/ppp2ppp/8/3P4/5p2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Modern Defense"},
{"gid": 101, "fen":"rnbqk1nr/ppp2ppp/3b4/3P4/5p2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Modern Defense"},
{"gid": 102, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Schallopp Defense"},
{"gid": 102, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Schallopp Defense"},
{"gid": 102, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Schallopp Defense"},
{"gid": 102, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Schallopp Defense"},
{"gid": 102, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Schallopp Defense"},
{"gid": 102, "fen":"rnbqkb1r/pppp1ppp/5n2/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Schallopp Defense"},
{"gid": 103, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Traditional Variation"},
{"gid": 103, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Accepted, Traditional Variation"},
{"gid": 103, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Traditional Variation"},
{"gid": 103, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Accepted, Traditional Variation"},
{"gid": 103, "fen":"rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Traditional Variation"},
{"gid": 103, "fen":"rnbqkbnr/pppp1p1p/8/6p1/4Pp2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Accepted, Traditional Variation"},
{"gid": 103, "fen":"rnbqkbnr/pppp1p1p/8/6p1/2B1Pp2/5N2/PPPP2PP/RNBQK2R", "treename":"King's Gambit Accepted, Traditional Variation"},
{"gid": 103, "fen":"rnbqk1nr/pppp1pbp/8/6p1/2B1Pp2/5N2/PPPP2PP/RNBQK2R", "treename":"King's Gambit Accepted, Traditional Variation"},
{"gid": 103, "fen":"rnbqk1nr/pppp1pbp/8/6p1/2BPPp2/5N2/PPP3PP/RNBQK2R", "treename":"King's Gambit Accepted, Traditional Variation"},
{"gid": 104, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqk1nr/pppp1ppp/8/2b1p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqk1nr/pppp1ppp/8/2b1p3/4PP2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqk1nr/ppp2ppp/3p4/2b1p3/4PP2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqk1nr/ppp2ppp/3p4/2b1p3/1P2PP2/5N2/P1PP2PP/RNBQKB1R", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqk1nr/pppp1ppp/8/2b1p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqk1nr/pppp1ppp/8/2b1p3/4PP2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqk1nr/ppp2ppp/3p4/2b1p3/4PP2/5N2/PPPP2PP/RNBQKB1R", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqk1nr/ppp2ppp/3p4/2b1p3/4PP2/2P2N2/PP1P2PP/RNBQKB1R", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 104, "fen":"rnbqk1nr/ppp2ppp/1b1p4/4p3/4PP2/2P2N2/PP1P2PP/RNBQKB1R", "treename":"King's Gambit Declined, Classical Variation"},
{"gid": 105, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Declined, Falkbeer"},
{"gid": 105, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Declined, Falkbeer"},
{"gid": 105, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Declined, Falkbeer"},
{"gid": 105, "fen":"rnbqkbnr/ppp2ppp/8/3pp3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Declined, Falkbeer"},
{"gid": 105, "fen":"rnbqkbnr/ppp2ppp/8/3Pp3/5P2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Declined, Falkbeer"},
{"gid": 105, "fen":"rnbqkbnr/ppp2ppp/8/3P4/4pP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Declined, Falkbeer"},
{"gid": 105, "fen":"rnbqkbnr/ppp2ppp/8/3P4/4pP2/2N5/PPPP2PP/R1BQKBNR", "treename":"King's Gambit Declined, Falkbeer"},
{"gid": 105, "fen":"rnbqkb1r/ppp2ppp/5n2/3P4/4pP2/2N5/PPPP2PP/R1BQKBNR", "treename":"King's Gambit Declined, Falkbeer"},
{"gid": 105, "fen":"rnbqkb1r/ppp2ppp/5n2/3P4/4pP2/2N5/PPPPQ1PP/R1B1KBNR", "treename":"King's Gambit Declined, Falkbeer"},
{"gid": 106, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Declined, Keene Defense"},
{"gid": 106, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Gambit Declined, Keene Defense"},
{"gid": 106, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Declined, Keene Defense"},
{"gid": 106, "fen":"rnb1kbnr/pppp1ppp/8/4p3/4PP1q/8/PPPP2PP/RNBQKBNR", "treename":"King's Gambit Declined, Keene Defense"},
{"gid": 106, "fen":"rnb1kbnr/pppp1ppp/8/4p3/4PP1q/6P1/PPPP3P/RNBQKBNR", "treename":"King's Gambit Declined, Keene Defense"},
{"gid": 106, "fen":"rnb1kbnr/ppppqppp/8/4p3/4PP2/6P1/PPPP3P/RNBQKBNR", "treename":"King's Gambit Declined, Keene Defense"},
{"gid": 107, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/3P1N2/PPP2PPP/RNBQKB1R", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/3P1N2/PPP2PPP/RNBQKB1R", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/3P1N2/PPP1BPPP/RNBQK2R", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/3P1N2/PPP2PPP/RNBQKB1R", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/3P1N2/PPP2PPP/RNBQKB1R", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 107, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/3P1NP1/PPP2P1P/RNBQKB1R", "treename":"King's Knight Opening, Normal Variation"},
{"gid": 108, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Pawn Game, Maroczy Defense"},
{"gid": 108, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Pawn Game, Maroczy Defense"},
{"gid": 108, "fen":"rnbqkbnr/pppp1ppp/8/4p3/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"King's Pawn Game, Maroczy Defense"},
{"gid": 108, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"King's Pawn Game, Maroczy Defense"},
{"gid": 108, "fen":"rnbqkbnr/ppp2ppp/3p4/4P3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"King's Pawn Game, Maroczy Defense"},
{"gid": 108, "fen":"rnbqkbnr/ppp2ppp/8/4p3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"King's Pawn Game, Maroczy Defense"},
{"gid": 108, "fen":"rnbQkbnr/ppp2ppp/8/4p3/4P3/8/PPP2PPP/RNB1KBNR", "treename":"King's Pawn Game, Maroczy Defense"},
{"gid": 109, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Pawn Game, Tayler Opening"},
{"gid": 109, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"King's Pawn Game, Tayler Opening"},
{"gid": 109, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"King's Pawn Game, Tayler Opening"},
{"gid": 109, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"King's Pawn Game, Tayler Opening"},
{"gid": 109, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPPBPPP/RNBQK2R", "treename":"King's Pawn Game, Tayler Opening"},
{"gid": 109, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/5N2/PPPPBPPP/RNBQK2R", "treename":"King's Pawn Game, Tayler Opening"},
{"gid": 109, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/3P1N2/PPP1BPPP/RNBQK2R", "treename":"King's Pawn Game, Tayler Opening"},
{"gid": 109, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp3/4P3/3P1N2/PPP1BPPP/RNBQK2R", "treename":"King's Pawn Game, Tayler Opening"},
{"gid": 109, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp3/4P3/3P1N2/PPPNBPPP/R1BQK2R", "treename":"King's Pawn Game, Tayler Opening"},
{"gid": 110, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/5P2/PPP3PP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/5P2/PPP3PP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/5P2/PPP3PP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5P2/PPP3PP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4P3/4P3/5P2/PPP3PP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp2ppp/5n2/4p3/4P3/5P2/PPP3PP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbQkb1r/ppp2ppp/5n2/4p3/4P3/5P2/PPP3PP/RNB1KBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/5P2/PPP3PP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5P2/PPP3PP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 110, "fen":"rnbqkb1r/ppp2ppp/3p1n2/3Pp3/4P3/5P2/PPP3PP/RNBQKBNR", "treename":"Lion Defense, Lion's Jaw"},
{"gid": 111, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Geller's System"},
{"gid": 111, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Geller's System"},
{"gid": 111, "fen":"rnbqkbnr/pppppp1p/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Geller's System"},
{"gid": 111, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Geller's System"},
{"gid": 111, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/2P5/PP3PPP/RNBQKBNR", "treename":"Modern Defense, Geller's System"},
{"gid": 111, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2P5/PP3PPP/RNBQKBNR", "treename":"Modern Defense, Geller's System"},
{"gid": 111, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Modern Defense, Geller's System"},
{"gid": 112, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Gurgenidze Defense"},
{"gid": 112, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Gurgenidze Defense"},
{"gid": 112, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Gurgenidze Defense"},
{"gid": 112, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Gurgenidze Defense"},
{"gid": 112, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Gurgenidze Defense"},
{"gid": 112, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Gurgenidze Defense"},
{"gid": 112, "fen":"rnbqkbnr/pp2pp1p/2p3p1/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Gurgenidze Defense"},
{"gid": 112, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Gurgenidze Defense"},
{"gid": 112, "fen":"rnbqk1nr/pp2ppbp/2p3p1/3pP3/3P1P2/2N5/PPP3PP/R1BQKBNR", "treename":"Modern Defense, Gurgenidze Defense"},
{"gid": 112, "fen":"rnbqk1nr/pp2ppb1/2p3p1/3pP2p/3P1P2/2N5/PPP3PP/R1BQKBNR", "treename":"Modern Defense, Gurgenidze Defense"},
{"gid": 112, "fen":"rnbqk1nr/pp2ppb1/2p3p1/3pP2p/3P1P2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Modern Defense, Gurgenidze Defense"},
{"gid": 113, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Lizard Defense"},
{"gid": 113, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Lizard Defense"},
{"gid": 113, "fen":"rnbqkbnr/pppppp1p/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Lizard Defense"},
{"gid": 113, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Lizard Defense"},
{"gid": 113, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Lizard Defense"},
{"gid": 113, "fen":"rnbqk1nr/ppp1ppbp/6p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Lizard Defense"},
{"gid": 115, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Modern Pterodactyl"},
{"gid": 115, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Modern Pterodactyl"},
{"gid": 115, "fen":"rnbqkbnr/pppppp1p/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Modern Pterodactyl"},
{"gid": 115, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Modern Pterodactyl"},
{"gid": 115, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Modern Pterodactyl"},
{"gid": 115, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Modern Pterodactyl"},
{"gid": 116, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqkbnr/pppppp1p/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"r1bqk1nr/ppp1ppbp/2np2p1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqkbnr/pppppp1p/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqk1nr/pp2ppbp/2pp2p1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 116, "fen":"rnbqk1nr/pp2ppbp/2pp2p1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Modern Defense, Pseudo-Austrian Attack"},
{"gid": 117, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Semi-Averbakh Variation"},
{"gid": 117, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Semi-Averbakh Variation"},
{"gid": 117, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Modern Defense, Semi-Averbakh Variation"},
{"gid": 117, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Modern Defense, Semi-Averbakh Variation"},
{"gid": 117, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Modern Defense, Semi-Averbakh Variation"},
{"gid": 117, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Modern Defense, Semi-Averbakh Variation"},
{"gid": 117, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/2PPP3/5N2/PP3PPP/RNBQKB1R", "treename":"Modern Defense, Semi-Averbakh Variation"},
{"gid": 117, "fen":"rnb1k1nr/pp1pppbp/6p1/q1p5/2PPP3/5N2/PP3PPP/RNBQKB1R", "treename":"Modern Defense, Semi-Averbakh Variation"},
{"gid": 118, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqkbnr/pppppp1p/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N1B3/PPP2PPP/R2QKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/pp2ppbp/2pp2p1/8/3PP3/2N1B3/PPP2PPP/R2QKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqkbnr/pppppp1p/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2BPP3/2N5/PPP2PPP/R1BQK1NR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/pp2ppbp/2pp2p1/8/2BPP3/2N5/PPP2PPP/R1BQK1NR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/pp2ppbp/2pp2p1/8/2BPP3/2N2Q2/PPP2PPP/R1B1K1NR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/2BPP3/2N2Q2/PPP2PPP/R1B1K1NR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqkbnr/pppppp1p/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N1B3/PPP2PPP/R2QKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/1pp1ppbp/p2p2p1/8/3PP3/2N1B3/PPP2PPP/R2QKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqkbnr/pppppp1p/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2BPP3/2N5/PPP2PPP/R1BQK1NR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/pp2ppbp/2pp2p1/8/2BPP3/2N5/PPP2PPP/R1BQK1NR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/pp2ppbp/2pp2p1/8/2BPP3/2N2Q2/PPP2PPP/R1B1K1NR", "treename":"Modern Defense, Standard Defense"},
{"gid": 118, "fen":"rnbqk1nr/pp3pbp/2ppp1p1/8/2BPP3/2N2Q2/PPP2PPP/R1B1K1NR", "treename":"Modern Defense, Standard Defense"},
{"gid": 114, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 114, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 114, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 114, "fen":"rnbqk1nr/ppppppbp/6p1/8/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 114, "fen":"rnbqk1nr/ppppppbp/6p1/8/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 114, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 119, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqk2r/pp3ppp/4pn2/3p4/1bPP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqk2r/pp3ppp/4pn2/3P4/1b1P4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqk2r/pp3ppp/5n2/3p4/1b1P4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqk2r/pp3ppp/4pn2/3p4/1bPP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqk2r/pp3ppp/4pn2/3P4/1b1P4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqk2r/pp3ppp/4p3/3n4/1b1P4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqk2r/pp3ppp/4p3/3n4/1b1P4/2N2N2/PPQ2PPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"r1bqk2r/pp3ppp/2n1p3/3n4/1b1P4/2N2N2/PPQ2PPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"r1bqk2r/pp3ppp/2n1p3/3n4/1b1P4/2NB1N2/PPQ2PPP/R1B1K2R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqk2r/pp3ppp/4pn2/3p4/1bPP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqk2r/pp3ppp/4pn2/3P4/1b1P4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqk2r/pp3ppp/4p3/3n4/1b1P4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"rnbqk2r/pp3ppp/4p3/3n4/1b1P4/2N2N2/PP1B1PPP/R2QKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"r1bqk2r/pp3ppp/2n1p3/3n4/1b1P4/2N2N2/PP1B1PPP/R2QKB1R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"r1bqk2r/pp3ppp/2n1p3/3n4/1b1P4/2NB1N2/PP1B1PPP/R2QK2R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 119, "fen":"r1bq1rk1/pp3ppp/2n1p3/3n4/1b1P4/2NB1N2/PP1B1PPP/R2QK2R", "treename":"Nimzo-Indian Defense, Panov Attack"},
{"gid": 120, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/pppppppp/2n5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/ppp1pppp/2n5/3pP3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r2qkbnr/ppp1pppp/2n5/3pPb2/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r2qkbnr/ppp1pppp/2n5/3pPb2/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/pppppppp/2n5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkb1r/ppp1pppp/2n2n2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/pppppppp/2n5/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 120, "fen":"r1bqkbnr/ppp1pppp/2n5/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Nimzowitsch Defense, Scandinavian Variation"},
{"gid": 121, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r2qkbnr/ppp1pppp/2np4/8/3PP1b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkb1r/ppp1pppp/2np1n2/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkb1r/ppp1pppp/2np1n2/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkb1r/ppp1pp1p/2np1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkb1r/ppp1pppp/2np1n2/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkb1r/ppp1pppp/2np1n2/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r2qkb1r/ppp1pppp/2np1n2/8/3PP1b1/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r2qkb1r/ppp1pppp/2np1n2/1B6/3PP1b1/2N2N2/PPP2PPP/R1BQK2R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkb1r/ppp1pppp/2np1n2/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkb1r/ppp1pppp/2np1n2/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r2qkb1r/ppp1pppp/2np1n2/8/3PP1b1/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r2qkb1r/ppp1pppp/2np1n2/3P4/4P1b1/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkb1r/ppp1pppp/2np1n2/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkb1r/ppp1pppp/2np1n2/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r2qkb1r/ppp1pppp/2np1n2/8/3PP1b1/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r2qkb1r/ppp1pppp/2np1n2/8/3PP1b1/2N1BN2/PPP2PPP/R2QKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/pppppppp/2n5/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkbnr/ppp1pppp/2np4/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkb1r/ppp1pppp/2np1n2/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r1bqkb1r/ppp1pppp/2np1n2/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r2qkb1r/ppp1pppp/2np1n2/8/3PP1b1/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 121, "fen":"r2qkb1r/ppp1pppp/2np1n2/8/3PP1b1/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Nimzowitsch Defense, Williams Variation"},
{"gid": 122, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rnbqkbnr/p1pppppp/1p6/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rnbqkbnr/p1pppppp/1p6/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkbnr/pbpppppp/1p6/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkbnr/pbpppppp/1p6/8/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkbnr/pbppp1pp/1p6/5p2/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rnbqkbnr/p1pppppp/1p6/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rnbqkbnr/p1pppppp/1p6/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkbnr/pbpppppp/1p6/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkbnr/pbpppppp/1p6/8/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rnbqkbnr/p1pppppp/1p6/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rnbqkbnr/p1pppppp/1p6/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkbnr/pbpppppp/1p6/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkbnr/pbpppppp/1p6/8/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkbnr/pbpp1ppp/1p2p3/8/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkbnr/pbpp1ppp/1p2p3/8/3PP3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkbnr/pb1p1ppp/1p2p3/2p5/3PP3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Owen Defense, Main Line"},
{"gid": 122, "fen":"rn1qkbnr/pb1p1ppp/1p2p3/2p5/3PP3/2PB1N2/PP3PPP/RNBQK2R", "treename":"Owen Defense, Main Line"},
{"gid": 123, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"r1bqkbnr/ppp2ppp/2np4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/3QP3/2N2N2/PPP2PPP/R1B1KB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkbnr/ppp2ppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqk2r/ppp1bppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 123, "fen":"rnbqk2r/ppp1bppp/3p1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Exchange Variation"},
{"gid": 124, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pppn1ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pppn1ppp/3p4/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pppn1ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pppn1ppp/3p4/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pppn1ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pppn1ppp/3p4/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqk1nr/pp1nbppp/2pp4/4p3/2BPP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 124, "fen":"r1bqk1nr/pp1nbppp/2pp4/4P3/2B1P3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Philidor Defense, Hanham Variation"},
{"gid": 125, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Larsen Variation"},
{"gid": 125, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Larsen Variation"},
{"gid": 125, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Larsen Variation"},
{"gid": 125, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Larsen Variation"},
{"gid": 125, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Larsen Variation"},
{"gid": 125, "fen":"rnbqkbnr/ppp2ppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Larsen Variation"},
{"gid": 125, "fen":"rnbqkbnr/ppp2ppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Larsen Variation"},
{"gid": 125, "fen":"rnbqkbnr/ppp2p1p/3p2p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Larsen Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP1QPPP/R1B2RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2BPP3/2N2N2/PPP1QPPP/R1B2RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/P1BPP3/2N2N2/1PP1QPPP/R1B2RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/8/P1BpP3/2N2N2/1PP1QPPP/R1B2RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqk2r/pppnbppp/3p1n2/4P3/2B1P3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqk2r/ppp1bppp/3p1n2/4n3/2B1P3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQR1K1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQR1K1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/P1BPP3/2N2N2/1PP2PPP/R1BQR1K1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/1p1nbppp/2pp1n2/p3p3/P1BPP3/2N2N2/1PP2PPP/R1BQR1K1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQR1K1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQR1K1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/P1BPP3/2N2N2/1PP2PPP/R1BQR1K1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/p2nbppp/1ppp1n2/4p3/P1BPP3/2N2N2/1PP2PPP/R1BQR1K1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/P1BPP3/2N2N2/1PP2PPP/R1BQ1RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/P1BPP3/2N2N2/1PP2PPP/R1BQ1RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 126, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/P1BPP3/2N2N2/1PP1QPPP/R1B2RK1", "treename":"Philidor Defense, Lion Variation"},
{"gid": 127, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"r1bqk2r/pppnbppp/3p1n2/4p1N1/2BPP3/2N5/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p1N1/2BPP3/2N5/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 127, "fen":"r1bq1rk1/pppnbBpp/3p1n2/4p1N1/3PP3/2N5/PPP2PPP/R1BQK2R", "treename":"Philidor Defense, Nimzowitsch"},
{"gid": 128, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4P3/4P3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkb1r/ppp2ppp/3p4/4P3/4n3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkb1r/ppp2ppp/3p4/3QP3/4n3/5N2/PPP2PPP/RNB1KB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4P3/4P3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkb1r/ppp2ppp/3p4/4P3/4n3/5N2/PPP2PPP/RNBQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 128, "fen":"rnbqkb1r/ppp2ppp/3p4/4P3/4n3/5N2/PPPN1PPP/R1BQKB1R", "treename":"Philidor Defense, Nimzowitsch Variation"},
{"gid": 129, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/pp2ppbp/3p1np1/2p5/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/pp2ppbp/3p1np1/1Bp5/3PPP2/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bppbp/3p1np1/1Bp5/3PPP2/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bppbp/3p1np1/1Bp1P3/3P1P2/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bppbp/3p2p1/1Bp1P3/3P1Pn1/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bppbp/3pP1p1/1Bp5/3P1Pn1/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp2ppbp/3pP1p1/1bp5/3P1Pn1/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/pp2ppbp/3p1np1/2p5/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/pp2ppbp/3p1np1/1Bp5/3PPP2/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bppbp/3p1np1/1Bp5/3PPP2/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bppbp/3p1np1/1Bp1P3/3P1P2/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bppbp/3p2p1/1Bp1P3/3P1Pn1/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bppbp/3pP1p1/1Bp5/3P1Pn1/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bp1bp/3pp1p1/1Bp5/3P1Pn1/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/pp2ppbp/3p1np1/2p5/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/pp2ppbp/3p1np1/2P5/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnb1k2r/pp2ppbp/3p1np1/q1P5/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnb1k2r/pp2ppbp/3p1np1/q1P5/4PP2/2NB1N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/pp2ppbp/3p1np1/2p5/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/pp2ppbp/3p1np1/1Bp5/3PPP2/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bppbp/3p1np1/1Bp5/3PPP2/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bppbp/3p1np1/1Bp1P3/3P1P2/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1bppbp/3p2p1/1Bp1P3/3P1Pn1/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rn1qk2r/pp1Bppbp/3p2p1/2p1P3/3P1Pn1/2N2N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2N1BN2/PPP3PP/R2QKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2BPPP2/2N5/PPP3PP/R1BQK1NR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/4P3/3P1P2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2NB1N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/3PPP2/2NB1N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/3PPP2/2NB1N2/PPP3PP/R1BQ1RK1", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"r1bq1rk1/pp2ppbp/n2p1np1/2p5/3PPP2/2NB1N2/PPP3PP/R1BQ1RK1", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"r1bq1rk1/pp2ppbp/n2p1np1/2pP4/4PP2/2NB1N2/PPP3PP/R1BQ1RK1", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"r2q1rk1/pp2ppbp/n2p1np1/2pP4/4PPb1/2NB1N2/PPP3PP/R1BQ1RK1", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2NB1N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/3PPP2/2NB1N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/4P3/3P1P2/2NB1N2/PPP3PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 129, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP1B1PP/R1BQK2R", "treename":"Pirc Defense, Austrian Attack"},
{"gid": 130, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Bayonet Attack"},
{"gid": 130, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Bayonet Attack"},
{"gid": 130, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Bayonet Attack"},
{"gid": 130, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Bayonet Attack"},
{"gid": 130, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Bayonet Attack"},
{"gid": 130, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Bayonet Attack"},
{"gid": 130, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP1BPPP/R1BQK1NR", "treename":"Pirc Defense, Bayonet Attack"},
{"gid": 130, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N5/PPP1BPPP/R1BQK1NR", "treename":"Pirc Defense, Bayonet Attack"},
{"gid": 130, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP2P/2N5/PPP1BPP1/R1BQK1NR", "treename":"Pirc Defense, Bayonet Attack"},
{"gid": 131, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Byrne Variation"},
{"gid": 131, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Byrne Variation"},
{"gid": 131, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Byrne Variation"},
{"gid": 131, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Byrne Variation"},
{"gid": 131, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Byrne Variation"},
{"gid": 131, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Byrne Variation"},
{"gid": 131, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/6B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"Pirc Defense, Byrne Variation"},
{"gid": 131, "fen":"rnbqk2r/ppp1ppbp/3p1np1/6B1/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"Pirc Defense, Byrne Variation"},
{"gid": 132, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Chinese Variation"},
{"gid": 132, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Chinese Variation"},
{"gid": 132, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Chinese Variation"},
{"gid": 132, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Chinese Variation"},
{"gid": 132, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Chinese Variation"},
{"gid": 132, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Chinese Variation"},
{"gid": 132, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP1BPPP/R1BQK1NR", "treename":"Pirc Defense, Chinese Variation"},
{"gid": 132, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N5/PPP1BPPP/R1BQK1NR", "treename":"Pirc Defense, Chinese Variation"},
{"gid": 132, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP1P1/2N5/PPP1BP1P/R1BQK1NR", "treename":"Pirc Defense, Chinese Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/P2PP3/2N2N2/1PP1BPPP/R1BQ1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"r1bq1rk1/pp1nppbp/2pp1np1/8/P2PP3/2N2N2/1PP1BPPP/R1BQ1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/3PP3/2N2N1P/PPP1BPP1/R1BQ1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rn1q1rk1/ppp1ppbp/3p1np1/8/3PP1b1/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rn1q1rk1/ppp1ppbp/3p1np1/8/3PP1b1/2N1BN2/PPP1BPPP/R2Q1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"r2q1rk1/ppp1ppbp/2np1np1/8/3PP1b1/2N1BN2/PPP1BPPP/R2Q1RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"r2q1rk1/ppp1ppbp/2np1np1/8/3PP1b1/2N1BN2/PPPQBPPP/R4RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"r2q1rk1/ppp2pbp/2np1np1/4p3/3PP1b1/2N1BN2/PPPQBPPP/R4RK1", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/1pp1ppbp/p2p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/1pp1ppbp/p2p1np1/8/P2PP3/2N2N2/1PP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N1BN2/PPP1BPPP/R2QK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N1BN1P/PPP2PP1/R2QKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/3PP3/2N1BN1P/PPP2PP1/R2QKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N1BN1P/PPP2PP1/R2QKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/3PP3/2N1BN1P/PPP2PP1/R2QKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/3PP3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3PP3/2N1BN1P/PPP2PP1/R2QKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbq1rk1/1pp1ppbp/p2p1np1/8/3PP3/2N1BN1P/PPP2PP1/R2QKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/2BPP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N1BN2/PPP2PPP/R2QKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/3PP3/2N1BN2/PPP2PPP/R2QKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N1BN2/PPP2PPP/R2QKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/1pp1ppbp/p2p1np1/8/3PP3/2N1BN2/PPP2PPP/R2QKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 133, "fen":"rnbqk2r/1pp1ppbp/p2p1np1/8/P2PP3/2N1BN2/1PP2PPP/R2QKB1R", "treename":"Pirc Defense, Classical Variation"},
{"gid": 134, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Kholmov System"},
{"gid": 134, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Kholmov System"},
{"gid": 134, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Kholmov System"},
{"gid": 134, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Kholmov System"},
{"gid": 134, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Kholmov System"},
{"gid": 134, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Kholmov System"},
{"gid": 134, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/2BPP3/2N5/PPP2PPP/R1BQK1NR", "treename":"Pirc Defense, Kholmov System"},
{"gid": 134, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2BPP3/2N5/PPP2PPP/R1BQK1NR", "treename":"Pirc Defense, Kholmov System"},
{"gid": 136, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Sveshnikov System"},
{"gid": 136, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Sveshnikov System"},
{"gid": 136, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Sveshnikov System"},
{"gid": 136, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Sveshnikov System"},
{"gid": 136, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Sveshnikov System"},
{"gid": 136, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Sveshnikov System"},
{"gid": 136, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N3P1/PPP2P1P/R1BQKBNR", "treename":"Pirc Defense, Sveshnikov System"},
{"gid": 136, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N3P1/PPP2P1P/R1BQKBNR", "treename":"Pirc Defense, Sveshnikov System"},
{"gid": 136, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N3P1/PPP2PBP/R1BQK1NR", "treename":"Pirc Defense, Sveshnikov System"},
{"gid": 136, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/3PP3/2N3P1/PPP2PBP/R1BQK1NR", "treename":"Pirc Defense, Sveshnikov System"},
{"gid": 135, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N1B3/PPP2PPP/R2QKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/pp2pp1p/2pp1np1/8/3PP3/2N1B3/PPP2PPP/R2QKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"r1bqkb1r/pppnpppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/2PB4/PP3PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/3PP3/2PB4/PP3PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/5n2/3pP3/4P3/2PB4/PP3PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/5n2/4P3/4p3/2PB4/PP3PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP1NPPP/R1BQKB1R", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N5/PPP1NPPP/R1BQKB1R", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/8/PPPN1PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2P5/PP1N1PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2P5/PP1N1PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N1B3/PPP2PPP/R2QKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N1B3/PPP2PPP/R2QKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N1B3/PPPQ1PPP/R3KBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/3PP3/2N1B3/PPPQ1PPP/R3KBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/3PP3/2N1BP2/PPPQ2PP/R3KBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqk2r/p3ppbp/2pp1np1/1p6/3PP3/2N1BP2/PPPQ2PP/R3KBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/2PB4/PP3PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/3PP3/2PB4/PP3PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/5n2/3pP3/4P3/2PB4/PP3PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2PB4/PP3PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP2P/2N5/PPP2PP1/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/3B4/PPP2PPP/RNBQK1NR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2PB1N2/PP3PPP/RNBQK2R", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4P3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbqkb1r/ppp2ppp/5n2/4p3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 135, "fen":"rnbQkb1r/ppp2ppp/5n2/4p3/4P3/2N5/PPP2PPP/R1B1KBNR", "treename":"Pirc Defense, Main Line"},
{"gid": 137, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Caro Gambit"},
{"gid": 137, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Caro Gambit"},
{"gid": 137, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Caro Gambit"},
{"gid": 137, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Caro Gambit"},
{"gid": 137, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Caro Gambit"},
{"gid": 137, "fen":"r1bqkbnr/ppp2ppp/2n5/3pp3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Caro Gambit"},
{"gid": 137, "fen":"r1bqkbnr/ppp2ppp/2n5/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R", "treename":"Ponziani Opening, Caro Gambit"},
{"gid": 137, "fen":"r2qkbnr/pppb1ppp/2n5/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R", "treename":"Ponziani Opening, Caro Gambit"},
{"gid": 138, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkb1r/pppp1ppp/2n5/4p3/3Pn3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkb1r/pppp1ppp/2n5/3Pp3/4n3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkb1r/ppppnppp/8/3Pp3/4n3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 138, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3pP3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Ponziani Opening, Jaenisch Counterattack"},
{"gid": 139, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Leonhardt Variation"},
{"gid": 139, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Leonhardt Variation"},
{"gid": 139, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Leonhardt Variation"},
{"gid": 139, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Leonhardt Variation"},
{"gid": 139, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Leonhardt Variation"},
{"gid": 139, "fen":"r1bqkbnr/ppp2ppp/2n5/3pp3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Leonhardt Variation"},
{"gid": 139, "fen":"r1bqkbnr/ppp2ppp/2n5/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R", "treename":"Ponziani Opening, Leonhardt Variation"},
{"gid": 139, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R", "treename":"Ponziani Opening, Leonhardt Variation"},
{"gid": 140, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Romanishin Variation"},
{"gid": 140, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Romanishin Variation"},
{"gid": 140, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Romanishin Variation"},
{"gid": 140, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Romanishin Variation"},
{"gid": 140, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Romanishin Variation"},
{"gid": 140, "fen":"r1bqk1nr/ppppbppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Romanishin Variation"},
{"gid": 141, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Steinitz Variation"},
{"gid": 141, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Steinitz Variation"},
{"gid": 141, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Steinitz Variation"},
{"gid": 141, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Steinitz Variation"},
{"gid": 141, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Steinitz Variation"},
{"gid": 141, "fen":"r1bqkbnr/ppp2ppp/2n5/3pp3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Steinitz Variation"},
{"gid": 141, "fen":"r1bqkbnr/ppp2ppp/2n5/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R", "treename":"Ponziani Opening, Steinitz Variation"},
{"gid": 141, "fen":"r1bqkbnr/ppp3pp/2n2p2/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R", "treename":"Ponziani Opening, Steinitz Variation"},
{"gid": 142, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Vukovic Gambit"},
{"gid": 142, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ponziani Opening, Vukovic Gambit"},
{"gid": 142, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Vukovic Gambit"},
{"gid": 142, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ponziani Opening, Vukovic Gambit"},
{"gid": 142, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Vukovic Gambit"},
{"gid": 142, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Ponziani Opening, Vukovic Gambit"},
{"gid": 142, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Ponziani Opening, Vukovic Gambit"},
{"gid": 142, "fen":"r1bqkb1r/pppp1ppp/2n5/4p3/3Pn3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Ponziani Opening, Vukovic Gambit"},
{"gid": 142, "fen":"r1bqkb1r/pppp1ppp/2n5/3Pp3/4n3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Ponziani Opening, Vukovic Gambit"},
{"gid": 142, "fen":"r1bqk2r/pppp1ppp/2n5/2bPp3/4n3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Ponziani Opening, Vukovic Gambit"},
{"gid": 143, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pterodactyl Defense, Sicilian"},
{"gid": 143, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pterodactyl Defense, Sicilian"},
{"gid": 143, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Sicilian"},
{"gid": 143, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Sicilian"},
{"gid": 143, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Sicilian"},
{"gid": 143, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Sicilian"},
{"gid": 143, "fen":"rnbqk1nr/pp1pppbp/6p1/2P5/4P3/5N2/PPP2PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Sicilian"},
{"gid": 143, "fen":"rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/5N2/PPP2PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Sicilian"},
{"gid": 143, "fen":"rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Pterodactyl Defense, Sicilian"},
{"gid": 144, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pterodactyl Defense, Western"},
{"gid": 144, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Pterodactyl Defense, Western"},
{"gid": 144, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Western"},
{"gid": 144, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Western"},
{"gid": 144, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Western"},
{"gid": 144, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Western"},
{"gid": 144, "fen":"rnbqk1nr/pp1pppbp/6p1/2P5/4P3/5N2/PPP2PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Western"},
{"gid": 144, "fen":"rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/5N2/PPP2PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Western"},
{"gid": 144, "fen":"rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Western"},
{"gid": 144, "fen":"rnb1k1nr/pp1pppbp/6p1/2q5/4P3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Pterodactyl Defense, Western"},
{"gid": 145, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Rat Defense, Harmonist"},
{"gid": 145, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Rat Defense, Harmonist"},
{"gid": 145, "fen":"rnbqkbnr/ppp1pppp/3p4/8/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"Rat Defense, Harmonist"},
{"gid": 145, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"Rat Defense, Harmonist"},
{"gid": 145, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Rat Defense, Harmonist"},
{"gid": 145, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Rat Defense, Harmonist"},
{"gid": 145, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Rat Defense, Harmonist"},
{"gid": 145, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Rat Defense, Harmonist"},
{"gid": 146, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Robatsch (Modern) Defense, Main Line"},
{"gid": 146, "fen":"rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Robatsch (Modern) Defense, Main Line"},
{"gid": 146, "fen":"rnbqkbnr/pppppp1p/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Robatsch (Modern) Defense, Main Line"},
{"gid": 146, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Robatsch (Modern) Defense, Main Line"},
{"gid": 146, "fen":"rnbqk1nr/ppppppbp/6p1/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Robatsch (Modern) Defense, Main Line"},
{"gid": 146, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Robatsch (Modern) Defense, Main Line"},
{"gid": 146, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Robatsch (Modern) Defense, Main Line"},
{"gid": 147, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r1bqk2r/ppp1bppp/2n5/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r1bqk2r/ppp1bppp/2n5/3p4/3Pn3/3B1N2/PPP2PPP/RNBQR1K1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r2qk2r/ppp1bppp/2n5/3p4/3Pn1b1/3B1N2/PPP2PPP/RNBQR1K1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r2qk2r/ppp1bppp/2n5/3p4/3Pn1b1/2PB1N2/PP3PPP/RNBQR1K1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r2qk2r/ppp1b1pp/2n5/3p1p2/3Pn1b1/2PB1N2/PP3PPP/RNBQR1K1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r2qk2r/ppp1b1pp/2n5/3p1p2/3Pn1b1/2PB1N2/PP1N1PPP/R1BQR1K1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r1bqk2r/ppp1bppp/2n5/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r1bqk2r/ppp1bppp/2n5/3p4/2PPn3/3B1N2/PP3PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r1bqk2r/ppp1bppp/8/3p4/1nPPn3/3B1N2/PP3PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r1bqk2r/ppp1bppp/8/3p4/1nPPn3/5N2/PP2BPPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r1bq1rk1/ppp1bppp/8/3p4/1nPPn3/5N2/PP2BPPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r1bqk2r/ppp1bppp/2n5/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r1bqk2r/ppp1bppp/2n5/3p4/3Pn3/3B1N2/PPP2PPP/RNBQR1K1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r2qk2r/ppp1bppp/2n5/3p1b2/3Pn3/3B1N2/PPP2PPP/RNBQR1K1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r1bqk2r/ppp1bppp/2n5/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r1bqk2r/ppp1bppp/2n5/3p4/3Pn3/3B1N2/PPP2PPP/RNBQR1K1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r2qk2r/ppp1bppp/2n5/3p4/3Pn1b1/3B1N2/PPP2PPP/RNBQR1K1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"r2qk2r/ppp1bppp/2n5/3p4/2PPn1b1/3B1N2/PP3PPP/RNBQR1K1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbq1rk1/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/3p4/8/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqkb1r/ppp2ppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp2ppp/3b4/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbqk2r/ppp2ppp/3b4/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbq1rk1/ppp2ppp/3b4/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbq1rk1/ppp2ppp/3b4/3p4/2PPn3/3B1N2/PP3PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 147, "fen":"rnbq1rk1/pp3ppp/2pb4/3p4/2PPn3/3B1N2/PP3PPP/RNBQ1RK1", "treename":"Russian Game, Classical Attack"},
{"gid": 148, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Cochrane Gambit"},
{"gid": 148, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Cochrane Gambit"},
{"gid": 148, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cochrane Gambit"},
{"gid": 148, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cochrane Gambit"},
{"gid": 148, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cochrane Gambit"},
{"gid": 148, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cochrane Gambit"},
{"gid": 148, "fen":"rnbqkb1r/ppp2Npp/3p1n2/8/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cochrane Gambit"},
{"gid": 148, "fen":"rnbq1b1r/ppp2kpp/3p1n2/8/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cochrane Gambit"},
{"gid": 148, "fen":"rnbq1b1r/ppp2kpp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Cochrane Gambit"},
{"gid": 149, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPPQPPP/RNB1KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnb1kb1r/ppp1qppp/3p4/8/4n3/5N2/PPPPQPPP/RNB1KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnb1kb1r/ppp1qppp/3p4/8/4n3/3P1N2/PPP1QPPP/RNB1KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnb1kb1r/ppp1qppp/3p1n2/8/8/3P1N2/PPP1QPPP/RNB1KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnb1kb1r/ppp1qppp/3p1n2/6B1/8/3P1N2/PPP1QPPP/RN2KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"r1b1kb1r/pppnqppp/3p1n2/6B1/8/3P1N2/PPP1QPPP/RN2KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPPQPPP/RNB1KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnb1kb1r/ppp1qppp/3p4/8/4n3/5N2/PPPPQPPP/RNB1KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnb1kb1r/ppp1qppp/3p4/8/4n3/3P1N2/PPP1QPPP/RNB1KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnb1kb1r/ppp1qppp/3p1n2/8/8/3P1N2/PPP1QPPP/RNB1KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnb1kb1r/ppp1qppp/3p1n2/6B1/8/3P1N2/PPP1QPPP/RN2KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 149, "fen":"rnb1kb1r/ppp2ppp/3p1n2/6B1/8/3P1N2/PPP1qPPP/RN2KB1R", "treename":"Russian Game, Cozio (Lasker) Attack"},
{"gid": 150, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Damiano Variation"},
{"gid": 150, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Damiano Variation"},
{"gid": 150, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Damiano Variation"},
{"gid": 150, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Damiano Variation"},
{"gid": 150, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Damiano Variation"},
{"gid": 150, "fen":"rnbqkb1r/pppp1ppp/8/4N3/4n3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Damiano Variation"},
{"gid": 151, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, French Attack"},
{"gid": 151, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, French Attack"},
{"gid": 151, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, French Attack"},
{"gid": 151, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, French Attack"},
{"gid": 151, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, French Attack"},
{"gid": 151, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, French Attack"},
{"gid": 151, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, French Attack"},
{"gid": 151, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, French Attack"},
{"gid": 151, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/3P1N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, French Attack"},
{"gid": 152, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Kaufmann Attack"},
{"gid": 152, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Kaufmann Attack"},
{"gid": 152, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Kaufmann Attack"},
{"gid": 152, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Kaufmann Attack"},
{"gid": 152, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Kaufmann Attack"},
{"gid": 152, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Kaufmann Attack"},
{"gid": 152, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Kaufmann Attack"},
{"gid": 152, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Kaufmann Attack"},
{"gid": 152, "fen":"rnbqkb1r/ppp2ppp/3p4/8/2P1n3/5N2/PP1P1PPP/RNBQKB1R", "treename":"Russian Game, Kaufmann Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pp3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r1bqkb1r/ppp2ppp/2n5/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pp3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r1bqkb1r/pppn1ppp/8/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r1bqkb1r/pppN1ppp/8/3p4/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r2qkb1r/pppb1ppp/8/3p4/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r2qkb1r/pppb1ppp/8/3p4/3Pn3/3B4/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r3kb1r/pppb1ppp/8/3p4/3Pn2q/3B4/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pp3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqk2r/ppp1bppp/8/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pp3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r1bqkb1r/pppn1ppp/8/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r1bqkb1r/pppn1ppp/8/3pN3/3Pn3/3B4/PPP1QPPP/RNB1K2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pp3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pp3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r1bqkb1r/pppn1ppp/8/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r1bqkb1r/pppN1ppp/8/3p4/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r2qkb1r/pppb1ppp/8/3p4/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r2qkb1r/pppb1ppp/8/3p4/3Pn3/3B4/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r2qk2r/pppb1ppp/3b4/3p4/3Pn3/3B4/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r2qk2r/pppb1ppp/3b4/3p4/2PPn3/3B4/PP3PPP/RNBQ1RK1", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r1bqkb1r/pppp1ppp/2n5/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r1bqkb1r/pppp1ppp/2n5/3Pp3/4n3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r1bqkb1r/pppp1ppp/2n5/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"r1bqkb1r/pppp1ppp/2n5/4p3/3PB3/5N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4P3/3p4/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4P3/3pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/1B2P3/3pn3/5N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/5N2/PPP2PPP/RNBQKB1R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/pppp1ppp/8/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pp3/3Pn3/3B1N2/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqkb1r/ppp2ppp/8/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqk2r/ppp2ppp/3b4/3pN3/3Pn3/3B4/PPP2PPP/RNBQK2R", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbqk2r/ppp2ppp/3b4/3pN3/3Pn3/3B4/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbq1rk1/ppp2ppp/3b4/3pN3/3Pn3/3B4/PPP2PPP/RNBQ1RK1", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbq1rk1/ppp2ppp/3b4/3pN3/2PPn3/3B4/PP3PPP/RNBQ1RK1", "treename":"Russian Game, Modern Attack"},
{"gid": 153, "fen":"rnbq1rk1/ppp2ppp/8/3pb3/2PPn3/3B4/PP3PPP/RNBQ1RK1", "treename":"Russian Game, Modern Attack"},
{"gid": 154, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Nimzowitsch Attack"},
{"gid": 154, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Nimzowitsch Attack"},
{"gid": 154, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Nimzowitsch Attack"},
{"gid": 154, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Nimzowitsch Attack"},
{"gid": 154, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Nimzowitsch Attack"},
{"gid": 154, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Nimzowitsch Attack"},
{"gid": 154, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Nimzowitsch Attack"},
{"gid": 154, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Nimzowitsch Attack"},
{"gid": 154, "fen":"rnbqkb1r/ppp2ppp/3p4/8/4n3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Russian Game, Nimzowitsch Attack"},
{"gid": 155, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Paulsen Attack"},
{"gid": 155, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Paulsen Attack"},
{"gid": 155, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Paulsen Attack"},
{"gid": 155, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Paulsen Attack"},
{"gid": 155, "fen":"rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Paulsen Attack"},
{"gid": 155, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Paulsen Attack"},
{"gid": 155, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/2N1P3/8/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Paulsen Attack"},
{"gid": 156, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Three Knights Game"},
{"gid": 156, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Russian Game, Three Knights Game"},
{"gid": 156, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Three Knights Game"},
{"gid": 156, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Russian Game, Three Knights Game"},
{"gid": 156, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Russian Game, Three Knights Game"},
{"gid": 156, "fen":"rnbqk2r/pppp1ppp/5n2/4p3/1b2P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Russian Game, Three Knights Game"},
{"gid": 156, "fen":"rnbqk2r/pppp1ppp/5n2/4N3/1b2P3/2N5/PPPP1PPP/R1BQKB1R", "treename":"Russian Game, Three Knights Game"},
{"gid": 157, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Alapin Defense"},
{"gid": 157, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Alapin Defense"},
{"gid": 157, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Alapin Defense"},
{"gid": 157, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Alapin Defense"},
{"gid": 157, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Alapin Defense"},
{"gid": 157, "fen":"r1bqk1nr/pppp1ppp/2n5/1B2p3/1b2P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Alapin Defense"},
{"gid": 157, "fen":"r1bqk1nr/pppp1ppp/2n5/1B2p3/1b2P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Alapin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1N3/4P3/8/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r2qkb1r/pppb1ppp/2np1n2/1B2p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r2qkb1r/pppb1ppp/2np1n2/1B2p3/3PP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r2qk2r/pppbbppp/2np1n2/1B2p3/3PP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r2qk2r/pppbbppp/2Bp1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r2qkb1r/pppb1ppp/2np1n2/1B2p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r2qkb1r/pppb1ppp/2np1n2/1B2p3/3PP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r2qkb1r/pppb1ppp/2np1n2/1B6/3pP3/2N2N2/PPP2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2nn4/1B2p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2Bn4/4p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/p1ppbppp/2pn4/4p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/p1ppbppp/2pn4/4P3/8/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/p1ppbppp/2p5/4Pn2/8/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/4P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/2P1P3/3P1N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/3PBN2/PPP2PPP/RN1QK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2nn4/1B2p3/3P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2Bn4/4p3/3P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2pn4/4p3/3P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2pn4/4P3/8/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2p5/4Pn2/8/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bQkb1r/ppp2ppp/2p5/4Pn2/8/5N2/PPP2PPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bk1b1r/ppp2ppp/2p5/4Pn2/8/5N2/PPP2PPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bk1b1r/ppp2ppp/2p5/4Pn2/8/2N2N2/PPP2PPP/R1B2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bk1b1r/ppp2pp1/2p4p/4Pn2/8/2N2N2/PPP2PPP/R1B2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2nn4/1B2p3/3P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2Bn4/4p3/3P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2pn4/4p3/3P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2pn4/4P3/8/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2p5/4Pn2/8/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bQkb1r/ppp2ppp/2p5/4Pn2/8/5N2/PPP2PPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bk1b1r/ppp2ppp/2p5/4Pn2/8/5N2/PPP2PPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bk1b1r/ppp2ppp/2p5/4Pn2/8/2N2N2/PPP2PPP/R1B2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1b1kb1r/ppp2ppp/2p5/4Pn2/8/2N2N2/PPP2PPP/R1B2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1b1kb1r/ppp2ppp/2p5/4Pn2/8/2N2N1P/PPP2PP1/R1B2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1b1kb1r/1pp2ppp/2p5/p3Pn2/8/2N2N1P/PPP2PP1/R1B2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2nn4/1B2p3/3P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2Bn4/4p3/3P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2pn4/4p3/3P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2pn4/4P3/8/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2p5/4Pn2/8/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bQkb1r/ppp2ppp/2p5/4Pn2/8/5N2/PPP2PPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bk1b1r/ppp2ppp/2p5/4Pn2/8/5N2/PPP2PPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bk1b1r/ppp2ppp/2p5/4Pn2/8/2N2N2/PPP2PPP/R1B2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1b1kb1r/ppp2ppp/2p5/4Pn2/8/2N2N2/PPP2PPP/R1B2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1b1kb1r/ppp2ppp/2p5/4Pn2/8/2N2N2/PPP2PPP/R1BR2K1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2P3/4n3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/3PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B6/3pP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B6/3pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2nn4/1B2p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2Bn4/4p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/p1ppbppp/2pn4/4p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/p1ppbppp/2pn4/4P3/8/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pnppbppp/2p5/4P3/8/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pnppbppp/2p5/4P3/8/1P3N2/P1P1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2nn4/1B2p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2Bn4/4p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/p1ppbppp/2pn4/4p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/p1ppbppp/2pn4/4P3/8/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pnppbppp/2p5/4P3/8/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pnppbppp/2p5/4P3/8/2N2N2/PPP1QPPP/R1B2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2nn4/1B2p3/8/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2nn4/1B2N3/8/8/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/1ppp1ppp/p1n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppp1bppp/2n5/1B1pp3/3Pn3/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2nn4/1B2p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2Bn4/4p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/p1ppbppp/2pn4/4p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/p1ppbppp/2pn4/4P3/8/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pnppbppp/2p5/4P3/8/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pnppbppp/2p5/4P3/3N4/8/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2nn4/1B2p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2Bn4/4p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/p1ppbppp/2pn4/4p3/3P4/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/p1ppbppp/2pn4/4P3/8/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pnppbppp/2p5/4P3/8/5N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/pnppbppp/2p5/4P3/2P5/5N2/PP2QPPP/RNB2RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqk2r/ppppbppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/4P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 158, "fen":"r1bqkb1r/ppp2ppp/2np1n2/1B2p3/4P3/2PP1N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Berlin Defense"},
{"gid": 159, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B2p3/3nP3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B2p3/3NP3/8/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B6/3pP3/8/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B6/3pP3/8/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqk1nr/pppp1ppp/8/1Bb5/3pP3/8/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqk1nr/pppp1ppp/8/1Bb5/3pP3/3P4/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqk1nr/pp1p1ppp/2p5/1Bb5/3pP3/3P4/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqk1nr/pp1p1ppp/2p5/2b5/2BpP3/3P4/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqk1nr/pp3ppp/2p5/2bp4/2BpP3/3P4/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B2p3/3nP3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B2p3/3NP3/8/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B6/3pP3/8/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B6/3pP3/3P4/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B2p3/3nP3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B2p3/3NP3/8/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B6/3pP3/8/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B6/3pP3/8/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pp1p1ppp/2p5/1B6/3pP3/8/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pp1p1ppp/2p5/8/2BpP3/8/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B2p3/3nP3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B2p3/3NP3/8/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/1B6/3pP3/8/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 159, "fen":"r1bqkbnr/pppp1ppp/8/8/2BpP3/8/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Bird Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1b1k1nr/pppp1ppp/2n2q2/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/3PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk2r/pppp1ppp/1bn2n2/1B2p3/3PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk2r/ppppnppp/2n5/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/1bn5/1B2p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp2pp/2n5/1Bb1pp2/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp2pp/2n5/1Bb1pp2/3PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bq1rk1/pppp1ppp/2n2n2/1Bb1p3/3PP3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bq1rk1/pppp1ppp/1bn2n2/1B2p3/3PP3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bq1rk1/pppp1ppp/1bn2n2/1B2p1B1/3PP3/2P2N2/PP3PPP/RN1Q1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1b1k1nr/pppp1ppp/2n2q2/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk2r/ppppnppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2B5/2b1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/8/1Bb1p3/3nP3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/8/2b1p3/2BnP3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/ppp2ppp/2np4/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/ppp2ppp/2np4/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/8/1Bb1p3/3nP3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/8/1Bb1p3/3NP3/8/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/8/1B2p3/3bP3/8/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/8/1B2p3/3bP3/2P5/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/1b6/1B2p3/4P3/2P5/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pppp1ppp/1b6/1B2p3/3PP3/2P5/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pp1p1ppp/1bp5/1B2p3/3PP3/2P5/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pp1p1ppp/1bp5/4p3/B2PP3/2P5/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pp3ppp/1bpp4/4p3/B2PP3/2P5/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 160, "fen":"r1bqk1nr/pp3ppp/1bpp4/4p3/B2PP3/N1P5/PP3PPP/R1BQ1RK1", "treename":"Ruy Lopez, Classical Variation"},
{"gid": 161, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/P3P3/1B3N2/1PPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/4p3/Pp2P3/1B3N2/1PPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/P3P3/1B3N2/1PPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2q1rk1/1bppbppp/p1n2n2/1p2p3/P3P3/1B3N2/1PPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2q1rk1/1bppbppp/p1n2n2/1p2p3/P3P3/1B1P1N2/1PP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/P3P3/1B1P1N2/1PP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/P3P3/1BPP1N2/1P3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/P3P3/1B3N2/1PPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2q1rk1/1bppbppp/p1n2n2/1p2p3/P3P3/1B3N2/1PPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2q1rk1/1bppbppp/p1n2n2/1p2p3/P3P3/1B1P1N2/1PP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/P3P3/1B1P1N2/1PP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/P3P3/1BNP1N2/1PP2PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/4bppp/p1pp1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/4bppp/p1pp1n2/np2p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1b2rk1/2q1bppp/p1pp1n2/np2p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2p1bppp/p1np1n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2qk2r/2p1bppp/p1np1n2/1p2p3/4P1b1/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N1P/PPPP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2q1rk1/1bppbppp/p1n2n2/1p2p3/4P3/1B3N1P/PPPP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2q1rk1/1bppbppp/p1n2n2/1p2p3/4P3/1B1P1N1P/PPP2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/4P3/1B1P1N1P/PPP2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B1P1N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 161, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1B1P1N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1pp1bppp/p1np1n2/4p3/B3P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1pp1bppp/p1np1n2/4p3/B3P3/2P2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1ppbbppp/p1np1n2/4p3/B3P3/2P2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1pp1bppp/p1np1n2/4p3/B3P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1pp1bppp/p1np1n2/4p3/B2PP3/2P2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1ppbbppp/p1np1n2/4p3/B2PP3/2P2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1ppbbppp/p1np1n2/4p3/B2PP3/2P2N2/PP1N1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qr1k1/1ppbbppp/p1np1n2/4p3/B2PP3/2P2N2/PP1N1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1Bp1n2/4p3/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p1pp1n2/4p3/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p1pp1n2/4p3/3PP3/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p1pp1n2/8/3pP3/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p1pp1n2/8/3NP3/8/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qk2r/1pp1bppp/p1np1n2/4p3/B3P1b1/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qk2r/1pp1bppp/p1np1n2/4p3/B3P1b1/2PP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qk2r/1pp1bppp/p1np1n2/4p3/B3P1b1/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qk2r/1pp1bppp/p1np1n2/4p3/B3P1b1/2P2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p1np1n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p2p1n2/np2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p2p1n2/np2p3/4P3/2P2N2/PPBP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/4bppp/p2p1n2/npp1p3/4P3/2P2N2/PPBP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/4bppp/p2p1n2/npp1p3/3PP3/2P2N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b1k2r/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b1k2r/2q1bppp/p2p1n2/npp1p3/P2PP3/2P2N2/1PB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/3PP3/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1np1n2/1p2p3/3PP1b1/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1np1n2/1p2p3/3PP1b1/1BP1BN2/PP3PPP/RN1QR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1np1n2/1p6/3pP1b1/1BP1BN2/PP3PPP/RN1QR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/3PP3/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1np1n2/1p2p3/3PP1b1/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1np1n2/1p1Pp3/4P1b1/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p2p1n2/np1Pp3/4P1b1/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p2p1n2/np1Pp3/4P1b1/2P2N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1q2rk1/2p1bppp/p2p1n2/np1Pp3/4P1b1/2P2N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/3PP3/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1np1n2/1p2p3/3PP1b1/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1np1n2/1p1Pp3/4P1b1/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p2p1n2/np1Pp3/4P1b1/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p2p1n2/np1Pp3/4P1b1/2P2N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/4bppp/p1pp1n2/np1Pp3/4P1b1/2P2N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/4bppp/p1pp1n2/np1Pp3/4P1b1/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p1pp1n2/np1Pp3/4P3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p1np1n2/1pp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1b1nbppp/p2p1n2/1pp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP2N/1BP4P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/8/B2pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/8/B2pP3/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p6/B2pP3/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2P3/B2p4/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p4n2/1p2n3/B2p4/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p4n2/1p2R3/B2p4/5N2/PPP2PPP/RNBQ2K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/8/B2pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4P3/B2p4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n5/4P3/B2pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n5/4P3/B2Nn3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p7/4P3/B2nn3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/8/B2pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4P3/B2p4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n5/4P3/B2pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n5/4P3/B2Nn3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1pppbppp/p1n5/4P3/B2Nn3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/8/B2pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/8/B2pP3/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1pppbppp/p1n2n2/8/B2pP3/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1pppbppp/p1n2n2/4P3/B2p4/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqnrk1/1pppbppp/p1n5/4P3/B2p4/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqnrk1/1pppbppp/p1n5/4P3/B2p4/2P2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/8/B2pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/8/B2pP3/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1pppbppp/p1n2n2/8/B2pP3/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1pppbppp/p1n2n2/4P3/B2p4/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqnrk1/1pppbppp/p1n5/4P3/B2p4/5N2/PPP2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqnrk1/1pppbppp/p1n5/4P3/B2p1B2/5N2/PPP2PPP/RN1QR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/nppPp3/4P3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/np1Pp3/2p1P3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p1np1n2/1pp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p1np1n2/1ppPp3/4P3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/nppPp3/4P3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r4rk1/1bq1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b1r1k1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1br2k1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r4rk1/2qbbppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r4rk1/2qbbppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r4rk1/2qbbppp/p2p1n2/1pp1p3/2nPP3/2P2N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p1np1n2/1pp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p1np1n2/1ppPp3/4P3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bn1rk1/2q1bppp/p2p1n2/1ppPp3/4P3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bn1rk1/2q1bppp/p2p1n2/1ppPp3/P3P3/2P2N1P/1PBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/np2p3/3pP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p1np1n2/1pp1p3/4P3/2PP1N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/P3P3/1BP2N2/1P1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1np1n2/1p2p3/P3P1b1/1BP2N2/1P1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1b2bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1b2bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/5N2/PPPP1PPP/RNB1QRK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1ppnbppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNB1QRK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1ppnbppp/p1p5/4p3/4P3/1P3N2/P1PP1PPP/RNB1QRK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qk2r/1pp1bppp/p1p2n2/4p3/4P1b1/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/5N2/PPPP1PPP/RNB1QRK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p4n2/2p1p3/4P3/5N2/PPPP1PPP/RNB1QRK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1ppnbppp/p1p5/4p3/4P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1ppnbppp/p1p5/4p3/4P3/3P1N2/PPPN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1ppnbppp/p1p5/4p3/4P3/3P1N2/PPPN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1ppnbppp/p1p5/4p3/2N1P3/3P1N2/PPP2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1ppnb1pp/p1p2p2/4p3/2N1P3/3P1N2/PPP2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1ppnb1pp/p1p2p2/4p3/2N1P2N/3P4/PPP2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1pp1b1pp/p1p2p2/2n1p3/2N1P2N/3P4/PPP2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/1pp1b1pp/p1p2p2/2n1pN2/2N1P3/3P4/PPP2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1p2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qk2r/1pp1bppp/p1p2n2/4p3/4P1b1/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qr1k1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qr1k1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2ppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2ppp/p1np1n2/1p1Pp3/4P3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rn1qrbk1/1bp2ppp/p2p1n2/1p1Pp3/4P3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qr1k1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qr1k1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2ppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2ppp/p1np1n2/1p2p3/P2PP3/1BP2N1P/1P1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2pp1/p1np1n1p/1p2p3/P2PP3/1BP2N1P/1P1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2pp1/p1np1n1p/1p2p3/P2PP3/2P2N1P/1PBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2pp1/p1np1n1p/1p6/P2pP3/2P2N1P/1PBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2pp1/p1np1n1p/1p6/P2PP3/5N1P/1PBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2pp1/p2p1n1p/1p6/Pn1PP3/5N1P/1PBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2pp1/p2p1n1p/1p6/Pn1PP3/5N1P/1P1N1PP1/RBBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1b3pp1/p2p1n1p/1pp5/Pn1PP3/5N1P/1P1N1PP1/RBBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qr1k1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qr1k1/1bp1bppp/p1np1n2/1p2p1N1/3PP3/1BP4P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qr1k1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qr1k1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2ppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2ppp/p1np1n2/1p2p3/3PP3/PBP2N1P/1P1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qr1k1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qr1k1/1bp1bppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2ppp/p1np1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2ppp/p1np1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/3nbppp/p2p4/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/3nbppp/p2p4/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/3nbppp/p2p4/np2p3/3pP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/2np1n2/pp2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2pnbppp/p1np4/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2pnbppp/p1np4/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2pn1ppp/p1np1b2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2pn1ppp/p1np1b2/1p2p3/P2PP3/1BP2N1P/1P3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bpn1ppp/p1np1b2/1p2p3/P2PP3/1BP2N1P/1P3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bpn1ppp/p1np1b2/1p2p3/P2PP3/NBP2N1P/1P3PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1npbn2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1npbn2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1np1n2/1p2p3/3PP3/1bP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/2p1bppp/p1np1n2/1p2p3/3PP3/1QP2N1P/PP3PP1/RNB1R1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B1P1N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1BN2N2/PPPP1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p1np1n2/1p2p3/4P3/1BN2N2/PPPP1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p1np1n2/1p1Np3/4P3/1B3N2/PPPP1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2PP1N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPB2PPP/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/4bpp1/p2p1n1p/npp1p3/4P3/2PP1N2/PPB2PPP/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2PP1N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPB2PPP/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqrbk1/5ppp/p2p1n2/npp1p3/4P3/2PP1N2/PPB2PPP/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2PP1N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2PP1N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p1np1n2/1pp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2PP1N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/4bppp/p2p1n2/npp1p3/4P3/2PP1N2/PPB2PPP/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/4bppp/p1np1n2/1pp1p3/4P3/2PP1N2/PPB2PPP/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqrbk1/2p2pp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqrbk1/2p2pp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2pp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2pp1/p1np1n1p/1p2p3/3PP3/1BP2NNP/PP3PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2pp1/p2p1n1p/np2p3/3PP3/1BP2NNP/PP3PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2pp1/p2p1n1p/np2p3/3PP3/2P2NNP/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/1bp2pp1/p2p1n1p/1p2p3/2nPP3/2P2NNP/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqrbk1/2p2pp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqrbk1/2p2pp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/2pb1pp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/R1BQRNK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/2pb1pp1/p1np1n1p/1p2p3/3PP3/1BP2NNP/PP3PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/2pb1pp1/p2p1n1p/np2p3/3PP3/1BP2NNP/PP3PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/2pb1pp1/p2p1n1p/np2p3/3PP3/2P2NNP/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qrbk1/3b1pp1/p2p1n1p/npp1p3/3PP3/2P2NNP/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP1BN1P/PP3PP1/RN1QR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/P2PP3/1BP2N1P/1P3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqrbk1/2p2pp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqrbk1/2p2pp1/p1np1n1p/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqr1k1/2p1bpp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqrbk1/2p2pp1/p1np1n1p/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqrbk1/2p2pp1/p1np1n1p/1p2p3/3PP3/PBP2N1P/1P1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/4P3/1BPP1N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/PBP2N2/1P1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2qk2r/1bppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1PQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1pp3/4P3/1BP2N2/PP1PQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1pp3/4P3/1BPP1N2/PP2QPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r2q1rk1/1bp1bppp/p1n2n2/1p1pp3/4P3/1BPP1N2/PP2QPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1PQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1PQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/3PP3/1BP2N2/PP2QPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1PQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1PQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1PQPPP/RNBR2K1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p1np1n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1PQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 162, "fen":"r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Ruy Lopez, Closed Variations"},
{"gid": 163, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Columbus Variation"},
{"gid": 163, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Columbus Variation"},
{"gid": 163, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Columbus Variation"},
{"gid": 163, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Columbus Variation"},
{"gid": 163, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Columbus Variation"},
{"gid": 163, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Columbus Variation"},
{"gid": 163, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Columbus Variation"},
{"gid": 163, "fen":"r1bqk1nr/1pppbppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Columbus Variation"},
{"gid": 164, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkb1r/ppppnppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkb1r/ppppnppp/2n5/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkb1r/ppppnp1p/2n3p1/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkb1r/ppppnppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkb1r/ppppnppp/2n5/1B2p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkb1r/ppppnppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkb1r/ppppnppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkb1r/ppppnp1p/2n3p1/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 164, "fen":"r1bqkb1r/ppppnp1p/2n3p1/1B2p3/4P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Cozio Defense"},
{"gid": 165, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/3PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"r1bqkbnr/1pp2ppp/p1p5/8/3pP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"r1bqkbnr/1pp2ppp/p1p5/8/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"r1b1kbnr/1pp2ppp/p1p5/8/3qP3/5N2/PPP2PPP/RNB1K2R", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"r1b1kbnr/1pp2ppp/p1p5/8/3NP3/8/PPP2PPP/RNB1K2R", "treename":"Ruy Lopez, Exchange"},
{"gid": 165, "fen":"r3kbnr/1ppb1ppp/p1p5/8/3NP3/8/PPP2PPP/RNB1K2R", "treename":"Ruy Lopez, Exchange"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r2qkbnr/1pp2ppp/p1p5/4p3/4P1b1/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r2qkbnr/1pp2ppp/p1p5/4p3/4P1b1/5N1P/PPPP1PP1/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r2qkbnr/1pp2pp1/p1p5/4p2p/4P1b1/5N1P/PPPP1PP1/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r2qkbnr/1pp2pp1/p1p5/4p2p/4P1b1/3P1N1P/PPP2PP1/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3kbnr/1pp2pp1/p1p2q2/4p2p/4P1b1/3P1N1P/PPP2PP1/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3kbnr/1pp2pp1/p1p2q2/4p2p/4P1b1/3P1N1P/PPPN1PP1/R1BQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1b1kbnr/1pp2ppp/p1pq4/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1b1kbnr/1pp2ppp/p1pq4/4p3/4P3/N4N2/PPPP1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1b1kbnr/2p2ppp/p1pq4/1p2p3/4P3/N4N2/PPPP1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1b1kbnr/1pp2ppp/p1pq4/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1b1kbnr/1pp2ppp/p1pq4/4p3/4P3/N4N2/PPPP1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3kbnr/1pp2ppp/p1pqb3/4p3/4P3/N4N2/PPPP1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1b1kbnr/1pp2ppp/p1pq4/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1b1kbnr/1pp2ppp/p1pq4/4p3/4P3/3P1N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/8/3pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/8/3QP3/5N2/PPP2PPP/RNB2RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r2qkbnr/1pp3pp/p1p2p2/4p3/3PP1b1/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r2qkbnr/1pp3pp/p1p2p2/4P3/4P1b1/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3kbnr/1pp3pp/p1p2p2/4P3/4P1b1/5N2/PPP2PPP/RNBq1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3kbnr/1pp3pp/p1p2p2/4P3/4P1b1/5N2/PPP2PPP/RNBR2K1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3kbnr/1pp3pp/p1p5/4p3/4P1b1/5N2/PPP2PPP/RNBR2K1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3kbnr/1pp3pp/p1p5/4p3/4P1b1/3R1N2/PPP2PPP/RNB3K1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3k1nr/1pp3pp/p1pb4/4p3/4P1b1/3R1N2/PPP2PPP/RNB3K1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/8/3pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/8/3NP3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkb1r/1pp1n1pp/p1p2p2/8/3NP3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/8/3pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/8/3NP3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p4p2/2p5/3NP3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p4p2/2p5/4P3/1N6/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1b1kbnr/1pp3pp/p4p2/2p5/4P3/1N6/PPP2PPP/RNBq1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1b1kbnr/1pp3pp/p4p2/2p5/4P3/1N6/PPP2PPP/RNBR2K1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3kbnr/1ppb2pp/p4p2/2p5/4P3/1N6/PPP2PPP/RNBR2K1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r2qkbnr/1pp3pp/p1p2p2/4p3/3PP1b1/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r2qkbnr/1pp3pp/p1p2p2/4p3/3PP1b1/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r2qk1nr/1pp3pp/p1pb1p2/4p3/3PP1b1/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/8/3pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/8/3NP3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p4p2/2p5/3NP3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p4p2/2p5/4P3/1N6/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1b1kbnr/1pp3pp/p4p2/2p5/4P3/1N6/PPP2PPP/RNBq1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1b1kbnr/1pp3pp/p4p2/2p5/4P3/1N6/PPP2PPP/RNBR2K1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3kbnr/1pp3pp/p4p2/2p5/4P1b1/1N6/PPP2PPP/RNBR2K1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3kbnr/1pp3pp/p4p2/2p5/4P1b1/1N3P2/PPP3PP/RNBR2K1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r3kbnr/1pp3pp/p3bp2/2p5/4P3/1N3P2/PPP3PP/RNBR2K1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/8/3pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/8/3NP3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p4p2/2p5/3NP3/8/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p4p2/2p5/4P3/8/PPP1NPPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp3pp/p1p2p2/4p3/3PP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqk1nr/1pp2ppp/p1pb4/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqk1nr/1pp2ppp/p1pb4/4p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqk1nr/1pp2ppp/p1pb4/8/3pP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/2pp1ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 166, "fen":"r1bqkb1r/1pp1nppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Exchange Variation"},
{"gid": 167, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1p1p/2n3p1/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1p1p/2n3p1/1B2p3/3PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1p1p/2n3p1/1B6/3pP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1p1p/2n3p1/1B4B1/3pP3/5N2/PPP2PPP/RN1QK2R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1p1p/2n3p1/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1p1p/2n3p1/1B2p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1p1p/2n3p1/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 167, "fen":"r1bqkbnr/pppp1p1p/2n3p1/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Fianchetto Defense"},
{"gid": 168, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1pp3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1Pp3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1np3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1nN3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/2p1bppp/p7/1p1nn3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/2p1bppp/p7/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/5ppp/p1pb4/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1bq1rk1/5ppp/p1pb4/1p1n4/3P4/1BP5/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P3q/1BP5/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P3q/1BP3P1/PP3P1P/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P4/1BP3Pq/PP3P1P/RNBQR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P4/1BP1B1Pq/PP3P1P/RN1QR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r4rk1/5ppp/p1pb4/1p1n4/3P2b1/1BP1B1Pq/PP3P1P/RN1QR1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"r4rk1/5ppp/p1pb4/1p1n4/3P2b1/1BPQB1Pq/PP3P1P/RN2R1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"4rrk1/5ppp/p1pb4/1p1n4/3P2b1/1BPQB1Pq/PP3P1P/RN2R1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"4rrk1/5ppp/p1pb4/1p1n4/3P2b1/1BPQB1Pq/PP1N1P1P/R3R1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"5rk1/5ppp/p1pbr3/1p1n4/3P2b1/1BPQB1Pq/PP1N1P1P/R3R1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"5rk1/5ppp/p1pbr3/1p1n4/P2P2b1/1BPQB1Pq/1P1N1P1P/R3R1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 168, "fen":"5rk1/5ppp/p1pbr3/1p1n3q/P2P2b1/1BPQB1P1/1P1N1P1P/R3R1K1", "treename":"Ruy Lopez, Marshall"},
{"gid": 169, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1pp3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1Pp3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1np3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1nN3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nn3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p1pb4/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p1pb4/1p1n4/3P4/1BP5/PP2RPPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1pp3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1Pp3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1np3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1nN3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nn3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p1pb4/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p1pb4/1p1n4/3P4/1BP5/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P3q/1BP5/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P3q/1BP3P1/PP3P1P/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P4/1BP3Pq/PP3P1P/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P4/1BP1B1Pq/PP3P1P/RN1QR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r4rk1/5ppp/p1pb4/1p1n4/3P2b1/1BP1B1Pq/PP3P1P/RN1QR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r4rk1/5ppp/p1pb4/1p1n4/3P2b1/1BPQB1Pq/PP3P1P/RN2R1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"4rrk1/5ppp/p1pb4/1p1n4/3P2b1/1BPQB1Pq/PP3P1P/RN2R1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"4rrk1/5ppp/p1pb4/1p1n4/3P2b1/1BPQB1Pq/PP1N1P1P/R3R1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"4rrk1/6pp/p1pb4/1p1n1p2/3P2b1/1BPQB1Pq/PP1N1P1P/R3R1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1pp3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1Pp3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1np3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1nN3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nn3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p1pb4/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p1pb4/1p1n4/3P4/1BP5/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P3q/1BP5/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P3q/1BP3P1/PP3P1P/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P4/1BP3Pq/PP3P1P/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3PR3/1BP3Pq/PP3P1P/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1pp3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1Pp3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1np3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1nN3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nn3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p1pb4/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p1pb4/1p1n4/3P4/1BP5/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P3q/1BP5/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P3q/1BP3P1/PP3P1P/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P4/1BP3Pq/PP3P1P/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/3P4/1BP1B1Pq/PP3P1P/RN1QR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r4rk1/5ppp/p1pb4/1p1n4/3P2b1/1BP1B1Pq/PP3P1P/RN1QR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r4rk1/5ppp/p1pb4/1p1n4/3P2b1/1BPQB1Pq/PP3P1P/RN2R1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"4rrk1/5ppp/p1pb4/1p1n4/3P2b1/1BPQB1Pq/PP3P1P/RN2R1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"4rrk1/5ppp/p1pb4/1p1n4/3P2b1/1BPQB1Pq/PP1N1P1P/R3R1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"5rk1/5ppp/p1pbr3/1p1n4/3P2b1/1BPQB1Pq/PP1N1P1P/R3R1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"5rk1/5ppp/p1pbr3/1p1n4/3P2b1/1BP1B1Pq/PP1N1P1P/R3RQK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1pp3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1Pp3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1np3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1nN3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nn3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/8/1BPP4/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p1pb4/1p1nR3/8/1BPP4/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p1pb4/1p1n4/8/1BPP4/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1b2rk1/5ppp/p1pb4/1p1n4/7q/1BPP4/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1pp3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1Pp3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1np3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n5/1p1nN3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nn3/8/1BP5/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p7/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p1p5/1p1BR3/8/2P5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p7/1p1pR3/8/2P5/PP1P1PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/4bppp/p7/1p1pR3/3P4/2P5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p2b4/1p1pR3/3P4/2P5/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/5ppp/p2b4/1p1p4/3P4/2P1R3/PP3PPP/RNBQ2K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1pp3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1Pp3/8/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 169, "fen":"r1bq1rk1/2p1bppp/p1n2n2/1p1P4/4p3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Marshall Attack"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1pp2ppp/p1np1n2/4p3/B3P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1pp2ppp/p1np1n2/4p3/B3P3/2PP1N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1pp2p1p/p1np1np1/4p3/B3P3/2PP1N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B1P1N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bppbppp/p1n2n2/1p2p3/4P3/1B1P1N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n5/1p2p3/4n3/1BP2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/3PP3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bpp1ppp/p1n2n2/1pb1p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bpp1ppp/p1n2n2/1pb1p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/p1np1n2/1pb1p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/p1np1n2/1pb1p3/3PP3/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/pbnp1n2/1p2p3/3PP3/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/pbnp1n2/1p2p3/P2PP3/1BP2N2/1P3PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bpp1ppp/p1n2n2/1pb1p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bpp1ppp/p1n2n2/1pb1p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/p1np1n2/1pb1p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/p1np1n2/1pb1p3/3PP3/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/pbnp1n2/1p2p3/3PP3/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/pbnp1n2/1p2p1B1/3PP3/1BP2N2/PP3PPP/RN1QR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bpp1ppp/p1n2n2/1pb1p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bpp1ppp/p1n2n2/1pb1p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/p1np1n2/1pb1p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/p1np1n2/1pb1p3/3PP3/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/pbnp1n2/1p2p3/3PP3/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk2r/1bp2ppp/pbnp1n2/1p2p3/3PP3/1BP1BN2/PP3PPP/RN1QR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BPP1N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/4P3/1BPP1N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/4P3/1BPP1N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/4P3/1BPP1N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qr1k1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qr1k1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1bpn1ppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1bpn1ppp/p2p1n2/1p2p3/3PP3/2P2NNP/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1bpn1p1p/p2p1np1/1p2p3/3PP3/2P2NNP/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1bpn1p1p/p2p1np1/1p2p3/P2PP3/2P2NNP/1PB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1b1n1p1p/p2p1np1/1pp1p3/P2PP3/2P2NNP/1PB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1b1n1p1p/p2p1np1/1ppPp3/P3P3/2P2NNP/1PB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1b1n1p1p/p2p1np1/1p1Pp3/P1p1P3/2P2NNP/1PB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qr1k1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qr1k1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1bpn1ppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1bpn1ppp/p2p1n2/1p2p3/3PP3/2P2NNP/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1b1n1ppp/p2p1n2/1pp1p3/3PP3/2P2NNP/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/2PPP3/1B3N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/3nbppp/p1pp1n2/1p2p3/2PPP3/1B3N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p1B1/3PP3/1BP2N1P/PP3PP1/RN1QR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qr1k1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qr1k1/1bpnbppp/p2p1n2/1p2p3/1P1PP3/2P2N1P/P1BN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/P2PP3/1BP2N1P/1P1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qr1k1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qr1k1/1bpnbppp/p2p1n2/1p2p3/P2PP3/2P2N1P/1PBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbq1rk1/2p1bppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP1N1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2q1rk1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qr1k1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qr1k1/1bpnbppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1bpn1ppp/p2p1n2/1p2p3/3PP3/2P2N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1bpn1ppp/p2p1n2/1p2p3/3PP3/2P2NNP/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1bpn1p1p/p2p1np1/1p2p3/3PP3/2P2NNP/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qrbk1/1bpn1p1p/p2p1np1/1p2p3/3PP3/1PP2NNP/P1B2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3pP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r4rk1/1bq1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r4rk1/1bq1bppp/p2p1n2/np1Pp3/4P3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"2r2rk1/1bq1bppp/p2p1n2/np1Pp3/4P3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3pP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p1np1n2/1p2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p1np1n2/1p2p3/3PP3/1N3N1P/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/2np1n2/pp2p3/3PP3/1N3N1P/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/2np1n2/pp2p3/3PP3/1N2BN1P/PPB2PP1/R2QR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/2np1n2/1p2p3/p2PP3/1N2BN1P/PPB2PP1/R2QR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/2np1n2/1p2p3/p2PP3/4BN1P/PPBN1PP1/R2QR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/3p1n2/1p2p3/pn1PP3/4BN1P/PPBN1PP1/R2QR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3pP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1br2k1/2q1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3pP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p1np1n2/1p2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p1np1n2/1p2p3/3PP3/1N3N1P/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/2np1n2/pp2p3/3PP3/1N3N1P/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/2np1n2/pp2p3/3PP3/1N2BN1P/PPB2PP1/R2QR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/2np1n2/1p2p3/p2PP3/1N2BN1P/PPB2PP1/R2QR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/2np1n2/1p2p3/p2PP3/4BN1P/PPBN1PP1/R2QR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r4rk1/2qbbppp/2np1n2/1p2p3/p2PP3/4BN1P/PPBN1PP1/R2QR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3pP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p1np1n2/1p2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p1np1n2/1p1Pp3/4P3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3pP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r4rk1/1bq1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r4rk1/1bq1bppp/p2p1n2/np2p3/3PP3/5N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"2r2rk1/1bq1bppp/p2p1n2/np2p3/3PP3/5N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"2r2rk1/1bq1bppp/p2p1n2/np2p3/3PP3/5N1P/PPB1RPP1/R1BQ1NK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3pP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p1np1n2/1p2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p1np1n2/1p2p3/3PP3/P4N1P/1PBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bq1rk1/4bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3pP3/2P2N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1b2rk1/2q1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r4rk1/2qbbppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r4rk1/2qbbppp/p2p1n2/np2p3/3PP3/5N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"2r2rk1/2qbbppp/p2p1n2/np2p3/3PP3/5N1P/PPB2PP1/R1BQRNK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"2r2rk1/2qbbppp/p2p1n2/np2p3/3PP3/4NN1P/PPB2PP1/R1BQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk1nr/1ppp1ppp/p1n5/2b1p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1pppnppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1pp2ppp/p1np1n2/4p3/B3P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1pp2ppp/p1np1n2/4p3/B1P1P3/3P1N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1p1p/p1n3p1/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p4n2/4p3/B2nP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/8/B2pP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1p1p/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1p1p/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1ppb1pbp/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1ppb1pbp/p1np2p1/4P3/B3P3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1ppb1pbp/p1n3p1/4p3/B3P3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1p1p/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1p1p/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1ppb1pbp/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1ppb1pbp/p1np2p1/4P3/B3P3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1ppb1pbp/p2p2p1/4n3/B3P3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1p1p/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1p1p/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1ppb1pbp/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1ppb1pbp/p1np2p1/3Pp3/B3P3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1p1p/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1p1p/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1ppb1pbp/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1ppb1pbp/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1pp1nppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1Bp4/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/2p2ppp/p1pp4/4p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/2p2ppp/p1pp4/4p3/3PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/2p3pp/p1pp1p2/4p3/3PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2p1p/p1np2p1/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1ppb1ppp/p1np1n2/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp3pp/p1np4/4pp2/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp3pp/p1np4/4pP2/B7/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1pp3pp/p1np4/4pb2/B7/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1pp3pp/p1np4/4pb2/B7/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1pp3pp/p1np4/4p3/B7/2Pb1N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1pp3pp/p1np4/4p3/B7/2Pb1N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1pp1b1pp/p1np4/4p3/B7/2Pb1N2/PP1P1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1pp1b1pp/p1np4/4p3/8/2Pb1N2/PPBP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1pp1b1pp/p1np4/4p3/8/2P2N2/PPbP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qk1nr/1pp1b1pp/p1np4/4p3/8/2P2N2/PPQP1PPP/RNB1R1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B1P1P3/5N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B2PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1ppbnppp/p1np4/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1ppbnppp/p1np4/4p3/3PP3/1BP2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1pp2ppp/p1np4/4p3/B3P1b1/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1pp2ppp/p1np4/4p3/B3P1b1/5N1P/PPPP1PP1/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkbnr/1ppb1ppp/p1np4/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1ppbnppp/p1np4/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r2qkb1r/1ppbnppp/p1np4/4p3/B2PP3/2P1BN2/PP3PPP/RN1QK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1ppp1ppp/p1n2n2/2b1p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1ppp1ppp/p1n2n2/2b1N3/B3P3/8/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1ppp1ppp/p1n2n2/2b1p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1ppp1ppp/p1n2n2/2b1p3/B3P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/2pp1ppp/p1n5/1p2p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/2pp1ppp/p1n5/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/2pp1ppp/p7/np2p3/4P3/1B3N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp2pp/p1n5/4pp2/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp2pp/p1n5/4pp2/B2PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp2pp/p1n5/5p2/B2pP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp2pp/p1n5/4Pp2/B2p4/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1pp2ppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1pp2ppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1pp2ppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1pp2ppp/p1np1n2/4p3/B3P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2pp1ppp/p1n2n2/1pb1p3/4P3/1B3N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2pp1ppp/p1n2n2/1pb1p3/4P3/1BP2N2/PP1PQPPP/RNB1K2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2pp1ppp/p1n2n2/1pb1p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2pp1ppp/p1n2n2/1pb1p3/P3P3/1B3N2/1PPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"1rbqk2r/2pp1ppp/p1n2n2/1pb1p3/P3P3/1B3N2/1PPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2pp1ppp/p1n2n2/1pb1p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2pp1ppp/p1n2n2/1pb1N3/4P3/1B6/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2pp1ppp/p4n2/1pb1n3/4P3/1B6/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2pp1ppp/p4n2/1pb1n3/3PP3/1B6/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2pp1ppp/p1n2n2/1pb1p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2pp1ppp/p1n2n2/1pb1p3/4P3/1BP2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2p2ppp/p1np1n2/1pb1p3/4P3/1BP2N2/PP1P1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2p2ppp/p1np1n2/1pb1p3/3PP3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 170, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/3PP3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Morphy Defense"},
{"gid": 171, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNBR2K1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNBR2K1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/2P1n3/1B3N2/PP2QPPP/RNBR2K1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2q1rk1/2p1bppp/p1n1b3/3pP3/2p1n3/1B3N2/PP2QPPP/RNBR2K1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2q1rk1/2p1bppp/p1n1b3/3pP3/2B1n3/5N2/PP2QPPP/RNBR2K1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r4rk1/2pqbppp/p1n1b3/3pP3/2B1n3/5N2/PP2QPPP/RNBR2K1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1BPQ1N2/PP3PPP/RNB2RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r2qk2r/2p1nppp/p3b3/1pbpP3/4n3/1BPQ1N2/PP3PPP/RNB2RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 171, "fen":"r1bqk2r/1pppbppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open"},
{"gid": 172, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2nn4/1B2p3/3P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2nn4/1B2P3/8/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2nn4/1B2p3/3P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 172, "fen":"r1bqkb1r/pppp1ppp/2nn4/4p3/B2P4/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Berlin Defense"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/2P2N2/PPB2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n5/1pnpP3/6b1/2P2N2/PPB2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n5/1pnpP3/6b1/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n5/1pnpP3/6b1/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n5/1pnpP3/6b1/2P2N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n5/1pnpP3/6b1/2P2N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/2P2N2/PPB2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n5/1pnpP3/6b1/2P2N2/PPB2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n5/1pnpP3/6b1/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n5/1pnpP3/6b1/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n5/1pnpP3/6b1/2P2N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r3k2r/2pqbppp/p1n5/1pnpP3/6b1/2P2N2/PPBN1PPP/R1BQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/2P2N2/PPB2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n5/1pnpP3/6b1/2P2N2/PPB2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n5/1pnpP3/6b1/2P2N2/PPB2PPP/RNBQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPPN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1B3N2/PPPN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPPN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/1B3N2/PPPN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pn1P3/3p4/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pn1P3/3P4/1B3N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPPN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/1B3N2/PPPN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pn1P3/3p4/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1B3/1pn1P3/3p4/2P2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/3Nn3/1BP5/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p3b3/1p1pn3/3Nn3/1BP5/PP3PPP/RNBQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1pnpP3/8/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1b1pp/p1n1b3/1p1pPp2/4n3/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1b1pp/p1n1bP2/1p1p4/4n3/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP1BN2/PP3PPP/RN1Q1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1b1pp/p1n1b3/1p1pPp2/4n3/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1b1pp/p1n1b3/1p1pPp2/4n3/1NP2N2/PPB2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p2ppp/p1n1b3/1pbpP3/4n3/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p2ppp/p1n1b3/1pbpP3/8/2P2N2/PPBN1nPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p2ppp/p1n1b3/1pbpP3/8/2P2N2/PPBN1RPP/R1BQ2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p3pp/p1n1bp2/1pbpP3/8/2P2N2/PPBN1RPP/R1BQ2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p3pp/p1n1bP2/1pbp4/8/2P2N2/PPBN1RPP/R1BQ2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p3pp/p1n1bP2/1p1p4/8/2P2N2/PPBN1bPP/R1BQ2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p3pp/p1n1bP2/1p1p4/8/2P2N2/PPBN1KPP/R1BQ4", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r4rk1/2p3pp/p1n1bq2/1p1p4/8/2P2N2/PPBN1KPP/R1BQ4", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r4rk1/2p3pp/p1n1bq2/1p1p4/8/2P2N2/PPB2KPP/R1BQ1N2", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1B3N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNBR2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNBR2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/2P1n3/1B3N2/PP2QPPP/RNBR2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/3pP3/2p1n3/1B3N2/PP2QPPP/RNBR2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/3pP3/2B1n3/5N2/PP2QPPP/RNBR2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p2ppp/p1n1b3/2bpP3/2B1n3/5N2/PP2QPPP/RNBR2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNB2RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNBR2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNBR2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP2QPPP/RNBR2K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPPN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/1B3N2/PPPN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pn1P3/3p4/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1pn1P1N1/3p4/1BP5/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/P3n3/1B3N2/1PP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B2BN2/PPP2PPP/RN1Q1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP1NQPPP/R1B2RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/2n1p3/B7/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1B5/2n1p3/8/5N2/PPPP1PPP/RNBQR1K1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/8/B2pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p2ppp/p1n1b3/1pbpP3/4n3/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p3pp/p1n1b3/1pbpPp2/4n3/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p2ppp/p1n1b3/1pbpP3/4n3/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p2ppp/p1n5/1pbpPb2/4n3/2P2N2/PPBN1PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r2q1rk1/2p2ppp/p1n5/1pbpPb2/4n3/1NP2N2/PPB2PPP/R1BQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pp3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 173, "fen":"r1bqkb1r/2p2ppp/p1n5/1p1pN3/3Pn3/1B6/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Open Variations"},
{"gid": 174, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2p3/4p3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2p3/4N3/5N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/ppp3pp/2n5/1B1pp3/4N3/5N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/ppp3pp/2n5/1B1pN3/4N3/8/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/ppp3pp/2n5/1B2N3/4p3/8/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/ppp3pp/2N5/1B6/4p3/8/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1b1kbnr/ppp3pp/2N5/1B4q1/4p3/8/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/8/1B2pp2/3nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/8/4pp2/B2nP3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkb1r/pppp2pp/2n2n2/1B2pp2/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkb1r/pppp2pp/2n2n2/1B2pP2/8/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2B5/4pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/ppp3pp/2p5/4pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2p3/4p3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2p3/4N3/5N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/ppp3pp/2n5/1B1pp3/4N3/5N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/ppp3pp/2n5/1B1pN3/4N3/8/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/ppp3pp/2n5/1B2N3/4p3/8/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/ppp3pp/2N5/1B6/4p3/8/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1b1kbnr/ppp3pp/2N5/1B1q4/4p3/8/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/3PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2p3/4p3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2p3/4N3/5N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkb1r/pppp2pp/2n2n2/1B2p3/4N3/5N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkb1r/pppp2pp/2n2n2/1B2p3/4N3/5N2/PPPPQPPP/R1B1K2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2p3/4p3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2p3/4N3/5N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkb1r/pppp2pp/2n2n2/1B2p3/4N3/5N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkb1r/pppp2pp/2n2N2/1B2p3/8/5N2/PPPP1PPP/R1BQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2p3/4p3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkbnr/pppp2pp/2n5/1B2p3/4P3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkb1r/pppp2pp/2n2n2/1B2p3/4P3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 174, "fen":"r1bqkb1r/pppp2pp/2n2n2/1B2p3/4P3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Schliemann Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B2p3/3PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B6/3pP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B6/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B2p3/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B2p3/3PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B6/3pP3/5N2/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 175, "fen":"r1bqkbnr/ppp2ppp/2np4/1B6/3NP3/8/PPP2PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense"},
{"gid": 176, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"r1bqkb1r/1pp2ppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"r1bqkb1r/1pp2ppp/p1Bp1n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"r1bqkb1r/2p2ppp/p1pp1n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"r1bqkb1r/2p2ppp/p1pp1n2/4p3/3PP3/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 176, "fen":"r2qkb1r/2p2ppp/p1pp1n2/4p3/3PP1b1/5N2/PPP2PPP/RNBQ1RK1", "treename":"Ruy Lopez, Steinitz Defense Deferred"},
{"gid": 177, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqkbnr/1ppp1ppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/B3P3/5N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqkb1r/2pp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB1K2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqk2r/2ppbppp/p1n2n2/1p2p3/3PP3/1B3N2/PPP1QPPP/RNB1K2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqk2r/2p1bppp/p1np1n2/1p2p3/3PP3/1B3N2/PPP1QPPP/RNB1K2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r1bqk2r/2p1bppp/p1np1n2/1p2p3/3PP3/1BP2N2/PP2QPPP/RNB1K2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 177, "fen":"r2qk2r/2p1bppp/p1np1n2/1p2p3/3PP1b1/1BP2N2/PP2QPPP/RNB1K2R", "treename":"Ruy Lopez, Wormald Attack"},
{"gid": 178, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Bronstein Variation"},
{"gid": 178, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Bronstein Variation"},
{"gid": 178, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Bronstein Variation"},
{"gid": 178, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Bronstein Variation"},
{"gid": 178, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Bronstein Variation"},
{"gid": 178, "fen":"rnb1kbnr/ppp1pppp/3q4/8/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Bronstein Variation"},
{"gid": 178, "fen":"rnb1kbnr/ppp1pppp/3q4/8/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Bronstein Variation"},
{"gid": 178, "fen":"rnb1kb1r/ppp1pppp/3q1n2/8/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Bronstein Variation"},
{"gid": 178, "fen":"rnb1kb1r/ppp1pppp/3q1n2/8/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Bronstein Variation"},
{"gid": 178, "fen":"rnb1kb1r/1pp1pppp/p2q1n2/8/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Bronstein Variation"},
{"gid": 179, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Classical Variation"},
{"gid": 179, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Classical Variation"},
{"gid": 179, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Classical Variation"},
{"gid": 179, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Classical Variation"},
{"gid": 179, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Classical Variation"},
{"gid": 179, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Classical Variation"},
{"gid": 179, "fen":"rnb1kbnr/ppp1pppp/8/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Classical Variation"},
{"gid": 179, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Classical Variation"},
{"gid": 179, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Classical Variation"},
{"gid": 179, "fen":"rn2kb1r/ppp1pppp/5n2/q4b2/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Classical Variation"},
{"gid": 179, "fen":"rn2kb1r/ppp1pppp/5n2/q4b2/2BP4/2N2N2/PPP2PPP/R1BQK2R", "treename":"Scandinavian Defense, Classical Variation"},
{"gid": 180, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rnb1kbnr/ppp1pppp/8/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rn2kb1r/ppp1pppp/5n2/q4b2/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rn2kb1r/ppp1pppp/5n2/q3Nb2/3P4/2N5/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rn2kb1r/pp2pppp/2p2n2/q3Nb2/3P4/2N5/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 180, "fen":"rn2kb1r/pp2pppp/2p2n2/q3Nb2/3P2P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Scandinavian Defense, Grunfeld Variation"},
{"gid": 181, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Icelandic-Palme Gambit"},
{"gid": 181, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Icelandic-Palme Gambit"},
{"gid": 181, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Icelandic-Palme Gambit"},
{"gid": 181, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Icelandic-Palme Gambit"},
{"gid": 181, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Icelandic-Palme Gambit"},
{"gid": 181, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Icelandic-Palme Gambit"},
{"gid": 181, "fen":"rnbqkb1r/ppp2ppp/4Pn2/8/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Icelandic-Palme Gambit"},
{"gid": 181, "fen":"rn1qkb1r/ppp2ppp/4bn2/8/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Icelandic-Palme Gambit"},
{"gid": 181, "fen":"rn1qkb1r/ppp2ppp/4bn2/8/2P5/5N2/PP1P1PPP/RNBQKB1R", "treename":"Scandinavian Defense, Icelandic-Palme Gambit"},
{"gid": 182, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Lasker Variation"},
{"gid": 182, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Lasker Variation"},
{"gid": 182, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Lasker Variation"},
{"gid": 182, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Lasker Variation"},
{"gid": 182, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Lasker Variation"},
{"gid": 182, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Lasker Variation"},
{"gid": 182, "fen":"rnb1kbnr/ppp1pppp/8/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Lasker Variation"},
{"gid": 182, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Lasker Variation"},
{"gid": 182, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Lasker Variation"},
{"gid": 182, "fen":"rn2kb1r/ppp1pppp/5n2/q7/3P2b1/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Lasker Variation"},
{"gid": 182, "fen":"rn2kb1r/ppp1pppp/5n2/q7/3P2b1/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Scandinavian Defense, Lasker Variation"},
{"gid": 183, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/pp2pppp/2p2n2/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/pp2pppp/2p2n2/q7/2BP4/2N2N2/PPP2PPP/R1BQK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp2pppp/2p2n2/q7/2BP2b1/2N2N2/PPP2PPP/R1BQK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPPB1PPP/R2QKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/pp2pppp/2p2n2/q7/3P4/2N5/PPPB1PPP/R2QKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/2BP4/2N5/PPP2PPP/R1BQK1NR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/pp2pppp/2p2n2/q7/2BP4/2N5/PPP2PPP/R1BQK1NR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/pp2pppp/2p2n2/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/pp2pppp/2p2n2/q7/2BP4/2N2N2/PPP2PPP/R1BQK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp2pppp/2p2n2/q4b2/2BP4/2N2N2/PPP2PPP/R1BQK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp2pppp/2p2n2/q4b2/2BP4/2N2N2/PPPB1PPP/R2QK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp3ppp/2p1pn2/q4b2/2BP4/2N2N2/PPPB1PPP/R2QK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp3ppp/2p1pn2/q4b2/2BP4/2N2N2/PPPBQPPP/R3K2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2k2r/pp3ppp/2p1pn2/q4b2/1bBP4/2N2N2/PPPBQPPP/R3K2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2k2r/pp3ppp/2p1pn2/q4b2/1bBP4/P1N2N2/1PPBQPPP/R3K2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/pp2pppp/2p2n2/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/pp2pppp/2p2n2/q7/2BP4/2N2N2/PPP2PPP/R1BQK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp2pppp/2p2n2/q4b2/2BP4/2N2N2/PPP2PPP/R1BQK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp2pppp/2p2n2/q4b2/2BP4/2N2N2/PPPB1PPP/R2QK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp3ppp/2p1pn2/q4b2/2BP4/2N2N2/PPPB1PPP/R2QK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp3ppp/2p1pn2/q2N1b2/2BP4/5N2/PPPB1PPP/R2QK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/pp2pppp/2p2n2/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/pp2pppp/2p2n2/q7/2BP4/2N2N2/PPP2PPP/R1BQK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp2pppp/2p2n2/q4b2/2BP4/2N2N2/PPP2PPP/R1BQK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp2pppp/2p2n2/q4b2/2BP4/2N2N2/PPPB1PPP/R2QK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp3ppp/2p1pn2/q4b2/2BP4/2N2N2/PPPB1PPP/R2QK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2kb1r/pp3ppp/2p1pn2/q4b2/2BP4/2N2N2/PPPBQPPP/R3K2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2k2r/pp3ppp/2p1pn2/q4b2/1bBP4/2N2N2/PPPBQPPP/R3K2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rn2k2r/pp3ppp/2p1pn2/q4b2/1bBP4/2N2N2/PPPBQPPP/2KR3R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/2B5/2N5/PPPP1PPP/R1BQK1NR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/2B5/2N5/PPPP1PPP/R1BQK1NR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/8/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kb1r/ppp1pppp/5n2/q7/8/2N2N2/PPPPBPPP/R1BQK2R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/pp2pppp/2p5/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/pp2pppp/2p5/q7/2BP4/2N5/PPP2PPP/R1BQK1NR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/ppp1pppp/8/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/pp2pppp/2p5/q7/3P4/2N5/PPP2PPP/R1BQKBNR", "treename":"Scandinavian Defense, Main Line"},
{"gid": 183, "fen":"rnb1kbnr/pp2pppp/2p5/q7/3P4/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Scandinavian Defense, Main Line"},
{"gid": 184, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/8/3n4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/8/3n4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/1n6/8/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/1n6/8/2PP4/5N2/PP3PPP/RNBQKB1R", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pp1p/1n4p1/8/2PP4/5N2/PP3PPP/RNBQKB1R", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pp1p/1n4p1/8/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/2PP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/8/3n4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/8/3n4/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rn1qkb1r/ppp1pppp/8/3n1b2/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/8/3n4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/8/3n4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/1n6/8/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/1n6/8/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp2ppp/1n6/4p3/2PP4/2N5/PP3PPP/R1BQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/8/3n4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/8/3n4/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/1n6/8/2PP4/8/PP3PPP/RNBQKBNR", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rnbqkb1r/ppp1pppp/1n6/8/2PP4/5N2/PP3PPP/RNBQKB1R", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rn1qkb1r/ppp1pppp/1n6/8/2PP2b1/5N2/PP3PPP/RNBQKB1R", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 184, "fen":"rn1qkb1r/ppp1pppp/1n6/2P5/3P2b1/5N2/PP3PPP/RNBQKB1R", "treename":"Scandinavian Defense, Marshall Variation"},
{"gid": 185, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnb1kbnr/ppp1pppp/8/3q4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnb1kb1r/ppp1pppp/5n2/3q4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/ppp1pppp/8/8/8/2N5/PPPP1PPP/R1BQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnb1kb1r/ppp1pppp/5n2/3q4/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnb1kbnr/ppp1pppp/8/3q4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 185, "fen":"rnb1kbnr/ppp2ppp/8/3qp3/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Mieses-Kotroc Variation"},
{"gid": 186, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/8/3n4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/8/3n4/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rn1qkb1r/ppp1pppp/8/3n4/3P2b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rn1qkb1r/ppp1pppp/8/3n4/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rn1qkb1r/ppp2ppp/4p3/3n4/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/8/3n4/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/5n2/1B1P4/8/8/PPPP1PPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"r1bqkb1r/pppnpppp/5n2/1B1P4/8/8/PPPP1PPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/5n2/1B1P4/8/8/PPPP1PPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rn1qkb1r/pppbpppp/5n2/1B1P4/8/8/PPPP1PPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rn1qkb1r/pppbpppp/5n2/3P4/8/8/PPPPBPPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rn1qkb1r/pppbpppp/8/3n4/8/8/PPPPBPPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rn1qkb1r/pppbpppp/8/3n4/3P4/8/PPP1BPPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rn1qkb1r/ppp1pppp/8/3n1b2/3P4/8/PPP1BPPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rnbqkb1r/ppp1pppp/5n2/1B1P4/8/8/PPPP1PPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rn1qkb1r/pppbpppp/5n2/1B1P4/8/8/PPPP1PPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rn1qkb1r/pppbpppp/5n2/3P4/2B5/8/PPPP1PPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 186, "fen":"rn1qkb1r/ppp1pppp/5n2/3P4/2B3b1/8/PPPP1PPP/RNBQK1NR", "treename":"Scandinavian Defense, Modern Variation"},
{"gid": 187, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Panov Transfer"},
{"gid": 187, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Panov Transfer"},
{"gid": 187, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Panov Transfer"},
{"gid": 187, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Panov Transfer"},
{"gid": 187, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Panov Transfer"},
{"gid": 187, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Panov Transfer"},
{"gid": 187, "fen":"rnbqkb1r/pp2pppp/2P2n2/8/2P5/8/PP1P1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Panov Transfer"},
{"gid": 188, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/5P2/PPP3PP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P1b2/3P4/5P2/PPP3PP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/1B1P1b2/3P4/5P2/PPP3PP/RNBQK1NR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"r2qkb1r/pppnpppp/5n2/1B1P1b2/3P4/5P2/PPP3PP/RNBQK1NR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"r2qkb1r/pppnpppp/5n2/1B1P1b2/2PP4/5P2/PP4PP/RNBQK1NR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn2kb1r/ppp1pppp/5n2/3q4/3P2b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn2kb1r/ppp1pppp/5n2/3q4/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"r3kb1r/ppp1pppp/2n2n2/3q4/3P2b1/5N2/PPP1BPPP/RNBQK2R", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/8/PPP1BPPP/RNBQK1NR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP1bPPP/RNBQK1NR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/5P2/PPP3PP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P1b2/3P4/5P2/PPP3PP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 188, "fen":"rn1qkb1r/ppp1pppp/5n2/3P1b2/2PP4/5P2/PP4PP/RNBQKBNR", "treename":"Scandinavian Defense, Portuguese Variation"},
{"gid": 189, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Richter Variation"},
{"gid": 189, "fen":"rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Richter Variation"},
{"gid": 189, "fen":"rnbqkbnr/ppp1pppp/8/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Richter Variation"},
{"gid": 189, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR", "treename":"Scandinavian Defense, Richter Variation"},
{"gid": 189, "fen":"rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Richter Variation"},
{"gid": 189, "fen":"rnbqkb1r/ppp1pppp/8/3n4/3P4/8/PPP2PPP/RNBQKBNR", "treename":"Scandinavian Defense, Richter Variation"},
{"gid": 189, "fen":"rnbqkb1r/ppp1pppp/8/3n4/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Scandinavian Defense, Richter Variation"},
{"gid": 189, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Scandinavian Defense, Richter Variation"},
{"gid": 189, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/5N2/PPP1BPPP/RNBQK2R", "treename":"Scandinavian Defense, Richter Variation"},
{"gid": 189, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/5N2/PPP1BPPP/RNBQK2R", "treename":"Scandinavian Defense, Richter Variation"},
{"gid": 190, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Blumenfeld Attack"},
{"gid": 190, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Blumenfeld Attack"},
{"gid": 190, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Blumenfeld Attack"},
{"gid": 190, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Blumenfeld Attack"},
{"gid": 190, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Blumenfeld Attack"},
{"gid": 190, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Blumenfeld Attack"},
{"gid": 190, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Blumenfeld Attack"},
{"gid": 190, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Blumenfeld Attack"},
{"gid": 190, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/4B3/PPP2PPP/RN1QKB1R", "treename":"Scotch Game, Blumenfeld Attack"},
{"gid": 190, "fen":"r1b1k1nr/pppp1ppp/2n2q2/2b5/3NP3/4B3/PPP2PPP/RN1QKB1R", "treename":"Scotch Game, Blumenfeld Attack"},
{"gid": 190, "fen":"r1b1k1nr/pppp1ppp/2n2q2/1Nb5/4P3/4B3/PPP2PPP/RN1QKB1R", "treename":"Scotch Game, Blumenfeld Attack"},
{"gid": 191, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/4B3/PPP2PPP/RN1QKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k1nr/pppp1ppp/2n2q2/2b5/3NP3/4B3/PPP2PPP/RN1QKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k1nr/pppp1ppp/2n2q2/2b5/3NP3/2P1B3/PP3PPP/RN1QKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k2r/ppppnppp/2n2q2/2b5/3NP3/2P1B3/PP3PPP/RN1QKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k2r/ppppnppp/2n2q2/2b5/3NP3/2P1B3/PP1Q1PPP/RN2KB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqk1nr/pppp1ppp/2N5/2b5/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k1nr/pppp1ppp/2N2q2/2b5/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k1nr/pppp1ppp/2N2q2/2b5/4P3/8/PPPQ1PPP/RNB1KB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k1nr/ppp2ppp/2p2q2/2b5/4P3/8/PPPQ1PPP/RNB1KB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k1nr/ppp2ppp/2p2q2/2b5/4P3/2N5/PPPQ1PPP/R1B1KB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/4B3/PPP2PPP/RN1QKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k1nr/pppp1ppp/2n2q2/2b5/3NP3/4B3/PPP2PPP/RN1QKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k1nr/pppp1ppp/2n2q2/2b5/3NP3/2P1B3/PP3PPP/RN1QKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k2r/ppppnppp/2n2q2/2b5/3NP3/2P1B3/PP3PPP/RN1QKB1R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k2r/ppppnppp/2n2q2/2b5/2BNP3/2P1B3/PP3PPP/RN1QK2R", "treename":"Scotch Game, Classical Variation"},
{"gid": 191, "fen":"r1b1k2r/ppppnppp/5q2/2b1n3/2BNP3/2P1B3/PP3PPP/RN1QK2R", "treename":"Scotch Game, Classical Variation"},
{"gid": 192, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Göring Gambit"},
{"gid": 192, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Göring Gambit"},
{"gid": 192, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Göring Gambit"},
{"gid": 192, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Göring Gambit"},
{"gid": 192, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Göring Gambit"},
{"gid": 192, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Göring Gambit"},
{"gid": 192, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Scotch Game, Göring Gambit"},
{"gid": 192, "fen":"r1bqkbnr/pppp1ppp/2n5/8/4P3/2Pp1N2/PP3PPP/RNBQKB1R", "treename":"Scotch Game, Göring Gambit"},
{"gid": 193, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Haxo Gambit"},
{"gid": 193, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Haxo Gambit"},
{"gid": 193, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Haxo Gambit"},
{"gid": 193, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Haxo Gambit"},
{"gid": 193, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Haxo Gambit"},
{"gid": 193, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Haxo Gambit"},
{"gid": 193, "fen":"r1bqkbnr/pppp1ppp/2n5/8/2BpP3/5N2/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Haxo Gambit"},
{"gid": 193, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/2BpP3/5N2/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Haxo Gambit"},
{"gid": 193, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/2BpP3/2P2N2/PP3PPP/RNBQK2R", "treename":"Scotch Game, Haxo Gambit"},
{"gid": 193, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/2B1P3/2Pp1N2/PP3PPP/RNBQK2R", "treename":"Scotch Game, Haxo Gambit"},
{"gid": 195, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Malaniuk Variation"},
{"gid": 195, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Malaniuk Variation"},
{"gid": 195, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Malaniuk Variation"},
{"gid": 195, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Malaniuk Variation"},
{"gid": 195, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Malaniuk Variation"},
{"gid": 195, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Malaniuk Variation"},
{"gid": 195, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Malaniuk Variation"},
{"gid": 195, "fen":"r1bqk1nr/pppp1ppp/2n5/8/1b1NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Malaniuk Variation"},
{"gid": 196, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/4B3/PPP2PPP/RN1QKB1R", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"r1b1k1nr/pppp1ppp/2n2q2/2b5/3NP3/4B3/PPP2PPP/RN1QKB1R", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"r1b1k1nr/pppp1ppp/2n2q2/2b5/3NP3/2P1B3/PP3PPP/RN1QKB1R", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"r1b1k2r/ppppnppp/2n2q2/2b5/3NP3/2P1B3/PP3PPP/RN1QKB1R", "treename":"Scotch Game, Meitner Variation"},
{"gid": 196, "fen":"r1b1k2r/ppppnppp/2n2q2/2b5/4P3/2P1B3/PPN2PPP/RN1QKB1R", "treename":"Scotch Game, Meitner Variation"},
{"gid": 197, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1bqkb1r/pppp1ppp/2N2n2/8/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1bqkb1r/p1pp1ppp/2p2n2/8/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1bqkb1r/p1pp1ppp/2p2n2/4P3/8/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1b1kb1r/p1ppqppp/2p2n2/4P3/8/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1b1kb1r/p1ppqppp/2p2n2/4P3/8/8/PPP1QPPP/RNB1KB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1b1kb1r/p1ppqppp/2p5/3nP3/8/8/PPP1QPPP/RNB1KB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r1b1kb1r/p1ppqppp/2p5/3nP3/2P5/8/PP2QPPP/RNB1KB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r3kb1r/p1ppqppp/b1p5/3nP3/2P5/8/PP2QPPP/RNB1KB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 197, "fen":"r3kb1r/p1ppqppp/b1p5/3nP3/2P5/1P6/P3QPPP/RNB1KB1R", "treename":"Scotch Game, Mieses Variation"},
{"gid": 198, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Romanishin Variation"},
{"gid": 198, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Romanishin Variation"},
{"gid": 198, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Romanishin Variation"},
{"gid": 198, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Romanishin Variation"},
{"gid": 198, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Romanishin Variation"},
{"gid": 198, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Romanishin Variation"},
{"gid": 198, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Romanishin Variation"},
{"gid": 198, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Romanishin Variation"},
{"gid": 198, "fen":"r1bqk1nr/pppp1ppp/2n5/2b5/4P3/1N6/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Romanishin Variation"},
{"gid": 198, "fen":"r1bqk1nr/pppp1ppp/2n5/8/1b2P3/1N6/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Romanishin Variation"},
{"gid": 199, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Schmidt Variation"},
{"gid": 199, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Schmidt Variation"},
{"gid": 199, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Schmidt Variation"},
{"gid": 199, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Schmidt Variation"},
{"gid": 199, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Schmidt Variation"},
{"gid": 199, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Schmidt Variation"},
{"gid": 199, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Schmidt Variation"},
{"gid": 199, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Schmidt Variation"},
{"gid": 199, "fen":"r1bqkb1r/pppp1ppp/2N2n2/8/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Schmidt Variation"},
{"gid": 199, "fen":"r1bqkb1r/p1pp1ppp/2p2n2/8/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Schmidt Variation"},
{"gid": 199, "fen":"r1bqkb1r/p1pp1ppp/2p2n2/8/4P3/3B4/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Schmidt Variation"},
{"gid": 200, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4P3/2Bp4/5N2/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pP3/2Bp4/5N2/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkb1r/ppp2ppp/2n2n2/1B1pP3/3p4/5N2/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkb1r/ppp2ppp/2n5/1B1pP3/3pn3/5N2/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkb1r/ppp2ppp/2n5/1B1pP3/3Nn3/8/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r2qkb1r/pppb1ppp/2n5/1B1pP3/3Nn3/8/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r2qkb1r/pppb1ppp/2B5/3pP3/3Nn3/8/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r2qkb1r/p1pb1ppp/2p5/3pP3/3Nn3/8/PPP2PPP/RNBQK2R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r2qkb1r/p1pb1ppp/2p5/3pP3/3Nn3/8/PPP2PPP/RNBQ1RK1", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r2qk2r/p1pb1ppp/2p5/2bpP3/3Nn3/8/PPP2PPP/RNBQ1RK1", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 200, "fen":"r1bqkbnr/ppp2ppp/2n5/3p4/3pP3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Scotch Game, Scotch Gambit"},
{"gid": 201, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Tartakower Variation"},
{"gid": 201, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Tartakower Variation"},
{"gid": 201, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Tartakower Variation"},
{"gid": 201, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Tartakower Variation"},
{"gid": 201, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Tartakower Variation"},
{"gid": 201, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Tartakower Variation"},
{"gid": 201, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Tartakower Variation"},
{"gid": 201, "fen":"r1bqkb1r/pppp1ppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Tartakower Variation"},
{"gid": 201, "fen":"r1bqkb1r/pppp1ppp/2N2n2/8/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Tartakower Variation"},
{"gid": 201, "fen":"r1bqkb1r/p1pp1ppp/2p2n2/8/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Tartakower Variation"},
{"gid": 201, "fen":"r1bqkb1r/p1pp1ppp/2p2n2/8/4P3/8/PPPN1PPP/R1BQKB1R", "treename":"Scotch Game, Tartakower Variation"},
{"gid": 194, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"r1bqkbnr/ppp2ppp/2np4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Main Line"},
{"gid": 194, "fen":"r1b1kbnr/pppp1ppp/2n2q2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Scotch Game, Main Line"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2N3p1/8/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n3p1/8/2PNP1n1/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n3p1/8/2PNP1Q1/2N1B3/PP3PPP/R3KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/6p1/8/2PnP1Q1/2N1B3/PP3PPP/R3KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/6p1/8/2PnP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1p1pbp/6p1/4p3/2PnP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1p1pbp/6p1/1N2p3/2PnP3/4B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n3p1/8/2PNP1n1/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n3p1/8/2PNP1Q1/2N1B3/PP3PPP/R3KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/6p1/8/2PnP1Q1/2N1B3/PP3PPP/R3KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/6p1/8/2PnP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/4n1p1/8/2P1P3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/4n1p1/8/2P1P3/2N1B3/PP3PPP/2RQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1b1k2r/pp1pppbp/4n1p1/q7/2P1P3/2N1B3/PP3PPP/2RQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1b1k2r/pp1pppbp/4n1p1/q7/2P1P3/2N1B3/PP1Q1PPP/2R1KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n3p1/8/2PNP1n1/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n3p1/8/2PNP1Q1/2N1B3/PP3PPP/R3KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/6p1/8/2PnP1Q1/2N1B3/PP3PPP/R3KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/6p1/8/2PnP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/4n1p1/8/2P1P3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/4n1p1/8/2P1P3/2N1B3/PP1Q1PPP/R3KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n3p1/8/2PNP1n1/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n3p1/8/2PNP1Q1/2N1B3/PP3PPP/R3KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/6p1/8/2PnP1Q1/2N1B3/PP3PPP/R3KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/6p1/8/2PnP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1p1pbp/6p1/4p3/2PnP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1p1pbp/6p1/4p3/2PnP3/2NBB3/PP3PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/2n2np1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/2n2np1/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/5np1/8/2PnP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/5np1/8/2PQP3/2N5/PP3PPP/R1B1KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/3p1np1/8/2PQP3/2N5/PP3PPP/R1B1KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp2ppbp/2np2p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp2ppbp/2np2p1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp2ppbp/2np2pn/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n3pn/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/p2pppbp/1pn2np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/p2pppbp/1pn2np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pb1pppbp/1pn2np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pb1pppbp/1pn2np1/8/2PNP3/2N1BP2/PP2B1PP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rq3rk1/pb1pppbp/1pn2np1/8/2PNP3/2N1BP2/PP2B1PP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1nppbp/2np2p1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/1p2ppbp/p1np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2PnP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp2pp1p/2np2p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp2pp1p/2np2p1/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp2ppbp/2np2p1/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/2RQ1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/2n2np1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/2n2np1/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/2PNP3/2N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/3p1np1/8/2PnP3/2N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/3p1np1/8/2PQP3/2N5/PP2BPPP/R1B1K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp2ppbp/3p1np1/8/2PQP3/2N5/PP2BPPP/R1B1K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp2ppbp/3p1np1/8/2PQP3/2N5/PP2BPPP/R1B2RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/2n2np1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/2n2np1/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/2PNP3/2N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/3p1np1/8/2PnP3/2N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/3p1np1/8/2PQP3/2N5/PP2BPPP/R1B1K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp2ppbp/3p1np1/8/2PQP3/2N5/PP2BPPP/R1B1K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp2ppbp/3p1np1/6B1/2PQP3/2N5/PP2BPPP/R3K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/3p1np1/6B1/2PQP3/2N5/PP2BPPP/R3K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/3p1np1/6B1/2P1P3/2N5/PP1QBPPP/R3K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1BP2/PP2B1PP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2P1P3/2N1B3/PPN1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2PNP3/2N1BP2/PP2B1PP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/2n2np1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/2n2np1/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/2PNP3/2N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/3p1np1/8/2PnP3/2N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp2pp1p/3p1np1/8/2PQP3/2N5/PP2BPPP/R1B1K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp2ppbp/3p1np1/8/2PQP3/2N5/PP2BPPP/R1B1K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp2ppbp/3p1np1/8/2PQP3/2N1B3/PP2BPPP/R3K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2PQP3/2N1B3/PP2BPPP/R3K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2P1P3/2N1B3/PP1QBPPP/R3K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp2ppbp/3pbnp1/8/2P1P3/2N1B3/PP1QBPPP/R3K2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp2ppbp/3pbnp1/8/2P1P3/2N1B3/PP1QBPPP/R4RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/4B3/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2PNP3/2N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2PNP3/2N1B3/PP1QBPPP/R4RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/3p1np1/8/2PnP3/2N1B3/PP1QBPPP/R4RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/3p1np1/8/2PBP3/2N5/PP1QBPPP/R4RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp2ppbp/2bp1np1/8/2PBP3/2N5/PP1QBPPP/R4RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp2ppbp/2bp1np1/8/2PBP3/2N2P2/PP1QB1PP/R4RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/1p2ppbp/2bp1np1/p7/2PBP3/2N2P2/PP1QB1PP/R4RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/1p2ppbp/2bp1np1/p7/2PBP3/1PN2P2/P2QB1PP/R4RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1b2rk1/pp1pppbp/2n2np1/q7/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/1BN1B2P/PPP2PP1/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/3NP3/1BN1B2P/PPP2PP1/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/1BN1BP2/PPP3PP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/3NP3/1BN1BP2/PPP3PP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2BNP3/2N1BP2/PPP3PP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/1p1pppbp/2n2np1/p7/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/1p1pppbp/2n2np1/p7/P2NP3/1BN1B3/1PP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1b1k2r/pp1pppbp/2n2np1/q7/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1b1k2r/pp1pppbp/2n2np1/q7/2BNP3/2N1B3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1b2rk1/pp1pppbp/2n2np1/q7/2BNP3/2N1B3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1b2rk1/pp1pppbp/2n2np1/q7/3NP3/1BN1B3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1b2rk1/pp2ppbp/2np1np1/q7/3NP3/1BN1B3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1b2rk1/pp2ppbp/2np1np1/q7/3NP3/1BN1B2P/PPP2PP1/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r4rk1/pp1bppbp/2np1np1/q7/3NP3/1BN1B2P/PPP2PP1/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r4rk1/pp1bppbp/2np1np1/q7/3NPP2/1BN1B2P/PPP3P1/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/1p1pppbp/2n2np1/p7/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/1p1pppbp/2n2np1/p7/3NP3/1BN1BP2/PPP3PP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/2n2np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/pp1ppp1p/2N2np1/8/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkb1r/p2ppp1p/2p2np1/8/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2N2np1/8/4P3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/p2pppbp/2p2np1/8/4P3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/p2pppbp/2p2np1/4P3/8/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/p2pppbp/2p3p1/4P3/8/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/4P3/2N5/PPP1NPPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2n2np1/3p4/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bq1rk1/pp2ppbp/2n2np1/3P4/3N4/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/4P3/1NN5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp2ppbp/2np2p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqk1nr/pp2ppbp/2np2p1/8/3NP3/2N1B3/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP1BPPP/RNBQK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2BNP3/8/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 202, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/4B3/PPP2PPP/RN1QKB1R", "treename":"Sicilian Defense, Accelerated Dragon"},
{"gid": 203, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2P1P3/8/PPN2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2P1P3/8/PPN2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2P1P3/2N5/PPN2PPP/R1BQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp1pppbp/2n2np1/8/2P1P3/2N5/PPN2PPP/R1BQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2P1P3/8/PPN2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2P1P3/8/PPN2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2P1P3/2N5/PPN2PPP/R1BQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN2PPP/R1BQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN1BPPP/R1BQK2R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN1BPPP/R1BQK2R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp1nppbp/2np2p1/8/2P1P3/2N5/PPN1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp1nppbp/2np2p1/8/2P1P3/2N5/PPNBBPPP/R2Q1RK1", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/1p1nppbp/2np2p1/p7/2P1P3/2N5/PPNBBPPP/R2Q1RK1", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2P1P3/8/PPN2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2P1P3/8/PPN2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2P1P3/2N5/PPN2PPP/R1BQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN2PPP/R1BQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN1BPPP/R1BQK2R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN1BPPP/R1BQK2R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r2q1rk1/pp2ppbp/2npbnp1/8/2P1P3/2N5/PPN1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2P1P3/8/PPN2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2P1P3/8/PPN2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2P1P3/2N5/PPN2PPP/R1BQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN2PPP/R1BQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN1BPPP/R1BQK2R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp1nppbp/2np2p1/8/2P1P3/2N5/PPN1BPPP/R1BQK2R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk1nr/pp1pppbp/2n3p1/8/2P1P3/8/PPN2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2P1P3/8/PPN2PPP/RNBQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp1pppbp/2n2np1/8/2P1P3/2N5/PPN2PPP/R1BQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN2PPP/R1BQKB1R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN1BPPP/R1BQK2R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN1BPPP/R1BQK2R", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2P1P3/2N5/PPN1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp1nppbp/2np2p1/8/2P1P3/2N5/PPN1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp1nppbp/2np2p1/8/2P1P3/2N5/PPNBBPPP/R2Q1RK1", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp2ppbp/2np2p1/2n5/2P1P3/2N5/PPNBBPPP/R2Q1RK1", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 203, "fen":"r1bq1rk1/pp2ppbp/2np2p1/2n5/1PP1P3/2N5/P1NBBPPP/R2Q1RK1", "treename":"Sicilian Defense, AcceleratedFianchetto"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pp4/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pP4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kb1r/pp2pppp/5n2/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kb1r/pp2pppp/5n2/2pq4/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rn2kb1r/pp2pppp/5n2/2pq4/3P2b1/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rn2kb1r/pp2pppp/5n2/2pq4/3P2b1/2P2N2/PP2BPPP/RNBQK2R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rn2kb1r/pp3ppp/4pn2/2pq4/3P2b1/2P2N2/PP2BPPP/RNBQK2R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pp4/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pP4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1b1kbnr/pp2pppp/2n5/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1b1kbnr/pp2pppp/2n5/2pq4/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r3kbnr/pp2pppp/2n5/2pq4/3P2b1/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pp4/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pP4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp3ppp/4p3/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp3ppp/4p3/2pq4/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pp4/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pP4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kb1r/pp2pppp/5n2/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kb1r/pp2pppp/5n2/2pq4/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kb1r/pp3ppp/4pn2/2pq4/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kb1r/pp3ppp/4pn2/2pq4/3P4/2P1BN2/PP3PPP/RN1QKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pp4/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pP4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kb1r/pp2pppp/5n2/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kb1r/pp2pppp/5n2/2pq4/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kb1r/pp3ppp/4pn2/2pq4/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kb1r/pp3ppp/4pn2/2pq4/3P4/2P2N2/PP2BPPP/RNBQK2R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pp4/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pP4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/3q4/3p4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/3q4/3P4/8/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pp4/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp2pppp/8/2pP4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnb1kbnr/pp2pppp/8/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1b1kbnr/pp2pppp/2n5/2pq4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1b1kbnr/pp2pppp/2n5/2pq4/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1b1kbnr/pp2pppp/2n5/3q4/3p4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1b1kbnr/pp2pppp/2n5/3q4/3P4/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3P4/8/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1p1ppp/4p3/3nP3/3P4/8/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1p1ppp/4p3/3nP3/3P4/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp3ppp/3pp3/3nP3/3P4/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3P4/8/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1p1ppp/4p3/3nP3/3P4/8/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1p1ppp/4p3/3nP3/3P4/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1bqkb1r/pp1p1ppp/2n1p3/3nP3/3P4/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3P4/8/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1p1ppp/4p3/3nP3/3P4/8/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1p1ppp/4p3/3nP3/3P4/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/p2p1ppp/1p2p3/3nP3/3P4/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1p1ppp/4p3/3nP3/3p4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3Q4/2P5/PP3PPP/RNB1KBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1bqkb1r/pp1ppppp/2n5/3nP3/3p4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1bqkb1r/pp1ppppp/2n5/3nP3/2Bp4/2P2N2/PP3PPP/RNBQK2R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3P4/8/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1bqkb1r/pp1ppppp/2n5/3nP3/3P4/8/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1bqkb1r/pp1ppppp/2n5/3nP3/3P4/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/3nP3/3P4/8/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp2pppp/3p4/3nP3/3P4/8/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp2pppp/3p4/3nP3/3P4/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1bqkb1r/pp2pppp/2np4/3nP3/3P4/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1bqkb1r/pp2pppp/2np4/3nP3/2BP4/5N2/PP3PPP/RNBQK2R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1bqkb1r/pp2pppp/1nnp4/4P3/2BP4/5N2/PP3PPP/RNBQK2R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1bqkb1r/pp2pppp/1nnp4/1B2P3/3P4/5N2/PP3PPP/RNBQK2R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/3PP3/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppp1p/6p1/8/3pP3/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 204, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P3P1/PP1P1P1P/RNBQKBNR", "treename":"Sicilian Defense, Alapin Variation"},
{"gid": 205, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3pp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3pp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bpp1/2np1n1p/4p3/4P3/2N2N1P/PPP1BPP1/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/1p2bpp1/p1np1n1p/4p3/4P3/2N2N1P/PPP1BPP1/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3pp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3pp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bpp1/2np1n1p/4p3/4P3/2N2N1P/PPP1BPP1/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r2q1rk1/pp2bpp1/2npbn1p/4p3/4P3/2N2N1P/PPP1BPP1/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r2q1rk1/pp2bpp1/2npbn1p/4p3/4P3/2N2N1P/PPP2PP1/R1BQRBK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r4rk1/pp2bpp1/2npbn1p/q3p3/4P3/2N2N1P/PPP2PP1/R1BQRBK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3pp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3pp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bpp1/2np1n1p/4p3/4P3/2N2N1P/PPP1BPP1/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r2q1rk1/pp2bpp1/2npbn1p/4p3/4P3/2N2N1P/PPP1BPP1/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r2q1rk1/pp2bpp1/2npbn1p/4p3/4P3/2N2N1P/PPP2PP1/R1BQRBK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rn1q1rk1/pp2bpp1/3pbn1p/4p3/4P3/2N2N1P/PPP2PP1/R1BQRBK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rn1q1rk1/pp2bpp1/3pbn1p/4p3/4P3/1PN2N1P/P1P2PP1/R1BQRBK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rn1q1rk1/1p2bpp1/p2pbn1p/4p3/4P3/1PN2N1P/P1P2PP1/R1BQRBK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bppp/2np1n2/4p3/4PP2/1NN5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bppp/2np1n2/4p1B1/4P3/1NN5/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bppp/2np1n2/4p3/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r2q1rk1/pp2bppp/2npbn2/4p3/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r2q1rk1/pp2bppp/2npbn2/4p3/4P3/1NN1BB2/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bppp/2np1n2/4p3/4P3/1NN1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3pp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3pp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bpp1/2np1n1p/4p3/4P3/2N2N1P/PPP1BPP1/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3pp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqkb1r/pp3pp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bqk2r/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bpp1/2np1n1p/4p3/4P3/2N2N2/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r1bq1rk1/pp2bpp1/2np1n1p/4p3/4P3/2N2N1P/PPP1BPP1/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r2q1rk1/pp2bpp1/2npbn1p/4p3/4P3/2N2N1P/PPP1BPP1/R1BQR1K1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"r2q1rk1/pp2bpp1/2npbn1p/4p3/4P3/2N2N1P/PPP2PP1/R1BQRBK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rn1q1rk1/pp2bpp1/3pbn1p/4p3/4P3/2N2N1P/PPP2PP1/R1BQRBK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 205, "fen":"rn1q1rk1/pp2bpp1/3pbn1p/4p3/P3P3/2N2N1P/1PP2PP1/R1BQRBK1", "treename":"Sicilian Defense, Boleslavsky Variation"},
{"gid": 206, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rn1qkbnr/pp1bpppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rn1qkbnr/pp1Bpppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rn2kbnr/pp1qpppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rn2kbnr/pp1qpppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"r3kbnr/pp1qpppp/2np4/2p5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"r3kbnr/pp1qpppp/2np4/2p5/4P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"r3kb1r/pp1qpppp/2np1n2/2p5/4P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"r3kb1r/pp1qpppp/2np1n2/2p5/3PP3/2P2N2/PP3PPP/RNBQ1RK1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rn1qkbnr/pp1bpppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rn1qkbnr/pp1Bpppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rn2kbnr/pp1qpppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rn2kbnr/pp1qpppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"r3kbnr/pp1qpppp/2np4/2p5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"r3kbnr/pp1qpppp/2np4/2p5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"r3kb1r/pp1qpppp/2np1n2/2p5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rn1qkbnr/pp1bpppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"rn1qkbnr/pp1Bpppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"r2qkbnr/pp1npppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"r2qkbnr/pp1npppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"r2qkb1r/pp1npppp/3p1n2/2p5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 206, "fen":"r2qkb1r/pp1npppp/3p1n2/2p5/4P3/5N2/PPPPQPPP/RNB2RK1", "treename":"Sicilian Defense, Canal Attack"},
{"gid": 207, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1bqkbnr/pp2pppp/2np4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1bqkbnr/pp2pppp/2np4/1B6/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1b1kbnr/pp1qpppp/2np4/1B6/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/1p2pppp/p2p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/1p2pppp/p2p4/8/2PQP3/5N2/PP3PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1bqkbnr/1p2pppp/p1np4/8/2PQP3/5N2/PP3PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1bqkbnr/1p2pppp/p1np4/8/2P1P3/5N2/PP1Q1PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1bqkbnr/1p2pp1p/p1np2p1/8/2P1P3/5N2/PP1Q1PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rn1qkbnr/pp1bpppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1bqkbnr/pp2pppp/2np4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1bqkbnr/pp2pppp/2np4/1B6/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp1bpppp/2np4/1B6/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp1bpppp/2Bp4/8/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp2pppp/2bp4/8/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp2pppp/2bp4/8/3QP3/2N2N2/PPP2PPP/R1B1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkb1r/pp2pppp/2bp1n2/8/3QP3/2N2N2/PPP2PPP/R1B1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkb1r/pp2pppp/2bp1n2/6B1/3QP3/2N2N2/PPP2PPP/R3K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkb1r/pp3ppp/2bppn2/6B1/3QP3/2N2N2/PPP2PPP/R3K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkb1r/pp3ppp/2bppn2/6B1/3QP3/2N2N2/PPP2PPP/2KR3R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qk2r/pp2bppp/2bppn2/6B1/3QP3/2N2N2/PPP2PPP/2KR3R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qk2r/pp2bppp/2bppn2/6B1/3QP3/2N2N2/PPP2PPP/2KRR3", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2q1rk1/pp2bppp/2bppn2/6B1/3QP3/2N2N2/PPP2PPP/2KRR3", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2q1rk1/pp2bppp/2bppn2/6B1/4P3/2N2N2/PPPQ1PPP/2KRR3", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1bqkbnr/pp2pppp/2np4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1bqkbnr/pp2pppp/2np4/1B6/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp1bpppp/2np4/1B6/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp1bpppp/2Bp4/8/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp2pppp/2bp4/8/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp2pppp/2bp4/8/2PQP3/5N2/PP3PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkb1r/pp2pppp/2bp1n2/8/2PQP3/5N2/PP3PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkb1r/pp2pppp/2bp1n2/8/2PQP3/2N2N2/PP3PPP/R1B1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkb1r/pp2pp1p/2bp1np1/8/2PQP3/2N2N2/PP3PPP/R1B1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3QP3/2N2N2/PPP2PPP/R1B1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1bqkbnr/pp2pppp/2np4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r1bqkbnr/pp2pppp/2np4/1B6/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp1bpppp/2np4/1B6/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp1bpppp/2Bp4/8/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp2pppp/2bp4/8/3QP3/5N2/PPP2PPP/RNB1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkbnr/pp2pppp/2bp4/8/3QP3/2N2N2/PPP2PPP/R1B1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkb1r/pp2pppp/2bp1n2/8/3QP3/2N2N2/PPP2PPP/R1B1K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkb1r/pp2pppp/2bp1n2/6B1/3QP3/2N2N2/PPP2PPP/R3K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkb1r/pp3ppp/2bppn2/6B1/3QP3/2N2N2/PPP2PPP/R3K2R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qkb1r/pp3ppp/2bppn2/6B1/3QP3/2N2N2/PPP2PPP/2KR3R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qk2r/pp2bppp/2bppn2/6B1/3QP3/2N2N2/PPP2PPP/2KR3R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"r2qk2r/pp2bppp/2bppn2/6B1/4P3/2NQ1N2/PPP2PPP/2KR3R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/pp2pppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/1p2pppp/p2p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 207, "fen":"rnbqkbnr/1p2pppp/p2p4/8/3QP3/4BN2/PPP2PPP/RN2KB1R", "treename":"Sicilian Defense, Chekhover Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qNp1n2/8/2B1P3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/p3pppp/1qpp1n2/8/2B1P3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/p3pppp/1qpp1n2/8/2B1P3/2N5/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/p3pp1p/1qpp1np1/8/2B1P3/2N5/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/8/2B1P3/2N5/PPP1NPPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/8/2B1P3/1NN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp3ppp/1qnppn2/8/2B1P3/1NN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp3ppp/1qnppn2/8/2B1PB2/1NN5/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/8/2B1P3/1NN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp3ppp/1qnppn2/8/2B1P3/1NN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp3ppp/1qnppn2/8/2B1P3/1NN5/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1k2r/pp2bppp/1qnppn2/8/2B1P3/1NN5/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1k2r/pp2bppp/1qnppn2/8/2B1P3/1NN1B3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/1N6/2B1P3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/8/2B1P3/1NN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp3ppp/1qnppn2/8/2B1P3/1NN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1b1kb1r/pp3ppp/1qnppn2/8/2B1P3/1NN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r2qkb1r/pp1bpppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/3p1n2/8/3nP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/1p2pppp/p1np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N2P2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N2P2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/1NN2P2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqk2r/pp2bppp/2np1n2/4p3/4P3/1NN2P2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np4/8/3NP1n1/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np4/1B6/3NP1n1/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 208, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N4P/PPP2PP1/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation"},
{"gid": 209, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation,Fianchetto Variation"},
{"gid": 209, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Classical Variation,Fianchetto Variation"},
{"gid": 209, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation,Fianchetto Variation"},
{"gid": 209, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation,Fianchetto Variation"},
{"gid": 209, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation,Fianchetto Variation"},
{"gid": 209, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation,Fianchetto Variation"},
{"gid": 209, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation,Fianchetto Variation"},
{"gid": 209, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Classical Variation,Fianchetto Variation"},
{"gid": 209, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation,Fianchetto Variation"},
{"gid": 209, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation,Fianchetto Variation"},
{"gid": 209, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Classical Variation,Fianchetto Variation"},
{"gid": 210, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1p1pbp/4p1p1/2p5/3nP3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1p1pbp/4p1p1/2p5/3nP3/3PB1P1/PPP1NPBP/R2QK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4PP2/2N3P1/PPPP2BP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2NP2P1/PPP2P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP1NPBP/R1BQK2R", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4P3/2NP2P1/PPP1NPBP/R1BQK2R", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed"},
{"gid": 210, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPPNPBP/R1BQK2R", "treename":"Sicilian Defense, Closed"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP1NPBP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2np2p1/2p1p3/4P3/2NP2P1/PPP1NPBP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2np2p1/2p1p3/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2np2p1/2p1p3/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2np2p1/2p1p3/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2np2p1/2p1p3/4PP2/2NP1NP1/PPP3BP/R1BQ1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPPNPPP/R1BQKB1R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1p1ppp/2n5/2p1p3/4P3/2N5/PPPPNPPP/R1BQKB1R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPPNPPP/R1BQKB1R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N5/PPPPNPPP/R1BQKB1R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/8/1Bp5/3nP3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQ1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQ1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/2npp1p1/2p5/4PP2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/3pp1p1/2p5/3nPP2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/3pp1p1/2p5/3nPP2/2NPBNP1/PPP3BP/1R1Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2ppbp/2np1np1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2ppbp/2np1np1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"1rbqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"1rbqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"1rbqk1nr/p3ppbp/2np2p1/1pp5/4P3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"1rbqk1nr/p3ppbp/2np2p1/1pp5/4P3/2NPB1P1/PPPQNPBP/R3K2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4P3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1b1k1nr/pp3pbp/2npp1p1/q1p5/4P3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4P3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/3pp1p1/2p5/3nP3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4P3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2npp1p1/2p5/4P3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2ppbp/2np1np1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2ppbp/2np1np1/2p5/4P3/2NPB1PP/PPP2PB1/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2np2p1/2p1p3/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2np2p1/2p1p3/4P3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2np2p1/2p1p3/4P3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQ1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQ1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/2npp1p1/2p5/4PP2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/3pp1p1/2p5/3nPP2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/3pp1p1/2p5/3nPP2/2NPBNP1/PPPQ2BP/R4RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4P3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"1rbqk1nr/pp3pbp/2npp1p1/2p5/4P3/2NPB1P1/PPPQ1PBP/R3K1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"1rbqk1nr/pp3pbp/2npp1p1/2p5/4P3/2NPB1P1/PPPQNPBP/R3K2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"1rbqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQ1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQ1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/2npp1p1/2p5/4PP2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/p3npbp/1pnpp1p1/2p5/4PP2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQ1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQ1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/2npp1p1/2p5/4PP2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/3pp1p1/2p5/3nPP2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/3pp1p1/2p1P3/3n1P2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp3pbp/3pp1p1/2p1Pn2/3n1P2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk1nr/pp3pbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQK2R", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bqk2r/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQ1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/2npp1p1/2p5/4PP2/2NP1NP1/PPP3BP/R1BQ1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"r1bq1rk1/pp2npbp/2npp1p1/2p5/4PP2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 211, "fen":"1rbq1rk1/pp2npbp/2npp1p1/2p5/4PP2/2NPBNP1/PPP3BP/R2Q1RK1", "treename":"Sicilian Defense, Closed Variation"},
{"gid": 212, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation,Fianchetto Variation"},
{"gid": 212, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Closed Variation,Fianchetto Variation"},
{"gid": 212, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation,Fianchetto Variation"},
{"gid": 212, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation,Fianchetto Variation"},
{"gid": 212, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation,Fianchetto Variation"},
{"gid": 212, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Sicilian Defense, Closed Variation,Fianchetto Variation"},
{"gid": 213, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N2/PP1PBPPP/RNBQK2R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"r1bqkb1r/pp2pppp/2np1n2/2p5/4P3/2P2N2/PP1PBPPP/RNBQK2R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N2/PP1PBPPP/RNBQK2R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"r1bqkb1r/pp1npppp/3p1n2/2p5/4P3/2P2N2/PP1PBPPP/RNBQK2R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N1P/PP1P1PP1/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"r1bqkb1r/pp2pppp/2np1n2/2p5/4P3/2P2N1P/PP1P1PP1/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2PB1N2/PP1P1PPP/RNBQK2R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"r1bqkb1r/pp2pppp/2np1n2/2p5/4P3/2PB1N2/PP1P1PPP/RNBQK2R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/2B1P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N2/PP1PBPPP/RNBQK2R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2p5/4P3/2P2N2/PP1PBPPP/RNBQK2R", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2p5/4P3/2P2N2/PP1PBPPP/RNBQ1RK1", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqk2r/pp2ppbp/3p1np1/2p5/4P3/2P2N2/PP1PBPPP/RNBQ1RK1", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 213, "fen":"rnbqk2r/pp2ppbp/3p1np1/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Delayed Alapin"},
{"gid": 214, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin Variation"},
{"gid": 214, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin Variation"},
{"gid": 214, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin Variation"},
{"gid": 214, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Delayed Alapin Variation"},
{"gid": 214, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin Variation"},
{"gid": 214, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Delayed Alapin Variation"},
{"gid": 215, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPPQBPPP/R3K2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPPQBPPP/R3K2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPPQBPPP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r4rk1/pp1bppbp/2np1np1/q7/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r4rk1/pp1bppbp/2np1np1/q7/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/2np1np1/q7/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/2np1np1/q7/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/3p1np1/q3n3/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/3p1np1/q3n3/3NP2P/1BN1BP2/PPPQ2P1/1K1R3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/3p1np1/q7/2nNP2P/1BN1BP2/PPPQ2P1/1K1R3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r4rk1/pp1bppbp/2np1np1/q7/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r4rk1/pp1bppbp/2np1np1/q7/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/2np1np1/q7/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/2np1np1/q7/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/3p1np1/q3n3/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/3p1np1/q3n3/3NP1PP/1BN1BP2/PPPQ4/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r4rk1/pp1bppbp/2np1np1/q7/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r4rk1/pp1bppbp/2np1np1/q7/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/2np1np1/q7/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/2np1np1/q7/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/3p1np1/q3n3/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"r1r3k1/pp1bppbp/3p1np1/q3n2P/3NP3/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 215, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Dragon"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/1p2ppbp/2np1np1/p7/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPPQBPPP/R4RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/p3ppbp/1pnp1np1/8/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/1p2ppbp/p1np1np1/8/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp2ppbp/2npbnp1/8/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp2ppbp/2npbnp1/8/4PP2/1NN1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp2ppbp/2npbnp1/8/4PP2/1NN1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp2ppbp/2npbnp1/8/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp2ppbp/2npbnp1/8/4PP2/1NN1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1q2rk1/pp2ppbp/2npbnp1/8/4PP2/1NN1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/4P3/1NN1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/1p2pp1p/p2p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/1p2pp1p/p2p1np1/8/P2NP3/2N5/1PP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/1p2ppbp/p2p1np1/8/P2NP3/2N5/1PP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/6B1/3NP3/2N5/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/6B1/3NP3/2N5/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/6B1/4P3/1NN5/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp2ppbp/2npbnp1/6B1/4P3/1NN5/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp2ppbp/2npbnp1/6B1/4P3/1NN5/PPP1BPPP/R2Q1R1K", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1R1K", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N5/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/4P3/1NN5/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp2ppbp/2npbnp1/8/4P3/1NN5/PPP1BPPP/R1BQR1K1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/6B1/3NP3/2N5/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/6B1/3NP3/2N5/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/6B1/4P3/1NN5/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/1p2ppbp/p1np1np1/6B1/4P3/1NN5/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqkb1r/pp1npp1p/3p1np1/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqkb1r/pp2pp1p/2Np1np1/8/4PP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2qk2r/pp1bppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2qk2r/pp1bppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rqk2r/pp1bppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bqk2r/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPP3PP/R2QK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/1p2ppbp/p2p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/2BnP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2n2np1/3p4/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2n2np1/3P4/3N4/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2n3p1/3n4/3N4/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2N3p1/3n4/8/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/p3ppbp/2p3p1/3n4/8/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/p3ppbp/2p3p1/3n4/3B4/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/p4pbp/2p3p1/3np3/3B4/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/p4pbp/2p3p1/2Bnp3/8/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/p4pbp/2p1b1p1/2Bnp3/8/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2n2np1/3p4/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2n2np1/3P4/3N4/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2n3p1/3n4/3N4/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2N3p1/3n4/8/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/p3ppbp/2p3p1/3n4/8/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/p3ppbp/2p3p1/3N4/8/4BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/3nP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/3BP3/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp2ppbp/3pbnp1/8/3BP3/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp2ppbp/3pbnp1/8/3BP3/2N2P2/PPPQ2PP/1K1R1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r4rk1/ppq1ppbp/3pbnp1/8/3BP3/2N2P2/PPPQ2PP/1K1R1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r4rk1/ppq1ppbp/3pbnp1/8/3BP1P1/2N2P2/PPPQ3P/1K1R1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2n2np1/3p4/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2n2np1/3p4/3NP3/2N1BP2/PPP3PP/2KRQB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/3NP1P1/2N1BP2/PPPQ3P/2KR1B1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP3/1BN1BP2/PPPQ2PP/1K1R3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/8/2nNP3/1BN1BP2/PPPQ2PP/1K1R3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/8/2BNP3/2N1BP2/PPPQ2PP/1K1R3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"3q1rk1/pp1bppbp/3p1np1/8/2rNP3/2N1BP2/PPPQ2PP/1K1R3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"3q1rk1/pp1bppbp/3p1np1/8/2rNP1P1/2N1BP2/PPPQ3P/1K1R3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"3q1rk1/p2bppbp/3p1np1/1p6/2rNP1P1/2N1BP2/PPPQ3P/1K1R3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppb1/3p1np1/4n2p/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppb1/3p1npB/4n2p/3NP2P/1BN2P2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bpp2/3p1npb/4n2p/3NP2P/1BN2P2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppb1/3p1np1/4n2p/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppb1/3p1np1/4n1Bp/3NP2P/1BN2P2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"3q1rk1/pp1bppb1/3p1np1/2r1n1Bp/3NP2P/1BN2P2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"3q1rk1/pp1bppb1/3p1np1/2r1n1Bp/3NP1PP/1BN2P2/PPPQ4/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppb1/3p1np1/4n2p/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppb1/3p1np1/4n2p/3NP2P/1BN1BP2/PPPQ2P1/1K1R3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppb1/3p1np1/4n2p/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppb1/3p1np1/4n1Bp/3NP2P/1BN2P2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"3q1rk1/pp1bppb1/3p1np1/2r1n1Bp/3NP2P/1BN2P2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"3q1rk1/pp1bppb1/3p1np1/2r1n1Bp/3NP2P/1BN2P2/PPPQ2P1/1K1R3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/8/2nNP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/8/2BNP2P/2N1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"3q1rk1/pp1bppbp/3p1np1/8/2rNP2P/2N1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"3q1rk1/pp1bppbp/3p1np1/7P/2rNP3/2N1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/4n3/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/8/2nNP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/3p1np1/8/2BNP2P/2N1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"3q1rk1/pp1bppbp/3p1np1/8/2rNP2P/2N1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"3q1rk1/pp1bppbp/3p1np1/8/2rNP1PP/2N1BP2/PPPQ4/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP1P1/2N1BP2/PPPQ3P/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp2ppbp/2npbnp1/8/3NP1P1/2N1BP2/PPPQ3P/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP1P1/2N1BP2/PPPQ3P/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/3p1np1/8/3nP1P1/2N1BP2/PPPQ3P/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r4rk1/pp1bppbp/2np1np1/q7/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r4rk1/pp1bppbp/2np1np1/q7/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1r3k1/pp1bppbp/2np1np1/q7/3NP3/1BN1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1r3k1/pp1bppbp/2np1np1/q7/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1r3k1/pp1bppb1/2np1np1/q6p/3NP2P/1BN1BP2/PPPQ2P1/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp1nppbp/2np2p1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/3p1np1/4n3/2BNP3/2N1BP2/PPPQ2PP/2KR3R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP2P/2N1BP2/PPPQ2P1/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"2rq1rk1/pp1bppbp/2np1np1/8/2BNP2P/2N1BP2/PPPQ2P1/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP1P1/2N1BP2/PPPQ3P/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"r2q1rk1/pp1bppbp/2np1np1/8/3NP3/1BN1BP2/PPPQ2PP/R3K2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/2BNP3/2N5/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqk2r/pp2ppbp/3p1np1/8/2BNP3/2N4P/PPP2PP1/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 216, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N2P2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation"},
{"gid": 217, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/3NP3/2N3P1/PPP2PBP/R1BQK2R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 217, "fen":"r1bqkb1r/pp2pp1p/2np1np1/8/4P3/2N3P1/PPP1NP1P/R1BQKB1R", "treename":"Sicilian Defense, Dragon Variation,Fianchetto Variation"},
{"gid": 218, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Flohr Variation"},
{"gid": 218, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Flohr Variation"},
{"gid": 218, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Flohr Variation"},
{"gid": 218, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Flohr Variation"},
{"gid": 218, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Flohr Variation"},
{"gid": 218, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Flohr Variation"},
{"gid": 218, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Flohr Variation"},
{"gid": 218, "fen":"r1b1kbnr/ppqppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Flohr Variation"},
{"gid": 218, "fen":"r1b1kbnr/ppqppppp/2n5/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Flohr Variation"},
{"gid": 219, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2N1pn2/8/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/p2p1ppp/2p1pn2/8/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/p2p1ppp/2p1pn2/4P3/8/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/p2p1ppp/2p1p3/3nP3/8/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/p2p1ppp/2p1p3/3nP3/4N3/8/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1b1kb1r/p1qp1ppp/2p1p3/3nP3/4N3/8/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/1N6/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/4PB2/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/P1N5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/1N6/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/1N6/1b2P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/1N6/1b2P3/P1N5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/1N6/4P3/P1b5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/8/4P3/P1N5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqk2r/pp3ppp/2n1pn2/3p4/4P3/P1N5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqk2r/pp3ppp/2n1pn2/3P4/8/P1N5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqk2r/pp3ppp/2n2n2/3p4/8/P1N5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bqk2r/pp3ppp/2n2n2/3p4/8/P1NB4/1PP2PPP/R1BQK2R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bq1rk1/pp3ppp/2n2n2/3p4/8/P1NB4/1PP2PPP/R1BQK2R", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bq1rk1/pp3ppp/2n2n2/3p4/8/P1NB4/1PP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bq1rk1/pp3ppp/2n2n2/8/3p4/P1NB4/1PP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 219, "fen":"r1bq1rk1/pp3ppp/2n2n2/8/3p4/P2B4/1PP1NPPP/R1BQ1RK1", "treename":"Sicilian Defense, Four Knights Variation"},
{"gid": 220, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"r1bqkb1r/pp1p1ppp/2N1pn2/8/4P3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp3ppp/3pp3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/1P3N2/P1PP1PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/2p5/4P3/1P3N2/P1PP1PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 220, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, French Variation"},
{"gid": 221, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Godiva Variation"},
{"gid": 221, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Godiva Variation"},
{"gid": 221, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Godiva Variation"},
{"gid": 221, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Godiva Variation"},
{"gid": 221, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Godiva Variation"},
{"gid": 221, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Godiva Variation"},
{"gid": 221, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Godiva Variation"},
{"gid": 221, "fen":"r1b1kbnr/pp1ppppp/1qn5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Godiva Variation"},
{"gid": 222, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/2B1PP2/2N2N2/PPPP2PP/R1BQK2R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/2B1PP2/2N2N2/PPPP2PP/R1BQK2R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p2P2/2B1P3/2N2N2/PPPP2PP/R1BQK2R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4PP2/2N2N2/PPPP2PP/R1BQK2R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1pppbp/6p1/1Bp5/3nPP2/2N2N2/PPPP2PP/R1BQK2R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1pppbp/6p1/2p5/3nPP2/2NB1N2/PPPP2PP/R1BQK2R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp2pppp/2np4/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp2pppp/2np4/2p5/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp2pp1p/2np2p1/2p5/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4PP2/2N2N2/PPPP2PP/R1BQK2R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1pppbp/6p1/1Bp5/3nPP2/2N2N2/PPPP2PP/R1BQK2R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqk1nr/pp1pppbp/6p1/1Bp5/3nPP2/2N2N2/PPPP2PP/R1BQ1RK1", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/2p5/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 222, "fen":"r1bqkbnr/pp3ppp/2n1p3/2pp4/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Sicilian Defense, Grand Prix Attack"},
{"gid": 223, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Heidenfeld Variation"},
{"gid": 223, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Heidenfeld Variation"},
{"gid": 223, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Heidenfeld Variation"},
{"gid": 223, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Heidenfeld Variation"},
{"gid": 223, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Heidenfeld Variation"},
{"gid": 223, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P5/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Heidenfeld Variation"},
{"gid": 223, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Heidenfeld Variation"},
{"gid": 223, "fen":"r1bqkb1r/pp1ppppp/2n5/2pnP3/8/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Heidenfeld Variation"},
{"gid": 223, "fen":"r1bqkb1r/pp1ppppp/2n5/2pnP3/8/N1P2N2/PP1P1PPP/R1BQKB1R", "treename":"Sicilian Defense, Heidenfeld Variation"},
{"gid": 224, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Hyperaccelerated Dragon"},
{"gid": 224, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Hyperaccelerated Dragon"},
{"gid": 224, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Dragon"},
{"gid": 224, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Dragon"},
{"gid": 224, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Dragon"},
{"gid": 224, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Dragon"},
{"gid": 224, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Dragon"},
{"gid": 224, "fen":"rnbqk1nr/pp1pppbp/6p1/8/3pP3/2P2N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Dragon"},
{"gid": 224, "fen":"rnbqk1nr/pp1pppbp/6p1/8/3PP3/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Dragon"},
{"gid": 224, "fen":"rnbqk1nr/pp2ppbp/6p1/3p4/3PP3/5N2/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Dragon"},
{"gid": 225, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Hyperaccelerated Pterodactyl"},
{"gid": 225, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Hyperaccelerated Pterodactyl"},
{"gid": 225, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Pterodactyl"},
{"gid": 225, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Pterodactyl"},
{"gid": 225, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Pterodactyl"},
{"gid": 225, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Pterodactyl"},
{"gid": 225, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Hyperaccelerated Pterodactyl"},
{"gid": 226, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, HyperacceleratedFianchetto"},
{"gid": 226, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, HyperacceleratedFianchetto"},
{"gid": 226, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, HyperacceleratedFianchetto"},
{"gid": 226, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, HyperacceleratedFianchetto"},
{"gid": 226, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, HyperacceleratedFianchetto"},
{"gid": 226, "fen":"rnbqkbnr/pp1ppp1p/6p1/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, HyperacceleratedFianchetto"},
{"gid": 226, "fen":"rnbqkbnr/pp1ppp1p/6p1/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, HyperacceleratedFianchetto"},
{"gid": 227, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1p1ppp/2n5/4p3/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1p1ppp/2n5/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/1p3ppp/p1np4/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/1p3ppp/p1np4/4p3/4P3/N1N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/5ppp/p1np4/1p2p3/4P3/N1N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/5ppp/p1np4/1p1Np3/4P3/N7/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkb1r/4nppp/p1np4/1p1Np3/4P3/N7/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1p1ppp/2n5/4p3/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1p1ppp/2n5/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/P3P3/8/1PP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1p1ppp/2n5/4p3/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1p1ppp/2n5/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqk1nr/pp2bppp/2np4/1N2p3/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqk1nr/pp2bppp/2np4/1N2p3/2P1P3/8/PP2BPPP/RNBQK2R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1p1ppp/2n5/4p3/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1p1ppp/2n5/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqk1nr/pp2bppp/2np4/1N2p3/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqk1nr/pp2bppp/2np4/1N2p3/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqk1nr/1p2bppp/p1np4/1N2p3/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqk1nr/1p2bppp/p1np4/4p3/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r2qk1nr/1p2bppp/p1npb3/4p3/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r2qk1nr/1p2bppp/p1npb3/4p3/2P1P3/N1N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1p1ppp/2n5/4p3/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp1p1ppp/2n5/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqk1nr/pp2bppp/2np4/1N2p3/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqk1nr/pp2bppp/2np4/1N2p3/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqk1nr/1p2bppp/p1np4/1N2p3/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r1bqk1nr/1p2bppp/p1np4/4p3/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r2qk1nr/1p2bppp/p1npb3/4p3/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 227, "fen":"r2qk1nr/1p2bppp/p1npb3/4p3/2P1P3/2N5/PPN2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kalashnikov Variation"},
{"gid": 228, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQ1RK1", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/3B4/PPP2PPP/RNBQ1RK1", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/2PNP3/3B4/PP3PPP/RNBQ1RK1", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkb1r/1p3p1p/p2ppnp1/8/2PNP3/3B4/PP3PPP/RNBQ1RK1", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqk2r/1p1p1ppp/p3pn2/8/1bPNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"rnbqk2r/1p1p1ppp/p3pn2/8/1bPNP3/2NB4/PP3PPP/R1BQK2R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"r1bqk2r/1p1p1ppp/p1n1pn2/8/1bPNP3/2NB4/PP3PPP/R1BQK2R", "treename":"Sicilian Defense, Kan"},
{"gid": 228, "fen":"r1bqk2r/1p1p1ppp/p1n1pn2/8/1bPNP3/2N5/PPB2PPP/R1BQK2R", "treename":"Sicilian Defense, Kan"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p3ppp/p2pp3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kbnr/1pqp1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kbnr/1pqp1ppp/p3p3/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kbnr/1pqp1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kbnr/1pqp1ppp/p3p3/8/3NP3/2NB4/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/2NB4/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/2NB4/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kbnr/1pqp1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kbnr/1pqp1ppp/p3p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kbnr/1pqp1ppp/p3p3/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pqp1ppp/p3pn2/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pqp1ppp/p3pn2/8/2PNP3/2N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kbnr/1p1p1ppp/pq2p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kbnr/1pqp1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/2PNP3/3B4/PP3PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQ1RK1", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQ1RK1", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pqp1ppp/p3pn2/8/2PNP3/3B4/PP3PPP/RNBQ1RK1", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQ1RK1", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/3B4/PPP2PPP/RNBQ1RK1", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pqp1ppp/p3pn2/8/3NP3/3B4/PPP1QPPP/RNB2RK1", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/8/3NP3/3B4/PPP1QPPP/RNB2RK1", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/8/2PNP3/3B4/PP2QPPP/RNB2RK1", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqk1nr/1p1p1ppp/p3p3/2b5/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqk1nr/1p1p1ppp/p3p3/2b5/4P3/1N1B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqk1nr/bp1p1ppp/p3p3/8/4P3/1N1B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1p1p/p3p1p1/8/3NP3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/3p1ppp/p3p3/1p6/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/3p1ppp/p3p3/1p6/3NP3/2NB4/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kbnr/3p1ppp/pq2p3/1p6/3NP3/2NB4/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnb1kbnr/3p1ppp/pq2p3/1p6/4P3/1NNB4/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP1BPPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/8/3NP3/8/PPP1BPPP/RNBQK2R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/6P1/PPP2P1P/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 229, "fen":"rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/4B3/PPP2PPP/RN1QKB1R", "treename":"Sicilian Defense, Kan Variation"},
{"gid": 230, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kupreichik Variation"},
{"gid": 230, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kupreichik Variation"},
{"gid": 230, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kupreichik Variation"},
{"gid": 230, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kupreichik Variation"},
{"gid": 230, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kupreichik Variation"},
{"gid": 230, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kupreichik Variation"},
{"gid": 230, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kupreichik Variation"},
{"gid": 230, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kupreichik Variation"},
{"gid": 230, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kupreichik Variation"},
{"gid": 230, "fen":"rn1qkb1r/pp1bpppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Kupreichik Variation"},
{"gid": 230, "fen":"rn1qkb1r/pp1bpppp/3p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Kupreichik Variation"},
{"gid": 231, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kveinis Variation"},
{"gid": 231, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Kveinis Variation"},
{"gid": 231, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kveinis Variation"},
{"gid": 231, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Kveinis Variation"},
{"gid": 231, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kveinis Variation"},
{"gid": 231, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kveinis Variation"},
{"gid": 231, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kveinis Variation"},
{"gid": 231, "fen":"rnb1kbnr/pp1p1ppp/1q2p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Kveinis Variation"},
{"gid": 233, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1p1ppp/2n5/4p3/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1p1ppp/2n5/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/1p1p1ppp/p1n5/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/1p1p1ppp/p1nN4/4p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqk1nr/1p1p1ppp/p1nb4/4p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqk1nr/1p1p1ppp/p1nQ4/4p3/4P3/8/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1b1k1nr/1p1p1ppp/p1nQ1q2/4p3/4P3/8/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1b1k1nr/1p1p1ppp/p1n2Q2/4p3/4P3/8/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1p1ppp/2n5/4p3/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/pp1p1ppp/2n5/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/1p1p1ppp/p1n5/1N2p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqkbnr/1p1p1ppp/p1nN4/4p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqk1nr/1p1p1ppp/p1nb4/4p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1bqk1nr/1p1p1ppp/p1nQ4/4p3/4P3/8/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1b1k1nr/1p1p1ppp/p1nQ1q2/4p3/4P3/8/PPP2PPP/RNB1KB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 233, "fen":"r1b1k1nr/1p1p1ppp/p1n2q2/4p3/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Löwenthal Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/4p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r2qkb1r/1p3ppp/p1npbn2/4p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/4p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5ppp/p1np1n2/1p2p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5ppp/p1np1n2/1p1Np1B1/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/4bppp/p1np1n2/1p1Np1B1/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/4bppp/p1np1B2/1p1Np3/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/5ppp/p1np1b2/1p1Np3/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/5ppp/p1np1b2/1p1Np3/4P3/N1P5/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/5ppp/p1np4/1p1Np1b1/4P3/N1P5/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/4p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5ppp/p1np1n2/1p2p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5ppp/p1np1n2/1p1Np1B1/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/4bppp/p1np1n2/1p1Np1B1/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/4bppp/p1np1B2/1p1Np3/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/5ppp/p1np1b2/1p1Np3/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/5ppp/p1np1b2/1p1Np3/4P3/N1P5/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bq1rk1/5ppp/p1np1b2/1p1Np3/4P3/N1P5/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bq1rk1/5ppp/p1np1b2/1p1Np3/4P3/2P5/PPN2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bq1rk1/5ppp/p1np4/1p1Np1b1/4P3/2P5/PPN2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bq1rk1/5ppp/p1np4/1p1Np1b1/P3P3/2P5/1PN2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/4p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5ppp/p1np1n2/1p2p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5ppp/p1np1B2/1p2p3/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5p1p/p1np1p2/1p2p3/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5p1p/p1np1p2/1p1Np3/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/5pbp/p1np1p2/1p1Np3/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/5pbp/p1np1p2/1p1Np3/4P3/N2B4/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/4p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5ppp/p1np1n2/1p2p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5ppp/p1np1B2/1p2p3/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5p1p/p1np1p2/1p2p3/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5p1p/p1np1p2/1p1Np3/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5p1p/p1np4/1p1Npp2/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5p1p/p1np4/1p1Npp2/4P3/N1P5/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/5pbp/p1np4/1p1Npp2/4P3/N1P5/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqk2r/5pbp/p1np4/1p1NpP2/8/N1P5/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r2qk2r/5pbp/p1np4/1p1Npb2/8/N1P5/PP3PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r2qk2r/5pbp/p1np4/1p1Npb2/8/2P5/PPN2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r2q1rk1/5pbp/p1np4/1p1Npb2/8/2P5/PPN2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/4p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5ppp/p1np1n2/1p2p1B1/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5ppp/p1np1B2/1p2p3/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5p1p/p1np1p2/1p2p3/4P3/N1N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5p1p/p1np1p2/1p1Np3/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5p1p/p1np4/1p1Npp2/4P3/N7/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/5p1p/p1np4/1p1Npp2/4P3/N2B4/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r2qkb1r/5p1p/p1npb3/1p1Npp2/4P3/N2B4/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r2qkb1r/5p1p/p1npb3/1p1Npp2/4P3/N2B4/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1pp1/2n2n1p/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/N1N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4pN2/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/1N2p1B1/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1B2/1N2p3/4P3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/P3P3/2N5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/1N2p3/P3P3/2N5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/1p3ppp/p1np1n2/4p3/P3P3/N1N5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp1p1ppp/2n2n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 232, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N1Np3/4P3/8/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Lasker-Pelikan Variation"},
{"gid": 235, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Marshall Counterattack"},
{"gid": 235, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Marshall Counterattack"},
{"gid": 235, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Marshall Counterattack"},
{"gid": 235, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Marshall Counterattack"},
{"gid": 235, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Marshall Counterattack"},
{"gid": 235, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Marshall Counterattack"},
{"gid": 236, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp2pppp/8/2pp4/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp2pppp/8/2pP4/5P2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/5P2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkb1r/pp2pppp/5n2/1BpP4/5P2/8/PPPP2PP/RNBQK1NR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4PP2/5N2/PPPP2PP/RNBQKB1R", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4PP2/5N2/PPPP2PP/RNBQKB1R", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp2pppp/8/2pp4/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp2pppp/8/2pP4/5P2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnb1kbnr/pp2pppp/8/2pq4/5P2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4PP2/8/PPPP2PP/RNBQKBNR", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4PP2/5N2/PPPP2PP/RNBQKB1R", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 236, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/2p5/4PP2/5N2/PPPP2PP/RNBQKB1R", "treename":"Sicilian Defense, McDonnell Attack"},
{"gid": 237, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"r1bqkb1r/pp1npppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 237, "fen":"r1bqkb1r/pp1npppp/3p1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Modern Variations"},
{"gid": 238, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rn1qkbnr/pp1bpppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rn1qkbnr/pp1Bpppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rn2kbnr/pp1qpppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rn2kbnr/pp1qpppp/3p4/2p5/2P1P3/5N2/PP1P1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r3kbnr/pp1qpppp/2np4/2p5/2P1P3/5N2/PP1P1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r3kbnr/pp1qpppp/2np4/2p5/2P1P3/5N2/PP1P1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r3kb1r/pp1qpppp/2np1n2/2p5/2P1P3/5N2/PP1P1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rn1qkbnr/pp1bpppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rn1qkbnr/pp1Bpppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rn2kbnr/pp1qpppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rn2kbnr/pp1qpppp/3p4/2p5/2P1P3/5N2/PP1P1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r3kbnr/pp1qpppp/2np4/2p5/2P1P3/5N2/PP1P1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r3kbnr/pp1qpppp/2np4/2p5/2P1P3/2N2N2/PP1P1PPP/R1BQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r3kb1r/pp1qpppp/2np1n2/2p5/2P1P3/2N2N2/PP1P1PPP/R1BQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r3kb1r/pp1qpppp/2np1n2/2p5/2P1P3/2N2N2/PP1P1PPP/R1BQ1RK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp2pppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp2pppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkbnr/pp1bpppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkbnr/pp1bpppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkbnr/1p1bpppp/p1np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp1npppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp1npppp/3p4/1Bp5/3PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkb1r/pp1npppp/3p1n2/1Bp5/3PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkb1r/pp1npppp/3p1n2/1Bp5/3PP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkb1r/pp1npppp/3p1n2/1B6/3pP3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkb1r/pp1npppp/3p1n2/1B6/3QP3/2N2N2/PPP2PPP/R1B1K2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkb1r/pp1n1ppp/3p1n2/1B2p3/3QP3/2N2N2/PPP2PPP/R1B1K2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp1npppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp1npppp/3p4/1Bp5/3PP3/5N2/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp1npppp/3p4/1B6/3pP3/5N2/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp2pppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp2pppp/2Bp4/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp2pppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp2pppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkbnr/pp1bpppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkbnr/pp1bpppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkb1r/pp1bpppp/2np1n2/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkb1r/pp1bpppp/2np1n2/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkb1r/1p1bpppp/p1np1n2/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkb1r/1p1bpppp/p1np1n2/2p5/B3P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp2pppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp2pppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkbnr/pp1bpppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkbnr/pp1bpppp/2np4/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkb1r/pp1bpppp/2np1n2/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkb1r/pp1bpppp/2np1n2/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkb1r/1p1bpppp/p1np1n2/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkb1r/1p1bpppp/p1np1n2/2p5/4P3/2P2N2/PP1P1PPP/RNBQRBK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkb1r/1p2pppp/p1np1n2/2p5/4P1b1/2P2N2/PP1P1PPP/RNBQRBK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r2qkb1r/1p2pppp/p1np1n2/2p5/4P1b1/2P2N1P/PP1P1PP1/RNBQRBK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp1npppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp1npppp/3p4/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp1npppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkbnr/pp1npppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkb1r/pp1npppp/3p1n2/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkb1r/pp1npppp/3p1n2/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkb1r/1p1npppp/p2p1n2/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 238, "fen":"r1bqkb1r/1p1npppp/p2p1n2/2p5/4P3/5N2/PPPP1PPP/RNBQRBK1", "treename":"Sicilian Defense, Moscow Variation"},
{"gid": 239, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf"},
{"gid": 239, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf"},
{"gid": 239, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf"},
{"gid": 239, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf"},
{"gid": 239, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf"},
{"gid": 239, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf"},
{"gid": 239, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf"},
{"gid": 239, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf"},
{"gid": 239, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf"},
{"gid": 239, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf"},
{"gid": 239, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N4P/PPP2PP1/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p2pppp/p1np1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2p1n2/4p3/4PP2/2NB1N2/PPP3PP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2p1n2/4p3/P3PP2/2N2N2/1PP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqk2r/1p1nbppp/p2p1n2/4p3/P3PP2/2N2N2/1PP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqk2r/1p1nbppp/p2p1n2/4p3/P3PP2/2NB1N2/1PP3PP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bq1rk1/1p1nbppp/p2p1n2/4p3/P3PP2/2NB1N2/1PP3PP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bq1rk1/1p1nbppp/p2p1n2/4p3/P3PP2/2NB1N2/1PP3PP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bq1rk1/1p1nbppp/p2p1n2/8/P3Pp2/2NB1N2/1PP3PP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq1pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq1pppp/p2p1n2/8/3NPP2/2NB4/PPP3PP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq1pp1p/p2p1np1/8/3NPP2/2NB4/PPP3PP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2p1n2/4p3/P3PP2/2N2N2/1PP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1pqn1ppp/p2p1n2/4p3/P3PP2/2N2N2/1PP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2p1n2/4p3/P3PP2/2N2N2/1PP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqk2r/1p1nbppp/p2p1n2/4p3/P3PP2/2N2N2/1PP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqk2r/1p1nbppp/p2p1n2/4p3/P3PP2/2NB1N2/1PP3PP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bq1rk1/1p1nbppp/p2p1n2/4p3/P3PP2/2NB1N2/1PP3PP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bq1rk1/1p1nbppp/p2p1n2/4p3/P3PP2/2NB1N2/1PP3PP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bq1rk1/1p2bppp/p2p1n2/2n1p3/P3PP2/2NB1N2/1PP3PP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq1pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq1pppp/p2p1n2/8/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1pqnpppp/p2p1n2/8/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1pqnpppp/p2p1n2/8/4PP2/2NB1N2/PPP3PP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2p1n2/4p3/P3PP2/2N2N2/1PP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqk2r/1p1nbppp/p2p1n2/4p3/P3PP2/2N2N2/1PP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqk2r/1p1nbppp/p2p1n2/4p3/P1B1PP2/2N2N2/1PP3PP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq2ppp/p2p1n2/4p3/4PP2/2N2N2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq2ppp/p2p1n2/4p3/P3PP2/2N2N2/1PP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1npppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1npppp/p2p1n2/8/3NPP2/2N5/PPP1B1PP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bpp1/p2ppn1p/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bpp1/p2ppn1p/8/3NPP1B/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bpp1/p2ppn1p/8/3NPP1B/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bpp1/p2ppn1p/8/3NPP1B/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbpp1/p2ppn1p/8/3NPP1B/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbpp1/p2ppn1p/8/3NPP1B/2N3Q1/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bpp1/p2ppn1p/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bpp1/p2ppn1p/8/3NPP1B/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bpp1/p2ppn1p/8/3NPP1B/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bpp1/p2ppn1p/8/3NPP1B/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbpp1/p2ppn1p/8/3NPP1B/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbpp1/p2ppn1p/8/3NPP1B/2N2Q2/PPP1B1PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p4/8/3NP1n1/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p4/6B1/3NP1n1/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2ppp1/p2p3p/6B1/3NP1n1/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2ppp1/p2p3p/8/3NP1nB/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pp2/p2p3p/6p1/3NP1nB/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pp2/p2p3p/6p1/3NP1n1/2N3B1/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2ppb1/p2p3p/6p1/3NP1n1/2N3B1/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2ppb1/p2p3p/6p1/3NP1n1/2N3B1/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2ppb1/p2p4/6pp/3NP1n1/2N3B1/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qkb1r/1p3ppp/p2pbn2/4p3/4P3/1NN1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qkb1r/1p3ppp/p2pbn2/4p3/4P3/1NN1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qk2r/1p2bppp/p2pbn2/4p3/4P3/1NN1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qk2r/1p2bppp/p2pbn2/4p3/4P3/1NN1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r2qk2r/1p1nbppp/p2pbn2/4p3/4P3/1NN1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qkb1r/1p3ppp/p2pbn2/4p3/4P3/1NN1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qkb1r/1p3ppp/p2pbn2/4p3/4P3/1NN1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r2qkb1r/1p1n1ppp/p2pbn2/4p3/4P3/1NN1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r2qkb1r/1p1n1ppp/p2pbn2/4p3/4P3/1NN1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r2qkb1r/3n1ppp/p2pbn2/1p2p3/4P3/1NN1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qkb1r/1p3ppp/p2pbn2/4p3/4P3/1NN1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qkb1r/1p3ppp/p2pbn2/4p3/4P3/1NN1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qk2r/1p2bppp/p2pbn2/4p3/4P3/1NN1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qk2r/1p2bppp/p2pbn2/4p3/4P3/1NN1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1q1rk1/1p2bppp/p2pbn2/4p3/4P3/1NN1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1q1rk1/1p2bppp/p2pbn2/4p3/4P3/1NN1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r2q1rk1/1p1nbppp/p2pbn2/4p3/4P3/1NN1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qkb1r/1p3ppp/p2pbn2/4p3/4P3/1NN1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qkb1r/1p3ppp/p2pbn2/4p3/4P3/1NN1B3/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bpp1/p2ppn1p/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bpp1/p2ppn1p/8/3NPP1B/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bp2/p2ppn1p/6p1/3NPP1B/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bp2/p2ppn1p/6P1/3NP2B/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p1nbp2/p2pp2p/6P1/3NP2B/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p1nbp2/p2pN2p/6P1/4P2B/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppn2/1p4B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppB2/1p6/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbp1p/p2ppp2/1p6/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppn2/1p4B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppB2/1p6/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2ppn2/1p6/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2ppn2/1p4P1/3NPP2/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2pp3/1p4P1/3NPP2/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2pp3/1p3PP1/3NP3/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qn1ppp/p2pp3/1p3Pb1/3NP3/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qn1ppp/p2pp3/1p3Pb1/3NP3/2N2Q2/PPP4P/1K1R1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q2ppp/p2pp3/1p2nPb1/3NP3/2N2Q2/PPP4P/1K1R1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbpp1/p2ppn1p/6B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppn2/1p4B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppB2/1p6/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2ppn2/1p6/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2ppn2/1p4P1/3NPP2/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2pp3/1p4P1/3NPP2/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2pp3/1p4P1/3NPP2/P1N2Q2/1PP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N3Q1/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2NB1Q2/PPP3PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppn2/1p4B1/3NPP2/2NB1Q2/PPP3PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppn2/1p4B1/3NPP2/2NB1Q2/PPP3PP/2KRR3", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r3k2r/1bqnbppp/p2ppn2/1p4B1/3NPP2/2NB1Q2/PPP3PP/2KRR3", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r3k2r/1bqnbppp/p2ppn2/1p4B1/3NPP2/2NB2Q1/PPP3PP/2KRR3", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2NB1Q2/PPP3PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbpp1/p2ppn1p/6B1/3NPP2/2NB1Q2/PPP3PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbpp1/p2ppn1p/6B1/3NPP2/2NB3Q/PPP3PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2NB1Q2/PPP3PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbpp1/p2ppn1p/6B1/3NPP2/2NB1Q2/PPP3PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbpp1/p2ppn1p/8/3NPP1B/2NB1Q2/PPP3PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbp2/p2ppn1p/6p1/3NPP1B/2NB1Q2/PPP3PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbp2/p2ppn1p/6P1/3NP2B/2NB1Q2/PPP3PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppn2/1p4B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppB2/1p6/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2ppn2/1p6/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2ppn2/1p4P1/3NPP2/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2pp3/1p4P1/3NPP2/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2pp3/1p3PP1/3NP3/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2pp3/1pn2PP1/3NP3/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2ppP2/1pn3P1/3NP3/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bp1p/p2ppp2/1pn3P1/3NP3/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bp1p/p2ppP2/1pn5/3NP3/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/2q2p1p/p2ppP2/1pn5/3NP3/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/2q2p1p/p2ppP2/1pn5/3NP3/2N2Q2/PPP4P/2KR1BR1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppn2/1p4B1/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2ppB2/1p6/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2ppn2/1p6/3NPPP1/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2ppn2/1p4P1/3NPP2/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2pp3/1p4P1/3NPP2/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2qnbppp/p2pp3/1p3PP1/3NP3/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2pp3/1pn2PP1/3NP3/2N2Q2/PPP4P/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/2q1bppp/p2pp3/1pn2PP1/3NP2P/2N2Q2/PPP5/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p3ppp/p1nppn2/4P1B1/3N1P2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p3ppp/p1Nppn2/6B1/4PP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qk2r/1p2bppp/p2pbn2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qk2r/1p2bppp/p2pbn2/4p3/4PP2/1NN5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1q1rk1/1p2bppp/p2pbn2/4p3/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1q1rk1/1p2bppp/p2pbn2/4p3/P3P3/1NN1B3/1PP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1q1rk1/1p2bppp/p2pbn2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb2rk1/1pq1bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bq1rk1/1p2bppp/p1np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1q1rk1/1p2bppp/p2pbn2/4p3/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1q1rk1/1p2bppp/p2pbn2/3Np3/4P3/1N2B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1q1rk1/1p2bppp/p2pbn2/4p3/4P3/1NN1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1q1rk1/1p2bppp/p2pbn2/4p3/4P3/1NN1B3/PPPQBPPP/R4RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1npppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qk2r/1p2bppp/p2pbn2/4p3/4P3/1NN1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/5PB1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p3ppp/p1nppn2/5PB1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p3ppp/p1npPn2/6B1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p4pp/p1nppn2/6B1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p4pp/p1Nppn2/6B1/4P3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/6pp/p1pppn2/6B1/4P3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/6pp/p1pppn2/4P1B1/8/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/6pp/p1p1pn2/4p1B1/8/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/6pp/p1p1pB2/4p3/8/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/7p/p1p1pp2/4p3/8/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/7p/p1p1pp2/4p3/4N3/q7/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/4b2p/p1p1pp2/4p3/4N3/q7/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/4b2p/p1p1pp2/4p3/4N3/q7/P1PQB1PP/1R2K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/4b3/p1p1pp2/4p2p/4N3/q7/P1PQB1PP/1R2K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/4b3/p1p1pp2/4p2p/4N3/qR6/P1PQB1PP/4K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/4b3/p1p1pp2/4p2p/q3N3/1R6/P1PQB1PP/4K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/4b3/p1p1pN2/4p2p/q7/1R6/P1PQB1PP/4K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/5PB1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p3ppp/p1nppn2/5PB1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p3ppp/p1npPn2/6B1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p4pp/p1nppn2/6B1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p4pp/p1Nppn2/6B1/4P3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/6pp/p1pppn2/6B1/4P3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/6pp/p1pppn2/6B1/4P3/q1N5/P1PQB1PP/1R2K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/5PB1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p3ppp/p1nppn2/5PB1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p3ppp/p1npPn2/6B1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p4pp/p1nppn2/6B1/3NP3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p4pp/p1Nppn2/6B1/4P3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/6pp/p1pppn2/6B1/4P3/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/6pp/p1pppn2/4P1B1/8/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/6pp/p1p1pn2/4p1B1/8/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/6pp/p1p1pB2/4p3/8/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/7p/p1p1pp2/4p3/8/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/7p/p1p1pp2/4p3/4N3/q7/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/7p/p1p1pp2/4p3/4N3/8/q1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppB2/8/3NPP2/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3p1p/p2ppp2/8/3NPP2/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3p1p/p2ppp2/8/3NPP2/q1N5/P1PQB1PP/1R2K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p3p1p/p1nppp2/8/3NPP2/q1N5/P1PQB1PP/1R2K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppB2/8/3NPP2/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3p1p/p2ppp2/8/3NPP2/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3p1p/p2ppp2/8/3NPP2/q1N5/P1PQB1PP/1R2K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1p3pbp/p2ppp2/8/3NPP2/q1N5/P1PQB1PP/1R2K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/q1N5/P1PQ2PP/1R2KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/q1N5/P1PQB1PP/1R2K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1p2bppp/p2ppn2/6B1/3NPP2/q1N5/P1PQB1PP/1R2K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1p2bppp/p2ppn2/6B1/3NPP2/q1N5/P1PQB1PP/1R3RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1p1nbppp/p2ppn2/6B1/3NPP2/q1N5/P1PQB1PP/1R3RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/4PP2/1NN5/PqPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/4PP2/qNN5/P1PQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppB2/8/4PP2/qNN5/P1PQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2NQ4/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/4PP2/1NN5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1p2bppp/pq1ppn2/6B1/4PP2/1NN5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppB2/8/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PqPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/p2ppn2/6B1/4PP2/1NN5/PqPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p3ppp/p1nppn2/6B1/4PP2/1NN5/PqPQ2PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/P1N5/1PP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3ppp/pq1ppn2/6B1/4PP2/1NN5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p1n1ppp/pq1ppn2/6B1/4PP2/1NN5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p1n1ppp/pq1ppn2/6B1/4PP2/1NN2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1p1nbppp/pq1ppn2/6B1/4PP2/1NN2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p2ppn2/1p4B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p2ppn2/1p2P1B1/3N1P2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p3pn2/1p2p1B1/3N1P2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p3pn2/1p2P1B1/3N4/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p3pn2/1p2P1B1/3N4/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p3pn2/1p2P1B1/3N4/2N5/PPP1Q1PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2qn1ppp/p3p3/1p2P1B1/3N4/2N5/PPP1Q1PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2qn1ppp/p3p3/1p2P1B1/3N4/2N5/PPP1Q1PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn2kb1r/1bqn1ppp/p3p3/1p2P1B1/3N4/2N5/PPP1Q1PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn2kb1r/1bqn1ppp/p3p3/1p2P1B1/3N2Q1/2N5/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn2kb1r/1b1n1ppp/p3p3/1p2q1B1/3N2Q1/2N5/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p2ppn2/1p4B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p2ppn2/1p2P1B1/3N1P2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p3pn2/1p2p1B1/3N1P2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p3pn2/1p2P1B1/3N4/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p3pn2/1p2P1B1/3N4/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p3pn2/1p2P1B1/3N4/2N5/PPP1Q1PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2qn1ppp/p3p3/1p2P1B1/3N4/2N5/PPP1Q1PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2qn1ppp/p3p3/1p2P1B1/3N4/2N5/PPP1Q1PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/2qn1ppp/p1n1p3/1p2P1B1/3N4/2N5/PPP1Q1PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p2ppn2/1p4B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p2ppn2/1p2P1B1/3N1P2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p3pn2/1p2p1B1/3N1P2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p3pn2/1p2P1B1/3N4/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p3pn2/1p2P1B1/3N4/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p3pn2/1B2P1B1/3N4/2N5/PPP3PP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p2ppn2/1p4B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p2ppn2/1p2P1B1/3N1P2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p3pn2/1p2p1B1/3N1P2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p3pn2/1p2P1B1/3N4/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p3pn2/1p2P1B1/3N4/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p3pP2/1p4B1/3N4/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/5ppp/p3pP2/1p2q1B1/3N4/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/5ppp/p3pP2/1p2q1B1/3N4/2N5/PPP1B1PP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/5ppp/p3pP2/1p4q1/3N4/2N5/PPP1B1PP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/5ppp/p3pP2/1p4q1/3N4/2NQ4/PPP1B1PP/R3K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/8/P2NP3/2N5/1PP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pq1bppp/p1nppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1k2r/1pq1bppp/p1Nppn2/6B1/4PP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qkb1r/1p2pppp/p2p1n2/8/3NP1b1/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/2N3P1/PPP1NP1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/2N3P1/PPP1NP1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/2N3P1/PPP1NPBP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqk2r/1p1nbppp/p2p1n2/4p3/4P3/2N3P1/PPP1NPBP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/2N3P1/PPP1NP1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/5ppp/p2p1n2/1p2p3/4P3/2N3P1/PPP1NP1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/2N3P1/PPP1NP1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2p1n2/4p3/4P3/2N3P1/PPP1NP1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p2pppp/p1np1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/1NN3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/1NN3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pp1p/p2p1np1/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/4P3/2N3P1/PPP1NP1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/2N3P1/PPP1NP1P/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2p1n2/4p3/4P3/2N3P1/PPP1NPBP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/2N3P1/PPP1NPBP/R1BQK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/4pppp/p2p1n2/1p4B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2NQ4/PPP2PPP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2NQ4/PPP2PPP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rn1qkb1r/1p1b1ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1npppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1npppp/p2p1n2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/3npppp/p2p1n2/1p4B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N2Q2/PPP2PPP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NP3/2N2Q2/PPP2PPP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqk2r/1p2bppp/p2ppn2/6B1/3NP3/2N2Q2/PPP2PPP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqk2r/1p1nbppp/p2ppn2/6B1/3NP3/2N2Q2/PPP2PPP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N2Q2/PPP2PPP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3pp1/p2ppn1p/6B1/3NP3/2N2Q2/PPP2PPP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/6B1/3NPP2/2N5/PPP1Q1PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1pqn1ppp/p2ppn2/6B1/3NPP2/2N5/PPP1Q1PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP1QPPP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/P2NP3/2N5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/P2NP3/2N5/1PP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1pqn1ppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1pqn1ppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/2qn1ppp/p2ppn2/1p4B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/2qn1ppp/p2ppn2/1p2P1B1/3N1P2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3pp1/p2ppn1p/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3pp1/p2ppn1p/8/3NPP1B/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1p3pp1/pq1ppn1p/8/3NPP1B/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N2Q2/PPP2PPP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/6B1/3NP3/2N2Q2/PPP2PPP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N2P2/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/6B1/2BNPP2/2N5/PPP3PP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1npppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1npppp/p2p1n2/6B1/2BNP3/2N5/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/6B1/2BNP3/2N5/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/6B1/2BNP3/2N5/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p2ppn2/1p4B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p2ppn2/1p4B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p2ppn2/1p4B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/2q2ppp/p2ppB2/1p6/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1npppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1npppp/p2p1n2/6B1/2BNP3/2N5/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p1npppp/p2p1n2/q5B1/2BNP3/2N5/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p1npppp/p2p1n2/q5B1/2BNP3/2N5/PPPQ1PPP/R3K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p1n1ppp/p2ppn2/q5B1/2BNP3/2N5/PPPQ1PPP/R3K2R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1p1n1ppp/p2ppn2/q5B1/2BNP3/2N5/PPPQ1PPP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1bqkb1r/1p1n1ppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1pqn1ppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/1pqn1ppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/2qn1ppp/p2ppn2/1p4B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"r1b1kb1r/2qn1ppp/p2ppn2/1p4B1/3NPP2/2NB1Q2/PPP3PP/2KR3R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 240, "fen":"rnb1kb1r/1pq2ppp/p2ppB2/8/3NPP2/2N5/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Najdorf Variation"},
{"gid": 241, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nimzo-American Variation"},
{"gid": 241, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nimzo-American Variation"},
{"gid": 241, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzo-American Variation"},
{"gid": 241, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzo-American Variation"},
{"gid": 241, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzo-American Variation"},
{"gid": 241, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzo-American Variation"},
{"gid": 241, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzo-American Variation"},
{"gid": 241, "fen":"r1bqkbnr/pp2pppp/2n5/3p4/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzo-American Variation"},
{"gid": 242, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/3P4/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/8/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/8/2p1P3/8/2n2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/5n2/2p1P3/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1ppppp/8/2pnP3/8/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1p1ppp/4p3/2pnP3/8/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1p1ppp/4p3/2pNP3/8/5N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1p1ppp/8/2ppP3/8/5N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"rnbqkb1r/pp1p1ppp/8/2ppP3/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"r1bqkb1r/pp1p1ppp/2n5/2ppP3/3P4/5N2/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 242, "fen":"r1bqkb1r/pp1p1ppp/2n5/2PpP3/8/5N2/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Nimzowitsch Variation"},
{"gid": 243, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/1Bp1p3/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/1Bp1p3/1P2P3/5N2/P1PP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1b1kbnr/pp1ppppp/1qn5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkb1r/pp1ppppp/2n2n2/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkb1r/pp1pnppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkb1r/pp1pnppp/2n1p3/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkb1r/1p1pnppp/p1n1p3/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkb1r/pp1pnppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkb1r/pp1pnppp/2n1p3/1Bp5/4P3/1P3N2/P1PP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/1P3N2/P1PP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2B1p3/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkb1r/pp1pnppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkb1r/pp1pnppp/2n1p3/1Bp5/4P3/2N2N2/PPPP1PPP/R1BQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkb1r/pp1pnppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 243, "fen":"r1bqkb1r/pp1pnppp/2n1p3/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack"},
{"gid": 244, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/1Bp1p3/4P3/2P2N2/PP1P1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/1Bp1p3/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp1p1pbp/2n3p1/1Bp1p3/4P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2B3p1/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp2pp1p/2p3p1/2p5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp2pp1p/2p3p1/2p5/4P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp2ppbp/2p3p1/2p5/4P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp2ppbp/2p3p1/2p5/4P3/3P1N1P/PPP2PP1/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk2r/pp1pppbp/2n2np1/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk2r/pp1pppbp/2n2np1/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bq1rk1/pp1pppbp/2n2np1/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bq1rk1/pp1pppbp/2n2np1/1Bp5/3PP3/2P2N2/PP3PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQ1RK1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk1nr/pp1pppbp/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk2r/pp1pppbp/2n2np1/1Bp5/4P3/5N2/PPPP1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bqk2r/pp1pppbp/2n2np1/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bq1rk1/pp1pppbp/2n2np1/1Bp5/4P3/2P2N2/PP1P1PPP/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 244, "fen":"r1bq1rk1/pp1pppbp/2n2np1/1Bp5/4P3/2P2N1P/PP1P1PP1/RNBQR1K1", "treename":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"},
{"gid": 245, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1ppppp/p7/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1ppppp/p7/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1p1ppp/p3p3/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1ppppp/p7/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1ppppp/p7/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1ppppp/p7/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1ppppp/p7/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkb1r/1p1ppppp/p4n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1ppppp/p7/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1ppppp/p7/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1p1ppp/p3p3/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1ppppp/p7/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p1ppppp/p7/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 245, "fen":"rnbqkbnr/1p2pppp/p7/2pp4/4P3/2P2N2/PP1P1PPP/RNBQKB1R", "treename":"Sicilian Defense, O'Kelly Variation"},
{"gid": 246, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkb1r/pp1ppppp/2n2n2/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1p1ppp/2n5/2p1p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5NP1/PPPP1P1P/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/2p5/4P3/5NP1/PPPP1P1P/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/2p5/4P3/3P1NP1/PPP2P1P/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 246, "fen":"r1bqkbnr/pp3ppp/2n1p3/2pp4/4P3/3P1NP1/PPP2P1P/RNBQKB1R", "treename":"Sicilian Defense, Old Sicilian"},
{"gid": 247, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1bqkbnr/pp2pppp/2np4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1b1kb1r/pp1ppppp/1qn2n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1b1kb1r/pp1ppppp/1qn2n2/8/4P3/1NN5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1b1kb1r/pp1p1ppp/1qn1pn2/8/4P3/1NN5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Open"},
{"gid": 247, "fen":"r1b1kb1r/pp1p1ppp/1qn1pn2/8/4P3/1NNB4/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Open"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/ppqp1ppp/2n1pn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N3P1/PPP2PBP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N3P1/PPP2PBP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N3P1/PPP2PBP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p1n1pn2/2b5/3NP3/2N3P1/PPP2PBP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/2qp1ppp/p1n1pn2/1p6/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2NBB3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1pp1/p1n1pn2/7p/3NP3/2NBB3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2NBB3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p1nbpn2/8/3NP3/2NBB3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/2qp1ppp/p1n1p3/1p6/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2NBB3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p3pn2/8/3nP3/2NBB3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/ppqp1ppp/2n1pn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p1n1pn2/8/1b1NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p1n1pn2/8/Nb1NP3/4B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b2rk1/1pqp1ppp/p1n1pn2/8/Nb1NP3/4B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqpbppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqpbppp/p1n1pn2/8/3NP3/2N5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NPP2/2N1B3/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/2qp1ppp/p1n1p3/1p6/3NPP2/2N1B3/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2NBB3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/2qp1ppp/p1n1pn2/1p6/3NP3/2NBB3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/P1N5/1PP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/P1N1B3/1PP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/2qp1ppp/p1n1pn2/1p6/3NP3/P1N1B3/1PP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2NBB3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p3pn2/4n3/3NP3/2NBB3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p3pn2/4n3/3NP3/2NBB2P/PPP2PP1/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p3pn2/2b1n3/3NP3/2NBB2P/PPP2PP1/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p3pn2/2b1n3/3NP3/2NBB2P/PPP2PP1/R2Q1R1K", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/P1N1B3/1PP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqpbppp/p1n1pn2/8/3NP3/P1N1B3/1PP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqpbppp/p1n1pn2/8/3NP3/P1N1B3/1PP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p3pn2/8/3nP3/2N5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p3pn2/8/3QP3/2N5/PPP1BPPP/R1B2R1K", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p3pn2/2b5/3QP3/2N5/PPP1BPPP/R1B2R1K", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p3pn2/2b5/4P3/2NQ4/PPP1BPPP/R1B2R1K", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/2qp1ppp/p3pn2/1pb5/4P3/2NQ4/PPP1BPPP/R1B2R1K", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/2qp1ppp/p3pn2/1pb5/4PP2/2NQ4/PPP1B1PP/R1B2R1K", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/2qp1ppp/p1n1p3/1p6/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/2qp1ppp/p1n1p3/1p6/3NP3/2NBB3/PPP2PPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/2qp1ppp/p1n1p3/1p6/3NP3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/2qp1ppp/p1N1p3/1p6/4P3/2NBB3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/2qp1ppp/p1n1p3/1p6/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/2qp1ppp/p1N1p3/1p6/4P3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N3P1/PPP2PBP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pq2ppp/p1npp3/8/3NP3/2N3P1/PPP2PBP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pq2ppp/p1npp3/8/3NP3/2N3P1/PPP2PBP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r3kbnr/1pqb1ppp/p1npp3/8/3NP3/2N3P1/PPP2PBP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r3kbnr/1pqb1ppp/p1npp3/8/3NP3/2N3P1/PPP2PBP/R1BQR1K1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NPP2/2N5/PPP1B1PP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/1N6/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1N1p3/8/4PP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N3P1/PPP2PBP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N3P1/PPP2PBP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N3P1/PPP2PBP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqpbppp/p1n1pn2/8/3NP3/2N3P1/PPP2PBP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqpbppp/p1n1pn2/8/3NP3/2N3P1/PPP2PBP/R1BQR1K1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2N1p3/8/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/P1N1B3/1PP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p1n1pn2/8/1b1NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p1N1pn2/8/1b2P3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1kb1r/1pqp1ppp/p1n1pn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p1n1pn2/8/1b1NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqp1ppp/p1n1pn2/8/Nb1NP3/4B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqpbppp/p1n1pn2/8/N2NP3/4B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/1pqpbppp/p1N1pn2/8/N3P3/4B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/2qpbppp/p1p1pn2/8/N3P3/4B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1b1k2r/2qpbppp/pNp1pn2/8/4P3/4B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/3p4/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP2BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/4bppp/ppnppn2/8/2P1P3/N1N5/PP2BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/4bppp/ppnppn2/8/2P1P3/N1N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r2q1rk1/3bbppp/ppnppn2/8/2P1P3/N1N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP2BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/4bppp/ppnppn2/8/2P1P3/N1N5/PP2BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/4bppp/ppnppn2/8/2P1P3/N1N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/4bppp/pp1ppn2/4n3/2P1P3/N1N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP2BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/4bppp/ppnppn2/8/2P1P3/N1N5/PP2BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bq1rk1/4bppp/ppnppn2/8/2P1P3/N1N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r2q1rk1/1b2bppp/ppnppn2/8/2P1P3/N1N1B3/PP2BPPP/R2Q1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r2q1rk1/1b2bppp/ppnppn2/8/2P1P3/NQN1B3/PP2BPPP/R4RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk1nr/pp1p1ppp/2n1p3/8/1b1NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/1p2bppp/p1nppn2/8/2P1P3/N1N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/4bppp/ppnppn2/8/2P1P3/N1N5/PP2BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/1N6/2P1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/5ppp/ppnppn2/8/2P1P3/N1N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p3ppp/p1npp3/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4PB2/8/PPP2PPP/RN1QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/4PB2/8/PPP2PPP/RN1QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/4P3/4B3/PPP2PPP/RN1QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p3ppp/p1np4/1N2p3/4P3/4B3/PPP2PPP/RN1QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4PB2/8/PPP2PPP/RN1QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/4PB2/8/PPP2PPP/RN1QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2np4/1N2p3/4P3/4B3/PPP2PPP/RN1QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2np1n2/1N2p3/4P3/4B3/PPP2PPP/RN1QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp3ppp/2npp3/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/1N6/2P1P3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/2P1P3/2N5/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p1pnppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p1pnppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p1pnppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p1pnppp/p1n1p3/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p1p1ppp/p1n1pn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p1pnppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p3ppp/p1npp3/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p1pnppp/p1n1p3/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p3ppp/p1npp3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p3ppp/p1npp3/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/1p1p1ppp/p1n1pn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1N1p3/8/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/3p1ppp/p1p1p3/8/4P3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/3p1ppp/p1p1p3/8/4P3/2NB4/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/5ppp/p1p1p3/3p4/4P3/2NB4/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PNP3/8/PP3PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/8/2PNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/8/1bPNP3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/pp1p1ppp/2N1pn2/8/1bP1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqk2r/p2p1ppp/2p1pn2/8/1bP1P3/2N5/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/6P1/PPP2P1P/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2N1p3/8/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/p2p1ppp/2p1p3/8/4P3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/p2p1ppp/2p1p3/8/4P3/3B4/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 248, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP1BPPP/RNBQK2R", "treename":"Sicilian Defense, Paulsen Variation"},
{"gid": 249, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen-Basman Defense"},
{"gid": 249, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Paulsen-Basman Defense"},
{"gid": 249, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen-Basman Defense"},
{"gid": 249, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen-Basman Defense"},
{"gid": 249, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen-Basman Defense"},
{"gid": 249, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen-Basman Defense"},
{"gid": 249, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen-Basman Defense"},
{"gid": 249, "fen":"rnbqk1nr/pp1p1ppp/4p3/2b5/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Paulsen-Basman Defense"},
{"gid": 250, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Pin Variation"},
{"gid": 250, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Pin Variation"},
{"gid": 250, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Pin Variation"},
{"gid": 250, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Pin Variation"},
{"gid": 250, "fen":"rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Pin Variation"},
{"gid": 250, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Pin Variation"},
{"gid": 250, "fen":"rnbqkbnr/pp1p1ppp/4p3/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Pin Variation"},
{"gid": 250, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Pin Variation"},
{"gid": 250, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Pin Variation"},
{"gid": 250, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1b1NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Pin Variation"},
{"gid": 250, "fen":"rnbqk2r/pp1p1ppp/4pn2/4P3/1b1N4/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Pin Variation"},
{"gid": 251, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp3ppp/3p1n2/4p3/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp3ppp/3p1n2/1B2p3/3NP3/5P2/PPP3PP/RNBQK2R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rn1qkb1r/pp1b1ppp/3p1n2/1B2p3/3NP3/5P2/PPP3PP/RNBQK2R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp3ppp/3p1n2/4p3/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp3ppp/3p1n2/1B2p3/3NP3/5P2/PPP3PP/RNBQK2R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"r1bqkb1r/pp1n1ppp/3p1n2/1B2p3/3NP3/5P2/PPP3PP/RNBQK2R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"r1bqkb1r/pp1n1ppp/3p1n2/1B2pN2/4P3/5P2/PPP3PP/RNBQK2R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"r1bqkb1r/pp1n1ppp/5n2/1B1ppN2/4P3/5P2/PPP3PP/RNBQK2R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/2PNP3/5P2/PP4PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/8/2PNP3/5P2/PP4PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp3ppp/3p1n2/4p3/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp3ppp/3p1n2/4p3/4P3/1N3P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rn1qkb1r/pp3ppp/3pbn2/4p3/4P3/1N3P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/2PNP3/5P2/PP4PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"r1bqkb1r/pp2pppp/3p1n2/8/2PnP3/5P2/PP4PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp3ppp/3p1n2/4p3/3NP3/5P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp3ppp/3p1n2/4p3/4P3/1N3P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 251, "fen":"rnbqkb1r/pp3ppp/5n2/3pp3/4P3/1N3P2/PPP3PP/RNBQKB1R", "treename":"Sicilian Defense, Prins Variation"},
{"gid": 252, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 252, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2NQ4/PPP2PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/4P3/1NN5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/1p2bppp/2nppn2/p5B1/4P3/1NN5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/3ppn2/6B1/3nP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/3ppn2/6B1/3QP3/2N5/PPP2PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/3ppn2/6B1/3QP3/2N5/PPP2PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bpp1/2nppn1p/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bpp1/2nppn1p/8/3NPP1B/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2q1rk1/pp1bbpp1/2nppn1p/8/3NPP1B/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/3ppn2/6B1/3nPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/3ppn2/6B1/3QPP2/2N5/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bpp1/3ppn1p/6B1/3QPP2/2N5/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bpp1/2nppn1p/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bpp1/2nppn1p/8/3NPP1B/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bpp1/2np1n1p/4p3/3NPP1B/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/4P3/1NN5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/1p2bppp/p1nppn2/6B1/4P3/1NN5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/3ppn2/6B1/3nPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/3ppn2/6B1/3QPP2/2N5/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b2rk1/pp2bppp/3ppn2/q5B1/3QPP2/2N5/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b2rk1/pp2bppp/3ppn2/q5B1/3QPP2/2N5/PPP3PP/1K1R1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NP3/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/4P3/1NN5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b2rk1/pp2bppp/1qnppn2/6B1/4P3/1NN5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b2rk1/pp2bppp/1qnppn2/6B1/4P3/1NN2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1br2k1/pp2bppp/1qnppn2/6B1/4P3/1NN2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1br2k1/pp2bppp/1qnppn2/6B1/4P3/1NN2P2/PPPQ2PP/1K1R1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/2nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/3ppn2/6B1/3nPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bq1rk1/pp2bppp/3ppn2/6B1/3QPP2/2N5/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b2rk1/pp2bppp/3ppn2/q5B1/3QPP2/2N5/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b2rk1/pp2bppp/3ppn2/q5B1/2BQPP2/2N5/PPP3PP/2KR3R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r4rk1/pp1bbppp/3ppn2/q5B1/2BQPP2/2N5/PPP3PP/2KR3R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r4rk1/pp1bbppp/3ppn2/q3P1B1/2BQ1P2/2N5/PPP3PP/2KR3R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pp1p/2np1np1/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b1kb1r/pp3ppp/1qnppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b1kb1r/pp3ppp/1qnppn2/6B1/4P3/1NN5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b1kb1r/1p3ppp/pqnppn2/6B1/4P3/1NN5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b1kb1r/1p3ppp/pqnppn2/6B1/4P3/1NN5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b1k2r/1p2bppp/pqnppn2/6B1/4P3/1NN5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1bpppp/p1np1n2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/3p1n2/6B1/3nP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1bpppp/p1np1n2/6B1/3NP3/2N5/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r3kb1r/pp1bpppp/2np1n2/q5B1/3NP3/2N5/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/4P3/1NN5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2rqkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2rqkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2rqkb1r/pp1bpppp/3p1n2/6B1/3nP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2rqkb1r/pp1bpppp/3p1n2/6B1/3QP3/2N5/PPP2PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2r1kb1r/pp1bpppp/3p1n2/q5B1/3QP3/2N5/PPP2PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2r1kb1r/pp1bpppp/3p1n2/q7/3QP3/2N5/PPPB1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1B2/8/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2rqkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2rqkb1r/pp1bpppp/2np1n2/6B1/3NPP2/2N5/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2rqkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2rqkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2rqkb1r/pp1bpppp/3p1n2/6B1/3nP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2rqkb1r/pp1bpppp/3p1n2/6B1/3QP3/2N5/PPP2PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2r1kb1r/pp1bpppp/3p1n2/q5B1/3QP3/2N5/PPP2PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2r1kb1r/pp1bpppp/3p1n2/q5B1/3QPP2/2N5/PPP3PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3pp1/p1nppn1p/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3pp1/p1nppn1p/8/3NP3/2N1B3/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b1kb1r/1pq2pp1/p1nppn1p/8/3NP3/2N1B3/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3pp1/p1nppn1p/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3pp1/p1nppn1p/8/3NP3/2N1B3/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/1p2bpp1/p1nppn1p/8/3NP3/2N1B3/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/1p2bpp1/p1nppn1p/8/3NPP2/2N1B3/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/1p2bpp1/p2ppn1p/8/3nPP2/2N1B3/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/1p2bppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p2ppn2/6B1/3nP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p2ppn2/6B1/3QP3/2N5/PPP2PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/1p2bppp/p2ppn2/6B1/3QP3/2N5/PPP2PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3pp1/p1nppn1p/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3pp1/p1nppn1p/8/3NPB2/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1pp1/p1nppn1p/8/3NPB2/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1pp1/p1Nppn1p/8/4PB2/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p3pp1/p1bppn1p/8/4PB2/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p3pp1/p1bppn1p/8/4PB2/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQBPPP/R3K2R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3pp1/p1nppn1p/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3pp1/p1nppn1p/8/3NP3/2N1B3/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1pp1/p1nppn1p/8/3NP3/2N1B3/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1pp1/p1nppn1p/8/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3pp1/p1nppn1p/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3pp1/p1nppn1p/8/3NP3/2N1B3/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/1p2bpp1/p1nppn1p/8/3NP3/2N1B3/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/1p2bpp1/p1nppn1p/8/3NP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/1p2bpp1/p2ppn1p/8/3nP3/2N1BP2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqk2r/1p2bpp1/p2ppn1p/8/3BP3/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/1p1bbppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/1p1bbppp/p1nppn2/6B1/4PP2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/3bbppp/p1nppn2/1p4B1/4PP2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/3bbppp/p1nppB2/1p6/4PP2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/3bbp1p/p1nppp2/1p6/4PP2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/3bbp1p/p1nppp2/1p3P2/4P3/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/1p1bbppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/1p1bbppp/p1nppn2/6B1/4PP2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/3bbppp/p1nppn2/1p4B1/4PP2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/3bbppp/p1nppB2/1p6/4PP2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/3bbp1p/p1nppp2/1p6/4PP2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/3bbp1p/p1nppp2/1p6/4PP2/2N2N2/PPPQ2PP/1K1R1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1pp1/p1nppn1p/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1pp1/p1nppn1p/8/3NPP1B/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1p2/p1nppn1p/6p1/3NPP1B/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/1p1bbppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/1p1bbppp/p1nppn2/6B1/3NPP2/2N5/PPPQB1PP/2KR3R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r3k2r/1pqbbppp/p1nppn2/6B1/3NPP2/2N5/PPPQB1PP/2KR3R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"2rqkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1ppp/p1nppn2/1p4B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1ppp/p1Nppn2/1p4B1/4PP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQBPPP/2KR3R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/1p1bbppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/1p1bbppp/p1nppn2/6B1/4PP2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/3bbppp/p1nppn2/1p4B1/4PP2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/3bbppp/p1nppn2/1p2P1B1/5P2/2N2N2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1ppp/p1nppn2/1p4B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1ppp/p1nppB2/1p6/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1p1p/p1nppp2/1p6/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1p1p/p1Nppp2/1p6/4PP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/5p1p/p1bppp2/1p6/4PP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/5p1p/p1bppp2/1p6/4PP2/2N5/PPP3PP/2KRQB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1ppp/p1nppn2/1p4B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1ppp/p1nppB2/1p6/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1p1p/p1nppp2/1p6/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1p1p/p1nppp2/1p6/3NPP2/2N5/PPPQ2PP/1K1R1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r3kb1r/3b1p1p/pqnppp2/1p6/3NPP2/2N5/PPPQ2PP/1K1R1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r3kb1r/3b1p1p/pqNppp2/1p6/4PP2/2N5/PPPQ2PP/1K1R1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/1p1bbppp/p1nppn2/6B1/3NP3/2N2P2/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qk2r/1p1bbppp/p1nppn2/6B1/3NP2P/2N2P2/PPPQ2P1/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1ppp/p1nppn2/1p4B1/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1ppp/p1nppB2/1p6/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1p1p/p1nppp2/1p6/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/3b1p1p/p1nppp2/1p3P2/3NP3/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/4P3/1NN5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/3ppn2/6B1/3nP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3pp1/2nppn1p/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3pp1/2nppB1p/8/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3p2/2nppp1p/8/3NP3/2N5/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3p2/2nppp1p/8/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3p2/p1nppp1p/8/3NP3/2N5/PPPQ1PPP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p3p2/p1nppp1p/8/3NPP2/2N5/PPPQ2PP/2KR1B1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp3ppp/2nppn2/1B4B1/3NP3/2N5/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1b1ppp/2nppn2/1B4B1/3NP3/2N5/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r2qkb1r/pp1b1ppp/2Bppn2/6B1/3NP3/2N5/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b1kb1r/pp2pppp/2np1n2/q5B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1b1kb1r/pp2pppp/1qnp1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 253, "fen":"r1bqkb1r/1p2pppp/p1np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Richter-Rauzer Variation"},
{"gid": 254, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R3QRK1", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"r1b2rk1/1pq1bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R3QRK1", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 254, "fen":"r1b2rk1/1pq1bppp/p1nppn2/8/3NPP2/2N1B1Q1/PPP1B1PP/R4RK1", "treename":"Sicilian Defense, Scheveningen"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1b1kb1r/1pq2ppp/p1nppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/pp2bppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/8/P2NP3/2N5/1PP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1b1kb1r/1pq2ppp/p1nppn2/8/P2NP3/2N5/1PP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1b1kb1r/1pq2ppp/p1nppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1R1K", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/P2NPP2/2N1B3/1PP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r2q1rk1/1p1bbppp/p1nppn2/8/P2NPP2/2N1B3/1PP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1R1K", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1b2rk1/1pq1bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1R1K", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1k2r/1pq1bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1k2r/1pq1bppp/p2ppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1b1k2r/1pq1bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1kb1r/1pq2ppp/p2ppn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1b1kb1r/1pq2ppp/p1nppn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1R1K", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r2q1rk1/1p1bbppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1R1K", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb2rk1/1pq1bppp/p2ppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1k2r/1pq1bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1k2r/1pq1bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1R1K", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbq1rk1/1p2bppp/p2ppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/1p2bppp/p1nppn2/8/P2NPP2/2N1B3/1PP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1b2rk1/1pq1bppp/p1nppn2/8/P2NPP2/2N1B3/1PP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1b2rk1/1pq1bppp/p1nppn2/8/P2NPP2/2N1B3/1PP1B1PP/R2Q1R1K", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/P2NP3/2N5/1PP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1B3/PPP1BPPP/R2QK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP1P1/2N1B3/PPP2P1P/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3pp1/p2ppn1p/8/3NP1P1/2N1B3/PPP2P1P/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3pp1/p2ppn1p/8/3NPPP1/2N1B3/PPP4P/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP1P1/2N1B3/PPP2P1P/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2p1n2/4p3/3NP1P1/2N1B3/PPP2P1P/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/1p2bppp/p1nppn2/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/5ppp/p2ppn2/1p6/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/5ppp/p2ppn2/1p6/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/3n1ppp/p2ppn2/1p6/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1B3/PPPQ1PPP/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/5ppp/p2ppn2/1p6/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/5ppp/p2ppn2/1p6/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rn1qkb1r/1b3ppp/p2ppn2/1p6/3NP3/2N1BP2/PPPQ2PP/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rn1qkb1r/1b3ppp/p2ppn2/1p6/3NP1P1/2N1BP2/PPPQ3P/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/8/3NP1PP/2N5/PPP2P2/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3pp1/2nppn1p/8/3NP1PP/2N5/PPP2P2/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3pp1/2nppn1p/8/3NP1PP/2N5/PPP2P2/R1BQKBR1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3pp1/2nppn2/7p/3NP1PP/2N5/PPP2P2/R1BQKBR1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/8/3NP1PP/2N5/PPP2P2/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/pp2bpp1/3ppn1p/8/3NP1PP/2N5/PPP2P2/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/6P1/3NP3/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp1n1ppp/2npp3/6P1/3NP3/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp1n1ppp/2npp3/6P1/3NP3/2N1B3/PPP2P1P/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp1nbppp/2npp3/6P1/3NP3/2N1B3/PPP2P1P/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3p1n2/4p3/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/8/3NP1P1/2N4P/PPP2P2/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3pp1/2nppn1p/8/3NP1P1/2N4P/PPP2P2/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3pp1/2nppn1p/8/3NP1P1/2N4P/PPP2PB1/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/6P1/3NP3/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/8/3NP1P1/2N4P/PPP2P2/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3pp1/p2ppn1p/8/3NP1P1/2N4P/PPP2P2/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3pp1/p2ppn1p/8/3NP1P1/2N4P/PPP2PB1/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3pp1/3ppn1p/8/3NP1P1/2N5/PPP2P1P/R1BQKBR1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnb1kb1r/pp3ppp/1q1ppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NPP2/2N1B3/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1b1kb1r/ppq2ppp/2nppn2/8/3NPP2/2N1B3/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqk2r/pp2bppp/3ppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NPP2/2N1B3/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/5ppp/p2ppn2/1p6/3NPP2/2N1B3/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2np1n2/4p3/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2np1n2/4p3/4PP2/1NN1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2np1n2/8/4Pp2/1NN1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2np1n2/8/4PB2/1NN5/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r2q1rk1/pp1bbppp/2nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r2q1rk1/pp1bbppp/2nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1R1K", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r2q1rk1/pp1bbppp/2nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r2q1rk1/pp1bbppp/2nppn2/8/4PP2/1NN1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r2q1rk1/1p1bbppp/p1nppn2/8/4PP2/1NN1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r2q1rk1/1p1bbppp/p1nppn2/8/P3PP2/1NN1B3/1PP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r2q1rk1/pp1bbppp/2nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r2q1rk1/pp1bbppp/2nppn2/8/3NPP2/2N1B3/PPP1B1PP/R3QRK1", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NPP2/2N1B3/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NPP2/2N1B3/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NPP2/2N1BQ2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2np1n2/4p3/3NPP2/2N1BQ2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NPP2/2N1B3/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NPP2/2N1B3/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NPP2/2N1BQ2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1b1k2r/ppq1bppp/2nppn2/8/3NPP2/2N1BQ2/PPP3PP/R3KB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 255, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N1BP2/PPP3PP/R2QKB1R", "treename":"Sicilian Defense, Scheveningen Variation"},
{"gid": 256, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N3P1/PPP2PBP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/3NP3/2N3P1/PPP2PBP/R1BQK2R", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/3NP3/2N3P1/PPP2PBP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 256, "fen":"r2qkb1r/1p1b1ppp/p1nppn2/8/3NP3/2N3P1/PPP2PBP/R1BQ1RK1", "treename":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation"},
{"gid": 257, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 257, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 257, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 257, "fen":"rnbqkbnr/pp1ppppp/8/8/3pP3/8/PPP2PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 257, "fen":"rnbqkbnr/pp1ppppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 257, "fen":"rnbqkbnr/pp1ppppp/8/8/4P3/2p5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 257, "fen":"rnbqkbnr/pp1ppppp/8/8/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 257, "fen":"r1bqkbnr/pp1ppppp/2n5/8/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 257, "fen":"r1bqkbnr/pp1ppppp/2n5/8/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 257, "fen":"r1bqkbnr/pp2pppp/2np4/8/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 257, "fen":"r1bqkbnr/pp2pppp/2np4/8/2B1P3/2N2N2/PP3PPP/R1BQK2R", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 257, "fen":"r1bqkbnr/1p2pppp/p1np4/8/2B1P3/2N2N2/PP3PPP/R1BQK2R", "treename":"Sicilian Defense, Smith-Morra Gambit"},
{"gid": 258, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 258, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 258, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 258, "fen":"rnbqkbnr/pp1ppppp/8/8/3pP3/8/PPP2PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 258, "fen":"rnbqkbnr/pp1ppppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 258, "fen":"rnbqkbnr/pp1ppppp/8/8/4P3/2p5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 258, "fen":"rnbqkbnr/pp1ppppp/8/8/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 258, "fen":"r1bqkbnr/pp1ppppp/2n5/8/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 258, "fen":"r1bqkbnr/pp1ppppp/2n5/8/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 258, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 258, "fen":"r1bqkbnr/pp1p1ppp/2n1p3/8/2B1P3/2N2N2/PP3PPP/R1BQK2R", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 258, "fen":"r1bqkbnr/1p1p1ppp/p1n1p3/8/2B1P3/2N2N2/PP3PPP/R1BQK2R", "treename":"Sicilian Defense, Smith-Morra Gambit Accepted"},
{"gid": 259, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/8/3pP3/8/PPP2PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkb1r/pp1ppppp/5n2/8/3pP3/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/8/3pP3/8/PPP2PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/8/4P3/2Pp4/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/8/3pP3/8/PPP2PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp1ppppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 259, "fen":"rnbqkbnr/pp2pppp/8/3p4/3pP3/2P5/PP3PPP/RNBQKBNR", "treename":"Sicilian Defense, Smith-Morra Gambit Declined"},
{"gid": 260, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"r1bqkb1r/pp2pppp/2np1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"r2qkb1r/pp1bpppp/2np1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin"},
{"gid": 260, "fen":"r2qkb1r/pp1bpppp/2np1n2/8/2BNP3/2N5/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Sozin"},
{"gid": 261, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/2BNP3/2N5/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"r1bqkb1r/1p3ppp/p1nppn2/8/2BNP3/2N1B3/PPP1QPPP/R3K2R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/1BN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 261, "fen":"rnbqk2r/1p2bppp/p2ppn2/8/3NP3/1BN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack"},
{"gid": 262, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/1BN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/5ppp/p2ppn2/1p6/3NP3/1BN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/5ppp/p2ppn2/1p6/3NP3/1BN5/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqk2r/4bppp/p2ppn2/1p6/3NP3/1BN5/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqk2r/4bppp/p2ppn2/1p6/3NPP2/1BN5/PPP3PP/R1BQ1RK1", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/1BN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/5ppp/p2ppn2/1p6/3NP3/1BN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqkb1r/5ppp/p2ppn2/1p6/3NP3/1BN5/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqk2r/4bppp/p2ppn2/1p6/3NP3/1BN5/PPP2PPP/R1BQ1RK1", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 262, "fen":"rnbqk2r/4bppp/p2ppn2/1p6/3NP3/1BN2Q2/PPP2PPP/R1B2RK1", "treename":"Sicilian Defense, Sozin Attack,Flank Variation"},
{"gid": 263, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/3NP3/1BN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NP3/1BN5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"r1bqk2r/pp2bppp/2nppn2/8/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/3NP3/1BN1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 263, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/3NPP2/1BN1B3/PPP3PP/R2QK2R", "treename":"Sicilian Defense, Sozin,Fischer Variation"},
{"gid": 264, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Staunton-Cochrane Variation"},
{"gid": 264, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Staunton-Cochrane Variation"},
{"gid": 264, "fen":"rnbqkbnr/pp1ppppp/8/2p5/2P1P3/8/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Staunton-Cochrane Variation"},
{"gid": 264, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P1P3/8/PP1P1PPP/RNBQKBNR", "treename":"Sicilian Defense, Staunton-Cochrane Variation"},
{"gid": 264, "fen":"r1bqkbnr/pp1ppppp/2n5/2p5/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"Sicilian Defense, Staunton-Cochrane Variation"},
{"gid": 264, "fen":"r1bqkbnr/pp1ppp1p/2n3p1/2p5/2P1P3/2N5/PP1P1PPP/R1BQKBNR", "treename":"Sicilian Defense, Staunton-Cochrane Variation"},
{"gid": 265, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"rnbqkb1r/pp3ppp/3ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"r1bqkb1r/pp3ppp/2nppn2/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"r1bqk2r/pp2bppp/2nppn2/8/2BNP3/2N1B3/PPP2PPP/R2QK2R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"r1bqk2r/pp2bppp/2nppn2/8/2BNP3/2N1B3/PPP1QPPP/R3K2R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/2BNP3/2N1B3/PPP1QPPP/R3K2R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"r1bq1rk1/pp2bppp/2nppn2/8/2BNP3/2N1B3/PPP1QPPP/2KR3R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"r1b2rk1/ppq1bppp/2nppn2/8/2BNP3/2N1B3/PPP1QPPP/2KR3R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"r1b2rk1/ppq1bppp/2nppn2/8/3NP3/1BN1B3/PPP1QPPP/2KR3R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 265, "fen":"r1b2rk1/1pq1bppp/p1nppn2/8/3NP3/1BN1B3/PPP1QPPP/2KR3R", "treename":"Sicilian Defense, Velimirovic Attack"},
{"gid": 266, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Venice Attack"},
{"gid": 266, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Venice Attack"},
{"gid": 266, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Venice Attack"},
{"gid": 266, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Venice Attack"},
{"gid": 266, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Venice Attack"},
{"gid": 266, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Venice Attack"},
{"gid": 266, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Venice Attack"},
{"gid": 266, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Venice Attack"},
{"gid": 266, "fen":"rnbqkb1r/pp2pppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Venice Attack"},
{"gid": 266, "fen":"rnbqkb1r/pp3ppp/3p1n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Sicilian Defense, Venice Attack"},
{"gid": 266, "fen":"rnbqkb1r/pp3ppp/3p1n2/1B2p3/3NP3/2N5/PPP2PPP/R1BQK2R", "treename":"Sicilian Defense, Venice Attack"},
{"gid": 234, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/1p2pppp/p2p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp3ppp/3p4/4p3/3NP3/8/PPP2PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp3ppp/3pp3/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/1P3N2/P1PP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp3ppp/3pp3/2p5/4P3/1P3N2/P1PP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkbnr/pp2pppp/3p4/2p5/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/2B1P3/5N2/PPPP1PPP/RNBQK2R", "treename":"Sicilian Defense, Main Line"},
{"gid": 234, "fen":"rnbqkb1r/pp2pppp/3p1n2/2p5/2B1P3/3P1N2/PPP2PPP/RNBQK2R", "treename":"Sicilian Defense, Main Line"},
{"gid": 268, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 268, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 268, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 268, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 268, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 268, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 268, "fen":"r1bqkbnr/pppp1p1p/2n3p1/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 268, "fen":"r1bqkbnr/pppp1p1p/2n3p1/8/3pP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 268, "fen":"r1bqkbnr/pppp1p1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 268, "fen":"r1bqk1nr/pppp1pbp/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 268, "fen":"r1bqk1nr/pppp1pbp/2n3p1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 268, "fen":"r1bqk2r/pppp1pbp/2n2np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R", "treename":"Three Knights Opening, Steinitz Defense"},
{"gid": 267, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"r1bqkbnr/ppp2ppp/2np4/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"r1bqkbnr/ppp2ppp/2np4/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R", "treename":"Three Knights Opening, Main Line"},
{"gid": 267, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1N3/4P3/2N5/PPPP1PPP/R1BQKB1R", "treename":"Three Knights Opening, Main Line"},
{"gid": 269, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkb1r/pppp1ppp/8/4p3/2B1n3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkb1r/pppp1ppp/8/4p3/2B1N3/8/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkb1r/pppp1ppp/8/4p3/2B1n3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkb1r/pppp1ppp/8/4p2Q/2B1n3/2N5/PPPP1PPP/R1B1K1NR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkb1r/pppp1ppp/3n4/4p2Q/2B5/2N5/PPPP1PPP/R1B1K1NR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 269, "fen":"rnbqkb1r/pppp1ppp/3n4/4Q3/2B5/2N5/PPPP1PPP/R1B1K1NR", "treename":"Vienna Game, Frankenstein-Dracula Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqk2r/ppp2ppp/3p1n2/2b1p3/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbq1rk1/pppp1ppp/5n2/2b1p3/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/ppp2ppp/5n2/3Pp3/8/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/ppp2ppp/8/3np3/8/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/ppp2ppp/8/3np3/8/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/ppp2ppp/8/4p3/8/2n3P1/PPPP1PBP/R1BQK1NR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/ppp2ppp/8/4p3/8/2P3P1/P1PP1PBP/R1BQK1NR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqk2r/ppp2ppp/3b4/4p3/8/2P3P1/P1PP1PBP/R1BQK1NR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/4P3/2N3P1/PPPP1PBP/R1BQK1NR", "treename":"Vienna Game, Mieses Variation"},
{"gid": 270, "fen":"r1bqk2r/pppp1ppp/2n2n2/2b1p3/4P3/2N3P1/PPPPNPBP/R1BQK2R", "treename":"Vienna Game, Mieses Variation"},
{"gid": 271, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Paulsen Variation"},
{"gid": 271, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Paulsen Variation"},
{"gid": 271, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Paulsen Variation"},
{"gid": 271, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Paulsen Variation"},
{"gid": 271, "fen":"r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Paulsen Variation"},
{"gid": 271, "fen":"r1bqk1nr/pppp1ppp/2n5/2b1p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR", "treename":"Vienna Game, Paulsen Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/8/4p3/2B1n3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/8/4p2Q/2B1n3/2N5/PPPP1PPP/R1B1K1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/3n4/4p2Q/2B5/2N5/PPPP1PPP/R1B1K1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/3n4/4p2Q/8/1BN5/PPPP1PPP/R1B1K1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqk2r/ppppbppp/3n4/4p2Q/8/1BN5/PPPP1PPP/R1B1K1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqk2r/ppppbppp/3n4/4p2Q/8/1BN2N2/PPPP1PPP/R1B1K2R", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"r1bqk2r/ppppbppp/2nn4/4p2Q/8/1BN2N2/PPPP1PPP/R1B1K2R", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"r1bqk2r/ppppbppp/2nn4/4N2Q/8/1BN5/PPPP1PPP/R1B1K2R", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqk2r/pppp1ppp/5n2/4p3/1bB1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqk2r/pppp1ppp/5n2/4p3/1bB1P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbq1rk1/pppp1ppp/5n2/4p3/1bB1P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/2B1PP2/2N5/PPPP2PP/R1BQK1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR", "treename":"Vienna Game, Stanley Variation"},
{"gid": 272, "fen":"rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/2N2N2/PPPP1PPP/R1BQK2R", "treename":"Vienna Game, Stanley Variation"},
{"gid": 273, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pP3/4P3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqk2r/ppp1bppp/8/3pP3/4n3/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqk2r/ppp1bppp/8/3pP3/4n3/2N2N2/PPPPQ1PP/R1B1KB1R", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqk2r/ppp1bppp/8/3pP3/8/2n2N2/PPPPQ1PP/R1B1KB1R", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqk2r/ppp1bppp/8/3pP3/8/2P2N2/PPP1Q1PP/R1B1KB1R", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pP3/4P3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rn1qkb1r/ppp2ppp/8/3pP3/4n1b1/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rn1qkb1r/ppp2ppp/8/3pP3/4n1b1/2N2N2/PPPPQ1PP/R1B1KB1R", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3Pp3/5P2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3P4/5p2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pP3/4P3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"r1bqkb1r/ppp2ppp/2n5/3pP3/4n3/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pP3/4P3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2NP4/PPP3PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqk2r/ppp2ppp/8/3pP3/1b2n3/2NP4/PPP3PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqk2r/ppp2ppp/8/3pP3/1b2P3/2N5/PPP3PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnb1k2r/ppp2ppp/8/3pP3/1b2P2q/2N5/PPP3PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnb1k2r/ppp2ppp/8/3pP3/1b2P2q/2N5/PPP1K1PP/R1BQ1BNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pP3/4P3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2NP4/PPP3PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/8/2nP4/PPP3PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/8/2PP4/P1P3PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/4P3/3p4/2PP4/P1P3PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pP3/4P3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N2Q2/PPPP2PP/R1B1KBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"r1bqkb1r/ppp2ppp/2n5/3pP3/4n3/2N2Q2/PPPP2PP/R1B1KBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2NP4/PPP3PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 273, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/4PP2/2N2N2/PPPP2PP/R1BQKB1R", "treename":"Vienna Game, Vienna Gambit"},
{"gid": 274, "fen":"rnbqkbnr/pppppppp/8/8/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/5P2/5NP1/PPPPP2P/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/5P2/5NP1/PPPPP2P/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/5P2/5NP1/PPPPP1BP/RNBQK2R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/5P2/5NP1/PPPPP1BP/RNBQK2R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/5P2/3P1NP1/PPP1P1BP/RNBQK2R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/pppppppp/8/8/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pp1p/6p1/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pp1p/6p1/3p4/5P2/5NP1/PPPPP2P/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqk1nr/ppp1ppbp/6p1/3p4/5P2/5NP1/PPPPP2P/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqk1nr/ppp1ppbp/6p1/3p4/5P2/5NP1/PPPPP1BP/RNBQK2R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/pppppppp/8/8/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/5P2/4PN2/PPPP2PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rn1qkb1r/ppp1pppp/5n2/3p4/5Pb1/4PN2/PPPP2PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/pppppppp/8/8/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/5P2/5NP1/PPPPP2P/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/5P2/5NP1/PPPPP2P/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/5P2/5NP1/PPPPP1BP/RNBQK2R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/5P2/5NP1/PPPPP1BP/RNBQK2R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/5P2/5NP1/PPPPP1BP/RNBQ1RK1", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/5P2/5NP1/PPPPP1BP/RNBQ1RK1", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/5P2/3P1NP1/PPP1P1BP/RNBQ1RK1", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbq1rk1/pp2ppbp/5np1/2pp4/5P2/3P1NP1/PPP1P1BP/RNBQ1RK1", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/pppppppp/8/8/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/5P2/4PN2/PPPP2PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/5P2/4PN2/PPPP2PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/pppppppp/8/8/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/8/PPPPP1PP/RNBQKBNR", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkbnr/ppp1pppp/8/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/5P2/5N2/PPPPP1PP/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/5P2/5NP1/PPPPP2P/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/5P2/5NP1/PPPPP2P/RNBQKB1R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/5P2/5NP1/PPPPP1BP/RNBQK2R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/5P2/5NP1/PPPPP1BP/RNBQK2R", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/5P2/5NP1/PPPPP1BP/RNBQ1RK1", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/5P2/5NP1/PPPPP1BP/RNBQ1RK1", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/5P2/3P1NP1/PPP1P1BP/RNBQ1RK1", "treename":"Bird Opening, Dutch Variation"},
{"gid": 274, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/5P2/3P1NP1/PPP1P1BP/RNBQ1RK1", "treename":"Bird Opening, Dutch Variation"},
{"gid": 275, "fen":"rnbqkbnr/pppppppp/8/8/1P6/8/P1PPPPPP/RNBQKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqkbnr/pppp1ppp/8/4p3/1P6/8/P1PPPPPP/RNBQKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqkbnr/pppp1ppp/8/4p3/1P6/8/PBPPPPPP/RN1QKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqk1nr/pppp1ppp/8/4p3/1b6/8/PBPPPPPP/RN1QKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqk1nr/pppp1ppp/8/4B3/1b6/8/P1PPPPPP/RN1QKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqk2r/pppp1ppp/5n2/4B3/1b6/8/P1PPPPPP/RN1QKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqk2r/pppp1ppp/5n2/4B3/1b6/5N2/P1PPPPPP/RN1QKB1R", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqkbnr/pppppppp/8/8/1P6/8/P1PPPPPP/RNBQKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqkbnr/pppp1ppp/8/4p3/1P6/8/P1PPPPPP/RNBQKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqkbnr/pppp1ppp/8/4p3/1P6/8/PBPPPPPP/RN1QKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqk1nr/pppp1ppp/8/4p3/1b6/8/PBPPPPPP/RN1QKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqk1nr/pppp1ppp/8/4B3/1b6/8/P1PPPPPP/RN1QKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqk2r/pppp1ppp/5n2/4B3/1b6/8/P1PPPPPP/RN1QKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 275, "fen":"rnbqk2r/pppp1ppp/5n2/4B3/1bP5/8/P2PPPPP/RN1QKBNR", "treename":"Polish Opening, Main Line"},
{"gid": 276, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 276, "fen":"rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N2NPB/PP2PP1P/R1BQK2R", "treename":"Benko Gambit, Fianchetto Variation"},
{"gid": 277, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N5/PP1NPPPP/R1BQKB1R", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 277, "fen":"rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N5/PP1NPPPP/R1BQKB1R", "treename":"Benko Gambit, Nd2 Variation"},
{"gid": 278, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/3ppppp/5n2/1ppP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/3ppppp/5n2/1ppP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/3ppppp/5n2/2pP4/1p2P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/3ppppp/5n2/1NpP4/1p2P3/8/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/4pppp/3p1n2/1NpP4/1p2P3/8/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 278, "fen":"rnbqkb1r/4pppp/3p1n2/1NpP4/1pB1P3/8/PP3PPP/R1BQK1NR", "treename":"Benko Gambit, Nescafe Frappe Attack"},
{"gid": 279, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/5n2/1ppP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/5n2/1ppP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/5n2/2pP4/1p2P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/5n2/1NpP4/1p2P3/8/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/4pppp/3p1n2/1NpP4/1p2P3/8/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/4pppp/3p1n2/1NpP4/1p2P3/5N2/PP3PPP/R1BQKB1R", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/5n2/1ppP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/5n2/1ppP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/5n2/2pP4/1p2P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/3ppppp/5n2/1NpP4/1p2P3/8/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/4pppp/3p1n2/1NpP4/1p2P3/8/PP3PPP/R1BQKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/4pppp/3p1n2/1NpP4/1p2PB2/8/PP3PPP/R2QKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 279, "fen":"rnbqkb1r/4pp1p/3p1n2/1NpP2p1/1p2PB2/8/PP3PPP/R2QKBNR", "treename":"Benko Gambit, Zaitsev Variation"},
{"gid": 280, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/5P2/PP2P1PP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/3p1ppp/p3pn2/1PpP4/8/5P2/PP2P1PP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/5P2/PP2P1PP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 280, "fen":"rnbqkb1r/3ppppp/5n2/1ppP4/8/5P2/PP2P1PP/RNBQKBNR", "treename":"Benko Gambit Accepted, Dlugy Variation"},
{"gid": 281, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1q1rk1/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1q1rk1/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"r2q1rk1/3nppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"r2q1rk1/3nppbp/b2p1np1/2pP4/8/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"r4rk1/3nppbp/bq1p1np1/2pP4/8/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"r2qk2r/3nppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"r2qk2r/3nppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"r2qk2r/4ppbp/bn1p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1q1rk1/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1q1rk1/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"r2q1rk1/3nppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"r2q1rk1/3nppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQR1K1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1q1rk1/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"rn1q1rk1/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"r2q1rk1/3nppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 281, "fen":"r2q1rk1/3nppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/1RBQ1RK1", "treename":"Benko Gambit Accepted, Fianchetto Variation"},
{"gid": 282, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rn1qkb1r/3ppp1p/b4np1/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/3ppp1p/P4np1/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 282, "fen":"rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Benko Gambit Accepted, Fully Accepted Variation"},
{"gid": 283, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKbNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N3P1/PP3P1P/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3P1P/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3PKP/R1BQ2NR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1q1rk1/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3PKP/R1BQ2NR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1q1rk1/4ppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r2q1rk1/3nppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r2q1rk1/3nppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQR3", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r4rk1/3nppbp/3p1np1/q1pP4/4P3/2N2NP1/PP3PKP/R1BQR3", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKbNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N3P1/PP3P1P/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3P1P/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3PKP/R1BQ2NR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1q1rk1/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3PKP/R1BQ2NR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1q1rk1/4ppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r2q1rk1/3nppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r2q1rk1/3nppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQR3", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r2q1rk1/3nppbp/3p2p1/2pP4/4P1n1/2N2NP1/PP3PKP/R1BQR3", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKbNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N3P1/PP3P1P/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3P1P/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3PKP/R1BQ2NR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1q1rk1/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3PKP/R1BQ2NR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1q1rk1/4ppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r2q1rk1/3nppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r2q1rk1/3nppbp/3p1np1/2pP4/4P3/2N2NPP/PP3PK1/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"3q1rk1/3nppbp/r2p1np1/2pP4/4P3/2N2NPP/PP3PK1/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"3q1rk1/3nppbp/r2p1np1/2pP4/4P3/2N2NPP/PP3PK1/R1BQR3", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKbNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N3P1/PP3P1P/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3P1P/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3PKP/R1BQ2NR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1q1rk1/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3PKP/R1BQ2NR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1q1rk1/4ppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r2q1rk1/3nppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r2q1rk1/3nppbp/3p1np1/2pP4/4P3/2N2NPP/PP3PK1/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r4rk1/3nppbp/1q1p1np1/2pP4/4P3/2N2NPP/PP3PK1/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r4rk1/3nppbp/1q1p1np1/2pP4/4P3/2N2NPP/PP3PK1/R1BQR3", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKbNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N3P1/PP3P1P/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3P1P/R1BQ1KNR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3PKP/R1BQ2NR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1q1rk1/4ppbp/3p1np1/2pP4/4P3/2N3P1/PP3PKP/R1BQ2NR", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"rn1q1rk1/4ppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r2q1rk1/3nppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r2q1rk1/3nppbp/3p1np1/2pP4/4P3/2N2NPP/PP3PK1/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r4rk1/3nppbp/3p1np1/q1pP4/4P3/2N2NPP/PP3PK1/R1BQ3R", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 283, "fen":"r4rk1/3nppbp/3p1np1/q1pP4/4P3/2N2NPP/PP3PK1/R1BQR3", "treename":"Benko Gambit Accepted, King Walk Variation"},
{"gid": 284, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/4P3/PP3PPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/3ppp1p/p4np1/1PpP4/8/4P3/PP3PPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/3ppp1p/p4np1/1PpP4/8/2N1P3/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/4pp1p/p2p1np1/1PpP4/8/2N1P3/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/4P3/PP3PPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/3ppp1p/p4np1/1PpP4/8/4P3/PP3PPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqkb1r/3ppp1p/p4np1/1PpP4/8/2N1P3/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 284, "fen":"rnbqk2r/3pppbp/p4np1/1PpP4/8/2N1P3/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Modern Variation"},
{"gid": 285, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/3ppppp/pP3n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/4pppp/pP1p1n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/3ppppp/pP3n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/3p1ppp/pP2pn2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnbqkb1r/3ppppp/pP3n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 285, "fen":"rnb1kb1r/3ppppp/pq3n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Pawn Return Variation"},
{"gid": 286, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKbNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQ1K1R", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQ1K1R", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qk2r/4ppbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQ1K1R", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"r2qk2r/3nppbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQ1K1R", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKbNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P1P1/2N5/PP3P1P/R1BQ1KNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/p2ppppp/5n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/3ppppp/b4n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/b2p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKbNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQ1KNR", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 286, "fen":"rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N5/PP2NPPP/R1BQ1K1R", "treename":"Benko Gambit Accepted, Yugoslav"},
{"gid": 287, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/p2ppppp/5n2/2pP4/2p5/5N2/PP2PPPP/RNBQKB1R", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/p2ppp1p/5np1/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/p2ppp1p/5np1/1PpP4/8/5N2/PP2PPPP/RNBQKB1R", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/3ppp1p/p4np1/1PpP4/8/5N2/PP2PPPP/RNBQKB1R", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rn1qkb1r/pb1ppppp/5n2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 287, "fen":"rn1qkb1r/pb1ppppp/5n2/1ppP4/P1P5/5N2/1P2PPPP/RNBQKB1R", "treename":"Benko Gambit Declined, Main Line"},
{"gid": 288, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Quiet Line"},
{"gid": 288, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Quiet Line"},
{"gid": 288, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Quiet Line"},
{"gid": 288, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Quiet Line"},
{"gid": 288, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Quiet Line"},
{"gid": 288, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Quiet Line"},
{"gid": 288, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP1NPPPP/R1BQKBNR", "treename":"Benko Gambit Declined, Quiet Line"},
{"gid": 289, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Sosonko Variation"},
{"gid": 289, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Sosonko Variation"},
{"gid": 289, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Sosonko Variation"},
{"gid": 289, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Sosonko Variation"},
{"gid": 289, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Sosonko Variation"},
{"gid": 289, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Sosonko Variation"},
{"gid": 289, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/P1P5/8/1P2PPPP/RNBQKBNR", "treename":"Benko Gambit Declined, Sosonko Variation"},
{"gid": 290, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/8/5N2/PPP1PPPP/RNBQKB1R", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/8/5N2/PPP1PPPP/RNBQKB1R", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/8/2N2N2/PPP1PPPP/R1BQKB1R", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/8/5N2/PPP1PPPP/RNBQKB1R", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkb1r/pp1ppp1p/5np1/2pP4/8/5N2/PPP1PPPP/RNBQKB1R", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 290, "fen":"rnbqkb1r/pp1ppp1p/5np1/2pP4/8/2N2N2/PPP1PPPP/R1BQKB1R", "treename":"Benoni Defense, Benoni-Indian Defense"},
{"gid": 291, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PB2/2N2N2/PP2BPPP/R2QK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/p4pbp/3p1np1/1ppP4/4PB2/2N2N2/PP2BPPP/R2QK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP2B1/4P3/2N2N2/PP2BPPP/R2QK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP2B1/4P3/2N2N2/PP2BPPP/R2QK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P2B/2N2N2/PP2BPPP/R2QK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pb1/3p1n1p/2pP2p1/4P2B/2N2N2/PP2BPPP/R2QK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pb1/3p1n1p/2pP2p1/4P3/2N2NB1/PP2BPPP/R2QK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pb1/3p3p/2pP2pn/4P3/2N2NB1/PP2BPPP/R2QK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pb1/3p3p/2pP2pn/4P3/2N3B1/PP1NBPPP/R2QK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical"},
{"gid": 291, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQK2R", "treename":"Benoni Defense, Classical"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/P3P3/2N2N2/1P2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3P1b1/2N2N2/1P2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3PBb1/2N2N2/1P2BPPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1qr1k1/1p3pbp/p2p1np1/2pP4/P3PBb1/2N2N2/1P2BPPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1qr1k1/1p3pbp/p2p1np1/2pP4/P3PBb1/2N5/1P1NBPPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1qr1k1/1p3pbp/p2p1np1/2pP4/P3PB2/2N5/1P1NbPPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1qr1k1/1p3pbp/p2p1np1/2pP4/P3PB2/2N5/1P1NQPPP/R4RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1qr1k1/1p3pbp/p2p2p1/2pP3n/P3PB2/2N5/1P1NQPPP/R4RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/P3P3/2N2N2/1P2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3P1b1/2N2N2/1P2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3PBb1/2N2N2/1P2BPPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn3rk1/1p2qpbp/p2p1np1/2pP4/P3PBb1/2N2N2/1P2BPPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/P3P3/2N2N2/1P2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3P1b1/2N2N2/1P2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3PBb1/2N2N2/1P2BPPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3PB2/2N2b2/1P2BPPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3PB2/2N2B2/1P3PPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn3rk1/1p2qpbp/p2p1np1/2pP4/P3PB2/2N2B2/1P3PPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn3rk1/1p2qpbp/p2p1np1/2pP4/P3PB2/2N2B2/1P3PPP/R2QR1K1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r4rk1/1p1nqpbp/p2p1np1/2pP4/P3PB2/2N2B2/1P3PPP/R2QR1K1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r4rk1/1p1nqpbp/p2p1np1/P1pP4/4PB2/2N2B2/1P3PPP/R2QR1K1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/P3P3/2N2N2/1P2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3P1b1/2N2N2/1P2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3P1b1/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/P3P3/2N2N2/1P2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3P1b1/2N2N2/1P2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3P1b1/2N2N1P/1P2BPP1/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP2B1/4P3/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP2B1/4P3/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP4/4P2B/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pb1/3p1n1p/2pP2p1/4P2B/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP2B1/4P3/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP2B1/4P3/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP4/4P2B/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/1p3pb1/p2p1npp/2pP4/4P2B/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/1p3pb1/p2p1npp/2pP4/4P2B/2N5/PP1N1PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/5pb1/p2p1npp/1ppP4/4P2B/2N5/PP1N1PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/5pb1/p2p1npp/1ppP4/4P2B/2N5/PP1NBPPP/R2QK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/1p3pbp/p2p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P3P3/2N5/1PQNBPPP/R1B2RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/3p1np1/2pPn3/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/3p1np1/2pPn3/P3P3/2N5/1PQNBPPP/R1B2RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/3p1np1/2pPn3/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/3p1np1/2pPn3/P3P3/2N5/1P1NBPPP/R1BQR1K1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/3p1np1/2pPn3/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/3p1np1/2pPn3/P3P3/R1N5/1P1NBPPP/2BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P3P3/2N4P/1P1NBPP1/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P3P3/R1N5/1P1NBPPP/2BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/3p1np1/2pPn3/P3P3/2N5/1P1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/3p1np1/2pPn3/P3P3/2N5/1P2BPPP/RNBQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N2P2/PP1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/ppn2pbp/3p1np1/2pP4/4P3/2N2P2/PP1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/ppn2pbp/3p1np1/2pP4/P3P3/2N2P2/1P1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/ppnn1pbp/3p2p1/2pP4/P3P3/2N2P2/1P1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4PP2/2N5/PP1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/ppn2pbp/3p1np1/2pP4/4PP2/2N5/PP1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N2P2/PP1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/ppn2pbp/3p1np1/2pP4/4P3/2N2P2/PP1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/ppn2pbp/3p1np1/2pP4/P3P3/2N2P2/1P1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/p1n2pbp/1p1p1np1/2pP4/P3P3/2N2P2/1P1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/p1n2pbp/1p1p1np1/2pP4/P3P3/2N2P2/1P1NB1PP/R1BQ1R1K", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQR1K1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1R1K", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N2P2/PP1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/ppn2pbp/3p1np1/2pP4/4P3/2N2P2/PP1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/ppn2pbp/3p1np1/2pP4/P3P3/2N2P2/1P1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/p1n2pbp/1p1p1np1/2pP4/P3P3/2N2P2/1P1NB1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/p1n2pbp/1p1p1np1/2pP4/P1N1P3/2N2P2/1P2B1PP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bq1rk1/pp3pbp/n2p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bq1rk1/pp3pbp/n2p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bq1rk1/ppn2pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/pp3pbp/3p1np1/2pP4/4P1b1/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/pp3pbp/3p1np1/2pP2B1/4P1b1/2N2N2/PP2BPPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/pp3pbp/3p1np1/2pP4/4P1b1/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/pp3pbp/3p1np1/2pP4/4PBb1/2N2N2/PP2BPPP/R2Q1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/1p3pbp/p2p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/p4pbp/3p1np1/1ppP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/p4pbp/3p1np1/1NpP4/4P3/3B1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/p4pbp/3p2p1/1NpP4/4n3/3B1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bq1rk1/pp3pbp/n2p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/P3P3/2NB1N1P/1P3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/2pP4/P3P3/2NB1N1P/1P3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/2pP4/P3P3/2NB1N1P/1P3PP1/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P3P3/2NB1N1P/1P3PP1/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1q1rk1/pp1b1pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/pp3pbp/3p1np1/3P4/2p1P3/2NB1N1P/PP3PP1/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/5pbp/p2p1np1/1ppP4/4P3/2NB1N1P/PP3PP1/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/p4pbp/3p1np1/1ppP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/p4pbp/3p1np1/1NpP4/4P3/3B1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/p4pbp/3p1np1/1NpP4/4P3/3B1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/p4pbp/3p1np1/1NpP4/4P3/3B1N1P/PP3PP1/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqr1k1/p4pbp/3p2p1/1NpP4/4n3/3B1N1P/PP3PP1/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/p4pbp/3p1np1/1ppP4/4P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/p4pbp/3p1np1/1BpP4/4P3/2N2N1P/PP3PP1/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PB2/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PB2/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PB2/2N5/PP1N1PPP/R2QKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/1p3p1p/p2p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rnbqkb1r/1p3p1p/p2p1np1/2pP4/P3P3/2N2N2/1P3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1qkb1r/1p3p1p/p2p1np1/2pP4/P3P1b1/2N2N2/1P3PPP/R1BQKB1R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 292, "fen":"rn1qkb1r/1p3p1p/p2p1np1/2pP4/P3P1b1/2N2N2/1P2BPPP/R1BQK2R", "treename":"Benoni Defense, Classical Variation"},
{"gid": 293, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pPp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqk2r/pp2bppp/3p1n2/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqk2r/pp2bppp/3p1n2/2pPp3/2P1P3/2N3P1/PP3P1P/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbq1rk1/pp2bppp/3p1n2/2pPp3/2P1P3/2N3P1/PP3P1P/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbq1rk1/pp2bppp/3p1n2/2pPp3/2P1P3/2N3P1/PP3PBP/R1BQK1NR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqnrk1/pp2bppp/3p4/2pPp3/2P1P3/2N3P1/PP3PBP/R1BQK1NR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pPp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqk2r/pp2bppp/3p1n2/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqk2r/pp2bppp/3p1n2/2pPp3/2P1P3/2NB4/PP3PPP/R1BQK1NR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pPp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqk2r/pp2bppp/3p1n2/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqk2r/pp2bppp/3p1n2/2pPp3/2P1P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbq1rk1/pp2bppp/3p1n2/2pPp3/2P1P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbq1rk1/pp2bppp/3p1n2/2pPp3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pPp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqk2r/pp2bppp/3p1n2/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbqk2r/pp2bppp/3p1n2/2pPp3/2P1P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbq1rk1/pp2bppp/3p1n2/2pPp3/2P1P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 293, "fen":"rnbq1rk1/pp2bppp/3p1n2/2pPp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Benoni Defense, Czech Benoni Defense"},
{"gid": 294, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/2pP4/P7/2N3P1/1P1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P7/2N3P1/1P1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P7/2N3PP/1P1NPPB1/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"1rbqr1k1/1p1n1pbp/p2p1np1/2pP4/P7/2N3PP/1P1NPPB1/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"1rbqr1k1/1p1n1pbp/p2p1np1/2pP4/P1N5/2N3PP/1P2PPB1/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"1rbqr1k1/1p3pbp/pn1p1np1/2pP4/P1N5/2N3PP/1P2PPB1/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/2pP4/P7/2N3P1/1P1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P7/2N3P1/1P1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/P1pP4/8/2N3P1/1P1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/2pP4/P7/2N3P1/1P1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P7/2N3P1/1P1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P7/2N3PP/1P1NPPB1/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"1rbqr1k1/1p1n1pbp/p2p1np1/2pP4/P7/2N3PP/1P1NPPB1/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"1rbqr1k1/1p1n1pbp/p2p1np1/2pP4/P1N5/2N3PP/1P2PPB1/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"1rbqr1k1/1p3pbp/p2p1np1/2pPn3/P1N5/2N3PP/1P2PPB1/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/2pP4/P7/2N3P1/1P1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P7/2N3P1/1P1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P1N5/2N3P1/1P2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bqr1k1/1p3pbp/p2p1np1/2pPn3/P1N5/2N3P1/1P2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bqr1k1/pp1n1pbp/3p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp3pbp/n2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/pp3pbp/n2p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/ppn2pbp/3p1np1/2pP4/8/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/P7/2N2NP1/1P2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/2pP4/P7/2N2NP1/1P2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 294, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Benoni Defense, Fianchetto Variation"},
{"gid": 295, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pPP3/5P2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/5np1/2pPp3/5P2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/5np1/2pPP3/8/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/6p1/2pPP3/6n1/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/6p1/2pPP1B1/6n1/2N2N2/PP2B1PP/R2QK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp4bp/5pp1/2pPP1B1/6n1/2N2N2/PP2B1PP/R2QK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pPP3/5P2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/5np1/2pPp3/5P2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/5np1/2pPP3/8/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/6p1/2pPP3/6n1/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/6p1/2pPP1B1/6n1/2N2N2/PP2B1PP/R2QK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnb1r1k1/pp3pbp/1q4p1/2pPP1B1/6n1/2N2N2/PP2B1PP/R2QK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnb1r1k1/pp3pbp/1q4p1/2pPP1B1/6n1/2N2N2/PP2B1PP/R2Q1RK1", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnb1r1k1/pp3pbp/1q4p1/2pPn1B1/8/2N2N2/PP2B1PP/R2Q1RK1", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnb1r1k1/pp3pbp/1q4p1/2pPN1B1/8/2N5/PP2B1PP/R2Q1RK1", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pPP3/5P2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/5np1/2pPp3/5P2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/5np1/2pPP3/8/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/6p1/2pPP3/6n1/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqr1k1/pp3pbp/4P1p1/2pP4/6n1/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2NB1N2/PP4PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/p4pbp/3p1np1/1ppP4/4PP2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/p4pbp/3p1np1/1ppPP3/5P2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/p4pbp/5np1/1ppPp3/5P2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/p4pbp/5np1/1ppPP3/8/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/p4pbp/6p1/1ppPP3/6n1/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/p4pbp/6p1/1ppPP1B1/6n1/2N2N2/PP2B1PP/R2QK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rn1q1rk1/pp3pbp/3p1np1/2pP4/4PPb1/2N2N2/PP2B1PP/R1BQK2R", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"rn1q1rk1/pp3pbp/3p1np1/2pP4/4PPb1/2N2N2/PP2B1PP/R1BQ1RK1", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"r2q1rk1/pp1n1pbp/3p1np1/2pP4/4PPb1/2N2N2/PP2B1PP/R1BQ1RK1", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"r2q1rk1/pp1n1pbp/3p1np1/2pP4/4PPb1/2N2N1P/PP2B1P1/R1BQ1RK1", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"r2q1rk1/pp1n1pbp/3p1np1/2pP4/4PP2/2N2b1P/PP2B1P1/R1BQ1RK1", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"r2q1rk1/pp1n1pbp/3p1np1/2pP4/4PP2/2N2B1P/PP4P1/R1BQ1RK1", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"r2qr1k1/pp1n1pbp/3p1np1/2pP4/4PP2/2N2B1P/PP4P1/R1BQ1RK1", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 295, "fen":"r2qr1k1/pp1n1pbp/3p1np1/2pP4/4PP2/2N2B1P/PP4P1/R1BQR1K1", "treename":"Benoni Defense, Four Pawns Attack"},
{"gid": 296, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/2P1P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 296, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/2P1P3/2NB4/PP3PPP/R1BQK1NR", "treename":"Benoni Defense, Hromodka System"},
{"gid": 297, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Indian System"},
{"gid": 297, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Indian System"},
{"gid": 297, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Indian System"},
{"gid": 297, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Indian System"},
{"gid": 297, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Indian System"},
{"gid": 297, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Indian System"},
{"gid": 297, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Indian System"},
{"gid": 297, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pPp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Indian System"},
{"gid": 297, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Indian System"},
{"gid": 297, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Indian System"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp2bppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2NB4/PP3PPP/R1BQK1NR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP3PPP/R1BQK1NR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQK2R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQK2R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQ1RK1", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"r1bq1rk1/pp3pbp/n2p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQ1RK1", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/1p3pb1/p2p1npp/2pP4/4P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/1p3pb1/p2p1npp/2pP4/P3P3/2N1BP2/1P1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"r1bq1rk1/1p1n1pb1/p2p1npp/2pP4/P3P3/2N1BP2/1P1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2NB4/PP3PPP/R1BQK1NR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP3PPP/R1BQK1NR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQK2R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQK2R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQ1RK1", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQ1RK1", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/1p3pb1/p2p1npp/2pP4/4P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/1p3pb1/p2p1npp/2pP4/P3P3/2N1BP2/1P1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/1p3pb1/p2p1np1/2pP3p/P3P3/2N1BP2/1P1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2NB4/PP3PPP/R1BQK1NR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP3PPP/R1BQK1NR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQK2R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQK2R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQ1RK1", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/p4pbp/1p1p1np1/2pP4/4P3/2NB4/PP2NPPP/R1BQ1RK1", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqr1k1/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP2B1/4P3/2N2P2/PP1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/1p3pbp/p2p1np1/2pP2B1/4P3/2N2P2/PP1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP2B1/4P3/2N2P2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP4PP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/1p3pb1/p2p1npp/2pP4/4P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbq1rk1/1p3pb1/p2p1npp/2pP4/P3P3/2N1BP2/1P1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqr1k1/1p3pb1/p2p1npp/2pP4/P3P3/2N1BP2/1P1Q2PP/R3KBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqr1k1/1p3pb1/p2p1npp/2pP4/P3P3/2N1BP2/1P1QN1PP/R3KB1R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"r1bqr1k1/1p1n1pb1/p2p1npp/2pP4/P3P3/2N1BP2/1P1QN1PP/R3KB1R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PB2/2N5/PP3PPP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/1p3p1p/p2p1np1/2pP4/4PB2/2N5/PP3PPP/R2QKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/1p3p1p/p2p1np1/2pP4/4PB2/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/5p1p/p2p1np1/1ppP4/4PB2/2N2N2/PP3PPP/R2QKB1R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/5p1p/p2p1np1/1ppP4/4PB2/2N2N2/PP2QPPP/R3KB1R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2P2/PP4PP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2P2/PP2N1PP/R1BQKB1R", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2NB4/PP3PPP/R1BQK1NR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2NB4/PP3PPP/R1BQK1NR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2NB3P/PP3PP1/R1BQK1NR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 298, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N4P/PP3PP1/R1BQKBNR", "treename":"Benoni Defense, King's Pawn Line"},
{"gid": 299, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N5/PP1NPPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"r1bqkb1r/pp1n1p1p/3p1np1/2pP4/8/2N5/PP1NPPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N5/PP1NPPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N5/PP1NPPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/2N5/2N5/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/2N5/2N5/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP2B1/2N5/2N5/PP2PPPP/R2QKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N5/PP1NPPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/8/2N5/PP1NPPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/2N5/2N5/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/2N5/2N5/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 299, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/2N2B2/2N5/PP2PPPP/R2QKB1R", "treename":"Benoni Defense, Knight's Tour Variation"},
{"gid": 301, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqk2r/pp3pbp/3p1np1/2pPP3/5P2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqk2r/pp1n1pbp/3p2p1/2pPP3/5P2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqk2r/pp1n1pbp/3p2p1/1NpPP3/5P2/8/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqk2r/pp1n1pbp/6p1/1NpPp3/5P2/8/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbqk2r/pp1n1pbp/3N2p1/2pPp3/5P2/8/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnbq3r/pp1nkpbp/3N2p1/2pPp3/5P2/8/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnNq3r/pp1nkpbp/6p1/2pPp3/5P2/8/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnq4r/pp1nkpbp/6p1/2pPp3/5P2/8/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 301, "fen":"rnq4r/pp1nkpbp/6p1/2pPp3/5P2/5N2/PP4PP/R1BQKB1R", "treename":"Benoni Defense, Mikenas Variation"},
{"gid": 302, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqk2r/pp1p1ppp/3b1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqk2r/pp1p1ppp/3b1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqk2r/ppbp1ppp/5n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqk2r/pp1p1ppp/3b1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqk2r/pp1p1ppp/3b1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbq1rk1/pp1p1ppp/3b1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbq1rk1/pp1p1ppp/3b1n2/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1p1p/5np1/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/5N2/PP2PPPP/RNBQKB1R", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqk2r/pp1p1ppp/3b1n2/2pP4/8/5N2/PP2PPPP/RNBQKB1R", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/6P1/PP2PP1P/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/6P1/PP2PP1P/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/6P1/PP2PP1P/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/p2p1ppp/5n2/1ppP4/8/6P1/PP2PP1P/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 302, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pN4/2P5/8/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Modern Variation"},
{"gid": 303, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/8/2N5/PPP1PPPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pp1p/3p2p1/2pP4/8/2N5/PPP1PPPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/8/5N2/PPP1PPPP/RNBQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/8/5N2/PPP1PPPP/RNBQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pp1p/3p2p1/2pP4/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pp1p/3p2p1/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqk1nr/pp2ppbp/3p2p1/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/8/2N5/PPP1PPPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/8/2N5/PPP1PPPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/4P3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/4P3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP4/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/4P3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/4P3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/4P3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/4P3/2N2N1P/PPP2PP1/R1BQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/4P3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/4P3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/4P3/2N2N2/PPP1BPPP/R1BQK2R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"r1bq1rk1/pp2ppbp/n2p1np1/2pP4/4P3/2N2N2/PPP1BPPP/R1BQ1RK1", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"r1bq1rk1/pp2ppbp/n2p1np1/2pP4/4P3/2N2N1P/PPP1BPP1/R1BQ1RK1", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkbnr/pp2pppp/3p4/2pP4/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pppp/3p1n2/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqkb1r/pp2pp1p/3p1np1/2pP4/4P3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/4P3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 303, "fen":"rnbqk2r/pp2ppbp/3p1np1/1BpP4/4P3/2N2N2/PPP2PPP/R1BQK2R", "treename":"Benoni Defense, Old Benoni"},
{"gid": 304, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp3pbp/3p1np1/1BpP4/4PP2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp1n1pbp/3p2p1/1BpP4/4PP2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp1n1pbp/3p2p1/1BpP4/P3PP2/2N5/1P4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbq1rk1/pp1n1pbp/3p2p1/1BpP4/P3PP2/2N5/1P4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbq1rk1/pp1n1pbp/3p2p1/1BpP4/P3PP2/2N2N2/1P4PP/R1BQK2R", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"r1bq1rk1/pp1n1pbp/n2p2p1/1BpP4/P3PP2/2N2N2/1P4PP/R1BQK2R", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"r1bq1rk1/pp1n1pbp/n2p2p1/1BpP4/P3PP2/2N2N2/1P4PP/R1BQ1RK1", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"r1bq1rk1/ppnn1pbp/3p2p1/1BpP4/P3PP2/2N2N2/1P4PP/R1BQ1RK1", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp3pbp/3p1np1/1BpP4/4PP2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp1n1pbp/3p2p1/1BpP4/4PP2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp1n1pbp/3p2p1/1BpP4/P3PP2/2N5/1P4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnb1k2r/pp1n1pbp/3p2p1/1BpP4/P3PP1q/2N5/1P4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp3pbp/3p1np1/1BpP4/4PP2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp1n1pbp/3p2p1/1BpP4/4PP2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp1n1pbp/3p2p1/1BpP4/P3PP2/2N5/1P4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/1p1n1pbp/p2p2p1/1BpP4/P3PP2/2N5/1P4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/1p1n1pbp/p2p2p1/2pP4/P3PP2/2NB4/1P4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp3pbp/3p1np1/1BpP4/4PP2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp1n1pbp/3p2p1/1BpP4/4PP2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp1n1pbp/3p2p1/1BpP4/P3PP2/2N5/1P4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/1p1n1pbp/p2p2p1/1BpP4/P3PP2/2N5/1P4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/1p1n1pbp/p2p2p1/2pP4/P3PP2/2N5/1P2B1PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"rnbqk2r/pp3pbp/3p1np1/1BpP4/4PP2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"r1bqk2r/pp1n1pbp/3p1np1/1BpP4/4PP2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"r1bqk2r/pp1n1pbp/3p1np1/1BpPP3/5P2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"r1bqk2r/pp1n1pbp/5np1/1BpPp3/5P2/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"r1bqk2r/pp1n1pbp/5np1/1BpPP3/8/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"r1bqk2r/pp1n1pbp/6p1/1BpPP2n/8/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 304, "fen":"r1bqk2r/pp1n1pbp/4P1p1/1BpP3n/8/2N5/PP4PP/R1BQK1NR", "treename":"Benoni Defense, Taimanov Variation"},
{"gid": 305, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP2B1/8/2N2N2/PP2PPPP/R2QKB1R", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp3p2/3p1npp/2pP2B1/8/2N2N2/PP2PPPP/R2QKB1R", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP2B1/8/2N2N2/PP2PPPP/R2QKB1R", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 305, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP2B1/8/2N2N2/PP2PPPP/R2QKB1R", "treename":"Benoni Defense, Uhlmann Variation"},
{"gid": 306, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Woozle"},
{"gid": 306, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Woozle"},
{"gid": 306, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Woozle"},
{"gid": 306, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Woozle"},
{"gid": 306, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/8/2N5/PPP1PPPP/R1BQKBNR", "treename":"Benoni Defense, Woozle"},
{"gid": 306, "fen":"rnb1kb1r/pp1ppppp/5n2/q1pP4/8/2N5/PPP1PPPP/R1BQKBNR", "treename":"Benoni Defense, Woozle"},
{"gid": 300, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/5B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/1p3p1p/p2p1np1/2pP4/5B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/1p1ppppp/p4n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/4P3/PP3PPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/4P3/PP3PPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/4P3/PP3PPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppp1p/5np1/2p5/2PP4/4P3/PP3PPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppp1p/5np1/2p5/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N1P/PP2PPP1/R1BQKB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pp4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp1p1ppp/5n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqkb1r/pp3p1p/3p1np1/2pP4/5B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/5B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 300, "fen":"rnbqk2r/pp3pbp/3p1np1/2pP4/Q4B2/2N2N2/PP2PPPP/R3KB1R", "treename":"Benoni Defense, Main Line"},
{"gid": 307, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/p2p1ppp/4pn2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/p2p1ppp/4pn2/1ppP2B1/2P5/5N2/PP2PPPP/RN1QKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/p2p1pp1/4pn1p/1ppP2B1/2P5/5N2/PP2PPPP/RN1QKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/p2p1ppp/4pn2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnbqkb1r/p2p1ppp/4pn2/1ppP2B1/2P5/5N2/PP2PPPP/RN1QKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 307, "fen":"rnb1kb1r/p2p1ppp/4pn2/qppP2B1/2P5/5N2/PP2PPPP/RN1QKB1R", "treename":"Blumenfeld Countergambit, Dus-Khotimirsky Variation"},
{"gid": 309, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 309, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 309, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 309, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 309, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 309, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 309, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 309, "fen":"rnbqkb1r/p2p1ppp/4pn2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 309, "fen":"rnbqkb1r/p2p1ppp/4pn2/1ppP2B1/2P5/5N2/PP2PPPP/RN1QKB1R", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 309, "fen":"rnbqkb1r/p2p1ppp/5n2/1ppp2B1/2P5/5N2/PP2PPPP/RN1QKB1R", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 309, "fen":"rnbqkb1r/p2p1ppp/5n2/1ppP2B1/8/5N2/PP2PPPP/RN1QKB1R", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 309, "fen":"rnbqkb1r/p2p1pp1/5n1p/1ppP2B1/8/5N2/PP2PPPP/RN1QKB1R", "treename":"Blumenfeld Countergambit, Spielmann Variation"},
{"gid": 308, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/p2p1ppp/1p2pn2/2p5/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 308, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/2Pp4/4PN2/PP3PPP/RNBQKB1R", "treename":"Blumenfeld Countergambit, Main Line"},
{"gid": 310, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 310, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 310, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 310, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 310, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 310, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 310, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 310, "fen":"rnbqkb1r/p2p1ppp/4pn2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 310, "fen":"rnbqkb1r/p2p1ppp/4Pn2/1pp5/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 310, "fen":"rnbqkb1r/p2p2pp/4pn2/1pp5/2P5/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 310, "fen":"rnbqkb1r/p2p2pp/4pn2/1Pp5/8/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 310, "fen":"rnbqkb1r/p5pp/4pn2/1Ppp4/8/5N2/PP2PPPP/RNBQKB1R", "treename":"Blumenfeld Countergambit Accepted, Main Line"},
{"gid": 311, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/5N2/PP1bPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/5N2/PP1NPPPP/R2QKB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/5N2/PP1bPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/5N2/PP1QPPPP/RN2KB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/5N2/PP1QPPPP/RN2KB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/2N2N2/PP1QPPPP/R3KB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP1QPPPP/R3KB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 311, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/2PP4/2N1PN2/PP1Q1PPP/R3KB1R", "treename":"Bogo-Indian Defense, Exchange Variation"},
{"gid": 312, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/P4N2/1P1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P4N2/1P1bPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/P4N2/1P1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P1bPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P1BPPPP/R2QKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P1BPPPP/R2QKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/P4N2/1P1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbq1rk1/ppppbppp/4pn2/8/2PP4/P4N2/1P1NPPPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbq1rk1/ppppbppp/4pn2/8/2PPP3/P4N2/1P1N1PPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 312, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PPP3/P4N2/1P1N1PPP/R1BQKB1R", "treename":"Bogo-Indian Defense, Grunfeld Variation"},
{"gid": 313, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/1bPP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/2PP4/5NP1/PP1bPPBP/RN1QK2R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/2PP4/5NP1/PP1NPPBP/R2QK2R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppp1qppp/2nppn2/8/2PP4/5NP1/PP1NPPBP/R2QK2R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppp1qppp/2nppn2/8/2PP4/5NP1/PP1NPPBP/R2Q1RK1", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b2rk1/ppp1qppp/2nppn2/8/2PP4/5NP1/PP1NPPBP/R2Q1RK1", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/1bPP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/2PP4/5NP1/PP1bPPBP/RN1QK2R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/2PP4/5NP1/PP1NPPBP/R2QK2R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppp1qppp/2nppn2/8/2PP4/5NP1/PP1NPPBP/R2QK2R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppp1qppp/2nppn2/8/2PP4/5NP1/PP1NPPBP/R2Q1RK1", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/1pp1qppp/2nppn2/p7/2PP4/5NP1/PP1NPPBP/R2Q1RK1", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/1pp1qppp/2nppn2/p7/2PPP3/5NP1/PP1N1PBP/R2Q1RK1", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/1pp1qppp/2np1n2/p3p3/2PPP3/5NP1/PP1N1PBP/R2Q1RK1", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/1pp1qppp/2np1n2/p2Pp3/2P1P3/5NP1/PP1N1PBP/R2Q1RK1", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb1k2r/1pp1qppp/3p1n2/p2Pp3/2P1P3/5NP1/PP1N1PBP/R2Q1RK1", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb2rk1/ppppqppp/4pn2/8/1bPP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb2rk1/ppppqppp/4pn2/8/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/2N2N2/PP1BPPPP/R2QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/1bPP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/1bPP4/2N2NP1/PP1BPP1P/R2QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/2PP4/2b2NP1/PP1BPP1P/R2QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1pn2/8/2PP4/2B2NP1/PP2PP1P/R2QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1p3/8/2PPn3/2B2NP1/PP2PP1P/R2QKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 313, "fen":"r1b1k2r/ppppqppp/2n1p3/8/2PPn3/2B2NP1/PP2PP1P/2RQKB1R", "treename":"Bogo-Indian Defense, Nimzowitsch Variation"},
{"gid": 314, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbqk2r/ppppbppp/4pn2/8/2PP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbqk2r/ppppbppp/4pn2/8/2PP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP1BPPBP/RN1Q1RK1", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP4/5NP1/PP1BPPBP/RN1Q1RK1", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP4/5NP1/PPQBPPBP/RN3RK1", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbq1rk1/p3bppp/1pp1pn2/3p4/2PP4/5NP1/PPQBPPBP/RN3RK1", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 314, "fen":"rnbq1rk1/p3bppp/1pp1pn2/3p4/2PP1B2/5NP1/PPQ1PPBP/RN3RK1", "treename":"Bogo-Indian Defense, Retreat Variation"},
{"gid": 315, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Vitolins Variation"},
{"gid": 315, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Vitolins Variation"},
{"gid": 315, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Vitolins Variation"},
{"gid": 315, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Vitolins Variation"},
{"gid": 315, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Vitolins Variation"},
{"gid": 315, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Vitolins Variation"},
{"gid": 315, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Vitolins Variation"},
{"gid": 315, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Vitolins Variation"},
{"gid": 315, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1BPP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Vitolins Variation"},
{"gid": 315, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1pPP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Vitolins Variation"},
{"gid": 315, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1pPP4/5NP1/PP2PP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Vitolins Variation"},
{"gid": 316, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqk2r/1ppp1ppp/4pn2/p7/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqk2r/1ppp1ppp/4pn2/p7/1bPP4/2N2N2/PP1BPPPP/R2QKB1R", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqk2r/1ppp1ppp/4pn2/p7/1bPP4/5N2/PP1BPPPP/RN1QKB1R", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 316, "fen":"rnbqk2r/1ppp1ppp/4pn2/p7/1bPP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Bogo-Indian Defense, Wade-Smyslov Variation"},
{"gid": 317, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Budapest Defense, Adler Variation"},
{"gid": 317, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Budapest Defense, Adler Variation"},
{"gid": 317, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Adler Variation"},
{"gid": 317, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Adler Variation"},
{"gid": 317, "fen":"rnbqkb1r/pppp1ppp/5n2/4P3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Adler Variation"},
{"gid": 317, "fen":"rnbqkb1r/pppp1ppp/8/4P3/2P3n1/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Adler Variation"},
{"gid": 317, "fen":"rnbqkb1r/pppp1ppp/8/4P3/2P3n1/5N2/PP2PPPP/RNBQKB1R", "treename":"Budapest Defense, Adler Variation"},
{"gid": 317, "fen":"rnbqk2r/pppp1ppp/8/2b1P3/2P3n1/5N2/PP2PPPP/RNBQKB1R", "treename":"Budapest Defense, Adler Variation"},
{"gid": 317, "fen":"rnbqk2r/pppp1ppp/8/2b1P3/2P3n1/4PN2/PP3PPP/RNBQKB1R", "treename":"Budapest Defense, Adler Variation"},
{"gid": 317, "fen":"r1bqk2r/pppp1ppp/2n5/2b1P3/2P3n1/4PN2/PP3PPP/RNBQKB1R", "treename":"Budapest Defense, Adler Variation"},
{"gid": 317, "fen":"r1bqk2r/pppp1ppp/2n5/2b1P3/2P3n1/4PN2/PP2BPPP/RNBQK2R", "treename":"Budapest Defense, Adler Variation"},
{"gid": 318, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Budapest Defense, Alekhine Variation"},
{"gid": 318, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Budapest Defense, Alekhine Variation"},
{"gid": 318, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Alekhine Variation"},
{"gid": 318, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Alekhine Variation"},
{"gid": 318, "fen":"rnbqkb1r/pppp1ppp/5n2/4P3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Alekhine Variation"},
{"gid": 318, "fen":"rnbqkb1r/pppp1ppp/8/4P3/2P3n1/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Alekhine Variation"},
{"gid": 318, "fen":"rnbqkb1r/pppp1ppp/8/4P3/2P1P1n1/8/PP3PPP/RNBQKBNR", "treename":"Budapest Defense, Alekhine Variation"},
{"gid": 318, "fen":"rnbqkb1r/pppp1ppp/8/4n3/2P1P3/8/PP3PPP/RNBQKBNR", "treename":"Budapest Defense, Alekhine Variation"},
{"gid": 318, "fen":"rnbqkb1r/pppp1ppp/8/4n3/2P1PP2/8/PP4PP/RNBQKBNR", "treename":"Budapest Defense, Alekhine Variation"},
{"gid": 318, "fen":"rnbqkb1r/pppp1ppp/2n5/8/2P1PP2/8/PP4PP/RNBQKBNR", "treename":"Budapest Defense, Alekhine Variation"},
{"gid": 320, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppp1ppp/5n2/4P3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppp1ppp/8/4P3/2P3n1/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppp1ppp/8/4P3/2P2Bn1/8/PP2PPPP/RN1QKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqk2r/pppp1ppp/8/4P3/1bP2Bn1/8/PP2PPPP/RN1QKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppp1ppp/5n2/4P3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppp1ppp/8/4P3/2P3n1/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"rnbqkb1r/pppp1ppp/8/4P3/2P2Bn1/8/PP2PPPP/RN1QKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"r1bqkb1r/pppp1ppp/2n5/4P3/2P2Bn1/8/PP2PPPP/RN1QKBNR", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"r1bqkb1r/pppp1ppp/2n5/4P3/2P2Bn1/5N2/PP2PPPP/RN1QKB1R", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"r1bqk2r/pppp1ppp/2n5/4P3/1bP2Bn1/5N2/PP2PPPP/RN1QKB1R", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"r1bqk2r/pppp1ppp/2n5/4P3/1bP2Bn1/5N2/PP1NPPPP/R2QKB1R", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"r1b1k2r/ppppqppp/2n5/4P3/1bP2Bn1/5N2/PP1NPPPP/R2QKB1R", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 320, "fen":"r1b1k2r/ppppqppp/2n5/4P3/1bP2Bn1/4PN2/PP1N1PPP/R2QKB1R", "treename":"Budapest Defense, Rubinstein Variation"},
{"gid": 319, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Budapest Defense, Main Line"},
{"gid": 319, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Budapest Defense, Main Line"},
{"gid": 319, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Main Line"},
{"gid": 319, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Main Line"},
{"gid": 319, "fen":"rnbqkb1r/pppp1ppp/5n2/4P3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Main Line"},
{"gid": 319, "fen":"rnbqkb1r/pppp1ppp/8/4P3/2P3n1/8/PP2PPPP/RNBQKBNR", "treename":"Budapest Defense, Main Line"},
{"gid": 319, "fen":"rnbqkb1r/pppp1ppp/8/4P3/2P3n1/4P3/PP3PPP/RNBQKBNR", "treename":"Budapest Defense, Main Line"},
{"gid": 321, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNBR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNBR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3p4/2PP4/1PN2NP1/P1Q1PPBP/R1BR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r2q1rk1/pb1nbppp/2p1pn2/1p1p4/2PP4/1PN2NP1/P1Q1PPBP/R1BR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNBR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNBR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3p4/2PP4/1PN2NP1/P1Q1PPBP/R1BR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"2rq1rk1/pb1nbppp/1pp1pn2/3p4/2PP4/1PN2NP1/P1Q1PPBP/R1BR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"2rq1rk1/pb1nbppp/1pp1pn2/3p4/2PPP3/1PN2NP1/P1Q2PBP/R1BR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"2rq1rk1/pb1nbppp/1pp1pn2/8/2PPp3/1PN2NP1/P1Q2PBP/R1BR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/p2nbppp/2p1pn2/1p1p4/2PP4/1P3NP1/P1Q1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNBR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r2q1rk1/p2nbppp/bpp1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNBR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 321, "fen":"r2q1rk1/p2nbppp/bpp1pn2/3p4/2PP4/1P3NP1/P1QNPPBP/R1BR2K1", "treename":"Catalan Opening, Closed"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/2NQ1NP1/PP2PPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/2p1pn2/1p1p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PPP3/5NP1/PPQN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3p4/2PPP3/5NP1/PPQN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3p4/2PPP3/1P3NP1/P1QN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"2rq1rk1/pb1nbppp/1pp1pn2/3p4/2PPP3/1P3NP1/P1QN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"2rq1rk1/pb1nbppp/1pp1pn2/3p4/2PPP3/1P3NP1/PBQN1PBP/R4RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"2r2rk1/pbqnbppp/1pp1pn2/3p4/2PPP3/1P3NP1/PBQN1PBP/R4RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PPP3/5NP1/PPQN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3p4/2PPP3/5NP1/PPQN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3p4/2PPP3/1P3NP1/P1QN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"2rq1rk1/pb1nbppp/1pp1pn2/3p4/2PPP3/1P3NP1/P1QN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"2rq1rk1/pb1nbppp/1pp1pn2/3p4/2PPP3/1P3NP1/PBQN1PBP/R4RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"2rq1rk1/pb1nbppp/1p2pn2/2pp4/2PPP3/1P3NP1/PBQN1PBP/R4RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/1P3NP1/P1QNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3p4/2PP4/1P3NP1/P1QNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3p4/2PP4/1P3NP1/PBQNPPBP/R4RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"2rq1rk1/pb1nbppp/1pp1pn2/3p4/2PP4/1P3NP1/PBQNPPBP/R4RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PPP3/5NP1/PPQN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r2q1rk1/p2nbppp/bpp1pn2/3p4/2PPP3/5NP1/PPQN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r2q1rk1/p2nbppp/bpp1pn2/3p4/2PPP3/1P3NP1/P1QN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PPP3/5NP1/PPQN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3p4/2PPP3/5NP1/PPQN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r2q1rk1/pb1nbppp/1pp1pn2/3pP3/2PP4/5NP1/PPQN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PPP3/5NP1/PPQN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/8/2PPp3/5NP1/PPQN1PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/8/2PPN3/5NP1/PPQ2PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1p3/8/2PPn3/5NP1/PPQ2PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1p3/8/2PPQ3/5NP1/PP3PBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p1pnbppp/1p2pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/4pn2/2pp4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP1B2/5NP1/PPQ1PPBP/RN3RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1p3/3p3n/2PP1B2/5NP1/PPQ1PPBP/RN3RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP1B2/5NP1/PPQ1PPBP/RN3RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1p3/3p4/2PPnB2/5NP1/PPQ1PPBP/RN3RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP1B2/5NP1/PPQ1PPBP/RN3RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP1B2/5NP1/PPQ1PPBP/RN3RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/p3bppp/1pp1pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/6P1/PP1NPPBP/R1BQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/6P1/PP1NPPBP/R1BQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB1K2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/8/2pP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/8/2pPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/1QN2NP1/PP2PPBP/R1B2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP4/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/6P1/PP1BPPBP/RN1QK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/1P3NP1/PB2PPBP/RN1Q1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/3Q1NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/pp2bppp/4pn2/2pp4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/pp2bppp/4pn2/2pP4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/p3bppp/1pp1pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 322, "fen":"rnbq1rk1/p3bppp/1pp1pn2/3pN3/2PP4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Closed Variation"},
{"gid": 323, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Hungarian Gambit"},
{"gid": 323, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Hungarian Gambit"},
{"gid": 323, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Hungarian Gambit"},
{"gid": 323, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Hungarian Gambit"},
{"gid": 323, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Hungarian Gambit"},
{"gid": 323, "fen":"rnbqkb1r/pppp1ppp/5n2/4p3/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Hungarian Gambit"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/2QP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1ppn1ppp/p3pn2/8/2QP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1ppn1ppp/p3pn2/8/3P4/6P1/PPQ1PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/1pp1bppp/p3pn2/8/2pP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/1pp1bppp/p3pn2/8/P1pP4/5NP1/1PQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1ppbbppp/p3pn2/8/P1pP4/5NP1/1PQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1ppbbppp/p3pn2/8/P1QP4/5NP1/1P2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1pp1bppp/p1b1pn2/8/P1QP4/5NP1/1P2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1pp1bppp/p1b1pn2/6B1/P1QP4/5NP1/1P2PPBP/RN3RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1pp1bppp/p3pn2/3b2B1/P1QP4/5NP1/1P2PPBP/RN3RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/1pp1bppp/p3pn2/8/2pP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/1pp1bppp/p3pn2/8/P1pP4/5NP1/1PQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1ppbbppp/p3pn2/8/P1pP4/5NP1/1PQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1ppbbppp/p3pn2/8/P1QP4/5NP1/1P2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1pp1bppp/p1b1pn2/8/P1QP4/5NP1/1P2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1pp1bppp/p1b1pn2/6B1/P1QP4/5NP1/1P2PPBP/RN3RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1pp1bppp/2b1pn2/p5B1/P1QP4/5NP1/1P2PPBP/RN3RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/1pp1bppp/p3pn2/8/2pP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/1pp1bppp/p3pn2/8/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/2p1bppp/p3pn2/1p6/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/2p1bppp/p3pn2/1p6/3P4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1bp1bppp/p3pn2/1p6/3P4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1bp1bppp/p3pn2/1p6/3P4/5NP1/PPQBPPBP/RN3RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/2p1bppp/p3pn2/1p6/3Pb3/5NP1/PPQBPPBP/RN3RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/1pp1bppp/p3pn2/8/2pP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/1pp1bppp/p3pn2/8/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/2p1bppp/p3pn2/1p6/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/2p1bppp/p3pn2/1p6/3P4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1bp1bppp/p3pn2/1p6/3P4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1bp1bppp/p3pn2/1p6/3P1B2/5NP1/PPQ1PPBP/RN3RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r2q1rk1/1bp1bppp/p1n1pn2/1p6/3P1B2/5NP1/PPQ1PPBP/RN3RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r2q1rk1/1bp1bppp/p1n1pn2/1p6/3P1B2/5NP1/PPQ1PPBP/RN1R2K1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/1pp1bppp/p3pn2/8/2pP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/1pp1bppp/p3pn2/8/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/2p1bppp/p3pn2/1p6/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/2p1bppp/p3pn2/1p6/3P4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1bp1bppp/p3pn2/1p6/3P4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1q1rk1/1bp1bppp/p3pn2/1p4B1/3P4/5NP1/PPQ1PPBP/RN3RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/4N3/2pP4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bq1rk1/ppp1bppp/2n1pn2/4N3/2pP4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bq1rk1/ppp1bppp/2N1pn2/8/2pP4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp1bppp/4pn2/8/2pP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/8/2pP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbq1rk1/ppp1bppp/4pn2/4N3/2pP4/2N3P1/PP2PPBP/R1BQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/8/Q1pP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqk2r/ppp2ppp/2n1pn2/8/QbpP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqk2r/ppp2ppp/2n1pn2/8/QbpP4/5NP1/PP1BPPBP/RN2K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqk2r/ppp2ppp/2n1p3/3n4/QbpP4/5NP1/PP1BPPBP/RN2K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1qkb1r/pppb1ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2p5/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2p5/Q1pP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pp3ppp/2n1pn2/8/Q1pp4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqk2r/ppp2ppp/4pn2/8/1bpP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/2QP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1ppn1ppp/p3pn2/8/2QP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1ppn1ppp/p3pn2/8/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/2pn1ppp/p3pn2/1p6/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/2pn1ppp/p3pn2/1p6/3P4/5NP1/PPQ1PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/p1p2ppp/4pn2/1p6/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2p5/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2p5/Q1pP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r2qkb1r/pp1b1ppp/2n1pn2/2p5/Q1pP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r2qkb1r/pp1b1ppp/2n1pn2/2p5/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r2qkb1r/p2b1ppp/2n1pn2/1pp5/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/2QP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1ppn1ppp/p3pn2/8/2QP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1ppn1ppp/p3pn2/8/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/2pn1ppp/p3pn2/1p6/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/2pn1ppp/p1Q1pn2/1p6/3P4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/ppp2ppp/2n1pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"1rbqkb1r/ppp2ppp/2n1pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"1rbqkb1r/ppp2ppp/2n1pn2/8/2pP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/2QP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pp1n1ppp/4pn2/2p5/2QP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pp1n1ppp/4pn2/2p5/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1p1n1ppp/p3pn2/2p5/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1p1n1ppp/p3pn2/2p5/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/2QP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pp1n1ppp/4pn2/2p5/2QP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pp1n1ppp/4pn2/2p5/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1p1n1ppp/p3pn2/2p5/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1p1n1ppp/p3pn2/2p5/3P4/5NP1/PPQ1PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/3n1ppp/pp2pn2/2p5/3P4/5NP1/PPQ1PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1pP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1ppn1ppp/p3pn2/8/Q1pP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1ppn1ppp/p3pn2/8/Q1pP4/2N2NP1/PP2PPBP/R1B1K2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2p5/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2p1N3/2pP4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r2qkb1r/pp1b1ppp/2n1pn2/2p1N3/2pP4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r2qkb1r/pp1b1ppp/2n1pn2/2p1N3/2pP4/N5P1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r2qkb1r/pp1b1ppp/2n1pn2/4N3/2pp4/N5P1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r2qkb1r/pp1b1ppp/2n1pn2/4N3/2Np4/6P1/PP2PPBP/R1BQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/1pp2ppp/p3pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/1pp2ppp/p3pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1pp2ppp/p1n1pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"r1bqkb1r/1pp2ppp/p1n1pn2/8/2pP4/4PNP1/PP3PBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1qkb1r/pppb1ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rn1qkb1r/pppb1ppp/4pn2/8/2QP4/6P1/PP2PPBP/RNB1K1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/1pp2ppp/p3pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/1pp2ppp/p3pn2/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/2p2ppp/p3pn2/1p6/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/2p2ppp/p3pn2/1p2N3/2pP4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/2p2ppp/p3p3/1p1nN3/2pP4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 325, "fen":"rnbqkb1r/2p2ppp/p3p3/1p1nN3/P1pP4/6P1/1P2PPBP/RNBQ1RK1", "treename":"Catalan Opening, Open Defense"},
{"gid": 324, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/6P1/PP1BPP1P/RN1QKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/6P1/PP1bPP1P/RN1QKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pp1p1ppp/2p1pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/6P1/PP1BPP1P/RN1QKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/ppppbppp/4pn2/8/2PP4/6P1/PP1BPP1P/RN1QKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/Q1pP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1pP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/6P1/PP1NPP1P/R1BQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/6P1/PP1BPP1P/RN1QKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/6P1/PP1BPP1P/RN1QKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnb1k2r/ppppqppp/4pn2/8/1bPP4/6P1/PP1BPPBP/RN1QK1NR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 324, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Catalan Opening, Main Line"},
{"gid": 326, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/2PBPN2/PP3PPP/RNBQK2R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/3P4/2PBPN2/PP3PPP/RNBQK2R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/3P4/2PBPN2/PP1N1PPP/R1BQK2R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"r1bqk2r/pp3ppp/2nbpn2/2pp4/3P4/2PBPN2/PP1N1PPP/R1BQK2R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"r1bqk2r/pp3ppp/2nbpn2/2pp4/3P4/2PBPN2/PP1N1PPP/R1BQ1RK1", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"r1bq1rk1/pp3ppp/2nbpn2/2pp4/3P4/2PBPN2/PP1N1PPP/R1BQ1RK1", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"r1bq1rk1/pp3ppp/2nbpn2/2Pp4/8/2PBPN2/PP1N1PPP/R1BQ1RK1", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2bp4/8/2PBPN2/PP1N1PPP/R1BQ1RK1", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/2PBPN2/PP3PPP/RNBQK2R", "treename":"Colle System, Traditional Colle"},
{"gid": 326, "fen":"r1bqkb1r/pp1n1ppp/4pn2/2pp4/3P4/2PBPN2/PP3PPP/RNBQK2R", "treename":"Colle System, Traditional Colle"},
{"gid": 327, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbq1rk1/ppppb1pp/4p3/5p2/2PPn3/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbq1rk1/ppppb1pp/4p3/3P1p2/2P1n3/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbq1rk1/ppppb1pp/4p3/5p2/2PPn3/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 327, "fen":"rnbq1rk1/ppppb1pp/4p3/5p2/2PPn3/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Dutch Defense, Alekhine Variation"},
{"gid": 328, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Blackburne Variation"},
{"gid": 328, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Blackburne Variation"},
{"gid": 328, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/6P1/PPP1PP1P/RNBQKBNR", "treename":"Dutch Defense, Blackburne Variation"},
{"gid": 328, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/3P4/6P1/PPP1PP1P/RNBQKBNR", "treename":"Dutch Defense, Blackburne Variation"},
{"gid": 328, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/3P4/6P1/PPP1PPBP/RNBQK1NR", "treename":"Dutch Defense, Blackburne Variation"},
{"gid": 328, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/3P4/6P1/PPP1PPBP/RNBQK1NR", "treename":"Dutch Defense, Blackburne Variation"},
{"gid": 328, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/3P4/6PN/PPP1PPBP/RNBQK2R", "treename":"Dutch Defense, Blackburne Variation"},
{"gid": 329, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Blackmar's Second Gambit"},
{"gid": 329, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Blackmar's Second Gambit"},
{"gid": 329, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Dutch Defense, Blackmar's Second Gambit"},
{"gid": 329, "fen":"rnbqkbnr/ppppp1pp/8/8/3Pp3/8/PPP2PPP/RNBQKBNR", "treename":"Dutch Defense, Blackmar's Second Gambit"},
{"gid": 329, "fen":"rnbqkbnr/ppppp1pp/8/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Dutch Defense, Blackmar's Second Gambit"},
{"gid": 329, "fen":"rnbqkb1r/ppppp1pp/5n2/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Dutch Defense, Blackmar's Second Gambit"},
{"gid": 329, "fen":"rnbqkb1r/ppppp1pp/5n2/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR", "treename":"Dutch Defense, Blackmar's Second Gambit"},
{"gid": 329, "fen":"rnbqkb1r/ppp1p1pp/5n2/3p4/3Pp3/2N2P2/PPP3PP/R1BQKBNR", "treename":"Dutch Defense, Blackmar's Second Gambit"},
{"gid": 329, "fen":"rnbqkb1r/ppp1p1pp/5n2/3p4/3PP3/2N5/PPP3PP/R1BQKBNR", "treename":"Dutch Defense, Blackmar's Second Gambit"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/1pp1b1pp/3ppn2/p4p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/1pp1b1pp/3ppn2/p4p2/2PP4/2N2NP1/PP2PPBP/R1BQR1K1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/1pp1b1pp/3ppn2/p4p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/1pp1b1pp/3ppn2/p4p2/2PP4/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/1pp1b1pp/3ppn2/p4p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/1pp1b1pp/3ppn2/p4p2/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb2rk1/ppp1b1pp/3ppn2/5p1q/2PP4/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"r1b1qrk1/ppp1b1pp/2nppn2/5p2/2PP4/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/1pp1b1pp/3ppn2/p4p2/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/1pp1b1pp/3ppn2/p4p2/2PP4/BPN2NP1/P3PPBP/R2Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/1PPP4/2N2NP1/P3PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"r1bq1rk1/ppp1b1pp/2n1pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/p1p1b1pp/1p2pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/BP3NP1/P3PPBP/RN1Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rn1q1rk1/pp1bb1pp/2p1pn2/3p1p2/2PP4/BP3NP1/P3PPBP/RN1Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/BP3NP1/P3PPBP/RN1Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"r1bq1rk1/pp1nb1pp/2p1pn2/3p1p2/2PP4/BP3NP1/P3PPBP/RN1Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"r1bq1rk1/pp1nB1pp/2p1pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RN1Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP1B2/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1pB1/2PP4/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/BP3NP1/P3PPBP/RN1Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp4pp/2p1pn2/3p1p2/2PP4/bP3NP1/P3PPBP/RN1Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp4pp/2p1pn2/3p1p2/2PP4/NP3NP1/P3PPBP/R2Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb2rk1/pp2q1pp/2p1pn2/3p1p2/2PP4/NP3NP1/P3PPBP/R2Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb2rk1/pp2q1pp/2p1pn2/3p1p2/2PP4/NP3NP1/P3PPBP/R1Q2RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppp2pp/4p3/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppp2pp/4p3/5p2/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pp1p2pp/2p1pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pp1p2pp/2p1pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pp4pp/2p1pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pp4pp/2p1pn2/3p1p2/2PP4/5NP1/PPQ1PPBP/RNB1K2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/pp4pp/2pbpn2/3p1p2/2PP4/5NP1/PPQ1PPBP/RNB1K2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/pp2b1pp/2p1pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp1pb1pp/2p1pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/pp1pb1pp/2p1pn2/5p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/1p1pb1pp/2p1pn2/p4p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppp3pp/4pn2/3p1p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppp3pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/3P1p2/2P5/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pp1p2pp/2p1pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pp1p2pp/2p1pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pp4pp/2p1pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pp4pp/2p1pn2/3p1p2/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/1P3NP1/PB2PPBP/RN1Q1RK1", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppp3pp/4pn2/3p1p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppp3pp/4pn2/3p1p2/2PP4/6PN/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pp1p2pp/2p1pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Classical Variation"},
{"gid": 330, "fen":"rnbqkb1r/pp1p2pp/2p1pn2/5p2/2PP4/6PN/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Classical Variation"},
{"gid": 331, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Attack"},
{"gid": 331, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Attack"},
{"gid": 331, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/6P1/PPP1PP1P/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Attack"},
{"gid": 331, "fen":"rnbqkbnr/pppp2pp/4p3/5p2/3P4/6P1/PPP1PP1P/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Attack"},
{"gid": 331, "fen":"rnbqkbnr/pppp2pp/4p3/5p2/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Dutch Defense, Fianchetto Attack"},
{"gid": 331, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Dutch Defense, Fianchetto Attack"},
{"gid": 332, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkb1r/ppp3pp/4pn2/3p1p2/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 332, "fen":"rnbqkb1r/ppp3pp/4pn2/3p1p2/2PP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Dutch Defense, Fianchetto Variation"},
{"gid": 333, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQR1K1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnb2rk1/ppp1b1pp/3ppnq1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQR1K1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnb2rk1/ppp1b1pp/3ppnq1/5p2/2PPP3/2N2NP1/PP3PBP/R1BQR1K1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnb2rk1/ppp1b1pp/3ppnq1/8/2PPp3/2N2NP1/PP3PBP/R1BQR1K1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQR1K1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 333, "fen":"rnb1qrk1/ppp1b1pp/3pp3/5p2/2PPn3/2N2NP1/PP2PPBP/R1BQR1K1", "treename":"Dutch Defense, Ilyin-Zhenevsky"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/2np1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/2np1np1/5p2/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/2np1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/2np1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/3p1np1/3Pnp2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/3p1np1/3PNp2/2P5/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/5np1/3Ppp2/2P5/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/5np1/3Ppp2/2P1P3/2N3P1/PP3PBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/2np1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/2np1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/3p1np1/n2P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/3p1np1/n2P1p2/2P5/2NQ1NP1/PP2PPBP/R1B2RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/2np1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/2np1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/3p1np1/n2P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/3p1np1/n2P1p2/2P5/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/2np1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/2np1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/3p1np1/3Pnp2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/3p1np1/3PNp2/2P5/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/5np1/3Ppp2/2P5/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/5np1/3Ppp2/2P5/1QN3P1/PP2PPBP/R1B2RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb2rk1/pp2p1bp/2pp1np1/q2P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/3p1np1/3p1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rn1q1rk1/pp1bp1bp/2pp1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb2rk1/pp2p1bp/2pp1np1/q4p2/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/pp2p1bp/2pp1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/pp2p1bp/n1pp1np1/5p2/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp4bp/2pp1np1/3Ppp2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp4bp/2ppPnp1/5p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rn1q1rk1/pp4bp/2ppbnp1/5p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rn1q1rk1/pp4bp/2ppbnp1/5p2/2P5/2NQ1NP1/PP2PPBP/R1B2RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQR1K1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp4bp/2pp1np1/3Ppp2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp4bp/2ppPnp1/5p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rn1q1rk1/pp4bp/2ppbnp1/5p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rn1q1rk1/pp4bp/2ppbnp1/5p2/2P5/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp3bp/3ppnp1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/3P1p2/2P5/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/3p1np1/2pP1p2/2P5/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1bq1rk1/ppp1p1bp/n2p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/3P1p2/2P5/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp2p1bp/2pp1np1/3P1p2/2P5/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/pp1pp1bp/2p2np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppp1p2p/3p1np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/ppp1p1bp/3p1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/1pp1p1bp/3p1np1/p2P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/ppp1p1bp/3p1np1/3N1p2/2PP4/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/2N3PN/PP2PPBP/R1BQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6PN/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppp1p1bp/3p1np1/5p2/2PP4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppp1p1bp/3p1np1/5p2/2PP4/2N3PN/PP2PPBP/R1BQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/ppp1p1bp/3p1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1b1qrk1/ppp1p1bp/n2p1np1/3P1p2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"r1b1qrk1/ppp1p1bp/n2p1np1/3P1p2/2P5/2N2NP1/PP2PPBP/1RBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQR1K1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppp1p1bp/3p1np1/5p2/2PP4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppp1p1bp/3p1np1/3P1p2/2P5/2N3P1/PP2PPBP/R1BQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnbq1rk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/ppp1p1bp/3p1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 334, "fen":"rnb1qrk1/ppp1p1bp/3p1np1/5p2/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Dutch Defense, Leningrad Variation"},
{"gid": 335, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqk2r/pppp2pp/4pn2/5p2/1bPP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqk2r/pppp2pp/4pn2/5p2/1bPP4/6P1/PP1BPPBP/RN1QK1NR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP1BPPBP/RN1QK1NR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqk2r/pppp2pp/4pn2/5p2/1bPP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqk2r/pppp2pp/4pn2/5p2/1bPP4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqk2r/pppp2pp/4pn2/5p2/1bPP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 335, "fen":"rnbqk2r/pppp2pp/4pn2/5p2/1bPP4/6P1/PP1NPPBP/R1BQK1NR", "treename":"Dutch Defense, Nimzo-Dutch Variation"},
{"gid": 336, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppp3pp/4pn2/3p1p2/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppp1p1pp/3p1n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqk2r/pppp2pp/4pn2/5p2/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/2N2P2/PP2P1PP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5pB1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp2p/5np1/5pB1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 336, "fen":"rnbqk2r/ppppp1bp/5np1/5p2/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Dutch Defense, Queen's Knight Variation"},
{"gid": 337, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkb1r/ppppp1pp/5n2/5pB1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkb1r/pppp2pp/4pn2/5pB1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkb1r/ppppp1pp/5n2/5pB1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkb1r/ppp1p1pp/5n2/3p1pB1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkb1r/ppp1p1pp/5B2/3p1p2/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkb1r/ppp3pp/5p2/3p1p2/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkb1r/ppp3pp/5p2/3p1p2/3P4/2N1P3/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 337, "fen":"rnbqkb1r/pp4pp/2p2p2/3p1p2/3P4/2N1P3/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Raphael Variation"},
{"gid": 338, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Semi-Leningrad Variation"},
{"gid": 338, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Semi-Leningrad Variation"},
{"gid": 338, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/6P1/PPP1PP1P/RNBQKBNR", "treename":"Dutch Defense, Semi-Leningrad Variation"},
{"gid": 338, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/3P4/6P1/PPP1PP1P/RNBQKBNR", "treename":"Dutch Defense, Semi-Leningrad Variation"},
{"gid": 338, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/3P4/6P1/PPP1PPBP/RNBQK1NR", "treename":"Dutch Defense, Semi-Leningrad Variation"},
{"gid": 338, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/3P4/6P1/PPP1PPBP/RNBQK1NR", "treename":"Dutch Defense, Semi-Leningrad Variation"},
{"gid": 338, "fen":"rnbqkb1r/ppppp2p/5np1/5p2/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Dutch Defense, Semi-Leningrad Variation"},
{"gid": 339, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/8/3Pp3/8/PPP2PPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkb1r/ppppp1pp/5n2/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkb1r/ppppp1pp/5n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"r1bqkb1r/ppppp1pp/2n2n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"r1bqkb1r/ppppp1pp/2n2n2/6B1/3Pp3/2N2P2/PPP3PP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/8/3Pp3/8/PPP2PPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkb1r/ppppp1pp/5n2/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkb1r/ppppp1pp/5n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"r1bqkb1r/ppppp1pp/2n2n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"r1bqkb1r/ppppp1pp/2n2n2/3P2B1/4p3/2N5/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"r1bqkb1r/ppppp1pp/5n2/3Pn1B1/4p3/2N5/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"r1bqkb1r/ppppp1pp/5n2/3Pn1B1/3Qp3/2N5/PPP2PPP/R3KBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3PP3/8/PPP2PPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/8/3Pp3/8/PPP2PPP/RNBQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkbnr/ppppp1pp/8/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkb1r/ppppp1pp/5n2/8/3Pp3/2N5/PPP2PPP/R1BQKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkb1r/ppppp1pp/5n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkb1r/pppp2pp/4pn2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqkb1r/pppp2pp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqk2r/ppppb1pp/4pn2/6B1/3PN3/8/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqk2r/ppppb1pp/4pB2/8/3PN3/8/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqk2r/pppp2pp/4pb2/8/3PN3/8/PPP2PPP/R2QKBNR", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 339, "fen":"rnbqk2r/pppp2pp/4pb2/8/3PN3/5N2/PPP2PPP/R2QKB1R", "treename":"Dutch Defense, Staunton Gambit"},
{"gid": 340, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/pp1p2pp/2p1pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/pp1p2pp/2p1pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/pp4pp/2p1pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/pp4pp/2p1pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqk2r/pp4pp/2pbpn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqk2r/pp4pp/2pbpn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 340, "fen":"rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/5NP1/PPQNPPBP/R1B2RK1", "treename":"Dutch Defense, Stonewall Variation"},
{"gid": 341, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pp1ppp1p/5np1/2p5/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqk2r/ppppppbp/5np1/8/3P4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 341, "fen":"rnbqk2r/ppppppbp/5np1/8/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"East Indian Defense, Main Line"},
{"gid": 342, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/1QN1PN2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbq1rk1/ppp2pbp/4pnp1/3p4/2PP4/1QN1PN2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 342, "fen":"rnbq1rk1/ppp2pbp/4pnp1/3p4/2PP4/1QN1PN2/PP1B1PPP/R3KB1R", "treename":"Grunfeld Defense, Botvinnik Variation"},
{"gid": 343, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/2RQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/pp2ppbp/5np1/2pp4/2PP1B2/2N1P3/PP3PPP/2RQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/pp2ppbp/5np1/2Pp4/2P2B2/2N1P3/PP3PPP/2RQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rn1q1rk1/pp2ppbp/4bnp1/2Pp4/2P2B2/2N1P3/PP3PPP/2RQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/1QN1P3/PP3PPP/R3KBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/6p1/3N4/3P1B2/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnb2rk1/ppp1ppbp/6p1/3q4/3P1B2/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnb2rk1/ppB1ppbp/6p1/3q4/3P4/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rn3rk1/ppB1ppbp/6p1/3q1b2/3P4/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/6p1/3N4/3P1B2/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnb2rk1/ppp1ppbp/6p1/3q4/3P1B2/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnb2rk1/ppB1ppbp/6p1/3q4/3P4/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"r1b2rk1/ppB1ppbp/2n3p1/3q4/3P4/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"r1b2rk1/ppB1ppbp/2n3p1/3q4/3P4/4P3/PP2NPPP/R2QKB1R", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"r4rk1/ppB1ppbp/2n3p1/3q4/3P2b1/4P3/PP2NPPP/R2QKB1R", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbq1rk1/ppp1ppbp/6p1/3N4/3P1B2/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnb2rk1/ppp1ppbp/6p1/3q4/3P1B2/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnb2rk1/ppB1ppbp/6p1/3q4/3P4/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"r1b2rk1/ppB1ppbp/n5p1/3q4/3P4/4P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"r1b2rk1/ppB1ppbp/B5p1/3q4/3P4/4P3/PP3PPP/R2QK1NR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"r1b2rk1/ppB1ppbp/B5p1/8/3P4/4P3/PP3PqP/R2QK1NR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/pp2ppbp/5np1/2pp4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/pp2ppbp/5np1/2Pp4/2P2B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnb1k2r/pp2ppbp/5np1/q1Pp4/2P2B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnb1k2r/pp2ppbp/5np1/q1Pp4/Q1P2B2/2N1P3/PP3PPP/R3KBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/pp2ppbp/5np1/2pp4/2PP1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnbqk2r/pp2ppbp/5np1/2Pp4/2P2B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnb1k2r/pp2ppbp/5np1/q1Pp4/2P2B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 343, "fen":"rnb1k2r/pp2ppbp/5np1/q1Pp4/2P2B2/2N1P3/PP3PPP/2RQKBNR", "treename":"Grunfeld Defense, Brinckmann Attack"},
{"gid": 344, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/pp2ppbp/5np1/2pp4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/N4NP1/PP2PPBP/R1BQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/pp2ppbp/2p2np1/3P4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/pp2ppbp/5np1/3p4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 344, "fen":"rnbqk2r/pp2ppbp/5np1/3p4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Grunfeld Defense, Counterthrust Variation"},
{"gid": 345, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnb2rk1/pppqppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnb2rk1/pppqppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnb2rk1/p1pqppbp/1p4p1/8/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbq1rk1/p1p1ppbp/1p4p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbq1rk1/p1p1ppbp/1p4p1/8/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbq1rk1/p1p1ppbp/1p4p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange"},
{"gid": 345, "fen":"rnbq1rk1/p1p1ppbp/1p4p1/8/2BPP2P/2P5/P3NPP1/R1BQK2R", "treename":"Grunfeld Defense, Exchange"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/p1p1ppbp/1p4p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/1RBQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/1RBQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/p3ppbp/1p4p1/2p5/3PP3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P3BPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P3BPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P3BPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/3PP3/2P2N2/P3BPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P3BPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P3BPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P3BPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/p3ppbp/1p4p1/2p5/3PP3/2P2N2/P3BPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P1BN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bqk2r/pp2ppbp/2n3p1/2p5/3PP3/2P1BN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/1RBQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/1RBQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q1p5/3PP3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P1BN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rn1qk2r/pp2ppbp/6p1/2p5/3PP1b1/2P1BN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/1RBQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/1RBQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/3PP3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2pP4/4P3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/6p1/2pPn3/4P3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/6p1/2pPN3/4P3/2P5/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2pp1p/6p1/2pPb3/4P3/2P5/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2pp1p/6p1/2pPb3/4P3/2P5/P2QBPPP/1RB1K2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp3p1p/4p1p1/2pPb3/4P3/2P5/P2QBPPP/1RB1K2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P1BN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P1BN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P1BN2/P4PPP/2RQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q1p5/3PP3/2P1BN2/P4PPP/2RQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q1p5/3PP3/2P1BN2/P2Q1PPP/2R1KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q7/3pP3/2P1BN2/P2Q1PPP/2R1KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q7/3PP3/4BN2/P2Q1PPP/2R1KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/8/3PP3/4BN2/P2q1PPP/2R1KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/8/3PP3/4B3/P2N1PPP/2R1KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P1BN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb1k2r/pp2ppbp/6p1/q1p5/3PP3/2P1BN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb1k2r/pp2ppbp/6p1/q1p5/3PP3/2P1BN2/P2Q1PPP/R3KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1b1k2r/pp2ppbp/2n3p1/q1p5/3PP3/2P1BN2/P2Q1PPP/R3KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1b1k2r/pp2ppbp/2n3p1/q1p5/3PP3/2P1BN2/P2Q1PPP/2R1KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/1Bp5/3PP3/2P2N2/P4PPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/1RBQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/1RBQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/8/3pP3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/8/3PP3/5N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q7/3PP3/5N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q7/3PP3/5N2/P2QBPPP/1RB1K2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P1BN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P1BN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P1BN2/P4PPP/2RQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q1p5/3PP3/2P1BN2/P4PPP/2RQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q1p5/3PP3/2P1BN2/P2Q1PPP/2R1KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q7/3pP3/2P1BN2/P2Q1PPP/2R1KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q7/3PP3/4BN2/P2Q1PPP/2R1KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/8/3PP3/4BN2/P2q1PPP/2R1KB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/8/3PP3/4BN2/P2K1PPP/2R2B1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/1RBQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P4PPP/1RBQKB1R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/8/3pP3/2P2N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/8/3PP3/5N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q7/3PP3/5N2/P3BPPP/1RBQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/q7/3PP3/5N2/P2BBPPP/1R1QK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/8/3PP3/5N2/q2BBPPP/1R1QK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb2rk1/pp2ppbp/6p1/8/3PP3/5N2/q2BBPPP/1R1Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rn3rk1/pp2ppbp/6p1/8/3PP1b1/5N2/q2BBPPP/1R1Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rn3rk1/pp2ppbp/6p1/6B1/3PP1b1/5N2/q3BPPP/1R1Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/N2P4/8/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/2p5/2BPP1b1/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/2p5/2BPP1b1/2P1BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n1p5/2BPP1b1/2P1BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2pBbp/6p1/n1p5/3PP1b1/2P1BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/ppp1ppbp/2n3p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/ppp1ppbp/2n3p1/8/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/p1p1ppbp/1pn3p1/8/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/ppp1ppbp/2n3p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/ppp1ppbp/2n3p1/8/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/ppp2pbp/2n3p1/4p3/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/ppp1ppbp/2n3p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/ppp1ppbp/2n3p1/8/2BPP3/2P1B3/P3NPPP/R2QK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BpP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BPP3/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/3PP1b1/3BBP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n7/3PP3/3BBP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n2P4/4P3/3BBP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2pp1p/4b1p1/n2P4/4P3/3BBP2/P3N1PP/b2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2pp1p/4b1p1/n2P4/4P3/3BBP2/P3N1PP/Q4RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2p2p/4bpp1/n2P4/4P3/3BBP2/P3N1PP/Q4RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2p2p/4bppB/n2P4/4P3/3B1P2/P3N1PP/Q4RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BpP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BPP3/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/3PP1b1/3BBP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n7/3PP3/3BBP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n2P4/4P3/3BBP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2pp1p/4b1p1/n2P4/4P3/3BBP2/P3N1PP/b2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2pp1p/4b1p1/n2P4/4P3/3BBP2/P3N1PP/Q4RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2p2p/4bpp1/n2P4/4P3/3BBP2/P3N1PP/Q4RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2p2p/4bpp1/n2P4/4P3/3BBP2/P3N1PP/QR4K1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/6p1/n1p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P1B3/P3NPPP/R2QK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2QK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BpP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BPP3/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/3PP1b1/3BBP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n7/3PP3/3BBP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n7/3PP3/3BBP2/P3N1PP/2RQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/3PP3/3BBP2/b3N1PP/2RQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/Q2PP3/3BBP2/b3N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n7/Q2PP3/3BBP2/4N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n2P4/Q3P3/3BBP2/4N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp1bppbp/6p1/n2P4/Q3P3/3BBP2/4N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp1bppbp/6p1/n2P4/1Q2P3/3BBP2/4N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/p2bppbp/1p4p1/n2P4/1Q2P3/3BBP2/4N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BpP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BPP3/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/3PP1b1/3BBP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n7/3PP3/3BBP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n7/3PP3/3BBP2/P3N1PP/2RQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/3PP3/3BBP2/b3N1PP/2RQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/Q2PP3/3BBP2/b3N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n7/Q2PP3/3BBP2/4N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/4b1p1/n2P4/Q3P3/3BBP2/4N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp1bppbp/6p1/n2P4/Q3P3/3BBP2/4N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp1bppbp/6p1/n2P4/1Q2P3/3BBP2/4N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp1b1pbp/4p1p1/n2P4/1Q2P3/3BBP2/4N1PP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BpP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BPP3/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n2B4/3PP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/2p5/2BPP1b1/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/2p5/2BPP1b1/2P1BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n1p5/2BPP1b1/2P1BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n1pB4/3PP1b1/2P1BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BpP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BPP3/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/6p1/n7/2BPP3/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/6p1/n7/3PP3/3BB3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1b2rk1/ppq1ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1b2rk1/ppq1ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/2RQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1br2k1/ppq1ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/2RQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1br2k1/ppq1ppbp/2n3p1/2p5/2BPP3/2P1B3/P2QNPPP/2R2RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1b2rk1/ppq1ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1b2rk1/ppq1ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/2RQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1br2k1/ppq1ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/2RQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1br2k1/ppq1ppbp/2n3p1/2p5/2BPPB2/2P5/P3NPPP/2RQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/ppp1ppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P5/P3NPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/2p5/2BPP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BpP3/2P1B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r1bq1rk1/pp2ppbp/2n3p1/8/2BPP3/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4B3/P3NPPP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/2n3p1/8/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2ppbp/6p1/n7/2BPP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"r2q1rk1/pp2pBbp/6p1/n7/3PP1b1/4BP2/P3N1PP/R2Q1RK1", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pp2pp1p/6p1/2p5/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/B1P5/P4PPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P1B3/P4PPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P1B3/P4PPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P1B3/P2Q1PPP/R3KBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb1k2r/pp2ppbp/6p1/q1p5/3PP3/2P1B3/P2Q1PPP/R3KBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb1k2r/pp2ppbp/6p1/q1p5/3PP3/2P1B3/P2Q1PPP/1R2KBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb1k2r/p3ppbp/1p4p1/q1p5/3PP3/2P1B3/P2Q1PPP/1R2KBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/1B6/3PP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/2p3p1/1B6/3PP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/2p3p1/8/B2PP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/2p3p1/8/B2PP3/2P5/P4PPP/R1BQK1NR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P1B3/P4PPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P1B3/P4PPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P1B3/P2Q1PPP/R3KBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb1k2r/pp2ppbp/6p1/q1p5/3PP3/2P1B3/P2Q1PPP/R3KBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnb1k2r/pp2ppbp/6p1/q1p5/3PP3/2P1B3/P2Q1PPP/2R1KBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/2N5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2n5/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P5/P4PPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P1B3/P4PPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P1B3/P4PPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P1B3/P2Q1PPP/R3KBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P1B3/P2Q1PPP/R3KBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3PP3/2P1B3/P2Q1PPP/2R1KBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP1BPPPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/2N5/PP1BPPPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3PP3/2N5/PP1B1PPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/2N5/PP1B1PPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 346, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/2N1B3/PP3PPP/R2QKBNR", "treename":"Grunfeld Defense, Exchange Variation"},
{"gid": 347, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Flohr Variation"},
{"gid": 347, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Flohr Variation"},
{"gid": 347, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Flohr Variation"},
{"gid": 347, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Flohr Variation"},
{"gid": 347, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Flohr Variation"},
{"gid": 347, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Flohr Variation"},
{"gid": 347, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Flohr Variation"},
{"gid": 347, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Flohr Variation"},
{"gid": 347, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/Q1PP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Flohr Variation"},
{"gid": 348, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Lutikov Variation"},
{"gid": 348, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Lutikov Variation"},
{"gid": 348, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Lutikov Variation"},
{"gid": 348, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Lutikov Variation"},
{"gid": 348, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Lutikov Variation"},
{"gid": 348, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Lutikov Variation"},
{"gid": 348, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2P2/PP2P1PP/R1BQKBNR", "treename":"Grunfeld Defense, Lutikov Variation"},
{"gid": 350, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Makogonov Variation"},
{"gid": 350, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Makogonov Variation"},
{"gid": 350, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Makogonov Variation"},
{"gid": 350, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Makogonov Variation"},
{"gid": 350, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Makogonov Variation"},
{"gid": 350, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Makogonov Variation"},
{"gid": 350, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Makogonov Variation"},
{"gid": 350, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Makogonov Variation"},
{"gid": 350, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Makogonov Variation"},
{"gid": 350, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Makogonov Variation"},
{"gid": 350, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/1PPP4/2N1PN2/P4PPP/R1BQKB1R", "treename":"Grunfeld Defense, Makogonov Variation"},
{"gid": 351, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Opocensky Variation"},
{"gid": 351, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Opocensky Variation"},
{"gid": 351, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Opocensky Variation"},
{"gid": 351, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Opocensky Variation"},
{"gid": 351, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Opocensky Variation"},
{"gid": 351, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Opocensky Variation"},
{"gid": 351, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Opocensky Variation"},
{"gid": 351, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Opocensky Variation"},
{"gid": 351, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Opocensky Variation"},
{"gid": 351, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Opocensky Variation"},
{"gid": 351, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP1B1PPP/R2QKB1R", "treename":"Grunfeld Defense, Opocensky Variation"},
{"gid": 352, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rn1q1rk1/pppnppbp/6p1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rn1q1rk1/pppnppbp/6p1/8/2QPP1b1/2N1BN2/PP2BPPP/R3K2R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rn1q1rk1/ppp1ppbp/1n4p1/8/2QPP1b1/2N1BN2/PP2BPPP/R3K2R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"rn1q1rk1/ppp1ppbp/1n4p1/8/3PP1b1/2NQBN2/PP2BPPP/R3K2R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"r2q1rk1/ppp1ppbp/1nn3p1/8/3PP1b1/2NQBN2/PP2BPPP/R3K2R", "treename":"Grunfeld Defense, Russian"},
{"gid": 352, "fen":"r2q1rk1/ppp1ppbp/1nn3p1/8/3PP1b1/2NQBN2/PP2BPPP/2KR3R", "treename":"Grunfeld Defense, Russian"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/8/2pP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/8/2QP4/2N5/PP2PPPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1qkb1r/ppp1pp1p/4bnp1/8/2QP4/2N5/PP2PPPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1qkb1r/ppp1pp1p/4bnp1/1Q6/3P4/2N5/PP2PPPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1qkb1r/pppbpp1p/5np1/1Q6/3P4/2N5/PP2PPPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/8/2pP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/8/2QP4/2N5/PP2PPPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N5/PP2PPPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QPP3/2N5/PP3PPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N5/PP3PPP/R1B1KBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/ppp1ppbp/2n2np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/ppp1ppbp/2n2np1/8/2QPP3/2N2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/1pp1ppbp/p4np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/1pp1ppbp/p4np1/4P3/2QP4/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/2p1ppbp/p4np1/1p2P3/2QP4/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/2p1ppbp/p4np1/1p2P3/3P4/1QN2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/2pnppbp/p5p1/1p2P3/3P4/1QN2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/2pnppbp/p5p1/1p2P3/3P4/1QN1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/1pp1ppbp/p4np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/1pp1ppbp/p4np1/8/2QPP3/2N2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/2p1ppbp/p4np1/1p6/2QPP3/2N2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/2p1ppbp/p4np1/1p6/3PP3/1QN2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/1pp1ppbp/p4np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/1pp1ppbp/p4np1/8/3PP3/1QN2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/p1p1ppbp/1p3np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/ppp1ppbp/n4np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/ppp1ppbp/n4np1/8/2QPP3/2N2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/pp2ppbp/n4np1/2p5/2QPP3/2N2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/pp2ppbp/n4np1/2pP4/2Q1P3/2N2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/pp3pbp/n3pnp1/2pP4/2Q1P3/2N2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/pp3pbp/n3pnp1/2pP4/2Q1P3/2N2N2/PP2BPPP/R1B2RK1", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/pp3pbp/n4np1/2pp4/2Q1P3/2N2N2/PP2BPPP/R1B2RK1", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/pp3pbp/n4np1/2pP4/2Q5/2N2N2/PP2BPPP/R1B2RK1", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r2q1rk1/pp3pbp/n4np1/2pP1b2/2Q5/2N2N2/PP2BPPP/R1B2RK1", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/ppp1ppbp/n4np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r1bq1rk1/ppp1ppbp/n4np1/8/2QPPB2/2N2N2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/pppnppbp/6p1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/pppnppbp/6p1/8/3PP1b1/1QN1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/1n4p1/8/3PP1b1/1QN1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/1n4p1/8/3PP1b1/1QN1BN2/PP3PPP/3RKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r2q1rk1/ppp1ppbp/1nn3p1/8/3PP1b1/1QN1BN2/PP3PPP/3RKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r2q1rk1/ppp1ppbp/1nn3p1/3P4/4P1b1/1QN1BN2/PP3PPP/3RKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r2q1rk1/ppp1ppbp/1n4p1/3Pn3/4P1b1/1QN1BN2/PP3PPP/3RKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/pppnppbp/6p1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/pppnppbp/6p1/8/3PP1b1/1QN1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/1n4p1/8/3PP1b1/1QN1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/1n4p1/8/3PP1b1/1QN1BN2/PP3PPP/3RKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp2pbp/1n2p1p1/8/3PP1b1/1QN1BN2/PP3PPP/3RKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/pppnppbp/6p1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/pppnppbp/6p1/8/2QPP1b1/2N1BN2/PP3PPP/3RKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r2q1rk1/pppnppbp/2n3p1/8/2QPP1b1/2N1BN2/PP3PPP/3RKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/pppnppbp/6p1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/pppnppbp/6p1/8/2QPP1b1/2N1BN2/PP3PPP/2KR1B1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r2q1rk1/ppp1ppbp/2n2np1/8/2QPP1b1/2N2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"r2q1rk1/ppp1ppbp/2n2np1/3P4/2Q1P1b1/2N2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/pp2ppbp/2p2np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/pp2ppbp/2p2np1/8/2QPP3/2N2N2/PP2BPPP/R1B1K2R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/pppnppbp/6p1/8/2QPP1b1/2N1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/pppnppbp/6p1/8/3PP1b1/1QN1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rn1q1rk1/pp1nppbp/6p1/2p5/3PP1b1/1QN1BN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/pp2ppbp/2p2np1/3P4/3P4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/pp2ppbp/2p3p1/3n4/3P4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/pp2ppbp/2p2np1/3P4/3P4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/pp2ppbp/5np1/3p4/3P4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/pppnppbp/6p1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2QP1B2/2N2N2/PP2PPPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbq1rk1/pp2ppbp/2p2np1/8/2QP1B2/2N2N2/PP2PPPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/1pp1ppbp/p4np1/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 353, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/2PP1B2/1QN2N2/PP2PPPP/R3KB1R", "treename":"Grunfeld Defense, Russian Variation"},
{"gid": 354, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/ppp1pp1p/6p1/3p2B1/2PPn3/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/ppp1pp1p/6p1/3p4/2PPn2B/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/ppp1pp1p/6p1/3p4/2PP3B/2n5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/ppp1pp1p/6p1/3p4/2PP3B/2P5/P3PPPP/R2QKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqk2r/ppp1ppbp/6p1/3p4/2PP3B/2P5/P3PPPP/R2QKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/ppp1pp1p/6p1/3p2B1/2PPn3/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 354, "fen":"rnbqkb1r/ppp1pp1p/6p1/3p4/2PPnB2/2N5/PP2PPPP/R2QKBNR", "treename":"Grunfeld Defense, Stockholm Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/5np1/2pp4/2PP4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/2PP4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/2N1PN2/PP2BPPP/R1BQ1RK1", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/2RQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2pP1B2/2N2N2/PP2PPPP/2RQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/2RQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/5np1/2pp4/2PP1B2/2N2N2/PP2PPPP/2RQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/5np1/2pp4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/5np1/2Pp4/2P2B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnb2rk1/pp2ppbp/5np1/q1Pp4/2P2B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnb2rk1/pp2ppbp/5np1/q1Pp4/2P2B2/2N1PN2/PP3PPP/2RQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnb2rk1/pp2ppbp/5np1/q1P5/2p2B2/2N1PN2/PP3PPP/2RQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnb2rk1/pp2ppbp/5np1/q1P5/2B2B2/2N1PN2/PP3PPP/2RQK2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP1B2/1QN1PN2/PP3PPP/R3KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP1B2/2N1PN2/PP3PPP/2RQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/pp2ppbp/2p2np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3p2B1/2PPn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2B1/3Pn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2n1/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2N1/3P4/2N5/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/pp2ppbp/2p3p1/3P2N1/3P4/2N5/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3p2B1/2PPn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2B1/3Pn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2n1/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2N1/3P4/2N5/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/4p1p1/3P2N1/3P4/2N5/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/4p1p1/3P2N1/3P4/2N5/PP1QPPPP/R3KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/6p1/3p2N1/3P4/2N5/PP1QPPPP/R3KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/6p1/3p2N1/3P4/2N1Q3/PP2PPPP/R3KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1k1r/ppp2pbp/6p1/3p2N1/3P4/2N1Q3/PP2PPPP/R3KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1k1r/ppp2pbp/6p1/3p2N1/3P1Q2/2N5/PP2PPPP/R3KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1k1r/ppp2p1p/5bp1/3p2N1/3P1Q2/2N5/PP2PPPP/R3KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1k1r/ppp2p1p/5bp1/3p2N1/3P1Q1P/2N5/PP2PPP1/R3KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1k1r/ppp2p2/5bpp/3p2N1/3P1Q1P/2N5/PP2PPP1/R3KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3p2B1/2PPn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3p4/2PPn2B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3p4/2PP3B/2n2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3p4/2PP3B/2P2N2/P3PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/8/2pP3B/2P2N2/P3PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3p2B1/2PPn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2B1/3Pn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2n1/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2N1/3P4/2N5/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/4p1p1/3P2N1/3P4/2N5/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/4p1p1/3P4/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/6p1/3p4/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/6p1/3p4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp2pbp/6p1/3p4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp2pbp/6p1/3p4/3P4/2NBPN2/PP3PPP/R2QK2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp3pbp/2p3p1/3p4/3P4/2NBPN2/PP3PPP/R2QK2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3p2B1/2PPn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3p4/2PPnB2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3p2B1/2PPn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2B1/3Pn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2n1/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2N1/3P4/2N5/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/4p1p1/3P2N1/3P4/2N5/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/4p1p1/3P4/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/6p1/3p4/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/6p1/3p4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp2pbp/6p1/3p4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp2pbp/6p1/3p4/3P4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp3pbp/2p3p1/3p4/3P4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3p2B1/2PPn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2B1/3Pn3/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2n1/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3P2N1/3P4/2N5/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/4p1p1/3P2N1/3P4/2N5/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/4p1p1/3P4/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/6p1/3p4/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp2pbp/6p1/3p4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp2pbp/6p1/3p4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp2pbp/6p1/3p4/1P1P4/2N1PN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp3pbp/2p3p1/3p4/1P1P4/2N1PN2/P4PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/6B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/1QN1PN2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2pP4/1QN1PN2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2BP4/1QN1PN2/PP3PPP/R1B1K2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"r1bq1rk1/ppp1ppbp/2n2np1/8/2BP4/1QN1PN2/PP3PPP/R1B1K2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/1QN1PN2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/1QN1PN2/PP3PPP/R1B1KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/1QN1PN2/PP1B1PPP/R3KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp3pbp/2p1pnp1/3p4/2PP4/1QN1PN2/PP1B1PPP/R3KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/pp3pbp/2p1pnp1/3p4/2PP4/1QNBPN2/PP1B1PPP/R3K2R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3P4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/5np1/3P4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/2N2N2/PP1BPPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/2N2N2/PP1BPPPP/R2QKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 355, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/2N2N2/PP1BPPPP/2RQKB1R", "treename":"Grunfeld Defense, Three Knights Variation"},
{"gid": 356, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Zaitsev Gambit"},
{"gid": 356, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Zaitsev Gambit"},
{"gid": 356, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Zaitsev Gambit"},
{"gid": 356, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Zaitsev Gambit"},
{"gid": 356, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Zaitsev Gambit"},
{"gid": 356, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Zaitsev Gambit"},
{"gid": 356, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP3P/2N5/PP2PPP1/R1BQKBNR", "treename":"Grunfeld Defense, Zaitsev Gambit"},
{"gid": 349, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Main Line"},
{"gid": 349, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Main Line"},
{"gid": 349, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Main Line"},
{"gid": 349, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Grunfeld Defense, Main Line"},
{"gid": 349, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Main Line"},
{"gid": 349, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Grunfeld Defense, Main Line"},
{"gid": 349, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Main Line"},
{"gid": 349, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Grunfeld Defense, Main Line"},
{"gid": 349, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN1P3/PP3PPP/R1B1KBNR", "treename":"Grunfeld Defense, Main Line"},
{"gid": 357, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Anti-Grunfeld"},
{"gid": 357, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Anti-Grunfeld"},
{"gid": 357, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Anti-Grunfeld"},
{"gid": 357, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Anti-Grunfeld"},
{"gid": 357, "fen":"rnbqkb1r/pppppp1p/5np1/3P4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Anti-Grunfeld"},
{"gid": 357, "fen":"rnbqkb1r/p1pppp1p/5np1/1p1P4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Anti-Grunfeld"},
{"gid": 358, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Anti-Nimzo-Indian"},
{"gid": 358, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Anti-Nimzo-Indian"},
{"gid": 358, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Anti-Nimzo-Indian"},
{"gid": 358, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Anti-Nimzo-Indian"},
{"gid": 358, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Anti-Nimzo-Indian"},
{"gid": 358, "fen":"rnbqk2r/ppppbppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Anti-Nimzo-Indian"},
{"gid": 359, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Dzindzi-Indian Defense"},
{"gid": 359, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Dzindzi-Indian Defense"},
{"gid": 359, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Dzindzi-Indian Defense"},
{"gid": 359, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Dzindzi-Indian Defense"},
{"gid": 359, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Dzindzi-Indian Defense"},
{"gid": 359, "fen":"rnbqkb1r/1ppp1ppp/p3pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Dzindzi-Indian Defense"},
{"gid": 359, "fen":"rnbqkb1r/1ppp1ppp/p3pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Indian Game, Dzindzi-Indian Defense"},
{"gid": 359, "fen":"rnbqkb1r/1p1p1ppp/p3pn2/2p5/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Indian Game, Dzindzi-Indian Defense"},
{"gid": 360, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, King's Indian Variation"},
{"gid": 360, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, King's Indian Variation"},
{"gid": 360, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, King's Indian Variation"},
{"gid": 360, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, King's Indian Variation"},
{"gid": 360, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Indian Game, King's Indian Variation"},
{"gid": 360, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Indian Game, King's Indian Variation"},
{"gid": 360, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Indian Game, King's Indian Variation"},
{"gid": 360, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Indian Game, King's Indian Variation"},
{"gid": 361, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"r1bqkb1r/pp1p1ppp/2n1pn2/2p5/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pp1p1ppp/4pn2/8/3p4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnb1kb1r/pp1p1ppp/4pn2/q1p5/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnb1kb1r/ppqp1ppp/4pn2/2p5/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/3P4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/6B1/3P4/2P2N2/PP2PPPP/RN1QKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/p1pp1ppp/4pn2/1p6/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rnbqkb1r/p1pp1ppp/4pn2/1p6/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rn1qkb1r/pbpp1ppp/4pn2/1p6/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Knights Variation"},
{"gid": 361, "fen":"rn1qkb1r/pbpp1ppp/4pn2/1p6/3P4/5NP1/PPP1PPBP/RNBQ1RK1", "treename":"Indian Game, Knights Variation"},
{"gid": 362, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P1B2/4PN2/PPP2PPP/RN1QKB1R", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnb1kb1r/pp1p1ppp/1q2pn2/2p5/3P1B2/4PN2/PPP2PPP/RN1QKB1R", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Indian Game, London System"},
{"gid": 362, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P1B2/2P2N2/PP2PPPP/RN1QKB1R", "treename":"Indian Game, London System"},
{"gid": 363, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 363, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 363, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 363, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 363, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 363, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 363, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 363, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 363, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 363, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 363, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/P7/1P2PPPP/RNBQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 363, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/P7/1P2PPPP/RNBQKBNR", "treename":"Indian Game, Normal Variation"},
{"gid": 364, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqk2r/ppppppbp/5np1/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqk2r/ppppppbp/5np1/8/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbq1rk1/ppppppbp/5np1/8/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbq1rk1/ppppppbp/5np1/8/3P4/5NP1/PPP1PPBP/RNBQ1RK1", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3P4/5NP1/PPP1PPBP/RNBQ1RK1", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqk2r/ppppppbp/5np1/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbqk2r/ppppppbp/5np1/8/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbq1rk1/ppppppbp/5np1/8/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbq1rk1/ppppppbp/5np1/8/3P4/1P3NP1/P1P1PPBP/RNBQK2R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3P4/1P3NP1/P1P1PPBP/RNBQK2R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 364, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3P4/1P3NP1/PBP1PPBP/RN1QK2R", "treename":"Indian Game, Przepiorka Variation"},
{"gid": 365, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/8/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/8/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP2B1/8/5N2/PPP1PPPP/RN1QKB1R", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkb1r/p2ppppp/8/1ppP2B1/4n3/5N2/PPP1PPPP/RN1QKB1R", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/8/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/8/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP2B1/8/5N2/PPP1PPPP/RN1QKB1R", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 365, "fen":"rnbqkb1r/p3pppp/3p1n2/1ppP2B1/8/5N2/PPP1PPPP/RN1QKB1R", "treename":"Indian Game, Pseudo-Benko"},
{"gid": 366, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/p2ppppp/1p3n2/2p5/3P4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppp1p/5np1/2p5/3P4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/8/3p4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/8/3N4/6P1/PPP1PP1P/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/8/3p4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/8/3p4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 366, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P4/2P2N2/PP2PPPP/RNBQKB1R", "treename":"Indian Game, Spielmann-Indian"},
{"gid": 367, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Tartakower Attack"},
{"gid": 367, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Tartakower Attack"},
{"gid": 367, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/6P1/PPP1PP1P/RNBQKBNR", "treename":"Indian Game, Tartakower Attack"},
{"gid": 367, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/3P4/6P1/PPP1PP1P/RNBQKBNR", "treename":"Indian Game, Tartakower Attack"},
{"gid": 367, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP4/8/6P1/PPP1PP1P/RNBQKBNR", "treename":"Indian Game, Tartakower Attack"},
{"gid": 367, "fen":"rnbqkb1r/p2ppppp/5n2/1ppP4/8/6P1/PPP1PP1P/RNBQKBNR", "treename":"Indian Game, Tartakower Attack"},
{"gid": 368, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Wade-Tartakower Defense"},
{"gid": 368, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Indian Game, Wade-Tartakower Defense"},
{"gid": 368, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Wade-Tartakower Defense"},
{"gid": 368, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Indian Game, Wade-Tartakower Defense"},
{"gid": 368, "fen":"rnbqkb1r/ppp1pppp/3p1n2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Indian Game, Wade-Tartakower Defense"},
{"gid": 368, "fen":"r1bqkb1r/pppnpppp/3p1n2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Indian Game, Wade-Tartakower Defense"},
{"gid": 369, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqk1nr/pppp1ppp/4p3/8/1bPP4/8/PP2PPPP/RNBQKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqk1nr/pppp1ppp/4p3/8/1bPP4/8/PP1BPPPP/RN1QKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqk1nr/pppp1ppp/4p3/8/2PP4/8/PP1bPPPP/RN1QKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqk1nr/pppp1ppp/4p3/8/2PP4/8/PP1QPPPP/RN2KBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqk1nr/p1pp1ppp/1p2p3/8/2PP4/8/PP1QPPPP/RN2KBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqk1nr/p1pp1ppp/1p2p3/8/2PP4/2N5/PP1QPPPP/R3KBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rn1qk1nr/pbpp1ppp/1p2p3/8/2PP4/2N5/PP1QPPPP/R3KBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rn1qk1nr/pbpp1ppp/1p2p3/8/2PPP3/2N5/PP1Q1PPP/R3KBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rn1qk2r/pbpp1ppp/1p2p2n/8/2PPP3/2N5/PP1Q1PPP/R3KBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqkbnr/pppp1ppp/4p3/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqkbnr/pppp1ppp/4p3/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqk1nr/pppp1ppp/4p3/8/1bPP4/8/PP2PPPP/RNBQKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqk1nr/pppp1ppp/4p3/8/1bPP4/8/PP1BPPPP/RN1QKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 369, "fen":"rnbqk1nr/1ppp1ppp/4p3/p7/1bPP4/8/PP1BPPPP/RN1QKBNR", "treename":"Kangaroo Defense, Main Line"},
{"gid": 370, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pp2B1/2P1P3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP2B1/2P5/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP2B1/2P5/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP2B1/2P5/2N2N2/PP1QBPPP/R3K2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rn1qr1k1/pp3pbp/3p1np1/2pP2B1/2P3b1/2N2N2/PP1QBPPP/R3K2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pb1/3ppnpp/2pP2B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pb1/3ppnpp/2pP4/2P1PB2/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pb1/3ppnpp/2pP2B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pb1/3ppnpp/2pP4/2P1P2B/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pp4/2P1P2B/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pb1/3p1npp/2pP4/4P2B/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pp2B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP2B1/2P5/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pb1/3ppnpp/2pP2B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pb1/3ppnpp/2pP4/2P1P3/2N2N2/PP1BBPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pb1/3ppnpp/2pP2B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 370, "fen":"rnbq1rk1/pp3pb1/3ppnpp/2pP4/2P1P3/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian, Averbakh"},
{"gid": 371, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/1ppn1pbp/p2p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/8/2PpP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/8/2PNP3/2N3P1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/ppp2pbp/3p1np1/2n5/2PNP3/2N3P1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bqr1k1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/1PN2NP1/P4PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 371, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/1RBQ1RK1", "treename":"King's Indian, Fianchetto"},
{"gid": 372, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/8/2PpP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/8/2PNP3/2N5/PP3PPP/R1BQRBK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp2p1/8/2PNP1n1/2N5/PP3PPP/R1BQRBK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pb1/3p1npp/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/1ppn1pbp/3p1np1/p3p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/8/2PpP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/8/2PNP3/2N5/PP3PPP/R1BQRBK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/8/2PNP3/2N5/PP3PPP/R1BQRBK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/8/2PpP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/8/2PNP3/2N5/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/ppp2pbp/3p1np1/2n5/2PNP3/2N5/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bqr1k1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bqr1k1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 372, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/1RBQR1K1", "treename":"King's Indian, Orthodox"},
{"gid": 373, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP2P/2N1BP2/PP1QN1P1/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/2p1ppbp/p1np1np1/1p6/2PPP2P/2N1BP2/PP1QN1P1/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1Q2PP/R1N1KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp2pbp/p1np1np1/4p3/2PPP3/2N1BP2/PP1Q2PP/R1N1KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp2pbp/p1np1np1/4p3/2PPP3/1NN1BP2/PP1Q2PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP2P/2N1BP2/PP1QN1P1/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppb1/p1np1np1/7p/2PPP2P/2N1BP2/PP1QN1P1/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppb1/p1np1np1/7p/2PPP2P/2N1BP2/PP1QN1P1/2KR1B1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/2p1ppb1/p1np1np1/1p5p/2PPP2P/2N1BP2/PP1QN1P1/2KR1B1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/2p1ppb1/p1np1npB/1p5p/2PPP2P/2N2P2/PP1QN1P1/2KR1B1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/1R2KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/P1N1BP2/1P1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1Q2PP/R1N1KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp2pbp/p1np1np1/4p3/2PPP3/2N1BP2/PP1Q2PP/R1N1KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp2pbp/p1np1np1/3Pp3/2P1P3/2N1BP2/PP1Q2PP/R1N1KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp2pbp/p2p1np1/3Pp3/2PnP3/2N1BP2/PP1Q2PP/R1N1KB1R", "treename":"King's Indian, Samisch"},
{"gid": 373, "fen":"1rbq1rk1/1pp2pbp/p2p1np1/3Pp3/2PnP3/1NN1BP2/PP1Q2PP/R3KB1R", "treename":"King's Indian, Samisch"},
{"gid": 374, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqk2r/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP3PPP/R2QKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqk2r/ppp1ppb1/3p1npp/6B1/2PPP3/2N5/PP3PPP/R2QKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbqk2r/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP3PPP/R2QKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP3PPP/R2QKBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 374, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP1Q1PPP/R3KBNR", "treename":"King's Indian Defense, Accelerated Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/1p2ppbp/p2p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/1p2ppbp/p2p1np1/2pP2B1/P1P1P3/2N5/1P2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnb2rk1/1p2ppbp/p2p1np1/q1pP2B1/P1P1P3/2N5/1P2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnb2rk1/pp2ppbp/3p1np1/q1pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppb1/3p1npp/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppb1/3p1npp/2pP4/2P1PB2/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp3pb1/3ppnpp/2pP4/2P1PB2/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppb1/3p1npp/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppb1/3p1npp/2pP4/2P1P3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp3pb1/3ppnpp/2pP4/2P1P3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp3pb1/3ppnpp/2pP4/2P1P3/2N1B3/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/1p2ppbp/p2p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/1p2ppbp/p2p1np1/2pP2B1/2P1P3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/p3ppbp/3p1np1/1ppP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/p3ppbp/3p1np1/1PpP2B1/4P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/4ppbp/p2p1np1/1PpP2B1/4P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/4ppbp/p2p1np1/1PpP2B1/P3P3/2N5/1P2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2P3B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnb2rk1/pp2ppbp/3p1np1/q1P3B1/2P1P3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnb2rk1/pp2ppbp/3p1np1/q1P5/2P1P3/2N5/PP1BBPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnb2rk1/pp2ppbp/3p1np1/2q5/2P1P3/2N5/PP1BBPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnb2rk1/pp2ppbp/3p1np1/2q5/2P1P3/2N2N2/PP1BBPPP/R2QK2R", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rn3rk1/pp2ppbp/3p1np1/2q5/2P1P1b1/2N2N2/PP1BBPPP/R2QK2R", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppb1/3p1npp/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppb1/3p1npp/8/2PPP3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp2pb1/3p1npp/4p3/2PPP3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp2pb1/3p1npp/3Pp3/2P1P3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp3pb1/2pp1npp/3Pp3/2P1P3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppb1/3p1npp/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppb1/3p1npp/8/2PPP3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp2pb1/3p1npp/4p3/2PPP3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp2pb1/3p1npp/3Pp3/2P1P3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/pppn1pb1/3p1npp/3Pp3/2P1P3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppb1/3p1npp/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppb1/3p1npp/8/2PPP3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppb1/3p1npp/2p5/2PPP3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/pppnppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/pppnppbp/3p1np1/6B1/2PPP3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p1B1/2PPP3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/pppn1pbp/3p1np1/3Pp1B1/2P1P3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/ppp2pbp/3p1np1/2nPp1B1/2P1P3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/4p1B1/2PPP3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/3Pp1B1/2P1P3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/pp3pbp/n1pp1np1/3Pp1B1/2P1P3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/4p1B1/2PPP3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/3Pp1B1/2P1P3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1b1qrk1/ppp2pbp/n2p1np1/3Pp1B1/2P1P3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP2P/2N5/PP2BPP1/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/6B1/2PPP3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 375, "fen":"r1bq1rk1/pp1nppbp/2pp1np1/6B1/2PPP3/2N5/PP1QBPPP/R3K1NR", "treename":"King's Indian Defense, Averbakh Variation"},
{"gid": 376, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4P3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbq1rk1/ppp2pbp/5np1/4p3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbQ1rk1/ppp2pbp/5np1/4p3/2P1P3/2N2N2/PP2BPPP/R1B1K2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbr2k1/ppp2pbp/5np1/4p3/2P1P3/2N2N2/PP2BPPP/R1B1K2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbr2k1/ppp2pbp/5np1/4p1B1/2P1P3/2N2N2/PP2BPPP/R3K2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbr2k1/pp3pbp/2p2np1/4p1B1/2P1P3/2N2N2/PP2BPPP/R3K2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4P3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbq1rk1/ppp2pbp/5np1/4p3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbQ1rk1/ppp2pbp/5np1/4p3/2P1P3/2N2N2/PP2BPPP/R1B1K2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbr2k1/ppp2pbp/5np1/4p3/2P1P3/2N2N2/PP2BPPP/R1B1K2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnbr2k1/ppp2pbp/5np1/4p1B1/2P1P3/2N2N2/PP2BPPP/R3K2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnb1r1k1/ppp2pbp/5np1/4p1B1/2P1P3/2N2N2/PP2BPPP/R3K2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 376, "fen":"rnb1r1k1/ppp2pbp/5np1/3Np1B1/2P1P3/5N2/PP2BPPP/R3K2R", "treename":"King's Indian Defense, Exchange Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NPP/PP2PPB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PP4/2N2NPP/PP2PPB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NPP/PP2PPB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pppn1pbp/3p1np1/4p3/2PP4/2N2NPP/PP2PPB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PP4/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/3Pp3/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4P3/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/8/2PpP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/8/2PNP3/2N3PP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/1p1n1pbp/2pp1np1/p7/2PNP3/2N3PP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1p1n1pbp/p1pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/8/2PpP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/8/2PNP3/2N3PP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/8/2PNP3/2N3PP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1p1n1pbp/2pp1np1/p3p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/1qpp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/1qpp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b1r1k1/pp1n1pbp/1qpp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/1qpp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/1qpp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/1qpp1np1/8/2PpP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/1qpp1np1/8/2PNP3/2N3PP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b1r1k1/pp1n1pbp/1qpp1np1/8/2PNP3/2N3PP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/1qpp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/1qpp1np1/3Pp3/2P1P3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/1p1n1pbp/2pp1np1/p3p3/2PPP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/1p1n1pbp/2pp1np1/p3p3/2PPP3/2N1BNPP/PP3PB1/R2QR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/2pp1np1/q3p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/2pp1np1/q3p3/2PPP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/2pp1np1/q7/2PpP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/2pp1np1/q7/2PNP3/2N3PP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/1PN2NPP/P4PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/8/2PpP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/8/2PNP3/2N3PP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp3pbp/2pp1np1/2n5/2PNP3/2N3PP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp3pbp/2pp1np1/2n5/2PNP3/2N3PP/PP3PB1/1RBQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/8/2PpP3/2N2NPP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/8/2PNP3/2N3PP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp3pbp/2pp1np1/2n5/2PNP3/2N3PP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp3pbp/2pp1np1/2n5/2PNP3/1PN3PP/P4PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/1qpp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1b2rk1/pp1n1pbp/1qpp1np1/2P1p3/3PP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N1BNPP/PP3PB1/R2Q1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/8/2PpP3/2N1BNPP/PP3PB1/R2Q1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pp1n1pbp/2pp1np1/8/2PNP3/2N1B1PP/PP3PB1/R2Q1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1nppbp/2pp1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/1p2ppbp/p1pp1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/3P4/2P5/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnb2rk1/pp2ppbp/2pp1np1/q7/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnb2rk1/pp2ppbp/2pp1np1/q7/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rn3rk1/pp2ppbp/2pp1np1/q7/2PPP1b1/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnb2rk1/pp2ppbp/2pp1np1/q7/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnb2rk1/pp2ppbp/2pp1np1/q7/2PP4/2N2NPP/PP2PPB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rn1q1rk1/pp2ppbp/2pp1np1/5b2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rn1q1rk1/pp2ppbp/2pp1np1/5b2/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r2q1rk1/ppp1ppbp/2np1np1/5b2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r2q1rk1/ppp1ppbp/2np1np1/3P1b2/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/8/2PpP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pppn1pbp/3p1np1/8/2PNP3/2N3P1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pppn1pbp/3p1np1/8/2PNP3/2N3P1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/pppn1pbp/3p1np1/8/2PNP3/2N3PP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/ppp2pbp/3p1np1/2n5/2PNP3/2N3PP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/ppp2pbp/3p1np1/2n5/2PNP3/2N3PP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bqr1k1/1pp2pbp/3p1np1/p1n5/2PNP3/2N3PP/PP3PB1/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/3P4/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p2p1np1/n2P4/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PP4/1PN2NP1/PB2PPBP/R2Q1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQR1K1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NPP/PP2PPB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NPP/PP2PPB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N1BNPP/PP2PPB1/R2Q1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/3N4/2PP4/1P3NP1/P3PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NPP/PP2PPB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NPP/PP2PPB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r2q1rk1/ppp1ppbp/2np1np1/8/2PP2b1/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r2q1rk1/ppp1ppbp/2np1np1/3P4/2P3b1/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2P5/2P5/5NP1/PP2PPBP/RNBQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2pP4/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/3p1np1/n1pP4/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/3p1np1/n1pP4/2P5/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1p2ppbp/p2p1np1/n1pP4/2P5/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1p2ppbp/p2p1np1/n1pP4/2P5/2N3P1/PPQNPPBP/R1B2RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1p2ppbp/p2p1np1/n1pP4/2P5/2N3P1/PPQNPPBP/R1B2RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1p2ppbp/p2p1np1/n1pP4/2P5/1PN3P1/P1QNPPBP/R1B2RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/4ppbp/p2p1np1/nppP4/2P5/1PN3P1/P1QNPPBP/R1B2RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/4ppbp/p2p1np1/nppP4/2P5/1PN3P1/PBQNPPBP/R4RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/4ppbp/p2p1np1/n1pP4/2p5/1PN3P1/PBQNPPBP/R4RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2pP4/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/3p1np1/n1pP4/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/3p1np1/n1pP4/2P5/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp3pbp/3p1np1/n1pPp3/2P5/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2pP4/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/3p1np1/n1pP4/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/3p1np1/n1pP4/2P5/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1p2ppbp/p2p1np1/n1pP4/2P5/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1p2ppbp/p2p1np1/n1pP4/2P5/2N3P1/PPQNPPBP/R1B2RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1p2ppbp/p2p1np1/n1pP4/2P5/2N3P1/PPQNPPBP/R1B2RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1p2ppbp/p2p1np1/n1pP4/2P5/1PN3P1/P1QNPPBP/R1B2RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/4ppbp/p2p1np1/nppP4/2P5/1PN3P1/P1QNPPBP/R1B2RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/4ppbp/p2p1np1/nppP4/2P5/1PN3P1/PBQNPPBP/R4RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/5pbp/p2p1np1/nppPp3/2P5/1PN3P1/PBQNPPBP/R4RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2pP4/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/3p1np1/n1pP4/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/3p1np1/n1pP4/2P5/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1p2ppbp/p2p1np1/n1pP4/2P5/2N3P1/PP1NPPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/1p2ppbp/p2p1np1/n1pP4/2P5/2N3P1/PP1NPPBP/1RBQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"1rbq1rk1/1p2ppbp/p2p1np1/n1pP4/2P5/2N3P1/PP1NPPBP/1RBQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2P5/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2n2np1/2p5/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2n2np1/2p5/2P2B2/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2P5/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2n2np1/2p5/2P5/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/2n2np1/2p5/2P5/2N1BNP1/PP2PPBP/R2Q1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P5/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp2ppbp/n2p1np1/2pP4/2P5/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P5/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P5/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2P5/2P5/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"r1bq1rk1/pp1nppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 377, "fen":"rnbq1rk1/pp2ppbp/3p1np1/8/2Pp4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"King's Indian Defense, Fianchetto Variation"},
{"gid": 378, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/1p2ppbp/p2p1np1/2pP4/2P1PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P1PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/pp3pbp/3pPnp1/2p5/2P1PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/p3ppbp/3p1np1/1ppP4/2P1PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/p3ppbp/3p1np1/1PpP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/4ppbp/p2p1np1/1PpP4/4PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/4ppbp/p2p1np1/1PpP4/P3PP2/2N2N2/1P4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rn1q1rk1/ppp1ppbp/3p1np1/8/2PPPPb1/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPPP2/2NB1N2/PP4PP/R1BQK2R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/4p3/2PPPP2/2NB1N2/PP4PP/R1BQK2R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2P5/2P1PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnb2rk1/pp2ppbp/3p1np1/q1P5/2P1PP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnb2rk1/pp2ppbp/3p1np1/q1P5/2P1PP2/2NB1N2/PP4PP/R1BQK2R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnb2rk1/pp2ppbp/3p1np1/2q5/2P1PP2/2NB1N2/PP4PP/R1BQK2R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnb2rk1/pp2ppbp/3p1np1/2q5/2P1PP2/2NB1N2/PP2Q1PP/R1B1K2R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"r1b2rk1/pp2ppbp/2np1np1/2q5/2P1PP2/2NB1N2/PP2Q1PP/R1B1K2R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/4P3/2PP1P2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/pp2ppbp/3p1np1/2p5/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/pp2ppbp/3p1np1/2P5/2P1PP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/pp2ppbp/3p1np1/2p5/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/2P1PP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPPP2/2N2N2/PP4PP/R1BQKB1R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 378, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPPP2/2N2N2/PP2B1PP/R1BQK2R", "treename":"King's Indian Defense, Four Pawns Attack"},
{"gid": 379, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"r1bq1rk1/pp2ppbp/n2p1np1/2p5/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"r1bq1rk1/pp2ppbp/n1pp1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 379, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Kazakh Variation"},
{"gid": 380, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2NPPP/R1BQKB1R", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2NPPP/R1BQKB1R", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N3N1/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N3N1/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 380, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N3N1/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Kramer Variation"},
{"gid": 381, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N1BN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4P3/2P1P3/2N1BN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbq1rk1/ppp2pbp/5np1/4p3/2P1P3/2N1BN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 381, "fen":"rnbQ1rk1/ppp2pbp/5np1/4p3/2P1P3/2N1BN2/PP3PPP/R3KB1R", "treename":"King's Indian Defense, Larsen Variation"},
{"gid": 383, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/p3ppbp/3p1np1/1ppP2B1/2P1P3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/pp2ppbp/3p1np1/2p5/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppb1/3p1npp/6B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/6B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP3/2NB3P/PP3PP1/R2QK1NR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/4p1B1/2PPP3/2NB3P/PP3PP1/R2QK1NR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"r1bqk2r/pppnppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"r1bq1rk1/pppnppbp/3p1np1/6B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1B2P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1B2P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 383, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N4P/PP3PP1/R2QKBNR", "treename":"King's Indian Defense, Makogonov Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/5NP1/PPQ1PPBP/RNBR2K1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bqr1k1/pppn1pbp/3p1np1/4p3/2PP4/5NP1/PPQ1PPBP/RNBR2K1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P1P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pp3pbp/n2ppnp1/2pP4/2P1P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pp3pbp/n2ppnp1/2pP4/2P1P3/2NB1N1P/PP3PP1/R1BQ1RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/ppn2pbp/3ppnp1/2pP4/2P1P3/2NB1N1P/PP3PP1/R1BQ1RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/pp2ppbp/3p1np1/2p5/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/pp2ppbp/3p1np1/2pP4/2P1P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P1P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pp4/2P1P3/2NB1N1P/PP3PP1/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pP4/2P5/2NB1N1P/PP3PP1/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqr1k1/pp3pbp/3p1np1/2pP4/2P5/2NB1N1P/PP3PP1/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rn1qk2r/ppp1ppbp/3p1np1/8/2PPP1b1/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bqk2r/pppnppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bqk2r/pppnppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bqk2r/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/1pp2pbp/3p1np1/p2Pp3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4P3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/5np1/4p3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbQ1rk1/ppp2pbp/5np1/4p3/2P1P3/2N2N1P/PP3PP1/R1B1KB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pppnppbp/3p1np1/6B1/2PPP3/2N2N1P/PP3PP1/R2QKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/3Pp3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/3Pp3/2P1P3/2N1BN1P/PP3PP1/R2QKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/3Pp3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/3Pp1B1/2P1P3/2N2N1P/PP3PP1/R2QKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N1BN1P/PP3PP1/R2QKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pppn1pbp/3p1np1/3Pp3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pppn1pbp/3p1np1/3Pp1B1/2P1P3/2N2N1P/PP3PP1/R2QKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP3/2N2N1P/PP3PP1/R2QKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pppn1pbp/3p1np1/3Pp3/2P1P3/2N2N1P/PP3PP1/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/pppn1pbp/3p1np1/3Pp3/2P1P3/2N1BN1P/PP3PP1/R2QKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bqk2r/pppnppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP1P1/2N5/PP2BP1P/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2NB4/PP3PPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2PPP3/2NB4/PP3PPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2NB4/PP3PPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2NB4/PP3PPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2NB4/PP2NPPP/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2NB4/PP2NPPP/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2NB4/PP2NPPP/R1BQ1RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2NB4/PP2NPPP/R1BQ1RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2NB4/PP3PPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2NB4/PP3PPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2NB4/PP2NPPP/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2NB4/PP2NPPP/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2NB4/PP2NPPP/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2NB4/PP3PPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2NB4/PP3PPP/R1BQK1NR", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2NB4/PP2NPPP/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2NB4/PP2NPPP/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1P3/2NB4/PP2NPPP/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P1P3/2NB4/PP2NPPP/R1BQK2R", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 384, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P1P3/2NB4/PP2NPPP/R1BQ1RK1", "treename":"King's Indian Defense, Normal Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np2p1/4p3/2PPP1n1/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np2p1/4p1B1/2PPP1n1/2N2N2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp3bp/2np1pp1/4p1B1/2PPP1n1/2N2N2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp3bp/2np1pp1/4p3/2PPP1n1/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp1B1/2P1P3/2N2N2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4P3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/3Pp2n/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/3Pp2n/1PP1P3/2N2N2/P3BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p2p1/p2Pp2n/1PP1P3/2N2N2/P3BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/3Pp2n/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/3Pp2n/1PP1P3/2N2N2/P3BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1n1bp/3p2p1/3Ppp1n/1PP1P3/2N2N2/P3BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1n1bp/3p2p1/3PppNn/1PP1P3/2N5/P3BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1n1bp/3p1np1/3PppN1/1PP1P3/2N5/P3BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1n1bp/3p1np1/3PppN1/1PP1P3/2N2B2/P4PPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp2n1bp/2pp1np1/3PppN1/1PP1P3/2N2B2/P4PPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/3Pp2n/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/3Pp2n/1PP1P3/2N2N2/P3BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/3Pp3/1PP1Pn2/2N2N2/P3BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/3Pp3/1PP1Pn2/2N2N2/P4PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p2p1/p2Pp3/1PP1Pn2/2N2N2/P4PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p2p1/P2Pp3/2P1Pn2/2N2N2/P4PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/p2Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/p2Pp3/1PP1P3/B1N2N2/P3BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/3Pp3/1pP1P3/B1N2N2/P3BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/3Pp3/1BP1P3/2N2N2/P3BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1ppnnpbp/3p2p1/3Pp3/1BP1P3/2N2N2/P3BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1r1k/ppp1npbp/3p1np1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/3Pp2n/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/3Pp2n/1PP1P3/2N2NP1/P3BP1P/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/3Pp2n/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p2p1/2PPp2n/1P2P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/p2Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/P2Pp3/2P1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1npbp/3p2p1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1npbp/3p2p1/2PPp3/1P2P3/2N2N2/P3BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N2P2/PP2B1PP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2N2P2/PP2B1PP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P1P1/2N2P2/PP2B2P/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1r1k/pppnn1bp/3p2p1/3Ppp2/2P1P1P1/2N2P2/PP2B2P/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N2P2/PP2B1PP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2N2P2/PP2B1PP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P1P1/2N2P2/PP2B2P/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1n1bp/3p1np1/3Ppp2/2P1P1P1/2N2P2/PP2B2P/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1n1bp/3p1np1/3Ppp2/2P1P1P1/2NN1P2/PP2B2P/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N1B3/PP2BPPP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2N1B3/PP2BPPP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N2P2/PP2B1PP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2N2P2/PP2B1PP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2N1BP2/PP2B1PP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Pp3/2P1Pp2/2N1BP2/PP2B1PP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Pp3/2P1Pp2/2N2P2/PP2BBPP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p4/3Pp1p1/2P1Pp2/2N2P2/PP2BBPP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p4/3Pp1p1/P1P1Pp2/2N2P2/1P2BBPP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn2bp/3p2n1/3Pp1p1/P1P1Pp2/2N2P2/1P2BBPP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N2P2/PP2B1PP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2N2P2/PP2B1PP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2NN1P2/PP2B1PP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Pp3/2P1Pp2/2NN1P2/PP2B1PP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N2P2/PP2B1PP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2N2P2/PP2B1PP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2NN1P2/PP2B1PP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1n1bp/3p1np1/3Ppp2/2P1P3/2NN1P2/PP2B1PP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1n1bp/3p1np1/3Ppp2/2P1P3/2NN1P2/PP1BB1PP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1n1bp/3p1np1/3Pp3/2P1Pp2/2NN1P2/PP1BB1PP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1n1bp/3p1np1/2PPp3/4Pp2/2NN1P2/PP1BB1PP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp2npbp/3p1np1/2pPp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1npbp/3p2p1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1npbp/3p2p1/3Pp3/2P1P3/2N1B3/PP2BPPP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1n1bp/3p2p1/3Ppp2/2P1P3/2N1B3/PP2BPPP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1n1bp/3p2p1/3Ppp2/2P1P3/2N1BP2/PP2B1PP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1n1bp/3p2p1/3Pp3/2P1Pp2/2N1BP2/PP2B1PP/R2QNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2NN4/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2NN4/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2NN4/PP1BBPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1r1k/pppnn1bp/3p2p1/3Ppp2/2P1P3/2NN4/PP1BBPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1npbp/3p2p1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1npbp/3p2p1/3Pp3/2P1P3/2NN4/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1n1bp/3p2p1/3Ppp2/2P1P3/2NN4/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2NN4/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2NN4/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2NN4/PP1BBPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1n1bp/3p1np1/3Ppp2/2P1P3/2NN4/PP1BBPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp3pbp/2pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp3pbp/2pp1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp3pbp/2pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp3pbp/2pp1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/n2p2p1/4p3/2PPP1n1/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/n2p2p1/4p1B1/2PPP1n1/2N2N2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1b1qrk1/ppp2pbp/n2p2p1/4p1B1/2PPP1n1/2N2N2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1b1qrk1/ppp2pbp/n2p2p1/4P1B1/2P1P1n1/2N2N2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1b1qrk1/ppp2pbp/n5p1/4p1B1/2P1P1n1/2N2N2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1b1qrk1/ppp2pbp/n5p1/4p1B1/2P1P1n1/2N2N1P/PP2BPP1/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1b1qrk1/ppp2pb1/n5pp/4p1B1/2P1P1n1/2N2N1P/PP2BPP1/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/n2p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp3pbp/n1pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp3pbp/n1pp1np1/4p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p2p1/4p3/2PPP1n1/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p2p1/4p1B1/2PPP1n1/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp3bp/3p1pp1/4p1B1/2PPP1n1/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp3bp/3p1pp1/4p3/2PPP1nB/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp3bp/2np1pp1/4p3/2PPP1nB/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnb2rk1/ppp1qpbp/3p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp3pbp/2pp1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/8/2PpP3/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p2p1/4p3/2PPP1n1/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p2p1/4p1B1/2PPP1n1/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp3bp/3p1pp1/4p1B1/2PPP1n1/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp3bp/3p1pp1/4p3/2PPP1n1/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP1BBPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/p2Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/p2Pp3/2P1P3/P1N5/1P1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r2q1rk1/1ppbnpbp/3p1np1/p2Pp3/2P1P3/P1N5/1P1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp2npbp/3p1np1/2pPp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/p2Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/p2Pp3/2P1P3/P1N5/1P1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1ppnnpbp/3p2p1/p2Pp3/2P1P3/P1N5/1P1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1ppnnpbp/3p2p1/p2Pp3/2P1P3/P1N5/1P1NBPPP/1RBQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1ppnn1bp/3p2p1/p2Ppp2/2P1P3/P1N5/1P1NBPPP/1RBQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1ppnn1bp/3p2p1/p2Ppp2/1PP1P3/P1N5/3NBPPP/1RBQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/p2Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp1npbp/3p1np1/p2Pp3/2P1P3/2N5/PP1NBPPP/1RBQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnnpbp/3p2p1/3Pp3/1PP1P3/2N5/P2NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/2np1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1npbp/3p2p1/3Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqnrk1/ppp1npbp/3p2p1/3Pp3/1PP1P3/2N5/P2NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/3p1np1/2nPp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp2pbp/3p1np1/2nPp3/2P1P3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1pp2pbp/3p1np1/p1nPp3/2P1P3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1p1n1pbp/2pp1np1/p3p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1p1n1pbp/2pp1np1/p3P3/2P1P3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1p1n1pbp/2p2np1/p3p3/2P1P3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1p1n1pbp/2pp1np1/p3p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1p1n1pbp/2pp1np1/p3p3/2PPP3/2N2N2/PP3PPP/1RBQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqr1k1/1p1n1pbp/2pp1np1/p3p3/2PPP3/2N2N2/PP3PPP/1RBQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqr1k1/1p1n1pbp/2pp1np1/p2Pp3/2P1P3/2N2N2/PP3PPP/1RBQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqr1k1/1p3pbp/2pp1np1/p1nPp3/2P1P3/2N2N2/PP3PPP/1RBQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqr1k1/1p3pbp/2pp1np1/p1nPp3/2P1P3/1PN2N2/P4PPP/1RBQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1p1n1pbp/2pp1np1/p3p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/1p1n1pbp/2pp1np1/p3p3/2PPP3/2N2N1P/PP3PP1/R1BQRBK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/3Pp3/2P1P3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/2pPp3/2P1P3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqr1k1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p2p1/4p3/2PPP1n1/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqr1k1/pppn1pbp/3p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/1pp1ppbp/3p1np1/p7/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/1pp1ppbp/p2p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pp1nppbp/2pp1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnppbp/3p1np1/4P3/2PP4/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqr1k1/pp3pbp/3ppnp1/2pP4/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqr1k1/pp3pbp/3ppnp1/2pP4/2P1P3/2N5/PP1NBPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rn1q1rk1/ppp1ppbp/3p1np1/8/2PPP1b1/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rn1q1rk1/ppp1ppbp/3p1np1/8/2PPP1b1/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rn1q1rk1/pppnppbp/3p2p1/8/2PPP1b1/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rn1q1rk1/pppnppbp/3p2p1/8/2PPP1b1/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/pppnppbp/3p1np1/6B1/2PPP3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/8/2PpP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/8/2PNP3/2N5/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqr1k1/ppp2pbp/3p1np1/8/2PNP3/2N5/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqr1k1/ppp2pbp/3p1np1/8/2PNP3/2N2P2/PP2B1PP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqr1k1/pp3pbp/2pp1np1/8/2PNP3/2N2P2/PP2B1PP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqr1k1/pp3pbp/2pp1np1/8/2PNP3/2N2P2/PP2B1PP/R1BQ1R1K", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rn1q1rk1/ppp1ppbp/3p1np1/8/2PPP1b1/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rn1q1rk1/ppp1ppbp/3p1np1/8/2PPP1b1/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rn1q1rk1/pppnppbp/3p2p1/8/2PPP1b1/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rn1q1rk1/pppnppbp/3p2p1/8/2PPP1b1/2N1BN2/PP2BPPP/2RQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/8/2PpP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp2pbp/3p1np1/8/2PNP3/2N5/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqr1k1/ppp2pbp/3p1np1/8/2PNP3/2N5/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqr1k1/ppp2pbp/3p1np1/8/2PNP3/2N2P2/PP2B1PP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqr1k1/ppp2pbp/2np1np1/8/2PNP3/2N2P2/PP2B1PP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqr1k1/ppp2pbp/2np1np1/8/2PNP3/2N1BP2/PP2B1PP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqr1k1/ppp2pbp/2np2p1/7n/2PNP3/2N1BP2/PP2B1PP/R2Q1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bqr1k1/ppp2pbp/2np2p1/7n/2PNP3/2N1BP2/PP1QB1PP/R4RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 385, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/3P4/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Orthodox Variation"},
{"gid": 386, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1bq1rk1/pppn1pbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1bq1rk1/pppn1pbp/3p1np1/3Pp3/2P1P3/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1bq1rk1/pppn1pbp/3p2p1/3Pp3/2P1P1n1/2N1BN2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/1pp2pbp/3p1np1/p2Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/1pp2pbp/3p1np1/p2Pp1B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/1pp2pb1/3p1npp/p2Pp1B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/1pp2pb1/3p1npp/p2Pp3/2P1P2B/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1bq1rk1/1pp2pb1/n2p1npp/p2Pp3/2P1P2B/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1bq1rk1/1pp2pb1/n2p1npp/p2Pp3/2P1P2B/2N5/PP1NBPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1b1qrk1/1pp2pb1/n2p1npp/p2Pp3/2P1P2B/2N5/PP1NBPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1b1qrk1/1pp2pb1/n2p1npp/p2Pp3/2P1P2B/2N5/PP1NBPPP/R2Q1RK1", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1b1qrk1/1pp2pbn/n2p2pp/p2Pp3/2P1P2B/2N5/PP1NBPPP/R2Q1RK1", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/1pp2pbp/3p1np1/p2Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/1pp2pbp/3p1np1/p2Pp1B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/1pp2pb1/3p1npp/p2Pp1B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/1pp2pb1/3p1npp/p2Pp3/2P1P2B/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1bq1rk1/1pp2pb1/n2p1npp/p2Pp3/2P1P2B/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1bq1rk1/1pp2pb1/n2p1npp/p2Pp3/2P1P2B/2N5/PP1NBPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1b1qrk1/1pp2pb1/n2p1npp/p2Pp3/2P1P2B/2N5/PP1NBPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r1b1qrk1/1pp2pb1/n2p1npp/p2Pp3/2P1P2B/2N5/PP1NBPPP/R2Q1RK1", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"r3qrk1/1ppb1pb1/n2p1npp/p2Pp3/2P1P2B/2N5/PP1NBPPP/R2Q1RK1", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/1pp2pbp/3p1np1/p2Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/1pp2pbp/3p1np1/p2Pp3/2P1P3/2N2N1P/PP2BPP1/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pPp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 386, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pPp1B1/2P1P3/2N2N2/PP2BPPP/R2QK2R", "treename":"King's Indian Defense, Petrosian Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP2N1PP/R1BQKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2P2/PP2N1PP/R1BQKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP2N1PP/R1BQKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N2P2/PP2N1PP/R1BQKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1P3/2N2P2/PP2N1PP/R1BQKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/8/2PPP3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/1p2ppbp/p1pp1np1/8/2PPP3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/3Pp3/2P1P3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/3p1np1/3pp3/2P1P3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/3p1np1/3Pp3/4P3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/3Pp3/4P3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp1n1pbp/3p1np1/3Pp3/4P3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/3Pp3/4P3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1p1n1pbp/p2p1np1/3Pp3/4P1P1/2N1BP2/PP1QN2P/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1p1n1pb1/p2p1np1/3Pp2p/4P1P1/2N1BP2/PP1QN2P/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1p1n1pb1/p2p1np1/3Pp2p/4P1P1/2N1BP1P/PP1QN3/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/3p1np1/2pPp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/3Pp3/2P1P3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/3p1np1/3pp3/2P1P3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/3p1np1/3Pp3/4P3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/3p2p1/3Pp2n/4P3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pppn1pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p2p1/3Pp2n/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p2p1/3Pp2n/2P1P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp3bp/3p2p1/3Ppp1n/2P1P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp3bp/3p2p1/3Ppp1n/2P1P3/2N1BP2/PP1Q2PP/2KR1BNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp3bp/3p2p1/3Pp2n/2P1Pp2/2N1BP2/PP1Q2PP/2KR1BNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/3Pp3/2P1P3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/p4pbp/2pp1np1/1p1Pp3/2P1P3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p2p1/3Pp2n/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p2p1/3Pp2n/2P1P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnb2rk1/ppp2pbp/3p2p1/3Pp2n/2P1P2q/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnb2rk1/ppp2pbp/3p2p1/3Pp2n/2P1P2q/2N2P2/PP1Q1BPP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p2p1/3Pp2n/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p2p1/3Pp2n/2P1P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp3bp/3p2p1/3Ppp1n/2P1P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp3bp/3p2p1/3Ppp1n/2P1P3/2N1BP2/PP1Q2PP/2KR1BNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pppn2bp/3p2p1/3Ppp1n/2P1P3/2N1BP2/PP1Q2PP/2KR1BNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pppn2bp/3p2p1/3Ppp1n/2P1P3/2NBBP2/PP1Q2PP/2KR2NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/3Pp3/2P1P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/3p1np1/3pp3/2P1P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/3p1np1/3Pp3/4P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/1p3pbp/p2p1np1/3Pp3/4P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/1p3pbp/p2p1np1/3Pp3/4P3/2NBBP2/PP1Q2PP/R3K1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p2p1/3Pp2n/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p2p1/3Pp2n/2P1P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnb2rk1/ppp2pbp/3p2p1/3Pp2n/2P1P2q/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnb2rk1/ppp2pbp/3p2p1/3Pp2n/2P1P2q/2N1BPP1/PP1Q3P/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/p1p1ppbp/1p1p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/p1p1ppbp/1p1p1np1/8/2PPP3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/2p1ppbp/pp1p1np1/8/2PPP3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/2p1ppbp/pp1p1np1/8/2PPP3/2NBBP2/PP2N1PP/R2QK2R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/4ppbp/pp1p1np1/2p5/2PPP3/2NBBP2/PP2N1PP/R2QK2R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/4ppbp/pp1p1np1/2pP4/2P1P3/2NBBP2/PP2N1PP/R2QK2R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/p1p1ppbp/1p1p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/p1p1ppbp/1p1p1np1/8/2PPP3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/2p1ppbp/pp1p1np1/8/2PPP3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/2p1ppbp/pp1p1np1/8/2PPP3/2NBBP2/PP2N1PP/R2QK2R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/4ppbp/pp1p1np1/2p5/2PPP3/2NBBP2/PP2N1PP/R2QK2R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/4ppbp/pp1p1np1/2p1P3/2PP4/2NBBP2/PP2N1PP/R2QK2R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PPP3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp1nppbp/3p1np1/2p5/2PPP3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp1nppbp/3p1np1/2p5/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1p1nppbp/p2p1np1/2p5/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pppnppbp/3p1np1/8/2PPP3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp1nppbp/3p1np1/2p5/2PPP3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp1nppbp/3p1np1/2pP4/2P1P3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2P5/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp2ppbp/5np1/2p5/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbQ1rk1/pp2ppbp/5np1/2p5/2P1P3/2N1BP2/PP4PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1P3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/1pp1ppbp/p2p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/1pp1ppbp/p2p1np1/8/2PPP3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2p5/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp2ppbp/2np1np1/2pP4/2P1P3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/8/2PpP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bqr1k1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/P1N1BP2/1P2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP4PP/R1NQKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1Q2PP/2KR1BNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/pp2ppbp/3p1np1/2p5/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/pp2ppbp/3p1np1/2P5/2P1P3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/pp2ppbp/5np1/2p5/2P1P3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbQk2r/pp2ppbp/5np1/2p5/2P1P3/2N2P2/PP4PP/R1B1KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbk3r/pp2ppbp/5np1/2p5/2P1P3/2N2P2/PP4PP/R1B1KBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bqk2r/pppnppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bqk2r/ppp1ppbp/2np1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/4p3/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/4p3/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N1BP2/PP1QN1PP/2KR1B1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1p1n1pbp/p1pp1np1/4p3/2PPP3/2N1BP2/PP1QN1PP/2KR1B1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/1p1n1pbp/p1pp1np1/4p3/2PPP3/2N1BP2/PP1QN1PP/1K1R1B1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/1p2ppbp/p1pp1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/1p2ppbp/p1pp1np1/8/2PPP3/2NBBP2/PP4PP/R2QK1NR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/pp2ppbp/2pp1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/1p2ppbp/p1pp1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/1p2ppbp/p1pp1np1/8/P1PPP3/2N1BP2/1P4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/1pp1ppbp/p2p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/1pp1ppbp/p2p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/4p3/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/4p3/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"r1bq1rk1/pp1n1pbp/2pp1np1/3Pp3/2P1P3/2N1BP2/PP1QN1PP/R3KB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/4p3/2PPP3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbq1rk1/pp3pbp/2pp1np1/3Pp3/2P1P3/2N1BP2/PP2N1PP/R2QKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 387, "fen":"rnbqk2r/ppp2pbp/3p1np1/4p3/2PPP3/2N2P2/PP2N1PP/R1BQKB1R", "treename":"King's Indian Defense, Samisch Variation"},
{"gid": 388, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Semi-Averbakh System"},
{"gid": 388, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Semi-Averbakh System"},
{"gid": 388, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Semi-Averbakh System"},
{"gid": 388, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Semi-Averbakh System"},
{"gid": 388, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Semi-Averbakh System"},
{"gid": 388, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Semi-Averbakh System"},
{"gid": 388, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Semi-Averbakh System"},
{"gid": 388, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Semi-Averbakh System"},
{"gid": 388, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Semi-Averbakh System"},
{"gid": 388, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR", "treename":"King's Indian Defense, Semi-Averbakh System"},
{"gid": 388, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1B3/PP2BPPP/R2QK1NR", "treename":"King's Indian Defense, Semi-Averbakh System"},
{"gid": 389, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 389, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N1PN2/PP2BPPP/R1BQ1RK1", "treename":"King's Indian Defense, Semi-Classical Variation"},
{"gid": 390, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Smyslov Variation"},
{"gid": 390, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Smyslov Variation"},
{"gid": 390, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Smyslov Variation"},
{"gid": 390, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Smyslov Variation"},
{"gid": 390, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Smyslov Variation"},
{"gid": 390, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Smyslov Variation"},
{"gid": 390, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Smyslov Variation"},
{"gid": 390, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Smyslov Variation"},
{"gid": 390, "fen":"rnbqk2r/ppp1ppbp/3p1np1/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Smyslov Variation"},
{"gid": 390, "fen":"rnbqk2r/ppp1ppb1/3p1npp/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Smyslov Variation"},
{"gid": 391, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/1p2ppbp/p2p1np1/2pP2B1/2P1P3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/pp2ppb1/3p1npp/2pP2B1/2P1P3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"r1bq1rk1/ppp1ppbp/2np1np1/6B1/2PPP3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/1pp1ppbp/p2p1np1/6B1/2PPP3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N2P2/PP4PP/R2QKBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 391, "fen":"rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N2P2/PP1Q2PP/R3KBNR", "treename":"King's Indian Defense, Steiner Attack"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pp1ppp1p/5np1/2p5/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pp1ppp1p/2p2np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/ppp1pp1p/3p1np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/pp2ppbp/2pp1np1/6B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/6B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/6B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/6B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N1P1P1/PP3PBP/R1BQK1NR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP1B2/2N2N1P/PP2PPP1/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/pp1pppbp/5np1/2p3B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/pp1pppbp/5np1/2p3B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppppppbp/5np1/8/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppppppbp/5np1/8/2PP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"King's Indian Defense, Main Line"},
{"gid": 382, "fen":"rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"King's Indian Defense, Main Line"},
{"gid": 392, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqk2r/ppppppbp/5np1/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqk2r/ppppppbp/5np1/8/3P1B2/5N2/PPPNPPPP/R2QKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqk2r/ppppppbp/5np1/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqk2r/ppppppbp/5np1/8/3P1B2/2P2N2/PP2PPPP/RN1QKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqk2r/ppppppbp/5np1/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbqk2r/ppppppbp/5np1/8/3P1B2/4PN2/PPP2PPP/RN1QKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbq1rk1/ppppppbp/5np1/8/3P1B2/4PN2/PPP2PPP/RN1QKB1R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbq1rk1/ppppppbp/5np1/8/3P1B2/4PN2/PPP1BPPP/RN1QK2R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3P1B2/4PN2/PPP1BPPP/RN1QK2R", "treename":"London System, Main Line"},
{"gid": 392, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/3P1B2/4PN1P/PPP1BPP1/RN1QK2R", "treename":"London System, Main Line"},
{"gid": 393, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"r1bqkb1r/pppppppp/2n2n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"r1bqkb1r/pppppppp/2n2n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"r1bqkb1r/ppp1pppp/2np1n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"r1bqkb1r/pppppppp/2n2n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"r1bqkb1r/pppppppp/2n2n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"r1bqkb1r/pppp1ppp/2n1pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"r1bqkb1r/pppp1ppp/2n1pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"r1bqkb1r/pppppppp/2n2n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"r1bqkb1r/pppppppp/2n2n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"r1bqkb1r/pppp1ppp/2n1pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Mexican Defense, Main Line"},
{"gid": 393, "fen":"r1bqkb1r/pppp1ppp/2n1pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Mexican Defense, Main Line"},
{"gid": 394, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp2pbp/3p2p1/4p3/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp2pbp/3p2p1/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"r1bqk1nr/pppn1pbp/3p2p1/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"r1bqk1nr/pppn1pbp/3p2p1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"r1bqk2r/pppnnpbp/3p2p1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp2pbp/3p2p1/4p3/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp2pbp/3p2p1/3Pp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"r1bqk1nr/pppn1pbp/3p2p1/3Pp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp2pbp/3p2p1/4p3/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp2pbp/3p2p1/4p3/2PPP3/2N5/PP2NPPP/R1BQKB1R", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp2pbp/3p2p1/4p3/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp2pbp/3p2p1/4P3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp2pbp/6p1/4p3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbQk1nr/ppp2pbp/6p1/4p3/2P1P3/2N5/PP3PPP/R1B1KBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbk2nr/ppp2pbp/6p1/4p3/2P1P3/2N5/PP3PPP/R1B1KBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbk2nr/ppp2pbp/6p1/4p3/2P1PP2/2N5/PP4PP/R1B1KBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/pp2ppbp/2pp2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/pp2ppbp/2pp2p1/8/2PPP3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"r1bqk1nr/pppnppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Averbakh System"},
{"gid": 394, "fen":"r1bqk1nr/pppnppbp/3p2p1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Modern Defense, Averbakh System"},
{"gid": 395, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Beefeater Variation"},
{"gid": 395, "fen":"rnbqkbnr/pppppp1p/6p1/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Beefeater Variation"},
{"gid": 395, "fen":"rnbqkbnr/pppppp1p/6p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Beefeater Variation"},
{"gid": 395, "fen":"rnbqk1nr/ppppppbp/6p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Beefeater Variation"},
{"gid": 395, "fen":"rnbqk1nr/ppppppbp/6p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Beefeater Variation"},
{"gid": 395, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Beefeater Variation"},
{"gid": 395, "fen":"rnbqk1nr/pp1pppbp/6p1/2pP4/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Beefeater Variation"},
{"gid": 395, "fen":"rnbqk1nr/pp1ppp1p/6p1/2pP4/2P5/2b5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Beefeater Variation"},
{"gid": 395, "fen":"rnbqk1nr/pp1ppp1p/6p1/2pP4/2P5/2P5/P3PPPP/R1BQKBNR", "treename":"Modern Defense, Beefeater Variation"},
{"gid": 395, "fen":"rnbqk1nr/pp1pp2p/6p1/2pP1p2/2P5/2P5/P3PPPP/R1BQKBNR", "treename":"Modern Defense, Beefeater Variation"},
{"gid": 396, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2PPP3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2PPP3/2N1B3/PP2NPPP/R2QKB1R", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2PPP3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp2pbp/2np2p1/3Pp3/2P1P3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1npbp/3p2p1/3Pp3/2P1P3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1npbp/3p2p1/3Pp3/2P1P1P1/2N1B3/PP3P1P/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N5/PP2NPPP/R1BQKB1R", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp2pbp/2np2p1/4p3/2PPP3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp2pbp/2np2p1/3Pp3/2P1P3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1npbp/3p2p1/3Pp3/2P1P3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1npbp/3p2p1/2PPp3/4P3/2N1B3/PP3PPP/R2QKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 396, "fen":"r1bqk1nr/ppp1ppbp/2np2p1/3P4/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Kotov Variation"},
{"gid": 398, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Randspringer Variation"},
{"gid": 398, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Randspringer Variation"},
{"gid": 398, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Randspringer Variation"},
{"gid": 398, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Randspringer Variation"},
{"gid": 398, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Randspringer Variation"},
{"gid": 398, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Randspringer Variation"},
{"gid": 398, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Randspringer Variation"},
{"gid": 398, "fen":"rnbqk1nr/ppp1p1bp/3p2p1/5p2/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Modern Defense, Randspringer Variation"},
{"gid": 398, "fen":"rnbqk1nr/ppp1p1bp/3p2p1/5p2/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Modern Defense, Randspringer Variation"},
{"gid": 397, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqkbnr/pppppp1p/6p1/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqkbnr/pppppp1p/6p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqk1nr/ppppppbp/6p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqk1nr/ppppppbp/6p1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rn1qk1nr/ppp1ppbp/3p2p1/8/2PP2b1/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"rn1qk1nr/ppp1ppbp/3p2p1/8/2PP2b1/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Modern Defense, Main Line"},
{"gid": 397, "fen":"r2qk1nr/ppp1ppbp/2np2p1/8/2PP2b1/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Modern Defense, Main Line"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2pP4/N4NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"r1bq1rk1/ppp1ppbp/2n2np1/8/2pP4/N4NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"r1bq1rk1/ppp1ppbp/2n2np1/8/2NP4/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"r2q1rk1/ppp1ppbp/2n1bnp1/8/2NP4/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"r2q1rk1/ppp1ppbp/2n1bnp1/8/2NP4/1P3NP1/P3PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"r2q1rk1/ppp1ppbp/2n2np1/3b4/2NP4/1P3NP1/P3PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2pP4/N4NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"r1bq1rk1/ppp1ppbp/n4np1/8/2pP4/N4NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2pP4/N4NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/3P4/N1p2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/3P4/N1P2NP1/P3PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/5np1/2p5/3P4/N1P2NP1/P3PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/5np1/2p5/3P4/N1P1PNP1/P4PBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"r1bq1rk1/pp1nppbp/2p2np1/3p4/2PP4/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/1Q3NP1/PP2PPBP/RNB2RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnb2rk1/pp2ppbp/1qp2np1/3p4/2PP4/1Q3NP1/PP2PPBP/RNB2RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p3p1/3p4/2PPn3/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/N4NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rn1q1rk1/pp2ppbp/2p2np1/3p1b2/2PP4/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rn1q1rk1/pp2ppbp/2p2np1/3p1b2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3pN3/2PP4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p3p1/3p4/2PPn3/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/Q1PP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/1Q3NP1/PP2PPBP/RNB2RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/8/2pP4/1Q3NP1/PP2PPBP/RNB2RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/8/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/2p2np1/8/2pP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"r1bq1rk1/ppp1ppbp/2n2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp2pbp/4pnp1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/5np1/2pp4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/5np1/2Pp4/2P5/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 399, "fen":"rnbq1rk1/pp2ppbp/5np1/2P5/2p5/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Classical Variation"},
{"gid": 400, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp1ppbp/n5p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/1n4p1/8/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/1n4p1/8/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp1ppbp/1nn3p1/8/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp1ppbp/1nn3p1/8/3P4/2N1PNP1/PP3PBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp2pbp/1nn3p1/4p3/3P4/2N1PNP1/PP3PBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp2pbp/1nn3p1/3Pp3/8/2N1PNP1/PP3PBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp2pbp/1n4p1/n2Pp3/8/2N1PNP1/PP3PBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/6p1/2pn4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/6p1/2Pn4/8/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/pp2ppbp/n5p1/2Pn4/8/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/2p3p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/6p1/2pn4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/6p1/2pn4/3PP3/5NP1/PP3PBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/5np1/2p5/3PP3/5NP1/PP3PBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/5np1/2p1P3/3P4/5NP1/PP3PBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/6p1/2pnP3/3P4/5NP1/PP3PBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/6p1/2pn4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/6p1/2pn4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3P4/2n2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/6p1/2p5/3P4/2P2NP1/P3PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/pp2ppbp/6p1/8/3p4/2P2NP1/P3PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp1ppbp/2n3p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/1n4p1/8/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/1n4p1/8/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp1ppbp/1nn3p1/8/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp1ppbp/1nn3p1/3P4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp1ppbp/1n4p1/n2P4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp1ppbp/1n4p1/n2P4/4P3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/pp2ppbp/1np3p1/n2P4/4P3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/pp2ppbp/1np3p1/n2P2B1/4P3/2N2NP1/PP3PBP/R2Q1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/5np1/3P4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/1n4p1/8/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"rnbq1rk1/ppp1ppbp/1n4p1/8/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp1ppbp/1nn3p1/8/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bq1rk1/ppp1ppbp/1nn3p1/8/3P4/2N1PNP1/PP3PBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bqr1k1/ppp1ppbp/1nn3p1/8/3P4/2N1PNP1/PP3PBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 400, "fen":"r1bqr1k1/ppp1ppbp/1nn3p1/3P4/8/2N1PNP1/PP3PBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Delayed Exchange Variation"},
{"gid": 401, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3P4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3PP3/6P1/PP3PBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/6P1/PP3PBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/6P1/PP2NPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"r1bqk2r/ppp1ppbp/1nn3p1/8/3PP3/6P1/PP2NPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3P4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3PP3/6P1/PP3PBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/6P1/PP3PBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/6P1/PP2NPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp2pbp/1n4p1/4p3/3PP3/6P1/PP2NPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3P4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3PP3/6P1/PP3PBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/6P1/PP3PBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/6P1/PP2NPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/pp2ppbp/1n4p1/2p5/3PP3/6P1/PP2NPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3P4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3P4/2n3P1/PP2PPBP/R1BQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/8/3P4/2P3P1/P3PPBP/R1BQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/pp2ppbp/6p1/2p5/3P4/2P3P1/P3PPBP/R1BQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3P4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3P4/2N3P1/PP2PPBP/R1BQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3P4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3PP3/6P1/PP3PBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/8/1n1PP3/6P1/PP3PBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3P4/1n2P3/6P1/PP3PBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/5np1/3P4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3P4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/6p1/3n4/3PP3/6P1/PP3PBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/6P1/PP3PBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/6P1/PP2NPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbq1rk1/ppp1ppbp/1n4p1/8/3PP3/6P1/PP2NPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 401, "fen":"rnbq1rk1/ppp1ppbp/1n4p1/8/3PP3/6P1/PP2NPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Exchange Variation"},
{"gid": 402, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/5P2/PP2P1PP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/5P2/PP2P1PP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/5P2/PP2P1PP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/5P2/PP2P1PP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqkb1r/ppp1pp1p/6p1/3n4/3PP3/5P2/PP4PP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqkb1r/ppp1pp1p/1n4p1/8/3PP3/5P2/PP4PP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqkb1r/ppp1pp1p/1n4p1/8/3PP3/2N2P2/PP4PP/R1BQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/2N2P2/PP4PP/R1BQKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbqk2r/ppp1ppbp/1n4p1/8/3PP3/2N1BP2/PP4PP/R2QKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 402, "fen":"rnbq1rk1/ppp1ppbp/1n4p1/8/3PP3/2N1BP2/PP4PP/R2QKBNR", "treename":"Neo-Grunfeld Defense, Goglidze Attack"},
{"gid": 404, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Non- or Delayed Fianchetto"},
{"gid": 404, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Non- or Delayed Fianchetto"},
{"gid": 404, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Non- or Delayed Fianchetto"},
{"gid": 404, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Non- or Delayed Fianchetto"},
{"gid": 404, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Neo-Grunfeld Defense, Non- or Delayed Fianchetto"},
{"gid": 404, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Neo-Grunfeld Defense, Non- or Delayed Fianchetto"},
{"gid": 405, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3P4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3p4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3p4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"r1bq1rk1/pp2ppbp/2n2np1/3p4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"r1bq1rk1/pp2ppbp/2n2np1/3pN3/3P4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"r1bq1rk1/pp3pbp/2n1pnp1/3pN3/3P4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3P4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3p4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3p4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp3pbp/4pnp1/3p4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3P4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3p4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3pN3/3P4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp3pbp/4pnp1/3pN3/3P4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp3pbp/4pnp1/3pN3/3P4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp1n1pbp/4p1p1/3pN3/3P4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp1n1pbp/4p1p1/3pN3/3P1P2/2N3P1/PP2P1BP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"r1bq1rk1/pp1n1pbp/2n1p1p1/3pN3/3P1P2/2N3P1/PP2P1BP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"r1bq1rk1/pp1n1pbp/2n1p1p1/3pN3/3P1P2/2N1B1P1/PP2P1BP/R2Q1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"r1bq1rk1/pp1n2bp/2n1ppp1/3pN3/3P1P2/2N1B1P1/PP2P1BP/R2Q1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3P4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3p4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3p4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/6p1/3p4/3Pn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/6p1/3p4/3PN3/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3P4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3p4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3pN3/3P4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp3pbp/4pnp1/3pN3/3P4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp3pbp/4pnp1/3pN3/3P4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp1n1pbp/4p1p1/3pN3/3P4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp1n1pbp/4p1p1/3pN3/3P1P2/2N3P1/PP2P1BP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"r1bq1rk1/pp1n1pbp/2n1p1p1/3pN3/3P1P2/2N3P1/PP2P1BP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"r1bq1rk1/pp1n1pbp/2n1p1p1/3pN3/3P1P2/2N1B1P1/PP2P1BP/R2Q1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"r1bq1rk1/pp3pbp/1nn1p1p1/3pN3/3P1P2/2N1B1P1/PP2P1BP/R2Q1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"r1bq1rk1/pp3pbp/1nn1p1p1/3pN3/3P1P2/2N3P1/PP2PBBP/R2Q1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/2p2np1/3P4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3p4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/5np1/3p4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/6p1/3p4/3Pn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 405, "fen":"rnbq1rk1/pp2ppbp/6p1/3pN3/3Pn3/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation"},
{"gid": 403, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/pp2pp1p/2p2np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 403, "fen":"rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/6P1/PP2PP1P/RNBQKBNR", "treename":"Neo-Grunfeld Defense, Main Line"},
{"gid": 406, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/4pn2/3P4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/5n2/3p4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/5n2/3p2B1/1b1P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/pp3ppp/5n2/2pp2B1/1b1P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/4pn2/3P4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/5n2/3p4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/5n2/3p2B1/1b1P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2pp1/5n1p/3p2B1/1b1P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2pp1/5n1p/3p4/1b1P3B/2N5/PPQ1PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/pp3pp1/5n1p/2pp4/1b1P3B/2N5/PPQ1PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/4pn2/3P4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/5n2/3p4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/5n2/3p4/1b1P4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/4pn2/3P4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/5n2/3p4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2ppp/5n2/3p2B1/1b1P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2pp1/5n1p/3p2B1/1b1P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 406, "fen":"rnbqk2r/ppp2pp1/5B1p/3p4/1b1P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2b5/2P5/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bq1rk1/pp1p1ppp/n3pn2/2P5/1bP5/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bq1rk1/pp1p1ppp/n3pn2/2P5/1bP5/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2b5/2P5/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2b5/2P5/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bq1rk1/pp1p1ppp/2n1pn2/2b5/2P5/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP2B2/2N5/PPQ1PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bq1rk1/pp1p1ppp/n3pn2/2P5/1bP5/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bq1rk1/pp1p1ppp/n3pn2/2P5/1bP5/2N2NP1/PPQ1PP1P/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bq1rk1/pp1p1ppp/n3pn2/2P5/1bP5/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bq1rk1/pp1p1ppp/n3pn2/2P5/1bP5/2N2N2/PPQBPPPP/R3KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2b5/2P5/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2b5/2P5/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/p2p1ppp/1p2pn2/2b5/2P5/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/p2p1ppp/1p2pn2/2b3B1/2P5/P1N2N2/1PQ1PPPP/R3KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2b5/2P5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2b5/2P5/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/pp1p1ppp/1q2pn2/2b5/2P5/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppqp1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PPQ2PPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp1p1ppp/n3pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp1p1ppp/n3pn2/2P5/1bP5/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp1p1ppp/n3pn2/2P5/2P5/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp1p1ppp/n3pn2/2P5/2P5/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp1p1ppp/4pn2/2n5/2P5/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp1p1ppp/4pn2/2n5/1PP5/P1Q5/4PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp1p1ppp/4pn2/8/1PP1n3/P1Q5/4PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp1p1ppp/4pn2/8/1PPQn3/P7/4PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp3ppp/4pn2/3p4/1PPQn3/P7/4PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp3ppp/4pn2/2Pp4/1P1Qn3/P7/4PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/6B1/2PP4/P1Q5/1P2PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rn1q1rk1/p1pp1ppp/bp2pn2/6B1/2PP4/P1Q5/1P2PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/6B1/2PP4/P1Q5/1P2PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/6B1/2PP4/P1Q5/1P2PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/6B1/2PP4/P1Q1P3/1P3PPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rn1q1rk1/pbp2ppp/1p1ppn2/6B1/2PP4/P1Q1P3/1P3PPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/6B1/2PP4/P1Q5/1P2PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/6B1/2PP4/P1Q5/1P2PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/6B1/2PP4/P1Q2P2/1P2P1PP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rn1q1rk1/pbpp1pp1/1p2pn1p/6B1/2PP4/P1Q2P2/1P2P1PP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rn1q1rk1/pbpp1pp1/1p2pn1p/8/2PP3B/P1Q2P2/1P2P1PP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/8/2PP4/P1Q2N2/1P2PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/ppp2ppp/2nppn2/8/1bPP4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/ppp2ppp/2nppn2/8/1bPP4/2N2N2/PPQBPPPP/R3KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bq1rk1/ppp2ppp/2nppn2/8/1bPP4/2N2N2/PPQBPPPP/R3KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/ppp2ppp/2nppn2/8/1bPP4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/ppp2ppp/2nppn2/8/1bPP4/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/ppp2ppp/2nppn2/8/2PP4/P1b2N2/1PQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/ppp2ppp/2nppn2/8/2PP4/P1Q2N2/1P2PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/ppp2ppp/2n1pn2/3p4/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4p3/3p4/2PPn3/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4p3/3p4/2PPn3/P7/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/ppp2ppp/2n1p3/3p4/2PPn3/P7/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/ppp2ppp/2n1p3/3p4/2PPn3/P3P3/1PQ2PPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/ppp2ppp/2n5/3pp3/2PPn3/P3P3/1PQ2PPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3P4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/3q4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/3q4/1b1P4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/pp3ppp/4pn2/2pq4/1b1P4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4p3/3p4/2PPn3/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4p3/3p4/2PPn3/P7/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp3ppp/4p3/2pp4/2PPn3/P7/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pp3ppp/4p3/2Pp4/2P1n3/P7/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pp3ppp/2n1p3/2Pp4/2P1n3/P7/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3P4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/3q4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/3q4/1b1P4/2N1P3/PPQ2PPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/pp3ppp/4pn2/2pq4/1b1P4/2N1P3/PPQ2PPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/8/2pP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3P4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/3q4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/3q4/1b1P4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/5q2/1b1P4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/5q2/1b1P4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/4pn2/3P4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/3q4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/3q4/1b1P4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/5q2/1b1P4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnb1k2r/ppp2ppp/4pn2/5Q2/1b1P4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/p1pp1ppp/4pn2/1p6/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"r1bqk2r/ppp2ppp/2n1pn2/3p4/1bPP4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/ppp2ppp/3ppn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1b5/1PQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4p3/8/2PPn3/P1Q5/1P2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/ppp2ppp/3ppn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/6B1/1bPP4/2N5/PPQ1PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 407, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPPP3/2N5/PPQ2PPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Classical Variation"},
{"gid": 408, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/p1pp1ppp/1p2p3/8/1bPPn3/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/p1pp1ppp/1p2p3/8/1bPPn3/2N1P3/PP1BNPPP/R2QKB1R", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/p1pp1ppp/1p2p3/8/1bPPn3/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 408, "fen":"rnbqk2r/p1pp1ppp/1p2p3/8/1bPPn3/2N1P3/PPQ1NPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Fischer Variation"},
{"gid": 409, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/2PP4/2bBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/2PP4/2PBPN2/P4PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp3ppp/2nppn2/2p5/2PP4/2PBPN2/P4PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp3ppp/2nppn2/2p5/2PPP3/2PB1N2/P4PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp3ppp/2np1n2/2p1p3/2PPP3/2PB1N2/P4PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp3ppp/2np1n2/2pPp3/2P1P3/2PB1N2/P4PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp2nppp/3p1n2/2pPp3/2P1P3/2PB1N2/P4PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/2PP4/2bBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/2PP4/2PBPN2/P4PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp3ppp/2nppn2/2p5/2PP4/2PBPN2/P4PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp3ppp/2nppn2/2p5/2PP4/2PBPN2/P4PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp3ppp/2np1n2/2p1p3/2PP4/2PBPN2/P4PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp3ppp/2np1n2/2p1p3/2PP4/2PBP3/P2N1PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPp4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPP4/2N5/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbq1rk1/pp1p1ppp/4pn2/8/1bPP4/2N5/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbq1rk1/pp1p1ppp/4pn2/8/1bPP4/P1N5/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbq1rk1/pp1pbppp/4pn2/8/2PP4/P1N5/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPp4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPP4/2N5/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp3ppp/4pn2/3p4/1bPP4/2N5/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp3ppp/4pn2/2pp4/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/1bPP4/2NBP3/PP2NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 409, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Hübner Variation"},
{"gid": 410, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2P2/PP2P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2P2/PP2P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/P1N2P2/1P2P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/P1N2P2/1P2P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2P2/PP2P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N2P2/PP2P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 410, "fen":"rnbqk2r/pp1p1ppp/4pn2/2pP4/1bP5/2N2P2/PP2P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Kmoch Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/p2p1pp1/4pn1p/1ppP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/2P4B/2b1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/2P4B/2P1P3/P4PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3p1n1p/2pPp3/2P4B/2P1P3/P4PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3p1n1p/2pPp3/2P4B/2PBP3/P4PPP/R2QK1NR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/2P4B/2b1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/2P4B/2P1P3/P4PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3p1n1p/2pPp3/2P4B/2P1P3/P4PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3p1n1p/2pPp3/2P4B/2P1PN2/P4PPP/R2QKB1R", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/2P4B/2b1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/2P4B/2P1P3/P4PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3p1n1p/2pPp3/2P4B/2P1P3/P4PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3p1n1p/2pPp3/2P4B/2P1P3/P1Q2PPP/R3KBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnb1k2r/pp2qpp1/3ppn1p/2pP4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3p1n1p/2pp4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbq1rk1/pp3pp1/3ppn1p/2pP4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/2P4B/2b1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/2P4B/2P1P3/P4PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnb1k2r/pp2qpp1/3ppn1p/2pP4/2P4B/2P1P3/P4PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/2P4B/2b1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/2P4B/2P1P3/P4PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3p1n1p/2pPp3/2P4B/2P1P3/P4PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3p1n1p/2pPp3/2P4B/2P1PP2/P5PP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"r1bqk2r/pp1n1pp1/3p1n1p/2pPp3/2P4B/2P1PP2/P5PP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3p2/3ppn1p/2pP2p1/1bP4B/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p3B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1ppp/4pn2/2pP2B1/1bP5/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1ppp/5n2/2pp2B1/1bP5/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p3B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1ppp/4pn2/2pP2B1/1bP5/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP2B1/1bP5/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p3B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1ppp/4pn2/2pP2B1/1bP5/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3ppp/3ppn2/2pP2B1/1bP5/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp3ppp/3ppn2/2pP2B1/1bP5/2N1P3/PP3PPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/2P4B/2b5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p3B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1ppp/4pn2/2pP2B1/1bP5/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1ppp/4pn2/2pP2B1/2P5/2b5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pppp1pp1/4pn1p/8/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2p5/1bPP3B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/4pn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/5n1p/2pp4/1bP4B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 411, "fen":"rnbqk2r/pp1p1pp1/5n1p/2pP4/1b5B/2N5/PP2PPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Leningrad Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/p4ppp/1pn1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2pp4/2PP4/P1PBPN2/2Q2PPP/R1B2RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2pP4/3P4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n2n2/2pp4/3P4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n2n2/2pp4/P2P4/2PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b1r1k1/ppq2ppp/2n2n2/2pp4/P2P4/2PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2pp4/2PP4/P1PBPN1P/5PP1/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2pP4/3P4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n2n2/2pp4/3P4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n2n2/2pp4/3P3N/P1PBP3/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2pp4/2PP4/P1PBPN2/1B3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2pP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/3P4/P1P1PN2/B4PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n2n2/2p1p3/3P4/P1P1PN2/B4PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2pP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/P1BP4/2P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2pP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/3P4/P1P1PN2/4BPPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2pP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/3P4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n2n2/2p1p3/3P4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n2n2/2p1p3/3P4/P1PBPN2/2Q2PPP/R1B2RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b1r1k1/ppq2ppp/2n2n2/2p1p3/3P4/P1PBPN2/2Q2PPP/R1B2RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b1r1k1/ppq2ppp/2n2n2/2p1P3/8/P1PBPN2/2Q2PPP/R1B2RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2pP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/2BP4/P1P1PN2/2Q2PPP/R1B2RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2pP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/1Bp5/3P4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2pP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/2BP4/P1P1PN2/4QPPP/R1B2RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1bBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2pP4/P1PBPN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1b2rk1/ppq2ppp/2n1pn2/2p5/2BP4/P1P1PN2/1B3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBP3/PP2NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBP3/PP2NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBP3/PP2NPPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/3p4/1bPp4/2NBP3/PP2NPPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/3p4/1bPP4/2NB4/PP2NPPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3P4/1b1P4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBP3/PP2NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBP3/PP2NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pP4/1b1P4/2NBP3/PP2NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/3P4/1b1p4/2NBP3/PP2NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/3P4/1b1P4/2NB4/PP2NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4p3/3n4/1b1P4/2NB4/PP2NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4p3/3n4/1b1P4/2NB4/PP2NPPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/3ppn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/1bPP4/2NBP3/PP2NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp1p1ppp/4pn2/8/1bPp4/2NBP3/PP2NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/P1NBP3/1P3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/2PP4/P1bBP3/1P3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/8/2pP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/8/2BP4/P1P1P3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/2BP4/P1P1P3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/2BP4/P1P1P3/4NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/P1NBP3/1P3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/2PP4/P1bBP3/1P3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pP4/3P4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/5n2/2pp4/3P4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/5n2/2pp4/3P4/P1PBP3/4NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/2N1P1N1/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/2PP4/2b1P1N1/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/2N1P1N1/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1pp1pp1/bp2pn2/7p/1bPP4/2N1P1N1/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/P1N1P3/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/2PP4/P1b1P3/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/P1N1P3/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1qk2r/p1ppbppp/bp2pn2/8/2PP4/P1N1P3/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/1bpP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/1bBP4/P1N1PN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/8/1bBp4/P1N1PN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/8/1bBP4/P1N2N2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/2BP4/P1N2N2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/2BP4/P1N2N2/1P3PPP/R1BQR1K1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/1p2bppp/p1n1pn2/8/2BP4/P1N2N2/1P3PPP/R1BQR1K1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/1bpP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/1bBP4/P1N1PN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/b1p5/2BP4/P1N1PN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/1bpP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/1bBP4/P1N1PN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/8/1bBp4/P1N1PN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/8/1bBP4/P1N2N2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/2BP4/P1N2N2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp2bppp/2n1pn2/6B1/2BP4/P1N2N2/1P3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/1bpP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/1bBP4/P1N1PN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/8/1bBp4/P1N1PN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/8/1bBP4/P1N2N2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/2BP4/P1N2N2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/2BP4/P1NQ1N2/1P3PPP/R1B2RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/3p4/1bPp4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/3p4/1bPP4/P1NB1N2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/b1pp4/2PP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/b1pP4/3P4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2pP4/1b1P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp1n1ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp1n1ppp/4pn2/2p5/1bBP4/2N1PN2/PP2QPPP/R1B2RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/1p1n1ppp/p3pn2/2p5/1bBP4/2N1PN2/PP2QPPP/R1B2RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp1n1ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp1n1ppp/4pn2/2p5/1bBP4/4PN2/PP2NPPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp1n1ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp1n1ppp/4pn2/2p5/1bBP4/2N1PN2/PP2QPPP/R1B2RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/p2n1ppp/1p2pn2/2p5/1bBP4/2N1PN2/PP2QPPP/R1B2RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/p2n1ppp/1p2pn2/2p5/1bBP4/2N1PN2/PP2QPPP/R1BR2K1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/8/1bBp4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/8/1bBP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p4ppp/1p2pn2/8/1bBP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p4ppp/1p2pn2/6B1/1bBP4/2N2N2/PP3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pb3ppp/1p2pn2/6B1/1bBP4/2N2N2/PP3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pb3ppp/1p2pn2/6B1/1bBP4/2N2N2/PP2QPPP/R4RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/8/1bBp4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/8/1bBP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/1p3ppp/p3pn2/8/1bBP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/1p3ppp/p3pn2/6B1/1bBP4/2N2N2/PP3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/8/1bBp4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/8/1bBP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p4ppp/1p2pn2/8/1bBP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p4ppp/1p2pn2/6B1/1bBP4/2N2N2/PP3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pb3ppp/1p2pn2/6B1/1bBP4/2N2N2/PP3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pb3ppp/1p2pn2/6B1/1bBP4/2N2N2/PP3PPP/R2QR1K1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/8/1bBp4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/8/1bBP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p4ppp/1p2pn2/8/1bBP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p4ppp/1p2pn2/6B1/1bBP4/2N2N2/PP3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pb3ppp/1p2pn2/6B1/1bBP4/2N2N2/PP3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pb3ppp/1p2pn2/6B1/1bBP4/2N2N2/PP3PPP/2RQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/8/1bBp4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/8/1bBP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p4ppp/1p2pn2/8/1bBP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p4ppp/1p2pn2/6B1/1bBP4/2N2N2/PP3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pb3ppp/1p2pn2/6B1/1bBP4/2N2N2/PP3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pb3ppp/1p2pn2/4N1B1/1bBP4/2N5/PP3PPP/R2Q1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p4ppp/1p2pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p4ppp/1p2pn2/2pP4/1b1P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p4ppp/1p3n2/2pp4/1b1P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnb2rk1/pp2qppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pp1b1ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp1n1ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/P1NBPN2/1P3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/1bPP4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp1p1ppp/2n1pn2/2p5/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bq1rk1/pp1p1ppp/2n1pn2/2p5/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p1p2ppp/1p2pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p1p2ppp/1p2pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p2pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p2pn2/3P4/1b1P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p3n2/3p4/1b1P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p3n2/3p4/1b1P4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p1b1n2/3p4/3P4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p1p2ppp/1p2pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p1p2ppp/1p2pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p2pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p2pn2/3p4/1bPP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p1bpn2/3p4/2PP4/P1NBPN2/1P3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p1p2ppp/1p2pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbq1rk1/p1p2ppp/1p2pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p2pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p2pn2/3P4/1b1P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p3n2/3p4/1b1P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rn1q1rk1/pbp2ppp/1p3n2/3pN3/1b1P4/2NBP3/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bqk2r/ppp2ppp/2n1pn2/3p4/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 413, "fen":"r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Normal Variation"},
{"gid": 414, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"r1bq1rk1/ppp2ppp/2n1pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"r1bq1rk1/ppp2ppp/2n1pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 414, "fen":"r1bq1rk1/1pp2ppp/p1n1pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Ragozin Defense"},
{"gid": 415, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"r1bq1rk1/ppp2ppp/2n1pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"r1bq1rk1/ppp2ppp/2n1pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 415, "fen":"r1bq1rk1/ppp2ppp/2n1pn2/8/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Ragozin Variation"},
{"gid": 416, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqr1k1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/P1N1P3/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/P1N1P3/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/ppp1bppp/4pn2/3P4/3P4/P1N1P3/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 416, "fen":"rnbq1rk1/ppp1bppp/5n2/3p4/3P4/P1N1P3/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Reshevsky Variation"},
{"gid": 417, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/1bPP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pp1p1ppp/4p3/2p5/1bPPn3/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/1bPP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPp4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbqk2r/pp1p1ppp/4pn2/8/1bPN4/2N3P1/PP2PP1P/R1BQKB1R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbq1rk1/pp1p1ppp/4pn2/8/1bPN4/2N3P1/PP2PP1P/R1BQKB1R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbq1rk1/pp1p1ppp/4pn2/8/1bPN4/2N3P1/PP2PPBP/R1BQK2R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbq1rk1/pp3ppp/4pn2/3p4/1bPN4/2N3P1/PP2PPBP/R1BQK2R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 417, "fen":"rnbq1rk1/pp3ppp/4pn2/3P4/1b1N4/2N3P1/PP2PPBP/R1BQK2R", "treename":"Nimzo-Indian Defense, Romanishin Variation"},
{"gid": 418, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp3ppp/4pn2/2pp4/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp3ppp/4pn2/2pP4/3P4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp3ppp/4p3/2pn4/3P4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp3ppp/4p3/2Pn4/8/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp4pp/4p3/2Pn1p2/8/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp4pp/4p3/2Pn1p2/4P3/P1P2P2/6PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp3ppp/4pn2/2pp4/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp3ppp/4pn2/2pP4/3P4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp3ppp/4p3/2pn4/3P4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp3ppp/4p3/2Pn4/8/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp4pp/4p3/2Pn1p2/8/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 418, "fen":"rnbqk2r/pp4pp/4p3/2Pn1p2/8/P1P2P1N/4P1PP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Samisch"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/pp1p1ppp/2n1pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/pp1p1ppp/2n1pn2/2p5/2PP4/P1PBP3/4NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/p2p1ppp/1pn1pn2/2p5/2PP4/P1PBP3/4NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/p2p1ppp/1pn1pn2/2p5/2PPP3/P1PB4/4NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bqnrk1/p2p1ppp/1pn1p3/2p5/2PPP3/P1PB4/4NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bqnrk1/p2p1ppp/1pn1p3/2p5/2PPP3/P1PB4/4NPPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/p2p1ppp/1p2pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/4NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/2PP4/P1P1PP2/6PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/pp1p1ppp/2n1pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/pp1p1ppp/2n1pn2/2p5/2PP4/P1PBP3/4NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/pp1p1ppp/2n2n2/2p1p3/2PP4/P1PBP3/4NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/8/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/p2p1ppp/1p2pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp3ppp/3ppn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/pp1p1ppp/2n1pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/pp1p1ppp/2n1pn2/2p5/2PP4/P1PBP3/4NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/pp3ppp/2nppn2/2p5/2PP4/P1PBP3/4NPPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp3ppp/4pn2/2pp4/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp3ppp/4pn2/2pP4/3P4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp3ppp/4p3/2pn4/3P4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp3ppp/4p3/2pn4/3P4/P1PQ1P2/4P1PP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnb1k2r/pp1p1ppp/4pn2/q1p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pp1p1ppp/4pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/pp1p1ppp/2n1pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bq1rk1/pp1p1ppp/2n1pn2/2p5/2PP4/P1PBPN2/5PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/2PP4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbq1rk1/ppp2ppp/4pn2/3P4/3P4/P1P2P2/4P1PP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1b5/1P2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P5/4PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 419, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, Samisch Variation"},
{"gid": 420, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 420, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 420, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 420, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 420, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 420, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 420, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 420, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 420, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 420, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1P3/PP2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 420, "fen":"rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/P1N1P3/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 420, "fen":"rnbq1rk1/ppp2ppp/3bpn2/3p4/2PP4/P1N1P3/1P2NPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Simagin Variation"},
{"gid": 421, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2P5/1bP5/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2P5/1bP5/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"r1bqk2r/pp1p1ppp/2n1p3/2P5/1bP1n3/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"r1bqk2r/pp1p1ppp/2n1p3/2P5/1bP1n3/1QN2N2/PP1BPPPP/R3KB1R", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"r1bqk2r/pp1p1ppp/2n1p3/2P5/1bP5/1QN2N2/PP1nPPPP/R3KB1R", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2P5/1bP5/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"r1bqk2r/pp1p1ppp/2n1pn2/2P3B1/1bP5/1QN5/PP2PPPP/R3KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pp1p1ppp/4pn2/2P5/1bP5/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"r1bqk2r/pp1p1ppp/n3pn2/2P5/1bP5/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/1QN5/PP2PPPP/R1B1KBNR", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 421, "fen":"rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Spielmann Variation"},
{"gid": 422, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rn1qk2r/pbpp1ppp/1p2p3/8/1bPPn3/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rn1qk2r/pbpp1ppp/1p2p3/8/1bPPn3/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rn1qk2r/pbpp2pp/1p2p3/5p2/1bPPn3/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/8/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 422, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/8/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Nimzo-Indian Defense, St. Petersburg Variation"},
{"gid": 423, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/2N2N2/PPQ1PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/2pp1ppp/1p2pn2/p7/1bPP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/6B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/6B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/p2p1ppp/1p2pn2/2p5/1bPP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 423, "fen":"rnbqk2r/pppp1ppp/4pn2/8/2PP4/2b2N2/PP2PPPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Three Knights Variation"},
{"gid": 412, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbq1rk1/p1pp1ppp/1p2pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP1BPPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N5/PP1BPPPP/R2QKBNR", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 412, "fen":"rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N2N2/PP1BPPPP/R2QKB1R", "treename":"Nimzo-Indian Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/4p3/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/4p3/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/8/2pp4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/8/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3ppp/3p4/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3ppp/3p4/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3p1p/3p2p1/2pP4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3p1p/3p2p1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqk1nr/pp3pbp/3p2p1/2pP4/4P3/2N5/PP3PPP/R1BQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqk1nr/pp3pbp/3p2p1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/4p3/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/4p3/2pP4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/8/2pp4/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/8/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3ppp/3p4/2pP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3ppp/3p4/2pP4/4P3/8/PP3PPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/8/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/2P1P3/8/PP3PPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3p1p/3p2p1/2pPp3/2P1P3/8/PP3PPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3p1p/3p2p1/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqk1nr/pp3pbp/3p2p1/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqk1nr/pp3pbp/3p2p1/2pPp3/2P1P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/8/8/PPP1PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/2P1P3/8/PP3PPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqk1nr/pp2bppp/3p4/2pPp3/2P1P3/8/PP3PPP/RNBQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 424, "fen":"rnbqk1nr/pp2bppp/3p4/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Old Benoni Defense, Main Line"},
{"gid": 425, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian, Czech Variation"},
{"gid": 425, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian, Czech Variation"},
{"gid": 425, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian, Czech Variation"},
{"gid": 425, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian, Czech Variation"},
{"gid": 425, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian, Czech Variation"},
{"gid": 425, "fen":"rnbqkb1r/pp2pppp/2pp1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian, Czech Variation"},
{"gid": 425, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian, Czech Variation"},
{"gid": 425, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian, Czech Variation"},
{"gid": 425, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian, Czech Variation"},
{"gid": 425, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian, Czech Variation"},
{"gid": 425, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian, Czech Variation"},
{"gid": 425, "fen":"rnbqkb1r/pp2pppp/2pp1n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian, Czech Variation"},
{"gid": 426, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Dus-Khotimirsky Variation"},
{"gid": 426, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Dus-Khotimirsky Variation"},
{"gid": 426, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Dus-Khotimirsky Variation"},
{"gid": 426, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Dus-Khotimirsky Variation"},
{"gid": 426, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Dus-Khotimirsky Variation"},
{"gid": 426, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Dus-Khotimirsky Variation"},
{"gid": 426, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Old Indian Defense, Dus-Khotimirsky Variation"},
{"gid": 426, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Old Indian Defense, Dus-Khotimirsky Variation"},
{"gid": 426, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Old Indian Defense, Dus-Khotimirsky Variation"},
{"gid": 427, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Janowski Variation"},
{"gid": 427, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Janowski Variation"},
{"gid": 427, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Janowski Variation"},
{"gid": 427, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Janowski Variation"},
{"gid": 427, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Janowski Variation"},
{"gid": 427, "fen":"rn1qkb1r/ppp1pppp/3p1n2/5b2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Janowski Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1b2rk1/ppqnbppp/2pp1n2/4p3/2PPP3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqr1k1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/1p1nbppp/p1pp1n2/4p3/2PPP3/2N1BN2/PP2BPPP/R2Q1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/1p1nbppp/p1pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/1p1nbppp/p1pp1n2/4p3/2PPP3/2N2N2/PPQ1BPPP/R1B2RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2NP1/PP3P1P/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3P1P/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1b2rk1/ppqnbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2NP1/PP3P1P/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3P1P/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqr1k1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1p1p/3p1np1/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2NP1/PP3P1P/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/2PPP3/2N2NP1/PP3P1P/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqr1k1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/1p1nbppp/p1pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/1p1nbppp/p1pp1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2N1P/PP2BPP1/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PPP3/2N2NP1/PP3P1P/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3P1P/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/1p1nbppp/p1pp1n2/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bq1rk1/1p1nbppp/p1pp1n2/4p3/P1PPP3/2N2NP1/1P3PBP/R1BQ1RK1", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pp1n1ppp/2pp1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 429, "fen":"r1bqkb1r/pp1n1ppp/2pp1n2/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R", "treename":"Old Indian Defense, Normal Variation"},
{"gid": 430, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rn1qkb1r/ppp1pppp/3p1n2/8/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rn1qkb1r/ppp1pppp/3p1n2/8/2PP2b1/1Q3N2/PP2PPPP/RNB1KB1R", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rn1qkb1r/ppp1pppp/3p1n2/8/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 430, "fen":"rn1qkb1r/ppp1pppp/3p1n2/8/2PP2b1/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Tartakower-Indian"},
{"gid": 431, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/ppp2ppp/2np1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/2Pp4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p1B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqk2r/pppnbppp/3p1n2/4p1B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqk2r/pppnbppp/3p1n2/4p1B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p1B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p1B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p1B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1p1p/3p1np1/4p3/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1p1p/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqk2r/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p1B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pp1n1ppp/2pp1n2/4p1B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p1B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqk2r/pppnbppp/3p1n2/4p1B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqk2r/pppnbppp/3p1n2/4p1B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqk2r/pp1nbppp/2pp1n2/4p1B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pp1n1ppp/2pp1n2/4p3/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pp1n1ppp/2pp1n2/4p3/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/8/2PPp3/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/6N1/2PPp3/2N5/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bqk2r/pppnbppp/3p1n2/4p3/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bq1rk1/pppnbppp/3p1n2/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 431, "fen":"r1bq1rk1/pp1nbppp/2pp1n2/4p3/2PP4/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Old Indian Defense, Two Knights Variation"},
{"gid": 432, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4P3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/ppp2ppp/5n2/4p3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbQkb1r/ppp2ppp/5n2/4p3/2P5/2N5/PP2PPPP/R1B1KBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbk1b1r/ppp2ppp/5n2/4p3/2P5/2N5/PP2PPPP/R1B1KBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbk1b1r/ppp2ppp/5n2/4p3/2P5/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbk1b1r/pppn1ppp/8/4p3/2P5/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqkb1r/ppp2ppp/3p1n2/3Pp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 432, "fen":"rnbqk2r/ppp1bppp/3p1n2/3Pp3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Ukrainian Variation"},
{"gid": 428, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"r1bqkb1r/pppnpppp/3p1n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rn1qkb1r/ppp1pppp/3p1n2/5b2/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PPP3/8/PP3PPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/8/PP3PPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"r1bqkb1r/pppnpppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"r1bqkb1r/pppnpppp/3p1n2/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"r1bqkb1r/pppn1ppp/3p1n2/3Pp3/2P1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"r1bqkb1r/pppnpppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"r1bqkb1r/pppnpppp/3p1n2/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N5/PP2NPPP/R1BQKB1R", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"r1bqkb1r/pppnpppp/3p1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Old Indian Defense, Main Line"},
{"gid": 428, "fen":"r1bqkb1r/pppnpppp/3p1n2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Old Indian Defense, Main Line"},
{"gid": 433, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Pterodactyl Defense, Main Line"},
{"gid": 433, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Pterodactyl Defense, Main Line"},
{"gid": 433, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Pterodactyl Defense, Main Line"},
{"gid": 433, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Pterodactyl Defense, Main Line"},
{"gid": 433, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Pterodactyl Defense, Main Line"},
{"gid": 433, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Pterodactyl Defense, Main Line"},
{"gid": 433, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Pterodactyl Defense, Main Line"},
{"gid": 433, "fen":"rnbqk1nr/pp2ppbp/3p2p1/2p5/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Pterodactyl Defense, Main Line"},
{"gid": 433, "fen":"rnbqk1nr/pp2ppbp/3p2p1/2p5/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Pterodactyl Defense, Main Line"},
{"gid": 433, "fen":"rnb1k1nr/pp2ppbp/3p2p1/q1p5/2PPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Pterodactyl Defense, Main Line"},
{"gid": 434, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp1pppp/p7/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp1pppp/p7/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp2ppp/p3p3/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp2ppp/p3p3/3P4/2B3b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pPP3/5N2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/2p1pppp/p7/1p6/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/P1pP4/5N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp2ppp/p3p3/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp2ppp/p3p3/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1p3ppp/p3p3/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1p3ppp/p3p3/2p5/2BP4/4PN2/PP2QPPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp1pppp/p7/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp1pppp/p7/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp2ppp/p3p3/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp2ppp/p3p3/8/2BP2b1/2N1PN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp1pppp/p7/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp1pppp/p7/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp2ppp/p3p3/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 434, "fen":"rn1qkbnr/1pp2ppp/p3p3/8/2BP2b1/4PN1P/PP3PP1/RNBQK2R", "treename":"Queen's Gambit Accepted, Alekhine Defense"},
{"gid": 435, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p4n2/1p6/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p4n2/1p2P3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p1nP3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p1nP3/P1pP4/2N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p2P3/PnpP4/2N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p4n2/1p6/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p4n2/1p2P3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p1nP3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p1nP3/P1pP4/2N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p2P3/P1pP4/2n2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p2P3/P1pP4/2P2N2/5PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnb1kb1r/2p1pppp/p7/1p1qP3/P1pP4/2P2N2/5PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnb1kb1r/2p1pppp/p7/1p1qP3/P1pP4/2P2NP1/5P1P/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rn2kb1r/1bp1pppp/p7/1p1qP3/P1pP4/2P2NP1/5P1P/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p4n2/1p6/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p4n2/1p2P3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p1nP3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p1nP3/P1pP4/2N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p2ppp/p3p3/1p1nP3/P1pP4/2N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p4n2/1p6/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p4n2/1p2P3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p1nP3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p1nP3/P1pP4/2N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rn1qkb1r/1bp1pppp/p7/1p1nP3/P1pP4/2N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p4n2/1p6/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p4n2/1p2P3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p1nP3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p1nP3/P1pP4/2N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p2P3/P1pP4/2n2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rnbqkb1r/2p1pppp/p7/1p2P3/P1pP4/2P2N2/5PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 435, "fen":"rn1qkb1r/1bp1pppp/p7/1p2P3/P1pP4/2P2N2/5PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Bogoljubov Defense"},
{"gid": 436, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/5n2/4P3/2pP4/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/8/3nP3/2pP4/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/8/3nP3/2BP4/8/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/1n6/4P3/2BP4/8/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/1n6/4P3/3P4/3B4/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"r1bqkb1r/ppp1pppp/1nn5/4P3/3P4/3B4/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/5n2/4P3/2pP4/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/8/3nP3/2pP4/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/8/3nP3/2BP4/8/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/1n6/4P3/2BP4/8/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkb1r/ppp1pppp/1n6/4P3/3P4/1B6/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"r1bqkb1r/ppp1pppp/1nn5/4P3/3P4/1B6/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"r1bqkb1r/ppp1pppp/1nn5/4P3/3P4/1B6/PP2NPPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp2ppp/8/4p3/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp2ppp/8/4p3/2pPP3/5N2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqk1nr/ppp2ppp/8/4p3/1bpPP3/5N2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp2ppp/8/4p3/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp2ppp/8/4p3/2pPP3/5N2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp2ppp/8/8/2ppP3/5N2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp2ppp/8/8/2BpP3/5N2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"r1bqkbnr/ppp2ppp/2n5/8/2BpP3/5N2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"r1bqkbnr/ppp1pppp/2n5/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"r1bqkbnr/ppp1pppp/2n5/8/2pPP3/4B3/PP3PPP/RN1QKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"r1bqkbnr/ppp1pppp/2n5/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"r1bqkbnr/ppp1pppp/2n5/8/2pPP3/5N2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/pp2pppp/8/2p5/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 436, "fen":"rnbqkbnr/pp2pppp/8/2p5/2pPP3/5N2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Central Variation"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3PP3/1B3N2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1BR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rq2kb1r/1b1n1ppp/p3pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1BR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/P2P4/1B2PN2/1P2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/2p5/Pp1P4/1B2PN2/1P2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1B2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1BR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r3kb1r/1bqn1ppp/p3pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1BR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/P2P4/1B2PN2/1P2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/P2P4/1B2PN2/1P2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/P2P4/1B2PN2/1P2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/2p5/Pp1P4/1B2PN2/1P2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1BR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r3kb1r/1b1n1ppp/pq2pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1BR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1BR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qk2r/1b1n1ppp/p2bpn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1BR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/8/2Bp4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/5ppp/p1n1pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/5ppp/p1n1pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1B2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqk2r/4bppp/p1n1pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1B2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/5ppp/p1n1pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/5ppp/p1n1pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1B2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r2qkb1r/1b3ppp/p1n1pn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1B2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/3BPN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1p6/3p4/3BPN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1p6/3P4/3B1N2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/3P4/1B2PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/8/3p4/1B2PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/3P4/1B2PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/5ppp/p3pn2/1pp5/3P4/1B2PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/3P4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p1n1ppp/p3pn2/2p5/3P4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/P3PN2/1P3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/3P4/1B2PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2p5/3P4/1B2PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2p5/3P4/1BN1PN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/1P2PN2/P4PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2P5/2B5/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnb1kb1r/1p3ppp/p3pn2/2P5/2B5/4PN2/PP3PPP/RNBq1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnb1kb1r/1p3ppp/p3pn2/2P5/2B5/4PN2/PP3PPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnb1k2r/1p3ppp/p3pn2/2b5/2B5/4PN2/PP3PPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnb1k2r/1p3ppp/p3pn2/2b5/2B5/1P2PN2/P4PPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2P5/2B5/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnb1kb1r/1p3ppp/p3pn2/2P5/2B5/4PN2/PP3PPP/RNBq1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnb1kb1r/1p3ppp/p3pn2/2P5/2B5/4PN2/PP3PPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnb1k2r/1p3ppp/p3pn2/2b5/2B5/4PN2/PP3PPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnb1k2r/1p3ppp/p3pn2/2b5/2B5/4PN2/PP1N1PPP/R1BR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/P1BP4/4PN2/1P3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2p5/P1BP4/4PN2/1P3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2p5/P1BP4/4PN2/1P2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1b1kb1r/1pq2ppp/p1n1pn2/2p5/P1BP4/4PN2/1P2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/P1BP4/4PN2/1P3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2p5/P1BP4/4PN2/1P3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2p5/P1BP4/4PN2/1P2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/8/P1Bp4/4PN2/1P2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/8/P1Bp4/4PN2/1P2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqk2r/1p2bppp/p1n1pn2/8/P1Bp4/4PN2/1P2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqk2r/1p2bppp/p1n1pn2/8/P1BP4/5N2/1P2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bq1rk1/1p2bppp/p1n1pn2/8/P1BP4/5N2/1P2QPPP/RNBR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bq1rk1/1p2bppp/p1n1pn2/8/P1BP4/2N2N2/1P2QPPP/R1BR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bq1rk1/1p2bppp/p1n1p3/3n4/P1BP4/2N2N2/1P2QPPP/R1BR2K1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/P1BP4/4PN2/1P3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2p5/P1BP4/4PN2/1P3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2p5/P1BP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqk2r/1p2bppp/p1n1pn2/2p5/P1BP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqk2r/1p2bppp/p1n1pn2/2p5/P1BP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BPP3/5N2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2p5/2BP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/8/2Bp4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP2QPPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/8/2Bp4/4PN2/PP2QPPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP2QPPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2P5/2B5/4PN2/PP2QPPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqk2r/1p3ppp/p3pn2/2b5/2B5/4PN2/PP2QPPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqk2r/1p3ppp/p3pn2/2b5/2B5/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"r1bqk2r/1p3ppp/p1n1pn2/2b5/2B5/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP2QPPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/1p3ppp/p3pn2/2P5/2B5/4PN2/PP2QPPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqk2r/1p3ppp/p3pn2/2b5/2B5/4PN2/PP2QPPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqk2r/1p3ppp/p3pn2/2b5/2B5/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqk2r/5ppp/p3pn2/1pb5/2B5/4PN2/PP2QPPP/RNB2RK1", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 437, "fen":"rnbqkb1r/pp3ppp/4pn2/2P5/2B5/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Classical Defense"},
{"gid": 438, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Godes Variation"},
{"gid": 438, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Godes Variation"},
{"gid": 438, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Godes Variation"},
{"gid": 438, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Godes Variation"},
{"gid": 438, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Godes Variation"},
{"gid": 438, "fen":"r1bqkbnr/pppnpppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Godes Variation"},
{"gid": 439, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkb1r/pp2pppp/5n2/2p5/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/2p5/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/2p5/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/2p1P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkb1r/pp3ppp/5n2/2pp4/2p1P3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkb1r/pp3ppp/5n2/2ppP3/2p5/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkb1r/pp1n1ppp/8/2ppP3/2p5/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkb1r/pp1n1ppp/8/2ppP1B1/2p5/2N2N2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqk2r/pp1nbppp/8/2ppP1B1/2p5/2N2N2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp2pppp/8/2p5/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp2pppp/8/2pP4/2p5/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/2p5/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/2p5/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp3ppp/8/2pp4/2p5/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp3ppp/8/2pQ4/2p5/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnb1kbnr/pp3ppp/8/2pq4/2p5/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnb1kbnr/pp3ppp/8/2pN4/2p5/5N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnb1k1nr/pp3ppp/3b4/2pN4/2p5/5N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnb1k1nr/pp3ppp/3b4/2pN4/2p5/8/PP1NPPPP/R1B1KB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnb1k2r/pp2nppp/3b4/2pN4/2p5/8/PP1NPPPP/R1B1KB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp2pppp/8/2p5/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp2pppp/8/2p5/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp2pppp/8/8/2pp4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp2pppp/8/8/2Bp4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp2pppp/8/2p5/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp2pppp/8/2p5/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp2pppp/8/2p5/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp2pppp/8/2pP4/2p5/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/2p5/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 439, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/2p1P3/5N2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Gunsberg Defense"},
{"gid": 440, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/4PN1P/PP3PP1/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/7b/2BP4/4PN1P/PP3PP1/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/7b/2BP4/2N1PN1P/PP3PP1/R1BQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"r2qkb1r/pppn1ppp/4pn2/7b/2BP4/2N1PN1P/PP3PP1/R1BQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"r2qkb1r/pppn1ppp/4pn2/7b/2BP4/2N1PN1P/PP3PP1/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"r2qk2r/pppn1ppp/3bpn2/7b/2BP4/2N1PN1P/PP3PP1/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"r2qk2r/pppn1ppp/3bpn2/7b/3P4/2N1PN1P/PP2BPP1/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/1Q2PN2/PP3PPP/RNB1K2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2pP2b1/4PN1P/PP3PP1/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/2N1PN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/4PN1P/PP3PP1/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/7b/2BP4/4PN1P/PP3PP1/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/7b/2BP4/2N1PN1P/PP3PP1/R1BQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/1pp2ppp/p3pn2/7b/2BP4/2N1PN1P/PP3PP1/R1BQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/1pp2ppp/p3pn2/7b/2BP2P1/2N1PN1P/PP3P2/R1BQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp1pppp/5n2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/8/2BP2b1/4PN1P/PP3PP1/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/7b/2BP4/4PN1P/PP3PP1/RNBQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"rn1qkb1r/ppp2ppp/4pn2/7b/2BP4/2N1PN1P/PP3PP1/R1BQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"r2qkb1r/pppn1ppp/4pn2/7b/2BP4/2N1PN1P/PP3PP1/R1BQK2R", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"r2qkb1r/pppn1ppp/4pn2/7b/2BP4/2N1PN1P/PP3PP1/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"r2qk2r/pppn1ppp/3bpn2/7b/2BP4/2N1PN1P/PP3PP1/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 440, "fen":"r2qk2r/pppn1ppp/3bpn2/7b/2BPP3/2N2N1P/PP3PP1/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Janowski-Larsen Variation"},
{"gid": 441, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Linares Variation"},
{"gid": 441, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Linares Variation"},
{"gid": 441, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Linares Variation"},
{"gid": 441, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Linares Variation"},
{"gid": 441, "fen":"rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Linares Variation"},
{"gid": 441, "fen":"rnbqkbnr/pp2pppp/8/2p5/2pPP3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Linares Variation"},
{"gid": 441, "fen":"rnbqkbnr/pp2pppp/8/2pP4/2p1P3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Linares Variation"},
{"gid": 441, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/2p1P3/8/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Linares Variation"},
{"gid": 441, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/2p1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Linares Variation"},
{"gid": 441, "fen":"rnbqkb1r/p3pppp/5n2/1ppP4/2p1P3/2N5/PP3PPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Linares Variation"},
{"gid": 443, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/ppp1pppp/5n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2QP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/2QP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/2QP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/2QP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/5b2/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/5b2/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2qk2r/pp1nbppp/2p1pn2/5b2/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2qk2r/pp1nbppp/2p1pn2/5b2/2QP4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2q1rk1/pp1nbppp/2p1pn2/5b2/2QP4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2q1rk1/pp1nbppp/2p1pn2/5bB1/2QP4/2N2NP1/PP2PPBP/R4RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/ppp1pppp/5n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r1bqkb1r/ppp1pppp/2n2n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r1bqkb1r/ppp1pppp/2n2n2/8/Q1pP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/ppp1pppp/5n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2QP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/2QP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/2QP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/2QP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/5b2/2QP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/5b2/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2qk2r/pp1nbppp/2p1pn2/5b2/2QP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2qk2r/pp1nbppp/2p1pn2/5b2/2QP4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2q1rk1/pp1nbppp/2p1pn2/5b2/2QP4/2N2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2q1rk1/pp1nbppp/2p1pn2/5b2/2QP4/2N1PNP1/PP3PBP/R1B2RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2q1rk1/pp1nbppp/2p1p3/5b2/2QPn3/2N1PNP1/PP3PBP/R1B2RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r2q1rk1/pp1nbppp/2p1p3/5b2/3Pn3/2N1PNP1/PP2QPBP/R1B2RK1", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/ppp1pppp/5n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2QP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/2QP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"rnbqkb1r/ppp1pppp/5n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r1bqkb1r/pppnpppp/5n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r1bqkb1r/pppnpppp/5n2/8/Q1pP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1pP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 443, "fen":"r1bqkb1r/pppn1ppp/4pn2/8/Q1pPP3/2N2N2/PP3PPP/R1B1KB1R", "treename":"Queen's Gambit Accepted, Mannheim Variation"},
{"gid": 444, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/1pp2ppp/p3pn2/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/1pp2ppp/p3pn2/8/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/2p2ppp/p3pn2/1p6/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rn1qkbnr/ppp1pppp/8/8/2pP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/pp2pppp/5n2/2p5/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 444, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Normal Variation"},
{"gid": 445, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp2ppp/8/4p3/2pP4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp2ppp/8/4p3/2BP4/4P3/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp2ppp/8/8/2Bp4/4P3/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp2ppp/8/8/2BP4/8/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkb1r/ppp2ppp/5n2/8/2BP4/8/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp2ppp/4p3/8/2pP4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp2ppp/4p3/8/2BP4/4P3/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2BP4/4P3/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/pp2pppp/8/2p5/2pP4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/pp2pppp/8/2p5/2BP4/4P3/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/pp2pppp/8/8/2Bp4/4P3/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 445, "fen":"rnbqkbnr/pp2pppp/8/8/2BP4/8/PP3PPP/RNBQK1NR", "treename":"Queen's Gambit Accepted, Old Variation"},
{"gid": 446, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp2ppp/4p3/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp2ppp/4p3/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/pp3ppp/4p3/2p5/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp2ppp/4p3/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp2ppp/4p3/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp2ppp/4p3/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 446, "fen":"rnbqkbnr/ppp2ppp/4p3/8/2pPP3/5N2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Rosenthal Variation"},
{"gid": 447, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Showalter Variation"},
{"gid": 447, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Showalter Variation"},
{"gid": 447, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Showalter Variation"},
{"gid": 447, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Showalter Variation"},
{"gid": 447, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Showalter Variation"},
{"gid": 447, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Showalter Variation"},
{"gid": 447, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Showalter Variation"},
{"gid": 447, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Showalter Variation"},
{"gid": 447, "fen":"rnbqkb1r/1pp1pppp/p4n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Showalter Variation"},
{"gid": 447, "fen":"r1bqkb1r/1pp1pppp/p1n2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Showalter Variation"},
{"gid": 447, "fen":"r1bqkb1r/1pp1pppp/p1n2n2/8/P1pPP3/2N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Showalter Variation"},
{"gid": 448, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkb1r/ppp1pp1p/5np1/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkb1r/ppp1pp1p/5np1/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkb1r/ppp1pp1p/5np1/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqkb1r/ppp1pp1p/5np1/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2BP4/4PN2/PP3PPP/RNBQK2R", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbqk2r/ppp1ppbp/5np1/8/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2BP4/4PN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 448, "fen":"rnbq1rk1/ppp1ppbp/5np1/8/2BP4/2N1PN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Accepted, Smyslov Variation"},
{"gid": 449, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Winawer Defense"},
{"gid": 449, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Winawer Defense"},
{"gid": 449, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Winawer Defense"},
{"gid": 449, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Winawer Defense"},
{"gid": 449, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Winawer Defense"},
{"gid": 449, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Winawer Defense"},
{"gid": 449, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Winawer Defense"},
{"gid": 449, "fen":"rn1qkb1r/ppp1pppp/4bn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Winawer Defense"},
{"gid": 449, "fen":"rn1qkb1r/ppp1pppp/4bn2/8/2pP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Winawer Defense"},
{"gid": 449, "fen":"rn1qkb1r/pp2pppp/2p1bn2/8/2pP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Accepted, Winawer Defense"},
{"gid": 442, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/1pp1pppp/p7/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/1pp1pppp/p7/8/P1pP4/2N5/1P2PPPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp2ppp/8/4p3/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp2ppp/4p3/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 442, "fen":"rnbqkbnr/ppp2ppp/4p3/8/2pPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Queen's Gambit Accepted, Main Line"},
{"gid": 450, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Albin Countergambit"},
{"gid": 450, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Albin Countergambit"},
{"gid": 450, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Albin Countergambit"},
{"gid": 450, "fen":"rnbqkbnr/ppp2ppp/8/3pp3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Albin Countergambit"},
{"gid": 450, "fen":"rnbqkbnr/ppp2ppp/8/3pP3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Albin Countergambit"},
{"gid": 450, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Albin Countergambit"},
{"gid": 450, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Albin Countergambit"},
{"gid": 450, "fen":"r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Albin Countergambit"},
{"gid": 450, "fen":"r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Albin Countergambit"},
{"gid": 450, "fen":"r1b1kbnr/ppp1qppp/2n5/4P3/2Pp4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Albin Countergambit"},
{"gid": 451, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp1bpp1/4pB1p/3p4/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp2pp1/4pb1p/3p4/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp2pp1/4pb1p/3p4/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/pp3pp1/2p1pb1p/3p4/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/pp3pp1/2p1pb1p/3p4/2PP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"r1bq1rk1/pp1n1pp1/2p1pb1p/3p4/2PP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"r1bq1rk1/pp1n1pp1/2p1pb1p/3p4/2PP4/2NBPN2/PP3PPP/2RQ1RK1", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"r1bq1rk1/pp1n1pp1/2p1pb1p/8/2pP4/2NBPN2/PP3PPP/2RQ1RK1", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"r1bq1rk1/pp1n1pp1/2p1pb1p/8/2BP4/2N1PN2/PP3PPP/2RQ1RK1", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp1bpp1/4pB1p/3p4/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp2pp1/4pb1p/3p4/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 451, "fen":"rnbq1rk1/ppp2pp1/4pb1p/3p4/2PP4/1QN1PN2/PP3PPP/R3KB1R", "treename":"Queen's Gambit Declined, Anti-Tartakower Variation"},
{"gid": 452, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Barmen Variation"},
{"gid": 452, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Barmen Variation"},
{"gid": 452, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Barmen Variation"},
{"gid": 452, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Barmen Variation"},
{"gid": 452, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Barmen Variation"},
{"gid": 452, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Barmen Variation"},
{"gid": 452, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Barmen Variation"},
{"gid": 452, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Barmen Variation"},
{"gid": 452, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Barmen Variation"},
{"gid": 453, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Been-Koomen Variation"},
{"gid": 453, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Been-Koomen Variation"},
{"gid": 453, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Been-Koomen Variation"},
{"gid": 453, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Been-Koomen Variation"},
{"gid": 453, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Been-Koomen Variation"},
{"gid": 453, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Been-Koomen Variation"},
{"gid": 453, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Been-Koomen Variation"},
{"gid": 453, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Been-Koomen Variation"},
{"gid": 453, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Been-Koomen Variation"},
{"gid": 454, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1pn2/q2p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1pn2/q2p2B1/2PP4/2N1P3/PP1N1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1k2r/pp1n1ppp/2p1pn2/q2p2B1/1bPP4/2N1P3/PP1N1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1k2r/pp1n1ppp/2p1pn2/q2p2B1/1bPP4/2N1P3/PPQN1PPP/R3KB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b2rk1/pp1n1ppp/2p1pn2/q2p2B1/1bPP4/2N1P3/PPQN1PPP/R3KB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b2rk1/pp1n1ppp/2p1pn2/q2p2B1/1bPP4/2N1P3/PPQNBPPP/R3K2R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b2rk1/pp1n1ppp/2p2n2/q2pp1B1/1bPP4/2N1P3/PPQNBPPP/R3K2R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1pn2/q2p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1pn2/q2p2B1/2PP4/2N1P3/PP1N1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1k2r/pp1n1ppp/2p1pn2/q2p2B1/1bPP4/2N1P3/PP1N1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1k2r/pp1n1ppp/2p1pn2/q2p2B1/1bPP4/2N1P3/PPQN1PPP/R3KB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1k2r/pp1n1ppp/2p1pn2/q5B1/1bpP4/2N1P3/PPQN1PPP/R3KB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1pn2/q2p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1pB2/q2p4/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1pn2/q2p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1pn2/q2p2B1/2PP4/2N1P3/PP1N1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1pn2/q5B1/2pP4/2N1P3/PP1N1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1pn2/q2p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1pn2/q2P2B1/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1p3/q2n2B1/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 454, "fen":"r1b1kb1r/pp1n1ppp/2p1p3/q2n2B1/3P4/2N1PN2/PP1Q1PPP/R3KB1R", "treename":"Queen's Gambit Declined, Cambridge Springs Defense"},
{"gid": 455, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/P1N1P3/1P3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 455, "fen":"rnbqkb1r/ppp2pp1/4pn1p/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Capablanca"},
{"gid": 456, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca Variation"},
{"gid": 456, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca Variation"},
{"gid": 456, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca Variation"},
{"gid": 456, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Capablanca Variation"},
{"gid": 456, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Capablanca Variation"},
{"gid": 456, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Capablanca Variation"},
{"gid": 456, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Capablanca Variation"},
{"gid": 456, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Capablanca Variation"},
{"gid": 456, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/4PN2/PP3PPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Capablanca Variation"},
{"gid": 456, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/4PN2/PP3PPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Capablanca Variation"},
{"gid": 456, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/4PN2/PP1N1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Capablanca Variation"},
{"gid": 457, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqk1nr/ppp1bppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqk1nr/ppp1bppp/4p3/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqk1nr/ppp1bppp/8/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqk1nr/ppp1bppp/8/3p4/3P1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqk1nr/pp2bppp/2p5/3p4/3P1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqk1nr/pp2bppp/2p5/3p4/3P1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rn1qk1nr/pp2bppp/2p5/3p1b2/3P1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rn1qk1nr/pp2bppp/2p5/3p1b2/3P1BP1/2N1P3/PP3P1P/R2QKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqk1nr/ppp1bppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqk1nr/ppp1bppp/4p3/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqk1nr/ppp1bppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 457, "fen":"rnbqk1nr/ppp1bppp/4p3/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Charousek (Petrosian) Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2NBP3/PP3PPP/R2QK1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p5/3p2B1/3Pn3/2NBP3/PP3PPP/R2QK1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/ppp1bppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/ppp1bppp/5n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pppnbppp/5n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqkb1r/pp1n1ppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/ppp1bppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/ppp1bppp/5n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbq1rk1/ppp1bppp/5n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/ppp1bppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/ppp1bppp/5n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/ppp1bpp1/5n1p/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/ppp1bppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/ppp1bppp/5n2/3p2B1/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rn1qk2r/pp2bppp/2p2n2/3p1bB1/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rn1qk2r/pp2bppp/2p2n2/3p1bB1/3P4/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2NBP3/PP3PPP/R2QK1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbq1rk1/pp2bppp/2p2n2/3p2B1/3P4/2NBP3/PP3PPP/R2QK1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbq1rk1/pp2bppp/2p2n2/3p2B1/3P4/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqkb1r/pppn1ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqkb1r/pppn1ppp/5n2/3p2B1/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2NBP3/PP3PPP/R2QK1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PP3PPP/R2QK1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PP2NPPP/R2QK2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqkb1r/pppn1ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqkb1r/pppn1ppp/5n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2NBP3/PP3PPP/R2QK1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PP3PPP/R2QK1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p2B1/3P4/2NBPN1P/PPQ2PP1/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bp1p/2p2np1/3p2B1/3P4/2NBPN1P/PPQ2PP1/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqkn1r/pp2bppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/1R3RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/1p2bppp/2p2n2/p2p2B1/3P4/2NBPN2/PPQ2PPP/1R3RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqkb1r/pp3ppp/n1p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbq1rk1/pp2bppp/2p2n2/3p2B1/3P4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bp1p/2p2np1/3p2B1/3P4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bp1p/2p2np1/3p2B1/3P4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/2KR3R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/2KR3R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p2B1/3P4/2NBPN1P/PPQ2PP1/2KR3R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbq1rk1/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbq1rk1/pp2bppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbpp1/2p2n1p/3p2B1/3P4/2NBPN2/PPQ2PPP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbpp1/2p2n1p/3p4/3P3B/2NBPN2/PPQ2PPP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ1NPPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ1NPPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ1NPPP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p2B1/3P4/2NBP3/PPQ1NPPP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p2B1/3P4/2NBPP2/PPQ1N1PP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN1P/PPQ2PP1/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p2B1/3P4/2NBPN1P/PPQ2PP1/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p4/3P1B2/2NBPN1P/PPQ2PP1/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBP3/PPQ2PPP/R3K1NR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bq1rk1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqr1k1/pp1nbppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/R4RK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqrnk1/pp2bppp/2p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/4RRK1", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p2B1/3P4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqkb1r/pppn1ppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqkb1r/pppn1ppp/5n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/ppp1bppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/ppp1bppp/5n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqk2r/pp2bppp/2p2n2/3p4/3P1B2/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"r1bqk2r/pp1nbppp/2p2n2/3p4/3P1B2/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4p3/3n4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 458, "fen":"rnbqkb1r/ppp2ppp/5n2/3p4/3P1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Exchange Variation"},
{"gid": 459, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/p1p1bppp/1p2pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp2bppp/4pn2/2pp4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp2bppp/4pn2/2Pp4/2P2B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp3ppp/4pn2/2bp4/2P2B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp3ppp/4pn2/2bP4/5B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp2bppp/4pn2/2pp4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp2bppp/4pn2/2Pp4/2P2B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp3ppp/4pn2/2bp4/2P2B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp3ppp/4pn2/2bp4/2P2B2/P1N1PN2/1P3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"r1bq1rk1/pppnbppp/4pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp2bppp/4pn2/2pp4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp2bppp/4pn2/2Pp4/2P2B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp3ppp/4pn2/2bp4/2P2B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp3ppp/4pn2/2bp4/2P2B2/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2bp4/2P2B2/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2bp4/2P2B2/P1N1PN2/1PQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"r1b2rk1/pp3ppp/2n1pn2/q1bp4/2P2B2/P1N1PN2/1PQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"r1b2rk1/pp3ppp/2n1pn2/q1bp4/2P2B2/P1N1PN2/1PQ2PPP/2KR1B1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp2bppp/4pn2/2pp4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp2bppp/4pn2/2Pp4/2P2B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp3ppp/4pn2/2bp4/2P2B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"rnbq1rk1/pp3ppp/4pn2/2bp4/2P2B2/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2bp4/2P2B2/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"r1bq1rk1/pp3ppp/2n1pn2/2bp4/2P2B2/P1N1PN2/1PQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"r1b2rk1/pp3ppp/2n1pn2/q1bp4/2P2B2/P1N1PN2/1PQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 459, "fen":"r1b2rk1/pp3ppp/2n1pn2/q1bp4/2P2B2/P1N1PN2/1PQ2PPP/3RKB1R", "treename":"Queen's Gambit Declined, Harrwitz Attack"},
{"gid": 460, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnbqkb1r/pp3pp1/2p1pn1p/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnbqkb1r/pp3pp1/2p1pB1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnb1kb1r/pp3pp1/2p1pq1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 460, "fen":"rnb1kb1r/pp3pp1/2p1pq1p/3p4/2PP4/1QN2N2/PP2PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Hastings Variation"},
{"gid": 461, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Janowski Variation"},
{"gid": 461, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Janowski Variation"},
{"gid": 461, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Janowski Variation"},
{"gid": 461, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Janowski Variation"},
{"gid": 461, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Janowski Variation"},
{"gid": 461, "fen":"rnbqkbnr/1pp2ppp/p3p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Janowski Variation"},
{"gid": 462, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4p2p/3p4/2PPn2B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1Bpp1/4p2p/3p4/2PPn3/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/4p2p/3p4/2PPn3/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/4p2p/3P4/3Pn3/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/4p2p/3P4/3P4/2n1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/4p2p/3P4/3P4/2P1PN2/P4PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/7p/3p4/3P4/2P1PN2/P4PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/7p/3p4/3P4/1QP1PN2/P4PPP/R3KB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbr2k1/ppp1qpp1/7p/3p4/3P4/1QP1PN2/P4PPP/R3KB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4p2p/3p4/2PPn2B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1Bpp1/4p2p/3p4/2PPn3/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/4p2p/3p4/2PPn3/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/4p2p/3p4/2PPn3/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/pp2qpp1/2p1p2p/3p4/2PPn3/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4p2p/3p4/2PPn2B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1Bpp1/4p2p/3p4/2PPn3/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/4p2p/3p4/2PPn3/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/4p2p/3p4/2PPn3/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/4p2p/3p4/2PP4/2n1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqk2r/ppp1bppp/4p3/3p2B1/2PPn3/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1bpp1/4p2p/3p4/2PPn2B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnbq1rk1/ppp1Bpp1/4p2p/3p4/2PPn3/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/4p2p/3p4/2PPn3/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/ppp1qpp1/4p2p/3p4/2PPn3/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/pp2qpp1/2p1p2p/3p4/2PPn3/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 462, "fen":"rnb2rk1/pp2qpp1/2p1p2p/3p4/2PPn3/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Lasker Defense"},
{"gid": 464, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Manhattan Variation"},
{"gid": 464, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Manhattan Variation"},
{"gid": 464, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Manhattan Variation"},
{"gid": 464, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Manhattan Variation"},
{"gid": 464, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Manhattan Variation"},
{"gid": 464, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Manhattan Variation"},
{"gid": 464, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Manhattan Variation"},
{"gid": 464, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Manhattan Variation"},
{"gid": 464, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Manhattan Variation"},
{"gid": 464, "fen":"r1bqk2r/pppn1ppp/4pn2/3p2B1/1bPP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Manhattan Variation"},
{"gid": 465, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 465, "fen":"rnbq1rk1/pp2bppp/4pn2/2pp2B1/2PP4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Miles Variation"},
{"gid": 466, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern"},
{"gid": 466, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern"},
{"gid": 466, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern"},
{"gid": 466, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern"},
{"gid": 466, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern"},
{"gid": 466, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern"},
{"gid": 466, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern"},
{"gid": 466, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern"},
{"gid": 466, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern"},
{"gid": 466, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern"},
{"gid": 466, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1P3/PPQ2PPP/R3KBNR", "treename":"Queen's Gambit Declined, Modern"},
{"gid": 467, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/p1p1bppp/1p2pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/p1p1bppp/1p2pn2/3P2B1/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/p1p1bppp/1p3n2/3p2B1/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/ppp1bppp/4p3/3p2B1/2PPn3/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/p1p1bppp/1p2pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/p1p1bppp/1p2pn2/3P2B1/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbq1rk1/p1p1bppp/1p2p3/3n2B1/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 467, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/2pP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Modern Variation"},
{"gid": 468, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/2RQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1P3/PP3PPP/2RQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1P3/PP3PPP/2RQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1P3/PP3PPP/2RQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3P4/3P3B/2N1P3/PP3PPP/2RQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbq1rk1/p1p1bpp1/1p2p2p/3n4/3P3B/2N1P3/PP3PPP/2RQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbq1rk1/p1p1bpp1/1p2p2p/3N4/3P3B/4P3/PP3PPP/2RQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbq1rk1/p1p1bpp1/1p5p/3p4/3P3B/4P3/PP3PPP/2RQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnbq1rk1/p1p1Bpp1/1p5p/3p4/3P4/4P3/PP3PPP/2RQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnb2rk1/p1p1qpp1/1p5p/3p4/3P4/4P3/PP3PPP/2RQKBNR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 468, "fen":"rnb2rk1/p1p1qpp1/1p5p/3p4/3P4/4P3/PP2BPPP/2RQK1NR", "treename":"Queen's Gambit Declined, Neo-Orthodox Variation"},
{"gid": 469, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Normal Defense"},
{"gid": 469, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Normal Defense"},
{"gid": 469, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Normal Defense"},
{"gid": 469, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Normal Defense"},
{"gid": 469, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Normal Defense"},
{"gid": 469, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Normal Defense"},
{"gid": 469, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Normal Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/6B1/2pP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/6B1/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1p3/3n2B1/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nBppp/2p1p3/3n4/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p1p3/3n4/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p1p3/3n4/2BPN3/4PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p5/3np3/2BPN3/4PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/6B1/2pP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/6B1/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1p3/3n2B1/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nBppp/2p1p3/3n4/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p1p3/3n4/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p1p3/3n4/2BPN3/4PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p1pn2/8/2BPN3/4PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqr1k1/pp1nbppp/2p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p1p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbpp1/2p1pn1p/3p2B1/2PP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbpp1/2p1pn1p/3p4/2PP3B/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbpp1/2p1pn1p/8/2pP3B/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbpp1/2p1pn1p/8/2BP3B/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/p2nbpp1/2p1pn1p/1p6/2BP3B/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/6B1/2pP4/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/6B1/2BP4/2N1PN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4pn2/2p3B1/2BP4/2N1PN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4pn2/2p3B1/2BP4/2N1PN2/PP3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p3pn2/2p3B1/2BP4/2N1PN2/PP3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/6B1/2pP4/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/6B1/2BP4/2N1PN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p3pn2/6B1/2BP4/2N1PN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/6B1/2pP4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/6B1/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1p3/3n2B1/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nBppp/2p1p3/3n4/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p1p3/3n4/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p1p3/3n4/2BP4/2N1PN2/PP3PPP/2RQ1RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p1p3/8/2BP4/2n1PN2/PP3PPP/2RQ1RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p1p3/8/2BP4/2R1PN2/PP3PPP/3Q1RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p5/4p3/2BP4/2R1PN2/PP3PPP/3Q1RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/2p5/4p3/3P4/1BR1PN2/PP3PPP/3Q1RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p3pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p3pn2/2Pp2B1/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p1p1pn2/2Pp2B1/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p3pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p3pn2/3p2B1/2PP4/P1N1PN2/1P3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqr1k1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/6B1/2pP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/6B1/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p3pn2/6B1/2BP4/2N1PN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/P1N1PN2/1P3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/p1pnbppp/1p2pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/p1pnbppp/1p2pn2/3P2B1/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/p1pnbppp/1p3n2/3p2B1/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/p1pnbppp/1p3n2/3p2B1/Q2P4/2N1PN2/PP3PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/1QN1PN2/PP3PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/1QN1PN2/PP3PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbpp1/2p1pn1p/3p2B1/2PP4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqr1k1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqr1k1/pp1nbppp/2p1pn2/3p2B1/2PP4/P1N1PN2/1PQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqr1k1/1p1nbppp/p1p1pn2/3p2B1/2PP4/P1N1PN2/1PQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p1p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p1p1pn2/3P2B1/3P4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p1p2n2/3p2B1/3P4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p1p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/2R1K2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqr1k1/1p1nbppp/p1p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/2R1K2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqr1k1/1p1nbppp/p1p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/2R2RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqrnk1/1p2bppp/p1p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/2R2RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqrnk1/1p2bppp/p1p2n2/3pN1B1/3P4/2NBP3/PPQ2PPP/2R2RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p1p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p1p1pn2/3P2B1/3P4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p1p2n2/3p2B1/3P4/2N1PN2/PPQ2PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p1p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/2R1K2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqr1k1/1p1nbppp/p1p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/2R1K2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqr1k1/1p1nbppp/p1p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/2R2RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqrnk1/1p2bppp/p1p2n2/3p2B1/3P4/2NBPN2/PPQ2PPP/2R2RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqrnk1/1p2bppp/p1p2n2/3p2B1/3P4/2NBPN1P/PPQ2PP1/2R2RK1", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4pn2/2pp2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4pn2/2pP2B1/3P4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4p3/2pn2B1/3P4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nBppp/4p3/2pn4/3P4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/4p3/2pn4/3P4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/4p3/2pN4/3P4/4PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/8/2pp4/3P4/4PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqppp/8/2pp4/3P4/3BPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1b2rk1/pp1nqp1p/6p1/2pp4/3P4/3BPN2/PPQ2PPP/R3K2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4pn2/2pp2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4pn2/2pP2B1/3P4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4pn2/3P2B1/3p4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4pn2/2pp2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4pn2/2pp2B1/2PP4/2N1PN2/PPQ2PPP/2KR1B1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbpp1/4pn1p/3p2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbpp1/4pn1p/3p4/2PP3B/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbpp1/4pn1p/2pp4/2PP3B/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/3RKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4pn2/2pp2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/4pn2/2pp2B1/2PP4/2N1PN2/PPQ2PPP/3RKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/P1N1PN2/1PQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p3pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p3pn2/3P2B1/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p4n2/3p2B1/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p4n2/3p2B1/3P4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1p1nbppp/p1p2n2/3p2B1/3P4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p3pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p3pn2/3P2B1/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p4n2/3p2B1/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/1ppnbppp/p4n2/3p2B1/3P4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bqr1k1/1ppnbppp/p4n2/3p2B1/3P4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4pn2/3P2B1/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 470, "fen":"r1bq1rk1/pppnbppp/4p3/3n2B1/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Orthodox Defense"},
{"gid": 471, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnb1kb1r/pp3ppp/1q2pn2/2pP2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/pp3ppp/4pn2/3p2B1/2Pp4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/pp3ppp/4pn2/3p2B1/2PQ4/2N2N2/PP2PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/pp3ppp/4pn2/3P2B1/3p4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqkb1r/pp3ppp/4pn2/3P2B1/3Q4/2N5/PP2PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqk2r/pp2bppp/4pn2/3P2B1/3Q4/2N5/PP2PPPP/R3KBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"rnbqk2r/pp2bppp/4pn2/3P2B1/3QP3/2N5/PP3PPP/R3KBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"r1bqk2r/pp2bppp/2n1pn2/3P2B1/3QP3/2N5/PP3PPP/R3KBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 471, "fen":"r1bqk2r/pp2bppp/2n1pn2/3P2B1/4P3/2N5/PP1Q1PPP/R3KBNR", "treename":"Queen's Gambit Declined, Pseudo-Tarrasch Variation"},
{"gid": 472, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"r1bqkbnr/ppp2ppp/2n1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1bPP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 472, "fen":"rnbqk1nr/ppp2ppp/4p3/3p4/1bPP4/P1N5/1P2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Queen's Knight Variation"},
{"gid": 473, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/QbPP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/ppp2ppp/2n1pn2/3p4/QbPP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/ppp2ppp/2n1pn2/3P4/Qb1P4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/ppp2ppp/2n2n2/3p4/Qb1P4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/ppp2ppp/2n2n2/3p2B1/Qb1P4/2N2N2/PP2PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/6B1/1bpP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/6B1/QbpP4/2N2N2/PP2PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/6B1/1bpP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/6B1/1bpPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/2p3B1/1bpPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/2p1P1B1/1bpP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/4P1B1/1bpp4/2N2N2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/4P1B1/Qbpp4/2N2N2/PP3PPP/R3KB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/pp3ppp/2n1pn2/4P1B1/Qbpp4/2N2N2/PP3PPP/R3KB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/pp3ppp/2n1pn2/4P1B1/Qbpp4/2N2N2/PP3PPP/2KR1B1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/6B1/1bpP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/6B1/1bpPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/2p3B1/1bpPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/2p3B1/1bBPP3/2N2N2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/6B1/1bBpP3/2N2N2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/6B1/1bBNP3/2N5/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnb1k2r/pp3ppp/4pn2/q5B1/1bBNP3/2N5/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/6B1/1bpP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/6B1/1bpPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/2p3B1/1bpPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/2p3B1/1bBPP3/2N2N2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/6B1/1bBpP3/2N2N2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/6B1/1bBNP3/2N5/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/6B1/2BNP3/2b5/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/pp3ppp/4pn2/6B1/2BNP3/2P5/P4PPP/R2QK2R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnb1k2r/pp3ppp/4pn2/q5B1/2BNP3/2P5/P4PPP/R2QK2R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnb1k2r/pp3ppp/4pn2/qB4B1/3NP3/2P5/P4PPP/R2QK2R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2pp1/4pn1p/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2pp1/4pB1p/3p4/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnb1k2r/ppp2pp1/4pq1p/3p4/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnb1k2r/ppp2pp1/4pq1p/3P4/1b1P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnb1k2r/ppp2pp1/5q1p/3p4/1b1P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2pp1/4pn1p/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2pp1/4pB1p/3p4/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnb1k2r/ppp2pp1/4pq1p/3p4/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnb1k2r/ppp2pp1/4pq1p/3p4/1bPP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnb2rk1/ppp2pp1/4pq1p/3p4/1bPP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnb2rk1/ppp2pp1/4pq1p/3p4/1bPP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3P4/1b1P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/5n2/3p4/1b1P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/5n2/3p2B1/1b1P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2pp1/5n1p/3p2B1/1b1P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2pp1/5n1p/3p4/1b1P3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/pppn1ppp/4pn2/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/pppn1ppp/4pn2/3P2B1/1b1P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/pppn1ppp/5n2/3p2B1/1b1P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/pppn1ppp/5n2/3p2B1/1b1P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/pppn1ppp/4pn2/3p2B1/1bPP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/pppn1ppp/4pn2/3P2B1/1b1P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/pppn1ppp/5n2/3p2B1/1b1P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 473, "fen":"r1bqk2r/pppn1ppp/5n2/3p2B1/1b1P4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Ragozin Defense"},
{"gid": 474, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/PbpP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/PbpP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/p4ppp/2p1p3/1p6/PbpP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/p4ppp/2p1p3/1p6/PbpP4/2N1PN2/1P1B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/5ppp/2p1p3/pp6/PbpP4/2N1PN2/1P1B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/5ppp/2p1p3/pP6/1bpP4/2N1PN2/1P1B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/5ppp/2p1p3/pP6/2pP4/2b1PN2/1P1B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/5ppp/2p1p3/pP6/2pP4/2B1PN2/1P3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/5ppp/4p3/pp6/2pP4/2B1PN2/1P3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/5ppp/4p3/pp6/2pP4/1PB1PN2/5PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rn1qk1nr/1b3ppp/4p3/pp6/2pP4/1PB1PN2/5PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rn1qk1nr/1b3ppp/4p3/pp6/2PP4/2B1PN2/5PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rn1qk1nr/1b3ppp/4p3/p7/1pPP4/2B1PN2/5PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rn1qk1nr/1b3ppp/4p3/p7/1pPP4/4PN2/1B3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rn1qk2r/1b3ppp/4pn2/p7/1pPP4/4PN2/1B3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rn1qk2r/1b3ppp/4pn2/p7/1pPP4/3BPN2/1B3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"r2qk2r/1b1n1ppp/4pn2/p7/1pPP4/3BPN2/1B3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"r2qk2r/1b1n1ppp/4pn2/p7/1pPP4/3BPN2/1B3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"r2q1rk1/1b1n1ppp/4pn2/p7/1pPP4/3BPN2/1B3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/PbpP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/PbpP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/p4ppp/2p1p3/1p6/PbpP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/p4ppp/2p1p3/1p6/PbpP4/2N1PN2/1P1B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnb1k1nr/p4ppp/1qp1p3/1p6/PbpP4/2N1PN2/1P1B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/PbpP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/PbpP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/p4ppp/2p1p3/1p6/PbpP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnbqk1nr/p4ppp/2p1p3/1p6/PbpP4/2N1PN2/1P1B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 474, "fen":"rnb1k1nr/p3qppp/2p1p3/1p6/PbpP4/2N1PN2/1P1B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Slav"},
{"gid": 475, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3PP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2p5/3PP3/2n2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/8/3pP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/8/3PP3/5N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqk2r/pp3ppp/4p3/8/1b1PP3/5N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqk2r/pp3ppp/4p3/8/1b1PP3/5N2/P2B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqk2r/pp3ppp/4p3/8/3PP3/5N2/P2b1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqk2r/pp3ppp/4p3/8/3PP3/5N2/P2Q1PPP/R3KB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbq1rk1/pp3ppp/4p3/8/3PP3/5N2/P2Q1PPP/R3KB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbq1rk1/pp3ppp/4p3/1B6/3PP3/5N2/P2Q1PPP/R3K2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3PP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2p5/3PP3/2n2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/8/3pP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/8/3PP3/5N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqk2r/pp3ppp/4p3/8/1b1PP3/5N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqk2r/pp3ppp/4p3/8/1b1PP3/5N2/P2B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnb1k2r/pp3ppp/4p3/q7/1b1PP3/5N2/P2B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqk2r/pp2bppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqk2r/pp2bppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp2bppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp2bppp/2n1p3/2pn4/3PP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp2bppp/1nn1p3/2p5/3PP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqk2r/pp2bppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqk2r/pp2bppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp2bppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp2bppp/2n1p3/2pn4/3PP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp2bppp/2n1p3/2p5/1n1PP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/3P4/3p4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqk2r/pp2bppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bqk2r/pp2bppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp2bppp/2n1p3/2pn4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp2bppp/2n1p3/2pN4/3P4/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp2bppp/2n5/2pp4/3P4/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp2bppp/2n5/2Pp4/8/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp3ppp/2n5/2bp4/8/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 475, "fen":"r1bq1rk1/pp3ppp/2n5/2bp2B1/8/5NP1/PP2PPBP/R2Q1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3PP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2p5/3PP3/2n2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/8/3pP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/8/3PP3/5N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/8/3PP3/5N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3PP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2p5/3PP3/2n2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2p5/3PP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/8/3pP3/2P2N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/8/3PP3/5N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqk2r/pp3ppp/4p3/8/1b1PP3/5N2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqk2r/pp3ppp/4p3/8/1b1PP3/5N2/P2B1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqk2r/pp3ppp/4p3/8/3PP3/5N2/P2b1PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqk2r/pp3ppp/4p3/8/3PP3/5N2/P2Q1PPP/R3KB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbq1rk1/pp3ppp/4p3/8/3PP3/5N2/P2Q1PPP/R3KB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbq1rk1/pp3ppp/4p3/8/2BPP3/5N2/P2Q1PPP/R3K2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp3ppp/2n1p3/8/2BPP3/5N2/P2Q1PPP/R3K2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp3ppp/2n1p3/8/2BPP3/5N2/P2Q1PPP/R4RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p4ppp/1pn1p3/8/2BPP3/5N2/P2Q1PPP/R4RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p4ppp/1pn1p3/8/2BPP3/5N2/P2Q1PPP/3R1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3p4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/4p3/3n4/1n1P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3p4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/P1NB1N2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/3P4/P1NB1N2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3p4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/P1NB1N2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp3ppp/2n1pb2/3n4/3P4/P1NB1N2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3p4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/3P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/3P4/P1NB1N2/1P3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p3bppp/1pn1pn2/8/3P4/P1NB1N2/1P3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p3bppp/1pn1pn2/8/3P4/P1N2N2/1PB2PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3p4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/3P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/3P4/P1NB1N2/1P3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p3bppp/1pn1pn2/8/3P4/P1NB1N2/1P3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p3bppp/1pn1pn2/8/3P4/P1NBBN2/1P3PPP/R2QR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3p4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp3ppp/2n1pb2/3n4/3P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp3ppp/2n1pb2/3n4/3PB3/2N2N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2nppp/4pb2/3n4/3PB3/2N2N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2nppp/4pb2/3nN3/3PB3/2N5/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3p4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/3P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1pn2/6B1/3P4/2NB1N2/PP3PPP/R2QR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3p4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/3P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/3P4/2NB1N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1pn2/8/3P4/P1NB1N2/1P3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p3bppp/1pn1pn2/8/3P4/P1NB1N2/1P3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p3bppp/1pn1pn2/6B1/3P4/P1NB1N2/1P3PPP/R2QR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/3n4/3p4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/3n4/3P4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/2Bp4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/2BP4/2N2N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/2BP4/2N2N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/2BP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/2BP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/2BP4/2N2N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/8/2BP4/2n2N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/8/2BP4/2P2N2/P4PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p3bppp/1pn1p3/8/2BP4/2P2N2/P4PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p3bppp/1pn1p3/8/3P4/2PB1N2/P4PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r2q1rk1/pb2bppp/1pn1p3/8/3P4/2PB1N2/P4PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r2q1rk1/pb2bppp/1pn1p3/8/3P4/2PB1N2/P1Q2PPP/R1B1R1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pn4/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/2Bp4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqkb1r/pp3ppp/2n1p3/3n4/2BP4/2N2N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/2BP4/2N2N2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bqk2r/pp2bppp/2n1p3/3n4/2BP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/2BP4/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/3n4/2BP4/2N2N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/8/2BP4/2n2N2/PP3PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/pp2bppp/2n1p3/8/2BP4/2P2N2/P4PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p3bppp/1pn1p3/8/2BP4/2P2N2/P4PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r1bq1rk1/p3bppp/1pn1p3/8/3P4/2PB1N2/P4PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r2q1rk1/pb2bppp/1pn1p3/8/3P4/2PB1N2/P4PPP/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"r2q1rk1/pb2bppp/1pn1p3/8/3P3P/2PB1N2/P4PP1/R1BQR1K1", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/1p3ppp/p3pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/1p3ppp/p3pn2/2pP4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/1p3ppp/p4n2/2pp4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqk2r/pp2bppp/4pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 476, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/2Pp4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semi-Tarrasch Defense"},
{"gid": 477, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 477, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 477, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 477, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 477, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 477, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 477, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 477, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 477, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/4PN2/PP1N1PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 477, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/4PN2/PP1N1PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 477, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/3BPN2/PP1N1PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 477, "fen":"r1bqkb1r/pp1n1ppp/4pn2/2pp4/2PP4/3BPN2/PP1N1PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Semmering Variation"},
{"gid": 478, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/3P1b2/3P4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/5n2/3p1b2/3P4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/5n2/3p1b2/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/4pn2/3p1b2/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/4pn2/3pNb2/3P4/2N1P3/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp1n1ppp/4p3/3pNb2/3P4/2N1P3/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1p3/5b2/PbBPn3/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1p3/5b2/PbBPn1P1/2N1PN2/1P2QP1P/R1B2RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qk2r/pp1n1ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qk2r/pp1n1ppp/2p1pn2/5b2/PbBP3N/2N1P3/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qk2r/pp1n1ppp/2p1pnb1/8/PbBP3N/2N1P3/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP3N/2N1P3/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pnb1/8/PbBP3N/2N1P3/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP3N/2N1P3/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pn2/8/PbBP2bN/2N1P3/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qk2r/pp1n1ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qk2r/pp1n1ppp/2p1pn2/5b2/PbBP4/1QN1PN2/1P3PPP/R1B2RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qk2r/1p1n1ppp/2p1pn2/p4b2/PbBP4/1QN1PN2/1P3PPP/R1B2RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/1QN1PN2/1P3PPP/R1B2RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP3N/2N1P3/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/5b2/PbBP3N/2N1P3/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/5N2/PbBP4/2N1P3/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2q1rk1/pp1n1ppp/2p2n2/5p2/PbBP4/2N1P3/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2q1rk1/pp1n1ppp/2p2n2/5p2/PbBP4/2N1P3/1PQ2PPP/R1B2RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qk2r/pp1n1ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qk2r/pp1n1ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qk2r/pp1n1ppp/2p1pnb1/8/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qk2r/pp1n1ppp/2p1pnb1/8/PbBPP3/2N2N2/1P2QPPP/R1B2RK1", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r1bqkb1r/pp2pppp/n1p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r1bqkb1r/pp2pppp/n1p2n2/8/P1pPP3/2N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qkb1r/pp2pppp/n1p2n2/8/P1pPP1b1/2N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qkb1r/pp2pppp/n1p2n2/8/P1BPP1b1/2N2N2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 478, "fen":"r2qkb1r/pp3ppp/n1p1pn2/8/P1BPP1b1/2N2N2/1P3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Slav"},
{"gid": 479, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkb1r/pp3ppp/5n2/2pp4/3P4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkb1r/pp3ppp/5n2/2pp4/3P4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 479, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N3P1/PP2PP1P/R1BQKBNR", "treename":"Queen's Gambit Declined, Tarrasch Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3P4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2p2p/3n4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1Bpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn3rk1/pbp1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn3rk1/pbp1qpp1/1p2p2p/3n4/3P4/2NBPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3P4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2p2p/3n4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2p2p/3N4/3P3B/4PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3P4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2p2p/3n4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1Bpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3N4/3P4/4PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnb2rk1/p1p1qpp1/1p5p/3p4/3P4/4PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnb2rk1/p1p1qpp1/1p5p/3p4/3P4/4PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3P4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2p2p/3n4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1Bpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn3rk1/pbp1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn3rk1/pbp1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP2BPPP/2RQK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3P4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2p2p/3n4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1Bpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn3rk1/pbp1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn3rk1/pbp1qpp1/1p2p2p/3N4/3P4/4PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn3rk1/p1p1qpp1/1p2p2p/3b4/3P4/4PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3P4/3P3B/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p3n1p/3p4/3P3B/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pB1p/3p4/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p2pb1p/3p4/2PP4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p2pb1p/3P4/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p3b1p/3p4/3P4/2N1PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pB1p/3p4/2PP4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p2pb1p/3p4/2PP4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p2pb1p/3P4/3P4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p3b1p/3p4/3P4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p3b1p/3p4/1P1P4/2N1PN2/P3BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pb3pp1/1p3b1p/2pp4/1P1P4/2N1PN2/P3BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pbpnbpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pbpnbpp1/1p2pn1p/3p4/2PP4/2NBPNB1/PP3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pbpnbpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pbpnbpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/2RQ1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pb1nbpp1/1p2pn1p/2pp4/2PP3B/2NBPN2/PP3PPP/2RQ1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pbpnbpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pbpnbpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP2QPPP/R4RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pb1nbpp1/1p2pn1p/2pp4/2PP3B/2NBPN2/PP2QPPP/R4RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pb1nbpp1/1p2pn1p/2pp4/2PP4/2NBPNB1/PP2QPPP/R4RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pB1p/3p4/2PP4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p2pb1p/3p4/2PP4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p2pb1p/3P4/3P4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p3b1p/3p4/3P4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p3b1p/3p4/1P1P4/2N1PN2/P3BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pb3pp1/1pp2b1p/3p4/1P1P4/2N1PN2/P3BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pb3pp1/1pp2b1p/3p4/1P1P4/2N1PN2/P3BPPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PPQ2PPP/R3KB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pB1p/3p4/2PP4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p2pb1p/3p4/2PP4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p2pb1p/3P4/3P4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p3b1p/3p4/3P4/2N1PN2/PP2BPPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp2pp1/1p3b1p/3p4/3P4/2N1PN2/PP2BPPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pB1p/3p4/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rn1q1rk1/pbp1bpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pbpnbpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP3PPP/R2Q1RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pbpnbpp1/1p2pn1p/3p4/2PP3B/2NBPN2/PP2QPPP/R4RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pb1nbpp1/1p2pn1p/2pp4/2PP3B/2NBPN2/PP2QPPP/R4RK1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"r2q1rk1/pb1nbpp1/1p2pn1p/2pp4/2PP3B/2NBPN2/PP2QPPP/R2R2K1", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 480, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/1QN1PN2/PP3PPP/R3KB1R", "treename":"Queen's Gambit Declined, Tartakower Defense"},
{"gid": 481, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3P4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1bpp1/1p3n1p/3p4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1bpp1/1p3n1p/3p4/3P3B/2NBPN2/PP3PPP/R2QK2R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3P4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1bpp1/1p2p2p/3n4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1Bpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3N4/3P4/4PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnb2rk1/p1p1qpp1/1p5p/3p4/3P4/4PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnb2rk1/p1p1qpp1/1p5p/3p4/3P4/4PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rn3rk1/p1p1qpp1/1p2b2p/3p4/3P4/4PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rn3rk1/p1p1qpp1/1p2b2p/3p4/3P4/3BPN2/PP3PPP/2RQK2R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1bpp1/1p2pn1p/3P4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1bpp1/1p2p2p/3n4/3P3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnbq1rk1/p1p1Bpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3n4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnb2rk1/p1p1qpp1/1p2p2p/3N4/3P4/4PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnb2rk1/p1p1qpp1/1p5p/3p4/3P4/4PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rnb2rk1/p1p1qpp1/1p5p/3p4/3P4/4PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rn3rk1/p1p1qpp1/1p2b2p/3p4/3P4/4PN2/PP3PPP/2RQKB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rn3rk1/p1p1qpp1/1p2b2p/3p4/Q2P4/4PN2/PP3PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 481, "fen":"rn3rk1/p3qpp1/1p2b2p/2pp4/Q2P4/4PN2/PP3PPP/2R1KB1R", "treename":"Queen's Gambit Declined, Tartakower Variation"},
{"gid": 482, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqk2r/ppp1bppp/4pn2/3P4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqk2r/ppp1bppp/4p3/3n4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 482, "fen":"rnbq1rk1/ppp1bppp/4pn2/3p4/2PP4/1PN1PN2/P4PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Three Knights Variation"},
{"gid": 483, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/2pP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 483, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/4PN2/PP3PPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Traditional Variation"},
{"gid": 484, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp2ppp/4pn2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 484, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Vienna Variation"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"r1bqkbnr/pppn1ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3P2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4p3/3n2B1/3P4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/5N2/PPQ1PPPP/RNB1KB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/5N2/PPQ1PPPP/RNB1KB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/5N2/PPQ1PPPP/RN2KB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bpp1/4pn1p/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bpp1/4pn1p/3p4/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"r1bqk2r/pppnbppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"r1bqk2r/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/8/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bpp1/4pn1p/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bpp1/4pn1p/3p4/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N2N2/PP2PPPP/2RQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bpp1/4pn1p/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp1bpp1/4pB1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp2pp1/4pb1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqk2r/ppp2pp1/4pb1p/3p4/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/5N2/PPQ1PPPP/RNB1KB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/5N2/PPQ1PPPP/RNB1KB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 463, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/5NP1/PPQ1PP1P/RNB1KB1R", "treename":"Queen's Gambit Declined, Main Line"},
{"gid": 485, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/3pp3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/3pP3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r2qkbnr/ppp2ppp/2n1b3/4P3/2Pp4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r2qkbnr/ppp2ppp/2n1b3/4P3/2Pp4/5NP1/PP1NPP1P/R1BQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r3kbnr/pppq1ppp/2n1b3/4P3/2Pp4/5NP1/PP1NPP1P/R1BQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r3kbnr/pppq1ppp/2n1b3/4P3/2Pp4/5NP1/PP1NPPBP/R1BQK2R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"2kr1bnr/pppq1ppp/2n1b3/4P3/2Pp4/5NP1/PP1NPPBP/R1BQK2R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/3pp3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/3pP3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r2qkbnr/ppp2ppp/2n1b3/4P3/2Pp4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r2qkbnr/ppp2ppp/2n1b3/4P3/2Pp4/5NP1/PP1NPP1P/R1BQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r3kbnr/pppq1ppp/2n1b3/4P3/2Pp4/5NP1/PP1NPP1P/R1BQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r3kbnr/pppq1ppp/2n1b3/4P3/2Pp4/5NP1/PP1NPPBP/R1BQK2R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r3kb1r/pppqnppp/2n1b3/4P3/2Pp4/5NP1/PP1NPPBP/R1BQK2R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/3pp3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/3pP3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r1bqkb1r/ppp1nppp/2n5/4P3/2Pp4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/3pp3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/3pP3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"r2qkbnr/ppp2ppp/2n1b3/4P3/2Pp4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/3pp3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/3pP3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 485, "fen":"rnbqkbnr/ppp2ppp/8/4P3/2Pp4/P7/1P2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Albin Countergambit"},
{"gid": 486, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkbnr/pp2pppp/8/2pp4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkbnr/pp2pppp/8/2pP4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkb1r/pp2pppp/5n2/3P4/3p4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkbnr/pp2pppp/8/2pp4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkbnr/pp2pppp/8/2pP4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkb1r/pp2pppp/5n2/2pP4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 486, "fen":"rnbqkb1r/pp2pppp/5n2/2PP4/8/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Austrian Defense"},
{"gid": 487, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp2ppp/4p3/3p1b2/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp2ppp/4p3/3p1b2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkb1r/ppp2ppp/4pn2/3p1b2/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp2ppp/4p3/3p1b2/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp2ppp/4p3/3p1b2/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp2ppp/4p3/3p1b2/2PP4/1Q3N2/PP2PPPP/RNB1KB1R", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp1pppp/8/3P1b2/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp1pppp/8/3P4/3P4/8/PP2PPPP/RbBQKBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/ppp1pppp/8/3P4/Q2P4/8/PP2PPPP/RbB1KBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/pp2pppp/2p5/3P4/Q2P4/8/PP2PPPP/RbB1KBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 487, "fen":"rn1qkbnr/pp2pppp/2p5/3P4/Q2P4/8/PP2PPPP/1RB1KBNR", "treename":"Queen's Gambit Refused, Baltic Defense"},
{"gid": 488, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3P4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1kbnr/ppp1pppp/2n5/3q4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1kbnr/ppp1pppp/2n5/3q4/3P4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1kbnr/ppp2ppp/2n5/3qp3/3P4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1kbnr/ppp2ppp/2n5/3qp3/3P4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1k1nr/ppp2ppp/2n5/3qp3/1b1P4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1k1nr/ppp2ppp/2n5/3qp3/1b1P4/2N1P3/PP1B1PPP/R2QKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1k1nr/ppp2ppp/2n5/3qp3/3P4/2b1P3/PP1B1PPP/R2QKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1k1nr/ppp2ppp/2n5/3qp3/3P4/2B1P3/PP3PPP/R2QKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3P4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1kbnr/ppp1pppp/2n5/3q4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1kbnr/ppp1pppp/2n5/3q4/3P4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1kbnr/ppp2ppp/2n5/3qp3/3P4/4P3/PP3PPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1kbnr/ppp2ppp/2n5/3qp3/3P4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1k1nr/ppp2ppp/2n5/3qp3/1b1P4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1k1nr/ppp2ppp/2n5/3qp3/1b1P4/2N1P3/PP1B1PPP/R2QKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1k1nr/ppp2ppp/2n5/3qp3/3P4/2b1P3/PP1B1PPP/R2QKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1b1k1nr/ppp2ppp/2n5/3qp3/3P4/2P1P3/P2B1PPP/R2QKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp2ppp/2n5/3pp3/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2n5/3p4/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2n5/3P4/3P2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2n5/3P4/3P4/5b2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2P5/8/3P4/5b2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2n5/3p4/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2n5/3p4/2PP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2n5/3p4/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2n5/3p4/2PP2b1/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2n5/3p4/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2n5/3P4/3P2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2n5/3P4/3P4/5b2/PP2PPPP/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkbnr/ppp1pppp/2n5/3P4/3P4/5P2/PP2PP1P/RNBQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkb1r/ppp1pppp/2n2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkb1r/ppp1pppp/2n2n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkb1r/ppp1pppp/2n2n2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkb1r/ppp1pppp/2n2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r2qkb1r/ppp1pppp/2n2n2/3p4/2PP2b1/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 488, "fen":"r1bqkbnr/ppp1pppp/2n5/3P4/2p5/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Gambit Refused, Chigorin Defense"},
{"gid": 489, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2pP4/2P5/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rn1qkb1r/pb1p1ppp/1p3n2/2pp4/2P5/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 489, "fen":"rn1qkb1r/pb1p1ppp/1p3n2/2pp2N1/2P5/6P1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian, Buerger Variation"},
{"gid": 490, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Accelerated, Main Line"},
{"gid": 490, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Accelerated, Main Line"},
{"gid": 490, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Accelerated, Main Line"},
{"gid": 490, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Accelerated, Main Line"},
{"gid": 490, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Indian Accelerated, Main Line"},
{"gid": 490, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Queen's Indian Accelerated, Main Line"},
{"gid": 490, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/2PP4/2N5/PPQ1PPPP/R1B1KBNR", "treename":"Queen's Indian Accelerated, Main Line"},
{"gid": 491, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PPQ1PPBP/R1B1K2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/2p5/2PP4/2N2NP1/PPQ1PPBP/R1B1K2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PPQ1PPBP/R1B1K2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/2PP4/2N2NP1/PPQ1PPBP/R1B1K2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qk2r/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 491, "fen":"rn1qk2r/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PPQ1PPBP/R1B1K2R", "treename":"Queen's Indian Defense, Anti-Queen's Indian System"},
{"gid": 492, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pb1p1ppp/1p2pn2/2p5/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP1NPPBP/R1BQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP1NPPBP/R1BQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1q1rk1/pbp2ppp/1p2pn2/3p4/1bPP4/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP1bPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP1NPPBP/R2QK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP1bPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP1QPPBP/RN2K2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP1QPPBP/RN2K2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/8/2PP4/2N2NP1/PP1QPPBP/R3K2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP1bPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP1QPPBP/RN2K2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP1QPPBP/RN2K2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP1QPPBP/RN3RK1", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn2k2r/pbppqppp/1p2pn2/8/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn2k2r/pbppqppp/1p2pn2/8/1bPP4/5NP1/PP1BPPBP/RN1Q1RK1", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn2k2r/pbppqppp/1p2pn2/8/2PP4/5NP1/PP1bPPBP/RN1Q1RK1", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 492, "fen":"rn2k2r/pbppqppp/1p2pn2/8/2PP4/5NP1/PP1QPPBP/RN3RK1", "treename":"Queen's Indian Defense, Capablanca Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/3P4/2P5/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p3n2/3p4/2P5/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p3n2/3p4/2P4N/6P1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb1pbppp/1pp2n2/3p4/2P4N/6P1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb1pbppp/1pp2n2/3P4/7N/6P1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb1pbppp/1pp5/3n4/7N/6P1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb1pbppp/1pp5/3n1N2/8/6P1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbnpbppp/1pp5/5N2/8/6P1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbnpbppp/1pp5/5N2/4P3/6P1/PP3PBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/3P4/2P5/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p3n2/3p4/2P5/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p3n2/3p4/2PN4/6P1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"r2q1rk1/pbppbppp/np2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2n2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb1pbppp/1p2p3/2p5/2PP4/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb1pbppp/1p2p3/2p5/2PP4/2Q2NP1/PP2PPBP/R1BR2K1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb2bppp/1p1pp3/2p5/2PP4/2Q2NP1/PP2PPBP/R1BR2K1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb2bppp/1p1pp3/2p5/2PP4/1PQ2NP1/P3PPBP/R1BR2K1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb3ppp/1p1ppb2/2p5/2PP4/1PQ2NP1/P3PPBP/R1BR2K1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb3ppp/1p1ppb2/2p5/2PP4/1PQ2NP1/PB2PPBP/R2R2K1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn3rk1/pb2qppp/1p1ppb2/2p5/2PP4/1PQ2NP1/PB2PPBP/R2R2K1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2n2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppb1pp/1p2p3/5p2/2PP4/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppb1pp/1p2p3/5p2/2PP4/1PQ2NP1/P3PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbpp2pp/1p2pb2/5p2/2PP4/1PQ2NP1/P3PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbpp2pp/1p2pb2/5p2/2PP4/1PQ2NP1/PB2PPBP/R4RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp3pp/1p1ppb2/5p2/2PP4/1PQ2NP1/PB2PPBP/R4RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2n2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PPb3/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PPb3/2Q3P1/PP2PPBP/R1B1NRK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2n2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p1pp3/8/2PP4/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p1pp3/8/2PP4/1PQ2NP1/P3PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2n2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb1pbppp/1p2p3/2p5/2PP4/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb1pbppp/1p2p3/2p5/2PP4/1PQ2NP1/P3PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2n2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppb1pp/1p2p3/5p2/2PP4/2Q2NP1/PP2PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppb1pp/1p2p3/5p2/2PP4/2Q2NP1/PP2PPBP/R1BR2K1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3pN3/2PP4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"r2q1rk1/pbp1bppp/np2pn2/3pN3/2PP4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"r2q1rk1/pbp1bppp/np2pn2/3PN3/3P4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"r2q1rk1/pbp1bppp/np3n2/3pN3/3P4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3P4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p3n2/3p4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3pN3/2PP4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pb2bppp/1pp1pn2/3pN3/2PP4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3pN3/2PP4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"r2q1rk1/pbpnbppp/1p2pn2/3pN3/2PP4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPN3/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PPb3/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PPb3/6P1/PP2PPBP/R1BQNRK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PP4/6P1/PP2PPbP/R1BQNRK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PP4/6P1/PP2PPNP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1p1bppp/1p2p3/3p4/2PP4/6P1/PP2PPNP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1p1bppp/1p2p3/3p4/Q1PP4/6P1/PP2PPNP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn3rk1/p1pqbppp/1p2p3/3p4/Q1PP4/6P1/PP2PPNP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p2p3/3p4/2PPn3/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p2p3/3P4/3Pn3/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbp1bppp/1p6/3p4/3Pn3/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPN3/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PPb3/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PPb3/6P1/PP2PPBP/R1BQNRK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PP4/6P1/PP2PPbP/R1BQNRK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PP4/6P1/PP2PPNP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1p1bppp/1p2p3/3p4/2PP4/6P1/PP2PPNP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1p1bppp/1p2p3/3p4/Q1PP4/6P1/PP2PPNP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1p1bppp/1p2p3/8/Q1pP4/6P1/PP2PPNP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2n2NP1/PPQ1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2P2NP1/P1Q1PPBP/R1B2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPN3/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PPb3/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PPbB2/5NP1/PP2PPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPN3/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PPb3/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/p1ppbppp/1p2p3/8/2PPb2N/6P1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbpp1ppp/1p2pb2/8/2PPn3/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbpp1ppp/1p2pb2/8/2PPn3/2N2NP1/PP1BPPBP/2RQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppb1pp/1p2p3/5p2/2PPn3/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppb1pp/1p2p3/3P1p2/2P1n3/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbpp2pp/1p2pb2/3P1p2/2P1n3/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbpp2pp/1p2pb2/3P1p2/2P1n3/2N2NP1/PP1BPPBP/2RQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbp1bppp/1p2pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 493, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PPQ1PPBP/RNB2RK1", "treename":"Queen's Indian Defense, Classical Variation"},
{"gid": 494, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 494, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/2p5/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Euwe Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/1P3NP1/P2BPP1P/RN1QKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1ppbppp/bp2pn2/8/2PP4/1P3NP1/P2BPP1P/RN1QKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1ppbppp/bp2pn2/8/2PP4/1P3NP1/P2BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p2pbppp/bpp1pn2/8/2PP4/1P3NP1/P2BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p2pbppp/bpp1pn2/8/2PP4/1PB2NP1/P3PPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p3bppp/bpp1pn2/3p4/2PP4/1PB2NP1/P3PPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p3bppp/bpp1pn2/3pN3/2PP4/1PB3P1/P3PPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p2nbppp/bpp1p3/3pN3/2PP4/1PB3P1/P3PPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/1P3NP1/P2BPP1P/RN1QKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1ppbppp/bp2pn2/8/2PP4/1P3NP1/P2BPP1P/RN1QKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1ppbppp/bp2pn2/8/2PP4/1P3NP1/P2BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1p1bppp/bp2pn2/3p4/2PP4/1P3NP1/P2BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1p1bppp/bp2pn2/3P4/3P4/1P3NP1/P2BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1p1bppp/bp3n2/3p4/3P4/1P3NP1/P2BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1p1bppp/bp3n2/3p4/3P4/1P3NP1/P2BPPBP/RN1Q1RK1", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1q1rk1/p1p1bppp/bp3n2/3p4/3P4/1P3NP1/P2BPPBP/RN1Q1RK1", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/1P3NP1/P2BPP1P/RN1QKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1ppbppp/bp2pn2/8/2PP4/1P3NP1/P2BPP1P/RN1QKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1ppbppp/bp2pn2/8/2PP4/1P3NP1/P2BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p2pbppp/bpp1pn2/8/2PP4/1P3NP1/P2BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p2pbppp/bpp1pn2/8/2PP4/1P3NP1/P2BPPBP/RN1Q1RK1", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p3bppp/bpp1pn2/3p4/2PP4/1P3NP1/P2BPPBP/RN1Q1RK1", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/1P3NP1/P2BPP1P/RN1QKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1ppbppp/bp2pn2/8/2PP4/1P3NP1/P2BPP1P/RN1QKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1ppbppp/bp2pn2/8/2PP4/1PN2NP1/P2BPP1P/R2QKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQR1K1", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQR1K1", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/Q1PP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p2p1ppp/bpp1pn2/8/Q1PP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p2p1ppp/bpp1pn2/8/Q1PP4/2N2NP1/PP2PP1P/R1B1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p2p1ppp/b1p1pn2/1p6/Q1PP4/2N2NP1/PP2PP1P/R1B1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/Q1PP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1ppbppp/bp2pn2/8/Q1PP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/Q1PP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p2p1ppp/bp2pn2/2p5/Q1PP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p2p1ppp/bp2pn2/2p5/Q1PP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/Q1PP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2P5/Q1P5/5NP1/PP2PPBP/RNB1K2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/Q1PP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p2p1ppp/bp2pn2/2p5/Q1PP4/5NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p2p1ppp/bp2pn2/2p5/Q1PP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/Q1PP4/5NP1/PP2PPBP/RNB1K2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/Q1PP4/5NP1/PP2PPBP/RNB2RK1", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/1P3NP1/P3PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/1P3NP1/P3PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/1P3NP1/P2BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/1bpp1ppp/1p2pn2/p7/1bPP4/1P3NP1/P2BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/1bpp1ppp/1p2pn2/p7/1bPP4/1P3NP1/P2BPPBP/RN1Q1RK1", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1q1rk1/1bpp1ppp/1p2pn2/p7/1bPP4/1P3NP1/P2BPPBP/RN1Q1RK1", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1p2ppp/bp2pn2/3p4/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1p2ppp/bp2pn2/3P4/3P4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1p2ppp/bp2pn2/3p4/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1p2ppp/bp2pn2/3p4/2PP4/1P3NP1/P3PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/b3pn2/1p6/2PP4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/b3pn2/1P6/3P4/1P3NP1/P3PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1Q3NP1/PP2PP1P/RNB1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP1NPP1P/R1BQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP1NPP1P/R1BQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP1NPPBP/R1BQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/5NP1/PP1NPPBP/R1BQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP1NPP1P/R1BQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p2p1ppp/bp2pn2/2p5/2PP4/5NP1/PP1NPP1P/R1BQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP1NPP1P/R1BQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/5NP1/PP1NPP1P/R1BQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PPQ1PP1P/RNB1KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2pP4/2P5/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pb1p1ppp/1p3n2/2pp4/2P5/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qkb1r/pb1p1ppp/1p3n2/2pp4/2P4N/6P1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/5NP1/PP1BPP1P/RN1QKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP1bPP1P/RN1QKB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP1QPP1P/RN2KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 495, "fen":"rn1qk2r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP1QPP1P/RN2KB1R", "treename":"Queen's Indian Defense, Fianchetto Variation"},
{"gid": 496, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/8/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1p2/1p2pn1p/6p1/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1p2/1p2pn1p/6p1/2PP4/2N2NB1/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1p2/1p2p2p/6pn/2PP4/2N2NB1/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1p2/1p2p2p/6pn/2PP4/2N2NB1/PPQ1PPPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/8/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1p2/1p2pn1p/6p1/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1p2/1p2pn1p/6p1/2PP4/2N2NB1/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1p2/1p2p2p/6pn/2PP4/2N2NB1/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1p2/1p2p2p/6pn/2PP4/2N1PNB1/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/8/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pb1p1pp1/1p2pn1p/2p5/1bPP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/8/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbppbpp1/1p2pn1p/8/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbppbpp1/1p2pn1p/8/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbppbpp1/1p2p2p/8/2PPn2B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/8/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/2PP3B/2b1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/2PP3B/2P1PN2/P4PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbp2pp1/1p1ppn1p/8/2PP3B/2P1PN2/P4PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbp2pp1/1p1ppn1p/8/2PP3B/2P1P3/P2N1PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"r2qk2r/pbpn1pp1/1p1ppn1p/8/2PP3B/2P1P3/P2N1PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"r2qk2r/pbpn1pp1/1p1ppn1p/8/2PP3B/2P1PP2/P2N2PP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"r3k2r/pbpnqpp1/1p1ppn1p/8/2PP3B/2P1PP2/P2N2PP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/8/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1p2/1p2pn1p/6p1/1bPP3B/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/8/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbppbpp1/1p2pn1p/8/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbppbpp1/1p2pn1p/8/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pb1pbpp1/1p2pn1p/2p5/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/8/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1p2/1p2pn1p/6p1/1bPP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1p2/1p2pn1p/6p1/1bPP4/2N1PNB1/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1p2/1p2p2p/6p1/1bPPn3/2N1PNB1/PP3PPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1p2/1p2p2p/6p1/1bPPn3/2N1PNB1/PPQ2PPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1p2/1p2p2p/6p1/2PPn3/2b1PNB1/PPQ2PPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1p2/1p2p2p/6p1/2PPn3/2P1PNB1/P1Q2PPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbp2p2/1p1pp2p/6p1/2PPn3/2P1PNB1/P1Q2PPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbp2p2/1p1pp2p/6p1/2PPn3/2PBPNB1/P1Q2PPP/R3K2R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbp5/1p1pp2p/5pp1/2PPn3/2PBPNB1/P1Q2PPP/R3K2R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qkb1r/pbpp1pp1/1p2pn1p/8/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N5/PP1NPPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/2PP3B/2b5/PP1NPPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 496, "fen":"rn1qk2r/pbpp1pp1/1p2pn1p/8/2PP3B/2P5/P2NPPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2p3/8/2PPn3/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3P4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p3n2/3p4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p3n2/3p2B1/3P4/P1N2N2/1P2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3P4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p3n2/3p4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p3n2/3p4/3P4/P1N2NP1/1P2PP1P/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1p1p/1p2pnp1/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3P4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pb3ppp/1p2p3/2pn4/3P4/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3P4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pbp1bppp/1p2p3/3n4/3P4/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3P4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/8/3P4/P1n2N2/1PQ1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/8/3P4/P1P2N2/2Q1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pb3ppp/1p2p3/2p5/3P4/P1P2N2/2Q1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pb3ppp/1p2p3/2p5/3PP3/P1P2N2/2Q2PPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3P4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/8/3P4/P1n2N2/1PQ1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/8/3P4/P1P2N2/2Q1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pbp1bppp/1p2p3/8/3P4/P1P2N2/2Q1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pbp1bppp/1p2p3/8/3PP3/P1P2N2/2Q2PPP/R1B1KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p2B1/2PP4/P1N2N2/1P2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pbp1bppp/1p2pn2/3p2B1/2PP4/P1N2N2/1P2PPPP/R2QKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pbp1bppp/1p2pn2/3p2B1/Q1PP4/P1N2N2/1P2PPPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pb2bppp/1pp1pn2/3p2B1/Q1PP4/P1N2N2/1P2PPPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pb2bppp/1pp1pB2/3p4/Q1PP4/P1N2N2/1P2PPPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pb3ppp/1pp1pb2/3p4/Q1PP4/P1N2N2/1P2PPPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pb3ppp/1pp1pb2/3P4/Q2P4/P1N2N2/1P2PPPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pb3ppp/1pp2b2/3p4/Q2P4/P1N2N2/1P2PPPP/R3KB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3P4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2p1p/1p2p1p1/3n4/3P4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3P4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qk2r/pbp1bppp/1p2p3/3n4/3P4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3P4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbp2ppp/1p2p3/3n4/3PP3/P1N2N2/1P3PPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 497, "fen":"rn1qkb1r/p1pp1ppp/1p2pn2/8/2PP4/P1N2b2/1P2PPPP/R1BQKB1R", "treename":"Queen's Indian Defense, Kasparov-Petrosian Variation"},
{"gid": 499, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Marienbad System"},
{"gid": 499, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Marienbad System"},
{"gid": 499, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Marienbad System"},
{"gid": 499, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Marienbad System"},
{"gid": 499, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Marienbad System"},
{"gid": 499, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Marienbad System"},
{"gid": 499, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Marienbad System"},
{"gid": 499, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Marienbad System"},
{"gid": 499, "fen":"rn1qkb1r/pb1ppppp/1p3n2/2p5/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Marienbad System"},
{"gid": 499, "fen":"rn1qkb1r/pb1ppppp/1p3n2/8/2Pp4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Marienbad System"},
{"gid": 499, "fen":"rn1qkb1r/pb1ppppp/1p3n2/8/2PQ4/5NP1/PP2PPBP/RNB1K2R", "treename":"Queen's Indian Defense, Marienbad System"},
{"gid": 500, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP1B2/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP1B2/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP1B2/4PN2/PP3PPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP1B2/4PN2/PP3PPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP1B2/4PN2/PP1N1PPP/R2QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP1B2/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP1B2/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP1B2/4PN2/PP3PPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP1B2/4PN2/PP3PPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP1B2/4PN1P/PP3PP1/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP1B2/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP1B2/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP1B2/4PN2/PP3PPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP1B2/4PN2/PP3PPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 500, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP1B2/4P3/PP1N1PPP/RN1QKB1R", "treename":"Queen's Indian Defense, Miles Variation"},
{"gid": 501, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP1BPPBP/R2QK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbpp1ppp/1p2pb2/8/2PPn3/2N2NP1/PP1BPPBP/R2QK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP1BPPBP/R2QK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppb1pp/1p2p3/5p2/2PPn3/2N2NP1/PP1BPPBP/R2QK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1qk2r/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP1BPPBP/R2QK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 501, "fen":"rn1q1rk1/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP1BPPBP/R2QK2R", "treename":"Queen's Indian Defense, Opocensky Variation"},
{"gid": 502, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/P4N2/1PQ1PPPP/RNB1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1PQ1PPPP/RNB1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/P1N2N2/1PQ1PPPP/R1B1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PPP3/P1N2N2/1PQ2PPP/R1B1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/8/2PpP3/P1N2N2/1PQ2PPP/R1B1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/8/2PNP3/P1N5/1PQ2PPP/R1B1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"r2qkb1r/pb1p1ppp/1pn1pn2/8/2PNP3/P1N5/1PQ2PPP/R1B1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/P4N2/1PQ1PPPP/RNB1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p2p1ppp/bp2pn2/2p5/2PP4/P4N2/1PQ1PPPP/RNB1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/p2p1ppp/1p2pn2/2p5/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/p2p1ppp/1p2pn2/2pP4/2P5/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p2p1ppp/bp2pn2/2pP4/2P5/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p2p1ppp/bp2pn2/2pP4/2P5/P4N2/1PQ1PPPP/RNB1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p2p1ppp/bp3n2/2pp4/2P5/P4N2/1PQ1PPPP/RNB1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p2p1ppp/bp3n2/2pP4/8/P4N2/1PQ1PPPP/RNB1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p2p1p1p/bp3np1/2pP4/8/P4N2/1PQ1PPPP/RNB1KB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 502, "fen":"rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/P3PN2/1P3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Petrosian Variation"},
{"gid": 503, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP1BPPBP/R2QK2R", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP1BPPBP/R2QK2R", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 503, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/2PP4/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Queen's Indian Defense, Riumin Variation"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/2p5/2PP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/3BPN2/PP1N1PPP/R1BQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb1p1ppp/1p2pn2/2p5/1bPP4/3BPN2/PP1N1PPP/R1BQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2Pp4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb1pbppp/1p2pn2/8/2PP4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb2bppp/1p2pn2/3p4/2PP4/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/1P1BPN2/P4PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/2p5/2PP4/1P1BPN2/P4PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/2p5/2PP4/1P1BPN2/PB3PPP/RN1Q1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pb2bppp/1p2pn2/2pp4/2PP4/1P1BPN2/PB3PPP/RN1Q1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pbp2ppp/1p1bpn2/3p4/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pbp2ppp/1p1bpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pbp2ppp/1p1bpn2/3p4/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pbp2ppp/1p1bpn2/3p4/2PP4/1P1BPN2/P4PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pb1pbppp/1p2pn2/2p5/2PP4/1P1BPN2/P4PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/2p5/2PP4/1P1BPN2/P4PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/2p5/2PP4/1P1BPN2/PB3PPP/RN1Q1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/8/2Pp4/1P1BPN2/PB3PPP/RN1Q1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pb1pbppp/1p2pn2/8/2PP4/1P1B1N2/PB3PPP/RN1Q1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pb2bppp/1p2pn2/3p4/2PP4/1P1B1N2/PB3PPP/RN1Q1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pbppbppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/8/2Pp4/3BPN2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/8/2PP4/3B1N2/PP3PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/3BPN2/PP1N1PPP/R1BQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/8/1bPP4/3BPN2/PP1N1PPP/R1BQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pbpp1ppp/1p2pn2/8/1bPP4/3BPN2/PP1N1PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pbp2ppp/1p2pn2/3p4/1bPP4/3BPN2/PP1N1PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pbp2ppp/1p2pn2/3p4/1bPP4/P2BPN2/1P1N1PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/2PP4/P2BPN2/1P1N1PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1q1rk1/pbp1bppp/1p2pn2/3p4/1PPP4/P2BPN2/3N1PPP/R1BQ1RK1", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 504, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Queen's Indian Defense, Spassky System"},
{"gid": 505, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rn1qk2r/1bpp1ppp/1p2pn2/p7/1bPP4/5NP1/PP1BPPBP/RN1QK2R", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rn1qk2r/1bpp1ppp/1p2pn2/p7/1bPP4/5NP1/PP1BPPBP/RN1Q1RK1", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 505, "fen":"rn1q1rk1/1bpp1ppp/1p2pn2/p7/1bPP4/5NP1/PP1BPPBP/RN1Q1RK1", "treename":"Queen's Indian Defense, Yates Variation"},
{"gid": 498, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/3P1B2/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p2p1ppp/1p2pn2/2p5/3P1B2/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/3P4/3BPN2/PPP2PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pb1p1ppp/1p2pn2/2p5/3P4/3BPN2/PPP2PPP/RNBQ1RK1", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/3P1B2/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pbpp1ppp/1p2pn2/8/3P1B2/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rn1qkb1r/pbpppppp/1p3n2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p2p1ppp/1p2pn2/2p5/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 498, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/6B1/2PP4/5N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Indian Defense, Main Line"},
{"gid": 506, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Pawn, Main Line"},
{"gid": 506, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Queen's Pawn, Main Line"},
{"gid": 507, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Barry Attack"},
{"gid": 507, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Barry Attack"},
{"gid": 507, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Queen's Pawn Game, Barry Attack"},
{"gid": 507, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Queen's Pawn Game, Barry Attack"},
{"gid": 507, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N2N2/PPP1PPPP/R1BQKB1R", "treename":"Queen's Pawn Game, Barry Attack"},
{"gid": 507, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/3P4/2N2N2/PPP1PPPP/R1BQKB1R", "treename":"Queen's Pawn Game, Barry Attack"},
{"gid": 507, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/3P1B2/2N2N2/PPP1PPPP/R2QKB1R", "treename":"Queen's Pawn Game, Barry Attack"},
{"gid": 507, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/3P1B2/2N2N2/PPP1PPPP/R2QKB1R", "treename":"Queen's Pawn Game, Barry Attack"},
{"gid": 507, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/3P1B2/2N1PN2/PPP2PPP/R2QKB1R", "treename":"Queen's Pawn Game, Barry Attack"},
{"gid": 507, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/3P1B2/2N1PN2/PPP2PPP/R2QKB1R", "treename":"Queen's Pawn Game, Barry Attack"},
{"gid": 507, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/3P1B2/2N1PN2/PPP1BPPP/R2QK2R", "treename":"Queen's Pawn Game, Barry Attack"},
{"gid": 508, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Chigorin Variation"},
{"gid": 508, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Chigorin Variation"},
{"gid": 508, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Chigorin Variation"},
{"gid": 508, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Chigorin Variation"},
{"gid": 508, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Pawn Game, Chigorin Variation"},
{"gid": 508, "fen":"r2qkbnr/ppp1pppp/2n5/3p4/3P2b1/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Pawn Game, Chigorin Variation"},
{"gid": 509, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rn1qkb1r/ppp1pppp/5n2/3p1b2/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rn1qkb1r/ppp1pppp/5n2/3p1b2/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rn1qkb1r/ppp2ppp/4pn2/3p1b2/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/3P4/2P1PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"r1bqkb1r/pp2pppp/2n2n2/2pp4/3P4/2P1PN2/PP3PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rn1qkb1r/ppp1pppp/5n2/3p4/3P2b1/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 509, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Colle System"},
{"gid": 510, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Levitsky Attack"},
{"gid": 510, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Levitsky Attack"},
{"gid": 510, "fen":"rnbqkbnr/ppp1pppp/8/3p2B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Queen's Pawn Game, Levitsky Attack"},
{"gid": 510, "fen":"rnbqkbnr/ppp1ppp1/7p/3p2B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Queen's Pawn Game, Levitsky Attack"},
{"gid": 510, "fen":"rnbqkbnr/ppp1ppp1/7p/3p4/3P3B/8/PPP1PPPP/RN1QKBNR", "treename":"Queen's Pawn Game, Levitsky Attack"},
{"gid": 510, "fen":"rnbqkbnr/pp2ppp1/2p4p/3p4/3P3B/8/PPP1PPPP/RN1QKBNR", "treename":"Queen's Pawn Game, Levitsky Attack"},
{"gid": 510, "fen":"rnbqkbnr/pp2ppp1/2p4p/3p4/3P3B/4P3/PPP2PPP/RN1QKBNR", "treename":"Queen's Pawn Game, Levitsky Attack"},
{"gid": 510, "fen":"rnb1kbnr/pp2ppp1/1qp4p/3p4/3P3B/4P3/PPP2PPP/RN1QKBNR", "treename":"Queen's Pawn Game, Levitsky Attack"},
{"gid": 511, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rn1qkb1r/ppp1pppp/5n2/3p1b2/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/3P1B2/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/3P1B2/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/3P1B2/2P1PN2/PP3PPP/RN1QKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 511, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/3P1B2/2P1PN2/PP3PPP/RN1QKB1R", "treename":"Queen's Pawn Game, London System"},
{"gid": 512, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqk2r/ppp1ppbp/5np1/3p4/3P4/5NP1/PPP1PPBP/RNBQ1RK1", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p4/3P4/5NP1/PPP1PPBP/RNBQ1RK1", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/3P4/5NP1/PPP1PP1P/RNBQKB1R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/3P2b1/5NP1/PPP1PPBP/RNBQK2R", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/3P2b1/5NP1/PPP1PPBP/RNBQ1RK1", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"r2qkb1r/pp1npppp/2p2n2/3p4/3P2b1/5NP1/PPP1PPBP/RNBQ1RK1", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"r2qkb1r/pp1npppp/2p2n2/3p4/3P2b1/5NP1/PPPNPPBP/R1BQ1RK1", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 512, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/3p4/3P2b1/5NP1/PPPNPPBP/R1BQ1RK1", "treename":"Queen's Pawn Game, Symmetrical Variation"},
{"gid": 513, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/8/3p2B1/3Pn3/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/8/3p4/3PnB2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p2B1/3P4/5N2/PPPNPPPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/3P4/5N2/PPPNPPPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p2B1/3P4/3BPN2/PPPN1PPP/R2QK2R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbq1rk1/pp2ppbp/5np1/2pp2B1/3P4/3BPN2/PPPN1PPP/R2QK2R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbq1rk1/pp2ppbp/5np1/2pp2B1/3P4/2PBPN2/PP1N1PPP/R2QK2R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"r1bq1rk1/pp1nppbp/5np1/2pp2B1/3P4/2PBPN2/PP1N1PPP/R2QK2R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p2B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p2B1/3P4/3BPN2/PPPN1PPP/R2QK2R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p2B1/3P4/5N2/PPPNPPPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/3P4/5N2/PPPNPPPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p2B1/3P4/2P1PN2/PP1N1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"r1bq1rk1/pppnppbp/5np1/3p2B1/3P4/2P1PN2/PP1N1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"r1bq1rk1/pppnppbp/5np1/3p2B1/3P4/2P1PN2/PP1NBPPP/R2QK2R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p2B1/3P4/2P2N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqk2r/ppp1ppbp/5np1/3p2B1/3P4/2P2N2/PP2PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"r1bqkb1r/pppn1ppp/4pn2/3p2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"r1bqk2r/pppnbppp/4pn2/3p2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp2B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqk2r/pp2bppp/4pn2/2pp2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqk2r/pp2bppp/4pn2/2pp2B1/3P4/2P1PN2/PP1N1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"r1bqk2r/pp1nbppp/4pn2/2pp2B1/3P4/2P1PN2/PP1N1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp2B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 513, "fen":"r1bqkb1r/pp1n1ppp/4pn2/2pp2B1/3P4/4PN2/PPPN1PPP/R2QKB1R", "treename":"Queen's Pawn Game, Torre Attack"},
{"gid": 514, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rn1qkb1r/ppp1pppp/5n2/3p1bB1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rn1qkb1r/ppp1pppp/5n2/3p1bB1/3P4/2N2P2/PPP1P1PP/R2QKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"r1bqkb1r/pppnpppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"r1bqkb1r/pppnpppp/5n2/3p2B1/3P4/2N2N2/PPP1PPPP/R2QKB1R", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"r1bqkb1r/pppnpp1p/5np1/3p2B1/3P4/2N2N2/PPP1PPPP/R2QKB1R", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"r1bqkb1r/pppnpppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"r1bqkb1r/pppnpppp/5n2/3p2B1/3P4/2N2N2/PPP1PPPP/R2QKB1R", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"r1bqkb1r/pppnppp1/5n1p/3p2B1/3P4/2N2N2/PPP1PPPP/R2QKB1R", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rn1qkb1r/ppp1pppp/5n2/3p1bB1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 514, "fen":"rn1qkb1r/ppp1pppp/5B2/3p1b2/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Queen's Pawn Game, Veresov Attack"},
{"gid": 515, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnb1kbnr/pp2pppp/1qp5/3p4/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rn1qkbnr/pp2pppp/2p5/3p1b2/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rn1qkbnr/pp2pppp/2p5/3p1b2/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rn1qkbnr/ppp1pppp/8/3p1b2/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rn1qkbnr/ppp2ppp/4p3/3p1b2/3P1B2/5N2/PPP1PPPP/RN1QKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/4PN2/PPPN1PPP/R1BQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/4PN2/PPPN1PPP/R1BQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 515, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/1P2PN2/P1PN1PPP/R1BQKB1R", "treename":"Queen's Pawn Game, Zukertort Variation"},
{"gid": 516, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbqkbnr/ppp2ppp/3p4/8/2PPp3/5N2/PP2PPPP/RNBQKB1R", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbqkbnr/ppp2ppp/3p4/4p3/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbqkbnr/ppp2ppp/3p4/4P3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbqkbnr/ppp2ppp/8/4p3/2P5/8/PP2PPPP/RNBQKBNR", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbQkbnr/ppp2ppp/8/4p3/2P5/8/PP2PPPP/RNB1KBNR", "treename":"Rat Defense, English Rat"},
{"gid": 516, "fen":"rnbk1bnr/ppp2ppp/8/4p3/2P5/8/PP2PPPP/RNB1KBNR", "treename":"Rat Defense, English Rat"},
{"gid": 517, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"r1bqkb1r/pppnpppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"r1bqkb1r/pppnpppp/5n2/3p2B1/3P4/2N2P2/PPP1P1PP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1ppp1/5n1p/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 517, "fen":"rnbqkb1r/pp2pppp/5n2/2pp2B1/3P4/2N5/PPP1PPPP/R2QKBNR", "treename":"Richter-Veresov Attack, Main Line"},
{"gid": 518, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Robatsch Defense, Main Line"},
{"gid": 518, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Robatsch Defense, Main Line"},
{"gid": 518, "fen":"rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Robatsch Defense, Main Line"},
{"gid": 518, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Robatsch Defense, Main Line"},
{"gid": 518, "fen":"rnbqkbnr/ppp1pp1p/3p2p1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Robatsch Defense, Main Line"},
{"gid": 518, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Robatsch Defense, Main Line"},
{"gid": 518, "fen":"rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/5N2/PP3PPP/RNBQKB1R", "treename":"Robatsch Defense, Main Line"},
{"gid": 518, "fen":"rn1qk1nr/ppp1ppbp/3p2p1/8/2PPP1b1/5N2/PP3PPP/RNBQKB1R", "treename":"Robatsch Defense, Main Line"},
{"gid": 519, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Rubinstein Opening, Main Line"},
{"gid": 519, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Rubinstein Opening, Main Line"},
{"gid": 519, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Rubinstein Opening, Main Line"},
{"gid": 519, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Rubinstein Opening, Main Line"},
{"gid": 519, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Rubinstein Opening, Main Line"},
{"gid": 519, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Rubinstein Opening, Main Line"},
{"gid": 519, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Rubinstein Opening, Main Line"},
{"gid": 519, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Rubinstein Opening, Main Line"},
{"gid": 519, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/1P1BPN2/P1P2PPP/RNBQK2R", "treename":"Rubinstein Opening, Main Line"},
{"gid": 519, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/3P4/1P1BPN2/P1P2PPP/RNBQK2R", "treename":"Rubinstein Opening, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqk1nr/pp2bppp/3p4/2pPp3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqk1nr/pp2bppp/3p4/2pPp3/4P3/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rn1qk1nr/pp2bppp/3p4/2pPp3/4P1b1/2N2N2/PPP2PPP/R1BQKB1R", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3p1p/3p2p1/2pPp3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/1p3ppp/p2p4/2pPp3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/1p3ppp/p2p4/2pPp3/P3P3/2N5/1PP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqk1nr/1p2bppp/p2p4/2pPp3/P3P3/2N5/1PP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkb1r/pp3ppp/3p1n2/2pPp3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqk1nr/pp2bppp/3p4/2pPp3/4P3/2N5/PPP2PPP/R1BQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqk1nr/pp2bppp/3p4/1BpPp3/4P3/2N5/PPP2PPP/R1BQK1NR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/5N2/PPP2PPP/RNBQKB1R", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/8/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp1p1ppp/8/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/8/PPP2PPP/RNBQKBNR", "treename":"Semi-Benoni, Main Line"},
{"gid": 520, "fen":"rnbqkbnr/pp3ppp/3p4/1BpPp3/4P3/8/PPP2PPP/RNBQK1NR", "treename":"Semi-Benoni, Main Line"},
{"gid": 521, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Meran Variation"},
{"gid": 521, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Meran Variation"},
{"gid": 521, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Meran Variation"},
{"gid": 521, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Meran Variation"},
{"gid": 521, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Meran Variation"},
{"gid": 521, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Meran Variation"},
{"gid": 521, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Accelerated Meran Variation"},
{"gid": 521, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Accelerated Meran Variation"},
{"gid": 521, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Accelerated Meran Variation"},
{"gid": 521, "fen":"rnbqkb1r/1p3ppp/p1p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Accelerated Meran Variation"},
{"gid": 522, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pp3ppp/2p1p3/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pp3ppp/2p5/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pp3ppp/2p5/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rn1qkbnr/pp3ppp/2p5/3p1b2/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pp3ppp/2p1p3/3P4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pp3ppp/2p5/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 522, "fen":"rnbqkbnr/pp3ppp/2p5/3p4/3P1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Semi-Slav Defense, Accelerated Move Order"},
{"gid": 523, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3pp1/2p1pn1p/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3pp1/2p1pn1p/3p4/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqk2r/pp2bpp1/2p1pn1p/3p4/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqk2r/pp2bpp1/2p1pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbq1rk1/pp2bpp1/2p1pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3pp1/2p1pn1p/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3pp1/2p1pn1p/3p4/2PP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3pp1/2p1pn1p/8/2pP3B/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3pp1/2p1pn1p/8/2pPP2B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3p2/2p1pn1p/6p1/2pPP2B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/pp3p2/2p1pn1p/6p1/2pPP3/2N2NB1/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/p4p2/2p1pn1p/1p4p1/2pPP3/2N2NB1/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rnbqkb1r/p4p2/2p1pn1p/1p4p1/2pPP3/2N2NB1/PP2BPPP/R2QK2R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 523, "fen":"rn1qkb1r/pb3p2/2p1pn1p/1p4p1/2pPP3/2N2NB1/PP2BPPP/R2QK2R", "treename":"Semi-Slav Defense, Anti-Moscow Gambit"},
{"gid": 524, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"r1bqk2r/pp1nbppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"r1bqk2r/pp1nbppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 524, "fen":"r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/1PNBPN2/P4PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Bogoljubov Variation"},
{"gid": 525, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p4B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p2P1B1/2pP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4pp1/2p1pn1p/1p2P1B1/2pP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4pp1/2p1pn1p/1p2P3/2pP3B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn1p/1p2P1p1/2pP3B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn1p/1p2P1N1/2pP3B/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1p2p/1p1nP1N1/2pP3B/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p4B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p2P1B1/2pP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4pp1/2p1pn1p/1p2P1B1/2pP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4pp1/2p1pn1p/1p2P3/2pP3B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn1p/1p2P1p1/2pP3B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pP1p/1p4p1/2pP3B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pP1p/1p6/2pP3p/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pP1p/1p2N3/2pP3p/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p4B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p2P1B1/2pP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4pp1/2p1pn1p/1p2P1B1/2pP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4pp1/2p1pn1p/1p2P3/2pP3B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn1p/1p2P1p1/2pP3B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn1p/1p2P1N1/2pP3B/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn2/1p2P1p1/2pP3B/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn2/1p2P1B1/2pP4/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r1bqkb1r/p2n1p2/2p1pn2/1p2P1B1/2pP4/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r1bqkb1r/p2n1p2/2p1pn2/1p2P1B1/2pP4/2N3P1/PP3P1P/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r1b1kb1r/p2n1p2/2p1pn2/qp2P1B1/2pP4/2N3P1/PP3P1P/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p4B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p2P1B1/2pP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4pp1/2p1pn1p/1p2P1B1/2pP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4pp1/2p1pn1p/1p2P3/2pP3B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn1p/1p2P1p1/2pP3B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn1p/1p2P1N1/2pP3B/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn2/1p2P1p1/2pP3B/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn2/1p2P1B1/2pP4/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r1bqkb1r/p2n1p2/2p1pn2/1p2P1B1/2pP4/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r1bqkb1r/p2n1p2/2p1pn2/1p2P1B1/2pP4/2N3P1/PP3P1P/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r2qkb1r/pb1n1p2/2p1pn2/1p2P1B1/2pP4/2N3P1/PP3P1P/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p4B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p2P1B1/2pP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4pp1/2p1pn1p/1p2P1B1/2pP4/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4pp1/2p1pn1p/1p2P3/2pP3B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn1p/1p2P1p1/2pP3B/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn1p/1p2P1N1/2pP3B/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn2/1p2P1p1/2pP3B/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"rnbqkb1r/p4p2/2p1pn2/1p2P1B1/2pP4/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r1bqkb1r/p2n1p2/2p1pn2/1p2P1B1/2pP4/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r1bqkb1r/p2n1p2/2p1pP2/1p4B1/2pP4/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r2qkb1r/pb1n1p2/2p1pP2/1p4B1/2pP4/2N5/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r2qkb1r/pb1n1p2/2p1pP2/1p4B1/2pP4/2N3P1/PP3P1P/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r2qkb1r/pb1n1p2/4pP2/1pp3B1/2pP4/2N3P1/PP3P1P/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r2qkb1r/pb1n1p2/4pP2/1ppP2B1/2p5/2N3P1/PP3P1P/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 525, "fen":"r3kb1r/pb1n1p2/1q2pP2/1ppP2B1/2p5/2N3P1/PP3P1P/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik System"},
{"gid": 526, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p4B1/2pPP3/2N2N2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 526, "fen":"rnbqkb1r/p4ppp/2p1pn2/1p4B1/P1pPP3/2N2N2/1P3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Botvinnik Variation"},
{"gid": 527, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PPP3/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2PPp3/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2PPN3/3B1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbp3/8/2PPn3/3B1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbp3/8/2PPB3/5N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1pp1/2pbp2p/8/2PPB3/5N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1pp1/2pbp2p/8/2PP4/5N2/PPB2PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1pp1/2pb3p/4p3/2PP4/5N2/PPB2PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PPP3/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2pPP3/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2BPP3/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pb1n2/4p3/2BPP3/2N2N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2pP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pb1n2/4p3/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2pP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/1p1n1ppp/p1pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/1p1n1ppp/p1pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1BR2K1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/3n1ppp/p1pbpn2/1p6/2BP4/2N1PN2/PPQ2PPP/R1BR2K1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2pP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1b2rk1/pp1nqppp/2pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1b2rk1/pp1nqppp/2pbpn2/8/2BP4/2N1PN2/PPQB1PPP/R4RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2pP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1b2rk1/pp1nqppp/2pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1b2rk1/pp1nqppp/2pbpn2/8/2BP4/P1N1PN2/1PQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2pP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1b2rk1/pp1nqppp/2pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1b2rk1/pp1nqppp/2pbpn2/8/2BP4/2N1PN1P/PPQ2PP1/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2pP4/2NBPN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/p2n1ppp/2pbpn2/1p6/2BP4/2N1PN2/PPQ2PPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bq1rk1/p2n1ppp/2pbpn2/1p6/3P4/2N1PN2/PPQ1BPPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r2q1rk1/pb1n1ppp/2pbpn2/1p6/3P4/2N1PN2/PPQ1BPPP/R1B2RK1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r2q1rk1/pb1n1ppp/2pbpn2/1p6/3P4/2N1PN2/PPQ1BPPP/R1BR2K1", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/8/2pP4/2NBPN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/8/2BP4/2N1PN2/PPQ2PPP/R1B1K2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PPP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/8/2PPp3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 527, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/8/2PPN3/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Chigorin Defense"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1bqkb1r/1p1n1ppp/p1p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqk2r/pp3ppp/2pbpn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqk2r/pp2bppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqk2r/pp2bppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqk2r/pp2bppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbq1rk1/pp2bppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3pp1/2p1pn1p/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3pp1/2p1pB1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnb1kb1r/pp3pp1/2p1pq1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnb1kb1r/pp3pp1/2p1pq1p/3p4/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1pp1/2p1pq1p/3p4/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1pp1/2p1pq1p/3p4/2PP4/2NBPN2/PP3PPP/R2QK2R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1pp1/2p1pq1p/8/2pP4/2NBPN2/PP3PPP/R2QK2R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1pp1/2p1pq1p/8/2BP4/2N1PN2/PP3PPP/R2QK2R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1p2/2p1pqpp/8/2BP4/2N1PN2/PP3PPP/R2QK2R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1p2/2p1pqpp/8/2BP4/2N1PN2/PP3PPP/R2Q1RK1", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1k2r/pp1n1pb1/2p1pqpp/8/2BP4/2N1PN2/PP3PPP/R2Q1RK1", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1k2r/pp1n1pb1/2p1pqpp/8/1PBP4/2N1PN2/P4PPP/R2Q1RK1", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3pp1/2p1pn1p/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3pp1/2p1pB1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnb1kb1r/pp3pp1/2p1pq1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnb1kb1r/pp3pp1/2p1pq1p/3p4/2PP4/2N2NP1/PP2PP1P/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3pp1/2p1pn1p/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3pp1/2p1pB1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnb1kb1r/pp3pp1/2p1pq1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnb1kb1r/pp3pp1/2p1pq1p/3p4/2PP4/2N2N2/PPQ1PPPP/R3KB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/8/2pP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/8/2QP4/2N2N2/PP2PPPP/R1B1KB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2NQ1N2/PP2PPPP/R1B1KB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3pp1/2p1pn1p/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3pp1/2p1pB1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnb1kb1r/pp3pp1/2p1pq1p/3p4/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnb1kb1r/pp3pp1/2p1pq1p/3p4/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1pp1/2p1pq1p/3p4/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1pp1/2p1pq1p/3p4/2PP4/2NBPN2/PP3PPP/R2QK2R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1pp1/2p1pq1p/8/2pP4/2NBPN2/PP3PPP/R2QK2R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1pp1/2p1pq1p/8/2BP4/2N1PN2/PP3PPP/R2QK2R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1p2/2p1pqpp/8/2BP4/2N1PN2/PP3PPP/R2QK2R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1kb1r/pp1n1p2/2p1pqpp/8/2BP4/2N1PN2/PP3PPP/R2Q1RK1", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1k2r/pp1n1pb1/2p1pqpp/8/2BP4/2N1PN2/PP3PPP/R2Q1RK1", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1b1k2r/pp1n1pb1/2p1pqpp/8/2BP4/2N1PN2/PP3PPP/2RQ1RK1", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqk2r/pp2bppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"rnbqk2r/pp2bppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1bqk2r/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 528, "fen":"r1bqk2r/pp1nbppp/2p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/R2QK2R", "treename":"Semi-Slav Defense, Main Line"},
{"gid": 529, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/2PPp3/2N5/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/2PPN3/8/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/1bPPN3/8/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/1bPP4/2N5/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/2PPp3/2N5/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/2PPN3/8/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/1bPPN3/8/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/1bPPN3/8/PP1B1PPP/R2QKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnb1k1nr/pp3ppp/2p1p3/8/1bPqN3/8/PP1B1PPP/R2QKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnb1k1nr/pp3ppp/2p1p3/8/1BPqN3/8/PP3PPP/R2QKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnb1k1nr/pp3ppp/2p1p3/8/1BP1q3/8/PP3PPP/R2QKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnb1k1nr/pp3ppp/2p1p3/8/1BP1q3/8/PP2BPPP/R2QK1NR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"r1b1k1nr/pp3ppp/n1p1p3/8/1BP1q3/8/PP2BPPP/R2QK1NR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/2PPp3/2N5/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/8/2PPN3/8/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/1bPPN3/8/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqk1nr/pp3ppp/2p1p3/8/1bPPN3/8/PP1B1PPP/R2QKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnb1k1nr/pp3ppp/2p1p3/8/1bPqN3/8/PP1B1PPP/R2QKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnb1k1nr/pp3ppp/2p1p3/8/1BPqN3/8/PP3PPP/R2QKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnb1k1nr/pp3ppp/2p1p3/8/1BP1q3/8/PP3PPP/R2QKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnb1k1nr/pp3ppp/2p1p3/8/1BP1q3/8/PP2NPPP/R2QKB1R", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqkbnr/pp3ppp/2p1p3/3p4/2PPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 529, "fen":"rnbqk1nr/pp3ppp/2p1p3/3p4/1bPPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Semi-Slav Defense, Marshall Gambit"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp5/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp1P3/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1p2P3/3p4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1N2P3/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/4pn2/1p2P3/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/4pP2/1p6/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1p1p/4pp2/1p6/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp5/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp1P3/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1p2P3/3p4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1N2P3/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/4pn2/1p2P3/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/4pP2/1p6/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1b1kb1r/3n1ppp/1q2pP2/1p6/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/8/1p1P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/8/1p1PN3/3BPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1p3/8/1p1Pn3/3BPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/8/1p1P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/8/Np1P4/3BPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/8/1p1P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/8/1p1PN3/3BPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqk2r/p2nbppp/2p1pn2/8/1p1PN3/3BPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqk2r/p2nbppp/2p1pn2/8/1p1PN3/3BPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp5/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp1P3/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3p3/1pp1P3/3P2n1/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp5/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp1P3/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1p2P3/3p4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1N2P3/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3p3/1N2P3/3p2n1/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3p3/1N2P3/Q2p2n1/3B1N2/PP3PPP/R1B1K2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3p3/1N2n3/Q2p4/3B1N2/PP3PPP/R1B1K2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp5/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1ppP4/4P3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1b1kb1r/2qn1ppp/p3pn2/1ppP4/4P3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp5/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1ppP4/4P3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1p1P4/2p1P3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3Pn2/1p6/2p1P3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n2pp/p3pn2/1p6/2p1P3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n2pp/p3pn2/1p6/2p1P3/2N2N2/PPB2PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1b1kb1r/2qn2pp/p3pn2/1p6/2p1P3/2N2N2/PPB2PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1b1kb1r/2qn2pp/p3pn2/1p6/2p1P3/2N2N2/PPB2PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp5/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp1P3/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1p2P3/3p4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1N2P3/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/5ppp/p3pn2/1N2n3/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/5ppp/p3pn2/1N2N3/3p4/3B4/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/5ppp/4pn2/1p2N3/3p4/3B4/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/5ppp/4pn2/1p2N3/3p4/3B4/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp5/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp1P3/3P4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1p2P3/3p4/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1N2P3/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/5ppp/p3pn2/1N2n3/3p4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/5ppp/p3pn2/1N2N3/3p4/3B4/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/5ppp/4pn2/1p2N3/3p4/3B4/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/5ppp/4pn2/1B2N3/3p4/8/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/8/1p1PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/8/Np1PP3/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/4pn2/2p5/Np1PP3/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/4pn2/2p1P3/Np1P4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/4p3/2pnP3/Np1P4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/4p3/2NnP3/1p1P4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/8/1p1PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/8/Np1PP3/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/4pn2/2p5/Np1PP3/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/4pn2/2p1P3/Np1P4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/4p3/2pnP3/Np1P4/3B1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/4p3/2pnP3/Np1P4/3B1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/8/1p1P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/8/1p1PN3/3BPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qk2r/pb1nbppp/2p1pn2/8/1p1PN3/3BPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qk2r/pb1nbppp/2p1pN2/8/1p1P4/3BPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qk2r/pb2bppp/2p1pn2/8/1p1P4/3BPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qk2r/pb2bppp/2p1pn2/8/1p1PP3/3B1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/1p6/3P4/P1NBPN2/1P3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/1b1n1ppp/p1p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqk2r/p2n1ppp/2pbpn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp5/3P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p3pn2/1pp5/3P4/2NBPN2/PP2QPPP/R1B2RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/8/1p1P4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/1b1n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/1b1n1ppp/p1p1pn2/1p6/3PP3/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1pp5/3PP3/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/1b1n1ppp/p3pn2/1ppP4/4P3/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r3kb1r/1bqn1ppp/p3pn2/1ppP4/4P3/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r3kb1r/1bqn1ppp/p3Pn2/1pp5/4P3/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r3kb1r/1bqn2pp/p3pn2/1pp5/4P3/2NB1N2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/1BN1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/8/1p1P4/1BN1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/1p6/3P4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/pb1n1ppp/2p1pn2/1p6/3P4/2N1PN2/PP2BPPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r2qkb1r/1b1n1ppp/p1p1pn2/1p6/3P4/2N1PN2/PP2BPPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2pP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/p2n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 530, "fen":"r1bqkb1r/3n1ppp/p1p1pn2/1p6/P2P4/2NBPN2/1P3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Meran Variation"},
{"gid": 531, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Normal Variation"},
{"gid": 531, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Normal Variation"},
{"gid": 531, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Normal Variation"},
{"gid": 531, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Normal Variation"},
{"gid": 531, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Normal Variation"},
{"gid": 531, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Normal Variation"},
{"gid": 531, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Normal Variation"},
{"gid": 531, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Normal Variation"},
{"gid": 531, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Normal Variation"},
{"gid": 531, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Normal Variation"},
{"gid": 531, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Normal Variation"},
{"gid": 532, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"r1bqk2r/pp1n1ppp/2p1pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"r1bqk2r/pp1n1ppp/2p1pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 532, "fen":"r1bq1rk1/pp1n1ppp/2p1pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1", "treename":"Semi-Slav Defense, Romih Variation"},
{"gid": 533, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Rubinstein (Anti-Meran) System"},
{"gid": 533, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Rubinstein (Anti-Meran) System"},
{"gid": 533, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Rubinstein (Anti-Meran) System"},
{"gid": 533, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Rubinstein (Anti-Meran) System"},
{"gid": 533, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Rubinstein (Anti-Meran) System"},
{"gid": 533, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Rubinstein (Anti-Meran) System"},
{"gid": 533, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Rubinstein (Anti-Meran) System"},
{"gid": 533, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Rubinstein (Anti-Meran) System"},
{"gid": 533, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Rubinstein (Anti-Meran) System"},
{"gid": 533, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Rubinstein (Anti-Meran) System"},
{"gid": 533, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3pN3/2PP4/2N1P3/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Rubinstein (Anti-Meran) System"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PPP3/2N2N2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP2P1/2N1PN2/PPQ2P1P/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2p1pn2/3p4/1bPP2P1/2N1PN2/PPQ2P1P/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP2P1/2N1PN2/PPQ2P1P/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/8/2pP2P1/2N1PN2/PPQ2P1P/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP2P1/2N1PN2/PPQ2P1P/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1pp1/2pbpn1p/3p4/2PP2P1/2N1PN2/PPQ2P1P/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP2P1/2N1PN2/PPQ2P1P/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbp3/3p4/2PP2n1/2N1PN2/PPQ2P1P/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ1BPPP/R1B1K2R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ1BPPP/R1B1K2R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ1BPPP/R1B2RK1", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pb1n2/3pp3/2PP4/2N1PN2/PPQ1BPPP/R1B2RK1", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1nbppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/p2n1ppp/1pp1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ1BPPP/R1B1K2R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ1BPPP/R1B1K2R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ1BPPP/R1B2RK1", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1b2rk1/pp1nqppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ1BPPP/R1B2RK1", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ1BPPP/R1B1K2R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ1BPPP/R1B1K2R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ1BPPP/R1B2RK1", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqr1k1/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ1BPPP/R1B2RK1", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/1PN1PN2/P1Q2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/1PN1PN2/P1Q2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/1PN1PN2/P1Q1BPPP/R1B1K2R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/p2n1ppp/1ppbpn2/3p4/2PP4/1PN1PN2/P1Q1BPPP/R1B1K2R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/1PN1PN2/P1Q2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/1PN1PN2/P1Q2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/1PN1PN2/P1Q1BPPP/R1B1K2R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqr1k1/pp1n1ppp/2pbpn2/3p4/2PP4/1PN1PN2/P1Q1BPPP/R1B1K2R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/1PN1PN2/P1Q2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/1PN1PN2/P1Q2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bq1rk1/pp1n1ppp/2pbpn2/3p4/2PP4/1PN1PN2/PBQ2PPP/R3KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 534, "fen":"r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2N1PN2/PPQB1PPP/R3KB1R", "treename":"Semi-Slav Defense, Stoltz Variation"},
{"gid": 535, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense Accepted, Main Line"},
{"gid": 535, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Semi-Slav Defense Accepted, Main Line"},
{"gid": 535, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense Accepted, Main Line"},
{"gid": 535, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Semi-Slav Defense Accepted, Main Line"},
{"gid": 535, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense Accepted, Main Line"},
{"gid": 535, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Semi-Slav Defense Accepted, Main Line"},
{"gid": 535, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense Accepted, Main Line"},
{"gid": 535, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Semi-Slav Defense Accepted, Main Line"},
{"gid": 535, "fen":"rnbqkb1r/pp3ppp/2p1pn2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense Accepted, Main Line"},
{"gid": 535, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Semi-Slav Defense Accepted, Main Line"},
{"gid": 535, "fen":"rnbqkb1r/pp3ppp/2p1pn2/6B1/P1pP4/2N2N2/1P2PPPP/R2QKB1R", "treename":"Semi-Slav Defense Accepted, Main Line"},
{"gid": 536, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Alapin Variation"},
{"gid": 536, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Alapin Variation"},
{"gid": 536, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Alapin Variation"},
{"gid": 536, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Alapin Variation"},
{"gid": 536, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Alapin Variation"},
{"gid": 536, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Alapin Variation"},
{"gid": 536, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Alapin Variation"},
{"gid": 536, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Alapin Variation"},
{"gid": 536, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Alapin Variation"},
{"gid": 536, "fen":"rnbqkb1r/1p2pppp/2p2n2/p7/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Alapin Variation"},
{"gid": 537, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkb1r/p3pppp/2p2n2/1p6/2pP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkb1r/p3pppp/2p2n2/1p6/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkb1r/p3pppp/2p2n2/8/PppP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 537, "fen":"rnbqkb1r/p3pppp/2p2n2/8/PppP4/4PN2/1P3PPP/RNBQKB1R", "treename":"Slav Defense, Alekhine Variation"},
{"gid": 538, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Breyer Variation"},
{"gid": 538, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Breyer Variation"},
{"gid": 538, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Breyer Variation"},
{"gid": 538, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Breyer Variation"},
{"gid": 538, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Breyer Variation"},
{"gid": 538, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Breyer Variation"},
{"gid": 538, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP1NPPPP/R1BQKB1R", "treename":"Slav Defense, Breyer Variation"},
{"gid": 539, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p2pppp/p1p2n2/2Pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"r1bqkb1r/1p1npppp/p1p2n2/2Pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3pN3/2PP4/2N5/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/4pppp/p1p2n2/1p1p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/4pppp/p1p2n2/1p1p4/2PP4/1PN1PN2/P4PPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/P1PP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p3ppp/p1p1pn2/3p4/P1PP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 539, "fen":"rnbqkb1r/1p3ppp/p1p1pn2/3p2B1/P1PP4/2N2N2/1P2PPPP/R2QKB1R", "treename":"Slav Defense, Chameleon Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP3N/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP3N/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP3N/2N1P3/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP3N/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP3N/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5N2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p2n2/5p2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p2n2/5p2/P1pP4/2N1P3/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2qkb1r/pp1npppp/2p2n2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2qkb1r/pp1npppp/2p2n2/5b2/P1NP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r3kb1r/ppqnpppp/2p2n2/5b2/P1NP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r3kb1r/ppqnpppp/2p2n2/5b2/P1NP4/2N3P1/1P2PP1P/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r3kb1r/ppqn1ppp/2p2n2/4pb2/P1NP4/2N3P1/1P2PP1P/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r3kb1r/ppqn1ppp/2p2n2/4Pb2/P1N5/2N3P1/1P2PP1P/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r3kb1r/ppq2ppp/2p2n2/4nb2/P1N5/2N3P1/1P2PP1P/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r3kb1r/ppq2ppp/2p2n2/4nb2/P1N2B2/2N3P1/1P2PP1P/R2QKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r3kb1r/ppqn1ppp/2p5/4nb2/P1N2B2/2N3P1/1P2PP1P/R2QKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r3kb1r/ppqn1ppp/2p5/4nb2/P1N2B2/2N3P1/1P2PPBP/R2QK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r3kb1r/ppqn2pp/2p2p2/4nb2/P1N2B2/2N3P1/1P2PPBP/R2QK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/8/PbBP2b1/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/5b2/PbBPP3/2N2N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pnb1/8/PbBPP3/2N2N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pnb1/8/Pb1PP3/2NB1N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1pp1/2p1pnbp/8/Pb1PP3/2NB1N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/5b2/PbBPP3/2N2N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/8/PbBPP1b1/2N2N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/5b2/PbBPP3/2N2N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pnb1/8/PbBPP3/2N2N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pnb1/8/Pb1PP3/2NB1N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2qr1k1/pp1n1ppp/2p1pnb1/8/Pb1PP3/2NB1N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pnb1/8/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pnb1/8/PbBP4/2N1PN2/1P2QPPP/R1BR2K1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pnb1/8/PbBP4/2N1PN2/1P2QPPP/R1BR2K1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pnb1/8/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pnb1/4N3/PbBP4/2N1P3/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pnb1/4N3/PbBP4/2N1P3/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pnN1/8/PbBP4/2N1P3/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1pp1/2p1pnp1/8/PbBP4/2N1P3/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1pp1/2p1pnp1/8/PbBP4/2N1P3/1P2QPPP/R1BR2K1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/5b2/P1BP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQK2R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/5b2/PbBPP3/2N2N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pnb1/8/PbBPP3/2N2N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pnb1/8/Pb1PP3/2NB1N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/7b/Pb1PP3/2NB1N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2q1rk1/pp1n1ppp/2p1pn2/4P2b/Pb1P4/2NB1N2/1P2QPPP/R1B2RK1", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2qkb1r/pp2pppp/n1p2n2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r2qkb1r/pp2pppp/n1p2n2/4Nb2/P1pP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/4Nb2/P1pP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/4Nb2/PbpP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/4Nb2/PbpPP3/2N2P2/1P4PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/4N3/PbpPb3/2N2P2/1P4PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/4N3/PbpPP3/2N5/1P4PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1p3/4N3/PbpPn3/2N5/1P4PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1p3/4N3/PbpPn3/2N5/1P1B2PP/R2QKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn2k2r/pp3ppp/2p1p3/4N3/Pbpqn3/2N5/1P1B2PP/R2QKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn2k2r/pp3ppp/2p1p3/4N3/PbpqN3/8/1P1B2PP/R2QKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn2k2r/pp3ppp/2p1p3/4N3/Pbp1q3/8/1P1B2PP/R2QKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn2k2r/pp3ppp/2p1p3/4N3/Pbp1q3/8/1P1BQ1PP/R3KB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn2k2r/pp3ppp/2p1p3/4N3/P1p1q3/8/1P1bQ1PP/R3KB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn2k2r/pp3ppp/2p1p3/4N3/P1p1q3/8/1P1KQ1PP/R4B1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn2k2r/pp3ppp/2p1p3/3qN3/P1p5/8/1P1KQ1PP/R4B1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn2k2r/pp3ppp/2p1p3/3qN3/P1p5/8/1PK1Q1PP/R4B1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r3k2r/pp3ppp/n1p1p3/3qN3/P1p5/8/1PK1Q1PP/R4B1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"r3k2r/pp3ppp/n1p1p3/3q4/P1N5/8/1PK1Q1PP/R4B1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"2kr3r/pp3ppp/n1p1p3/3q4/P1N5/8/1PK1Q1PP/R4B1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/4Nb2/P1pP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/4Nb2/PbpP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/4NbB1/PbpP4/2N2P2/1P2P1PP/R2QKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/4Nb2/P1pP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/4pn2/2p1Nb2/P1pP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp2pppp/2p2n2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qkb1r/pp3ppp/2p1pn2/4Nb2/P1pP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/4Nb2/PbpP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 540, "fen":"rn1qk2r/pp3ppp/2p1pn2/5b2/PbNP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Czech Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/1p2pppp/p1n2n2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3pNb2/3P1B2/2N1P3/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p1b2/3P1B2/2NBPN2/PP3PPP/R2QK2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p4/3P1B2/2NbPN2/PP3PPP/R2QK2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p4/3P1B2/2NQPN2/PP3PPP/R3K2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qk2r/pp3ppp/2nbpn2/3p4/3P1B2/2NQPN2/PP3PPP/R3K2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qk2r/pp3ppp/2nBpn2/3p4/3P4/2NQPN2/PP3PPP/R3K2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r3k2r/pp3ppp/2nqpn2/3p4/3P4/2NQPN2/PP3PPP/R3K2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r3k2r/pp3ppp/2nqpn2/3p4/3P4/2NQPN2/PP3PPP/R4RK1", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r4rk1/pp3ppp/2nqpn2/3p4/3P4/2NQPN2/PP3PPP/R4RK1", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r4rk1/pp3ppp/2nqpn2/3p4/3P4/2NQPN2/PP3PPP/2R2RK1", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p1b2/3P1B2/2NBPN2/PP3PPP/R2QK2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p4/3P1B2/2NbPN2/PP3PPP/R2QK2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p4/3P1B2/2NQPN2/PP3PPP/R3K2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qk2r/pp3ppp/2nbpn2/3p4/3P1B2/2NQPN2/PP3PPP/R3K2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qk2r/pp3ppp/2nBpn2/3p4/3P4/2NQPN2/PP3PPP/R3K2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r3k2r/pp3ppp/2nqpn2/3p4/3P4/2NQPN2/PP3PPP/R3K2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r3k2r/pp3ppp/2nqpn2/3p4/3P4/2NQPN2/PP3PPP/R4RK1", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r4rk1/pp3ppp/2nqpn2/3p4/3P4/2NQPN2/PP3PPP/R4RK1", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r4rk1/pp3ppp/2nqpn2/3p4/3P4/2NQPN2/PP3PPP/R1R3K1", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1r3k1/pp3ppp/2nqpn2/3p4/3P4/2NQPN2/PP3PPP/R1R3K1", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/1B1p1b2/3P1B2/2N1PN2/PP3PPP/R2QK2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp1n1ppp/2n1p3/1B1p1b2/3P1B2/2N1PN2/PP3PPP/R2QK2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp1n1ppp/2n1p3/1B1p1b2/Q2P1B2/2N1PN2/PP3PPP/R3K2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p1b2/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/pp3ppp/2n1pn2/3p1b2/3P1B2/1QN1PN2/PP3PPP/R3KB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qk2r/pp3ppp/2n1pn2/3p1b2/1b1P1B2/1QN1PN2/PP3PPP/R3KB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp3ppp/2n1pn2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp3ppp/2n1pn2/3p4/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqk2r/pp2bppp/2n1pn2/3p4/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N5/PP2PPPP/R2QKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/1p2pppp/p1n2n2/3p4/3P1B2/2N1P3/PP3PPP/R2QKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp3ppp/2n1pn2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp3ppp/2n1pn2/3p4/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqk2r/pp3ppp/2nbpn2/3p4/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqk2r/pp3ppp/2nBpn2/3p4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1b1k2r/pp3ppp/2nqpn2/3p4/3P4/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp3ppp/4pn2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/1p2pppp/p1n2n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r1bqkb1r/1p2pppp/p1n2n2/3p4/3P1B2/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/1p2pppp/p1n2n2/3p4/3P1Bb1/2N1PN2/PP3PPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"r2qkb1r/1p2pppp/p1n2n2/3p4/3P1Bb1/2N1PN2/PP2BPPP/R2QK2R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/1p2pppp/p4n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/1p2pppp/p4n2/3pN3/3P4/2N5/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/pp2pppp/5n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/1p2pppp/p4n2/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkb1r/1p2pppp/p4n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P1B2/8/PP2PPPP/RN1QKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Exchange Variation"},
{"gid": 541, "fen":"rnbqkbnr/pp2pppp/8/3p4/3P4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Exchange Variation"},
{"gid": 542, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/p3pppp/2p2n2/1p6/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/p3pppp/2p2n2/1p6/2pPP3/2N2N2/PPQ2PPP/R1B1KB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/p3pppp/2p2n2/1p6/2pPP3/2N2N2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/p3pppp/2p2n2/1p2P3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/p3pppp/2p5/1p1nP3/2pP4/2N2N2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/p3pppp/2p5/1p1nP3/P1pP4/2N2N2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 542, "fen":"rnbqkb1r/p4ppp/2p1p3/1p1nP3/P1pP4/2N2N2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Geller Gambit"},
{"gid": 544, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2PP2b1/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/3p4/2PP2b1/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2qk2r/pp1n1ppp/2pbpn2/3p4/2PP2b1/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3pp1/2p1pn1p/3p1b2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3pp1/2p1pn1p/3p1b2/2PP4/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2PP2b1/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/3p4/2PP2b1/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2qk2r/pp1n1ppp/2pbpn2/3p4/2PP2b1/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2qk2r/pp1n1ppp/2pbpn2/3p4/2PP2b1/1P3NP1/PB2PPBP/RN1Q1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2q1rk1/pp1n1ppp/2pbpn2/3p4/2PP2b1/1P3NP1/PB2PPBP/RN1Q1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2q1rk1/pp1n1ppp/2pbpn2/3p4/2PP2b1/1P3NP1/PB1NPPBP/R2Q1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2PP2b1/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/3p4/2PP2b1/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/3p4/2PP2b1/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2qk2r/pp1nbppp/2p1pn2/3p4/2PP2b1/5NP1/PP1NPPBP/R1BQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3pp1/2p1pn1p/3p1b2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3pp1/2p1pn1p/3p1b2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qk2r/pp2bppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PPQ1PPPP/RNB1KB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pp1p/2p2np1/3p4/2PP4/5N2/PPQ1PPPP/RNB1KB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pp1p/2p2np1/3p4/2PP1B2/5N2/PPQ1PPPP/RN2KB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/1Q3N2/PP2PPPP/RNB1KB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/5NP1/PP2PP1P/RNBQKB1R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQK2R", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3pp1/2p1pn1p/3p1b2/2PP4/5NP1/PP2PPBP/RNBQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 544, "fen":"rn1qkb1r/pp3pp1/2p1pn1p/3p1b2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Slav Defense, Modern"},
{"gid": 545, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rn1qkbnr/pp2pppp/2p1b3/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/8/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/p3pppp/2p5/1p6/2pP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/p3pppp/2p5/1p6/P1pP4/4PN2/1P3PPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/p4ppp/2p1p3/1p6/P1pP4/4PN2/1P3PPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/8/2pP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 545, "fen":"rnbqkbnr/pp2pppp/2p5/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Modern Line"},
{"gid": 546, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2PP2b1/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2PP2b1/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP3N/2N1P3/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2PP2bN/2N1P3/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/3BPN2/PP3PPP/RNBQK2R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/2PP4/3bPN2/PP3PPP/RNBQK2R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/2NBPN2/PP3PPP/R1BQK2R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2PP4/2NbPN2/PP3PPP/R1BQK2R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2PP4/2NQPN2/PP3PPP/R1B1K2R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NQPN2/PP3PPP/R1B1K2R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP3N/2N1P3/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p4/2PPb2N/2N1P3/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3P1b2/3P4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/5n2/3p1b2/3P4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/5n2/3p1b2/3P4/1Q2PN2/PP3PPP/RNB1KB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn2kb1r/ppq1pppp/5n2/3p1b2/3P4/1Q2PN2/PP3PPP/RNB1KB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/2PP4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/1Q2PN2/PP3PPP/RNB1KB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pp1p/2p2np1/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 546, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R", "treename":"Slav Defense, Quiet Variation"},
{"gid": 552, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Süchting Variation"},
{"gid": 552, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Süchting Variation"},
{"gid": 552, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Süchting Variation"},
{"gid": 552, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Süchting Variation"},
{"gid": 552, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Süchting Variation"},
{"gid": 552, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Süchting Variation"},
{"gid": 552, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Süchting Variation"},
{"gid": 552, "fen":"rnb1kb1r/pp2pppp/1qp2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Süchting Variation"},
{"gid": 547, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pp2pp1p/2p2np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pp2pp1p/2p2np1/3P4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pp2pp1p/5np1/3p4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pp2pp1p/2p2np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 547, "fen":"rnbqkb1r/pp2pp1p/2p2np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R", "treename":"Slav Defense, Schlechter Variation"},
{"gid": 548, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Slav Gambit"},
{"gid": 548, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Slav Gambit"},
{"gid": 548, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Slav Gambit"},
{"gid": 548, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Slav Gambit"},
{"gid": 548, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Slav Gambit"},
{"gid": 548, "fen":"rnbqkbnr/pp2pppp/2p5/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Slav Gambit"},
{"gid": 548, "fen":"rnbqkbnr/pp2pppp/2p5/8/2pPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Slav Defense, Slav Gambit"},
{"gid": 548, "fen":"rnbqkbnr/p3pppp/2p5/1p6/2pPP3/2N5/PP3PPP/R1BQKBNR", "treename":"Slav Defense, Slav Gambit"},
{"gid": 548, "fen":"rnbqkbnr/p3pppp/2p5/1p6/P1pPP3/2N5/1P3PPP/R1BQKBNR", "treename":"Slav Defense, Slav Gambit"},
{"gid": 549, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"r1bqkb1r/pp2pppp/n1p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"r1bqkb1r/pp2pppp/n1p2n2/8/P1pP4/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"r2qkb1r/pp2pppp/n1p2n2/8/P1pP2b1/2N1PN2/1P3PPP/R1BQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"r1bqkb1r/pp2pppp/n1p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 549, "fen":"r1bqkb1r/pp2pppp/n1p2n2/4N3/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Smyslov Variation"},
{"gid": 550, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Soultanbeieff Variation"},
{"gid": 550, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Soultanbeieff Variation"},
{"gid": 550, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Soultanbeieff Variation"},
{"gid": 550, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Soultanbeieff Variation"},
{"gid": 550, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Soultanbeieff Variation"},
{"gid": 550, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Soultanbeieff Variation"},
{"gid": 550, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Soultanbeieff Variation"},
{"gid": 550, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Soultanbeieff Variation"},
{"gid": 550, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Soultanbeieff Variation"},
{"gid": 550, "fen":"rnbqkb1r/pp3ppp/2p1pn2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Soultanbeieff Variation"},
{"gid": 551, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/8/P1pP2b1/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/4N3/P1pP2b1/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/4N2b/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/4N2b/P1pP4/2N4P/1P2PPP1/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/8/P1pP2b1/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/4N3/P1pP2b1/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/4N2b/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/4N2b/P1pP4/2N3P1/1P2PP1P/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/8/P1pP2b1/2N2N2/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/4N3/P1pP2b1/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/4N2b/P1pP4/2N5/1P2PPPP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp2pppp/2p2n2/4N2b/P1pP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp1npppp/2p5/4N2b/P1pP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp1npppp/2p5/7b/P1NP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp1n1ppp/2p5/4p2b/P1NP4/2N2P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 551, "fen":"rn1qkb1r/pp1n1ppp/2p5/4p2b/P1NPN3/5P2/1P2P1PP/R1BQKB1R", "treename":"Slav Defense, Steiner Variation"},
{"gid": 553, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Two Knights Attack"},
{"gid": 553, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Two Knights Attack"},
{"gid": 553, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Two Knights Attack"},
{"gid": 553, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Two Knights Attack"},
{"gid": 553, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Two Knights Attack"},
{"gid": 553, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R", "treename":"Slav Defense, Two Knights Attack"},
{"gid": 553, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Two Knights Attack"},
{"gid": 553, "fen":"rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Two Knights Attack"},
{"gid": 553, "fen":"rnbqkb1r/pp2pppp/2p2n2/4N3/2pP4/2N5/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Two Knights Attack"},
{"gid": 554, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/3pp3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/3Pp3/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/8/3pp3/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/8/3pp3/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/3pp3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/3pP3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/4P3/2Pp4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/4P3/2PpN3/8/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnb1kbnr/pp3ppp/2p5/q3P3/2PpN3/8/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnb1kbnr/pp3ppp/2p5/q3P3/2Pp4/8/PP1NPPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/3pp3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/3pP3/2P5/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/4P3/2Pp4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/4P3/2PpN3/8/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnb1kbnr/pp3ppp/2p5/q3P3/2PpN3/8/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnb1kbnr/pp3ppp/2p5/q3P3/2PpN3/8/PP1BPPPP/R2QKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/3pp3/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/2p5/3Pp3/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/8/3pp3/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 554, "fen":"rnbqkbnr/pp3ppp/8/3pP3/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Winawer Countergambit"},
{"gid": 543, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2N1P3/PPQ2PPP/R1B1KBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/4pppp/p1p2n2/1p1p4/2PP4/2N1P3/PPQ2PPP/R1B1KBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/4P3/PP3PPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4P3/PP3PPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/4P3/PP3PPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rn1qkbnr/pp2pppp/2p5/3p1b2/2PP4/4P3/PP3PPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/8/P1pP4/2N5/1P2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2NBP3/PP3PPP/R1BQK1NR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkb1r/pp2pppp/2p2n2/6B1/2pP4/2N5/PP2PPPP/R2QKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/8/2pP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 543, "fen":"rnbqkbnr/pp2pppp/2p5/8/2pP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Slav Defense, Main Line"},
{"gid": 555, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp2B1/3P4/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/3p2B1/2pP4/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/3pN1B1/2pP4/2N3P1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r2q1rk1/pp2bppp/2n1bn2/3pN1B1/2pP4/2N3P1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r2q1rk1/pp2bppp/2N1bn2/3p2B1/2pP4/2N3P1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2Pp4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2P5/3p4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp2B1/3P4/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/3p2B1/3p4/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/3p2B1/3N4/2N3P1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bpp1/2n2n1p/3p2B1/3N4/2N3P1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bpp1/2n2n1p/3p4/3N4/2N1B1P1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqr1k1/pp2bpp1/2n2n1p/3p4/3N4/2N1B1P1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqr1k1/pp2bpp1/2n2n1p/3p4/3N4/2N1B1P1/PP2PPBP/2RQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqrbk1/pp3pp1/2n2n1p/3p4/3N4/2N1B1P1/PP2PPBP/2RQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqrbk1/pp3pp1/2N2n1p/3p4/8/2N1B1P1/PP2PPBP/2RQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp2B1/3P4/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/3p2B1/3p4/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/3p2B1/3N4/2N3P1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bpp1/2n2n1p/3p2B1/3N4/2N3P1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bpp1/2n2n1p/3p4/3N4/2N1B1P1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqr1k1/pp2bpp1/2n2n1p/3p4/3N4/2N1B1P1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqr1k1/pp2bpp1/2n2n1p/3p4/3N4/1QN1B1P1/PP2PPBP/R4RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2Pp4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp3ppp/2n2n2/2bp4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp3ppp/2n2n2/2bp4/N7/5NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n5/2pp4/3Pn3/1PN2NP1/P3PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2Pp4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp3ppp/2n2n2/2bp4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp3ppp/2n2n2/2bp2B1/8/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp3ppp/2n2n2/2b3B1/3p4/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp3ppp/2n2B2/2b5/3p4/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1b2rk1/pp3ppp/2n2q2/2b5/3p4/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1b2rk1/pp3ppp/2n2q2/2bN4/3p4/5NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp3ppp/2n5/2bN4/3p4/5NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp3ppp/2n5/2bN4/3p4/6P1/PP1NPPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqr1k1/pp3ppp/2n5/2bN4/3p4/6P1/PP1NPPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P1B2/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N1BNP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp2bppp/2n2n2/2Pp4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp3ppp/2n2n2/2bp4/8/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r1bq1rk1/pp3ppp/2n2n2/2bp2B1/8/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r2q1rk1/pp3ppp/2n1bn2/2bp2B1/8/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 555, "fen":"r2q1rk1/pp3ppp/2n1bB2/2bp4/8/2N2NP1/PP2PPBP/R2Q1RK1", "treename":"Tarrasch Defense, Classical Variation"},
{"gid": 557, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r2qk2r/pp2bppp/2n1bn2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 557, "fen":"r2qkb1r/pp3ppp/2n1bn2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Prague Variation"},
{"gid": 558, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 558, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 558, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 558, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 558, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 558, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 558, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 558, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 558, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 558, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 558, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 558, "fen":"r1bqkbnr/pp3ppp/2n5/3p4/3p4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Rubinstein System"},
{"gid": 559, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/3p4/2pP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/3p4/2pP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk1nr/pp3ppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk1nr/pp3ppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk2r/pp2nppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk2r/pp2nppp/2n5/3p4/1bpPP3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk2r/pp2nppp/2n5/8/1bpPp3/2N2NP1/PP3PBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/3p4/2pP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/3p4/2pP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk1nr/pp3ppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk1nr/pp3ppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk2r/pp2nppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk2r/pp2nppp/2n5/3p4/1bpP4/2N2NP1/PP1BPPBP/R2Q1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/3p4/2pP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/3p4/2pP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk1nr/pp3ppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk1nr/pp3ppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk2r/pp2nppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk2r/pp2nppp/2n5/3pN3/1bpP4/2N3P1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/3p4/2pP4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqkbnr/pp3ppp/2n5/3p4/2pP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk1nr/pp3ppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk1nr/pp3ppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk2r/pp2nppp/2n5/3p4/1bpP4/2N2NP1/PP2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 559, "fen":"r1bqk2r/pp2nppp/2n5/3p4/1bpP4/P1N2NP1/1P2PPBP/R1BQ1RK1", "treename":"Tarrasch Defense, Swedish Variation"},
{"gid": 560, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2pp4/2PP4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2Pp4/2P5/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqk2r/1p3ppp/p1n1pn2/2bp4/2P5/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqk2r/1p3ppp/p1n1pn2/2bp4/1PP5/P1N1PN2/5PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqk2r/bp3ppp/p1n1pn2/3p4/1PP5/P1N1PN2/5PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1p3/2pp4/2PPn3/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2p5/2pP4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/3p4/2Pp4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/3p4/2PP4/P1N2N2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqk2r/pp2bppp/2n1pn2/3p4/2PP4/P1N2N2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqk2r/pp2bppp/2n1pn2/3p4/2PP4/P1NB1N2/1P3PPP/R1BQK2R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2pp4/2PP4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/1p3ppp/p1n1pn2/2pp4/2PP4/PPN1PN2/5PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/3p4/2Pp4/P1N1PN2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqkb1r/pp3ppp/2n1pn2/3p4/2PP4/P1N2N2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqk2r/pp2bppp/2n1pn2/3p4/2PP4/P1N2N2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 560, "fen":"r1bqk2r/pp2bppp/2n1pn2/2Pp4/3P4/P1N2N2/1P3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Symmetrical Variation"},
{"gid": 561, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 561, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 561, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 561, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 561, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 561, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 561, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 561, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 561, "fen":"rnbqkbnr/pp3ppp/8/2Pp4/8/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 561, "fen":"rnbqkbnr/pp3ppp/8/2P5/3p4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 561, "fen":"rnbqkbnr/pp3ppp/8/2P5/N2p4/8/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 561, "fen":"rnbqkbnr/p4ppp/8/1pP5/N2p4/8/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Tarrasch Gambit"},
{"gid": 562, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkbnr/pp3ppp/2n5/2pp2B1/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqk1nr/pp2bppp/2n5/2pp2B1/3P4/2N2N2/PP2PPPP/R2QKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkb1r/pp3ppp/2n2n2/3p4/3p4/2N1PN2/PP2BPPP/R1BQK2R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkb1r/pp3ppp/2n2n2/3p4/3N4/2N1P3/PP2BPPP/R1BQK2R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqk2r/pp3ppp/2nb1n2/3p4/3N4/2N1P3/PP2BPPP/R1BQK2R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqk2r/pp3ppp/2nb1n2/3p4/3N4/2N1P3/PP2BPPP/R1BQ1RK1", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bq1rk1/pp3ppp/2nb1n2/3p4/3N4/2N1P3/PP2BPPP/R1BQ1RK1", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bq1rk1/pp3ppp/2nb1n2/3p4/3N4/1PN1P3/P3BPPP/R1BQ1RK1", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P1B2/2N2N2/PP2PPPP/R2QKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N1PN2/PP3PPP/R1BQKB1R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 562, "fen":"r1bqkb1r/pp3ppp/2n2n2/1Bpp4/3P4/2N1PN2/PP3PPP/R1BQK2R", "treename":"Tarrasch Defense, Two Knights Variation"},
{"gid": 563, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"rnbqkbnr/pp3ppp/4p3/2pP4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 563, "fen":"r2qkb1r/pp3ppp/2n2n2/2pp4/3P2b1/2N2NP1/PP2PPBP/R1BQK2R", "treename":"Tarrasch Defense, Wagner Variation"},
{"gid": 556, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Main Line"},
{"gid": 556, "fen":"rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Main Line"},
{"gid": 556, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Main Line"},
{"gid": 556, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR", "treename":"Tarrasch Defense, Main Line"},
{"gid": 556, "fen":"rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Main Line"},
{"gid": 556, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR", "treename":"Tarrasch Defense, Main Line"},
{"gid": 556, "fen":"rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N1P3/PP3PPP/R1BQKBNR", "treename":"Tarrasch Defense, Main Line"},
{"gid": 564, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1pp1/4pn1p/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1pp1/4pB1p/8/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3PP3/5N2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/ppp2pp1/3ppq1p/8/3PP3/5N2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/ppp2pp1/3ppq1p/8/3PP3/2N2N2/PPP2PPP/R2QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"r1b1kb1r/pppn1pp1/3ppq1p/8/3PP3/2N2N2/PPP2PPP/R2QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"r1b1kb1r/pppn1pp1/3ppq1p/8/3PP3/2N2N2/PPPQ1PPP/R3KB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1pp1/4pn1p/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1pp1/4pn1p/8/3P3B/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1p2/4pn1p/6p1/3P3B/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1pp1/4pn1p/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1pp1/4pB1p/8/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3PP3/5N2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/p1pp1pp1/1p2pq1p/8/3PP3/5N2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1pp1/4pn1p/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnbqkb1r/pppp1pp1/4pB1p/8/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3PP3/5N2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/ppp2pp1/3ppq1p/8/3PP3/5N2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/ppp2pp1/3ppq1p/8/3PP3/2N2N2/PPP2PPP/R2QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 564, "fen":"rnb1kb1r/ppp2p2/3ppqpp/8/3PP3/2N2N2/PPP2PPP/R2QKB1R", "treename":"Torre Attack, Classical Defense"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/6p1/6B1/3Pn3/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/5N2/PPPNPPPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbq1rk1/ppppppbp/5np1/6B1/3P4/5N2/PPPNPPPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbq1rk1/ppppppbp/5np1/6B1/3PP3/5N2/PPPN1PPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbq1rk1/ppp1ppbp/5np1/3p2B1/3PP3/5N2/PPPN1PPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/p1pp1ppp/1p2pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/6B1/3p4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppbppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/5N2/PPPNPPPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbq1rk1/ppppppbp/5np1/6B1/3P4/5N2/PPPNPPPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbq1rk1/ppppppbp/5np1/6B1/3PP3/5N2/PPPN1PPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/3PP3/5N2/PPPN1PPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/2P2N2/PP2PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnb1kb1r/pp1p1ppp/1q2pn2/2p3B1/3P4/2P2N2/PP2PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/pp1pbppp/4pn2/2p3B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/5N2/PPPNPPPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbq1rk1/ppppppbp/5np1/6B1/3P4/5N2/PPPNPPPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbq1rk1/ppppppbp/5np1/6B1/3P4/2P2N2/PP1NPPPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/3P4/2P2N2/PP1NPPPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/6B1/3PP3/2P2N2/PP1N1PPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbq1rk1/pp2ppbp/3p1np1/2p3B1/3PP3/2P2N2/PP1N1PPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/2P2N2/PP2PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1pp1/4pn1p/2p3B1/3P4/2P2N2/PP2PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1pp1/4pn1p/2p5/3P3B/2P2N2/PP2PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnb1kb1r/pp1p1ppp/1q2pn2/2p3B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/2N2N2/PPP1PPPP/R2QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1pp1/4pn1p/2p3B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pp1p1pp1/4pn1p/2p5/3P3B/4PN2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/2P2N2/PP2PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqkb1r/pppppp1p/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 565, "fen":"rnbqk2r/ppppppbp/5np1/6B1/3P4/4PN2/PPP2PPP/RN1QKB1R", "treename":"Torre Attack, Main Line"},
{"gid": 566, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1pp1/4pn1p/6B1/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1pp1/4pB1p/8/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnb1kb1r/ppp2pp1/3ppq1p/8/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnb1kb1r/ppp2pp1/3ppq1p/8/3PP3/2N5/PPPQ1PPP/R3KBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnb1kb1r/ppp2p2/3ppq1p/6p1/3PP3/2N5/PPPQ1PPP/R3KBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1pp1/4pn1p/6B1/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1pp1/4pB1p/8/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3PP3/2P5/PP3PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1ppp/4pn2/6B1/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1pp1/4pn1p/6B1/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnbqkb1r/pppp1pp1/4pB1p/8/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3PP3/8/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnb1kb1r/pppp1pp1/4pq1p/8/3PP3/2N5/PPP2PPP/R2QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 566, "fen":"rnb1k2r/pppp1pp1/4pq1p/8/1b1PP3/2N5/PPP2PPP/R2QKBNR", "treename":"Trompowsky Attack, Classical Defense"},
{"gid": 567, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/8/8/3Pn2B/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/ppp1pppp/8/3p4/3Pn2B/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/8/8/3Pn2B/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pp1ppppp/8/2p5/3Pn2B/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pp1ppppp/8/2p5/3Pn2B/5P2/PPP1P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppppp/8/8/3Pn2B/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 567, "fen":"rnbqkb1r/pppppp1p/8/6p1/3Pn2B/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Edge Variation"},
{"gid": 569, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Poisoned Pawn Variation"},
{"gid": 569, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Poisoned Pawn Variation"},
{"gid": 569, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Poisoned Pawn Variation"},
{"gid": 569, "fen":"rnbqkb1r/pp1ppppp/5n2/2p3B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Poisoned Pawn Variation"},
{"gid": 569, "fen":"rnbqkb1r/pp1ppppp/5n2/2pP2B1/8/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Poisoned Pawn Variation"},
{"gid": 569, "fen":"rnb1kb1r/pp1ppppp/1q3n2/2pP2B1/8/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Poisoned Pawn Variation"},
{"gid": 569, "fen":"rnb1kb1r/pp1ppppp/1q3n2/2pP2B1/8/2N5/PPP1PPPP/R2QKBNR", "treename":"Trompowsky Attack, Poisoned Pawn Variation"},
{"gid": 570, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn2P/8/PPP1PPP1/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pp1ppppp/8/2p3B1/3Pn2P/8/PPP1PPP1/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pp1ppppp/8/2P3B1/4n2P/8/PPP1PPP1/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn2P/8/PPP1PPP1/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/ppp1pppp/8/3p2B1/3Pn2P/8/PPP1PPP1/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn2P/8/PPP1PPP1/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pp1ppppp/8/2p3B1/3Pn2P/8/PPP1PPP1/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 570, "fen":"rnbqkb1r/pp1ppppp/8/2pP2B1/4n2P/8/PPP1PPP1/RN1QKBNR", "treename":"Trompowsky Attack, Raptor Variation"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5B2/3p4/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp2ppp/5p2/3p4/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp2ppp/5p2/3p4/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rn1qkb1r/ppp2ppp/5p2/3p1b2/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/8/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/8/2p5/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/8/2pP4/4nB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppppp/1q6/2pP4/4nB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/8/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/8/3p4/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/8/3p4/3PnB2/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp2pppp/8/2pp4/3PnB2/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p2B1/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppp1p/5np1/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppp1p/5Bp1/8/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppp1p1p/5pp1/8/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppp1p1p/5pp1/8/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/5n2/2p3B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/5B2/2p5/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppp1p/5p2/2p5/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppp1p/5p2/2pP4/8/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q3p2/2pP4/8/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q3p2/2pP4/8/8/PPP1PPPP/RNQ1KBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q6/2pP1p2/8/8/PPP1PPPP/RNQ1KBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q6/2pP1p2/8/4P3/PPP2PPP/RNQ1KBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1k2r/pp1pppbp/1q6/2pP1p2/8/4P3/PPP2PPP/RNQ1KBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/8/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/8/3p4/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/8/3p4/3PnB2/5P2/PPP1P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/3P1B2/5P2/PPP1P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5B2/3p4/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp2ppp/5p2/3p4/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp2ppp/5p2/3p4/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp3ppp/2p2p2/3p4/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5B2/3p4/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pp1p/5p2/3p4/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pp1p/5p2/3p4/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp2pp1p/5p2/2pp4/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5B2/3p4/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp2ppp/5p2/3p4/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp2ppp/5p2/3p4/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rn1qkb1r/ppp2ppp/4bp2/3p4/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/5B2/3p4/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp2ppp/5p2/3p4/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp2ppp/5p2/3p4/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqk2r/ppp2ppp/3b1p2/3p4/3P4/4P3/PPP2PPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/8/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/8/2p5/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/8/2p5/3PnB2/5P2/PPP1P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppppp/8/q1p5/3PnB2/5P2/PPP1P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppppp/8/q1p5/3PnB2/2P2P2/PP2P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppppp/5n2/q1p5/3P1B2/2P2P2/PP2P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppppp/5n2/q1p5/3P1B2/2P2P2/PP1NP1PP/R2QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/5n2/2p3B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/5B2/2p5/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppp1p/5p2/2p5/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppp1p/5p2/2pP4/8/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q3p2/2pP4/8/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q3p2/2pP4/8/8/PPP1PPPP/RNQ1KBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q6/2pP1p2/8/8/PPP1PPPP/RNQ1KBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q6/2pP1p2/2P5/8/PP2PPPP/RNQ1KBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/5n2/2p3B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/5B2/2p5/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppp1p/5p2/2p5/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppp1p/5p2/2pP4/8/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q3p2/2pP4/8/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q3p2/2pP4/8/8/PPP1PPPP/RNQ1KBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q6/2pP1p2/8/8/PPP1PPPP/RNQ1KBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppp1p/1q6/2pP1p2/8/6P1/PPP1PP1P/RNQ1KBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/8/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/8/3p4/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/ppp1pppp/8/3p4/3PnB2/8/PPPNPPPP/R2QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/6B1/3Pn3/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pppppppp/8/8/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/8/2p5/3PnB2/8/PPP1PPPP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnbqkb1r/pp1ppppp/8/2p5/3PnB2/5P2/PPP1P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppppp/8/q1p5/3PnB2/5P2/PPP1P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppppp/8/q1p5/3PnB2/2P2P2/PP2P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppppp/5n2/q1p5/3P1B2/2P2P2/PP2P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 568, "fen":"rnb1kb1r/pp1ppppp/5n2/q1pP4/5B2/2P2P2/PP2P1PP/RN1QKBNR", "treename":"Trompowsky Attack, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/3P2b1/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/3PP1b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkb1r/ppp1pppp/3p1n2/8/3PP1b1/5N2/PPP2PPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/3P2b1/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp2ppp/3p4/4p3/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp2ppp/3p4/4p3/2PP2b1/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"r2qkbnr/ppp2ppp/2np4/4p3/2PP2b1/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/3P2b1/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"r2qkbnr/pppnpppp/3p4/8/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"r2qkbnr/pppnpppp/3p4/8/2PP2b1/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"r2qkbnr/pppn1ppp/3p4/4p3/2PP2b1/2N2N2/PP2PPPP/R1BQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/3P2b1/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/2PP2b1/5N2/PP2PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/2PP4/5b2/PP2PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/3P2b1/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/3P2b1/4PN2/PPP2PPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkb1r/ppp1pppp/3p1n2/8/3P2b1/4PN2/PPP2PPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rnbqkbnr/ppp1pppp/3p4/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/3P2b1/5N2/PPP1PPPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"rn1qkbnr/ppp1pppp/3p4/8/3P2b1/4PN2/PPP2PPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 571, "fen":"r2qkbnr/pppnpppp/3p4/8/3P2b1/4PN2/PPP2PPP/RNBQKB1R", "treename":"Wade Defense, Main Line"},
{"gid": 572, "fen":"rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Yusupov-Rubinstein System, Main Line"},
{"gid": 572, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR", "treename":"Yusupov-Rubinstein System, Main Line"},
{"gid": 572, "fen":"rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Yusupov-Rubinstein System, Main Line"},
{"gid": 572, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/5N2/PPP1PPPP/RNBQKB1R", "treename":"Yusupov-Rubinstein System, Main Line"},
{"gid": 572, "fen":"rnbqkb1r/pppp1ppp/4pn2/8/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Yusupov-Rubinstein System, Main Line"},
{"gid": 572, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P4/4PN2/PPP2PPP/RNBQKB1R", "treename":"Yusupov-Rubinstein System, Main Line"},
{"gid": 572, "fen":"rnbqkb1r/pp1p1ppp/4pn2/2p5/3P4/3BPN2/PPP2PPP/RNBQK2R", "treename":"Yusupov-Rubinstein System, Main Line"},
{"gid": 573, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkbnr/pp2pppp/2n5/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkbnr/pp2pppp/2n5/2pp4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkbnr/pp3ppp/2n1p3/2pp4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkbnr/pp3ppp/2n1p3/2pp4/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/8/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkb1r/pp3p1p/2n1pnp1/2pp4/8/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkb1r/pp3p1p/2n1pnp1/2pp4/4P3/3P1NP1/PPPN1PBP/R1BQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqk2r/pp3pbp/2n1pnp1/2pp4/4P3/3P1NP1/PPPN1PBP/R1BQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkbnr/pp2pppp/2n5/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkbnr/pp2pppp/2n5/2pp4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkbnr/pp3ppp/2n1p3/2pp4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkbnr/pp3ppp/2n1p3/2pp4/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/8/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkb1r/p4ppp/1pn1pn2/2pp4/8/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkb1r/p4ppp/1pn1pn2/2pp4/4P3/3P1NP1/PPPN1PBP/R1BQ1RK1", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkbnr/pp2pppp/2n5/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, French Variation"},
{"gid": 573, "fen":"r1bqkbnr/pp2pppp/2n5/2pp4/3P4/5NP1/PPP1PPBP/RNBQK2R", "treename":"King's Indian Attack, French Variation"},
{"gid": 574, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Keres Variation"},
{"gid": 574, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Keres Variation"},
{"gid": 574, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Keres Variation"},
{"gid": 574, "fen":"rn1qkbnr/ppp1pppp/8/3p4/6b1/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Keres Variation"},
{"gid": 574, "fen":"rn1qkbnr/ppp1pppp/8/3p4/6b1/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Keres Variation"},
{"gid": 574, "fen":"r2qkbnr/pppnpppp/8/3p4/6b1/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Keres Variation"},
{"gid": 576, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Pachman System"},
{"gid": 576, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Pachman System"},
{"gid": 576, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Pachman System"},
{"gid": 576, "fen":"rnbqkbnr/ppp1pp1p/6p1/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Pachman System"},
{"gid": 576, "fen":"rnbqkbnr/ppp1pp1p/6p1/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Pachman System"},
{"gid": 576, "fen":"rnbqk1nr/ppp1ppbp/6p1/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Pachman System"},
{"gid": 576, "fen":"rnbqk1nr/ppp1ppbp/6p1/3p4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Pachman System"},
{"gid": 576, "fen":"rnbqk1nr/ppp2pbp/6p1/3pp3/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Pachman System"},
{"gid": 576, "fen":"rnbqk1nr/ppp2pbp/6p1/3pp3/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Pachman System"},
{"gid": 576, "fen":"rnbqk2r/ppp1npbp/6p1/3pp3/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Pachman System"},
{"gid": 577, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkb1r/pp2pppp/5n2/2pp4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"r1bqkb1r/pp2pppp/2n2n2/2pp4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/pp2pp1p/6p1/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/pp2pp1p/6p1/2pp4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqk1nr/pp2ppbp/6p1/2pp4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqk1nr/pp2ppbp/6p1/2pp4/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqk2r/pp2ppbp/5np1/2pp4/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqk2r/pp2ppbp/5np1/2pp4/8/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbq1rk1/pp2ppbp/5np1/2pp4/8/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"r1bqkbnr/pp2pppp/2n5/2pp4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"r1bqkbnr/pp2pppp/2n5/2pp4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"r1bqkbnr/pp3ppp/2n1p3/2pp4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"r1bqkbnr/pp3ppp/2n1p3/2pp4/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"r1bqkb1r/pp3ppp/2n1pn2/2pp4/8/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"r1bqk2r/pp2bppp/2n1pn2/2pp4/8/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"r1bqk2r/pp2bppp/2n1pn2/2pp4/4P3/3P1NP1/PPPN1PBP/R1BQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"r1bq1rk1/pp2bppp/2n1pn2/2pp4/4P3/3P1NP1/PPPN1PBP/R1BQ1RK1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 577, "fen":"r1bq1rk1/pp2bppp/2n1pn2/2pp4/4P3/3P1NP1/PPPN1PBP/R1BQR1K1", "treename":"King's Indian Attack, Sicilian Variation"},
{"gid": 578, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Smyslov Variation"},
{"gid": 578, "fen":"rnbqkb1r/pppppppp/5n2/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Smyslov Variation"},
{"gid": 578, "fen":"rnbqkb1r/pppppppp/5n2/8/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Smyslov Variation"},
{"gid": 578, "fen":"rnbqkb1r/pppppp1p/5np1/8/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Smyslov Variation"},
{"gid": 578, "fen":"rnbqkb1r/pppppp1p/5np1/8/1P6/5NP1/P1PPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Smyslov Variation"},
{"gid": 578, "fen":"rnbqk2r/ppppppbp/5np1/8/1P6/5NP1/P1PPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Smyslov Variation"},
{"gid": 578, "fen":"rnbqk2r/ppppppbp/5np1/8/1P6/5NP1/PBPPPP1P/RN1QKB1R", "treename":"King's Indian Attack, Smyslov Variation"},
{"gid": 579, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqkb1r/pppppppp/5n2/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqkb1r/pppppppp/5n2/8/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqkb1r/pppppp1p/5np1/8/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqkb1r/pppppp1p/5np1/8/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqk2r/ppppppbp/5np1/8/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqk2r/ppppppbp/5np1/8/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbq1rk1/ppppppbp/5np1/8/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbq1rk1/ppppppbp/5np1/8/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbq1rk1/ppp1ppbp/3p1np1/8/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqkb1r/pppppppp/5n2/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqkb1r/pppppppp/5n2/8/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqkb1r/pppppp1p/5np1/8/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqkb1r/pppppp1p/5np1/8/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqk2r/ppppppbp/5np1/8/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbqk2r/ppppppbp/5np1/8/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbq1rk1/ppppppbp/5np1/8/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbq1rk1/ppppppbp/5np1/8/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 579, "fen":"rnbq1rk1/pp1pppbp/5np1/2p5/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Symmetrical Defense"},
{"gid": 580, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/6b1/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/6b1/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"r2qkb1r/pp1npppp/2p2n2/3p4/6b1/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"r2qkb1r/pp1npppp/2p2n2/3p4/6b1/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"r2qkb1r/pp1n1ppp/2p1pn2/3p4/6b1/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/6b1/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p4/6b1/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"r2qkb1r/pp1npppp/2p2n2/3p4/6b1/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"r2qkb1r/pp1npppp/2p2n2/3p4/6b1/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 580, "fen":"r2qkb1r/pp1n1ppp/2p2n2/3pp3/6b1/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, Yugoslav Variation"},
{"gid": 575, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rn1qkb1r/ppp1pppp/5n2/3p1b2/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/ppp1pp1p/5np1/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"r1bqkbnr/ppp1pppp/2n5/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"r1bqkbnr/ppp2ppp/2n5/3pp3/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"r1bqkbnr/ppp2ppp/2n5/3pp3/8/3P1NP1/PPP1PPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp3/8/3P1NP1/PPP1PPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"r1bqkb1r/ppp2ppp/2n2n2/3pp3/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"r1bqk2r/ppp1bppp/2n2n2/3pp3/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkb1r/pp2pppp/2p2n2/3p4/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rn1qkb1r/pp2pppp/2p2n2/3p1b2/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rn1qkb1r/pp3ppp/2p1pn2/3p1b2/8/3P1NP1/PPPNPPBP/R1BQ1RK1", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rnbqkbnr/pp2pppp/2p5/3p4/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/6b1/5NP1/PPPPPPBP/RNBQK2R", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"rn1qkbnr/pp2pppp/2p5/3p4/6b1/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"r2qkbnr/pp1npppp/2p5/3p4/6b1/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"King's Indian Attack, Main Line"},
{"gid": 575, "fen":"r2qkbnr/pp1npppp/2p5/3p4/6b1/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"King's Indian Attack, Main Line"},
{"gid": 581, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/1P3N2/P1PPPPPP/RNBQKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/1P3N2/P1PPPPPP/RNBQKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"rnbqkb1r/ppp1pppp/5n2/3p4/8/1P3N2/PBPPPPPP/RN1QKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/8/1P3N2/PBPPPPPP/RN1QKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"rnbqkb1r/ppp2ppp/4pn2/3p4/8/1P2PN2/PBPP1PPP/RN1QKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/1P3N2/P1PPPPPP/RNBQKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"rn1qkbnr/ppp1pppp/8/3p4/6b1/1P3N2/P1PPPPPP/RNBQKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"rn1qkbnr/ppp1pppp/8/3p4/6b1/1P3N2/PBPPPPPP/RN1QKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"r2qkbnr/pppnpppp/8/3p4/6b1/1P3N2/PBPPPPPP/RN1QKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 581, "fen":"r2qkbnr/pppnpppp/8/3p4/6b1/1P2PN2/PBPP1PPP/RN1QKB1R", "treename":"Nimzo-Larsen Attack, Classical Variation"},
{"gid": 582, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/2Pp4/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/2Pp4/5NP1/PP1PPP1P/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pp1p/6p1/8/2Pp4/5NP1/PP1PPP1P/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pp1p/6p1/8/2Pp4/5NP1/PP1PPPBP/RNBQK2R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqk1nr/ppp1ppbp/6p1/8/2Pp4/5NP1/PP1PPPBP/RNBQK2R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/2Pp4/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/1PPp4/5N2/P2PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pp1p/6p1/8/1PPp4/5N2/P2PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/2Pp4/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/2Pp4/4PN2/PP1P1PPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/pp2pppp/8/2p5/2Pp4/4PN2/PP1P1PPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/2Pp4/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/2Pp4/4PN2/PP1P1PPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"r1bqkbnr/ppp1pppp/2n5/8/2Pp4/4PN2/PP1P1PPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"r1bqkbnr/ppp1pppp/2n5/8/2PP4/5N2/PP1P1PPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"r1bqkbnr/ppp1pppp/8/8/2Pn4/5N2/PP1P1PPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/2Pp4/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/2Pp4/5NP1/PP1PPP1P/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/pp2pppp/8/2p5/2Pp4/5NP1/PP1PPP1P/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/pp2pppp/8/2p5/2Pp4/5NP1/PP1PPPBP/RNBQK2R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"r1bqkbnr/pp2pppp/2n5/2p5/2Pp4/5NP1/PP1PPPBP/RNBQK2R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"r1bqkbnr/pp2pppp/2n5/2p5/2Pp4/3P1NP1/PP2PPBP/RNBQK2R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"r1bqkbnr/pp3ppp/2n5/2p1p3/2Pp4/3P1NP1/PP2PPBP/RNBQK2R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/2Pp4/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"rnbqkbnr/ppp1pppp/8/8/2Pp4/5NP1/PP1PPP1P/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"r1bqkbnr/ppp1pppp/2n5/8/2Pp4/5NP1/PP1PPP1P/RNBQKB1R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"r1bqkbnr/ppp1pppp/2n5/8/2Pp4/5NP1/PP1PPPBP/RNBQK2R", "treename":"Reti Opening, Advance Variation"},
{"gid": 582, "fen":"r1bqkbnr/ppp2ppp/2n5/4p3/2Pp4/5NP1/PP1PPPBP/RNBQK2R", "treename":"Reti Opening, Advance Variation"},
{"gid": 584, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/8/2p5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/8/2p5/4PN2/PP1P1PPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2p5/4PN2/PP1P1PPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkb1r/ppp1pppp/5n2/8/2B5/4PN2/PP1P1PPP/RNBQK2R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkb1r/ppp2ppp/4pn2/8/2B5/4PN2/PP1P1PPP/RNBQK2R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/8/2p5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/8/2p5/N4N2/PP1PPPPP/R1BQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/pp2pppp/8/2p5/2p5/N4N2/PP1PPPPP/R1BQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/8/2p5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/8/2p5/N4N2/PP1PPPPP/R1BQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/1pp1pppp/p7/8/2p5/N4N2/PP1PPPPP/R1BQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/8/2p5/5N2/PP1PPPPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rnbqkbnr/ppp1pppp/8/8/2p5/4PN2/PP1P1PPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 584, "fen":"rn1qkbnr/ppp1pppp/4b3/8/2p5/4PN2/PP1P1PPP/RNBQKB1R", "treename":"Reti Opening, Reti Gambit Accepted"},
{"gid": 583, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Main Line"},
{"gid": 583, "fen":"rnbqkb1r/pppppppp/5n2/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Main Line"},
{"gid": 583, "fen":"rnbqkb1r/pppppppp/5n2/8/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Reti Opening, Main Line"},
{"gid": 583, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Reti Opening, Main Line"},
{"gid": 583, "fen":"rnbqkb1r/pp1ppppp/5n2/2p5/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Reti Opening, Main Line"},
{"gid": 583, "fen":"r1bqkb1r/pp1ppppp/2n2n2/2p5/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Reti Opening, Main Line"},
{"gid": 583, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Main Line"},
{"gid": 583, "fen":"rnbqkb1r/pppppppp/5n2/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Reti Opening, Main Line"},
{"gid": 583, "fen":"rnbqkb1r/pppppppp/5n2/8/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Reti Opening, Main Line"},
{"gid": 583, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Reti Opening, Main Line"},
{"gid": 583, "fen":"rnbqkb1r/p1pppppp/1p3n2/8/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Reti Opening, Main Line"},
{"gid": 583, "fen":"rn1qkb1r/pbpppppp/1p3n2/8/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Reti Opening, Main Line"},
{"gid": 585, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Zukertort Opening, Lisitsyn Gambit"},
{"gid": 585, "fen":"rnbqkbnr/ppppp1pp/8/5p2/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Zukertort Opening, Lisitsyn Gambit"},
{"gid": 585, "fen":"rnbqkbnr/ppppp1pp/8/5p2/4P3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Zukertort Opening, Lisitsyn Gambit"},
{"gid": 585, "fen":"rnbqkbnr/ppppp1pp/8/8/4p3/5N2/PPPP1PPP/RNBQKB1R", "treename":"Zukertort Opening, Lisitsyn Gambit"},
{"gid": 585, "fen":"rnbqkbnr/ppppp1pp/8/6N1/4p3/8/PPPP1PPP/RNBQKB1R", "treename":"Zukertort Opening, Lisitsyn Gambit"},
{"gid": 585, "fen":"rnbqkbnr/ppp1p1pp/8/3p2N1/4p3/8/PPPP1PPP/RNBQKB1R", "treename":"Zukertort Opening, Lisitsyn Gambit"},
{"gid": 586, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppppp/8/2p5/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppppp/8/2p5/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/p2ppppp/1p6/2p5/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/p2ppppp/1p6/2p5/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rn1qkbnr/pb1ppppp/1p6/2p5/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppppp/8/2p5/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppppp/8/2p5/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk2r/pp1pppbp/2n2np1/2p5/4P3/3P1NP1/PPP2PBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppppp/8/2p5/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppppp/8/2p5/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/3P1NP1/PPP2PBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppppp/8/2p5/8/5N2/PPPPPPPP/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppppp/8/2p5/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/8/5NP1/PPPPPP1P/RNBQKB1R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqkbnr/pp1ppp1p/6p1/2p5/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/8/5NP1/PPPPPPBP/RNBQK2R", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"rnbqk1nr/pp1pppbp/6p1/2p5/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/8/5NP1/PPPPPPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk1nr/pp1pppbp/2n3p1/2p5/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/8/3P1NP1/PPP1PPBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"},
{"gid": 586, "fen":"r1bqk1nr/pp1p1pbp/2n1p1p1/2p5/4P3/3P1NP1/PPP2PBP/RNBQ1RK1", "treename":"Zukertort Opening, Sicilian Invitation"}

        ]
});

