import Vue from 'vue';

export default Vue.observable({
    allopenings: [
{"oid":0,"gid":0,"fullname":"English Opening, Agincourt Defense, Catalan Defense, Semi-Slav Defense","main_line":"1.c4 e6 2.Nf3 d5 3.g3 Nf6 4.Bg2 c6 5.b3 *","weight":7278,"prior_delay":25,"prior_completion_time":0},
{"oid":1,"gid":0,"fullname":"English Opening, Agincourt Defense, Catalan Defense Accepted","main_line":"1.c4 e6 2.Nf3 d5 3.g3 Nf6 4.Bg2 dxc4 5.Qa4+ Nbd7 6.Qxc4 a6 *","weight":8737,"prior_delay":25,"prior_completion_time":0},
{"oid":2,"gid":0,"fullname":"English Opening, Agincourt Defense, Catalan Defense Accepted","main_line":"1.c4 e6 2.Nf3 d5 3.g3 Nf6 4.Bg2 dxc4 5.Qa4+ Nbd7 6.Qxc4 c5 *","weight":8753,"prior_delay":25,"prior_completion_time":0},
{"oid":3,"gid":0,"fullname":"English Opening, Agincourt Defense, Catalan Defense Accepted","main_line":"1.c4 e6 2.Nf3 d5 3.g3 Nf6 4.Bg2 dxc4 5.Qa4+ Nbd7 6.O-O *","weight":8415,"prior_delay":25,"prior_completion_time":0},
{"oid":4,"gid":0,"fullname":"English Opening, Agincourt Defense, Kurajica Defense","main_line":"1.c4 e6 2.Nf3 d5 3.g3 c6 4.Qc2 *","weight":6209,"prior_delay":25,"prior_completion_time":0},
{"oid":5,"gid":0,"fullname":"English Opening, Agincourt Defense, Neo-Catalan Declined","main_line":"1.c4 e6 2.Nf3 d5 3.g3 Nf6 4.Bg2 Be7 5.O-O c5 *","weight":8267,"prior_delay":25,"prior_completion_time":0},
{"oid":6,"gid":0,"fullname":"English Opening, Agincourt Defense, Neo-Catalan Declined","main_line":"1.c4 e6 2.Nf3 d5 3.g3 Nf6 4.Bg2 Be7 5.O-O c6 *","weight":8265,"prior_delay":25,"prior_completion_time":0},
{"oid":7,"gid":0,"fullname":"English Opening, Agincourt Defense, Neo-Catalan Declined","main_line":"1.c4 e6 2.Nf3 d5 3.g3 Nf6 4.Bg2 Be7 5.O-O O-O 6.b3 c5 7.Bb2 Nc6 8.e3 *","weight":9411,"prior_delay":25,"prior_completion_time":0},
{"oid":8,"gid":0,"fullname":"English Opening, Agincourt Defense, Neo-Catalan Declined","main_line":"1.c4 e6 2.Nf3 d5 3.g3 Nf6 4.Bg2 Be7 5.O-O O-O 6.b3 b6 7.Bb2 Bb7 8.e3 *","weight":9611,"prior_delay":25,"prior_completion_time":0},
{"oid":9,"gid":0,"fullname":"English Opening, Agincourt Defense, Wimpy System","main_line":"1.c4 e6 2.Nf3 d5 3.b3 Nf6 4.Bb2 c5 5.e3 Nc6 *","weight":6271,"prior_delay":25,"prior_completion_time":0},
{"oid":10,"gid":1,"fullname":"English Opening, Anglo-Dutch Defense","main_line":"1.c4 f5 2.Nc3 Nf6 3.g3 g6 *","weight":2695,"prior_delay":25,"prior_completion_time":0},
{"oid":11,"gid":1,"fullname":"English Opening, Anglo-Dutch Defense","main_line":"1.c4 f5 2.Nf3 Nf6 3.g3 e6 4.Bg2 c6 5.O-O d5 *","weight":2784,"prior_delay":25,"prior_completion_time":0},
{"oid":12,"gid":1,"fullname":"English Opening, Anglo-Dutch Defense","main_line":"1.c4 f5 2.Nf3 Nf6 3.g3 e6 4.Bg2 Be7 5.O-O O-O 6.Nc3 *","weight":2855,"prior_delay":25,"prior_completion_time":0},
{"oid":13,"gid":2,"fullname":"English Opening, Anglo-Grunfeld Defense, Korchnoi Variation","main_line":"1.c4 Nf6 2.Nc3 d5 3.cxd5 Nxd5 4.Nf3 g6 5.g3 Bg7 6.Bg2 e5 *","weight":10429,"prior_delay":25,"prior_completion_time":0},
{"oid":14,"gid":2,"fullname":"English Opening, Anglo-Grunfeld Defense","main_line":"1.c4 Nf6 2.Nc3 d5 3.Nf3 g6 4.Qa4+ *","weight":9765,"prior_delay":25,"prior_completion_time":0},
{"oid":15,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Anglo-Grunfeld Variation","main_line":"1.c4 Nf6 2.Nc3 d5 3.cxd5 Nxd5 4.g3 g6 5.Bg2 Nb6 *","weight":10458,"prior_delay":25,"prior_completion_time":0},
{"oid":16,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Anglo-Grunfeld Variation","main_line":"1.c4 Nf6 2.Nc3 d5 3.cxd5 Nxd5 4.Nf3 g6 5.e4 Nxc3 6.dxc3 Qxd1+ 7.Kxd1 *","weight":10619,"prior_delay":25,"prior_completion_time":0},
{"oid":17,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Anglo-Grunfeld Variation","main_line":"1.c4 Nf6 2.Nc3 d5 3.cxd5 Nxd5 4.Nf3 g6 5.Qa4+ *","weight":10313,"prior_delay":25,"prior_completion_time":0},
{"oid":18,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Anglo-Grunfeld Variation","main_line":"1.c4 Nf6 2.Nc3 d5 3.cxd5 Nxd5 4.g3 g6 5.Bg2 Nxc3 6.bxc3 Bg7 7.Rb1 *","weight":10597,"prior_delay":25,"prior_completion_time":0},
{"oid":19,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Flohr-Mikenas-Carls Variation, Nei Gambit","main_line":"1.c4 Nf6 2.Nc3 e6 3.e4 c5 4.e5 Ng8 5.Nf3 Nc6 6.d4 cxd4 7.Nxd4 Nxe5 *","weight":10712,"prior_delay":25,"prior_completion_time":0},
{"oid":20,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Flohr-Mikenas-Carls Variation, Nei Gambit","main_line":"1.c4 Nf6 2.Nc3 e6 3.e4 c5 4.e5 Ng8 5.d4 *","weight":10558,"prior_delay":25,"prior_completion_time":0},
{"oid":21,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Hedgehog System","main_line":"1.c4 Nf6 2.Nc3 e6 3.Nf3 d5 *","weight":11025,"prior_delay":25,"prior_completion_time":0},
{"oid":22,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, King's Indian Formation","main_line":"1.c4 Nf6 2.Nf3 g6 3.g3 Bg7 4.Bg2 O-O *","weight":11907,"prior_delay":25,"prior_completion_time":0},
{"oid":23,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Nimzo-English","main_line":"1.c4 Nf6 2.Nc3 e6 3.Nf3 Bb4 4.Qc2 O-O *","weight":11493,"prior_delay":25,"prior_completion_time":0},
{"oid":24,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Nimzo-English","main_line":"1.c4 Nf6 2.Nc3 e6 3.Nf3 Bb4 4.g3 *","weight":10987,"prior_delay":25,"prior_completion_time":0},
{"oid":25,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Queen's Indian Formation","main_line":"1.c4 Nf6 2.Nc3 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Re1 *","weight":11086,"prior_delay":25,"prior_completion_time":0},
{"oid":26,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Queen's Indian Variation","main_line":"1.c4 Nf6 2.Nc3 e6 3.Nf3 b6 4.e4 Bb7 5.Bd3 *","weight":10555,"prior_delay":25,"prior_completion_time":0},
{"oid":27,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Queen's Knight Variation","main_line":"1.c4 Nf6 2.Nc3 c6 3.e4 e5 *","weight":9529,"prior_delay":25,"prior_completion_time":0},
{"oid":28,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Queen's Knight Variation","main_line":"1.c4 Nf6 2.Nc3 c6 3.e4 d5 *","weight":9530,"prior_delay":25,"prior_completion_time":0},
{"oid":29,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Queen's Knight Variation","main_line":"1.c4 Nf6 2.Nc3 g6 3.g3 Bg7 4.Bg2 O-O 5.e4 *","weight":10828,"prior_delay":25,"prior_completion_time":0},
{"oid":30,"gid":3,"fullname":"English Opening, Anglo-Indian Defense, Queen's Knight Variation","main_line":"1.c4 Nf6 2.Nc3 g6 3.g3 Bg7 4.Bg2 O-O 5.Nf3 d6 6.O-O *","weight":11063,"prior_delay":25,"prior_completion_time":0},
{"oid":31,"gid":3,"fullname":"English Opening, Anglo-Indian Defense","main_line":"1.c4 Nf6 2.g3 g6 3.Bg2 Bg7 *","weight":6897,"prior_delay":25,"prior_completion_time":0},
{"oid":32,"gid":5,"fullname":"English Opening, Carls-Bremen System","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 Bc5 *","weight":9641,"prior_delay":25,"prior_completion_time":0},
{"oid":33,"gid":6,"fullname":"English Opening, Caro-Kann Defensive System","main_line":"1.c4 c6 2.Nf3 d5 3.g3 Bg4 4.Bg2 *","weight":5423,"prior_delay":25,"prior_completion_time":0},
{"oid":34,"gid":6,"fullname":"English Opening, Caro-Kann Defensive System","main_line":"1.c4 c6 2.g3 Nf6 3.Bg2 d5 4.Nf3 Bf5 5.O-O e6 6.d3 *","weight":4205,"prior_delay":25,"prior_completion_time":0},
{"oid":35,"gid":6,"fullname":"English Opening, Caro-Kann Defensive System","main_line":"1.c4 c6 2.g3 Nf6 3.Bg2 d5 4.Nf3 Bg4 5.O-O *","weight":4144,"prior_delay":25,"prior_completion_time":0},
{"oid":36,"gid":6,"fullname":"English Opening, Caro-Kann Defensive System","main_line":"1.c4 c6 2.Nf3 d5 3.e3 Nf6 4.Nc3 *","weight":7067,"prior_delay":25,"prior_completion_time":0},
{"oid":37,"gid":7,"fullname":"English Opening, Closed, Taimanov Variation","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.e3 d6 6.Nge2 Nh6 *","weight":9637,"prior_delay":25,"prior_completion_time":0},
{"oid":38,"gid":8,"fullname":"English Opening, Four Knights System, Nimzowitsch Variation","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.e4 *","weight":8901,"prior_delay":25,"prior_completion_time":0},
{"oid":39,"gid":9,"fullname":"English Opening, King's English Variation, Bellon Gambit","main_line":"1.c4 e5 2.Nc3 Nf6 3.Nf3 e4 4.Ng5 b5 *","weight":11028,"prior_delay":25,"prior_completion_time":0},
{"oid":40,"gid":9,"fullname":"English Opening, King's English Variation, Botvinnik System","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.e4 Nf6 7.Nf3 O-O 8.O-O *","weight":9617,"prior_delay":25,"prior_completion_time":0},
{"oid":41,"gid":9,"fullname":"English Opening, King's English Variation, Botvinnik System","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.e4 Nge7 7.Nge2 *","weight":9642,"prior_delay":25,"prior_completion_time":0},
{"oid":42,"gid":9,"fullname":"English Opening, King's English Variation, Botvinnik System","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.e4 Nf6 7.Nge2 O-O 8.O-O *","weight":9639,"prior_delay":25,"prior_completion_time":0},
{"oid":43,"gid":9,"fullname":"English Opening, King's English Variation, Bremen-Hort Variation","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.e3 d6 6.Nge2 Be6 7.d3 *","weight":9645,"prior_delay":25,"prior_completion_time":0},
{"oid":44,"gid":9,"fullname":"English Opening, King's English Variation, Closed System, Full Symmetry","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Nf3 Nf6 7.O-O O-O 8.Rb1 a5  9.a3 h6 *","weight":9916,"prior_delay":25,"prior_completion_time":0},
{"oid":45,"gid":9,"fullname":"English Opening, King's English Variation, Closed System, Full Symmetry","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Rb1 f5 *","weight":9604,"prior_delay":25,"prior_completion_time":0},
{"oid":46,"gid":9,"fullname":"English Opening, King's English Variation, Closed System, Full Symmetry","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Rb1 a5 *","weight":9621,"prior_delay":25,"prior_completion_time":0},
{"oid":47,"gid":9,"fullname":"English Opening, King's English Variation, Closed System, Full Symmetry","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.e3 *","weight":9595,"prior_delay":25,"prior_completion_time":0},
{"oid":48,"gid":9,"fullname":"English Opening, King's English Variation, Closed System","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 Nge7 *","weight":9511,"prior_delay":25,"prior_completion_time":0},
{"oid":49,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Bradley Beach Variation","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.d4 e4 *","weight":8894,"prior_delay":25,"prior_completion_time":0},
{"oid":50,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.g3 d5 5.cxd5 Nxd5 6.Bg2 Be6 *","weight":11538,"prior_delay":25,"prior_completion_time":0},
{"oid":51,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.g3 d5 5.cxd5 Nxd5 6.Bg2 Nb6 7.O-O Be7  8.d3 O-O *","weight":13151,"prior_delay":25,"prior_completion_time":0},
{"oid":52,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.g3 Bb4 5.Bg2 O-O 6.O-O e4 *","weight":11081,"prior_delay":25,"prior_completion_time":0},
{"oid":53,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.g3 Bc5 5.Bg2 d6 6.O-O O-O 7.d3 *","weight":10575,"prior_delay":25,"prior_completion_time":0},
{"oid":54,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.g3 Bb4 5.Nd5 *","weight":10487,"prior_delay":25,"prior_completion_time":0},
{"oid":55,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.g3 d5 5.cxd5 Nxd5 6.Bg2 Nb6 7.O-O Be7 8.a3 *","weight":13182,"prior_delay":25,"prior_completion_time":0},
{"oid":56,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.g3 d5 5.cxd5 Nxd5 6.Bg2 Nb6 7.O-O Be7 8.Rb1 *","weight":12925,"prior_delay":25,"prior_completion_time":0},
{"oid":57,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.g3 g6 5.d4 *","weight":10115,"prior_delay":25,"prior_completion_time":0},
{"oid":58,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Flexible Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.d3 *","weight":8975,"prior_delay":25,"prior_completion_time":0},
{"oid":59,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Korchnoi Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.a3 *","weight":8988,"prior_delay":25,"prior_completion_time":0},
{"oid":60,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Quiet Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.e3 Bb4 5.Qc2 Bxc3 *","weight":9571,"prior_delay":25,"prior_completion_time":0},
{"oid":61,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation, Quiet Line","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.e3 Bb4 5.Qc2 O-O 6.Nd5 Re8 7.Qf5 *","weight":9625,"prior_delay":25,"prior_completion_time":0},
{"oid":62,"gid":9,"fullname":"English Opening, King's English Variation, Four Knights Variation","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Nf6 4.d4 exd4 5.Nxd4 Bb4 6.Bg5 h6 7.Bh4 Bxc3+  8.bxc3 Ne5 *","weight":9272,"prior_delay":25,"prior_completion_time":0},
{"oid":63,"gid":9,"fullname":"English Opening, King's English Variation, Hungarian Attack","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Rb1 a5 *","weight":9583,"prior_delay":25,"prior_completion_time":0},
{"oid":64,"gid":9,"fullname":"English Opening, King's English Variation, Kramnik-Shirov Counterattack","main_line":"1.c4 e5 2.Nc3 Bb4 3.Nd5 Ba5 *","weight":8446,"prior_delay":25,"prior_completion_time":0},
{"oid":65,"gid":9,"fullname":"English Opening, King's English Variation, Kramnik-Shirov Counterattack","main_line":"1.c4 e5 2.Nc3 Bb4 3.Nd5 Bc5 *","weight":8537,"prior_delay":25,"prior_completion_time":0},
{"oid":66,"gid":9,"fullname":"English Opening, King's English Variation, Kramnik-Shirov Counterattack","main_line":"1.c4 e5 2.Nc3 Bb4 3.Nd5 a5 *","weight":8456,"prior_delay":25,"prior_completion_time":0},
{"oid":67,"gid":9,"fullname":"English Opening, King's English Variation, Kramnik-Shirov Counterattack","main_line":"1.c4 e5 2.Nc3 Bb4 3.g3 Bxc3 4.bxc3 *","weight":8344,"prior_delay":25,"prior_completion_time":0},
{"oid":68,"gid":9,"fullname":"English Opening, King's English Variation, Kramnik-Shirov Counterattack","main_line":"1.c4 e5 2.Nc3 Bb4 3.Nd5 Be7 4.d4 *","weight":8509,"prior_delay":25,"prior_completion_time":0},
{"oid":69,"gid":9,"fullname":"English Opening, King's English Variation, Reversed Closed Sicilian","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 f5 4.Bg2 Nf6 5.e3 g6 *","weight":8918,"prior_delay":25,"prior_completion_time":0},
{"oid":70,"gid":9,"fullname":"English Opening, King's English Variation, Reversed Closed Sicilian","main_line":"1.c4 e5 2.Nc3 Nc6 3.e3 Nf6 *","weight":8487,"prior_delay":25,"prior_completion_time":0},
{"oid":71,"gid":9,"fullname":"English Opening, King's English Variation, Reversed Closed Sicilian","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 Nf6 4.Bg2 Bb4 5.Nd5 *","weight":8873,"prior_delay":25,"prior_completion_time":0},
{"oid":72,"gid":9,"fullname":"English Opening, King's English Variation, Reversed Closed Sicilian","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 Nf6 4.Bg2 Bc5 5.e3 *","weight":8915,"prior_delay":25,"prior_completion_time":0},
{"oid":73,"gid":9,"fullname":"English Opening, King's English Variation, Reversed Closed Sicilian","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 f5 4.Bg2 Nf6 5.d3 *","weight":8879,"prior_delay":25,"prior_completion_time":0},
{"oid":74,"gid":9,"fullname":"English Opening, King's English Variation, Reversed Sicilian","main_line":"1.c4 e5 2.Nc3 g6 3.g3 Bg7 4.Bg2 *","weight":8021,"prior_delay":25,"prior_completion_time":0},
{"oid":75,"gid":9,"fullname":"English Opening, King's English Variation, Reversed Sicilian","main_line":"1.c4 e5 2.Nc3 f5 3.g3 Nf6 4.Bg2 *","weight":7968,"prior_delay":25,"prior_completion_time":0},
{"oid":76,"gid":9,"fullname":"English Opening, King's English Variation, Smyslov Defense","main_line":"1.c4 e5 2.Nc3 d6 3.Nf3 Bg4 *","weight":8151,"prior_delay":25,"prior_completion_time":0},
{"oid":77,"gid":9,"fullname":"English Opening, King's English Variation, Taimanov Variation","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.e4 *","weight":9489,"prior_delay":25,"prior_completion_time":0},
{"oid":78,"gid":9,"fullname":"English Opening, King's English Variation, Three Knights System","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 d6 *","weight":8689,"prior_delay":25,"prior_completion_time":0},
{"oid":79,"gid":9,"fullname":"English Opening, King's English Variation, Three Knights System","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 f5 4.d4 e4 *","weight":8814,"prior_delay":25,"prior_completion_time":0},
{"oid":80,"gid":9,"fullname":"English Opening, King's English Variation, Three Knights System","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 Bb4 *","weight":8691,"prior_delay":25,"prior_completion_time":0},
{"oid":81,"gid":9,"fullname":"English Opening, King's English Variation, Three Knights System","main_line":"1.c4 e5 2.Nc3 Nc6 3.Nf3 g6 4.d4 exd4 5.Nxd4 *","weight":8792,"prior_delay":25,"prior_completion_time":0},
{"oid":82,"gid":9,"fullname":"English Opening, King's English Variation, Troger Defense","main_line":"1.c4 e5 2.Nc3 Nc6 3.g3 d6 4.Bg2 Be6 5.d3 *","weight":8793,"prior_delay":25,"prior_completion_time":0},
{"oid":83,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 d6 6.O-O O-O *","weight":9673,"prior_delay":25,"prior_completion_time":0},
{"oid":84,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 d6 6.d3 O-O 7.O-O c6 *","weight":9693,"prior_delay":25,"prior_completion_time":0},
{"oid":85,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 g6 4.Bg2 Bg7 5.e3 d6 6.Nge2 O-O *","weight":9674,"prior_delay":25,"prior_completion_time":0},
{"oid":86,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 g6 4.Bg2 Bg7 5.d3 *","weight":9657,"prior_delay":25,"prior_completion_time":0},
{"oid":87,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation, Fianchetto Line","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 g6 4.Bg2 Bg7 5.e4 d6 6.Nge2 O-O 7.d3 *","weight":9671,"prior_delay":25,"prior_completion_time":0},
{"oid":88,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation, Keres Variation","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 c6 4.Nf3 e4 *","weight":9711,"prior_delay":25,"prior_completion_time":0},
{"oid":89,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation, Keres Variation","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 c6 4.Bg2 d5 5.cxd5 cxd5 *","weight":9679,"prior_delay":25,"prior_completion_time":0},
{"oid":90,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation, Keres Variation","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 c6 4.Nf3 d6 5.Bg2 *","weight":9699,"prior_delay":25,"prior_completion_time":0},
{"oid":91,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation, Reversed Dragon","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 d5 4.cxd5 Nxd5 5.Bg2 Nb6 *","weight":10532,"prior_delay":25,"prior_completion_time":0},
{"oid":92,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation, Smyslov System","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 Bb4 4.Bg2 Bxc3 *","weight":10033,"prior_delay":25,"prior_completion_time":0},
{"oid":93,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation, Smyslov System","main_line":"1.c4 e5 2.Nc3 Nf6 3.g3 Bb4 4.Bg2 O-O 5.e4 *","weight":10272,"prior_delay":25,"prior_completion_time":0},
{"oid":94,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation","main_line":"1.c4 e5 2.Nc3 Nf6 3.Nf3 d6 *","weight":11040,"prior_delay":25,"prior_completion_time":0},
{"oid":95,"gid":9,"fullname":"English Opening, King's English Variation, Two Knights Variation","main_line":"1.c4 e5 2.Nc3 Nf6 3.e3 Bb4 *","weight":9298,"prior_delay":25,"prior_completion_time":0},
{"oid":96,"gid":9,"fullname":"English Opening, King's English Variation","main_line":"1.c4 e5 2.Nc3 d6 3.Nf3 g6 *","weight":8150,"prior_delay":25,"prior_completion_time":0},
{"oid":97,"gid":9,"fullname":"English Opening, King's English Variation","main_line":"1.c4 e5 2.Nc3 d6 3.Nf3 f5 4.d4 e4 5.Ng5 c6 *","weight":8279,"prior_delay":25,"prior_completion_time":0},
{"oid":98,"gid":9,"fullname":"English Opening, King's English Variation","main_line":"1.c4 e5 2.Nc3 d6 3.Nf3 f5 4.d4 e4 5.Ng5 Nf6 *","weight":8261,"prior_delay":25,"prior_completion_time":0},
{"oid":99,"gid":9,"fullname":"English Opening, King's English Variation","main_line":"1.c4 e5 2.g3 Nf6 3.Bg2 Nc6 *","weight":8191,"prior_delay":25,"prior_completion_time":0},
{"oid":100,"gid":9,"fullname":"English Opening, King's English Variation","main_line":"1.c4 e5 2.Nc3 d6 3.Nf3 f5 4.d4 e4 5.Ng5 Be7 *","weight":8296,"prior_delay":25,"prior_completion_time":0},
{"oid":101,"gid":9,"fullname":"English Opening, King's English Variation","main_line":"1.c4 e5 2.g3 g6 3.Bg2 Bg7 *","weight":6859,"prior_delay":25,"prior_completion_time":0},
{"oid":102,"gid":9,"fullname":"English Opening, King's English Variation","main_line":"1.c4 e5 2.g3 Nf6 3.Bg2 Bc5 *","weight":8116,"prior_delay":25,"prior_completion_time":0},
{"oid":103,"gid":9,"fullname":"English Opening, King's English Variation","main_line":"1.c4 e5 2.g3 Nf6 3.Bg2 d5 *","weight":8370,"prior_delay":25,"prior_completion_time":0},
{"oid":104,"gid":9,"fullname":"English Opening, King's English Variation","main_line":"1.c4 e5 2.g3 Nf6 3.Bg2 c6 *","weight":8252,"prior_delay":25,"prior_completion_time":0},
{"oid":105,"gid":9,"fullname":"English Opening, King's English Variation","main_line":"1.c4 e5 2.Nc3 d6 3.Nf3 f5 4.d4 e4 5.Nd2 Nf6 6.e3 *","weight":8249,"prior_delay":25,"prior_completion_time":0},
{"oid":106,"gid":11,"fullname":"English Opening, Mikenas-Carls Variation","main_line":"1.c4 Nf6 2.Nc3 e6 3.e4 d5 4.e5 Ne4 *","weight":10793,"prior_delay":25,"prior_completion_time":0},
{"oid":107,"gid":11,"fullname":"English Opening, Mikenas-Carls Variation","main_line":"1.c4 Nf6 2.Nc3 e6 3.e4 d5 4.e5 d4 5.exf6 dxc3 6.bxc3 Qxf6 7.d4 e5 *","weight":11672,"prior_delay":25,"prior_completion_time":0},
{"oid":108,"gid":11,"fullname":"English Opening, Mikenas-Carls Variation","main_line":"1.c4 Nf6 2.Nc3 e6 3.e4 d5 4.e5 d4 5.exf6 dxc3 6.bxc3 Qxf6 7.d4 c5 *","weight":11655,"prior_delay":25,"prior_completion_time":0},
{"oid":109,"gid":11,"fullname":"English Opening, Mikenas-Carls Variation","main_line":"1.c4 Nf6 2.Nc3 e6 3.e4 d5 4.e5 d4 5.exf6 dxc3 6.bxc3 Qxf6 7.Nf3 *","weight":11676,"prior_delay":25,"prior_completion_time":0},
{"oid":110,"gid":11,"fullname":"English Opening, Mikenas-Carls Variation","main_line":"1.c4 Nf6 2.Nc3 e6 3.e4 d5 4.cxd5 *","weight":10637,"prior_delay":25,"prior_completion_time":0},
{"oid":111,"gid":12,"fullname":"English Opening, Neo-Catalan","main_line":"1.c4 e6 2.Nf3 d5 3.g3 Nf6 4.Bg2 c5 5.O-O *","weight":7292,"prior_delay":25,"prior_completion_time":0},
{"oid":112,"gid":13,"fullname":"English Opening, Romanishin Gambit","main_line":"1.c4 e6 2.Nf3 Nf6 3.g3 a6 4.Bg2 b5 *","weight":7636,"prior_delay":25,"prior_completion_time":0},
{"oid":113,"gid":14,"fullname":"English Opening, Symmetrical, Hedgehog, Flexible Formation","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O e6 6.Nc3 Be7 7.d4 cxd4  8.Qxd4 d6 9.Rd1 a6 10.b3 Nbd7 11.e4 O-O *","weight":12006,"prior_delay":25,"prior_completion_time":0},
{"oid":114,"gid":14,"fullname":"English Opening, Symmetrical, Hedgehog, Flexible Formation","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O e6 6.Nc3 Be7 7.d4 cxd4  8.Qxd4 d6 9.Rd1 a6 10.b3 Nbd7 11.e4 Qc7 *","weight":12013,"prior_delay":25,"prior_completion_time":0},
{"oid":115,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Geller Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Nc6 6.g3 Qb6 7.Nb3 Ne5 *","weight":8540,"prior_delay":25,"prior_completion_time":0},
{"oid":116,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Nc6 6.g3 Bb4 *","weight":8283,"prior_delay":25,"prior_completion_time":0},
{"oid":117,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Nc6 6.Ndb5 Bb4 *","weight":8166,"prior_delay":25,"prior_completion_time":0},
{"oid":118,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.g3 Bb4+ *","weight":8066,"prior_delay":25,"prior_completion_time":0},
{"oid":119,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Nc6 6.g3 Bc5 *","weight":8312,"prior_delay":25,"prior_completion_time":0},
{"oid":120,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.g3 Qc7 *","weight":8105,"prior_delay":25,"prior_completion_time":0},
{"oid":121,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Nc6 6.Ndb5 d5 7.Bf4 e5  8.cxd5 exf4 9.dxc6 bxc6 10.Qxd8+ Kxd8 *","weight":8623,"prior_delay":25,"prior_completion_time":0},
{"oid":122,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Nc6 6.a3 Bc5 *","weight":8480,"prior_delay":25,"prior_completion_time":0},
{"oid":123,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 d5 *","weight":8027,"prior_delay":25,"prior_completion_time":0},
{"oid":124,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Bb4 6.Bd2 Nc6 *","weight":8105,"prior_delay":25,"prior_completion_time":0},
{"oid":125,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 a6 6.g3 *","weight":8061,"prior_delay":25,"prior_completion_time":0},
{"oid":126,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.e3 *","weight":7951,"prior_delay":25,"prior_completion_time":0},
{"oid":127,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.g3 d5 6.Bg2 e5 7.Nf3 *","weight":8358,"prior_delay":25,"prior_completion_time":0},
{"oid":128,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Nc6 6.e3 *","weight":8097,"prior_delay":25,"prior_completion_time":0},
{"oid":129,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Bb4 6.Nb5 *","weight":8100,"prior_delay":25,"prior_completion_time":0},
{"oid":130,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Nc6 6.Bg5 *","weight":8120,"prior_delay":25,"prior_completion_time":0},
{"oid":131,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.g3 a6 6.Bg2 *","weight":8044,"prior_delay":25,"prior_completion_time":0},
{"oid":132,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Bb4 6.Qb3 *","weight":8096,"prior_delay":25,"prior_completion_time":0},
{"oid":133,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.Nc3 Nc6 6.Bf4 *","weight":8136,"prior_delay":25,"prior_completion_time":0},
{"oid":134,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.g3 Qb6 6.Bg2 *","weight":8038,"prior_delay":25,"prior_completion_time":0},
{"oid":135,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e6 5.g3 Nc6 6.Bg2 *","weight":8075,"prior_delay":25,"prior_completion_time":0},
{"oid":136,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 b6 5.Nc3 Bb7 *","weight":7926,"prior_delay":25,"prior_completion_time":0},
{"oid":137,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 g6 *","weight":8391,"prior_delay":25,"prior_completion_time":0},
{"oid":138,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 e5 5.Nb5 *","weight":7931,"prior_delay":25,"prior_completion_time":0},
{"oid":139,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 g6 4.d5 *","weight":7216,"prior_delay":25,"prior_completion_time":0},
{"oid":140,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.e4 *","weight":7937,"prior_delay":25,"prior_completion_time":0},
{"oid":141,"gid":15,"fullname":"English Opening, Symmetrical Variation, Anti-Benoni Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.d4 g6 4.Nc3 *","weight":7234,"prior_delay":25,"prior_completion_time":0},
{"oid":142,"gid":15,"fullname":"English Opening, Symmetrical Variation, Botvinnik System","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.e4 d6 6.Nge2 Nf6 7.O-O O-O 8.d3 Ne8 *","weight":5460,"prior_delay":25,"prior_completion_time":0},
{"oid":143,"gid":15,"fullname":"English Opening, Symmetrical Variation, Botvinnik System","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.e4 d6 6.Nge2 Nf6 7.O-O O-O 8.d3 Rb8 *","weight":5458,"prior_delay":25,"prior_completion_time":0},
{"oid":144,"gid":15,"fullname":"English Opening, Symmetrical Variation, Botvinnik System","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.e4 d6 6.Nge2 Nf6 7.O-O O-O 8.d3 a6 *","weight":5463,"prior_delay":25,"prior_completion_time":0},
{"oid":145,"gid":15,"fullname":"English Opening, Symmetrical Variation, Botvinnik System","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.e4 Nf6 6.Nge2 *","weight":5346,"prior_delay":25,"prior_completion_time":0},
{"oid":146,"gid":15,"fullname":"English Opening, Symmetrical Variation, Botvinnik System Reversed","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 e5 6.d3 Nge7 *","weight":5714,"prior_delay":25,"prior_completion_time":0},
{"oid":147,"gid":15,"fullname":"English Opening, Symmetrical Variation, Botvinnik System Reversed","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.e3 e5 *","weight":5477,"prior_delay":25,"prior_completion_time":0},
{"oid":148,"gid":15,"fullname":"English Opening, Symmetrical Variation, Botvinnik System Reversed","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 e5 6.a3 *","weight":5700,"prior_delay":25,"prior_completion_time":0},
{"oid":149,"gid":15,"fullname":"English Opening, Symmetrical Variation, Botvinnik System Reversed","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 e5 6.O-O d6 7.d3 Nge7 8.a3 *","weight":5880,"prior_delay":25,"prior_completion_time":0},
{"oid":150,"gid":15,"fullname":"English Opening, Symmetrical Variation, Botvinnik System Reversed","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 e5 6.O-O Nge7 7.d3 O-O 8.a3 *","weight":5918,"prior_delay":25,"prior_completion_time":0},
{"oid":151,"gid":15,"fullname":"English Opening, Symmetrical Variation, Double Fianchetto","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.b3 *","weight":5765,"prior_delay":25,"prior_completion_time":0},
{"oid":152,"gid":15,"fullname":"English Opening, Symmetrical Variation, Duchamp Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d3 d5 *","weight":5803,"prior_delay":25,"prior_completion_time":0},
{"oid":153,"gid":15,"fullname":"English Opening, Symmetrical Variation, Duchamp Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d3 a6 *","weight":5806,"prior_delay":25,"prior_completion_time":0},
{"oid":154,"gid":15,"fullname":"English Opening, Symmetrical Variation, Duchamp Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d3 d6 8.a3 a6 *","weight":5859,"prior_delay":25,"prior_completion_time":0},
{"oid":155,"gid":15,"fullname":"English Opening, Symmetrical Variation, Duchamp Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d3 d6 8.Bd2 *","weight":5832,"prior_delay":25,"prior_completion_time":0},
{"oid":156,"gid":15,"fullname":"English Opening, Symmetrical Variation, Duchamp Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d3 d6 8.Rb1 *","weight":5826,"prior_delay":25,"prior_completion_time":0},
{"oid":157,"gid":15,"fullname":"English Opening, Symmetrical Variation, Fianchetto Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 Nf6 *","weight":4906,"prior_delay":25,"prior_completion_time":0},
{"oid":158,"gid":15,"fullname":"English Opening, Symmetrical Variation, Four Knights Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.Nf3 Nf6 4.e3 e5 *","weight":5307,"prior_delay":25,"prior_completion_time":0},
{"oid":159,"gid":15,"fullname":"English Opening, Symmetrical Variation, Four Knights Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.Nf3 Nf6 4.d4 *","weight":5393,"prior_delay":25,"prior_completion_time":0},
{"oid":160,"gid":15,"fullname":"English Opening, Symmetrical Variation, Four Knights Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.Nf3 Nf6 4.g3 d5 5.cxd5 *","weight":6020,"prior_delay":25,"prior_completion_time":0},
{"oid":161,"gid":15,"fullname":"English Opening, Symmetrical Variation, Full Symmetry Line","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O d6 *","weight":5695,"prior_delay":25,"prior_completion_time":0},
{"oid":162,"gid":15,"fullname":"English Opening, Symmetrical Variation, Full Symmetry Line","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.a3 d6 *","weight":5767,"prior_delay":25,"prior_completion_time":0},
{"oid":163,"gid":15,"fullname":"English Opening, Symmetrical Variation, Full Symmetry Line","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.d4 *","weight":5700,"prior_delay":25,"prior_completion_time":0},
{"oid":164,"gid":15,"fullname":"English Opening, Symmetrical Variation, Hedgehog Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O e6 6.Nc3 Be7 7.d4 cxd4  8.Qxd4 Nc6 *","weight":11467,"prior_delay":25,"prior_completion_time":0},
{"oid":165,"gid":15,"fullname":"English Opening, Symmetrical Variation, Hedgehog Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O e6 6.Nc3 Be7 7.d4 cxd4  8.Qxd4 O-O 9.Rd1 Nc6 *","weight":11619,"prior_delay":25,"prior_completion_time":0},
{"oid":166,"gid":15,"fullname":"English Opening, Symmetrical Variation, Hedgehog Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O e6 6.Nc3 Be7 7.Re1 d5 *","weight":10737,"prior_delay":25,"prior_completion_time":0},
{"oid":167,"gid":15,"fullname":"English Opening, Symmetrical Variation, Hedgehog Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O e6 6.Nc3 Be7 7.b3 O-O 8.Bb2 d6 *","weight":10603,"prior_delay":25,"prior_completion_time":0},
{"oid":168,"gid":15,"fullname":"English Opening, Symmetrical Variation, Hedgehog Defense","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O e6 6.Nc3 Be7 7.d4 cxd4 8.Qxd4 d6  9.b3 Nbd7 10.Nb5 *","weight":11734,"prior_delay":25,"prior_completion_time":0},
{"oid":169,"gid":15,"fullname":"English Opening, Symmetrical Variation, Mecking Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d4 cxd4  8.Nxd4 d6 *","weight":6066,"prior_delay":25,"prior_completion_time":0},
{"oid":170,"gid":15,"fullname":"English Opening, Symmetrical Variation, Mecking Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d4 cxd4  8.Nxd4 Ng4 9.e3 d6 *","weight":6128,"prior_delay":25,"prior_completion_time":0},
{"oid":171,"gid":15,"fullname":"English Opening, Symmetrical Variation, Mecking Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d4 cxd4  8.Nxd4 Nxd4 9.Qxd4 d6 10.Qd3 Rb8 *","weight":6548,"prior_delay":25,"prior_completion_time":0},
{"oid":172,"gid":15,"fullname":"English Opening, Symmetrical Variation, Mecking Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d4 cxd4  8.Nxd4 Nxd4 9.Qxd4 d6 10.Qd3 Bf5 *","weight":6565,"prior_delay":25,"prior_completion_time":0},
{"oid":173,"gid":15,"fullname":"English Opening, Symmetrical Variation, Mecking Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d4 cxd4  8.Nxd4 Qa5 *","weight":6126,"prior_delay":25,"prior_completion_time":0},
{"oid":174,"gid":15,"fullname":"English Opening, Symmetrical Variation, Mecking Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d4 cxd4  8.Nxd4 Nxd4 9.Qxd4 d6 10.Qd2 *","weight":6406,"prior_delay":25,"prior_completion_time":0},
{"oid":175,"gid":15,"fullname":"English Opening, Symmetrical Variation, Mecking Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d4 cxd4  8.Nxd4 Nxd4 9.Qxd4 d6 10.Qd3 a6 11.Bd2 Rb8 12.Rac1 *","weight":6678,"prior_delay":25,"prior_completion_time":0},
{"oid":176,"gid":15,"fullname":"English Opening, Symmetrical Variation, Mecking Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nf6 6.O-O O-O 7.d4 cxd4  8.Nxd4 Qb6 9.Nc2 *","weight":6028,"prior_delay":25,"prior_completion_time":0},
{"oid":177,"gid":15,"fullname":"English Opening, Symmetrical Variation, Normal Variation","main_line":"1.c4 c5 2.Nc3 Nf6 3.Nf3 b6 *","weight":5148,"prior_delay":25,"prior_completion_time":0},
{"oid":178,"gid":15,"fullname":"English Opening, Symmetrical Variation, Normal Variation","main_line":"1.c4 c5 2.Nc3 Nf6 3.Nf3 g6 *","weight":5144,"prior_delay":25,"prior_completion_time":0},
{"oid":179,"gid":15,"fullname":"English Opening, Symmetrical Variation, Normal Variation","main_line":"1.c4 c5 2.Nc3 b6 3.Nf3 Bb7 *","weight":4685,"prior_delay":25,"prior_completion_time":0},
{"oid":180,"gid":15,"fullname":"English Opening, Symmetrical Variation, Normal Variation","main_line":"1.c4 c5 2.Nc3 g6 3.g3 Bg7 4.Bg2 *","weight":5307,"prior_delay":25,"prior_completion_time":0},
{"oid":181,"gid":15,"fullname":"English Opening, Symmetrical Variation, Normal Variation","main_line":"1.c4 c5 2.Nc3 Nf6 3.Nf3 e6 4.g3 *","weight":6428,"prior_delay":25,"prior_completion_time":0},
{"oid":182,"gid":15,"fullname":"English Opening, Symmetrical Variation, Rubinstein Variation","main_line":"1.c4 c5 2.Nc3 Nf6 3.g3 d5 4.cxd5 Nxd5 5.Bg2 Nc7 *","weight":5190,"prior_delay":25,"prior_completion_time":0},
{"oid":183,"gid":15,"fullname":"English Opening, Symmetrical Variation, Symmetrical Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.e3 e6 6.Nge2 Nge7 *","weight":5689,"prior_delay":25,"prior_completion_time":0},
{"oid":184,"gid":15,"fullname":"English Opening, Symmetrical Variation, Symmetrical Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.a3 d6 *","weight":5566,"prior_delay":25,"prior_completion_time":0},
{"oid":185,"gid":15,"fullname":"English Opening, Symmetrical Variation, Symmetrical Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.a3 e6 *","weight":5526,"prior_delay":25,"prior_completion_time":0},
{"oid":186,"gid":15,"fullname":"English Opening, Symmetrical Variation, Symmetrical Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 *","weight":5399,"prior_delay":25,"prior_completion_time":0},
{"oid":187,"gid":15,"fullname":"English Opening, Symmetrical Variation, Symmetrical Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.b3 *","weight":5327,"prior_delay":25,"prior_completion_time":0},
{"oid":188,"gid":15,"fullname":"English Opening, Symmetrical Variation, Three Knights Variation","main_line":"1.c4 c5 2.Nc3 Nf6 3.Nf3 d5 4.cxd5 Nxd5 5.d4 cxd4 *","weight":6825,"prior_delay":25,"prior_completion_time":0},
{"oid":189,"gid":15,"fullname":"English Opening, Symmetrical Variation, Three Knights Variation","main_line":"1.c4 c5 2.Nc3 Nf6 3.Nf3 d5 4.cxd5 Nxd5 5.d4 Nxc3 *","weight":6910,"prior_delay":25,"prior_completion_time":0},
{"oid":190,"gid":15,"fullname":"English Opening, Symmetrical Variation, Three Knights Variation","main_line":"1.c4 c5 2.Nc3 Nf6 3.Nf3 d5 4.cxd5 Nxd5 5.e4 Nb4 6.Bb5+ *","weight":6885,"prior_delay":25,"prior_completion_time":0},
{"oid":191,"gid":15,"fullname":"English Opening, Symmetrical Variation, Three Knights Variation","main_line":"1.c4 c5 2.Nc3 Nf6 3.Nf3 d5 4.cxd5 Nxd5 5.g3 *","weight":6778,"prior_delay":25,"prior_completion_time":0},
{"oid":192,"gid":15,"fullname":"English Opening, Symmetrical Variation, Three Knights Variation","main_line":"1.c4 c5 2.Nc3 Nf6 3.Nf3 d5 4.cxd5 Nxd5 5.e4 Nb4 6.Bc4 *","weight":6930,"prior_delay":25,"prior_completion_time":0},
{"oid":193,"gid":15,"fullname":"English Opening, Symmetrical Variation, Three Knights Variation","main_line":"1.c4 c5 2.Nc3 Nf6 3.Nf3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.dxc3 Qxd1+ 7.Kxd1 *","weight":6815,"prior_delay":25,"prior_completion_time":0},
{"oid":194,"gid":15,"fullname":"English Opening, Symmetrical Variation, Two Knights Line","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 a6 *","weight":5572,"prior_delay":25,"prior_completion_time":0},
{"oid":195,"gid":15,"fullname":"English Opening, Symmetrical Variation, Two Knights Line","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 d6 6.O-O Nh6 *","weight":5930,"prior_delay":25,"prior_completion_time":0},
{"oid":196,"gid":15,"fullname":"English Opening, Symmetrical Variation, Two Knights Line","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 e6 6.O-O Nge7 7.d3 O-O 8.Bd2 *","weight":6061,"prior_delay":25,"prior_completion_time":0},
{"oid":197,"gid":15,"fullname":"English Opening, Symmetrical Variation, Two Knights Line","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 e6 6.d3 *","weight":5785,"prior_delay":25,"prior_completion_time":0},
{"oid":198,"gid":15,"fullname":"English Opening, Symmetrical Variation, Two Knights Line","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 Nh6 6.O-O *","weight":5601,"prior_delay":25,"prior_completion_time":0},
{"oid":199,"gid":15,"fullname":"English Opening, Symmetrical Variation, Two Knights Line","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 d6 6.d3 *","weight":5774,"prior_delay":25,"prior_completion_time":0},
{"oid":200,"gid":15,"fullname":"English Opening, Symmetrical Variation, Two Knights Line","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 e6 6.e3 *","weight":5755,"prior_delay":25,"prior_completion_time":0},
{"oid":201,"gid":15,"fullname":"English Opening, Symmetrical Variation, Two Knights Line","main_line":"1.c4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nf3 e6 6.O-O Nge7 7.e3 *","weight":5958,"prior_delay":25,"prior_completion_time":0},
{"oid":202,"gid":15,"fullname":"English Opening, Symmetrical Variation, Two Knights Variation","main_line":"1.c4 c5 2.Nc3 Nc6 3.Nf3 g6 4.e3 Bg7 *","weight":5436,"prior_delay":25,"prior_completion_time":0},
{"oid":203,"gid":15,"fullname":"English Opening, Symmetrical Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O g6 6.Nc3 Bg7 *","weight":10030,"prior_delay":25,"prior_completion_time":0},
{"oid":204,"gid":15,"fullname":"English Opening, Symmetrical Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O e6 6.Nc3 a6 *","weight":9857,"prior_delay":25,"prior_completion_time":0},
{"oid":205,"gid":15,"fullname":"English Opening, Symmetrical Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O g6 6.b3 Bg7 7.Bb2 O-O 8.Nc3 d5 *","weight":10250,"prior_delay":25,"prior_completion_time":0},
{"oid":206,"gid":15,"fullname":"English Opening, Symmetrical Variation","main_line":"1.c4 c5 2.Nf3 b6 3.g3 Bb7 4.Bg2 *","weight":6003,"prior_delay":25,"prior_completion_time":0},
{"oid":207,"gid":15,"fullname":"English Opening, Symmetrical Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O e6 6.Nc3 d6 7.d4 *","weight":9887,"prior_delay":25,"prior_completion_time":0},
{"oid":208,"gid":15,"fullname":"English Opening, Symmetrical Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.Nc3 *","weight":8775,"prior_delay":25,"prior_completion_time":0},
{"oid":209,"gid":15,"fullname":"English Opening, Symmetrical Variation","main_line":"1.c4 c5 2.Nf3 Nf6 3.g3 b6 4.Bg2 Bb7 5.O-O e6 6.Nc3 d6 7.b3 *","weight":9874,"prior_delay":25,"prior_completion_time":0},
{"oid":210,"gid":10,"fullname":"English Opening, Main Line","main_line":"1.c4 b6 2.Nc3 Bb7 3.e4 e6 *","weight":2724,"prior_delay":25,"prior_completion_time":0},
{"oid":211,"gid":4,"fullname":"English Opening, Anglo-Slav Variation, Bled Variation","main_line":"1.c4 c6 2.Nf3 d5 3.b3 Nf6 4.Bb2 g6 5.e3 Bg7 *","weight":5278,"prior_delay":25,"prior_completion_time":0},
{"oid":212,"gid":4,"fullname":"English Opening, Anglo-Slav Variation, Bogoljubov Variation","main_line":"1.c4 c6 2.Nf3 d5 3.b3 Bg4 *","weight":5239,"prior_delay":25,"prior_completion_time":0},
{"oid":213,"gid":4,"fullname":"English Opening, Anglo-Slav Variation, Capablanca Variation","main_line":"1.c4 c6 2.Nf3 d5 3.b3 Nf6 4.Bb2 Bg4 *","weight":5280,"prior_delay":25,"prior_completion_time":0},
{"oid":214,"gid":4,"fullname":"English Opening, Anglo-Slav Variation, London Defensive System","main_line":"1.c4 c6 2.Nf3 d5 3.b3 Nf6 4.g3 Bf5 5.Bg2 e6 6.Bb2 *","weight":5268,"prior_delay":25,"prior_completion_time":0},
{"oid":215,"gid":4,"fullname":"English Opening, Anglo-Slav Variation, Torre System","main_line":"1.c4 c6 2.Nf3 d5 3.b3 Nf6 4.g3 Bg4 5.Bg2 e6 6.Bb2 *","weight":5286,"prior_delay":25,"prior_completion_time":0},
{"oid":216,"gid":4,"fullname":"English Opening, Anglo-Slav Variation, York System","main_line":"1.c4 c6 2.Nf3 d5 3.b3 Nf6 4.Bb2 Bf5 *","weight":5277,"prior_delay":25,"prior_completion_time":0},
{"oid":217,"gid":16,"fullname":"Alekhine Defense, Balogh Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Bc4 *","weight":15730,"prior_delay":25,"prior_completion_time":0},
{"oid":218,"gid":17,"fullname":"Alekhine Defense, Exchange Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.c4 Nb6 5.exd6 exd6 6.Nc3 Be7 *","weight":16338,"prior_delay":25,"prior_completion_time":0},
{"oid":219,"gid":17,"fullname":"Alekhine Defense, Exchange Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.c4 Nb6 5.exd6 cxd6 6.Nc3 g6 7.Be3 *","weight":16244,"prior_delay":25,"prior_completion_time":0},
{"oid":220,"gid":18,"fullname":"Alekhine Defense, Four Pawns Attack, Main Line","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.c4 Nb6 5.f4 dxe5 6.fxe5 Nc6 7.Be3 *","weight":16165,"prior_delay":25,"prior_completion_time":0},
{"oid":221,"gid":18,"fullname":"Alekhine Defense, Four Pawns Attack, Trifunovic Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.c4 Nb6 5.f4 Bf5 *","weight":16062,"prior_delay":25,"prior_completion_time":0},
{"oid":222,"gid":18,"fullname":"Alekhine Defense, Four Pawns Attack","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.c4 Nb6 5.f4 dxe5 6.fxe5 c5 *","weight":16125,"prior_delay":25,"prior_completion_time":0},
{"oid":223,"gid":19,"fullname":"Alekhine Defense, Kmoch Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.Bc4 Nb6 4.Bb3 c5 5.d3 *","weight":14398,"prior_delay":25,"prior_completion_time":0},
{"oid":224,"gid":21,"fullname":"Alekhine Defense, Modern Variation, Alburt Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 g6 5.Bc4 Nb6 6.Bb3 Bg7 7.Ng5 *","weight":16761,"prior_delay":25,"prior_completion_time":0},
{"oid":225,"gid":21,"fullname":"Alekhine Defense, Modern Variation, Alekhine Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 Bg4 5.c4 Nb6 6.exd6 cxd6 *","weight":16469,"prior_delay":25,"prior_completion_time":0},
{"oid":226,"gid":21,"fullname":"Alekhine Defense, Modern Variation, Keres Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 g6 5.Bc4 Nb6 6.Bb3 Bg7 7.a4 *","weight":16770,"prior_delay":25,"prior_completion_time":0},
{"oid":227,"gid":21,"fullname":"Alekhine Defense, Modern Variation, Larsen Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 dxe5 5.Nxe5 g6 6.Bc4 c6 7.O-O *","weight":16926,"prior_delay":25,"prior_completion_time":0},
{"oid":228,"gid":21,"fullname":"Alekhine Defense, Modern Variation, Larsen-Haakert Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 Nc6 *","weight":16379,"prior_delay":25,"prior_completion_time":0},
{"oid":229,"gid":21,"fullname":"Alekhine Defense, Modern Variation, Main Line","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 Bg4 5.Be2 Nc6 *","weight":16606,"prior_delay":25,"prior_completion_time":0},
{"oid":230,"gid":21,"fullname":"Alekhine Defense, Modern Variation, Main Line","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 Bg4 5.Be2 e6 6.O-O Be7 7.c4 Nb6 8.h3 Bh5  9.Nc3 O-O 10.Be3 d5 *","weight":17051,"prior_delay":25,"prior_completion_time":0},
{"oid":231,"gid":21,"fullname":"Alekhine Defense, Modern Variation, Main Line","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 Bg4 5.Be2 e6 6.O-O Be7 7.c4 Nb6 8.Nc3 O-O  9.Be3 Nc6 10.exd6 cxd6 *","weight":17045,"prior_delay":25,"prior_completion_time":0},
{"oid":232,"gid":21,"fullname":"Alekhine Defense, Modern Variation, Main Line","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 Bg4 5.Be2 e6 6.O-O Be7 7.c4 Nb6 8.exd6 *","weight":16937,"prior_delay":25,"prior_completion_time":0},
{"oid":233,"gid":21,"fullname":"Alekhine Defense, Modern Variation, Schmid Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 Nb6 *","weight":16373,"prior_delay":25,"prior_completion_time":0},
{"oid":234,"gid":21,"fullname":"Alekhine Defense, Modern Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 c6 5.c4 *","weight":16416,"prior_delay":25,"prior_completion_time":0},
{"oid":235,"gid":22,"fullname":"Alekhine Defense, Modern,Flohr Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 Bg4 5.Be2 c6 6.O-O *","weight":16615,"prior_delay":25,"prior_completion_time":0},
{"oid":236,"gid":22,"fullname":"Alekhine Defense, Modern,Flohr Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.Nf3 Bg4 5.Be2 c6 6.c4 *","weight":16616,"prior_delay":25,"prior_completion_time":0},
{"oid":237,"gid":23,"fullname":"Alekhine Defense, Samisch Attack","main_line":"1.e4 Nf6 2.e5 Nd5 3.Nc3 Nxc3 4.dxc3 *","weight":14396,"prior_delay":25,"prior_completion_time":0},
{"oid":238,"gid":24,"fullname":"Alekhine Defense, Scandinavian Variation","main_line":"1.e4 Nf6 2.Nc3 d5 3.e5 Ne4 *","weight":13002,"prior_delay":25,"prior_completion_time":0},
{"oid":239,"gid":24,"fullname":"Alekhine Defense, Scandinavian Variation","main_line":"1.e4 Nf6 2.Nc3 d5 3.exd5 Nxd5 4.Bc4 Nb6 *","weight":13008,"prior_delay":25,"prior_completion_time":0},
{"oid":240,"gid":24,"fullname":"Alekhine Defense, Scandinavian Variation","main_line":"1.e4 Nf6 2.Nc3 d5 3.exd5 Nxd5 4.Nxd5 Qxd5 *","weight":13003,"prior_delay":25,"prior_completion_time":0},
{"oid":241,"gid":24,"fullname":"Alekhine Defense, Scandinavian Variation","main_line":"1.e4 Nf6 2.Nc3 d5 3.exd5 Nxd5 4.Bc4 e6 *","weight":13006,"prior_delay":25,"prior_completion_time":0},
{"oid":242,"gid":24,"fullname":"Alekhine Defense, Scandinavian Variation","main_line":"1.e4 Nf6 2.Nc3 d5 3.exd5 Nxd5 4.Nf3 *","weight":13004,"prior_delay":25,"prior_completion_time":0},
{"oid":243,"gid":25,"fullname":"Alekhine Defense, Spielmann Gambit","main_line":"1.e4 Nf6 2.Nc3 d5 3.e5 Nfd7 4.e6 *","weight":13007,"prior_delay":25,"prior_completion_time":0},
{"oid":244,"gid":26,"fullname":"Alekhine Defense, Two Pawn Attack, Lasker Variation","main_line":"1.e4 Nf6 2.e5 Nd5 3.c4 Nb6 4.c5 Nd5 5.Nc3 *","weight":14485,"prior_delay":25,"prior_completion_time":0},
{"oid":245,"gid":20,"fullname":"Alekhine Defense, Main Line","main_line":"1.e4 Nf6 2.e5 Nd5 3.d4 d6 4.exd6 *","weight":15731,"prior_delay":25,"prior_completion_time":0},
{"oid":246,"gid":27,"fullname":"Bishop's Opening, Berlin Defense","main_line":"1.e4 e5 2.Bc4 Nf6 3.d3 c6 4.Nf3 *","weight":22097,"prior_delay":25,"prior_completion_time":0},
{"oid":247,"gid":28,"fullname":"Bishop's Opening, Urusov Gambit, Panov Variation","main_line":"1.e4 e5 2.Bc4 Nf6 3.d4 exd4 4.Nf3 d5 5.exd5 Bb4+ 6.c3 Qe7+ *","weight":21243,"prior_delay":25,"prior_completion_time":0},
{"oid":248,"gid":29,"fullname":"Bishop's Opening, Vienna Hybrid, Hromodka Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Nc6 4.d3 Bb4 5.Ne2 *","weight":21041,"prior_delay":25,"prior_completion_time":0},
{"oid":249,"gid":29,"fullname":"Bishop's Opening, Vienna Hybrid, Spielmann Attack","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Bc5 4.d3 d6 5.f4 *","weight":20937,"prior_delay":25,"prior_completion_time":0},
{"oid":250,"gid":29,"fullname":"Bishop's Opening, Vienna Hybrid, Spielmann Attack","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Bc5 4.d3 d6 5.Na4 *","weight":20938,"prior_delay":25,"prior_completion_time":0},
{"oid":251,"gid":29,"fullname":"Bishop's Opening, Vienna Hybrid","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Nc6 4.d3 Bc5 5.f4 d6 6.Nf3 Bg4 *","weight":21038,"prior_delay":25,"prior_completion_time":0},
{"oid":252,"gid":29,"fullname":"Bishop's Opening, Vienna Hybrid","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Nc6 4.d3 Be7 *","weight":20998,"prior_delay":25,"prior_completion_time":0},
{"oid":253,"gid":29,"fullname":"Bishop's Opening, Vienna Hybrid","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Nc6 4.d3 Bc5 5.f4 d6 6.Nf3 a6 *","weight":21037,"prior_delay":25,"prior_completion_time":0},
{"oid":254,"gid":29,"fullname":"Bishop's Opening, Vienna Hybrid","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Nc6 4.d3 Bb4 5.Bg5 *","weight":21024,"prior_delay":25,"prior_completion_time":0},
{"oid":255,"gid":29,"fullname":"Bishop's Opening, Vienna Hybrid","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Nc6 4.d3 Bc5 5.Bg5 *","weight":21022,"prior_delay":25,"prior_completion_time":0},
{"oid":256,"gid":29,"fullname":"Bishop's Opening, Vienna Hybrid","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Nc6 4.d3 Na5 5.Nge2 *","weight":21138,"prior_delay":25,"prior_completion_time":0},
{"oid":257,"gid":29,"fullname":"Bishop's Opening, Vienna Hybrid","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Nc6 4.d3 Bb4 5.Nf3 *","weight":21032,"prior_delay":25,"prior_completion_time":0},
{"oid":258,"gid":30,"fullname":"Caro-Kann Defense, Accelerated Panov Attack, Modern Variation","main_line":"1.e4 c6 2.c4 d5 3.exd5 cxd5 4.cxd5 Nf6 5.Nc3 Nxd5 *","weight":17991,"prior_delay":25,"prior_completion_time":0},
{"oid":259,"gid":30,"fullname":"Caro-Kann Defense, Accelerated Panov Attack, Modern Variation","main_line":"1.e4 c6 2.c4 d5 3.exd5 cxd5 4.cxd5 Nf6 5.Bb5+ *","weight":17513,"prior_delay":25,"prior_completion_time":0},
{"oid":260,"gid":30,"fullname":"Caro-Kann Defense, Accelerated Panov Attack","main_line":"1.e4 c6 2.c4 d5 3.cxd5 cxd5 *","weight":16159,"prior_delay":25,"prior_completion_time":0},
{"oid":261,"gid":31,"fullname":"Caro-Kann Defense, Advance, Short Variation","main_line":"1.e4 c6 2.d4 d5 3.e5 Bf5 4.c3 e6 5.Be2 *","weight":32668,"prior_delay":25,"prior_completion_time":0},
{"oid":262,"gid":32,"fullname":"Caro-Kann Defense, Advance Variation, Bayonet Attack","main_line":"1.e4 c6 2.d4 d5 3.e5 Bf5 4.g4 *","weight":32600,"prior_delay":25,"prior_completion_time":0},
{"oid":263,"gid":32,"fullname":"Caro-Kann Defense, Advance Variation, Botvinnik-Carls Defense","main_line":"1.e4 c6 2.d4 d5 3.e5 c5 4.dxc5 Nc6 *","weight":31185,"prior_delay":25,"prior_completion_time":0},
{"oid":264,"gid":32,"fullname":"Caro-Kann Defense, Advance Variation, Short Variation","main_line":"1.e4 c6 2.d4 d5 3.e5 Bf5 4.Nf3 e6 5.Be2 c5 6.Be3 Ne7 *","weight":37323,"prior_delay":25,"prior_completion_time":0},
{"oid":265,"gid":32,"fullname":"Caro-Kann Defense, Advance Variation, Short Variation","main_line":"1.e4 c6 2.d4 d5 3.e5 Bf5 4.Nf3 e6 5.Be2 Nd7 *","weight":37013,"prior_delay":25,"prior_completion_time":0},
{"oid":266,"gid":32,"fullname":"Caro-Kann Defense, Advance Variation, Short Variation","main_line":"1.e4 c6 2.d4 d5 3.e5 Bf5 4.Nf3 e6 5.Be2 c5 6.O-O Ne7 *","weight":37076,"prior_delay":25,"prior_completion_time":0},
{"oid":267,"gid":32,"fullname":"Caro-Kann Defense, Advance Variation, Short Variation","main_line":"1.e4 c6 2.d4 d5 3.e5 Bf5 4.Nf3 e6 5.Be2 c5 6.Be3 Nd7 7.O-O *","weight":37352,"prior_delay":25,"prior_completion_time":0},
{"oid":268,"gid":32,"fullname":"Caro-Kann Defense, Advance Variation, Tal Variation","main_line":"1.e4 c6 2.d4 d5 3.e5 Bf5 4.h4 *","weight":32949,"prior_delay":25,"prior_completion_time":0},
{"oid":269,"gid":32,"fullname":"Caro-Kann Defense, Advance Variation, Van der Wiel Attack, Dreyev Defense","main_line":"1.e4 c6 2.d4 d5 3.e5 Bf5 4.Nc3 Qb6 *","weight":32901,"prior_delay":25,"prior_completion_time":0},
{"oid":270,"gid":32,"fullname":"Caro-Kann Defense, Advance Variation, Van der Wiel Attack","main_line":"1.e4 c6 2.d4 d5 3.e5 Bf5 4.Nc3 h5 *","weight":32890,"prior_delay":25,"prior_completion_time":0},
{"oid":271,"gid":32,"fullname":"Caro-Kann Defense, Advance Variation, Van der Wiel Attack","main_line":"1.e4 c6 2.d4 d5 3.e5 Bf5 4.Nc3 e6 5.g4 Bg6 6.Nge2 c5 *","weight":33685,"prior_delay":25,"prior_completion_time":0},
{"oid":272,"gid":32,"fullname":"Caro-Kann Defense, Advance Variation","main_line":"1.e4 c6 2.d4 d5 3.e5 Bf5 4.Bd3 *","weight":32583,"prior_delay":25,"prior_completion_time":0},
{"oid":273,"gid":33,"fullname":"Caro-Kann Defense, Breyer Variation","main_line":"1.e4 c6 2.d3 d5 3.Nd2 g6 *","weight":15518,"prior_delay":25,"prior_completion_time":0},
{"oid":274,"gid":33,"fullname":"Caro-Kann Defense, Breyer Variation","main_line":"1.e4 c6 2.d3 d5 3.Nd2 e5 4.Ngf3 Bd6 5.g3 *","weight":15744,"prior_delay":25,"prior_completion_time":0},
{"oid":275,"gid":34,"fullname":"Caro-Kann Defense, Bronstein-Larsen Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nf6 5.Nxf6+ gxf6 6.c3 Bf5 7.Nf3 e6 *","weight":32725,"prior_delay":25,"prior_completion_time":0},
{"oid":276,"gid":34,"fullname":"Caro-Kann Defense, Bronstein-Larsen Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nf6 5.Nxf6+ gxf6 6.Nf3 Bf5 *","weight":32689,"prior_delay":25,"prior_completion_time":0},
{"oid":277,"gid":34,"fullname":"Caro-Kann Defense, Bronstein-Larsen Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nf6 5.Nxf6+ gxf6 6.c3 Bf5 7.Ne2 *","weight":32716,"prior_delay":25,"prior_completion_time":0},
{"oid":278,"gid":34,"fullname":"Caro-Kann Defense, Bronstein-Larsen Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nf6 5.Nxf6+ gxf6 6.Ne2 *","weight":32668,"prior_delay":25,"prior_completion_time":0},
{"oid":279,"gid":34,"fullname":"Caro-Kann Defense, Bronstein-Larsen Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nf6 5.Nxf6+ gxf6 6.Qd3 *","weight":32668,"prior_delay":25,"prior_completion_time":0},
{"oid":280,"gid":34,"fullname":"Caro-Kann Defense, Bronstein-Larsen Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nf6 5.Nxf6+ gxf6 6.Bc4 *","weight":32672,"prior_delay":25,"prior_completion_time":0},
{"oid":281,"gid":35,"fullname":"Caro-Kann Defense, Classical, Spassky Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.h4 h6 7.Nf3 Nd7 8.h5 Bh7  9.Bd3 Bxd3 10.Qxd3 Qc7 11.Bd2 e6 12.O-O-O Ngf6 13.Ne4 O-O-O 14.g3 Nxe4 *","weight":47488,"prior_delay":25,"prior_completion_time":0},
{"oid":282,"gid":35,"fullname":"Caro-Kann Defense, Classical, Spassky Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.h4 h6 7.Nf3 Nd7 8.h5 Bh7  9.Bd3 Bxd3 10.Qxd3 Ngf6 *","weight":46765,"prior_delay":25,"prior_completion_time":0},
{"oid":283,"gid":35,"fullname":"Caro-Kann Defense, Classical, Spassky Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.h4 h6 7.Nf3 Nd7 8.h5 Bh7  9.Bd3 Bxd3 10.Qxd3 e6 11.Bf4 *","weight":47779,"prior_delay":25,"prior_completion_time":0},
{"oid":284,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation, Main Line","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.h4 h6 7.Nf3 e6 *","weight":41068,"prior_delay":25,"prior_completion_time":0},
{"oid":285,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation, Main Line","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.h4 h6 7.Nf3 Nf6 8.h5 Bh7 *","weight":41090,"prior_delay":25,"prior_completion_time":0},
{"oid":286,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation, Main Line","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.h4 h6 7.f4 *","weight":39731,"prior_delay":25,"prior_completion_time":0},
{"oid":287,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation, Main Line","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.h4 h6 7.Nh3 *","weight":39722,"prior_delay":25,"prior_completion_time":0},
{"oid":288,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation, Main Line","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.h4 h6 7.Nf3 Nf6 8.Ne5 Bh7  9.Bd3 *","weight":41244,"prior_delay":25,"prior_completion_time":0},
{"oid":289,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation, Main Line","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.h4 h6 7.h5 *","weight":39730,"prior_delay":25,"prior_completion_time":0},
{"oid":290,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation, Maroczy Attack","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.f4 *","weight":37345,"prior_delay":25,"prior_completion_time":0},
{"oid":291,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.Nf3 Nd7 7.Bd3 e6 *","weight":37711,"prior_delay":25,"prior_completion_time":0},
{"oid":292,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Nc5 b6 *","weight":34131,"prior_delay":25,"prior_completion_time":0},
{"oid":293,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.Bc4 e6 7.N1e2 Nf6 *","weight":37637,"prior_delay":25,"prior_completion_time":0},
{"oid":294,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.N1e2 *","weight":37422,"prior_delay":25,"prior_completion_time":0},
{"oid":295,"gid":36,"fullname":"Caro-Kann Defense, Classical Variation, Flohr Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bf5 5.Ng3 Bg6 6.Nh3 *","weight":37502,"prior_delay":25,"prior_completion_time":0},
{"oid":296,"gid":37,"fullname":"Caro-Kann Defense, Edinburgh Variation","main_line":"1.e4 c6 2.d4 d5 3.Nd2 Qb6 *","weight":28664,"prior_delay":25,"prior_completion_time":0},
{"oid":297,"gid":38,"fullname":"Caro-Kann Defense, Exchange Variation, Rubinstein Variation","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.Bd3 Nc6 5.c3 Nf6 6.Bf4 Bg4 7.Qb3 Qc8 *","weight":30149,"prior_delay":25,"prior_completion_time":0},
{"oid":298,"gid":38,"fullname":"Caro-Kann Defense, Exchange Variation, Rubinstein Variation","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.Bd3 Nc6 5.c3 Nf6 6.Bf4 Bg4 7.Qb3 Qd7 *","weight":30140,"prior_delay":25,"prior_completion_time":0},
{"oid":299,"gid":38,"fullname":"Caro-Kann Defense, Exchange Variation","main_line":"1.e4 c6 2.d4 d5 3.exd5 Qxd5 *","weight":28472,"prior_delay":25,"prior_completion_time":0},
{"oid":300,"gid":38,"fullname":"Caro-Kann Defense, Exchange Variation","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.Bd3 Nc6 5.c3 g6 *","weight":29869,"prior_delay":25,"prior_completion_time":0},
{"oid":301,"gid":38,"fullname":"Caro-Kann Defense, Exchange Variation","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.Nf3 Nf6 *","weight":29411,"prior_delay":25,"prior_completion_time":0},
{"oid":302,"gid":38,"fullname":"Caro-Kann Defense, Exchange Variation","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.Bd3 Nc6 5.c3 Nf6 6.Nf3 *","weight":29963,"prior_delay":25,"prior_completion_time":0},
{"oid":303,"gid":39,"fullname":"Caro-Kann Defense, Forgacs Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nf6 5.Nxf6+ exf6 6.Bc4 *","weight":32691,"prior_delay":25,"prior_completion_time":0},
{"oid":304,"gid":40,"fullname":"Caro-Kann Defense, Gurgenidze Counterattack","main_line":"1.e4 c6 2.d4 d5 3.Nc3 b5 *","weight":29193,"prior_delay":25,"prior_completion_time":0},
{"oid":305,"gid":41,"fullname":"Caro-Kann Defense, Gurgenidze System","main_line":"1.e4 c6 2.d4 d5 3.Nc3 g6 4.e5 Bg7 5.f4 Nh6 *","weight":29258,"prior_delay":25,"prior_completion_time":0},
{"oid":306,"gid":41,"fullname":"Caro-Kann Defense, Gurgenidze System","main_line":"1.e4 c6 2.d4 d5 3.Nc3 g6 4.h3 Bg7 *","weight":29244,"prior_delay":25,"prior_completion_time":0},
{"oid":307,"gid":41,"fullname":"Caro-Kann Defense, Gurgenidze System","main_line":"1.e4 c6 2.d4 d5 3.Nc3 g6 4.Nf3 Bg7 5.exd5 *","weight":29255,"prior_delay":25,"prior_completion_time":0},
{"oid":308,"gid":41,"fullname":"Caro-Kann Defense, Gurgenidze System","main_line":"1.e4 c6 2.d4 d5 3.Nc3 g6 4.Nf3 Bg7 5.e5 *","weight":29255,"prior_delay":25,"prior_completion_time":0},
{"oid":309,"gid":41,"fullname":"Caro-Kann Defense, Gurgenidze System","main_line":"1.e4 c6 2.d4 d5 3.Nc3 g6 4.Nf3 Bg7 5.h3 dxe4 6.Nxe4 *","weight":29378,"prior_delay":25,"prior_completion_time":0},
{"oid":310,"gid":41,"fullname":"Caro-Kann Defense, Gurgenidze System","main_line":"1.e4 c6 2.d4 d5 3.Nc3 g6 4.Nf3 Bg7 5.h3 Nf6 6.e5 *","weight":29361,"prior_delay":25,"prior_completion_time":0},
{"oid":311,"gid":42,"fullname":"Caro-Kann Defense, Karpov Variation, Modern Main Line","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nd7 5.Ng5 Ngf6 6.Bd3 e6 7.N1f3 Bd6 8.Qe2  h6 9.Ne4 Nxe4 10.Qxe4 Nf6 *","weight":35514,"prior_delay":25,"prior_completion_time":0},
{"oid":312,"gid":42,"fullname":"Caro-Kann Defense, Karpov Variation, Modern Main Line","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nd7 5.Ng5 Ngf6 6.Bd3 e6 7.N1f3 Bd6 8.Qe2  h6 9.Ne4 Nxe4 10.Qxe4 Qc7 *","weight":35607,"prior_delay":25,"prior_completion_time":0},
{"oid":313,"gid":42,"fullname":"Caro-Kann Defense, Karpov Variation, Modern Variation, Kasparov Attack","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nd7 5.Nf3 Ngf6 6.Ng3 e6 *","weight":33505,"prior_delay":25,"prior_completion_time":0},
{"oid":314,"gid":42,"fullname":"Caro-Kann Defense, Karpov Variation, Smyslov Variation, Main Line","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nd7 5.Bc4 Ngf6 6.Ng5 e6 7.Qe2 Nb6 8.Bb3 *","weight":33741,"prior_delay":25,"prior_completion_time":0},
{"oid":315,"gid":42,"fullname":"Caro-Kann Defense, Karpov Variation, Smyslov Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nd7 5.Bc4 Ngf6 6.Ng5 e6 7.Qe2 Nb6  8.Bd3 h6 *","weight":33803,"prior_delay":25,"prior_completion_time":0},
{"oid":316,"gid":42,"fullname":"Caro-Kann Defense, Karpov Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nd7 5.Nf3 Ngf6 6.Nxf6+ Nxf6 7.Bc4 *","weight":33702,"prior_delay":25,"prior_completion_time":0},
{"oid":317,"gid":43,"fullname":"Caro-Kann Defense, Main Line","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nf6 5.Ng3 *","weight":32463,"prior_delay":25,"prior_completion_time":0},
{"oid":318,"gid":44,"fullname":"Caro-Kann Defense, Maroczy Variation, Maroczy Gambit","main_line":"1.e4 c6 2.d4 d5 3.f3 dxe4 4.fxe4 e5 5.Nf3 exd4 6.Bc4 *","weight":27786,"prior_delay":25,"prior_completion_time":0},
{"oid":319,"gid":44,"fullname":"Caro-Kann Defense, Maroczy Variation","main_line":"1.e4 c6 2.d4 d5 3.f3 e6 *","weight":27781,"prior_delay":25,"prior_completion_time":0},
{"oid":320,"gid":45,"fullname":"Caro-Kann Defense, Modern Variation","main_line":"1.e4 c6 2.d4 d5 3.Nd2 dxe4 *","weight":29640,"prior_delay":25,"prior_completion_time":0},
{"oid":321,"gid":45,"fullname":"Caro-Kann Defense, Modern Variation","main_line":"1.e4 c6 2.d4 d5 3.Nd2 g6 4.Ngf3 Bg7 5.c3 *","weight":28706,"prior_delay":25,"prior_completion_time":0},
{"oid":322,"gid":45,"fullname":"Caro-Kann Defense, Modern Variation","main_line":"1.e4 c6 2.d4 d5 3.Nd2 g6 4.Ngf3 Bg7 5.h3 *","weight":28701,"prior_delay":25,"prior_completion_time":0},
{"oid":323,"gid":46,"fullname":"Caro-Kann Defense, Panov Attack, Modern Defense, Carlsbad Line","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Nf3 Be7 8.c5 O-O  9.Bb5 *","weight":32418,"prior_delay":25,"prior_completion_time":0},
{"oid":324,"gid":46,"fullname":"Caro-Kann Defense, Panov Attack, Modern Defense, Czerniak Line","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 Nc6 6.Bg5 Qa5 *","weight":32272,"prior_delay":25,"prior_completion_time":0},
{"oid":325,"gid":46,"fullname":"Caro-Kann Defense, Panov Attack, Modern Defense, Mieses Line","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 Nc6 6.Nf3 Bg4 7.cxd5 Nxd5 8.Qb3  Bxf3 9.gxf3 Nb6 *","weight":32769,"prior_delay":25,"prior_completion_time":0},
{"oid":326,"gid":46,"fullname":"Caro-Kann Defense, Panov Attack, Modern Defense, Mieses Line","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 Nc6 6.Nf3 Bg4 7.cxd5 Nxd5  8.Qb3 Bxf3 9.gxf3 e6 *","weight":32836,"prior_delay":25,"prior_completion_time":0},
{"oid":327,"gid":46,"fullname":"Caro-Kann Defense, Panov Attack, Modern Defense","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 Nc6 6.Bg5 Be6 *","weight":32311,"prior_delay":25,"prior_completion_time":0},
{"oid":328,"gid":46,"fullname":"Caro-Kann Defense, Panov Attack, Modern Defense","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 Nc6 6.cxd5 Nxd5 *","weight":32073,"prior_delay":25,"prior_completion_time":0},
{"oid":329,"gid":46,"fullname":"Caro-Kann Defense, Panov Attack","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 e6 6.Nf3 Nc6 *","weight":32732,"prior_delay":25,"prior_completion_time":0},
{"oid":330,"gid":46,"fullname":"Caro-Kann Defense, Panov Attack","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 e6 6.Nf3 Be7 7.Bd3 *","weight":32813,"prior_delay":25,"prior_completion_time":0},
{"oid":331,"gid":46,"fullname":"Caro-Kann Defense, Panov Attack","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 e6 *","weight":30027,"prior_delay":25,"prior_completion_time":0},
{"oid":332,"gid":46,"fullname":"Caro-Kann Defense, Panov Attack","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 e6 6.Nf3 Be7 7.cxd5 Nxd5 8.Bd3 *","weight":33085,"prior_delay":25,"prior_completion_time":0},
{"oid":333,"gid":47,"fullname":"Caro-Kann Defense, Panov Attack,Fianchetto Defense","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 g6 6.Qb3 Bg7 7.cxd5 O-O  8.Be2 Nbd7 *","weight":32104,"prior_delay":25,"prior_completion_time":0},
{"oid":334,"gid":47,"fullname":"Caro-Kann Defense, Panov Attack,Fianchetto Defense","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 g6 6.Nf3 *","weight":31834,"prior_delay":25,"prior_completion_time":0},
{"oid":335,"gid":47,"fullname":"Caro-Kann Defense, Panov Attack,Fianchetto Defense","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 g6 6.cxd5 Nxd5 7.Bc4 *","weight":31892,"prior_delay":25,"prior_completion_time":0},
{"oid":336,"gid":48,"fullname":"Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 g6 6.cxd5 Bg7 *","weight":31863,"prior_delay":25,"prior_completion_time":0},
{"oid":337,"gid":49,"fullname":"Caro-Kann Defense, Panov-Botvinnik, Herzog Defense","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 Nc6 6.Bg5 dxc4 7.d5 Na5 *","weight":32345,"prior_delay":25,"prior_completion_time":0},
{"oid":338,"gid":50,"fullname":"Caro-Kann Defense, Rasa-Studier Gambit","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.f3 *","weight":30648,"prior_delay":25,"prior_completion_time":0},
{"oid":339,"gid":51,"fullname":"Caro-Kann Defense, Tartakower Variation","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nf6 5.Nxf6+ exf6 6.c3 *","weight":32730,"prior_delay":25,"prior_completion_time":0},
{"oid":340,"gid":52,"fullname":"Caro-Kann Defense, Two Knights Attack, Mindeno Variation, Exchange Line","main_line":"1.e4 c6 2.Nc3 d5 3.Nf3 Bg4 4.h3 Bxf3 5.Qxf3 Nf6 *","weight":18456,"prior_delay":25,"prior_completion_time":0},
{"oid":341,"gid":52,"fullname":"Caro-Kann Defense, Two Knights Attack, Mindeno Variation, Exchange Line","main_line":"1.e4 c6 2.Nc3 d5 3.Nf3 Bg4 4.h3 Bxf3 5.Qxf3 e6 6.d3 Nd7 *","weight":18748,"prior_delay":25,"prior_completion_time":0},
{"oid":342,"gid":52,"fullname":"Caro-Kann Defense, Two Knights Attack, Mindeno Variation, Exchange Line","main_line":"1.e4 c6 2.Nc3 d5 3.Nf3 Bg4 4.h3 Bxf3 5.Qxf3 e6 6.g3 Nf6 *","weight":18691,"prior_delay":25,"prior_completion_time":0},
{"oid":343,"gid":52,"fullname":"Caro-Kann Defense, Two Knights Attack, Mindeno Variation, Exchange Line","main_line":"1.e4 c6 2.Nc3 d5 3.Nf3 Bg4 4.h3 Bxf3 5.Qxf3 e6 6.d3 Nf6 7.a3 *","weight":18743,"prior_delay":25,"prior_completion_time":0},
{"oid":344,"gid":52,"fullname":"Caro-Kann Defense, Two Knights Attack, Mindeno Variation, Exchange Line","main_line":"1.e4 c6 2.Nc3 d5 3.Nf3 Bg4 4.h3 Bxf3 5.Qxf3 e6 6.d4 *","weight":18725,"prior_delay":25,"prior_completion_time":0},
{"oid":345,"gid":52,"fullname":"Caro-Kann Defense, Two Knights Attack, Mindeno Variation, Exchange Line","main_line":"1.e4 c6 2.Nc3 d5 3.Nf3 Bg4 4.h3 Bxf3 5.Qxf3 e6 6.d3 Nf6 7.Bd2 *","weight":18760,"prior_delay":25,"prior_completion_time":0},
{"oid":346,"gid":52,"fullname":"Caro-Kann Defense, Two Knights Attack, Mindeno Variation, Exchange Line","main_line":"1.e4 c6 2.Nc3 d5 3.Nf3 Bg4 4.h3 Bxf3 5.Qxf3 dxe4 6.Nxe4 *","weight":18366,"prior_delay":25,"prior_completion_time":0},
{"oid":347,"gid":52,"fullname":"Caro-Kann Defense, Two Knights Attack, Mindeno Variation, Retreat Line","main_line":"1.e4 c6 2.Nc3 d5 3.Nf3 Bg4 4.h3 Bh5 5.exd5 cxd5 6.Bb5+ Nc6 7.g4 Bg6 *","weight":17658,"prior_delay":25,"prior_completion_time":0},
{"oid":348,"gid":52,"fullname":"Caro-Kann Defense, Two Knights Attack","main_line":"1.e4 c6 2.Nc3 d5 3.Nf3 dxe4 4.Nxe4 Nf6 *","weight":16926,"prior_delay":25,"prior_completion_time":0},
{"oid":349,"gid":53,"fullname":"Caro-Kann Defense, von Hennig Gambit","main_line":"1.e4 c6 2.d4 d5 3.Nc3 dxe4 4.Bc4 *","weight":30647,"prior_delay":25,"prior_completion_time":0},
{"oid":350,"gid":54,"fullname":"Czech Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 c6 4.f4 Qa5 *","weight":21456,"prior_delay":25,"prior_completion_time":0},
{"oid":351,"gid":54,"fullname":"Czech Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 c6 4.Nf3 *","weight":21393,"prior_delay":25,"prior_completion_time":0},
{"oid":352,"gid":55,"fullname":"Four Knights Game, Bardeleben Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Bc5 5.O-O O-O 6.Nxe5 Nxe5 7.d4 Bd6  8.f4 Nc6 9.e5 Bb4 *","weight":50833,"prior_delay":25,"prior_completion_time":0},
{"oid":353,"gid":56,"fullname":"Four Knights Game, Gunsberg Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.a3 *","weight":50183,"prior_delay":25,"prior_completion_time":0},
{"oid":354,"gid":57,"fullname":"Four Knights Game, Italian Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.Nc3 Nf6 *","weight":53100,"prior_delay":25,"prior_completion_time":0},
{"oid":355,"gid":57,"fullname":"Four Knights Game, Italian Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bc4 *","weight":50174,"prior_delay":25,"prior_completion_time":0},
{"oid":356,"gid":58,"fullname":"Four Knights Game, Janowski Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Bb4 5.O-O O-O 6.d3 Bxc3 7.bxc3 d6 8.Re1 *","weight":51794,"prior_delay":25,"prior_completion_time":0},
{"oid":357,"gid":60,"fullname":"Four Knights Game, Marshall Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Bc5 5.O-O O-O 6.Nxe5 Nd4 *","weight":50792,"prior_delay":25,"prior_completion_time":0},
{"oid":358,"gid":61,"fullname":"Four Knights Game, Nimzowitsch (Paulsen)","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Bb4 5.O-O O-O 6.Bxc6 *","weight":51407,"prior_delay":25,"prior_completion_time":0},
{"oid":359,"gid":62,"fullname":"Four Knights Game, Rubinstein Countergambit, Henneberger Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Nd4 5.O-O *","weight":50950,"prior_delay":25,"prior_completion_time":0},
{"oid":360,"gid":63,"fullname":"Four Knights Game, Scotch Variation, Belgrade Gambit","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.d4 exd4 5.Nd5 Be7 *","weight":50817,"prior_delay":25,"prior_completion_time":0},
{"oid":361,"gid":63,"fullname":"Four Knights Game, Scotch Variation, Krause Gambit","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.d4 Bb4 5.Nxe5 *","weight":50565,"prior_delay":25,"prior_completion_time":0},
{"oid":362,"gid":63,"fullname":"Four Knights Game, Scotch Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.d4 d6 *","weight":50522,"prior_delay":25,"prior_completion_time":0},
{"oid":363,"gid":63,"fullname":"Four Knights Game, Scotch Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.d4 Bb4 5.d5 *","weight":50546,"prior_delay":25,"prior_completion_time":0},
{"oid":364,"gid":64,"fullname":"Four Knights Game, Scotch Variation Accepted","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.d4 exd4 5.Nxd4 Bc5 *","weight":51150,"prior_delay":25,"prior_completion_time":0},
{"oid":365,"gid":64,"fullname":"Four Knights Game, Scotch Variation Accepted","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.d4 exd4 5.Nxd4 d6 *","weight":51138,"prior_delay":25,"prior_completion_time":0},
{"oid":366,"gid":64,"fullname":"Four Knights Game, Scotch Variation Accepted","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.d4 exd4 5.Nxd4 Bb4 6.Nxc6 bxc6 7.Bd3 d5  8.exd5 cxd5 9.O-O O-O 10.Bg5 c6 11.Qf3 Be7 *","weight":53814,"prior_delay":25,"prior_completion_time":0},
{"oid":367,"gid":64,"fullname":"Four Knights Game, Scotch Variation Accepted","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.d4 exd4 5.Nxd4 Bb4 6.Nxc6 bxc6 7.Bd3 d5  8.exd5 cxd5 9.O-O O-O 10.Bg5 c6 11.Na4 *","weight":53769,"prior_delay":25,"prior_completion_time":0},
{"oid":368,"gid":65,"fullname":"Four Knights Game, Spanish Variation, Classical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Bc5 5.Nxe5 *","weight":50748,"prior_delay":25,"prior_completion_time":0},
{"oid":369,"gid":65,"fullname":"Four Knights Game, Spanish Variation, Rubinstein Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Nd4 5.Nxe5 Qe7 6.f4 *","weight":50954,"prior_delay":25,"prior_completion_time":0},
{"oid":370,"gid":65,"fullname":"Four Knights Game, Spanish Variation, Rubinstein Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Nd4 5.Ba4 Nxf3+ *","weight":51060,"prior_delay":25,"prior_completion_time":0},
{"oid":371,"gid":65,"fullname":"Four Knights Game, Spanish Variation, Rubinstein Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Nd4 5.Ba4 c6 6.Nxe5 *","weight":51115,"prior_delay":25,"prior_completion_time":0},
{"oid":372,"gid":65,"fullname":"Four Knights Game, Spanish Variation, Rubinstein Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Nd4 5.Bc4 *","weight":51013,"prior_delay":25,"prior_completion_time":0},
{"oid":373,"gid":65,"fullname":"Four Knights Game, Spanish Variation, Rubinstein Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Nd4 5.Ba4 Bc5 6.Nxe5 *","weight":51130,"prior_delay":25,"prior_completion_time":0},
{"oid":374,"gid":65,"fullname":"Four Knights Game, Spanish Variation, Rubinstein Variation Accepted","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Nd4 5.Nxd4 exd4 6.e5 dxc3 7.exf6 Qxf6  8.dxc3 Qe5+ *","weight":51296,"prior_delay":25,"prior_completion_time":0},
{"oid":375,"gid":65,"fullname":"Four Knights Game, Spanish Variation, Rubinstein Variation Accepted","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Nd4 5.Nxd4 exd4 6.e5 dxc3 7.exf6 Qxf6  8.dxc3 Bc5 *","weight":51289,"prior_delay":25,"prior_completion_time":0},
{"oid":376,"gid":65,"fullname":"Four Knights Game, Spanish Variation, Symmetrical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Bb4 5.O-O O-O 6.d3 d6 7.Bg5 Bxc3  8.bxc3 h6 *","weight":52013,"prior_delay":25,"prior_completion_time":0},
{"oid":377,"gid":65,"fullname":"Four Knights Game, Spanish Variation, Symmetrical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Bb4 5.O-O O-O 6.d3 d6 7.Bg5 Bxc3  8.bxc3 Qe7 9.Re1 Nd8 10.d4 Bg4 *","weight":52205,"prior_delay":25,"prior_completion_time":0},
{"oid":378,"gid":65,"fullname":"Four Knights Game, Spanish Variation, Symmetrical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Bb4 5.O-O O-O 6.d3 d6 7.Ne2 *","weight":51784,"prior_delay":25,"prior_completion_time":0},
{"oid":379,"gid":65,"fullname":"Four Knights Game, Spanish Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 d6 5.d4 *","weight":50733,"prior_delay":25,"prior_completion_time":0},
{"oid":380,"gid":66,"fullname":"Four Knights Game, Symmetrical, Blake Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Bb4 5.O-O O-O 6.d3 d6 7.Bg5 Ne7 8.Nh4 c6  9.Bc4 d5 10.Bb3 Qd6 *","weight":51959,"prior_delay":25,"prior_completion_time":0},
{"oid":381,"gid":66,"fullname":"Four Knights Game, Symmetrical, Metger Unpin","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Bb4 5.O-O O-O 6.d3 d6 7.Bg5 Bxc3  8.bxc3 Qe7 9.Re1 Nd8 10.d4 Ne6 11.Bc1 c5 *","weight":52266,"prior_delay":25,"prior_completion_time":0},
{"oid":382,"gid":66,"fullname":"Four Knights Game, Symmetrical, Metger Unpin","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Bb5 Bb4 5.O-O O-O 6.d3 d6 7.Bg5 Bxc3  8.bxc3 Qe7 9.Re1 Nd8 10.d4 Ne6 11.Bc1 Rd8 *","weight":52279,"prior_delay":25,"prior_completion_time":0},
{"oid":383,"gid":59,"fullname":"Four Knights Game, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.Be2 *","weight":50202,"prior_delay":25,"prior_completion_time":0},
{"oid":384,"gid":59,"fullname":"Four Knights Game, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.g3 Bc5 5.Bg2 d6 6.d3 a6 *","weight":50555,"prior_delay":25,"prior_completion_time":0},
{"oid":385,"gid":59,"fullname":"Four Knights Game, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Nf6 4.g3 d5 *","weight":50345,"prior_delay":25,"prior_completion_time":0},
{"oid":386,"gid":67,"fullname":"French Defense, Advance Variation, Euwe Variation","main_line":"1.e4 e6 2.d4 d5 3.e5 c5 4.c3 Nc6 5.Nf3 Bd7 6.Be2 Nge7 *","weight":37009,"prior_delay":25,"prior_completion_time":0},
{"oid":387,"gid":67,"fullname":"French Defense, Advance Variation, Euwe Variation","main_line":"1.e4 e6 2.d4 d5 3.e5 c5 4.c3 Nc6 5.Nf3 Bd7 6.a3 *","weight":36651,"prior_delay":25,"prior_completion_time":0},
{"oid":388,"gid":67,"fullname":"French Defense, Advance Variation, Lputian Variation","main_line":"1.e4 e6 2.d4 d5 3.e5 c5 4.c3 Nc6 5.Nf3 Qb6 6.a3 Nh6 *","weight":36869,"prior_delay":25,"prior_completion_time":0},
{"oid":389,"gid":67,"fullname":"French Defense, Advance Variation, Main Line","main_line":"1.e4 e6 2.d4 d5 3.e5 c5 4.c3 Nc6 5.Nf3 Qb6 6.a3 Bd7 *","weight":36795,"prior_delay":25,"prior_completion_time":0},
{"oid":390,"gid":67,"fullname":"French Defense, Advance Variation, Main Line","main_line":"1.e4 e6 2.d4 d5 3.e5 c5 4.c3 Nc6 5.Nf3 Qb6 6.a3 c4 7.Nbd2 *","weight":36884,"prior_delay":25,"prior_completion_time":0},
{"oid":391,"gid":67,"fullname":"French Defense, Advance Variation, Milner-Barry Gambit","main_line":"1.e4 e6 2.d4 d5 3.e5 c5 4.c3 Nc6 5.Nf3 Qb6 6.Bd3 *","weight":36557,"prior_delay":25,"prior_completion_time":0},
{"oid":392,"gid":67,"fullname":"French Defense, Advance Variation, Paulsen Attack","main_line":"1.e4 e6 2.d4 d5 3.e5 c5 4.c3 Nc6 5.Nf3 Nge7 *","weight":36392,"prior_delay":25,"prior_completion_time":0},
{"oid":393,"gid":67,"fullname":"French Defense, Advance Variation, Paulsen Attack","main_line":"1.e4 e6 2.d4 d5 3.e5 c5 4.c3 Nc6 5.Nf3 Qb6 6.Be2 cxd4 7.cxd4 *","weight":36648,"prior_delay":25,"prior_completion_time":0},
{"oid":394,"gid":67,"fullname":"French Defense, Advance Variation, Ruisdonk Gambit","main_line":"1.e4 e6 2.d4 d5 3.e5 c5 4.Nf3 cxd4 5.Bd3 *","weight":34664,"prior_delay":25,"prior_completion_time":0},
{"oid":395,"gid":67,"fullname":"French Defense, Advance Variation, Wade Variation","main_line":"1.e4 e6 2.d4 d5 3.e5 c5 4.c3 Qb6 5.Nf3 Bd7 6.Be2 *","weight":35833,"prior_delay":25,"prior_completion_time":0},
{"oid":396,"gid":67,"fullname":"French Defense, Advance Variation","main_line":"1.e4 e6 2.d4 d5 3.e5 b6 4.c3 Qd7 *","weight":34073,"prior_delay":25,"prior_completion_time":0},
{"oid":397,"gid":67,"fullname":"French Defense, Advance Variation","main_line":"1.e4 e6 2.d4 d5 3.e5 Ne7 *","weight":34049,"prior_delay":25,"prior_completion_time":0},
{"oid":398,"gid":68,"fullname":"French Defense, Alekhine-Chatard Attack, Albin-Chatard Gambit","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Be7 5.e5 Nfd7 6.h4 Bxg5 7.hxg5 Qxg5  8.Nh3 Qe7 9.Nf4 *","weight":42984,"prior_delay":25,"prior_completion_time":0},
{"oid":399,"gid":68,"fullname":"French Defense, Alekhine-Chatard Attack, Breyer Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Be7 5.e5 Nfd7 6.h4 c5 7.Bxe7 Kxe7 *","weight":42968,"prior_delay":25,"prior_completion_time":0},
{"oid":400,"gid":68,"fullname":"French Defense, Alekhine-Chatard Attack, Spielmann Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Be7 5.e5 Nfd7 6.h4 O-O *","weight":42949,"prior_delay":25,"prior_completion_time":0},
{"oid":401,"gid":69,"fullname":"French Defense, Burn Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 dxe4 5.Nxe4 Nbd7 6.Nf3 Be7 7.Nxf6+ Nxf6 *","weight":44748,"prior_delay":25,"prior_completion_time":0},
{"oid":402,"gid":69,"fullname":"French Defense, Burn Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 dxe4 5.Nxe4 Be7 6.Bxf6 Bxf6 *","weight":44849,"prior_delay":25,"prior_completion_time":0},
{"oid":403,"gid":70,"fullname":"French Defense, Classical, Stahlberg Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Be7 5.e5 Nfd7 6.Bxe7 Qxe7 7.f4 O-O  8.Nf3 c5 9.Qd2 Nc6 10.O-O-O c4 *","weight":43389,"prior_delay":25,"prior_completion_time":0},
{"oid":404,"gid":71,"fullname":"French Defense, Classical Variation, Alapin Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Be7 5.e5 Nfd7 6.Bxe7 Qxe7 7.Nb5 *","weight":43097,"prior_delay":25,"prior_completion_time":0},
{"oid":405,"gid":71,"fullname":"French Defense, Classical Variation, Burn Variation, Morozevich Line","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 dxe4 5.Nxe4 Be7 6.Bxf6 gxf6 7.Nf3 f5 *","weight":45036,"prior_delay":25,"prior_completion_time":0},
{"oid":406,"gid":71,"fullname":"French Defense, Classical Variation, Burn Variation, Morozevich Line","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 dxe4 5.Nxe4 Be7 6.Bxf6 gxf6 7.Nf3 b6 8.Bc4 *","weight":45020,"prior_delay":25,"prior_completion_time":0},
{"oid":407,"gid":71,"fullname":"French Defense, Classical Variation, Burn Variation, Morozevich Line","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 dxe4 5.Nxe4 Be7 6.Bxf6 gxf6 7.Nf3 b6 8.Bd3 *","weight":45017,"prior_delay":25,"prior_completion_time":0},
{"oid":408,"gid":71,"fullname":"French Defense, Classical Variation, Delayed Exchange Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.exd5 *","weight":41033,"prior_delay":25,"prior_completion_time":0},
{"oid":409,"gid":71,"fullname":"French Defense, Classical Variation, Pollock Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Be7 5.e5 Nfd7 6.Bxe7 Qxe7 7.Qg4 *","weight":43096,"prior_delay":25,"prior_completion_time":0},
{"oid":410,"gid":71,"fullname":"French Defense, Classical Variation, Rubinstein Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Be7 5.e5 Nfd7 6.Bxe7 Qxe7 7.Qd2 *","weight":43104,"prior_delay":25,"prior_completion_time":0},
{"oid":411,"gid":71,"fullname":"French Defense, Classical Variation, Steinitz Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Be7 5.e5 Nfd7 6.Bxe7 Qxe7 7.f4 O-O  8.Nf3 c5 9.Qd2 Nc6 10.dxc5 Qxc5 *","weight":43409,"prior_delay":25,"prior_completion_time":0},
{"oid":412,"gid":71,"fullname":"French Defense, Classical Variation, Steinitz Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.e5 Nfd7 5.Nf3 c5 6.dxc5 *","weight":43396,"prior_delay":25,"prior_completion_time":0},
{"oid":413,"gid":71,"fullname":"French Defense, Classical Variation, Steinitz Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Be7 5.e5 Nfd7 6.Bxe7 Qxe7 7.f4 a6 8.Nf3 c5  9.dxc5 *","weight":43371,"prior_delay":25,"prior_completion_time":0},
{"oid":414,"gid":71,"fullname":"French Defense, Classical Variation, Steinitz Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Be7 5.e5 Nfd7 6.Bxe7 Qxe7 7.f4 O-O 8.Nf3 c5  9.dxc5 *","weight":43331,"prior_delay":25,"prior_completion_time":0},
{"oid":415,"gid":71,"fullname":"French Defense, Classical Variation, Swiss Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bd3 *","weight":41032,"prior_delay":25,"prior_completion_time":0},
{"oid":416,"gid":71,"fullname":"French Defense, Classical Variation, Tartakower Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Be7 5.e5 Ne4 6.Bxe7 *","weight":42711,"prior_delay":25,"prior_completion_time":0},
{"oid":417,"gid":72,"fullname":"French Defense, Exchange Variation, Monte Carlo Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.c4 Nf6 5.Nc3 c6 *","weight":34100,"prior_delay":25,"prior_completion_time":0},
{"oid":418,"gid":72,"fullname":"French Defense, Exchange Variation, Monte Carlo Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.c4 Nf6 5.Nc3 Bb4 6.Nf3 *","weight":34122,"prior_delay":25,"prior_completion_time":0},
{"oid":419,"gid":72,"fullname":"French Defense, Exchange Variation, Monte Carlo Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.c4 Nf6 5.Nf3 *","weight":34084,"prior_delay":25,"prior_completion_time":0},
{"oid":420,"gid":72,"fullname":"French Defense, Exchange Variation, Monte Carlo Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.c4 Nf6 5.Nc3 Be7 6.Nf3 *","weight":34133,"prior_delay":25,"prior_completion_time":0},
{"oid":421,"gid":72,"fullname":"French Defense, Exchange Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 Qxd5 *","weight":33631,"prior_delay":25,"prior_completion_time":0},
{"oid":422,"gid":72,"fullname":"French Defense, Exchange Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.Nc3 Bb4 5.Bd3 Nc6 *","weight":34235,"prior_delay":25,"prior_completion_time":0},
{"oid":423,"gid":72,"fullname":"French Defense, Exchange Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.Bd3 Bd6 5.Nf3 Nf6 *","weight":34069,"prior_delay":25,"prior_completion_time":0},
{"oid":424,"gid":72,"fullname":"French Defense, Exchange Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.Bd3 Nc6 *","weight":34039,"prior_delay":25,"prior_completion_time":0},
{"oid":425,"gid":72,"fullname":"French Defense, Exchange Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.Bf4 *","weight":33985,"prior_delay":25,"prior_completion_time":0},
{"oid":426,"gid":72,"fullname":"French Defense, Exchange Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.Nc3 Nf6 5.Nf3 *","weight":33981,"prior_delay":25,"prior_completion_time":0},
{"oid":427,"gid":72,"fullname":"French Defense, Exchange Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.Nf3 Nf6 5.Bd3 *","weight":34531,"prior_delay":25,"prior_completion_time":0},
{"oid":428,"gid":72,"fullname":"French Defense, Exchange Variation","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.Nf3 Bd6 5.c4 *","weight":34367,"prior_delay":25,"prior_completion_time":0},
{"oid":429,"gid":73,"fullname":"French Defense, King's Indian Attack","main_line":"1.e4 e6 2.d3 d5 3.Nd2 Nf6 4.Ngf3 b6 *","weight":16105,"prior_delay":25,"prior_completion_time":0},
{"oid":430,"gid":73,"fullname":"French Defense, King's Indian Attack","main_line":"1.e4 e6 2.d3 d5 3.Nd2 c5 *","weight":15963,"prior_delay":25,"prior_completion_time":0},
{"oid":431,"gid":73,"fullname":"French Defense, King's Indian Attack","main_line":"1.e4 e6 2.d3 d5 3.Qe2 Nf6 *","weight":15844,"prior_delay":25,"prior_completion_time":0},
{"oid":432,"gid":73,"fullname":"French Defense, King's Indian Attack","main_line":"1.e4 e6 2.d3 d5 3.Nd2 Nf6 4.Ngf3 Nc6 5.c3 *","weight":16122,"prior_delay":25,"prior_completion_time":0},
{"oid":433,"gid":74,"fullname":"French Defense, MacCutcheon Variation, Bernstein Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Bb4 5.e5 h6 6.Bh4 *","weight":42927,"prior_delay":25,"prior_completion_time":0},
{"oid":434,"gid":74,"fullname":"French Defense, MacCutcheon Variation, Dr. Olland (Dutch) Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Bb4 5.e5 h6 6.Bc1 *","weight":42943,"prior_delay":25,"prior_completion_time":0},
{"oid":435,"gid":74,"fullname":"French Defense, MacCutcheon Variation, Duras Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Bb4 5.e5 h6 6.Bd2 Bxc3 7.bxc3 Ne4  8.Qg4 Kf8 9.Bc1 *","weight":43547,"prior_delay":25,"prior_completion_time":0},
{"oid":436,"gid":74,"fullname":"French Defense, MacCutcheon Variation, Janowski Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Bb4 5.e5 h6 6.Be3 *","weight":42986,"prior_delay":25,"prior_completion_time":0},
{"oid":437,"gid":74,"fullname":"French Defense, MacCutcheon Variation, Lasker Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Bb4 5.e5 h6 6.Bd2 Bxc3 7.bxc3 Ne4  8.Qg4 g6 9.Bd3 Nxd2 10.Kxd2 c5 11.Nf3 Nc6 *","weight":43715,"prior_delay":25,"prior_completion_time":0},
{"oid":438,"gid":74,"fullname":"French Defense, MacCutcheon Variation, Lasker Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.Bg5 Bb4 5.e5 h6 6.Bd2 Bxc3 7.bxc3 Ne4  8.Qg4 Kf8 9.Bd3 *","weight":43592,"prior_delay":25,"prior_completion_time":0},
{"oid":439,"gid":75,"fullname":"French Defense, Paulsen Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Be7 *","weight":38984,"prior_delay":25,"prior_completion_time":0},
{"oid":440,"gid":75,"fullname":"French Defense, Paulsen Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nc6 4.Nf3 Nf6 *","weight":39145,"prior_delay":25,"prior_completion_time":0},
{"oid":441,"gid":76,"fullname":"French Defense, Rubinstein Variation, Blackburne Defense","main_line":"1.e4 e6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nd7 5.Nf3 Be7 *","weight":41799,"prior_delay":25,"prior_completion_time":0},
{"oid":442,"gid":76,"fullname":"French Defense, Rubinstein Variation, Blackburne Defense","main_line":"1.e4 e6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nd7 5.Nf3 Ngf6 6.Bd3 *","weight":42467,"prior_delay":25,"prior_completion_time":0},
{"oid":443,"gid":76,"fullname":"French Defense, Rubinstein Variation, Blackburne Defense","main_line":"1.e4 e6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nd7 5.Nf3 Ngf6 6.Nxf6+ Nxf6 7.Bg5 *","weight":43120,"prior_delay":25,"prior_completion_time":0},
{"oid":444,"gid":76,"fullname":"French Defense, Rubinstein Variation, Blackburne Defense","main_line":"1.e4 e6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nd7 5.Nf3 Ngf6 6.Nxf6+ Nxf6 7.Bd3 c5  8.dxc5 *","weight":43237,"prior_delay":25,"prior_completion_time":0},
{"oid":445,"gid":76,"fullname":"French Defense, Rubinstein Variation, Capablanca Line","main_line":"1.e4 e6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nd7 5.Nf3 Ngf6 6.Nxf6+ Nxf6 7.Ne5 *","weight":43050,"prior_delay":25,"prior_completion_time":0},
{"oid":446,"gid":76,"fullname":"French Defense, Rubinstein Variation, Fort Knox Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Bd7 5.Nf3 Bc6 6.Bd3 Nd7 7.O-O Ngf6 8.Ng3 *","weight":42072,"prior_delay":25,"prior_completion_time":0},
{"oid":447,"gid":76,"fullname":"French Defense, Rubinstein Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 dxe4 4.Nxe4 Nf6 *","weight":40418,"prior_delay":25,"prior_completion_time":0},
{"oid":448,"gid":77,"fullname":"French Defense, Steinitz Variation, Boleslavsky Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.e5 Nfd7 5.f4 c5 6.Nf3 Nc6 7.Be3 a6 8.Qd2 b5 *","weight":49099,"prior_delay":25,"prior_completion_time":0},
{"oid":449,"gid":77,"fullname":"French Defense, Steinitz Variation, Boleslavsky Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.e5 Nfd7 5.f4 c5 6.Nf3 Nc6 7.Be3 cxd4 8.Nxd4 Bc5 *","weight":49205,"prior_delay":25,"prior_completion_time":0},
{"oid":450,"gid":77,"fullname":"French Defense, Steinitz Variation, Boleslavsky Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.e5 Nfd7 5.f4 c5 6.Nf3 Nc6 7.Be3 cxd4 8.Nxd4 Qb6 *","weight":49065,"prior_delay":25,"prior_completion_time":0},
{"oid":451,"gid":77,"fullname":"French Defense, Steinitz Variation, Gledhill Attack","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Nf6 4.e5 Nfd7 5.Qg4 *","weight":43321,"prior_delay":25,"prior_completion_time":0},
{"oid":452,"gid":78,"fullname":"French Defense, Tarrasch, Eliskases Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 Qxd5 5.Ngf3 cxd4 6.Bc4 Qd8 *","weight":40647,"prior_delay":25,"prior_completion_time":0},
{"oid":453,"gid":78,"fullname":"French Defense, Tarrasch, Open","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Bb5+ Bd7 6.Qe2+ Be7 *","weight":39477,"prior_delay":25,"prior_completion_time":0},
{"oid":454,"gid":78,"fullname":"French Defense, Tarrasch, Open","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Bb5+ Nc6 6.Qe2+ *","weight":39405,"prior_delay":25,"prior_completion_time":0},
{"oid":455,"gid":78,"fullname":"French Defense, Tarrasch, Open","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Ngf3 Nf6 6.Bb5+ Bd7 7.Bxd7+ Nbd7  8.O-O Be7 9.dxc5 Nxc5 10.Nb3 *","weight":40900,"prior_delay":25,"prior_completion_time":0},
{"oid":456,"gid":78,"fullname":"French Defense, Tarrasch, Open","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Ngf3 a6 6.Be2 *","weight":39639,"prior_delay":25,"prior_completion_time":0},
{"oid":457,"gid":79,"fullname":"French Defense, Tarrasch Variation, Botvinnik Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 b6 *","weight":38859,"prior_delay":25,"prior_completion_time":0},
{"oid":458,"gid":79,"fullname":"French Defense, Tarrasch Variation, Chistyakov Defense, Modern Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 Qxd5 5.Ngf3 cxd4 6.Bc4 Qd6 7.O-O Nf6  8.Nb3 Nc6 9.Nbxd4 Nxd4 10.Nxd4 a6 11.c3 *","weight":43548,"prior_delay":25,"prior_completion_time":0},
{"oid":459,"gid":79,"fullname":"French Defense, Tarrasch Variation, Chistyakov Defense, Modern Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 Qxd5 5.Ngf3 cxd4 6.Bc4 Qd6 7.O-O Nf6  8.Nb3 Nc6 9.Nbxd4 Nxd4 10.Nxd4 a6 11.Re1 *","weight":43650,"prior_delay":25,"prior_completion_time":0},
{"oid":460,"gid":79,"fullname":"French Defense, Tarrasch Variation, Chistyakov Defense","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 Qxd5 5.Ngf3 cxd4 6.Bc4 Qd6 7.O-O Nf6  8.Nb3 Nc6 9.Nbxd4 Nxd4 10.Qxd4 Qxd4 *","weight":43079,"prior_delay":25,"prior_completion_time":0},
{"oid":461,"gid":79,"fullname":"French Defense, Tarrasch Variation, Closed Variation, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ne2 cxd4  8.cxd4 Qb6 9.Nf3 f6 *","weight":39915,"prior_delay":25,"prior_completion_time":0},
{"oid":462,"gid":79,"fullname":"French Defense, Tarrasch Variation, Closed Variation, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ne2 cxd4  8.cxd4 f6 9.Nf4 *","weight":40095,"prior_delay":25,"prior_completion_time":0},
{"oid":463,"gid":79,"fullname":"French Defense, Tarrasch Variation, Closed Variation, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ne2 cxd4  8.cxd4 f6 9.exf6 Nxf6 10.O-O Bd6 11.Nf3 Qb6 12.Nc3 *","weight":40746,"prior_delay":25,"prior_completion_time":0},
{"oid":464,"gid":79,"fullname":"French Defense, Tarrasch Variation, Closed Variation, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ne2 cxd4  8.cxd4 f6 9.exf6 Nxf6 10.O-O Bd6 11.Nf3 O-O 12.Bf4 *","weight":40873,"prior_delay":25,"prior_completion_time":0},
{"oid":465,"gid":79,"fullname":"French Defense, Tarrasch Variation, Closed Variation, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ne2 cxd4  8.cxd4 f6 9.exf6 Nxf6 10.O-O Bd6 11.Nf3 Qc7 12.Bg5 *","weight":40856,"prior_delay":25,"prior_completion_time":0},
{"oid":466,"gid":79,"fullname":"French Defense, Tarrasch Variation, Closed Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ne2 a5 *","weight":39429,"prior_delay":25,"prior_completion_time":0},
{"oid":467,"gid":79,"fullname":"French Defense, Tarrasch Variation, Closed Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ngf3 Be7 8.O-O g5 *","weight":39356,"prior_delay":25,"prior_completion_time":0},
{"oid":468,"gid":79,"fullname":"French Defense, Tarrasch Variation, Closed Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ndf3 Qa5 *","weight":39119,"prior_delay":25,"prior_completion_time":0},
{"oid":469,"gid":79,"fullname":"French Defense, Tarrasch Variation, Closed Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ngf3 Qb6 8.O-O *","weight":39223,"prior_delay":25,"prior_completion_time":0},
{"oid":470,"gid":79,"fullname":"French Defense, Tarrasch Variation, Closed Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.c3 *","weight":38248,"prior_delay":25,"prior_completion_time":0},
{"oid":471,"gid":79,"fullname":"French Defense, Tarrasch Variation, Guimard Defense, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nc6 4.Ngf3 Nf6 5.e5 Nd7 6.Nb3 Be7 *","weight":37303,"prior_delay":25,"prior_completion_time":0},
{"oid":472,"gid":79,"fullname":"French Defense, Tarrasch Variation, Guimard Defense, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nc6 4.Ngf3 Nf6 5.e5 Nd7 6.Nb3 a5 *","weight":37304,"prior_delay":25,"prior_completion_time":0},
{"oid":473,"gid":79,"fullname":"French Defense, Tarrasch Variation, Guimard Defense, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nc6 4.Ngf3 Nf6 5.e5 Nd7 6.Be2 f6 *","weight":37308,"prior_delay":25,"prior_completion_time":0},
{"oid":474,"gid":79,"fullname":"French Defense, Tarrasch Variation, Guimard Defense, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nc6 4.Ngf3 Nf6 5.e5 Nd7 6.Bb5 *","weight":37268,"prior_delay":25,"prior_completion_time":0},
{"oid":475,"gid":79,"fullname":"French Defense, Tarrasch Variation, Guimard Defense, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nc6 4.Ngf3 Nf6 5.e5 Nd7 6.c3 *","weight":37276,"prior_delay":25,"prior_completion_time":0},
{"oid":476,"gid":79,"fullname":"French Defense, Tarrasch Variation, Guimard Defense","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nc6 4.c3 e5 *","weight":36851,"prior_delay":25,"prior_completion_time":0},
{"oid":477,"gid":79,"fullname":"French Defense, Tarrasch Variation, Haberditz Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 f5 *","weight":36737,"prior_delay":25,"prior_completion_time":0},
{"oid":478,"gid":79,"fullname":"French Defense, Tarrasch Variation, Leningrad Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ne2 cxd4  8.cxd4 Nb6 9.O-O Bd7 *","weight":39905,"prior_delay":25,"prior_completion_time":0},
{"oid":479,"gid":79,"fullname":"French Defense, Tarrasch Variation, Leningrad Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ne2 cxd4  8.cxd4 Nb6 9.O-O a5 *","weight":39904,"prior_delay":25,"prior_completion_time":0},
{"oid":480,"gid":79,"fullname":"French Defense, Tarrasch Variation, Leningrad Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.Bd3 c5 6.c3 Nc6 7.Ne2 cxd4  8.cxd4 Nb6 9.Nf3 *","weight":39898,"prior_delay":25,"prior_completion_time":0},
{"oid":481,"gid":79,"fullname":"French Defense, Tarrasch Variation, Modern System","main_line":"1.e4 e6 2.d4 d5 3.Nd2 a6 4.Ngf3 *","weight":37068,"prior_delay":25,"prior_completion_time":0},
{"oid":482,"gid":79,"fullname":"French Defense, Tarrasch Variation, Morozevich Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Be7 4.Bd3 c5 5.dxc5 Nf6 *","weight":37458,"prior_delay":25,"prior_completion_time":0},
{"oid":483,"gid":79,"fullname":"French Defense, Tarrasch Variation, Morozevich Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Be7 4.Ngf3 Nf6 5.Bd3 *","weight":37376,"prior_delay":25,"prior_completion_time":0},
{"oid":484,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Euwe-Keres Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.Ngf3 Nc6 *","weight":39195,"prior_delay":25,"prior_completion_time":0},
{"oid":485,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Euwe-Keres Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.Ngf3 a6 *","weight":39079,"prior_delay":25,"prior_completion_time":0},
{"oid":486,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Euwe-Keres Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.Ngf3 Nf6 *","weight":39209,"prior_delay":25,"prior_completion_time":0},
{"oid":487,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Euwe-Keres Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.Ngf3 cxd4 *","weight":39436,"prior_delay":25,"prior_completion_time":0},
{"oid":488,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Ngf3 Nc6 6.Bb5 Bd6 7.O-O Ne7  8.dxc5 Bxc5 9.Nb3 Bb6 *","weight":40388,"prior_delay":25,"prior_completion_time":0},
{"oid":489,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Ngf3 Nc6 6.Bb5 cxd4 *","weight":39980,"prior_delay":25,"prior_completion_time":0},
{"oid":490,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Ngf3 Nc6 6.Bb5 Bd6 7.dxc5 Bxc5  8.O-O Ne7 *","weight":40719,"prior_delay":25,"prior_completion_time":0},
{"oid":491,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Ngf3 Nc6 6.Bb5 Bd6 7.O-O Ne7  8.dxc5 Bxc5 9.c3 O-O 10.Nb3 Bd6 *","weight":40334,"prior_delay":25,"prior_completion_time":0},
{"oid":492,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Ngf3 Nc6 6.Bb5 Bd6 7.O-O Ne7  8.dxc5 Bxc5 9.Nb3 Bd6 10.Re1 O-O 11.Bg5 Bg4 *","weight":40702,"prior_delay":25,"prior_completion_time":0},
{"oid":493,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Ngf3 Nc6 6.Bb5 Bd6 7.O-O Ne7  8.dxc5 Bxc5 9.Nb3 Bd6 10.Bg5 *","weight":40518,"prior_delay":25,"prior_completion_time":0},
{"oid":494,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Ngf3 Nc6 6.Bb5 Bd6 7.O-O Ne7  8.dxc5 Bxc5 9.Nb3 Bd6 10.Nbd4 *","weight":40471,"prior_delay":25,"prior_completion_time":0},
{"oid":495,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.exd5 exd5 5.Ngf3 Nc6 6.Bb5 Bd6 7.O-O Ne7  8.dxc5 Bxc5 9.c3 O-O 10.Nb3 Bb6 11.Re1 *","weight":40359,"prior_delay":25,"prior_completion_time":0},
{"oid":496,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System, S\u00c3\u00bcchting Line","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.c3 *","weight":38158,"prior_delay":25,"prior_completion_time":0},
{"oid":497,"gid":79,"fullname":"French Defense, Tarrasch Variation, Open System","main_line":"1.e4 e6 2.d4 d5 3.Nd2 c5 4.dxc5 *","weight":38157,"prior_delay":25,"prior_completion_time":0},
{"oid":498,"gid":79,"fullname":"French Defense, Tarrasch Variation, Pawn Center Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.f4 c5 6.c3 Nc6 7.Ndf3 cxd4 *","weight":38362,"prior_delay":25,"prior_completion_time":0},
{"oid":499,"gid":79,"fullname":"French Defense, Tarrasch Variation, Pawn Center Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.f4 c5 6.c3 Nc6 7.Ndf3 Qb6 8.g3 cxd4  9.cxd4 *","weight":38401,"prior_delay":25,"prior_completion_time":0},
{"oid":500,"gid":79,"fullname":"French Defense, Tarrasch Variation, Pawn Center Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.f4 c5 6.c3 Nc6 7.Ndf3 Qb6 8.h4 cxd4  9.cxd4 *","weight":38408,"prior_delay":25,"prior_completion_time":0},
{"oid":501,"gid":79,"fullname":"French Defense, Tarrasch Variation, Pawn Center Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 Nf6 4.e5 Nfd7 5.f4 c5 6.c3 Nc6 7.Ndf3 Qb6 8.Ne2 cxd4  9.cxd4 *","weight":38394,"prior_delay":25,"prior_completion_time":0},
{"oid":502,"gid":79,"fullname":"French Defense, Tarrasch Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 b6 *","weight":36752,"prior_delay":25,"prior_completion_time":0},
{"oid":503,"gid":79,"fullname":"French Defense, Tarrasch Variation","main_line":"1.e4 e6 2.d4 d5 3.Nd2 dxe4 *","weight":36990,"prior_delay":25,"prior_completion_time":0},
{"oid":504,"gid":80,"fullname":"French Defense, Winawer Variation, Advance Variation, Moscow Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.Qg4 Ne7 6.dxc5 *","weight":43069,"prior_delay":25,"prior_completion_time":0},
{"oid":505,"gid":80,"fullname":"French Defense, Winawer Variation, Advance Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 b6 5.a3 Bf8 *","weight":42424,"prior_delay":25,"prior_completion_time":0},
{"oid":506,"gid":80,"fullname":"French Defense, Winawer Variation, Advance Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Qa5 *","weight":45706,"prior_delay":25,"prior_completion_time":0},
{"oid":507,"gid":80,"fullname":"French Defense, Winawer Variation, Advance Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 Ne7 5.Bd2 b6 *","weight":42593,"prior_delay":25,"prior_completion_time":0},
{"oid":508,"gid":80,"fullname":"French Defense, Winawer Variation, Advance Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.a4 Qa5 *","weight":46029,"prior_delay":25,"prior_completion_time":0},
{"oid":509,"gid":80,"fullname":"French Defense, Winawer Variation, Advance Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.dxc5 *","weight":43047,"prior_delay":25,"prior_completion_time":0},
{"oid":510,"gid":80,"fullname":"French Defense, Winawer Variation, Advance Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.h4 Nbc6 8.Nf3 *","weight":46090,"prior_delay":25,"prior_completion_time":0},
{"oid":511,"gid":80,"fullname":"French Defense, Winawer Variation, Advance Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.h4 Qc7 8.Nf3 *","weight":46122,"prior_delay":25,"prior_completion_time":0},
{"oid":512,"gid":80,"fullname":"French Defense, Winawer Variation, Advance Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 b6 5.Qg4 Bf8 6.Bg5 *","weight":42412,"prior_delay":25,"prior_completion_time":0},
{"oid":513,"gid":80,"fullname":"French Defense, Winawer Variation, Advance Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 Ne7 5.a3 Bxc3+ 6.bxc3 b6 7.Qg4 *","weight":43754,"prior_delay":25,"prior_completion_time":0},
{"oid":514,"gid":80,"fullname":"French Defense, Winawer Variation, Alekhine Gambit, Kan Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.Ne2 dxe4 5.a3 Bxc3+ 6.Nxc3 Nc6 7.Bb5 *","weight":40745,"prior_delay":25,"prior_completion_time":0},
{"oid":515,"gid":80,"fullname":"French Defense, Winawer Variation, Alekhine-Maroczy Gambit","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.Ne2 Nf6 *","weight":40589,"prior_delay":25,"prior_completion_time":0},
{"oid":516,"gid":80,"fullname":"French Defense, Winawer Variation, Alekhine-Maroczy Gambit","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.Ne2 Nc6 *","weight":40597,"prior_delay":25,"prior_completion_time":0},
{"oid":517,"gid":80,"fullname":"French Defense, Winawer Variation, Alekhine-Maroczy Gambit","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.Ne2 dxe4 5.a3 Be7 6.Nxe4 Nf6 7.Qd3 *","weight":40813,"prior_delay":25,"prior_completion_time":0},
{"oid":518,"gid":80,"fullname":"French Defense, Winawer Variation, Bogoljubov Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.Bd2 cxd4 *","weight":43079,"prior_delay":25,"prior_completion_time":0},
{"oid":519,"gid":80,"fullname":"French Defense, Winawer Variation, Bogoljubov Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.Bd2 Ne7 6.dxc5 *","weight":43107,"prior_delay":25,"prior_completion_time":0},
{"oid":520,"gid":80,"fullname":"French Defense, Winawer Variation, Bogoljubov Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.Bd2 Ne7 6.Nb5 *","weight":43120,"prior_delay":25,"prior_completion_time":0},
{"oid":521,"gid":80,"fullname":"French Defense, Winawer Variation, Bogoljubov Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.Bd2 Ne7 6.a3 *","weight":43126,"prior_delay":25,"prior_completion_time":0},
{"oid":522,"gid":80,"fullname":"French Defense, Winawer Variation, Classical Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Qc7 7.Qg4 f6 *","weight":45748,"prior_delay":25,"prior_completion_time":0},
{"oid":523,"gid":80,"fullname":"French Defense, Winawer Variation, Classical Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Qc7 7.Qg4 f5 *","weight":45769,"prior_delay":25,"prior_completion_time":0},
{"oid":524,"gid":80,"fullname":"French Defense, Winawer Variation, Exchange Variation, Canal Attack","main_line":"1.e4 e6 2.d4 d5 3.exd5 exd5 4.Nc3 Bb4 5.Bd3 Ne7 6.Qh5 *","weight":34187,"prior_delay":25,"prior_completion_time":0},
{"oid":525,"gid":80,"fullname":"French Defense, Winawer Variation, Fingerslip Variation, Kunin Double Gambit","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.Bd2 dxe4 5.Qg4 Qxd4 *","weight":40513,"prior_delay":25,"prior_completion_time":0},
{"oid":526,"gid":80,"fullname":"French Defense, Winawer Variation, Fingerslip Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.Bd2 dxe4 5.Qg4 Nf6 *","weight":40514,"prior_delay":25,"prior_completion_time":0},
{"oid":527,"gid":80,"fullname":"French Defense, Winawer Variation, Kondratiyev Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.Bd3 c5 5.exd5 Qxd5 6.Bd2 *","weight":40519,"prior_delay":25,"prior_completion_time":0},
{"oid":528,"gid":80,"fullname":"French Defense, Winawer Variation, Petrosian Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 Qd7 5.Bd2 *","weight":42292,"prior_delay":25,"prior_completion_time":0},
{"oid":529,"gid":80,"fullname":"French Defense, Winawer Variation, Petrosian Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 Qd7 5.a3 Bxc3+ 6.bxc3 b6 7.Qg4 *","weight":42381,"prior_delay":25,"prior_completion_time":0},
{"oid":530,"gid":80,"fullname":"French Defense, Winawer Variation, Poisoned Pawn Variation, Main Line","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Qg4 Qc7  8.Qxg7 Rg8 9.Qxh7 cxd4 10.Ne2 Nbc6 11.f4 Bd7 12.Qd3 dxc3 13.Nxc3 *","weight":47286,"prior_delay":25,"prior_completion_time":0},
{"oid":531,"gid":80,"fullname":"French Defense, Winawer Variation, Poisoned Pawn Variation, Paoli Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Qg4 Qc7  8.Qxg7 Rg8 9.Qxh7 cxd4 10.Kd1 *","weight":46841,"prior_delay":25,"prior_completion_time":0},
{"oid":532,"gid":80,"fullname":"French Defense, Winawer Variation, Poisoned Pawn Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Qg4 O-O  8.Bd3 Nbc6 *","weight":47019,"prior_delay":25,"prior_completion_time":0},
{"oid":533,"gid":80,"fullname":"French Defense, Winawer Variation, Poisoned Pawn Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Qg4 O-O  8.Nf3 Nbc6 *","weight":46666,"prior_delay":25,"prior_completion_time":0},
{"oid":534,"gid":80,"fullname":"French Defense, Winawer Variation, Poisoned Pawn Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Qg4 Kf8 *","weight":46530,"prior_delay":25,"prior_completion_time":0},
{"oid":535,"gid":80,"fullname":"French Defense, Winawer Variation, Poisoned Pawn Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Qg4 cxd4 *","weight":46523,"prior_delay":25,"prior_completion_time":0},
{"oid":536,"gid":80,"fullname":"French Defense, Winawer Variation, Positional Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Nf3 Nbc6 8.a4 Bd7 *","weight":46165,"prior_delay":25,"prior_completion_time":0},
{"oid":537,"gid":80,"fullname":"French Defense, Winawer Variation, Positional Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Nf3 Qc7 8.a4 b6 *","weight":46199,"prior_delay":25,"prior_completion_time":0},
{"oid":538,"gid":80,"fullname":"French Defense, Winawer Variation, Positional Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Nf3 Qa5 *","weight":46157,"prior_delay":25,"prior_completion_time":0},
{"oid":539,"gid":80,"fullname":"French Defense, Winawer Variation, Positional Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Nf3 Nbc6  8.a4 Qa5 9.Qd2 Bd7 *","weight":46198,"prior_delay":25,"prior_completion_time":0},
{"oid":540,"gid":80,"fullname":"French Defense, Winawer Variation, Positional Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Nf3 b6 8.a4 *","weight":46198,"prior_delay":25,"prior_completion_time":0},
{"oid":541,"gid":80,"fullname":"French Defense, Winawer Variation, Positional Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Nf3 b6 8.Bb5+ *","weight":46221,"prior_delay":25,"prior_completion_time":0},
{"oid":542,"gid":80,"fullname":"French Defense, Winawer Variation, Positional Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Nf3 Nbc6  8.a4 Qa5 9.Bd2 Bd7 10.Bb5 *","weight":46257,"prior_delay":25,"prior_completion_time":0},
{"oid":543,"gid":80,"fullname":"French Defense, Winawer Variation, Positional Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Bxc3+ 6.bxc3 Ne7 7.Nf3 Bd7 8.a4 *","weight":46177,"prior_delay":25,"prior_completion_time":0},
{"oid":544,"gid":80,"fullname":"French Defense, Winawer Variation, Retreat Variation, Armenian Line","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Ba5 6.b4 cxd4 7.Nb5 Bc7 8.f4 Bd7 *","weight":44606,"prior_delay":25,"prior_completion_time":0},
{"oid":545,"gid":80,"fullname":"French Defense, Winawer Variation, Retreat Variation, Armenian Line","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.e5 c5 5.a3 Ba5 6.b4 cxd4 7.Qg4 Ne7 8.bxa5 *","weight":44525,"prior_delay":25,"prior_completion_time":0},
{"oid":546,"gid":80,"fullname":"French Defense, Winawer Variation, Winckelmann-Riemer Gambit","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 dxe4 6.Qg4 *","weight":40688,"prior_delay":25,"prior_completion_time":0},
{"oid":547,"gid":80,"fullname":"French Defense, Winawer Variation, Winckelmann-Riemer Gambit","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 dxe4 6.f3 c5 *","weight":40654,"prior_delay":25,"prior_completion_time":0},
{"oid":548,"gid":80,"fullname":"French Defense, Winawer Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.Qg4 *","weight":40501,"prior_delay":25,"prior_completion_time":0},
{"oid":549,"gid":80,"fullname":"French Defense, Winawer Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.Bd3 dxe4 5.Bxe4 Nf6 *","weight":40541,"prior_delay":25,"prior_completion_time":0},
{"oid":550,"gid":80,"fullname":"French Defense, Winawer Variation","main_line":"1.e4 e6 2.d4 d5 3.Nc3 Bb4 4.Qd3 dxe4 *","weight":40504,"prior_delay":25,"prior_completion_time":0},
{"oid":551,"gid":81,"fullname":"French Defense, Wing Gambit","main_line":"1.e4 e6 2.Nf3 d5 3.e5 c5 4.b4 *","weight":15261,"prior_delay":25,"prior_completion_time":0},
{"oid":552,"gid":82,"fullname":"Giuoco Piano, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.O-O Nf6 5.Nc3 *","weight":54070,"prior_delay":25,"prior_completion_time":0},
{"oid":553,"gid":83,"fullname":"Italian Game, Classical Variation, Giuoco Pianissimo","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.c3 Nf6 5.d3 d6 6.O-O O-O *","weight":56421,"prior_delay":25,"prior_completion_time":0},
{"oid":554,"gid":83,"fullname":"Italian Game, Classical Variation, Giuoco Pianissimo","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.c3 Nf6 5.d3 a6 *","weight":56219,"prior_delay":25,"prior_completion_time":0},
{"oid":555,"gid":83,"fullname":"Italian Game, Classical Variation, Giuoco Pianissimo","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.c3 Nf6 5.d3 d6 6.Nbd2 *","weight":56237,"prior_delay":25,"prior_completion_time":0},
{"oid":556,"gid":83,"fullname":"Italian Game, Classical Variation, Greco Gambit, Anderssen Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.c3 Nf6 5.d4 exd4 6.e5 d5 7.Bb5 Ne4  8.cxd4 Bb4+ *","weight":55815,"prior_delay":25,"prior_completion_time":0},
{"oid":557,"gid":83,"fullname":"Italian Game, Classical Variation, La Bourdonnais Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.c3 d6 5.d4 exd4 6.cxd4 Bb6 *","weight":54184,"prior_delay":25,"prior_completion_time":0},
{"oid":558,"gid":84,"fullname":"Italian Game, Evans Gambit, Anderssen Variation, Cordel Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.b4 Bxb4 5.c3 Be7 6.d4 Na5 *","weight":53548,"prior_delay":25,"prior_completion_time":0},
{"oid":559,"gid":84,"fullname":"Italian Game, Evans Gambit, Anderssen Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.b4 Bxb4 5.c3 Be7 6.Qb3 *","weight":53457,"prior_delay":25,"prior_completion_time":0},
{"oid":560,"gid":84,"fullname":"Italian Game, Evans Gambit, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.b4 Bxb4 5.c3 Ba5 6.Qb3 *","weight":53470,"prior_delay":25,"prior_completion_time":0},
{"oid":561,"gid":84,"fullname":"Italian Game, Evans Gambit, Stone-Ware Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.b4 Bxb4 5.c3 Bd6 *","weight":53427,"prior_delay":25,"prior_completion_time":0},
{"oid":562,"gid":84,"fullname":"Italian Game, Evans Gambit, Tartakower Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.b4 Bxb4 5.c3 Ba5 6.d4 d6 7.Qb3 Qd7  8.dxe5 Bb6 *","weight":53593,"prior_delay":25,"prior_completion_time":0},
{"oid":563,"gid":85,"fullname":"Italian Game, Evans Gambit Declined, Showalter Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.b4 Bb6 5.a4 a6 6.Nc3 *","weight":53275,"prior_delay":25,"prior_completion_time":0},
{"oid":564,"gid":86,"fullname":"Italian Game, Giuoco Pianissimo, Canal Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.d3 Nf6 5.Nc3 d6 6.Bg5 h6 *","weight":53640,"prior_delay":25,"prior_completion_time":0},
{"oid":565,"gid":86,"fullname":"Italian Game, Giuoco Pianissimo, Italian Four Knights Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.d3 Nf6 5.Nc3 d6 6.Be3 *","weight":53553,"prior_delay":25,"prior_completion_time":0},
{"oid":566,"gid":86,"fullname":"Italian Game, Giuoco Pianissimo, Normal","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.d3 Nf6 5.O-O d6 *","weight":53919,"prior_delay":25,"prior_completion_time":0},
{"oid":567,"gid":87,"fullname":"Italian Game, Giuoco Piano, Mestel Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Bc5 4.c3 Qe7 5.d4 Bb6 6.Bg5 *","weight":54168,"prior_delay":25,"prior_completion_time":0},
{"oid":568,"gid":88,"fullname":"Italian Game, Hungarian Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Be7 4.d4 d6 *","weight":51398,"prior_delay":25,"prior_completion_time":0},
{"oid":569,"gid":90,"fullname":"Italian Game, Schilling-Kostic Gambit","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nd4 4.Bc4 *","weight":65083,"prior_delay":25,"prior_completion_time":0},
{"oid":570,"gid":91,"fullname":"Italian Game, Scotch Gambit, Anderssen Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.d4 exd4 5.O-O Nxe4 6.Re1 d5 7.Bxd5 Qxd5  8.Nc3 Qa5 9.Nxe4 Be6 10.Neg5 O-O-O 11.Nxe6 fxe6 12.Rxe6 Bd6 *","weight":52348,"prior_delay":25,"prior_completion_time":0},
{"oid":571,"gid":91,"fullname":"Italian Game, Scotch Gambit, Anderssen Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.d4 exd4 5.O-O Nxe4 6.Re1 d5 7.Bxd5 Qxd5  8.Nc3 Qa5 9.Nxe4 Be6 10.Bd2 Qf5 *","weight":52338,"prior_delay":25,"prior_completion_time":0},
{"oid":572,"gid":91,"fullname":"Italian Game, Scotch Gambit, Anderssen Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.d4 exd4 5.O-O Nxe4 6.Re1 d5 7.Bxd5 Qxd5  8.Nc3 Qh5 9.Nxe4 Be6 10.Bg5 Bd6 *","weight":52359,"prior_delay":25,"prior_completion_time":0},
{"oid":573,"gid":91,"fullname":"Italian Game, Scotch Gambit, Janowski Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.d4 exd4 5.O-O d6 *","weight":52243,"prior_delay":25,"prior_completion_time":0},
{"oid":574,"gid":92,"fullname":"Italian Game, Two Knights Defense, Colman Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.Ng5 d5 5.exd5 Na5 6.Bb5+ c6 7.dxc6 bxc6  8.Qf3 Rb8 *","weight":53319,"prior_delay":25,"prior_completion_time":0},
{"oid":575,"gid":92,"fullname":"Italian Game, Two Knights Defense, Fritz Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.Ng5 d5 5.exd5 Nd4 6.c3 b5 7.Bf1 Nxd5 8.cxd4 *","weight":52684,"prior_delay":25,"prior_completion_time":0},
{"oid":576,"gid":92,"fullname":"Italian Game, Two Knights Defense, Modern Bishop's Opening","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.d3 h6 *","weight":52939,"prior_delay":25,"prior_completion_time":0},
{"oid":577,"gid":92,"fullname":"Italian Game, Two Knights Defense, Modern Bishop's Opening","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.d3 Be7 5.Bb3 O-O *","weight":53252,"prior_delay":25,"prior_completion_time":0},
{"oid":578,"gid":92,"fullname":"Italian Game, Two Knights Defense, Modern Bishop's Opening","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.d3 Be7 5.c3 *","weight":53230,"prior_delay":25,"prior_completion_time":0},
{"oid":579,"gid":92,"fullname":"Italian Game, Two Knights Defense, Modern Bishop's Opening","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.d3 Be7 5.O-O O-O 6.Re1 *","weight":54131,"prior_delay":25,"prior_completion_time":0},
{"oid":580,"gid":92,"fullname":"Italian Game, Two Knights Defense, Modern Bishop's Opening","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.d3 Be7 5.O-O O-O 6.Bb3 d6 7.c3 *","weight":54117,"prior_delay":25,"prior_completion_time":0},
{"oid":581,"gid":92,"fullname":"Italian Game, Two Knights Defense, Paoli Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.Ng5 d5 5.exd5 Na5 6.Bb5+ c6 7.dxc6 bxc6  8.Qf3 Qc7 9.Bd3 *","weight":53316,"prior_delay":25,"prior_completion_time":0},
{"oid":582,"gid":92,"fullname":"Italian Game, Two Knights Defense, Perreux Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.d4 exd4 5.Ng5 *","weight":52230,"prior_delay":25,"prior_completion_time":0},
{"oid":583,"gid":92,"fullname":"Italian Game, Two Knights Defense, Polerio Defense, Bishop Check Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.Ng5 d5 5.exd5 Na5 6.Bb5+ Bd7 *","weight":52959,"prior_delay":25,"prior_completion_time":0},
{"oid":584,"gid":92,"fullname":"Italian Game, Two Knights Defense, Polerio Defense, G\u00c3\u00b6ring Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.Ng5 d5 5.exd5 Na5 6.Bb5+ c6 7.dxc6 bxc6  8.Be2 h6 9.Nf3 e4 10.Ne5 Qc7 *","weight":53480,"prior_delay":25,"prior_completion_time":0},
{"oid":585,"gid":92,"fullname":"Italian Game, Two Knights Defense, Polerio Defense, Suhle Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.Ng5 d5 5.exd5 Na5 6.Bb5+ c6 7.dxc6 bxc6  8.Be2 h6 9.Nf3 e4 10.Ne5 Bc5 *","weight":53488,"prior_delay":25,"prior_completion_time":0},
{"oid":586,"gid":92,"fullname":"Italian Game, Two Knights Defense, Polerio Defense, Suhle Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.Ng5 d5 5.exd5 Na5 6.Bb5+ c6 7.dxc6 bxc6  8.Be2 h6 9.Nf3 e4 10.Ne5 Bd6 11.f4 exf3 *","weight":53493,"prior_delay":25,"prior_completion_time":0},
{"oid":587,"gid":92,"fullname":"Italian Game, Two Knights Defense, Polerio Defense, Suhle Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.Ng5 d5 5.exd5 Na5 6.Bb5+ c6 7.dxc6 bxc6  8.Be2 h6 9.Nf3 e4 10.Ne5 Bd6 11.d4 exd3 12.Nxd3 Qc7 13.b3 *","weight":53541,"prior_delay":25,"prior_completion_time":0},
{"oid":588,"gid":92,"fullname":"Italian Game, Two Knights Defense, Steinitz Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.Ng5 d5 5.exd5 Na5 6.Bb5+ c6 7.dxc6 bxc6  8.Be2 h6 9.Nh3 *","weight":53441,"prior_delay":25,"prior_completion_time":0},
{"oid":589,"gid":92,"fullname":"Italian Game, Two Knights Defense, Ulvestad Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.Ng5 d5 5.exd5 b5 *","weight":52666,"prior_delay":25,"prior_completion_time":0},
{"oid":590,"gid":89,"fullname":"Italian Game, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 d6 *","weight":51372,"prior_delay":25,"prior_completion_time":0},
{"oid":591,"gid":93,"fullname":"King's Gambit, Falkbeer Countergambit, Modern Transfer","main_line":"1.e4 e5 2.f4 d5 3.exd5 exf4 *","weight":20496,"prior_delay":25,"prior_completion_time":0},
{"oid":592,"gid":94,"fullname":"King's Gambit Accepted, Abbazia Defense","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 d5 4.exd5 Nf6 5.Bc4 *","weight":20760,"prior_delay":25,"prior_completion_time":0},
{"oid":593,"gid":94,"fullname":"King's Gambit Accepted, Abbazia Defense","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 d5 4.exd5 Nf6 5.Bb5+ c6 6.dxc6 Nxc6 *","weight":20756,"prior_delay":25,"prior_completion_time":0},
{"oid":594,"gid":95,"fullname":"King's Gambit Accepted, Becker Defense","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 h6 *","weight":20679,"prior_delay":25,"prior_completion_time":0},
{"oid":595,"gid":96,"fullname":"King's Gambit Accepted, Bishop's Gambit, Bledow Countergambit","main_line":"1.e4 e5 2.f4 exf4 3.Bc4 d5 4.Bxd5 Nf6 *","weight":20657,"prior_delay":25,"prior_completion_time":0},
{"oid":596,"gid":96,"fullname":"King's Gambit Accepted, Bishop's Gambit, Bledow Variation","main_line":"1.e4 e5 2.f4 exf4 3.Bc4 d5 4.exd5 *","weight":20650,"prior_delay":25,"prior_completion_time":0},
{"oid":597,"gid":96,"fullname":"King's Gambit Accepted, Bishop's Gambit, Bogoljubov Defense","main_line":"1.e4 e5 2.f4 exf4 3.Bc4 Nf6 4.Nc3 c6 *","weight":20679,"prior_delay":25,"prior_completion_time":0},
{"oid":598,"gid":96,"fullname":"King's Gambit Accepted, Bishop's Gambit, Bryan Countergambit","main_line":"1.e4 e5 2.f4 exf4 3.Bc4 Qh4+ 4.Kf1 b5 *","weight":20656,"prior_delay":25,"prior_completion_time":0},
{"oid":599,"gid":96,"fullname":"King's Gambit Accepted, Bishop's Gambit, Lopez Defense","main_line":"1.e4 e5 2.f4 exf4 3.Bc4 c6 *","weight":20643,"prior_delay":25,"prior_completion_time":0},
{"oid":600,"gid":96,"fullname":"King's Gambit Accepted, Bishop's Gambit, Paulsen Attack","main_line":"1.e4 e5 2.f4 exf4 3.Bc4 Nf6 4.Nc3 Bb4 5.e5 *","weight":20677,"prior_delay":25,"prior_completion_time":0},
{"oid":601,"gid":96,"fullname":"King's Gambit Accepted, Bishop's Gambit, Steinitz Defense","main_line":"1.e4 e5 2.f4 exf4 3.Bc4 Ne7 *","weight":20643,"prior_delay":25,"prior_completion_time":0},
{"oid":602,"gid":97,"fullname":"King's Gambit Accepted, Bonsch-Osmolovsky Variation","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 Ne7 *","weight":20693,"prior_delay":25,"prior_completion_time":0},
{"oid":603,"gid":98,"fullname":"King's Gambit Accepted, Cunningham Defense, McCormick Defense","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 Be7 4.Bc4 Nf6 *","weight":20679,"prior_delay":25,"prior_completion_time":0},
{"oid":604,"gid":99,"fullname":"King's Gambit Accepted, Fischer Defense","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 d6 4.d4 g5 5.h4 g4 6.Ng1 *","weight":20720,"prior_delay":25,"prior_completion_time":0},
{"oid":605,"gid":99,"fullname":"King's Gambit Accepted, Fischer Defense","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 d6 4.Bc4 *","weight":20687,"prior_delay":25,"prior_completion_time":0},
{"oid":606,"gid":100,"fullname":"King's Gambit Accepted, Kieseritzky Gambit, Kolisch Defense","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 g5 4.h4 g4 5.Ne5 d6 *","weight":20779,"prior_delay":25,"prior_completion_time":0},
{"oid":607,"gid":100,"fullname":"King's Gambit Accepted, Kieseritzky Gambit, Neumann Defense","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 g5 4.h4 g4 5.Ne5 Nc6 *","weight":20763,"prior_delay":25,"prior_completion_time":0},
{"oid":608,"gid":101,"fullname":"King's Gambit Accepted, Modern Defense","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 d5 4.exd5 Bd6 *","weight":20716,"prior_delay":25,"prior_completion_time":0},
{"oid":609,"gid":102,"fullname":"King's Gambit Accepted, Schallopp Defense","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 Nf6 *","weight":20678,"prior_delay":25,"prior_completion_time":0},
{"oid":610,"gid":103,"fullname":"King's Gambit Accepted, Traditional Variation","main_line":"1.e4 e5 2.f4 exf4 3.Nf3 g5 4.Bc4 Bg7 5.d4 *","weight":20714,"prior_delay":25,"prior_completion_time":0},
{"oid":611,"gid":104,"fullname":"King's Gambit Declined, Classical Variation, Rotlewi Countergambit","main_line":"1.e4 e5 2.f4 Bc5 3.Nf3 d6 4.b4 *","weight":20477,"prior_delay":25,"prior_completion_time":0},
{"oid":612,"gid":104,"fullname":"King's Gambit Declined, Classical Variation","main_line":"1.e4 e5 2.f4 Bc5 3.Nf3 d6 4.c3 Bb6 *","weight":20481,"prior_delay":25,"prior_completion_time":0},
{"oid":613,"gid":105,"fullname":"King's Gambit Declined, Falkbeer, Rubinstein Variation","main_line":"1.e4 e5 2.f4 d5 3.exd5 e4 4.Nc3 Nf6 5.Qe2 *","weight":20487,"prior_delay":25,"prior_completion_time":0},
{"oid":614,"gid":106,"fullname":"King's Gambit Declined, Keene Defense","main_line":"1.e4 e5 2.f4 Qh4+ 3.g3 Qe7 *","weight":20451,"prior_delay":25,"prior_completion_time":0},
{"oid":615,"gid":107,"fullname":"King's Knight Opening, Normal Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.d3 Nf6 4.Be2 *","weight":48420,"prior_delay":25,"prior_completion_time":0},
{"oid":616,"gid":107,"fullname":"King's Knight Opening, Normal Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.d3 Nf6 4.g3 *","weight":48423,"prior_delay":25,"prior_completion_time":0},
{"oid":617,"gid":108,"fullname":"King's Pawn Game, Maroczy Defense","main_line":"1.e4 e5 2.d4 d6 3.dxe5 dxe5 4.Qxd8+ *","weight":20398,"prior_delay":25,"prior_completion_time":0},
{"oid":618,"gid":109,"fullname":"King's Pawn Game, Tayler Opening, Inverted Hanham","main_line":"1.e4 e5 2.Nf3 Nc6 3.Be2 Nf6 4.d3 d5 5.Nbd2 *","weight":48473,"prior_delay":25,"prior_completion_time":0},
{"oid":619,"gid":110,"fullname":"Lion Defense, Lion's Jaw","main_line":"1.e4 d6 2.d4 Nf6 3.f3 g6 *","weight":18842,"prior_delay":25,"prior_completion_time":0},
{"oid":620,"gid":110,"fullname":"Lion Defense, Lion's Jaw","main_line":"1.e4 d6 2.d4 Nf6 3.f3 e5 4.dxe5 dxe5 5.Qxd8+ *","weight":18937,"prior_delay":25,"prior_completion_time":0},
{"oid":621,"gid":110,"fullname":"Lion Defense, Lion's Jaw","main_line":"1.e4 d6 2.d4 Nf6 3.f3 e5 4.d5 *","weight":18921,"prior_delay":25,"prior_completion_time":0},
{"oid":622,"gid":111,"fullname":"Modern Defense, Geller's System","main_line":"1.e4 g6 2.d4 Bg7 3.c3 d6 4.Nf3 *","weight":16097,"prior_delay":25,"prior_completion_time":0},
{"oid":623,"gid":112,"fullname":"Modern Defense, Gurgenidze Defense","main_line":"1.e4 c6 2.d4 d5 3.Nc3 g6 4.e5 Bg7 5.f4 h5 6.Nf3 *","weight":29238,"prior_delay":25,"prior_completion_time":0},
{"oid":624,"gid":113,"fullname":"Modern Defense, Lizard Defense, Mittenberger Gambit","main_line":"1.e4 g6 2.d4 Bg7 3.Nc3 d5 *","weight":16968,"prior_delay":25,"prior_completion_time":0},
{"oid":625,"gid":115,"fullname":"Modern Defense, Modern Pterodactyl","main_line":"1.e4 g6 2.d4 Bg7 3.Nc3 c5 *","weight":16972,"prior_delay":25,"prior_completion_time":0},
{"oid":626,"gid":116,"fullname":"Modern Defense, Pseudo-Austrian Attack","main_line":"1.e4 g6 2.d4 Bg7 3.Nc3 d6 4.f4 Nc6 *","weight":17772,"prior_delay":25,"prior_completion_time":0},
{"oid":627,"gid":116,"fullname":"Modern Defense, Pseudo-Austrian Attack","main_line":"1.e4 g6 2.d4 Bg7 3.Nc3 d6 4.f4 c6 5.Nf3 *","weight":17789,"prior_delay":25,"prior_completion_time":0},
{"oid":628,"gid":117,"fullname":"Modern Defense, Semi-Averbakh Variation, Pterodactyl Variation","main_line":"1.e4 c5 2.Nf3 g6 3.d4 Bg7 4.c4 Qa5+ *","weight":38413,"prior_delay":25,"prior_completion_time":0},
{"oid":629,"gid":118,"fullname":"Modern Defense, Standard Defense","main_line":"1.e4 g6 2.d4 Bg7 3.Nc3 d6 4.Be3 c6 *","weight":17969,"prior_delay":25,"prior_completion_time":0},
{"oid":630,"gid":118,"fullname":"Modern Defense, Standard Defense","main_line":"1.e4 g6 2.d4 Bg7 3.Nc3 d6 4.Bc4 c6 5.Qf3 Nf6 *","weight":17509,"prior_delay":25,"prior_completion_time":0},
{"oid":631,"gid":118,"fullname":"Modern Defense, Standard Defense","main_line":"1.e4 g6 2.d4 Bg7 3.Nc3 d6 4.Be3 a6 *","weight":18113,"prior_delay":25,"prior_completion_time":0},
{"oid":632,"gid":118,"fullname":"Modern Defense, Standard Defense","main_line":"1.e4 g6 2.d4 Bg7 3.Nc3 d6 4.Bc4 c6 5.Qf3 e6 *","weight":17534,"prior_delay":25,"prior_completion_time":0},
{"oid":633,"gid":114,"fullname":"Modern Defense, Main Line","main_line":"1.e4 g6 2.Nc3 Bg7 3.f4 d6 *","weight":13135,"prior_delay":25,"prior_completion_time":0},
{"oid":634,"gid":119,"fullname":"Nimzo-Indian Defense, Panov Attack, Main Line","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 e6 6.Nf3 Bb4 7.cxd5 exd5 *","weight":33453,"prior_delay":25,"prior_completion_time":0},
{"oid":635,"gid":119,"fullname":"Nimzo-Indian Defense, Panov Attack, Main Line","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 e6 6.Nf3 Bb4 7.cxd5 Nxd5  8.Qc2 Nc6 9.Bd3 *","weight":33997,"prior_delay":25,"prior_completion_time":0},
{"oid":636,"gid":119,"fullname":"Nimzo-Indian Defense, Panov Attack, Main Line","main_line":"1.e4 c6 2.d4 d5 3.exd5 cxd5 4.c4 Nf6 5.Nc3 e6 6.Nf3 Bb4 7.cxd5 Nxd5  8.Bd2 Nc6 9.Bd3 O-O *","weight":34172,"prior_delay":25,"prior_completion_time":0},
{"oid":637,"gid":120,"fullname":"Nimzowitsch Defense, Scandinavian Variation, Advance Variation","main_line":"1.e4 Nc6 2.d4 d5 3.e5 Bf5 4.c3 *","weight":12792,"prior_delay":25,"prior_completion_time":0},
{"oid":638,"gid":120,"fullname":"Nimzowitsch Defense, Scandinavian Variation, Bogoljubov Variation, Vehre Variation","main_line":"1.e4 Nc6 2.d4 d5 3.Nc3 Nf6 *","weight":12786,"prior_delay":25,"prior_completion_time":0},
{"oid":639,"gid":120,"fullname":"Nimzowitsch Defense, Scandinavian Variation, Bogoljubov Variation","main_line":"1.e4 Nc6 2.d4 d5 3.Nc3 dxe4 *","weight":12784,"prior_delay":25,"prior_completion_time":0},
{"oid":640,"gid":121,"fullname":"Nimzowitsch Defense, Williams Variation","main_line":"1.e4 Nc6 2.Nf3 d6 3.d4 Bg4 *","weight":12970,"prior_delay":25,"prior_completion_time":0},
{"oid":641,"gid":121,"fullname":"Nimzowitsch Defense, Williams Variation","main_line":"1.e4 Nc6 2.Nf3 d6 3.d4 Nf6 4.Nc3 g6 *","weight":13083,"prior_delay":25,"prior_completion_time":0},
{"oid":642,"gid":121,"fullname":"Nimzowitsch Defense, Williams Variation","main_line":"1.e4 Nc6 2.Nf3 d6 3.d4 Nf6 4.Nc3 Bg4 5.Bb5 *","weight":13084,"prior_delay":25,"prior_completion_time":0},
{"oid":643,"gid":121,"fullname":"Nimzowitsch Defense, Williams Variation","main_line":"1.e4 Nc6 2.Nf3 d6 3.d4 Nf6 4.Nc3 Bg4 5.d5 *","weight":13091,"prior_delay":25,"prior_completion_time":0},
{"oid":644,"gid":121,"fullname":"Nimzowitsch Defense, Williams Variation","main_line":"1.e4 Nc6 2.Nf3 d6 3.d4 Nf6 4.Nc3 Bg4 5.Be3 *","weight":13096,"prior_delay":25,"prior_completion_time":0},
{"oid":645,"gid":121,"fullname":"Nimzowitsch Defense, Williams Variation","main_line":"1.e4 Nc6 2.Nf3 d6 3.d4 Nf6 4.Nc3 Bg4 5.Be2 *","weight":13092,"prior_delay":25,"prior_completion_time":0},
{"oid":646,"gid":122,"fullname":"Owen Defense, Main Line","main_line":"1.e4 b6 2.d4 Bb7 3.Bd3 f5 *","weight":12922,"prior_delay":25,"prior_completion_time":0},
{"oid":647,"gid":122,"fullname":"Owen Defense, Main Line","main_line":"1.e4 b6 2.d4 Bb7 3.Bd3 Nf6 *","weight":12935,"prior_delay":25,"prior_completion_time":0},
{"oid":648,"gid":122,"fullname":"Owen Defense, Main Line","main_line":"1.e4 b6 2.d4 Bb7 3.Bd3 e6 4.Nf3 c5 5.c3 *","weight":13005,"prior_delay":25,"prior_completion_time":0},
{"oid":649,"gid":123,"fullname":"Philidor Defense, Exchange Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 exd4 4.Qxd4 Nc6 *","weight":38130,"prior_delay":25,"prior_completion_time":0},
{"oid":650,"gid":123,"fullname":"Philidor Defense, Exchange Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 exd4 4.Qxd4 Nf6 5.Nc3 *","weight":38126,"prior_delay":25,"prior_completion_time":0},
{"oid":651,"gid":123,"fullname":"Philidor Defense, Exchange Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 exd4 4.Nxd4 Nf6 5.Nc3 Be7 6.Bc4 *","weight":38915,"prior_delay":25,"prior_completion_time":0},
{"oid":652,"gid":124,"fullname":"Philidor Defense, Hanham Variation, Delmar Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nd7 4.Bc4 c6 5.c3 *","weight":37936,"prior_delay":25,"prior_completion_time":0},
{"oid":653,"gid":124,"fullname":"Philidor Defense, Hanham Variation, Schlechter Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nd7 4.Bc4 c6 5.Nc3 *","weight":37935,"prior_delay":25,"prior_completion_time":0},
{"oid":654,"gid":124,"fullname":"Philidor Defense, Hanham Variation, Steiner Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nd7 4.Bc4 c6 5.O-O Be7 6.dxe5 *","weight":37956,"prior_delay":25,"prior_completion_time":0},
{"oid":655,"gid":125,"fullname":"Philidor Defense, Larsen Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 exd4 4.Nxd4 g6 *","weight":38332,"prior_delay":25,"prior_completion_time":0},
{"oid":656,"gid":126,"fullname":"Philidor Defense, Lion Variation, Sozin Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nf6 4.Nc3 Nbd7 5.Bc4 Be7 6.O-O O-O 7.Qe2 c6 8.a4 exd4 *","weight":41160,"prior_delay":25,"prior_completion_time":0},
{"oid":657,"gid":126,"fullname":"Philidor Defense, Lion Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nf6 4.Nc3 Nbd7 5.Bc4 Be7 6.dxe5 Nxe5 *","weight":39947,"prior_delay":25,"prior_completion_time":0},
{"oid":658,"gid":126,"fullname":"Philidor Defense, Lion Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nf6 4.Nc3 Nbd7 5.Bc4 Be7 6.O-O O-O 7.Re1 c6 8.a4 a5 *","weight":42091,"prior_delay":25,"prior_completion_time":0},
{"oid":659,"gid":126,"fullname":"Philidor Defense, Lion Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nf6 4.Nc3 Nbd7 5.Bc4 Be7 6.O-O O-O 7.Re1 c6 8.a4 b6 *","weight":42079,"prior_delay":25,"prior_completion_time":0},
{"oid":660,"gid":126,"fullname":"Philidor Defense, Lion Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nf6 4.Nc3 Nbd7 5.Bc4 Be7 6.O-O O-O 7.a4 c6 8.Qe2 *","weight":41558,"prior_delay":25,"prior_completion_time":0},
{"oid":661,"gid":127,"fullname":"Philidor Defense, Nimzowitsch, Larobok Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nf6 4.Nc3 Nbd7 5.Bc4 Be7 6.Ng5 O-O 7.Bxf7+ *","weight":39950,"prior_delay":25,"prior_completion_time":0},
{"oid":662,"gid":128,"fullname":"Philidor Defense, Nimzowitsch Variation, Klein Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nf6 4.Bc4 *","weight":37932,"prior_delay":25,"prior_completion_time":0},
{"oid":663,"gid":128,"fullname":"Philidor Defense, Nimzowitsch Variation, Rellstab Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nf6 4.dxe5 Nxe4 5.Qd5 *","weight":37968,"prior_delay":25,"prior_completion_time":0},
{"oid":664,"gid":128,"fullname":"Philidor Defense, Nimzowitsch Variation, Sokolsky Variation","main_line":"1.e4 e5 2.Nf3 d6 3.d4 Nf6 4.dxe5 Nxe4 5.Nbd2 *","weight":37956,"prior_delay":25,"prior_completion_time":0},
{"oid":665,"gid":129,"fullname":"Pirc Defense, Austrian Attack, DragonFormation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.f4 Bg7 5.Nf3 c5 6.Bb5+ Bd7 7.e5 Ng4 8.e6 Bxb5 *","weight":23814,"prior_delay":25,"prior_completion_time":0},
{"oid":666,"gid":129,"fullname":"Pirc Defense, Austrian Attack, DragonFormation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.f4 Bg7 5.Nf3 c5 6.Bb5+ Bd7 7.e5 Ng4 8.e6 fxe6 *","weight":23847,"prior_delay":25,"prior_completion_time":0},
{"oid":667,"gid":129,"fullname":"Pirc Defense, Austrian Attack, DragonFormation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.f4 Bg7 5.Nf3 c5 6.dxc5 Qa5 7.Bd3 *","weight":23587,"prior_delay":25,"prior_completion_time":0},
{"oid":668,"gid":129,"fullname":"Pirc Defense, Austrian Attack, DragonFormation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.f4 Bg7 5.Nf3 c5 6.Bb5+ Bd7 7.e5 Ng4 8.Bxd7+ *","weight":23796,"prior_delay":25,"prior_completion_time":0},
{"oid":669,"gid":129,"fullname":"Pirc Defense, Austrian Attack, Kurajica Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.f4 Bg7 5.Nf3 O-O 6.Be3 *","weight":23724,"prior_delay":25,"prior_completion_time":0},
{"oid":670,"gid":129,"fullname":"Pirc Defense, Austrian Attack, Ljubojevic Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.f4 Bg7 5.Bc4 *","weight":22968,"prior_delay":25,"prior_completion_time":0},
{"oid":671,"gid":129,"fullname":"Pirc Defense, Austrian Attack, Unzicker Attack","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.f4 Bg7 5.Nf3 O-O 6.e5 *","weight":23698,"prior_delay":25,"prior_completion_time":0},
{"oid":672,"gid":129,"fullname":"Pirc Defense, Austrian Attack, Weiss Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.f4 Bg7 5.Nf3 O-O 6.Bd3 Na6 7.O-O c5 8.d5 Bg4 *","weight":24253,"prior_delay":25,"prior_completion_time":0},
{"oid":673,"gid":129,"fullname":"Pirc Defense, Austrian Attack, Weiss Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.f4 Bg7 5.Nf3 O-O 6.Bd3 Nc6 7.e5 *","weight":23927,"prior_delay":25,"prior_completion_time":0},
{"oid":674,"gid":129,"fullname":"Pirc Defense, Austrian Attack","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.f4 Bg7 5.Nf3 O-O 6.Be2 *","weight":23667,"prior_delay":25,"prior_completion_time":0},
{"oid":675,"gid":130,"fullname":"Pirc Defense, Bayonet Attack","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Be2 Bg7 5.h4 *","weight":22420,"prior_delay":25,"prior_completion_time":0},
{"oid":676,"gid":131,"fullname":"Pirc Defense, Byrne Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Bg5 Bg7 *","weight":22611,"prior_delay":25,"prior_completion_time":0},
{"oid":677,"gid":132,"fullname":"Pirc Defense, Chinese Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Be2 Bg7 5.g4 *","weight":22410,"prior_delay":25,"prior_completion_time":0},
{"oid":678,"gid":133,"fullname":"Pirc Defense, Classical Variation, Quiet System, Chigorin Line","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.Be2 O-O 6.O-O Nc6 *","weight":24649,"prior_delay":25,"prior_completion_time":0},
{"oid":679,"gid":133,"fullname":"Pirc Defense, Classical Variation, Quiet System, Czech Defense","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.Be2 O-O 6.O-O c6 7.a4 Nbd7 *","weight":24882,"prior_delay":25,"prior_completion_time":0},
{"oid":680,"gid":133,"fullname":"Pirc Defense, Classical Variation, Quiet System, Czech Defense","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.Be2 O-O 6.O-O c6 7.h3 *","weight":24812,"prior_delay":25,"prior_completion_time":0},
{"oid":681,"gid":133,"fullname":"Pirc Defense, Classical Variation, Quiet System, Parma Defense","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.Be2 O-O 6.O-O Bg4 7.Be3 Nc6 8.Qd2 e5 *","weight":24954,"prior_delay":25,"prior_completion_time":0},
{"oid":682,"gid":133,"fullname":"Pirc Defense, Classical Variation, Quiet System","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.Be2 c6 *","weight":23554,"prior_delay":25,"prior_completion_time":0},
{"oid":683,"gid":133,"fullname":"Pirc Defense, Classical Variation, Quiet System","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.Be2 a6 6.a4 *","weight":23559,"prior_delay":25,"prior_completion_time":0},
{"oid":684,"gid":133,"fullname":"Pirc Defense, Classical Variation, Quiet System","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.Be2 O-O 6.Be3 *","weight":24031,"prior_delay":25,"prior_completion_time":0},
{"oid":685,"gid":133,"fullname":"Pirc Defense, Classical Variation, Schlechter Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.h3 O-O 6.Be3 d5 *","weight":23302,"prior_delay":25,"prior_completion_time":0},
{"oid":686,"gid":133,"fullname":"Pirc Defense, Classical Variation, Schlechter Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.h3 O-O 6.Be3 c6 *","weight":23321,"prior_delay":25,"prior_completion_time":0},
{"oid":687,"gid":133,"fullname":"Pirc Defense, Classical Variation, Schlechter Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.h3 c6 *","weight":23173,"prior_delay":25,"prior_completion_time":0},
{"oid":688,"gid":133,"fullname":"Pirc Defense, Classical Variation, Schlechter Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.h3 O-O 6.Be3 a6 *","weight":23333,"prior_delay":25,"prior_completion_time":0},
{"oid":689,"gid":133,"fullname":"Pirc Defense, Classical Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.Bc4 c6 *","weight":23105,"prior_delay":25,"prior_completion_time":0},
{"oid":690,"gid":133,"fullname":"Pirc Defense, Classical Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.Be3 c6 *","weight":23162,"prior_delay":25,"prior_completion_time":0},
{"oid":691,"gid":133,"fullname":"Pirc Defense, Classical Variation","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.Be3 a6 6.a4 *","weight":23170,"prior_delay":25,"prior_completion_time":0},
{"oid":692,"gid":134,"fullname":"Pirc Defense, Kholmov System","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Bc4 Bg7 *","weight":22369,"prior_delay":25,"prior_completion_time":0},
{"oid":693,"gid":136,"fullname":"Pirc Defense, Sveshnikov System","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.g3 Bg7 5.Bg2 c6 *","weight":22703,"prior_delay":25,"prior_completion_time":0},
{"oid":694,"gid":135,"fullname":"Pirc Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Be3 c6 *","weight":22833,"prior_delay":25,"prior_completion_time":0},
{"oid":695,"gid":135,"fullname":"Pirc Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 Nbd7 *","weight":21364,"prior_delay":25,"prior_completion_time":0},
{"oid":696,"gid":135,"fullname":"Pirc Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Bd3 e5 4.c3 d5 5.dxe5 dxe4 *","weight":18997,"prior_delay":25,"prior_completion_time":0},
{"oid":697,"gid":135,"fullname":"Pirc Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Nge2 Bg7 *","weight":22375,"prior_delay":25,"prior_completion_time":0},
{"oid":698,"gid":135,"fullname":"Pirc Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Nbd2 g6 4.c3 Bg7 *","weight":18616,"prior_delay":25,"prior_completion_time":0},
{"oid":699,"gid":135,"fullname":"Pirc Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.Be3 Bg7 5.Qd2 c6 6.f3 b5 *","weight":22872,"prior_delay":25,"prior_completion_time":0},
{"oid":700,"gid":135,"fullname":"Pirc Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Bd3 e5 4.c3 d5 5.dxe5 Nxe4 *","weight":19023,"prior_delay":25,"prior_completion_time":0},
{"oid":701,"gid":135,"fullname":"Pirc Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 g6 4.h4 *","weight":22365,"prior_delay":25,"prior_completion_time":0},
{"oid":702,"gid":135,"fullname":"Pirc Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Bd3 g6 4.Nf3 Bg7 5.c3 *","weight":18898,"prior_delay":25,"prior_completion_time":0},
{"oid":703,"gid":135,"fullname":"Pirc Defense, Main Line","main_line":"1.e4 d6 2.d4 Nf6 3.Nc3 e5 4.dxe5 dxe5 5.Qxd8+ *","weight":22518,"prior_delay":25,"prior_completion_time":0},
{"oid":704,"gid":137,"fullname":"Ponziani Opening, Caro Gambit","main_line":"1.e4 e5 2.Nf3 Nc6 3.c3 d5 4.Qa4 Bd7 *","weight":48531,"prior_delay":25,"prior_completion_time":0},
{"oid":705,"gid":138,"fullname":"Ponziani Opening, Jaenisch Counterattack","main_line":"1.e4 e5 2.Nf3 Nc6 3.c3 Nf6 4.d4 Nxe4 5.d5 Ne7 *","weight":48645,"prior_delay":25,"prior_completion_time":0},
{"oid":706,"gid":138,"fullname":"Ponziani Opening, Jaenisch Counterattack","main_line":"1.e4 e5 2.Nf3 Nc6 3.c3 Nf6 4.d4 exd4 *","weight":48588,"prior_delay":25,"prior_completion_time":0},
{"oid":707,"gid":139,"fullname":"Ponziani Opening, Leonhardt Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.c3 d5 4.Qa4 Nf6 *","weight":48531,"prior_delay":25,"prior_completion_time":0},
{"oid":708,"gid":140,"fullname":"Ponziani Opening, Romanishin Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.c3 Be7 *","weight":48489,"prior_delay":25,"prior_completion_time":0},
{"oid":709,"gid":141,"fullname":"Ponziani Opening, Steinitz Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.c3 d5 4.Qa4 f6 *","weight":48536,"prior_delay":25,"prior_completion_time":0},
{"oid":710,"gid":142,"fullname":"Ponziani Opening, Vukovic Gambit","main_line":"1.e4 e5 2.Nf3 Nc6 3.c3 Nf6 4.d4 Nxe4 5.d5 Bc5 *","weight":48633,"prior_delay":25,"prior_completion_time":0},
{"oid":711,"gid":143,"fullname":"Pterodactyl Defense, Sicilian, Rhamporhynchus","main_line":"1.e4 c5 2.Nf3 g6 3.d4 Bg7 4.dxc5 Qa5+ 5.Nc3 *","weight":38413,"prior_delay":25,"prior_completion_time":0},
{"oid":712,"gid":144,"fullname":"Pterodactyl Defense, Western, Rhamphorhynchus","main_line":"1.e4 c5 2.Nf3 g6 3.d4 Bg7 4.dxc5 Qa5+ 5.c3 Qxc5 *","weight":38448,"prior_delay":25,"prior_completion_time":0},
{"oid":713,"gid":145,"fullname":"Rat Defense, Harmonist","main_line":"1.e4 d6 2.f4 Nf6 3.Nc3 g6 4.Nf3 Bg7 *","weight":13421,"prior_delay":25,"prior_completion_time":0},
{"oid":714,"gid":146,"fullname":"Robatsch (Modern) Defense, Main Line","main_line":"1.e4 g6 2.d4 Bg7 3.Nf3 d6 4.Bc4 *","weight":16881,"prior_delay":25,"prior_completion_time":0},
{"oid":715,"gid":147,"fullname":"Russian Game, Classical Attack, Berger Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.d4 d5 6.Bd3 Be7 7.O-O Nc6  8.Re1 Bg4 9.c3 f5 10.Nbd2 *","weight":52164,"prior_delay":25,"prior_completion_time":0},
{"oid":716,"gid":147,"fullname":"Russian Game, Classical Attack, Chigorin Variation, Main Line","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.d4 d5 6.Bd3 Be7 7.O-O Nc6 8.c4 Nb4  9.Be2 O-O *","weight":53445,"prior_delay":25,"prior_completion_time":0},
{"oid":717,"gid":147,"fullname":"Russian Game, Classical Attack, Chigorin Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.d4 d5 6.Bd3 Be7 7.O-O Nc6  8.Re1 Bf5 *","weight":51933,"prior_delay":25,"prior_completion_time":0},
{"oid":718,"gid":147,"fullname":"Russian Game, Classical Attack, Chigorin Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.d4 d5 6.Bd3 Be7 7.O-O Nc6  8.Re1 Bg4 9.c4 *","weight":52085,"prior_delay":25,"prior_completion_time":0},
{"oid":719,"gid":147,"fullname":"Russian Game, Classical Attack, Closed Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.d4 Nf6 *","weight":48731,"prior_delay":25,"prior_completion_time":0},
{"oid":720,"gid":147,"fullname":"Russian Game, Classical Attack, Mason Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.d4 d5 6.Bd3 Be7 7.O-O O-O *","weight":51579,"prior_delay":25,"prior_completion_time":0},
{"oid":721,"gid":147,"fullname":"Russian Game, Classical Attack, Staunton Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.d4 d5 6.Bd3 Bd6 7.O-O O-O  8.c4 c6 *","weight":52246,"prior_delay":25,"prior_completion_time":0},
{"oid":722,"gid":148,"fullname":"Russian Game, Cochrane Gambit, Center Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nxf7 Kxf7 5.d4 *","weight":43163,"prior_delay":25,"prior_completion_time":0},
{"oid":723,"gid":149,"fullname":"Russian Game, Cozio (Lasker) Attack","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.Qe2 Qe7 6.d3 Nf6 7.Bg5 Nbd7 *","weight":47935,"prior_delay":25,"prior_completion_time":0},
{"oid":724,"gid":149,"fullname":"Russian Game, Cozio (Lasker) Attack","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.Qe2 Qe7 6.d3 Nf6 7.Bg5 Qxe2+ *","weight":47968,"prior_delay":25,"prior_completion_time":0},
{"oid":725,"gid":150,"fullname":"Russian Game, Damiano Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 Nxe4 *","weight":41328,"prior_delay":25,"prior_completion_time":0},
{"oid":726,"gid":151,"fullname":"Russian Game, French Attack","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.d3 *","weight":47347,"prior_delay":25,"prior_completion_time":0},
{"oid":727,"gid":152,"fullname":"Russian Game, Kaufmann Attack","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.c4 *","weight":47343,"prior_delay":25,"prior_completion_time":0},
{"oid":728,"gid":153,"fullname":"Russian Game, Modern Attack, Center Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.d4 Nxe4 4.Bd3 d5 5.Nxe5 Nc6 *","weight":41165,"prior_delay":25,"prior_completion_time":0},
{"oid":729,"gid":153,"fullname":"Russian Game, Modern Attack, Center Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.d4 Nxe4 4.Bd3 d5 5.Nxe5 Nd7 6.Nxd7 Bxd7 7.O-O Qh4 *","weight":42081,"prior_delay":25,"prior_completion_time":0},
{"oid":730,"gid":153,"fullname":"Russian Game, Modern Attack, Center Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.d4 Nxe4 4.Bd3 d5 5.Nxe5 Be7 *","weight":41165,"prior_delay":25,"prior_completion_time":0},
{"oid":731,"gid":153,"fullname":"Russian Game, Modern Attack, Center Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.d4 Nxe4 4.Bd3 d5 5.Nxe5 Nd7 6.Qe2 *","weight":41424,"prior_delay":25,"prior_completion_time":0},
{"oid":732,"gid":153,"fullname":"Russian Game, Modern Attack, Center Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.d4 Nxe4 4.Bd3 d5 5.dxe5 *","weight":40879,"prior_delay":25,"prior_completion_time":0},
{"oid":733,"gid":153,"fullname":"Russian Game, Modern Attack, Center Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.d4 Nxe4 4.Bd3 d5 5.Nxe5 Nd7 6.Nxd7 Bxd7 7.O-O Bd6 8.c4 *","weight":42287,"prior_delay":25,"prior_completion_time":0},
{"oid":734,"gid":153,"fullname":"Russian Game, Modern Attack, Murrey Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.d4 Nxe4 4.Bd3 Nc6 5.d5 *","weight":40521,"prior_delay":25,"prior_completion_time":0},
{"oid":735,"gid":153,"fullname":"Russian Game, Modern Attack, Murrey Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.d4 Nxe4 4.Bd3 Nc6 5.Bxe4 *","weight":40528,"prior_delay":25,"prior_completion_time":0},
{"oid":736,"gid":153,"fullname":"Russian Game, Modern Attack, Tal Gambit","main_line":"1.e4 e5 2.Nf3 Nf6 3.d4 exd4 4.e5 Ne4 5.Bb5 *","weight":39641,"prior_delay":25,"prior_completion_time":0},
{"oid":737,"gid":153,"fullname":"Russian Game, Modern Attack, Trifunovic Variation","main_line":"1.e4 e5 2.Nf3 Nf6 3.d4 Nxe4 4.Bd3 d5 5.Nxe5 Bd6 6.O-O O-O 7.c4 Bxe5 *","weight":41231,"prior_delay":25,"prior_completion_time":0},
{"oid":738,"gid":154,"fullname":"Russian Game, Nimzowitsch Attack","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nf3 Nxe4 5.Nc3 *","weight":47905,"prior_delay":25,"prior_completion_time":0},
{"oid":739,"gid":155,"fullname":"Russian Game, Paulsen Attack","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nxe5 d6 4.Nc4 *","weight":43188,"prior_delay":25,"prior_completion_time":0},
{"oid":740,"gid":156,"fullname":"Russian Game, Three Knights Game","main_line":"1.e4 e5 2.Nf3 Nf6 3.Nc3 Bb4 4.Nxe5 *","weight":39239,"prior_delay":25,"prior_completion_time":0},
{"oid":741,"gid":157,"fullname":"Ruy Lopez, Alapin Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bb4 4.c3 *","weight":65042,"prior_delay":25,"prior_completion_time":0},
{"oid":742,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Beverwijk Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Bc5 5.Nxe5 *","weight":71954,"prior_delay":25,"prior_completion_time":0},
{"oid":743,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Closed Showalter Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O d6 5.d4 Bd7 6.Nc3 Be7 7.Bxc6 *","weight":71908,"prior_delay":25,"prior_completion_time":0},
{"oid":744,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Closed Wolf Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O d6 5.d4 Bd7 6.Nc3 exd4 *","weight":71886,"prior_delay":25,"prior_completion_time":0},
{"oid":745,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Cordel Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Be7 6.Qe2 Nd6 7.Bxc6 bxc6  8.dxe5 Nf5 *","weight":76144,"prior_delay":25,"prior_completion_time":0},
{"oid":746,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Duras Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.d3 d6 5.c4 *","weight":71148,"prior_delay":25,"prior_completion_time":0},
{"oid":747,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Improved Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O d6 5.Re1 *","weight":71820,"prior_delay":25,"prior_completion_time":0},
{"oid":748,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Kaufmann Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.d3 Bc5 5.Be3 *","weight":71927,"prior_delay":25,"prior_completion_time":0},
{"oid":749,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, l'Hermet Variation, Berlin Wall Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Nd6 6.Bxc6 dxc6 7.dxe5 Nf5  8.Qxd8+ Kxd8 9.Nc3 h6 *","weight":86608,"prior_delay":25,"prior_completion_time":0},
{"oid":750,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, l'Hermet Variation, Berlin Wall Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Nd6 6.Bxc6 dxc6 7.dxe5 Nf5  8.Qxd8+ Kxd8 9.Nc3 Ke8 10.h3 a5 *","weight":87153,"prior_delay":25,"prior_completion_time":0},
{"oid":751,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, l'Hermet Variation, Berlin Wall Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Nd6 6.Bxc6 dxc6 7.dxe5 Nf5  8.Qxd8+ Kxd8 9.Nc3 Ke8 10.Rd1 *","weight":86831,"prior_delay":25,"prior_completion_time":0},
{"oid":752,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Minckwitz Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Be7 6.dxe5 *","weight":75885,"prior_delay":25,"prior_completion_time":0},
{"oid":753,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Nyholm Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.d4 exd4 5.O-O *","weight":69211,"prior_delay":25,"prior_completion_time":0},
{"oid":754,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Pillsbury Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Be7 6.Qe2 Nd6 7.Bxc6 bxc6  8.dxe5 Nb7 9.b3 *","weight":76181,"prior_delay":25,"prior_completion_time":0},
{"oid":755,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Rio de Janeiro Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Be7 6.Qe2 Nd6 7.Bxc6 bxc6  8.dxe5 Nb7 9.Nc3 *","weight":76195,"prior_delay":25,"prior_completion_time":0},
{"oid":756,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Rio Gambit Accepted","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.Qe2 *","weight":74079,"prior_delay":25,"prior_completion_time":0},
{"oid":757,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Rio Gambit Accepted","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.Re1 Nd6 6.Nxe5 *","weight":75993,"prior_delay":25,"prior_completion_time":0},
{"oid":758,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Rosenthal Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 a6 *","weight":75826,"prior_delay":25,"prior_completion_time":0},
{"oid":759,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Trifunovic Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Be7 6.Qe2 d5 *","weight":75933,"prior_delay":25,"prior_completion_time":0},
{"oid":760,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Winawer Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Be7 6.Qe2 Nd6 7.Bxc6 bxc6  8.dxe5 Nb7 9.Nd4 *","weight":76185,"prior_delay":25,"prior_completion_time":0},
{"oid":761,"gid":158,"fullname":"Ruy Lopez, Berlin Defense, Zukertort Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Be7 6.Qe2 Nd6 7.Bxc6 bxc6  8.dxe5 Nb7 9.c4 *","weight":76190,"prior_delay":25,"prior_completion_time":0},
{"oid":762,"gid":158,"fullname":"Ruy Lopez, Berlin Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Be7 *","weight":71808,"prior_delay":25,"prior_completion_time":0},
{"oid":763,"gid":158,"fullname":"Ruy Lopez, Berlin Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.Qe2 *","weight":69207,"prior_delay":25,"prior_completion_time":0},
{"oid":764,"gid":158,"fullname":"Ruy Lopez, Berlin Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.d3 d6 5.c3 *","weight":71282,"prior_delay":25,"prior_completion_time":0},
{"oid":765,"gid":159,"fullname":"Ruy Lopez, Bird Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nd4 4.Nxd4 exd4 5.O-O Bc5 6.d3 c6 7.Bc4 d5 *","weight":65285,"prior_delay":25,"prior_completion_time":0},
{"oid":766,"gid":159,"fullname":"Ruy Lopez, Bird Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nd4 4.Nxd4 exd4 5.d3 *","weight":65181,"prior_delay":25,"prior_completion_time":0},
{"oid":767,"gid":159,"fullname":"Ruy Lopez, Bird Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nd4 4.Nxd4 exd4 5.O-O c6 6.Bc4 *","weight":65235,"prior_delay":25,"prior_completion_time":0},
{"oid":768,"gid":159,"fullname":"Ruy Lopez, Bird Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nd4 4.Nxd4 exd4 5.Bc4 *","weight":65192,"prior_delay":25,"prior_completion_time":0},
{"oid":769,"gid":160,"fullname":"Ruy Lopez, Classical Variation, Central Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bc5 4.c3 Qf6 *","weight":65238,"prior_delay":25,"prior_completion_time":0},
{"oid":770,"gid":160,"fullname":"Ruy Lopez, Classical Variation, Central Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bc5 4.c3 Nf6 5.d4 Bb6 *","weight":65318,"prior_delay":25,"prior_completion_time":0},
{"oid":771,"gid":160,"fullname":"Ruy Lopez, Classical Variation, Central Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bc5 4.c3 Nge7 *","weight":65240,"prior_delay":25,"prior_completion_time":0},
{"oid":772,"gid":160,"fullname":"Ruy Lopez, Classical Variation, Charousek Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bc5 4.c3 Bb6 *","weight":65239,"prior_delay":25,"prior_completion_time":0},
{"oid":773,"gid":160,"fullname":"Ruy Lopez, Classical Variation, Cordel Gambit","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bc5 4.c3 f5 5.d4 *","weight":65262,"prior_delay":25,"prior_completion_time":0},
{"oid":774,"gid":160,"fullname":"Ruy Lopez, Classical Variation, Modern Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Bc5 5.c3 O-O 6.d4 Bb6 7.Bg5 *","weight":72192,"prior_delay":25,"prior_completion_time":0},
{"oid":775,"gid":160,"fullname":"Ruy Lopez, Classical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bc5 4.O-O Qf6 *","weight":65205,"prior_delay":25,"prior_completion_time":0},
{"oid":776,"gid":160,"fullname":"Ruy Lopez, Classical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bc5 4.O-O Nge7 *","weight":65200,"prior_delay":25,"prior_completion_time":0},
{"oid":777,"gid":160,"fullname":"Ruy Lopez, Classical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bc5 4.Bxc6 *","weight":65143,"prior_delay":25,"prior_completion_time":0},
{"oid":778,"gid":160,"fullname":"Ruy Lopez, Classical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bc5 4.O-O Nd4 5.Bc4 *","weight":65238,"prior_delay":25,"prior_completion_time":0},
{"oid":779,"gid":160,"fullname":"Ruy Lopez, Classical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bc5 4.O-O d6 5.c3 *","weight":65208,"prior_delay":25,"prior_completion_time":0},
{"oid":780,"gid":160,"fullname":"Ruy Lopez, Classical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Bc5 4.O-O Nd4 5.Nxd4 Bxd4 6.c3 Bb6 7.d4 c6 8.Ba4 d6  9.Na3 *","weight":65495,"prior_delay":25,"prior_completion_time":0},
{"oid":781,"gid":161,"fullname":"Ruy Lopez, Closed, Anti-Marshall","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.a4 b4 *","weight":121994,"prior_delay":25,"prior_completion_time":0},
{"oid":782,"gid":161,"fullname":"Ruy Lopez, Closed, Anti-Marshall","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.a4 Bb7  9.d3 d6 10.c3 *","weight":122141,"prior_delay":25,"prior_completion_time":0},
{"oid":783,"gid":161,"fullname":"Ruy Lopez, Closed, Anti-Marshall","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.a4 Bb7  9.d3 d6 10.Nc3 *","weight":122149,"prior_delay":25,"prior_completion_time":0},
{"oid":784,"gid":161,"fullname":"Ruy Lopez, Closed, Rossolimo Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c6 11.d4 Qc7 *","weight":126865,"prior_delay":25,"prior_completion_time":0},
{"oid":785,"gid":161,"fullname":"Ruy Lopez, Closed","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 d6 8.c3 Bg4 *","weight":124956,"prior_delay":25,"prior_completion_time":0},
{"oid":786,"gid":161,"fullname":"Ruy Lopez, Closed","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.h3 Bb7  9.d3 d6 *","weight":123147,"prior_delay":25,"prior_completion_time":0},
{"oid":787,"gid":161,"fullname":"Ruy Lopez, Closed","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.d3 d6 *","weight":121556,"prior_delay":25,"prior_completion_time":0},
{"oid":788,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Averbakh Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 d6 7.c3 O-O 8.h3 Bd7 *","weight":110644,"prior_delay":25,"prior_completion_time":0},
{"oid":789,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Averbakh Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 d6 7.c3 O-O 8.d4 Bd7  9.Nbd2 Re8 *","weight":110652,"prior_delay":25,"prior_completion_time":0},
{"oid":790,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Averbakh Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 d6 7.Bxc6+ bxc6  8.d4 exd4 9.Nxd4 *","weight":110737,"prior_delay":25,"prior_completion_time":0},
{"oid":791,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Averbakh Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 d6 7.c3 Bg4 8.d3 *","weight":110755,"prior_delay":25,"prior_completion_time":0},
{"oid":792,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Averbakh Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 d6 7.c3 Bg4 8.h3 *","weight":110733,"prior_delay":25,"prior_completion_time":0},
{"oid":793,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Balla Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 d6 8.c3 Na5  9.Bc2 c5 10.d4 Qc7 11.a4 *","weight":125062,"prior_delay":25,"prior_completion_time":0},
{"oid":794,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Bogoljubov Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.d4 Bg4 10.Be3 exd4 *","weight":123509,"prior_delay":25,"prior_completion_time":0},
{"oid":795,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Bogoljubov Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.d4 Bg4 10.d5 Na5 11.Bc2 Qc8 *","weight":123517,"prior_delay":25,"prior_completion_time":0},
{"oid":796,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Bogoljubov Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.d4 Bg4 10.d5 Na5 11.Bc2 c6 12.h3 Bc8 *","weight":123712,"prior_delay":25,"prior_completion_time":0},
{"oid":797,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Borisenko Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Nc6 *","weight":128436,"prior_delay":25,"prior_completion_time":0},
{"oid":798,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Breyer Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nb8 10.d4 Nbd7 11.Nbd2 Bb7 12.Bc2 c5 *","weight":129211,"prior_delay":25,"prior_completion_time":0},
{"oid":799,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Breyer Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nb8 10.d4 Nbd7 11.Nh4 *","weight":127233,"prior_delay":25,"prior_completion_time":0},
{"oid":800,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Center Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.d4 exd4 7.Re1 b5  8.e5 Nxe5 9.Rxe5 *","weight":105054,"prior_delay":25,"prior_completion_time":0},
{"oid":801,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Center Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.d4 exd4 7.e5 Ne4  8.Nxd4 Nxd4 *","weight":105081,"prior_delay":25,"prior_completion_time":0},
{"oid":802,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Center Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.d4 exd4 7.e5 Ne4  8.Nxd4 O-O *","weight":105088,"prior_delay":25,"prior_completion_time":0},
{"oid":803,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Center Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.d4 exd4 7.Re1 O-O  8.e5 Ne8 9.c3 *","weight":105078,"prior_delay":25,"prior_completion_time":0},
{"oid":804,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Center Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.d4 exd4 7.Re1 O-O  8.e5 Ne8 9.Bf4 *","weight":105075,"prior_delay":25,"prior_completion_time":0},
{"oid":805,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Chigorin Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.d5 c4 *","weight":128839,"prior_delay":25,"prior_completion_time":0},
{"oid":806,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Chigorin Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 Nc6 13.d5 Na5 *","weight":129205,"prior_delay":25,"prior_completion_time":0},
{"oid":807,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Chigorin Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 Bb7 *","weight":129177,"prior_delay":25,"prior_completion_time":0},
{"oid":808,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Chigorin Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 Re8 *","weight":129193,"prior_delay":25,"prior_completion_time":0},
{"oid":809,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Chigorin Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 Rd8 *","weight":129199,"prior_delay":25,"prior_completion_time":0},
{"oid":810,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Chigorin Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 Bd7 13.Nf1 Nc4 *","weight":129287,"prior_delay":25,"prior_completion_time":0},
{"oid":811,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Chigorin Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 Nc6 13.d5 Nd8 14.a4 *","weight":129221,"prior_delay":25,"prior_completion_time":0},
{"oid":812,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Closed Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 cxd4 *","weight":128443,"prior_delay":25,"prior_completion_time":0},
{"oid":813,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Closed Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d3 Nc6 *","weight":127514,"prior_delay":25,"prior_completion_time":0},
{"oid":814,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Closed Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.a4 Bg4 *","weight":122355,"prior_delay":25,"prior_completion_time":0},
{"oid":815,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Closed Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Bb7 12.Nbd2 *","weight":128472,"prior_delay":25,"prior_completion_time":0},
{"oid":816,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Delayed Exchange","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Bxc6 dxc6 7.Qe1 Nd7 8.b3 *","weight":105400,"prior_delay":25,"prior_completion_time":0},
{"oid":817,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Delayed Exchange","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Bxc6 dxc6 7.Qe2 *","weight":105349,"prior_delay":25,"prior_completion_time":0},
{"oid":818,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Delayed Exchange","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Bxc6 dxc6 7.d3 Bg4 *","weight":105457,"prior_delay":25,"prior_completion_time":0},
{"oid":819,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Delayed Exchange","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Bxc6 dxc6 7.Qe1 c5 *","weight":105374,"prior_delay":25,"prior_completion_time":0},
{"oid":820,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Delayed Exchange","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Bxc6 dxc6 7.d3 Nd7 8.Nbd2  O-O 9.Nc4 f6 10.Nh4 Nc5 11.Nf5 *","weight":105819,"prior_delay":25,"prior_completion_time":0},
{"oid":821,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Delayed Exchange","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Bxc6 dxc6 7.Nc3 Bg4 *","weight":105355,"prior_delay":25,"prior_completion_time":0},
{"oid":822,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Flohr System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Bb7 10.d4 Re8 11.Nbd2 Bf8 12.d5 Nb8 *","weight":128190,"prior_delay":25,"prior_completion_time":0},
{"oid":823,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Flohr System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Bb7 10.d4 Re8 11.Nbd2 Bf8 12.a4 h6 13.Bc2 exd4 14.cxd4 Nb4 15.Bb1 c5 *","weight":128537,"prior_delay":25,"prior_completion_time":0},
{"oid":824,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Flohr System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Bb7 10.d4 Re8 11.Ng5 *","weight":127061,"prior_delay":25,"prior_completion_time":0},
{"oid":825,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Flohr System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Bb7 10.d4 Re8 11.Nbd2 Bf8 12.a3 *","weight":128071,"prior_delay":25,"prior_completion_time":0},
{"oid":826,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Flohr System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Bb7 10.d4 Re8 11.Nbd2 Bf8 12.Bc2 *","weight":127973,"prior_delay":25,"prior_completion_time":0},
{"oid":827,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Keres Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Nd7 12.Nbd2 cxd4 *","weight":128771,"prior_delay":25,"prior_completion_time":0},
{"oid":828,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Keres Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 a5 *","weight":125240,"prior_delay":25,"prior_completion_time":0},
{"oid":829,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Keres Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nd7 10.d4 Bf6 11.a4 Bb7 12.Na3 *","weight":125695,"prior_delay":25,"prior_completion_time":0},
{"oid":830,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Kholmov Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Be6 10.d4 Bxb3 11.Qxb3 *","weight":125354,"prior_delay":25,"prior_completion_time":0},
{"oid":831,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Martinez Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.d3 b5 7.Bb3 *","weight":107111,"prior_delay":25,"prior_completion_time":0},
{"oid":832,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Morphy Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Nc3 b5 7.Bb3 d6 8.Nd5 *","weight":105046,"prior_delay":25,"prior_completion_time":0},
{"oid":833,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Pilnik Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.d3 h6 *","weight":122390,"prior_delay":25,"prior_completion_time":0},
{"oid":834,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Pilnik Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.d3 Na5 10.Bc2 c5 11.Nbd2 Re8 12.Nf1 h6 *","weight":123157,"prior_delay":25,"prior_completion_time":0},
{"oid":835,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Pilnik Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.d3 Na5 10.Bc2 c5 11.Nbd2 Re8 12.Nf1 Bf8 *","weight":123145,"prior_delay":25,"prior_completion_time":0},
{"oid":836,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Pilnik Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.d3 Na5 10.Bc2 c5 11.Nbd2 Qc7 *","weight":123028,"prior_delay":25,"prior_completion_time":0},
{"oid":837,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Pilnik Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.d3 Na5 10.Bc2 c5 11.Nbd2 Nc6 *","weight":123128,"prior_delay":25,"prior_completion_time":0},
{"oid":838,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Pilnik Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.d3 Na5 10.Bc2 c5 11.Nbd2 Re8 12.Nf1 Nc6 *","weight":123143,"prior_delay":25,"prior_completion_time":0},
{"oid":839,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Smyslov Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 h6 10.d4 Re8 11.Nbd2 Bf8 12.Nf1 Bb7 13.Ng3 Na5 14.Bc2 Nc4 *","weight":125591,"prior_delay":25,"prior_completion_time":0},
{"oid":840,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Smyslov Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 h6 10.d4 Re8 11.Nbd2 Bf8 12.Nf1 Bd7 13.Ng3 Na5 14.Bc2 c5 *","weight":125627,"prior_delay":25,"prior_completion_time":0},
{"oid":841,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Smyslov Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 h6 10.d4 Re8 11.Be3 *","weight":125371,"prior_delay":25,"prior_completion_time":0},
{"oid":842,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Smyslov Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 h6 10.d4 Re8 11.a4 *","weight":125369,"prior_delay":25,"prior_completion_time":0},
{"oid":843,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Smyslov Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 h6 10.d4 Re8 11.Nbd2 Bf8 12.Bc2 *","weight":125499,"prior_delay":25,"prior_completion_time":0},
{"oid":844,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Smyslov Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 h6 10.d4 Re8 11.Nbd2 Bf8 12.a3 *","weight":125487,"prior_delay":25,"prior_completion_time":0},
{"oid":845,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Smyslov Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 h6 10.d3 *","weight":125263,"prior_delay":25,"prior_completion_time":0},
{"oid":846,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Suetin Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.a3 *","weight":122336,"prior_delay":25,"prior_completion_time":0},
{"oid":847,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Trajkovic Counterattack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 Bb7 *","weight":119619,"prior_delay":25,"prior_completion_time":0},
{"oid":848,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Worrall Attack, Castling Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Qe2 b5 7.Bb3 O-O 8.c3 d5  9.d3 Bb7 *","weight":105481,"prior_delay":25,"prior_completion_time":0},
{"oid":849,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Worrall Attack, Castling Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Qe2 b5 7.Bb3 O-O 8.c3 d6  9.d4 *","weight":105423,"prior_delay":25,"prior_completion_time":0},
{"oid":850,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Worrall Attack, Castling Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Qe2 b5 7.Bb3 O-O 8.c3 d6  9.Rd1 *","weight":105435,"prior_delay":25,"prior_completion_time":0},
{"oid":851,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Worrall Attack, Delayed Castling Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Qe2 b5 7.Bb3 d6 8.c3 *","weight":105298,"prior_delay":25,"prior_completion_time":0},
{"oid":852,"gid":162,"fullname":"Ruy Lopez, Closed Variations, Worrall Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Qe2 d6 *","weight":105098,"prior_delay":25,"prior_completion_time":0},
{"oid":853,"gid":163,"fullname":"Ruy Lopez, Columbus Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Be7 *","weight":83917,"prior_delay":25,"prior_completion_time":0},
{"oid":854,"gid":164,"fullname":"Ruy Lopez, Cozio Defense, Paulsen Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nge7 4.Nc3 g6 *","weight":65185,"prior_delay":25,"prior_completion_time":0},
{"oid":855,"gid":164,"fullname":"Ruy Lopez, Cozio Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nge7 4.c3 *","weight":65157,"prior_delay":25,"prior_completion_time":0},
{"oid":856,"gid":164,"fullname":"Ruy Lopez, Cozio Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nge7 4.O-O g6 5.c3 *","weight":65228,"prior_delay":25,"prior_completion_time":0},
{"oid":857,"gid":165,"fullname":"Ruy Lopez, Exchange, Alekhine Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.d4 exd4 6.Qxd4 Qxd4 7.Nxd4 Bd7 *","weight":75366,"prior_delay":25,"prior_completion_time":0},
{"oid":858,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Alapin Gambit","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O Bg4 6.h3 h5 7.d3 Qf6 8.Nbd2 *","weight":76656,"prior_delay":25,"prior_completion_time":0},
{"oid":859,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Bronstein Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O Qd6 6.Na3 b5 *","weight":76283,"prior_delay":25,"prior_completion_time":0},
{"oid":860,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Bronstein Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O Qd6 6.Na3 Be6 *","weight":76307,"prior_delay":25,"prior_completion_time":0},
{"oid":861,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Bronstein Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O Qd6 6.d3 *","weight":76193,"prior_delay":25,"prior_completion_time":0},
{"oid":862,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Gligoric Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O f6 6.d4 exd4 7.Qxd4 *","weight":76677,"prior_delay":25,"prior_completion_time":0},
{"oid":863,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Gligoric Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O f6 6.d4 Bg4 7.dxe5 Qxd1  8.Rxd1 fxe5 9.Rd3 Bd6 *","weight":76913,"prior_delay":25,"prior_completion_time":0},
{"oid":864,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Gligoric Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O f6 6.d4 exd4 7.Nxd4 Ne7 *","weight":76840,"prior_delay":25,"prior_completion_time":0},
{"oid":865,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Gligoric Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O f6 6.d4 exd4 7.Nxd4 c5  8.Nb3 Qxd1 9.Rxd1 Bd7 *","weight":77089,"prior_delay":25,"prior_completion_time":0},
{"oid":866,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Gligoric Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O f6 6.d4 Bg4 7.c3 Bd6 *","weight":76742,"prior_delay":25,"prior_completion_time":0},
{"oid":867,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Gligoric Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O f6 6.d4 exd4 7.Nxd4 c5  8.Nb3 Qxd1 9.Rxd1 Bg4 10.f3 Be6 *","weight":77175,"prior_delay":25,"prior_completion_time":0},
{"oid":868,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Gligoric Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O f6 6.d4 exd4 7.Nxd4 c5  8.Ne2 *","weight":77067,"prior_delay":25,"prior_completion_time":0},
{"oid":869,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Keres Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.Nc3 f6 6.d4 *","weight":75369,"prior_delay":25,"prior_completion_time":0},
{"oid":870,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, King's Bishop Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O Bd6 6.d4 exd4 *","weight":76235,"prior_delay":25,"prior_completion_time":0},
{"oid":871,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Lutikov Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 bxc6 *","weight":74511,"prior_delay":25,"prior_completion_time":0},
{"oid":872,"gid":166,"fullname":"Ruy Lopez, Exchange Variation, Normal Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Bxc6 dxc6 5.O-O Ne7 *","weight":76106,"prior_delay":25,"prior_completion_time":0},
{"oid":873,"gid":167,"fullname":"Ruy Lopez, Fianchetto Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 g6 4.d4 exd4 5.Bg5 *","weight":65382,"prior_delay":25,"prior_completion_time":0},
{"oid":874,"gid":167,"fullname":"Ruy Lopez, Fianchetto Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 g6 4.c3 *","weight":65310,"prior_delay":25,"prior_completion_time":0},
{"oid":875,"gid":167,"fullname":"Ruy Lopez, Fianchetto Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 g6 4.O-O *","weight":65244,"prior_delay":25,"prior_completion_time":0},
{"oid":876,"gid":168,"fullname":"Ruy Lopez, Marshall, Main Line, Spassky Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d5  9.exd5 Nxd5 10.Nxe5 Nxe5 11.Rxe5 c6 12.d4 Bd6 13.Re1 Qh4 14.g3 Qh3  15.Be3 Bg4 16.Qd3 Rae8 17.Nd2 Re6 18.a4 Qh5 *","weight":125292,"prior_delay":25,"prior_completion_time":0},
{"oid":877,"gid":169,"fullname":"Ruy Lopez, Marshall Attack, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d5  9.exd5 Nxd5 10.Nxe5 Nxe5 11.Rxe5 c6 12.d4 Bd6 13.Re2 *","weight":124591,"prior_delay":25,"prior_completion_time":0},
{"oid":878,"gid":169,"fullname":"Ruy Lopez, Marshall Attack, Modern Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d5  9.exd5 Nxd5 10.Nxe5 Nxe5 11.Rxe5 c6 12.d4 Bd6 13.Re1 Qh4 14.g3 Qh3  15.Be3 Bg4 16.Qd3 Rae8 17.Nd2 f5 *","weight":125273,"prior_delay":25,"prior_completion_time":0},
{"oid":879,"gid":169,"fullname":"Ruy Lopez, Marshall Attack, Modern Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d5  9.exd5 Nxd5 10.Nxe5 Nxe5 11.Rxe5 c6 12.d4 Bd6 13.Re1 Qh4 14.g3 Qh3 15.Re4 *","weight":125207,"prior_delay":25,"prior_completion_time":0},
{"oid":880,"gid":169,"fullname":"Ruy Lopez, Marshall Attack, Modern Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d5  9.exd5 Nxd5 10.Nxe5 Nxe5 11.Rxe5 c6 12.d4 Bd6 13.Re1 Qh4 14.g3 Qh3  15.Be3 Bg4 16.Qd3 Rae8 17.Nd2 Re6 18.Qf1 *","weight":125284,"prior_delay":25,"prior_completion_time":0},
{"oid":881,"gid":169,"fullname":"Ruy Lopez, Marshall Attack, Modern Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d5  9.exd5 Nxd5 10.Nxe5 Nxe5 11.Rxe5 c6 12.d3 Bd6 13.Re1 Qh4 *","weight":124728,"prior_delay":25,"prior_completion_time":0},
{"oid":882,"gid":169,"fullname":"Ruy Lopez, Marshall Attack, Re3 Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d5  9.exd5 Nxd5 10.Nxe5 Nxe5 11.Rxe5 c6 12.Bxd5 cxd5 13.d4 Bd6 14.Re3 *","weight":124356,"prior_delay":25,"prior_completion_time":0},
{"oid":883,"gid":169,"fullname":"Ruy Lopez, Marshall Attack, Steiner Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d5  9.exd5 e4 *","weight":122761,"prior_delay":25,"prior_completion_time":0},
{"oid":884,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Anderssen Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.d3 d6 6.c3 g6 *","weight":92320,"prior_delay":25,"prior_completion_time":0},
{"oid":885,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Anderssen Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.d3 b5 *","weight":91877,"prior_delay":25,"prior_completion_time":0},
{"oid":886,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Arkhangelsk Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O b5 6.Bb3 Bb7 7.d3 Be7 *","weight":102281,"prior_delay":25,"prior_completion_time":0},
{"oid":887,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Arkhangelsk Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O b5 6.Bb3 Bb7 7.c3 Nxe4 *","weight":102039,"prior_delay":25,"prior_completion_time":0},
{"oid":888,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Arkhangelsk Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O b5 6.Bb3 Bb7 7.d4 *","weight":101974,"prior_delay":25,"prior_completion_time":0},
{"oid":889,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Arkhangelsk Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O b5 6.Bb3 Bb7 7.Re1 Bc5 8.c3 d6  9.d4 Bb6 10.a4 *","weight":102274,"prior_delay":25,"prior_completion_time":0},
{"oid":890,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Arkhangelsk Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O b5 6.Bb3 Bb7 7.Re1 Bc5 8.c3 d6  9.d4 Bb6 10.Bg5 *","weight":102271,"prior_delay":25,"prior_completion_time":0},
{"oid":891,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Arkhangelsk Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O b5 6.Bb3 Bb7 7.Re1 Bc5 8.c3 d6  9.d4 Bb6 10.Be3 *","weight":102305,"prior_delay":25,"prior_completion_time":0},
{"oid":892,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Bayreuth Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.Bxc6 *","weight":91206,"prior_delay":25,"prior_completion_time":0},
{"oid":893,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Breyer Defense, Quiet Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nb8 10.d3 Nbd7 11.Nbd2 Bb7 *","weight":125937,"prior_delay":25,"prior_completion_time":0},
{"oid":894,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Breyer Defense, Zaitsev Hybrid","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nb8 10.d4 Nbd7 11.Nbd2 Bb7 12.Bc2 Re8 13.Nf1 Bf8 14.Ng3 g6 15.a4 c5  16.d5 c4 *","weight":131688,"prior_delay":25,"prior_completion_time":0},
{"oid":895,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Breyer Defense, Zaitsev Hybrid","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nb8 10.d4 Nbd7 11.Nbd2 Bb7 12.Bc2 Re8 13.Nf1 Bf8 14.Ng3 c5 *","weight":130893,"prior_delay":25,"prior_completion_time":0},
{"oid":896,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Breyer Defense, Zaitsev Hybrid","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nb8 10.d4 Nbd7 11.c4 c6 *","weight":127326,"prior_delay":25,"prior_completion_time":0},
{"oid":897,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Breyer Defense, Zaitsev Hybrid","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nb8 10.d4 Nbd7 11.Bg5 *","weight":127235,"prior_delay":25,"prior_completion_time":0},
{"oid":898,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Breyer Defense, Zaitsev Hybrid","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nb8 10.d4 Nbd7 11.Nbd2 Bb7 12.Bc2 Re8 13.b4 *","weight":129644,"prior_delay":25,"prior_completion_time":0},
{"oid":899,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Breyer Defense, Zaitsev Hybrid","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nb8 10.d4 Nbd7 11.Nbd2 Bb7 12.a4 *","weight":128504,"prior_delay":25,"prior_completion_time":0},
{"oid":900,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Breyer Defense, Zaitsev Hybrid","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nb8 10.d4 Nbd7 11.Nbd2 Bb7 12.Bc2 Re8 13.a4 *","weight":129762,"prior_delay":25,"prior_completion_time":0},
{"oid":901,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Breyer Defense, Zaitsev Hybrid","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Nb8 10.d4 Nbd7 11.Nbd2 Bb7 12.Bc2 Re8 13.Nf1 Bf8 14.Ng3 g6 15.b3 *","weight":131317,"prior_delay":25,"prior_completion_time":0},
{"oid":902,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Chigorin Defense, Panov System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 cxd4 13.cxd4 Bb7 14.d5 Rac8 *","weight":129632,"prior_delay":25,"prior_completion_time":0},
{"oid":903,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Chigorin Defense, Panov System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 cxd4 13.cxd4 Nc6 14.Nb3 a5 15.Be3 a4  16.Nbd2 Nb4 *","weight":130236,"prior_delay":25,"prior_completion_time":0},
{"oid":904,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Chigorin Defense, Panov System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 cxd4 13.cxd4 Rd8 *","weight":129644,"prior_delay":25,"prior_completion_time":0},
{"oid":905,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Chigorin Defense, Panov System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 cxd4 13.cxd4 Nc6 14.Nb3 a5 15.Be3 a4  16.Nbd2 Bd7 *","weight":130312,"prior_delay":25,"prior_completion_time":0},
{"oid":906,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Chigorin Defense, Panov System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 cxd4 13.cxd4 Nc6 14.d5 *","weight":129723,"prior_delay":25,"prior_completion_time":0},
{"oid":907,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Chigorin Defense, Panov System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 cxd4 13.cxd4 Bb7 14.Nf1 Rac8 15.Re2 *","weight":129622,"prior_delay":25,"prior_completion_time":0},
{"oid":908,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Chigorin Defense, Panov System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 cxd4 13.cxd4 Nc6 14.a3 *","weight":129746,"prior_delay":25,"prior_completion_time":0},
{"oid":909,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Chigorin Defense, Panov System","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Be7 6.Re1 b5 7.Bb3 O-O 8.c3 d6  9.h3 Na5 10.Bc2 c5 11.d4 Qc7 12.Nbd2 cxd4 13.cxd4 Bd7 14.Nf1 Rac8 15.Ne3 *","weight":129691,"prior_delay":25,"prior_completion_time":0},
{"oid":910,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Classical Defense Deferred","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Bc5 *","weight":83915,"prior_delay":25,"prior_completion_time":0},
{"oid":911,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Cozio Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nge7 *","weight":84015,"prior_delay":25,"prior_completion_time":0},
{"oid":912,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Duras Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.d3 d6 6.c4 *","weight":92004,"prior_delay":25,"prior_completion_time":0},
{"oid":913,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Fianchetto Defense Deferred","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 g6 *","weight":83943,"prior_delay":25,"prior_completion_time":0},
{"oid":914,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Mackenzie Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.d4 Nxd4 *","weight":91215,"prior_delay":25,"prior_completion_time":0},
{"oid":915,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Mackenzie Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.d4 Nxe4 *","weight":91214,"prior_delay":25,"prior_completion_time":0},
{"oid":916,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Mackenzie Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.d4 exd4 *","weight":91234,"prior_delay":25,"prior_completion_time":0},
{"oid":917,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense, Fianchetto Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.c3 Bd7 6.d4 g6 7.O-O Bg7 8.dxe5 dxe5 *","weight":85169,"prior_delay":25,"prior_completion_time":0},
{"oid":918,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense, Fianchetto Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.c3 Bd7 6.d4 g6 7.O-O Bg7 8.dxe5 Nxe5 *","weight":85168,"prior_delay":25,"prior_completion_time":0},
{"oid":919,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense, Fianchetto Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.c3 Bd7 6.d4 g6 7.O-O Bg7 8.d5 *","weight":85185,"prior_delay":25,"prior_completion_time":0},
{"oid":920,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense, Fianchetto Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.c3 Bd7 6.d4 g6 7.O-O Bg7 8.Re1 *","weight":85235,"prior_delay":25,"prior_completion_time":0},
{"oid":921,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.O-O Ne7 *","weight":84597,"prior_delay":25,"prior_completion_time":0},
{"oid":922,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.Bxc6+ bxc6 6.d4 f6 *","weight":84553,"prior_delay":25,"prior_completion_time":0},
{"oid":923,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.c3 g6 *","weight":84568,"prior_delay":25,"prior_completion_time":0},
{"oid":924,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.c3 Bd7 6.d4 Nf6 *","weight":84793,"prior_delay":25,"prior_completion_time":0},
{"oid":925,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.c3 f5 6.exf5 Bxf5 7.O-O Bd3  8.Re1 Be7 9.Bc2 Bxc2 10.Qxc2 *","weight":84861,"prior_delay":25,"prior_completion_time":0},
{"oid":926,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.c4 *","weight":84341,"prior_delay":25,"prior_completion_time":0},
{"oid":927,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.O-O Bd7 6.d4 *","weight":84797,"prior_delay":25,"prior_completion_time":0},
{"oid":928,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.c3 Bd7 6.O-O *","weight":84713,"prior_delay":25,"prior_completion_time":0},
{"oid":929,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.c3 Bd7 6.d4 Nge7 7.Bb3 *","weight":84813,"prior_delay":25,"prior_completion_time":0},
{"oid":930,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.O-O Bg4 6.h3 *","weight":84647,"prior_delay":25,"prior_completion_time":0},
{"oid":931,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Modern Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 d6 5.c3 Bd7 6.d4 Nge7 7.Be3 *","weight":84813,"prior_delay":25,"prior_completion_time":0},
{"oid":932,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Neo-Arkhangelsk Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Bc5 6.Nxe5 *","weight":100108,"prior_delay":25,"prior_completion_time":0},
{"oid":933,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Neo-Arkhangelsk Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Bc5 6.c3 *","weight":100325,"prior_delay":25,"prior_completion_time":0},
{"oid":934,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Norwegian Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 b5 5.Bb3 Na5 *","weight":84012,"prior_delay":25,"prior_completion_time":0},
{"oid":935,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Schliemann Defense Deferred","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 f5 5.d4 exd4 6.e5 *","weight":83935,"prior_delay":25,"prior_completion_time":0},
{"oid":936,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Steinitz Deferred","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O d6 6.Re1 *","weight":99932,"prior_delay":25,"prior_completion_time":0},
{"oid":937,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Steinitz Deferred","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O d6 6.c3 *","weight":99920,"prior_delay":25,"prior_completion_time":0},
{"oid":938,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Tarrasch Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.Nc3 *","weight":91249,"prior_delay":25,"prior_completion_time":0},
{"oid":939,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Wormald Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.Qe2 Be7 *","weight":91352,"prior_delay":25,"prior_completion_time":0},
{"oid":940,"gid":170,"fullname":"Ruy Lopez, Morphy Defense, Wormald Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.Qe2 b5 6.Bb3 Bc5 7.c3 *","weight":91522,"prior_delay":25,"prior_completion_time":0},
{"oid":941,"gid":170,"fullname":"Ruy Lopez, Morphy Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O b5 6.Bb3 Bc5 7.a4 Rb8 *","weight":102734,"prior_delay":25,"prior_completion_time":0},
{"oid":942,"gid":170,"fullname":"Ruy Lopez, Morphy Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O b5 6.Bb3 Bc5 7.Nxe5 Nxe5 8.d4 *","weight":102248,"prior_delay":25,"prior_completion_time":0},
{"oid":943,"gid":170,"fullname":"Ruy Lopez, Morphy Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O b5 6.Bb3 Bc5 7.c3 d6 8.d4 *","weight":102838,"prior_delay":25,"prior_completion_time":0},
{"oid":944,"gid":170,"fullname":"Ruy Lopez, Morphy Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O b5 6.Bb3 Be7 7.d4 *","weight":101771,"prior_delay":25,"prior_completion_time":0},
{"oid":945,"gid":171,"fullname":"Ruy Lopez, Open, Howell Attack, Ekstrom Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.Qe2 Be7 10.Rd1 O-O 11.c4 bxc4 12.Bxc4 Qd7 *","weight":104545,"prior_delay":25,"prior_completion_time":0},
{"oid":946,"gid":171,"fullname":"Ruy Lopez, Open, Motzko Attack, Nenarokov Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Bc5 10.Qd3 Ne7 *","weight":104474,"prior_delay":25,"prior_completion_time":0},
{"oid":947,"gid":171,"fullname":"Ruy Lopez, Open","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 Be7 *","weight":101229,"prior_delay":25,"prior_completion_time":0},
{"oid":948,"gid":172,"fullname":"Ruy Lopez, Open Berlin Defense, l'Hermet Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Nd6 6.dxe5 *","weight":77377,"prior_delay":25,"prior_completion_time":0},
{"oid":949,"gid":172,"fullname":"Ruy Lopez, Open Berlin Defense, Showalter Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 Nf6 4.O-O Nxe4 5.d4 Nd6 6.Ba4 *","weight":77250,"prior_delay":25,"prior_completion_time":0},
{"oid":950,"gid":173,"fullname":"Ruy Lopez, Open Variations, Berlin Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Nc5 10.Bc2 Bg4 11.Nbd2 Be7 12.Re1 O-O *","weight":104495,"prior_delay":25,"prior_completion_time":0},
{"oid":951,"gid":173,"fullname":"Ruy Lopez, Open Variations, Berlin Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Nc5 10.Bc2 Bg4 11.Nbd2 Be7 12.Re1 Qd7 *","weight":104485,"prior_delay":25,"prior_completion_time":0},
{"oid":952,"gid":173,"fullname":"Ruy Lopez, Open Variations, Berlin Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Nc5 10.Bc2 Bg4 11.Re1 *","weight":104423,"prior_delay":25,"prior_completion_time":0},
{"oid":953,"gid":173,"fullname":"Ruy Lopez, Open Variations, Bernstein Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.Nbd2 Bc5 *","weight":104722,"prior_delay":25,"prior_completion_time":0},
{"oid":954,"gid":173,"fullname":"Ruy Lopez, Open Variations, Bernstein Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.Nbd2 Nc5 10.c3 d4 11.cxd4 *","weight":105215,"prior_delay":25,"prior_completion_time":0},
{"oid":955,"gid":173,"fullname":"Ruy Lopez, Open Variations, Bernstein Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.Nbd2 Nc5 10.c3 d4 11.Bxe6 *","weight":105276,"prior_delay":25,"prior_completion_time":0},
{"oid":956,"gid":173,"fullname":"Ruy Lopez, Open Variations, Breslau Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Be7 10.Re1 O-O 11.Nd4 Nxe5 *","weight":104457,"prior_delay":25,"prior_completion_time":0},
{"oid":957,"gid":173,"fullname":"Ruy Lopez, Open Variations, Classical Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Be7 10.Nbd2 Nc5 *","weight":104475,"prior_delay":25,"prior_completion_time":0},
{"oid":958,"gid":173,"fullname":"Ruy Lopez, Open Variations, Classical Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Be7 10.Nbd2 O-O 11.Bc2 f5 12.exf6 *","weight":104666,"prior_delay":25,"prior_completion_time":0},
{"oid":959,"gid":173,"fullname":"Ruy Lopez, Open Variations, Classical Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Be7 10.Be3 *","weight":104432,"prior_delay":25,"prior_completion_time":0},
{"oid":960,"gid":173,"fullname":"Ruy Lopez, Open Variations, Classical Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Be7 10.Nbd2 O-O 11.Bc2 f5 12.Nb3 *","weight":104707,"prior_delay":25,"prior_completion_time":0},
{"oid":961,"gid":173,"fullname":"Ruy Lopez, Open Variations, Dilworth Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Bc5 10.Nbd2 O-O 11.Bc2 Nxf2 12.Rxf2 f6 13.exf6 Bxf2+ 14.Kxf2 Qxf6  15.Nf1 *","weight":104515,"prior_delay":25,"prior_completion_time":0},
{"oid":962,"gid":173,"fullname":"Ruy Lopez, Open Variations, Howell Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.Qe2 Bc5 *","weight":104364,"prior_delay":25,"prior_completion_time":0},
{"oid":963,"gid":173,"fullname":"Ruy Lopez, Open Variations, Howell Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.Qe2 Be7 10.Rd1 O-O 11.c4 bxc4 12.Bxc4 Bc5 *","weight":104559,"prior_delay":25,"prior_completion_time":0},
{"oid":964,"gid":173,"fullname":"Ruy Lopez, Open Variations, Howell Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.Qe2 Be7 10.Rd1 O-O 11.c3 *","weight":104451,"prior_delay":25,"prior_completion_time":0},
{"oid":965,"gid":173,"fullname":"Ruy Lopez, Open Variations, Karpov Gambit","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.Nbd2 Nc5 10.c3 d4 11.Ng5 *","weight":105235,"prior_delay":25,"prior_completion_time":0},
{"oid":966,"gid":173,"fullname":"Ruy Lopez, Open Variations, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.a4 *","weight":104298,"prior_delay":25,"prior_completion_time":0},
{"oid":967,"gid":173,"fullname":"Ruy Lopez, Open Variations, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.Be3 *","weight":104463,"prior_delay":25,"prior_completion_time":0},
{"oid":968,"gid":173,"fullname":"Ruy Lopez, Open Variations, Malkin Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Be7 10.Nbd2 O-O 11.Qe2 *","weight":104527,"prior_delay":25,"prior_completion_time":0},
{"oid":969,"gid":173,"fullname":"Ruy Lopez, Open Variations, Open Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.Re1 Nc5 7.Bxc6 *","weight":100458,"prior_delay":25,"prior_completion_time":0},
{"oid":970,"gid":173,"fullname":"Ruy Lopez, Open Variations, Riga Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 exd4 *","weight":101214,"prior_delay":25,"prior_completion_time":0},
{"oid":971,"gid":173,"fullname":"Ruy Lopez, Open Variations, St. Petersburg Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Bc5 10.Nbd2 O-O 11.Bc2 f5 *","weight":104479,"prior_delay":25,"prior_completion_time":0},
{"oid":972,"gid":173,"fullname":"Ruy Lopez, Open Variations, St. Petersburg Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.dxe5 Be6  9.c3 Bc5 10.Nbd2 O-O 11.Bc2 Bf5 12.Nb3 *","weight":104494,"prior_delay":25,"prior_completion_time":0},
{"oid":973,"gid":173,"fullname":"Ruy Lopez, Open Variations","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O Nxe4 6.d4 b5 7.Bb3 d5 8.Nxe5 *","weight":103023,"prior_delay":25,"prior_completion_time":0},
{"oid":974,"gid":174,"fullname":"Ruy Lopez, Schliemann Defense, Classical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 f5 4.Nc3 fxe4 5.Nxe4 d5 6.Nxe5 dxe4 7.Nxc6 Qg5 *","weight":65528,"prior_delay":25,"prior_completion_time":0},
{"oid":975,"gid":174,"fullname":"Ruy Lopez, Schliemann Defense, Dyckhoff Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 f5 4.Nc3 Nd4 5.Ba4 *","weight":65309,"prior_delay":25,"prior_completion_time":0},
{"oid":976,"gid":174,"fullname":"Ruy Lopez, Schliemann Defense, Dyckhoff Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 f5 4.Nc3 Nf6 5.exf5 *","weight":65322,"prior_delay":25,"prior_completion_time":0},
{"oid":977,"gid":174,"fullname":"Ruy Lopez, Schliemann Defense, Exchange Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 f5 4.Bxc6 dxc6 *","weight":65196,"prior_delay":25,"prior_completion_time":0},
{"oid":978,"gid":174,"fullname":"Ruy Lopez, Schliemann Defense, M\u00c3\u00b6hring Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 f5 4.Nc3 fxe4 5.Nxe4 d5 6.Nxe5 dxe4 7.Nxc6 Qd5 *","weight":65522,"prior_delay":25,"prior_completion_time":0},
{"oid":979,"gid":174,"fullname":"Ruy Lopez, Schliemann Defense, Sch\u00c3\u00b6nemann Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 f5 4.d4 *","weight":65194,"prior_delay":25,"prior_completion_time":0},
{"oid":980,"gid":174,"fullname":"Ruy Lopez, Schliemann Defense, Tartakower Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 f5 4.Nc3 fxe4 5.Nxe4 Nf6 6.Qe2 *","weight":65559,"prior_delay":25,"prior_completion_time":0},
{"oid":981,"gid":174,"fullname":"Ruy Lopez, Schliemann Defense, Tartakower Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 f5 4.Nc3 fxe4 5.Nxe4 Nf6 6.Nxf6+ *","weight":65576,"prior_delay":25,"prior_completion_time":0},
{"oid":982,"gid":174,"fullname":"Ruy Lopez, Schliemann Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 f5 4.Qe2 *","weight":65189,"prior_delay":25,"prior_completion_time":0},
{"oid":983,"gid":174,"fullname":"Ruy Lopez, Schliemann Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 f5 4.d3 fxe4 5.dxe4 Nf6 6.O-O *","weight":65604,"prior_delay":25,"prior_completion_time":0},
{"oid":984,"gid":175,"fullname":"Ruy Lopez, Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 d6 4.O-O *","weight":65066,"prior_delay":25,"prior_completion_time":0},
{"oid":985,"gid":175,"fullname":"Ruy Lopez, Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 d6 4.d4 exd4 5.Qxd4 *","weight":65082,"prior_delay":25,"prior_completion_time":0},
{"oid":986,"gid":175,"fullname":"Ruy Lopez, Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 d6 4.c3 *","weight":65058,"prior_delay":25,"prior_completion_time":0},
{"oid":987,"gid":175,"fullname":"Ruy Lopez, Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 d6 4.d4 exd4 5.Nxd4 *","weight":65092,"prior_delay":25,"prior_completion_time":0},
{"oid":988,"gid":176,"fullname":"Ruy Lopez, Steinitz Defense Deferred, Lipnitsky Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.O-O d6 6.Bxc6+ bxc6 7.d4 Bg4 *","weight":99938,"prior_delay":25,"prior_completion_time":0},
{"oid":989,"gid":177,"fullname":"Ruy Lopez, Wormald Attack, Grunfeld Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bb5 a6 4.Ba4 Nf6 5.Qe2 b5 6.Bb3 Be7 7.d4 d6 8.c3 Bg4 *","weight":91532,"prior_delay":25,"prior_completion_time":0},
{"oid":990,"gid":178,"fullname":"Scandinavian Defense, Bronstein Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qd6 4.d4 Nf6 5.Nf3 a6 *","weight":17516,"prior_delay":25,"prior_completion_time":0},
{"oid":991,"gid":179,"fullname":"Scandinavian Defense, Classical Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.d4 Nf6 5.Nf3 Bf5 6.Bc4 *","weight":16879,"prior_delay":25,"prior_completion_time":0},
{"oid":992,"gid":180,"fullname":"Scandinavian Defense, Grunfeld Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.d4 Nf6 5.Nf3 Bf5 6.Ne5 c6 7.g4 *","weight":16878,"prior_delay":25,"prior_completion_time":0},
{"oid":993,"gid":181,"fullname":"Scandinavian Defense, Icelandic-Palme Gambit","main_line":"1.e4 d5 2.exd5 Nf6 3.c4 e6 4.dxe6 Bxe6 5.Nf3 *","weight":14273,"prior_delay":25,"prior_completion_time":0},
{"oid":994,"gid":182,"fullname":"Scandinavian Defense, Lasker Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.d4 Nf6 5.Nf3 Bg4 6.h3 *","weight":16821,"prior_delay":25,"prior_completion_time":0},
{"oid":995,"gid":183,"fullname":"Scandinavian Defense, Main Line, Mieses Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.d4 Nf6 5.Nf3 c6 6.Bc4 Bg4 *","weight":16957,"prior_delay":25,"prior_completion_time":0},
{"oid":996,"gid":183,"fullname":"Scandinavian Defense, Main Line, Mieses Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.d4 Nf6 5.Bd2 c6 *","weight":16695,"prior_delay":25,"prior_completion_time":0},
{"oid":997,"gid":183,"fullname":"Scandinavian Defense, Main Line, Mieses Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.d4 Nf6 5.Bc4 c6 *","weight":16715,"prior_delay":25,"prior_completion_time":0},
{"oid":998,"gid":183,"fullname":"Scandinavian Defense, Main Line, Mieses Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.d4 Nf6 5.Nf3 c6 6.Bc4 Bf5 7.Bd2 e6 8.Qe2  Bb4 9.a3 *","weight":17224,"prior_delay":25,"prior_completion_time":0},
{"oid":999,"gid":183,"fullname":"Scandinavian Defense, Main Line, Mieses Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.d4 Nf6 5.Nf3 c6 6.Bc4 Bf5 7.Bd2 e6 8.Nd5 *","weight":17238,"prior_delay":25,"prior_completion_time":0},
{"oid":1000,"gid":183,"fullname":"Scandinavian Defense, Main Line, Mieses Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.d4 Nf6 5.Nf3 c6 6.Bc4 Bf5 7.Bd2 e6 8.Qe2  Bb4 9.O-O-O *","weight":17235,"prior_delay":25,"prior_completion_time":0},
{"oid":1001,"gid":183,"fullname":"Scandinavian Defense, Main Line","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.Bc4 Nf6 *","weight":16196,"prior_delay":25,"prior_completion_time":0},
{"oid":1002,"gid":183,"fullname":"Scandinavian Defense, Main Line","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.g3 *","weight":16194,"prior_delay":25,"prior_completion_time":0},
{"oid":1003,"gid":183,"fullname":"Scandinavian Defense, Main Line","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.Nf3 Nf6 5.Be2 *","weight":16242,"prior_delay":25,"prior_completion_time":0},
{"oid":1004,"gid":183,"fullname":"Scandinavian Defense, Main Line","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.d4 c6 5.Bc4 *","weight":16569,"prior_delay":25,"prior_completion_time":0},
{"oid":1005,"gid":183,"fullname":"Scandinavian Defense, Main Line","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qa5 4.d4 c6 5.Nf3 *","weight":16574,"prior_delay":25,"prior_completion_time":0},
{"oid":1006,"gid":184,"fullname":"Scandinavian Defense, Marshall Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.d4 Nxd5 4.c4 Nb6 5.Nf3 g6 6.Nc3 Bg7 *","weight":14389,"prior_delay":25,"prior_completion_time":0},
{"oid":1007,"gid":184,"fullname":"Scandinavian Defense, Marshall Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.d4 Nxd5 4.Nf3 Bf5 *","weight":14364,"prior_delay":25,"prior_completion_time":0},
{"oid":1008,"gid":184,"fullname":"Scandinavian Defense, Marshall Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.d4 Nxd5 4.c4 Nb6 5.Nc3 e5 *","weight":14366,"prior_delay":25,"prior_completion_time":0},
{"oid":1009,"gid":184,"fullname":"Scandinavian Defense, Marshall Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.d4 Nxd5 4.c4 Nb6 5.Nf3 Bg4 6.c5 *","weight":14369,"prior_delay":25,"prior_completion_time":0},
{"oid":1010,"gid":185,"fullname":"Scandinavian Defense, Mieses-Kotroc Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.d4 Nf6 *","weight":14945,"prior_delay":25,"prior_completion_time":0},
{"oid":1011,"gid":185,"fullname":"Scandinavian Defense, Mieses-Kotroc Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nc3 Qd8 *","weight":15942,"prior_delay":25,"prior_completion_time":0},
{"oid":1012,"gid":185,"fullname":"Scandinavian Defense, Mieses-Kotroc Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.Nf3 Nf6 *","weight":15060,"prior_delay":25,"prior_completion_time":0},
{"oid":1013,"gid":185,"fullname":"Scandinavian Defense, Mieses-Kotroc Variation","main_line":"1.e4 d5 2.exd5 Qxd5 3.d4 e5 *","weight":14948,"prior_delay":25,"prior_completion_time":0},
{"oid":1014,"gid":186,"fullname":"Scandinavian Defense, Modern Variation, Gipslis Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.d4 Nxd5 4.Nf3 Bg4 5.Be2 e6 *","weight":14377,"prior_delay":25,"prior_completion_time":0},
{"oid":1015,"gid":186,"fullname":"Scandinavian Defense, Modern Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.Nf3 Nxd5 *","weight":14307,"prior_delay":25,"prior_completion_time":0},
{"oid":1016,"gid":186,"fullname":"Scandinavian Defense, Modern Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.Bb5+ Nbd7 *","weight":14267,"prior_delay":25,"prior_completion_time":0},
{"oid":1017,"gid":186,"fullname":"Scandinavian Defense, Modern Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.Bb5+ Bd7 4.Be2 Nxd5 5.d4 Bf5 *","weight":14292,"prior_delay":25,"prior_completion_time":0},
{"oid":1018,"gid":186,"fullname":"Scandinavian Defense, Modern Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.Bb5+ Bd7 4.Bc4 Bg4 *","weight":14277,"prior_delay":25,"prior_completion_time":0},
{"oid":1019,"gid":187,"fullname":"Scandinavian Defense, Panov Transfer","main_line":"1.e4 d5 2.exd5 Nf6 3.c4 c6 4.dxc6 *","weight":14270,"prior_delay":25,"prior_completion_time":0},
{"oid":1020,"gid":188,"fullname":"Scandinavian Defense, Portuguese Variation, Portuguese Gambit","main_line":"1.e4 d5 2.exd5 Nf6 3.d4 Bg4 4.f3 Bf5 5.Bb5+ Nbd7 6.c4 *","weight":14330,"prior_delay":25,"prior_completion_time":0},
{"oid":1021,"gid":188,"fullname":"Scandinavian Defense, Portuguese Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.d4 Bg4 4.Nf3 Qxd5 5.Be2 Nc6 *","weight":14354,"prior_delay":25,"prior_completion_time":0},
{"oid":1022,"gid":188,"fullname":"Scandinavian Defense, Portuguese Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.d4 Bg4 4.Be2 Bxe2 *","weight":14325,"prior_delay":25,"prior_completion_time":0},
{"oid":1023,"gid":188,"fullname":"Scandinavian Defense, Portuguese Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.d4 Bg4 4.f3 Bf5 5.c4 *","weight":14329,"prior_delay":25,"prior_completion_time":0},
{"oid":1024,"gid":189,"fullname":"Scandinavian Defense, Richter Variation","main_line":"1.e4 d5 2.exd5 Nf6 3.d4 Nxd5 4.Nf3 g6 5.Be2 Bg7 *","weight":14393,"prior_delay":25,"prior_completion_time":0},
{"oid":1025,"gid":190,"fullname":"Scotch Game, Blumenfeld Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Nxd4 Bc5 5.Be3 Qf6 6.Nb5 *","weight":54996,"prior_delay":25,"prior_completion_time":0},
{"oid":1026,"gid":191,"fullname":"Scotch Game, Classical Variation, Blackburne Attack","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Nxd4 Bc5 5.Be3 Qf6 6.c3 Nge7 7.Qd2 *","weight":55513,"prior_delay":25,"prior_completion_time":0},
{"oid":1027,"gid":191,"fullname":"Scotch Game, Classical Variation, Intermezzo Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Nxd4 Bc5 5.Nxc6 Qf6 6.Qd2 dxc6 7.Nc3 *","weight":55666,"prior_delay":25,"prior_completion_time":0},
{"oid":1028,"gid":191,"fullname":"Scotch Game, Classical Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Nxd4 Bc5 5.Be3 Qf6 6.c3 Nge7 7.Bc4 Ne5 *","weight":55773,"prior_delay":25,"prior_completion_time":0},
{"oid":1029,"gid":192,"fullname":"Scotch Game, G\u00c3\u00b6ring Gambit","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.c3 d3 *","weight":51900,"prior_delay":25,"prior_completion_time":0},
{"oid":1030,"gid":193,"fullname":"Scotch Game, Haxo Gambit","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Bc4 Bc5 5.c3 d3 *","weight":51992,"prior_delay":25,"prior_completion_time":0},
{"oid":1031,"gid":195,"fullname":"Scotch Game, Malaniuk Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Nxd4 Bb4+ *","weight":53835,"prior_delay":25,"prior_completion_time":0},
{"oid":1032,"gid":196,"fullname":"Scotch Game, Meitner Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Nxd4 Bc5 5.Be3 Qf6 6.c3 Nge7 7.Nc2 *","weight":55514,"prior_delay":25,"prior_completion_time":0},
{"oid":1033,"gid":197,"fullname":"Scotch Game, Mieses Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Nxd4 Nf6 5.Nxc6 bxc6 6.e5 Qe7 7.Qe2 Nd5  8.c4 Ba6 9.b3 *","weight":58020,"prior_delay":25,"prior_completion_time":0},
{"oid":1034,"gid":198,"fullname":"Scotch Game, Romanishin Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Nxd4 Bc5 5.Nb3 Bb4+ *","weight":54724,"prior_delay":25,"prior_completion_time":0},
{"oid":1035,"gid":199,"fullname":"Scotch Game, Schmidt Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Nxd4 Nf6 5.Nxc6 bxc6 6.Bd3 *","weight":55444,"prior_delay":25,"prior_completion_time":0},
{"oid":1036,"gid":200,"fullname":"Scotch Game, Scotch Gambit, Advance Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.Bc4 Nf6 4.d4 exd4 5.e5 d5 6.Bb5 Ne4 7.Nxd4 Bd7  8.Bxc6 bxc6 9.O-O Bc5 *","weight":52595,"prior_delay":25,"prior_completion_time":0},
{"oid":1037,"gid":200,"fullname":"Scotch Game, Scotch Gambit, G\u00c3\u00b6ring Gambit Declined","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.c3 d5 *","weight":51904,"prior_delay":25,"prior_completion_time":0},
{"oid":1038,"gid":201,"fullname":"Scotch Game, Tartakower Variation","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Nxd4 Nf6 5.Nxc6 bxc6 6.Nd2 *","weight":55427,"prior_delay":25,"prior_completion_time":0},
{"oid":1039,"gid":194,"fullname":"Scotch Game, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 d6 *","weight":50311,"prior_delay":25,"prior_completion_time":0},
{"oid":1040,"gid":194,"fullname":"Scotch Game, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.d4 exd4 4.Nxd4 Qf6 *","weight":53785,"prior_delay":25,"prior_completion_time":0},
{"oid":1041,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Exchange Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nxc6 *","weight":57641,"prior_delay":25,"prior_completion_time":0},
{"oid":1042,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind, Breyer Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 Ng4  8.Qxg4 Nxd4 9.Qd1 e5 10.Nb5 *","weight":59576,"prior_delay":25,"prior_completion_time":0},
{"oid":1043,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind, Breyer Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 Ng4  8.Qxg4 Nxd4 9.Qd1 Ne6 10.Rc1 Qa5 11.Qd2 *","weight":59650,"prior_delay":25,"prior_completion_time":0},
{"oid":1044,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind, Breyer Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 Ng4  8.Qxg4 Nxd4 9.Qd1 Ne6 10.Qd2 *","weight":59596,"prior_delay":25,"prior_completion_time":0},
{"oid":1045,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind, Breyer Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 Ng4  8.Qxg4 Nxd4 9.Qd1 e5 10.Bd3 *","weight":59579,"prior_delay":25,"prior_completion_time":0},
{"oid":1046,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind, Gurgenidze Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Nf6 6.Nc3 Nxd4 7.Qxd4 d6 *","weight":59517,"prior_delay":25,"prior_completion_time":0},
{"oid":1047,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 d6 7.Nc3 Nh6 *","weight":58731,"prior_delay":25,"prior_completion_time":0},
{"oid":1048,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nh6 *","weight":58707,"prior_delay":25,"prior_completion_time":0},
{"oid":1049,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 O-O  8.Be2 b6 9.O-O Bb7 10.f3 Qb8 *","weight":59663,"prior_delay":25,"prior_completion_time":0},
{"oid":1050,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 O-O  8.Be2 d6 9.O-O Nd7 *","weight":59892,"prior_delay":25,"prior_completion_time":0},
{"oid":1051,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 O-O  8.Be2 d6 9.O-O a6 *","weight":59893,"prior_delay":25,"prior_completion_time":0},
{"oid":1052,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 O-O  8.Be2 d6 9.O-O Nxd4 *","weight":59944,"prior_delay":25,"prior_completion_time":0},
{"oid":1053,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 d6 6.Nc3 Bg7 *","weight":58225,"prior_delay":25,"prior_completion_time":0},
{"oid":1054,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 O-O  8.Be2 d6 9.O-O Bd7 10.Rc1 *","weight":60130,"prior_delay":25,"prior_completion_time":0},
{"oid":1055,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Nf6 6.Nc3 d6 7.Be2 Nxd4  8.Qxd4 Bg7 9.O-O *","weight":60715,"prior_delay":25,"prior_completion_time":0},
{"oid":1056,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Nf6 6.Nc3 d6 7.Be2 Nxd4  8.Qxd4 Bg7 9.Bg5 O-O 10.Qd2 *","weight":61004,"prior_delay":25,"prior_completion_time":0},
{"oid":1057,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 O-O  8.Be2 d6 9.f3 *","weight":59651,"prior_delay":25,"prior_completion_time":0},
{"oid":1058,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 d6 8.Be2 *","weight":59390,"prior_delay":25,"prior_completion_time":0},
{"oid":1059,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 O-O  8.Be2 d6 9.O-O Bd7 10.Nc2 *","weight":60143,"prior_delay":25,"prior_completion_time":0},
{"oid":1060,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 O-O  8.Be2 d6 9.O-O Bd7 10.f3 *","weight":60126,"prior_delay":25,"prior_completion_time":0},
{"oid":1061,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Nf6 6.Nc3 d6 7.Be2 Nxd4  8.Qxd4 Bg7 9.Be3 O-O 10.Qd2 Be6 11.O-O *","weight":61317,"prior_delay":25,"prior_completion_time":0},
{"oid":1062,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Be3 Nf6 7.Nc3 O-O  8.Be2 d6 9.O-O Bd7 10.Qd2 Nxd4 11.Bxd4 Bc6 12.f3 a5 13.b3 *","weight":60913,"prior_delay":25,"prior_completion_time":0},
{"oid":1063,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Bc4 Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 Nf6 7.Bc4 O-O 8.Bb3  Qa5 *","weight":60711,"prior_delay":25,"prior_completion_time":0},
{"oid":1064,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Bc4 Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 Nf6 7.Bc4 O-O 8.Bb3  d6 9.h3 Bd7 *","weight":60966,"prior_delay":25,"prior_completion_time":0},
{"oid":1065,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Bc4 Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 Nf6 7.Bc4 O-O 8.Bb3  d6 9.f3 Bd7 *","weight":61244,"prior_delay":25,"prior_completion_time":0},
{"oid":1066,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Bc4 Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 Nf6 7.Bc4 O-O 8.f3 *","weight":60289,"prior_delay":25,"prior_completion_time":0},
{"oid":1067,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Bc4 Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 Nf6 7.Bc4 O-O 8.Bb3  a5 9.a4 *","weight":60758,"prior_delay":25,"prior_completion_time":0},
{"oid":1068,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Bc4 Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 Nf6 7.Bc4 Qa5  8.O-O O-O 9.Bb3 d6 10.h3 Bd7 11.f4 *","weight":60482,"prior_delay":25,"prior_completion_time":0},
{"oid":1069,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Bc4 Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 Nf6 7.Bc4 O-O 8.O-O *","weight":60295,"prior_delay":25,"prior_completion_time":0},
{"oid":1070,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Bc4 Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 Nf6 7.Bc4 O-O 8.Bb3  a5 9.f3 *","weight":60776,"prior_delay":25,"prior_completion_time":0},
{"oid":1071,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Nf6 6.Nxc6 bxc6 *","weight":57929,"prior_delay":25,"prior_completion_time":0},
{"oid":1072,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 Nf6 7.Nxc6 bxc6 8.e5  Ng8 *","weight":59480,"prior_delay":25,"prior_completion_time":0},
{"oid":1073,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Nde2 *","weight":58397,"prior_delay":25,"prior_completion_time":0},
{"oid":1074,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 Nf6 7.Be2 O-O  8.O-O d5 9.exd5 *","weight":59464,"prior_delay":25,"prior_completion_time":0},
{"oid":1075,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 Nf6 7.f3 *","weight":59432,"prior_delay":25,"prior_completion_time":0},
{"oid":1076,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Nb3 *","weight":58411,"prior_delay":25,"prior_completion_time":0},
{"oid":1077,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon, Modern Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Nc3 Bg7 6.Be3 d6 7.Qd2 *","weight":58985,"prior_delay":25,"prior_completion_time":0},
{"oid":1078,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Be2 *","weight":57644,"prior_delay":25,"prior_completion_time":0},
{"oid":1079,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Bc4 *","weight":57642,"prior_delay":25,"prior_completion_time":0},
{"oid":1080,"gid":202,"fullname":"Sicilian Defense, Accelerated Dragon","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.Be3 *","weight":57641,"prior_delay":25,"prior_completion_time":0},
{"oid":1081,"gid":203,"fullname":"Sicilian Defense, AcceleratedFianchetto, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Nc2 Nf6 7.Nc3 O-O *","weight":58435,"prior_delay":25,"prior_completion_time":0},
{"oid":1082,"gid":203,"fullname":"Sicilian Defense, AcceleratedFianchetto, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Nc2 Nf6 7.Nc3 d6 8.Be2 O-O  9.O-O Nd7 10.Bd2 a5 *","weight":58777,"prior_delay":25,"prior_completion_time":0},
{"oid":1083,"gid":203,"fullname":"Sicilian Defense, AcceleratedFianchetto, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Nc2 Nf6 7.Nc3 d6 8.Be2 O-O  9.O-O Be6 *","weight":58663,"prior_delay":25,"prior_completion_time":0},
{"oid":1084,"gid":203,"fullname":"Sicilian Defense, AcceleratedFianchetto, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Nc2 Nf6 7.Nc3 d6 8.Be2 Nd7 *","weight":58592,"prior_delay":25,"prior_completion_time":0},
{"oid":1085,"gid":203,"fullname":"Sicilian Defense, AcceleratedFianchetto, Maroczy Bind","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 g6 5.c4 Bg7 6.Nc2 Nf6 7.Nc3 d6 8.Be2 O-O  9.O-O Nd7 10.Bd2 Nc5 11.b4 *","weight":58839,"prior_delay":25,"prior_completion_time":0},
{"oid":1086,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Barmen Defense, Modern Line","main_line":"1.e4 c5 2.c3 d5 3.exd5 Qxd5 4.d4 Nf6 5.Nf3 Bg4 6.Be2 e6 *","weight":25326,"prior_delay":25,"prior_completion_time":0},
{"oid":1087,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Barmen Defense","main_line":"1.e4 c5 2.c3 d5 3.exd5 Qxd5 4.d4 Nc6 5.Nf3 Bg4 *","weight":24571,"prior_delay":25,"prior_completion_time":0},
{"oid":1088,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Barmen Defense","main_line":"1.e4 c5 2.c3 d5 3.exd5 Qxd5 4.d4 e6 5.Nf3 *","weight":24502,"prior_delay":25,"prior_completion_time":0},
{"oid":1089,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Barmen Defense","main_line":"1.e4 c5 2.c3 d5 3.exd5 Qxd5 4.d4 Nf6 5.Nf3 e6 6.Be3 *","weight":25286,"prior_delay":25,"prior_completion_time":0},
{"oid":1090,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Barmen Defense","main_line":"1.e4 c5 2.c3 d5 3.exd5 Qxd5 4.d4 Nf6 5.Nf3 e6 6.Be2 *","weight":25278,"prior_delay":25,"prior_completion_time":0},
{"oid":1091,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Barmen Defense","main_line":"1.e4 c5 2.c3 d5 3.exd5 Qxd5 4.d4 cxd4 5.cxd4 *","weight":24427,"prior_delay":25,"prior_completion_time":0},
{"oid":1092,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Barmen Defense","main_line":"1.e4 c5 2.c3 d5 3.exd5 Qxd5 4.d4 Nc6 5.Nf3 cxd4 6.cxd4 *","weight":24543,"prior_delay":25,"prior_completion_time":0},
{"oid":1093,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Smith-Morra Declined","main_line":"1.e4 c5 2.c3 Nf6 3.e5 Nd5 4.d4 cxd4 5.cxd4 e6 6.Nf3 d6 *","weight":25239,"prior_delay":25,"prior_completion_time":0},
{"oid":1094,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Smith-Morra Declined","main_line":"1.e4 c5 2.c3 Nf6 3.e5 Nd5 4.d4 cxd4 5.cxd4 e6 6.Nf3 Nc6 *","weight":25070,"prior_delay":25,"prior_completion_time":0},
{"oid":1095,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Smith-Morra Declined","main_line":"1.e4 c5 2.c3 Nf6 3.e5 Nd5 4.d4 cxd4 5.cxd4 e6 6.Nf3 b6 *","weight":25100,"prior_delay":25,"prior_completion_time":0},
{"oid":1096,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Smith-Morra Declined","main_line":"1.e4 c5 2.c3 Nf6 3.e5 Nd5 4.d4 cxd4 5.Nf3 e6 *","weight":25256,"prior_delay":25,"prior_completion_time":0},
{"oid":1097,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Smith-Morra Declined","main_line":"1.e4 c5 2.c3 Nf6 3.e5 Nd5 4.d4 cxd4 5.Qxd4 *","weight":25047,"prior_delay":25,"prior_completion_time":0},
{"oid":1098,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Smith-Morra Declined","main_line":"1.e4 c5 2.c3 Nf6 3.e5 Nd5 4.d4 cxd4 5.Nf3 Nc6 6.Bc4 *","weight":25399,"prior_delay":25,"prior_completion_time":0},
{"oid":1099,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Smith-Morra Declined","main_line":"1.e4 c5 2.c3 Nf6 3.e5 Nd5 4.d4 cxd4 5.cxd4 Nc6 6.Nf3 *","weight":25056,"prior_delay":25,"prior_completion_time":0},
{"oid":1100,"gid":204,"fullname":"Sicilian Defense, Alapin Variation, Smith-Morra Declined","main_line":"1.e4 c5 2.c3 Nf6 3.e5 Nd5 4.d4 cxd4 5.cxd4 d6 6.Nf3 Nc6 7.Bc4 Nb6 8.Bb5 *","weight":25661,"prior_delay":25,"prior_completion_time":0},
{"oid":1101,"gid":204,"fullname":"Sicilian Defense, Alapin Variation","main_line":"1.e4 c5 2.c3 g6 3.d4 cxd4 *","weight":22470,"prior_delay":25,"prior_completion_time":0},
{"oid":1102,"gid":204,"fullname":"Sicilian Defense, Alapin Variation","main_line":"1.e4 c5 2.c3 e6 3.d4 d5 4.exd5 exd5 5.Nf3 Nc6 *","weight":22938,"prior_delay":25,"prior_completion_time":0},
{"oid":1103,"gid":204,"fullname":"Sicilian Defense, Alapin Variation","main_line":"1.e4 c5 2.c3 Nf6 3.e5 Nd5 4.g3 *","weight":24556,"prior_delay":25,"prior_completion_time":0},
{"oid":1104,"gid":205,"fullname":"Sicilian Defense, Boleslavsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 e5 7.Nf3 h6  8.O-O Be7 9.Re1 O-O 10.h3 a6 *","weight":108473,"prior_delay":25,"prior_completion_time":0},
{"oid":1105,"gid":205,"fullname":"Sicilian Defense, Boleslavsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 e5 7.Nf3 h6  8.O-O Be7 9.Re1 O-O 10.h3 Be6 11.Bf1 Qa5 *","weight":108591,"prior_delay":25,"prior_completion_time":0},
{"oid":1106,"gid":205,"fullname":"Sicilian Defense, Boleslavsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 e5 7.Nf3 h6  8.O-O Be7 9.Re1 O-O 10.h3 Be6 11.Bf1 Nb8 12.b3 a6 *","weight":108616,"prior_delay":25,"prior_completion_time":0},
{"oid":1107,"gid":205,"fullname":"Sicilian Defense, Boleslavsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 e5 7.Nb3 Be7  8.O-O O-O 9.f4 *","weight":107970,"prior_delay":25,"prior_completion_time":0},
{"oid":1108,"gid":205,"fullname":"Sicilian Defense, Boleslavsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 e5 7.Nb3 Be7  8.O-O O-O 9.Kh1 *","weight":107969,"prior_delay":25,"prior_completion_time":0},
{"oid":1109,"gid":205,"fullname":"Sicilian Defense, Boleslavsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 e5 7.Nb3 Be7 8.Bg5 *","weight":107919,"prior_delay":25,"prior_completion_time":0},
{"oid":1110,"gid":205,"fullname":"Sicilian Defense, Boleslavsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 e5 7.Nb3 Be7  8.O-O O-O 9.Be3 Be6 10.Bf3 *","weight":107986,"prior_delay":25,"prior_completion_time":0},
{"oid":1111,"gid":205,"fullname":"Sicilian Defense, Boleslavsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 e5 7.Nb3 Be7 8.Be3 *","weight":107921,"prior_delay":25,"prior_completion_time":0},
{"oid":1112,"gid":205,"fullname":"Sicilian Defense, Boleslavsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 e5 7.Nf3 h6  8.O-O Be7 9.h3 *","weight":108244,"prior_delay":25,"prior_completion_time":0},
{"oid":1113,"gid":205,"fullname":"Sicilian Defense, Boleslavsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 e5 7.Nf3 h6  8.O-O Be7 9.Re1 O-O 10.h3 Be6 11.Bf1 Nb8 12.a4 *","weight":108602,"prior_delay":25,"prior_completion_time":0},
{"oid":1114,"gid":206,"fullname":"Sicilian Defense, Canal Attack, Haag Gambit","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Bd7 4.Bxd7+ Qxd7 5.O-O Nc6 6.c3 Nf6 7.d4 *","weight":56301,"prior_delay":25,"prior_completion_time":0},
{"oid":1115,"gid":206,"fullname":"Sicilian Defense, Canal Attack, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Bd7 4.Bxd7+ Qxd7 5.O-O Nc6 6.Re1 Nf6 *","weight":56133,"prior_delay":25,"prior_completion_time":0},
{"oid":1116,"gid":206,"fullname":"Sicilian Defense, Canal Attack, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Bd7 4.Bxd7+ Nxd7 5.O-O Ngf6 6.Qe2 *","weight":55470,"prior_delay":25,"prior_completion_time":0},
{"oid":1117,"gid":207,"fullname":"Sicilian Defense, Chekhover Variation, Zaitsev Defense","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Qxd4 Nc6 5.Bb5 Qd7 *","weight":74024,"prior_delay":25,"prior_completion_time":0},
{"oid":1118,"gid":207,"fullname":"Sicilian Defense, Chekhover Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Qxd4 a6 5.c4 Nc6 6.Qd2 g6 *","weight":73944,"prior_delay":25,"prior_completion_time":0},
{"oid":1119,"gid":207,"fullname":"Sicilian Defense, Chekhover Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Qxd4 Bd7 *","weight":73706,"prior_delay":25,"prior_completion_time":0},
{"oid":1120,"gid":207,"fullname":"Sicilian Defense, Chekhover Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Qxd4 Nc6 5.Bb5 Bd7 6.Bxc6 Bxc6 7.Nc3 Nf6  8.Bg5 e6 9.O-O-O Be7 10.Rhe1 O-O 11.Qd2 *","weight":75082,"prior_delay":25,"prior_completion_time":0},
{"oid":1121,"gid":207,"fullname":"Sicilian Defense, Chekhover Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Qxd4 Nc6 5.Bb5 Bd7 6.Bxc6 Bxc6 7.c4 Nf6  8.Nc3 g6 *","weight":74535,"prior_delay":25,"prior_completion_time":0},
{"oid":1122,"gid":207,"fullname":"Sicilian Defense, Chekhover Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Qxd4 Nf6 5.Nc3 *","weight":73787,"prior_delay":25,"prior_completion_time":0},
{"oid":1123,"gid":207,"fullname":"Sicilian Defense, Chekhover Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Qxd4 Nc6 5.Bb5 Bd7 6.Bxc6 Bxc6 7.Nc3 Nf6  8.Bg5 e6 9.O-O-O Be7 10.Qd3 *","weight":75037,"prior_delay":25,"prior_completion_time":0},
{"oid":1124,"gid":207,"fullname":"Sicilian Defense, Chekhover Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Qxd4 a6 5.Be3 *","weight":73832,"prior_delay":25,"prior_completion_time":0},
{"oid":1125,"gid":208,"fullname":"Sicilian Defense, Classical Variation, Anti-Sozin Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bc4 Qb6 7.Nxc6 bxc6  8.O-O g6 *","weight":108292,"prior_delay":25,"prior_completion_time":0},
{"oid":1126,"gid":208,"fullname":"Sicilian Defense, Classical Variation, Anti-Sozin Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bc4 Qb6 7.Nde2 *","weight":108179,"prior_delay":25,"prior_completion_time":0},
{"oid":1127,"gid":208,"fullname":"Sicilian Defense, Classical Variation, Anti-Sozin Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bc4 Qb6 7.Nb3 e6 8.Bf4 *","weight":108480,"prior_delay":25,"prior_completion_time":0},
{"oid":1128,"gid":208,"fullname":"Sicilian Defense, Classical Variation, Anti-Sozin Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bc4 Qb6 7.Nb3 e6 8.O-O  Be7 9.Be3 *","weight":108488,"prior_delay":25,"prior_completion_time":0},
{"oid":1129,"gid":208,"fullname":"Sicilian Defense, Classical Variation, Anti-Sozin Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bc4 Qb6 7.Ndb5 *","weight":108191,"prior_delay":25,"prior_completion_time":0},
{"oid":1130,"gid":208,"fullname":"Sicilian Defense, Classical Variation, Anti-Sozin Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bc4 Qb6 7.Nb3 e6 8.Be3 *","weight":108420,"prior_delay":25,"prior_completion_time":0},
{"oid":1131,"gid":208,"fullname":"Sicilian Defense, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 Bd7 *","weight":107735,"prior_delay":25,"prior_completion_time":0},
{"oid":1132,"gid":208,"fullname":"Sicilian Defense, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 Nxd4 *","weight":107738,"prior_delay":25,"prior_completion_time":0},
{"oid":1133,"gid":208,"fullname":"Sicilian Defense, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be2 a6 *","weight":107735,"prior_delay":25,"prior_completion_time":0},
{"oid":1134,"gid":208,"fullname":"Sicilian Defense, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.f3 e5 7.Nb3 Be7 *","weight":107896,"prior_delay":25,"prior_completion_time":0},
{"oid":1135,"gid":208,"fullname":"Sicilian Defense, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be3 Ng4 7.Bb5 *","weight":107542,"prior_delay":25,"prior_completion_time":0},
{"oid":1136,"gid":208,"fullname":"Sicilian Defense, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Be3 e5 *","weight":107536,"prior_delay":25,"prior_completion_time":0},
{"oid":1137,"gid":208,"fullname":"Sicilian Defense, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.f4 *","weight":107514,"prior_delay":25,"prior_completion_time":0},
{"oid":1138,"gid":208,"fullname":"Sicilian Defense, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.h3 *","weight":107514,"prior_delay":25,"prior_completion_time":0},
{"oid":1139,"gid":209,"fullname":"Sicilian Defense, Classical Variation,Fianchetto Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.g3 *","weight":107546,"prior_delay":25,"prior_completion_time":0},
{"oid":1140,"gid":210,"fullname":"Sicilian Defense, Closed, Smyslov Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 e6 6.Be3 Nd4 7.Nce2 *","weight":24339,"prior_delay":25,"prior_completion_time":0},
{"oid":1141,"gid":210,"fullname":"Sicilian Defense, Closed","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.f4 *","weight":24108,"prior_delay":25,"prior_completion_time":0},
{"oid":1142,"gid":210,"fullname":"Sicilian Defense, Closed","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 e6 6.f4 *","weight":24309,"prior_delay":25,"prior_completion_time":0},
{"oid":1143,"gid":210,"fullname":"Sicilian Defense, Closed","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.d3 *","weight":23767,"prior_delay":25,"prior_completion_time":0},
{"oid":1144,"gid":210,"fullname":"Sicilian Defense, Closed","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Nge2 e6 *","weight":24467,"prior_delay":25,"prior_completion_time":0},
{"oid":1145,"gid":210,"fullname":"Sicilian Defense, Closed","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Nge2 *","weight":24106,"prior_delay":25,"prior_completion_time":0},
{"oid":1146,"gid":211,"fullname":"Sicilian Defense, Closed Variation, Botvinnik Defense","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Nge2 e5 *","weight":24467,"prior_delay":25,"prior_completion_time":0},
{"oid":1147,"gid":211,"fullname":"Sicilian Defense, Closed Variation, Botvinnik Defense","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.f4 e5 7.Nf3 Nge7 8.O-O *","weight":24519,"prior_delay":25,"prior_completion_time":0},
{"oid":1148,"gid":211,"fullname":"Sicilian Defense, Closed Variation, Chameleon Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.Nge2 e5 *","weight":23595,"prior_delay":25,"prior_completion_time":0},
{"oid":1149,"gid":211,"fullname":"Sicilian Defense, Closed Variation, Chameleon Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.Nge2 g6 *","weight":23594,"prior_delay":25,"prior_completion_time":0},
{"oid":1150,"gid":211,"fullname":"Sicilian Defense, Closed Variation, Korchnoi Defense","main_line":"1.e4 c5 2.Nc3 e6 3.g3 d5 *","weight":23104,"prior_delay":25,"prior_completion_time":0},
{"oid":1151,"gid":211,"fullname":"Sicilian Defense, Closed Variation, Traditional","main_line":"1.e4 c5 2.Nc3 Nc6 3.Bb5 Nd4 *","weight":23744,"prior_delay":25,"prior_completion_time":0},
{"oid":1152,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.f4 e6 7.Nf3 Nge7 8.O-O O-O  9.Be3 Nd4 10.Rb1 *","weight":24710,"prior_delay":25,"prior_completion_time":0},
{"oid":1153,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.f4 Nf6 7.Nf3 *","weight":24490,"prior_delay":25,"prior_completion_time":0},
{"oid":1154,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Be3 Rb8 7.Qd2 b5 8.Nge2 *","weight":24577,"prior_delay":25,"prior_completion_time":0},
{"oid":1155,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Be3 e6 7.Qd2 Qa5 *","weight":24546,"prior_delay":25,"prior_completion_time":0},
{"oid":1156,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Be3 e6 7.Qd2 Nd4 *","weight":24544,"prior_delay":25,"prior_completion_time":0},
{"oid":1157,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Be3 e6 7.Qd2 Nge7 *","weight":24543,"prior_delay":25,"prior_completion_time":0},
{"oid":1158,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Be3 Nf6 7.h3 *","weight":24523,"prior_delay":25,"prior_completion_time":0},
{"oid":1159,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Be3 e5 7.Qd2 Nge7 *","weight":24541,"prior_delay":25,"prior_completion_time":0},
{"oid":1160,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.f4 e6 7.Nf3 Nge7 8.O-O O-O  9.Be3 Nd4 10.Qd2 *","weight":24709,"prior_delay":25,"prior_completion_time":0},
{"oid":1161,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.Be3 e6 7.Qd2 Rb8 8.Nge2 *","weight":24564,"prior_delay":25,"prior_completion_time":0},
{"oid":1162,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.f4 Rb8 *","weight":24479,"prior_delay":25,"prior_completion_time":0},
{"oid":1163,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.f4 e6 7.Nf3 Nge7 8.O-O O-O  9.Be3 b6 *","weight":24703,"prior_delay":25,"prior_completion_time":0},
{"oid":1164,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.f4 e6 7.Nf3 Nge7 8.O-O O-O  9.Be3 Nd4 10.e5 Nef5 *","weight":24720,"prior_delay":25,"prior_completion_time":0},
{"oid":1165,"gid":211,"fullname":"Sicilian Defense, Closed Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.d3 d6 6.f4 e6 7.Nf3 Nge7 8.O-O O-O  9.Be3 Rb8 *","weight":24697,"prior_delay":25,"prior_completion_time":0},
{"oid":1166,"gid":212,"fullname":"Sicilian Defense, Closed Variation,Fianchetto Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.g3 e6 *","weight":23606,"prior_delay":25,"prior_completion_time":0},
{"oid":1167,"gid":213,"fullname":"Sicilian Defense, Delayed Alapin","main_line":"1.e4 c5 2.Nf3 d6 3.c3 Nf6 4.Be2 Nc6 *","weight":51625,"prior_delay":25,"prior_completion_time":0},
{"oid":1168,"gid":213,"fullname":"Sicilian Defense, Delayed Alapin","main_line":"1.e4 c5 2.Nf3 d6 3.c3 Nf6 4.Be2 Nbd7 *","weight":51663,"prior_delay":25,"prior_completion_time":0},
{"oid":1169,"gid":213,"fullname":"Sicilian Defense, Delayed Alapin","main_line":"1.e4 c5 2.Nf3 d6 3.c3 Nf6 4.h3 Nc6 *","weight":51510,"prior_delay":25,"prior_completion_time":0},
{"oid":1170,"gid":213,"fullname":"Sicilian Defense, Delayed Alapin","main_line":"1.e4 c5 2.Nf3 d6 3.c3 Nf6 4.Bd3 Nc6 *","weight":51536,"prior_delay":25,"prior_completion_time":0},
{"oid":1171,"gid":213,"fullname":"Sicilian Defense, Delayed Alapin","main_line":"1.e4 c5 2.Nf3 d6 3.c3 Nf6 4.Bc4 *","weight":51414,"prior_delay":25,"prior_completion_time":0},
{"oid":1172,"gid":213,"fullname":"Sicilian Defense, Delayed Alapin","main_line":"1.e4 c5 2.Nf3 d6 3.c3 Nf6 4.Be2 g6 5.O-O Bg7 6.Bb5+ *","weight":51921,"prior_delay":25,"prior_completion_time":0},
{"oid":1173,"gid":214,"fullname":"Sicilian Defense, Delayed Alapin Variation","main_line":"1.e4 c5 2.c3 e6 3.Nf3 d5 *","weight":22804,"prior_delay":25,"prior_completion_time":0},
{"oid":1174,"gid":215,"fullname":"Sicilian Defense, Dragon, Classical, Grigoriev Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Be2 Nc6  8.Qd2 O-O 9.O-O-O *","weight":107969,"prior_delay":25,"prior_completion_time":0},
{"oid":1175,"gid":215,"fullname":"Sicilian Defense, Dragon, Classical Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Be2 O-O 8.O-O *","weight":107917,"prior_delay":25,"prior_completion_time":0},
{"oid":1176,"gid":215,"fullname":"Sicilian Defense, Dragon, Yugoslav Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Qa5 11.Bb3 Rfc8 12.h4 Ne5 13.Kb1 Nc4 *","weight":109835,"prior_delay":25,"prior_completion_time":0},
{"oid":1177,"gid":215,"fullname":"Sicilian Defense, Dragon, Yugoslav Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Qa5 11.Bb3 Rfc8 12.h4 Ne5 13.g4 *","weight":109834,"prior_delay":25,"prior_completion_time":0},
{"oid":1178,"gid":215,"fullname":"Sicilian Defense, Dragon, Yugoslav Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Qa5 11.Bb3 Rfc8 12.h4 Ne5 13.h5 *","weight":109829,"prior_delay":25,"prior_completion_time":0},
{"oid":1179,"gid":215,"fullname":"Sicilian Defense, Dragon","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Bc4 O-O *","weight":107868,"prior_delay":25,"prior_completion_time":0},
{"oid":1180,"gid":215,"fullname":"Sicilian Defense, Dragon","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Qd2 *","weight":107870,"prior_delay":25,"prior_completion_time":0},
{"oid":1181,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation, Alekhine Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Be2 Nc6  8.O-O O-O 9.Nb3 a5 *","weight":107995,"prior_delay":25,"prior_completion_time":0},
{"oid":1182,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation, Battery Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Be2 Nc6  8.O-O O-O 9.Qd2 *","weight":108012,"prior_delay":25,"prior_completion_time":0},
{"oid":1183,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation, Normal Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Be2 Nc6  8.O-O O-O 9.Nb3 b6 *","weight":107996,"prior_delay":25,"prior_completion_time":0},
{"oid":1184,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation, Normal Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Be2 Nc6  8.O-O O-O 9.Nb3 a6 *","weight":107999,"prior_delay":25,"prior_completion_time":0},
{"oid":1185,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation, Normal Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Be2 Nc6  8.O-O O-O 9.Nb3 Be6 10.f4 Rc8 *","weight":108061,"prior_delay":25,"prior_completion_time":0},
{"oid":1186,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation, Tartakower Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Be2 Nc6  8.O-O O-O 9.Nb3 Be6 10.f4 Qc8 *","weight":108068,"prior_delay":25,"prior_completion_time":0},
{"oid":1187,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Be2 Nc6 8.Nb3 *","weight":107914,"prior_delay":25,"prior_completion_time":0},
{"oid":1188,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be2 a6 7.a4 Bg7 *","weight":106819,"prior_delay":25,"prior_completion_time":0},
{"oid":1189,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be2 Nc6 *","weight":106788,"prior_delay":25,"prior_completion_time":0},
{"oid":1190,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be2 Bg7 7.O-O O-O  8.Bg5 Nc6 9.Nb3 Be6 10.Kh1 *","weight":107255,"prior_delay":25,"prior_completion_time":0},
{"oid":1191,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Be2 Nc6  8.O-O O-O 9.Kh1 *","weight":107972,"prior_delay":25,"prior_completion_time":0},
{"oid":1192,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be2 Bg7 7.O-O O-O  8.Re1 Nc6 9.Nb3 Be6 *","weight":107296,"prior_delay":25,"prior_completion_time":0},
{"oid":1193,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be2 Bg7 7.O-O Nc6  8.Nb3 O-O *","weight":107232,"prior_delay":25,"prior_completion_time":0},
{"oid":1194,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be2 Bg7 7.O-O O-O  8.Bg5 Nc6 9.Nb3 a6 *","weight":107238,"prior_delay":25,"prior_completion_time":0},
{"oid":1195,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Levenfish Variation, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.f4 Nbd7 *","weight":106638,"prior_delay":25,"prior_completion_time":0},
{"oid":1196,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Levenfish Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.f4 Nc6 7.Nxc6 *","weight":106645,"prior_delay":25,"prior_completion_time":0},
{"oid":1197,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Levenfish Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.f4 Nc6 7.Nf3 *","weight":106649,"prior_delay":25,"prior_completion_time":0},
{"oid":1198,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Modern Bc4 Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.Bc4 Nc6 *","weight":107868,"prior_delay":25,"prior_completion_time":0},
{"oid":1199,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Belezky Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 Nc6 8.Qd2  Bd7 9.O-O-O Rc8 *","weight":109141,"prior_delay":25,"prior_completion_time":0},
{"oid":1200,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Belezky Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 Nc6 8.Bc4 *","weight":108794,"prior_delay":25,"prior_completion_time":0},
{"oid":1201,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Early Deviations","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 a6 *","weight":108579,"prior_delay":25,"prior_completion_time":0},
{"oid":1202,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Nxd4 *","weight":109497,"prior_delay":25,"prior_completion_time":0},
{"oid":1203,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Modern Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.O-O-O d5 10.exd5 Nxd5 11.Nxc6 bxc6 12.Bd4 e5 13.Bc5 Be6 *","weight":110306,"prior_delay":25,"prior_completion_time":0},
{"oid":1204,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Modern Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.O-O-O d5 10.exd5 Nxd5 11.Nxc6 bxc6 12.Nxd5 *","weight":110113,"prior_delay":25,"prior_completion_time":0},
{"oid":1205,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Modern Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.O-O-O Nxd4 10.Bxd4 Be6 11.Kb1 Qc7 12.g4 *","weight":109538,"prior_delay":25,"prior_completion_time":0},
{"oid":1206,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Modern Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.O-O-O d5 10.Qe1 *","weight":109663,"prior_delay":25,"prior_completion_time":0},
{"oid":1207,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Modern Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.O-O-O Bd7 10.g4 *","weight":109495,"prior_delay":25,"prior_completion_time":0},
{"oid":1208,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Old Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Rc8 11.Bb3 Ne5 12.Kb1 Nc4 13.Bxc4 Rxc4  14.g4 b5 *","weight":110096,"prior_delay":25,"prior_completion_time":0},
{"oid":1209,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Old Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Rc8 11.Bb3 Ne5 12.h4 h5 13.Bh6 Bxh6 *","weight":110002,"prior_delay":25,"prior_completion_time":0},
{"oid":1210,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Old Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Rc8 11.Bb3 Ne5 12.h4 h5 13.Bg5 Rc5 14.g4 *","weight":110105,"prior_delay":25,"prior_completion_time":0},
{"oid":1211,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Old Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Rc8 11.Bb3 Ne5 12.h4 h5 13.Kb1 *","weight":110006,"prior_delay":25,"prior_completion_time":0},
{"oid":1212,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Old Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Rc8 11.Bb3 Ne5 12.h4 h5 13.Bg5 Rc5 14.Kb1 *","weight":110147,"prior_delay":25,"prior_completion_time":0},
{"oid":1213,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Old Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Rc8 11.Bb3 Ne5 12.h4 Nc4 13.Bxc4 Rxc4 14.h5 *","weight":109979,"prior_delay":25,"prior_completion_time":0},
{"oid":1214,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Old Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Rc8 11.Bb3 Ne5 12.h4 Nc4 13.Bxc4 Rxc4 14.g4 *","weight":109980,"prior_delay":25,"prior_completion_time":0},
{"oid":1215,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Panov Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.g4 Be6 *","weight":109337,"prior_delay":25,"prior_completion_time":0},
{"oid":1216,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Panov Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.g4 Nxd4 *","weight":109304,"prior_delay":25,"prior_completion_time":0},
{"oid":1217,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Soltis Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Qa5 11.Bb3 Rfc8 12.h4 h5 *","weight":109821,"prior_delay":25,"prior_completion_time":0},
{"oid":1218,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack, Sosonko Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Nd7 *","weight":109499,"prior_delay":25,"prior_completion_time":0},
{"oid":1219,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.O-O-O Ne5 *","weight":109823,"prior_delay":25,"prior_completion_time":0},
{"oid":1220,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.h4 Rc8 *","weight":109758,"prior_delay":25,"prior_completion_time":0},
{"oid":1221,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.g4 *","weight":109664,"prior_delay":25,"prior_completion_time":0},
{"oid":1222,"gid":216,"fullname":"Sicilian Defense, Dragon Variation, Yugoslav Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Be3 Bg7 7.f3 O-O  8.Qd2 Nc6 9.Bc4 Bd7 10.Bb3 *","weight":109693,"prior_delay":25,"prior_completion_time":0},
{"oid":1223,"gid":216,"fullname":"Sicilian Defense, Dragon Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Bg5 Bg7 *","weight":106645,"prior_delay":25,"prior_completion_time":0},
{"oid":1224,"gid":216,"fullname":"Sicilian Defense, Dragon Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Bc4 Bg7 7.O-O *","weight":106700,"prior_delay":25,"prior_completion_time":0},
{"oid":1225,"gid":216,"fullname":"Sicilian Defense, Dragon Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.Bc4 Bg7 7.h3 *","weight":106686,"prior_delay":25,"prior_completion_time":0},
{"oid":1226,"gid":216,"fullname":"Sicilian Defense, Dragon Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.f3 *","weight":106637,"prior_delay":25,"prior_completion_time":0},
{"oid":1227,"gid":217,"fullname":"Sicilian Defense, Dragon Variation,Fianchetto Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.g3 Nc6 7.Bg2 *","weight":106722,"prior_delay":25,"prior_completion_time":0},
{"oid":1228,"gid":217,"fullname":"Sicilian Defense, Dragon Variation,Fianchetto Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 g6 6.g3 Nc6 7.Nde2 *","weight":106760,"prior_delay":25,"prior_completion_time":0},
{"oid":1229,"gid":218,"fullname":"Sicilian Defense, Flohr Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Qc7 5.Nb5 *","weight":57278,"prior_delay":25,"prior_completion_time":0},
{"oid":1230,"gid":219,"fullname":"Sicilian Defense, Four Knights Variation, Exchange Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Nf6 6.Nxc6 bxc6 7.e5 Nd5 8.Ne4  Qc7 *","weight":70968,"prior_delay":25,"prior_completion_time":0},
{"oid":1231,"gid":219,"fullname":"Sicilian Defense, Four Knights Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Nf6 6.Bg5 *","weight":70235,"prior_delay":25,"prior_completion_time":0},
{"oid":1232,"gid":219,"fullname":"Sicilian Defense, Four Knights Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Nf6 6.g3 *","weight":70269,"prior_delay":25,"prior_completion_time":0},
{"oid":1233,"gid":219,"fullname":"Sicilian Defense, Four Knights Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Nf6 6.Be2 *","weight":70256,"prior_delay":25,"prior_completion_time":0},
{"oid":1234,"gid":219,"fullname":"Sicilian Defense, Four Knights Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Nf6 6.Ndb5 d6 7.Bf4 *","weight":71029,"prior_delay":25,"prior_completion_time":0},
{"oid":1235,"gid":219,"fullname":"Sicilian Defense, Four Knights Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Nf6 6.a3 *","weight":70262,"prior_delay":25,"prior_completion_time":0},
{"oid":1236,"gid":219,"fullname":"Sicilian Defense, Four Knights Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Nf6 6.Ndb5 Bb4 7.a3 Bxc3+  8.Nxc3 d5 9.exd5 exd5 10.Bd3 O-O 11.O-O d4 12.Ne2 *","weight":71445,"prior_delay":25,"prior_completion_time":0},
{"oid":1237,"gid":220,"fullname":"Sicilian Defense, French Variation, Normal","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nf6 5.Bd3 Nc6 6.Nxc6 *","weight":64962,"prior_delay":25,"prior_completion_time":0},
{"oid":1238,"gid":220,"fullname":"Sicilian Defense, French Variation, Open","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 d6 *","weight":64207,"prior_delay":25,"prior_completion_time":0},
{"oid":1239,"gid":220,"fullname":"Sicilian Defense, French Variation, Westerinen Attack","main_line":"1.e4 c5 2.Nf3 e6 3.b3 Nc6 *","weight":45068,"prior_delay":25,"prior_completion_time":0},
{"oid":1240,"gid":220,"fullname":"Sicilian Defense, French Variation","main_line":"1.e4 c5 2.Nf3 e6 3.Nc3 Nc6 *","weight":45618,"prior_delay":25,"prior_completion_time":0},
{"oid":1241,"gid":221,"fullname":"Sicilian Defense, Godiva Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Qb6 *","weight":57268,"prior_delay":25,"prior_completion_time":0},
{"oid":1242,"gid":222,"fullname":"Sicilian Defense, Grand Prix Attack, Schofman Variation","main_line":"1.e4 c5 2.Nc3 Nc6 3.f4 g6 4.Nf3 Bg7 5.Bc4 e6 6.f5 *","weight":23678,"prior_delay":25,"prior_completion_time":0},
{"oid":1243,"gid":222,"fullname":"Sicilian Defense, Grand Prix Attack","main_line":"1.e4 c5 2.Nc3 Nc6 3.f4 g6 4.Nf3 Bg7 5.Bb5 Nd4 6.Bd3 *","weight":23742,"prior_delay":25,"prior_completion_time":0},
{"oid":1244,"gid":222,"fullname":"Sicilian Defense, Grand Prix Attack","main_line":"1.e4 c5 2.Nc3 Nc6 3.f4 d6 4.Nf3 g6 *","weight":23642,"prior_delay":25,"prior_completion_time":0},
{"oid":1245,"gid":222,"fullname":"Sicilian Defense, Grand Prix Attack","main_line":"1.e4 c5 2.Nc3 Nc6 3.f4 g6 4.Nf3 Bg7 5.Bb5 Nd4 6.O-O *","weight":23767,"prior_delay":25,"prior_completion_time":0},
{"oid":1246,"gid":222,"fullname":"Sicilian Defense, Grand Prix Attack","main_line":"1.e4 c5 2.Nc3 Nc6 3.f4 e6 4.Nf3 d5 *","weight":23556,"prior_delay":25,"prior_completion_time":0},
{"oid":1247,"gid":223,"fullname":"Sicilian Defense, Heidenfeld Variation","main_line":"1.e4 c5 2.c3 Nf6 3.e5 Nd5 4.Nf3 Nc6 5.Na3 *","weight":25211,"prior_delay":25,"prior_completion_time":0},
{"oid":1248,"gid":224,"fullname":"Sicilian Defense, Hyperaccelerated Dragon","main_line":"1.e4 c5 2.Nf3 g6 3.c3 Bg7 4.d4 cxd4 5.cxd4 d5 *","weight":38300,"prior_delay":25,"prior_completion_time":0},
{"oid":1249,"gid":225,"fullname":"Sicilian Defense, Hyperaccelerated Pterodactyl","main_line":"1.e4 c5 2.Nf3 g6 3.d4 Bg7 4.Nc3 *","weight":38388,"prior_delay":25,"prior_completion_time":0},
{"oid":1250,"gid":226,"fullname":"Sicilian Defense, HyperacceleratedFianchetto","main_line":"1.e4 c5 2.Nf3 g6 3.d4 cxd4 4.Nxd4 *","weight":38636,"prior_delay":25,"prior_completion_time":0},
{"oid":1251,"gid":227,"fullname":"Sicilian Defense, Kalashnikov Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 e5 5.Nb5 d6 6.N1c3 a6 7.Na3 b5 8.Nd5  Nge7 *","weight":59155,"prior_delay":25,"prior_completion_time":0},
{"oid":1252,"gid":227,"fullname":"Sicilian Defense, Kalashnikov Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 e5 5.Nb5 d6 6.a4 *","weight":58307,"prior_delay":25,"prior_completion_time":0},
{"oid":1253,"gid":227,"fullname":"Sicilian Defense, Kalashnikov Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 e5 5.Nb5 d6 6.c4 Be7 7.Be2 *","weight":58692,"prior_delay":25,"prior_completion_time":0},
{"oid":1254,"gid":227,"fullname":"Sicilian Defense, Kalashnikov Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 e5 5.Nb5 d6 6.c4 Be7 7.N1c3 a6 8.Na3 Be6  9.Be2 *","weight":59116,"prior_delay":25,"prior_completion_time":0},
{"oid":1255,"gid":227,"fullname":"Sicilian Defense, Kalashnikov Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 e5 5.Nb5 d6 6.c4 Be7 7.N1c3 a6 8.Na3 Be6  9.Nc2 *","weight":59138,"prior_delay":25,"prior_completion_time":0},
{"oid":1256,"gid":228,"fullname":"Sicilian Defense, Kan, Gipslis Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Bd3 Nf6 6.O-O d6 7.c4 g6 *","weight":68723,"prior_delay":25,"prior_completion_time":0},
{"oid":1257,"gid":228,"fullname":"Sicilian Defense, Kan, Maroczy Bind, Bronstein Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.c4 Nf6 6.Nc3 Bb4 7.Bd3 Nc6 8.Bc2 *","weight":67247,"prior_delay":25,"prior_completion_time":0},
{"oid":1258,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Knight Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Nc3 d6 *","weight":67168,"prior_delay":25,"prior_completion_time":0},
{"oid":1259,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Knight Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Nc3 Qc7 6.g3 Nf6 *","weight":67656,"prior_delay":25,"prior_completion_time":0},
{"oid":1260,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Knight Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Nc3 Qc7 6.Bd3 Nf6 7.O-O *","weight":68194,"prior_delay":25,"prior_completion_time":0},
{"oid":1261,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Knight Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Nc3 Qc7 6.Be2 Nf6 7.O-O *","weight":67894,"prior_delay":25,"prior_completion_time":0},
{"oid":1262,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Maroczy Bind, Reti Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.c4 Nf6 6.Nc3 d6 *","weight":67105,"prior_delay":25,"prior_completion_time":0},
{"oid":1263,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Maroczy Bind, Reti Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.c4 Qc7 *","weight":66583,"prior_delay":25,"prior_completion_time":0},
{"oid":1264,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Maroczy Bind, Reti Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.c4 Nf6 6.Nc3 Qc7 7.Be2 *","weight":67289,"prior_delay":25,"prior_completion_time":0},
{"oid":1265,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Bd3 Qb6 *","weight":67748,"prior_delay":25,"prior_completion_time":0},
{"oid":1266,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Bd3 Qc7 *","weight":67751,"prior_delay":25,"prior_completion_time":0},
{"oid":1267,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Bd3 Nc6 *","weight":67760,"prior_delay":25,"prior_completion_time":0},
{"oid":1268,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Bd3 Nf6 6.c4 *","weight":68039,"prior_delay":25,"prior_completion_time":0},
{"oid":1269,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Bd3 Nf6 6.O-O Qc7 7.c4 *","weight":68604,"prior_delay":25,"prior_completion_time":0},
{"oid":1270,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Bd3 Nf6 6.O-O Qc7 7.Qe2 d6 8.c4 *","weight":69064,"prior_delay":25,"prior_completion_time":0},
{"oid":1271,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Polugaevsky Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Bd3 Bc5 6.Nb3 Ba7 *","weight":68987,"prior_delay":25,"prior_completion_time":0},
{"oid":1272,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Swiss Cheese Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Bd3 g6 *","weight":67786,"prior_delay":25,"prior_completion_time":0},
{"oid":1273,"gid":229,"fullname":"Sicilian Defense, Kan Variation, Wing Attack","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Nc3 b5 6.Bd3 Qb6 7.Nb3 *","weight":67897,"prior_delay":25,"prior_completion_time":0},
{"oid":1274,"gid":229,"fullname":"Sicilian Defense, Kan Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Be2 Nf6 *","weight":66468,"prior_delay":25,"prior_completion_time":0},
{"oid":1275,"gid":229,"fullname":"Sicilian Defense, Kan Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.g3 *","weight":66255,"prior_delay":25,"prior_completion_time":0},
{"oid":1276,"gid":229,"fullname":"Sicilian Defense, Kan Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 a6 5.Be3 *","weight":66246,"prior_delay":25,"prior_completion_time":0},
{"oid":1277,"gid":230,"fullname":"Sicilian Defense, Kupreichik Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Bd7 6.Bg5 *","weight":106055,"prior_delay":25,"prior_completion_time":0},
{"oid":1278,"gid":231,"fullname":"Sicilian Defense, Kveinis Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Qb6 *","weight":64243,"prior_delay":25,"prior_completion_time":0},
{"oid":1279,"gid":233,"fullname":"Sicilian Defense, L\u00c3\u00b6wenthal Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 e5 5.Nb5 a6 6.Nd6+ Bxd6 7.Qxd6 Qf6  8.Qxf6 *","weight":58118,"prior_delay":25,"prior_completion_time":0},
{"oid":1280,"gid":233,"fullname":"Sicilian Defense, L\u00c3\u00b6wenthal Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 e5 5.Nb5 a6 6.Nd6+ Bxd6 7.Qxd6 Qf6  8.Qd1 *","weight":58111,"prior_delay":25,"prior_completion_time":0},
{"oid":1281,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation, Bird Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Ndb5 d6 7.Bg5 a6  8.Na3 Be6 *","weight":68167,"prior_delay":25,"prior_completion_time":0},
{"oid":1282,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation, Sveshnikov Variation, Chelyabinsk Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Ndb5 d6 7.Bg5 a6  8.Na3 b5 9.Nd5 Be7 10.Bxf6 Bxf6 11.c3 Bg5 *","weight":72616,"prior_delay":25,"prior_completion_time":0},
{"oid":1283,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation, Sveshnikov Variation, Chelyabinsk Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Ndb5 d6 7.Bg5 a6  8.Na3 b5 9.Nd5 Be7 10.Bxf6 Bxf6 11.c3 O-O 12.Nc2 Bg5 13.a4 *","weight":73016,"prior_delay":25,"prior_completion_time":0},
{"oid":1284,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation, Sveshnikov Variation, Novosibirsk Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Ndb5 d6 7.Bg5 a6  8.Na3 b5 9.Bxf6 gxf6 10.Nd5 Bg7 11.Bd3 *","weight":70932,"prior_delay":25,"prior_completion_time":0},
{"oid":1285,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation, Sveshnikov Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Ndb5 d6 7.Bg5 a6  8.Na3 b5 9.Bxf6 gxf6 10.Nd5 f5 11.c3 Bg7 12.exf5 Bxf5 13.Nc2 O-O *","weight":71729,"prior_delay":25,"prior_completion_time":0},
{"oid":1286,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation, Sveshnikov Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Ndb5 d6 7.Bg5 a6  8.Na3 b5 9.Bxf6 gxf6 10.Nd5 f5 11.Bd3 Be6 12.O-O *","weight":71670,"prior_delay":25,"prior_completion_time":0},
{"oid":1287,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Ndb5 h6 *","weight":63486,"prior_delay":25,"prior_completion_time":0},
{"oid":1288,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Ndb5 d6 7.Na3 *","weight":64574,"prior_delay":25,"prior_completion_time":0},
{"oid":1289,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Nf5 *","weight":62290,"prior_delay":25,"prior_completion_time":0},
{"oid":1290,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Ndb5 d6 7.Bg5 a6 8.Bxf6 *","weight":66861,"prior_delay":25,"prior_completion_time":0},
{"oid":1291,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Ndb5 d6 7.a4 a6 8.Na3 *","weight":64614,"prior_delay":25,"prior_completion_time":0},
{"oid":1292,"gid":232,"fullname":"Sicilian Defense, Lasker-Pelikan Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Ndb5 d6 7.Nd5 *","weight":64637,"prior_delay":25,"prior_completion_time":0},
{"oid":1293,"gid":235,"fullname":"Sicilian Defense, Marshall Counterattack","main_line":"1.e4 c5 2.Nf3 e6 3.d4 d5 *","weight":51837,"prior_delay":25,"prior_completion_time":0},
{"oid":1294,"gid":236,"fullname":"Sicilian Defense, McDonnell Attack, Tal Gambit","main_line":"1.e4 c5 2.f4 d5 3.exd5 Nf6 4.Bb5+ *","weight":21894,"prior_delay":25,"prior_completion_time":0},
{"oid":1295,"gid":236,"fullname":"Sicilian Defense, McDonnell Attack","main_line":"1.e4 c5 2.f4 Nc6 3.Nf3 g6 *","weight":21837,"prior_delay":25,"prior_completion_time":0},
{"oid":1296,"gid":236,"fullname":"Sicilian Defense, McDonnell Attack","main_line":"1.e4 c5 2.f4 d5 3.exd5 Qxd5 *","weight":21874,"prior_delay":25,"prior_completion_time":0},
{"oid":1297,"gid":236,"fullname":"Sicilian Defense, McDonnell Attack","main_line":"1.e4 c5 2.f4 Nc6 3.Nf3 e6 *","weight":21834,"prior_delay":25,"prior_completion_time":0},
{"oid":1298,"gid":237,"fullname":"Sicilian Defense, Modern Variations, Anti-Qxd4 Move Order","main_line":"1.e4 c5 2.Nf3 d6 3.d4 Nf6 *","weight":63866,"prior_delay":25,"prior_completion_time":0},
{"oid":1299,"gid":237,"fullname":"Sicilian Defense, Modern Variations","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nbd7 6.Bc4 *","weight":105947,"prior_delay":25,"prior_completion_time":0},
{"oid":1300,"gid":238,"fullname":"Sicilian Defense, Moscow Variation, Sokolsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Bd7 4.Bxd7+ Qxd7 5.c4 Nc6 6.O-O Nf6 *","weight":56191,"prior_delay":25,"prior_completion_time":0},
{"oid":1301,"gid":238,"fullname":"Sicilian Defense, Moscow Variation, Sokolsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Bd7 4.Bxd7+ Qxd7 5.c4 Nc6 6.Nc3 Nf6 7.O-O *","weight":56301,"prior_delay":25,"prior_completion_time":0},
{"oid":1302,"gid":238,"fullname":"Sicilian Defense, Moscow Variation","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Nc6 4.O-O Bd7 5.Re1 a6 *","weight":55018,"prior_delay":25,"prior_completion_time":0},
{"oid":1303,"gid":238,"fullname":"Sicilian Defense, Moscow Variation","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Nd7 4.d4 Nf6 5.Nc3 cxd4 6.Qxd4 e5 *","weight":54201,"prior_delay":25,"prior_completion_time":0},
{"oid":1304,"gid":238,"fullname":"Sicilian Defense, Moscow Variation","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Nd7 4.d4 cxd4 *","weight":54074,"prior_delay":25,"prior_completion_time":0},
{"oid":1305,"gid":238,"fullname":"Sicilian Defense, Moscow Variation","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Nc6 4.Bxc6+ *","weight":53226,"prior_delay":25,"prior_completion_time":0},
{"oid":1306,"gid":238,"fullname":"Sicilian Defense, Moscow Variation","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Nc6 4.O-O Bd7 5.Re1 Nf6 6.c3 a6 7.Ba4 *","weight":56283,"prior_delay":25,"prior_completion_time":0},
{"oid":1307,"gid":238,"fullname":"Sicilian Defense, Moscow Variation","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Nc6 4.O-O Bd7 5.Re1 Nf6 6.c3 a6 7.Bf1 Bg4 8.h3 *","weight":56772,"prior_delay":25,"prior_completion_time":0},
{"oid":1308,"gid":238,"fullname":"Sicilian Defense, Moscow Variation","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Nd7 4.c3 *","weight":53680,"prior_delay":25,"prior_completion_time":0},
{"oid":1309,"gid":238,"fullname":"Sicilian Defense, Moscow Variation","main_line":"1.e4 c5 2.Nf3 d6 3.Bb5+ Nd7 4.O-O Nf6 5.Re1 a6 6.Bf1 *","weight":54169,"prior_delay":25,"prior_completion_time":0},
{"oid":1310,"gid":239,"fullname":"Sicilian Defense, Najdorf, Lipnitsky Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bc4 *","weight":113459,"prior_delay":25,"prior_completion_time":0},
{"oid":1311,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Adams Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.h3 *","weight":113736,"prior_delay":25,"prior_completion_time":0},
{"oid":1312,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Amsterdam Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.f4 Nc6 *","weight":113433,"prior_delay":25,"prior_completion_time":0},
{"oid":1313,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Amsterdam Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.f4 e5 7.Nf3 Nbd7 8.Bd3 *","weight":113823,"prior_delay":25,"prior_completion_time":0},
{"oid":1314,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Amsterdam Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.f4 e5 7.Nf3 Nbd7 8.a4 Be7  9.Bd3 O-O 10.O-O exf4 *","weight":114199,"prior_delay":25,"prior_completion_time":0},
{"oid":1315,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Amsterdam Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.f4 Qc7 7.Bd3 g6 *","weight":113531,"prior_delay":25,"prior_completion_time":0},
{"oid":1316,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Amsterdam Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.f4 e5 7.Nf3 Nbd7 8.a4 Qc7 *","weight":113929,"prior_delay":25,"prior_completion_time":0},
{"oid":1317,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Amsterdam Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.f4 e5 7.Nf3 Nbd7 8.a4 Be7  9.Bd3 O-O 10.O-O Nc5 *","weight":114204,"prior_delay":25,"prior_completion_time":0},
{"oid":1318,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Amsterdam Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.f4 Qc7 7.Nf3 Nbd7 8.Bd3 *","weight":113518,"prior_delay":25,"prior_completion_time":0},
{"oid":1319,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Amsterdam Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.f4 e5 7.Nf3 Nbd7 8.a4 Be7  9.Bc4 *","weight":114023,"prior_delay":25,"prior_completion_time":0},
{"oid":1320,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Amsterdam Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.f4 e5 7.Nf3 Qc7 8.a4 *","weight":113708,"prior_delay":25,"prior_completion_time":0},
{"oid":1321,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Amsterdam Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.f4 Nbd7 7.Be2 *","weight":113480,"prior_delay":25,"prior_completion_time":0},
{"oid":1322,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Browne Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 h6  9.Bh4 Qc7 10.O-O-O Nbd7 11.Qg3 *","weight":116392,"prior_delay":25,"prior_completion_time":0},
{"oid":1323,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Browne Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 h6  9.Bh4 Qc7 10.O-O-O Nbd7 11.Be2 *","weight":116412,"prior_delay":25,"prior_completion_time":0},
{"oid":1324,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, English Attack, Anti-English","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be3 Ng4 7.Bg5 h6 8.Bh4 g5  9.Bg3 Bg7 10.Be2 h5 *","weight":118578,"prior_delay":25,"prior_completion_time":0},
{"oid":1325,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, English Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be3 e5 7.Nb3 Be6 8.f3 Be7  9.Qd2 Nbd7 *","weight":120595,"prior_delay":25,"prior_completion_time":0},
{"oid":1326,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, English Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be3 e5 7.Nb3 Be6 8.f3 Nbd7  9.Qd2 b5 *","weight":120322,"prior_delay":25,"prior_completion_time":0},
{"oid":1327,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, English Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be3 e5 7.Nb3 Be6 8.f3 Be7  9.Qd2 O-O 10.O-O-O Nbd7 *","weight":121397,"prior_delay":25,"prior_completion_time":0},
{"oid":1328,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, English Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be3 e5 7.Nb3 Be6 8.Qd2 *","weight":119613,"prior_delay":25,"prior_completion_time":0},
{"oid":1329,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Goteborg (Argentine)","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 h6  9.Bh4 g5 10.fxg5 Nfd7 11.Nxe6 *","weight":116356,"prior_delay":25,"prior_completion_time":0},
{"oid":1330,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 Qc7  9.O-O-O Nbd7 10.g4 b5 11.Bxf6 gxf6 *","weight":116680,"prior_delay":25,"prior_completion_time":0},
{"oid":1331,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 Qc7  9.O-O-O Nbd7 10.g4 b5 11.Bxf6 Nxf6 12.g5 Nd7 13.f5 Bxg5+ 14.Kb1 Ne5 *","weight":116819,"prior_delay":25,"prior_completion_time":0},
{"oid":1332,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 Qc7  9.O-O-O Nbd7 10.g4 h6 *","weight":116613,"prior_delay":25,"prior_completion_time":0},
{"oid":1333,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 Qc7  9.O-O-O Nbd7 10.g4 b5 11.Bxf6 Nxf6 12.g5 Nd7 13.a3 *","weight":116760,"prior_delay":25,"prior_completion_time":0},
{"oid":1334,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 Qc7  9.O-O-O Nbd7 10.Qg3 *","weight":116545,"prior_delay":25,"prior_completion_time":0},
{"oid":1335,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 Qc7  9.O-O-O Nbd7 10.Bd3 b5 11.Rhe1 Bb7 12.Qg3 *","weight":116635,"prior_delay":25,"prior_completion_time":0},
{"oid":1336,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 Qc7  9.O-O-O Nbd7 10.Bd3 h6 11.Qh3 *","weight":116621,"prior_delay":25,"prior_completion_time":0},
{"oid":1337,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 Qc7  9.O-O-O Nbd7 10.Bd3 h6 11.Bh4 g5 12.fxg5 *","weight":116650,"prior_delay":25,"prior_completion_time":0},
{"oid":1338,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 Qc7  9.O-O-O Nbd7 10.g4 b5 11.Bxf6 Nxf6 12.g5 Nd7 13.f5 Nc5 14.f6 gxf6 15.gxf6  Bf8 16.Rg1 *","weight":116828,"prior_delay":25,"prior_completion_time":0},
{"oid":1339,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 Qc7  9.O-O-O Nbd7 10.g4 b5 11.Bxf6 Nxf6 12.g5 Nd7 13.f5 Nc5 14.h4 *","weight":116800,"prior_delay":25,"prior_completion_time":0},
{"oid":1340,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Neo-Classical Defense","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Nc6 8.e5 *","weight":116098,"prior_delay":25,"prior_completion_time":0},
{"oid":1341,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Neo-Classical Defense","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Nc6 8.Nxc6 *","weight":116098,"prior_delay":25,"prior_completion_time":0},
{"oid":1342,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Opocensky Variation, Modern Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be2 e5 7.Nb3 Be7 8.O-O Be6  9.f4 *","weight":117671,"prior_delay":25,"prior_completion_time":0},
{"oid":1343,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Opocensky Variation, Traditional Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be2 e5 7.Nb3 Be7 8.O-O O-O  9.Be3 Be6 10.a4 *","weight":118271,"prior_delay":25,"prior_completion_time":0},
{"oid":1344,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Opocensky Variation, Traditional Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be2 e5 7.Nb3 Be7 8.O-O O-O  9.Kh1 Be6 *","weight":118246,"prior_delay":25,"prior_completion_time":0},
{"oid":1345,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Opocensky Variation, Traditional Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be2 e5 7.Nb3 Be7 8.O-O O-O  9.Kh1 Qc7 *","weight":118254,"prior_delay":25,"prior_completion_time":0},
{"oid":1346,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Opocensky Variation, Traditional Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be2 e5 7.Nb3 Be7 8.O-O O-O  9.Kh1 Nc6 *","weight":118307,"prior_delay":25,"prior_completion_time":0},
{"oid":1347,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Opocensky Variation, Traditional Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be2 e5 7.Nb3 Be7 8.O-O O-O  9.Be3 Be6 10.Nd5 *","weight":118289,"prior_delay":25,"prior_completion_time":0},
{"oid":1348,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Opocensky Variation, Traditional Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be2 e5 7.Nb3 Be7 8.O-O O-O  9.Be3 Be6 10.Qd2 *","weight":118344,"prior_delay":25,"prior_completion_time":0},
{"oid":1349,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Opocensky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be2 Nbd7 *","weight":114596,"prior_delay":25,"prior_completion_time":0},
{"oid":1350,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Opocensky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be2 e5 7.Nb3 Be7 8.Be3 Be6 *","weight":117154,"prior_delay":25,"prior_completion_time":0},
{"oid":1351,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Opocensky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Be2 e5 7.Nf3 *","weight":115408,"prior_delay":25,"prior_completion_time":0},
{"oid":1352,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Accepted","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Qd2  Qxb2 9.Rb1 Qa3 10.f5 Nc6 11.fxe6 fxe6 12.Nxc6 bxc6 13.e5 dxe5 14.Bxf6 gxf6  15.Ne4 Be7 16.Be2 h5 17.Rb3 Qa4 18.Nxf6+ *","weight":117222,"prior_delay":25,"prior_completion_time":0},
{"oid":1353,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Accepted","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Qd2  Qxb2 9.Rb1 Qa3 10.f5 Nc6 11.fxe6 fxe6 12.Nxc6 bxc6 13.Be2 *","weight":117127,"prior_delay":25,"prior_completion_time":0},
{"oid":1354,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Accepted","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Qd2  Qxb2 9.Rb1 Qa3 10.f5 Nc6 11.fxe6 fxe6 12.Nxc6 bxc6 13.e5 dxe5 14.Bxf6 gxf6  15.Ne4 Qxa2 *","weight":117220,"prior_delay":25,"prior_completion_time":0},
{"oid":1355,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Accepted","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Qd2  Qxb2 9.Rb1 Qa3 10.Bxf6 gxf6 11.Be2 Nc6 *","weight":116891,"prior_delay":25,"prior_completion_time":0},
{"oid":1356,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Accepted","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Qd2  Qxb2 9.Rb1 Qa3 10.Bxf6 gxf6 11.Be2 Bg7 *","weight":116890,"prior_delay":25,"prior_completion_time":0},
{"oid":1357,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Accepted","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Qd2  Qxb2 9.Rb1 Qa3 10.Be2 Be7 11.O-O Nbd7 *","weight":116878,"prior_delay":25,"prior_completion_time":0},
{"oid":1358,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Qd2  Qxb2 9.Nb3 Qa3 10.Bxf6 *","weight":116751,"prior_delay":25,"prior_completion_time":0},
{"oid":1359,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Qd3 *","weight":116364,"prior_delay":25,"prior_completion_time":0},
{"oid":1360,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Nb3 Be7 *","weight":116396,"prior_delay":25,"prior_completion_time":0},
{"oid":1361,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Bxf6 *","weight":116318,"prior_delay":25,"prior_completion_time":0},
{"oid":1362,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Qd2  Qxb2 9.Nb3 Nc6 *","weight":116705,"prior_delay":25,"prior_completion_time":0},
{"oid":1363,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.a3 *","weight":116329,"prior_delay":25,"prior_completion_time":0},
{"oid":1364,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Poisoned Pawn Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qb6 8.Nb3 Nbd7  9.Qf3 Be7 *","weight":116403,"prior_delay":25,"prior_completion_time":0},
{"oid":1365,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Polugaevsky Variation, Simagin Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 b5 8.e5 dxe5  9.fxe5 Qc7 10.Qe2 Nfd7 11.O-O-O Bb7 12.Qg4 Qxe5 *","weight":116199,"prior_delay":25,"prior_completion_time":0},
{"oid":1366,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Polugaevsky Variation, Simagin Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 b5 8.e5 dxe5  9.fxe5 Qc7 10.Qe2 Nfd7 11.O-O-O Nc6 *","weight":116189,"prior_delay":25,"prior_completion_time":0},
{"oid":1367,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Polugaevsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 b5 8.e5 dxe5  9.fxe5 Qc7 10.Bxb5+ *","weight":116167,"prior_delay":25,"prior_completion_time":0},
{"oid":1368,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Polugaevsky Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 b5 8.e5 dxe5  9.fxe5 Qc7 10.exf6 Qe5+ 11.Be2 Qxg5 12.Qd3 *","weight":116230,"prior_delay":25,"prior_completion_time":0},
{"oid":1369,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Scheveningen Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Nbd7 8.f4 *","weight":107100,"prior_delay":25,"prior_completion_time":0},
{"oid":1370,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Scheveningen Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Nbd7 8.a4 *","weight":107093,"prior_delay":25,"prior_completion_time":0},
{"oid":1371,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Traditional Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Be7 8.Qf3 Qc7  9.O-O-O Nc6 10.Nxc6 *","weight":116477,"prior_delay":25,"prior_completion_time":0},
{"oid":1372,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Zagreb (Fianchetto) Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.g3 Bg4 *","weight":113446,"prior_delay":25,"prior_completion_time":0},
{"oid":1373,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Zagreb (Fianchetto) Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.g3 e5 7.Nde2 Be7  8.Bg2 Nbd7 *","weight":113941,"prior_delay":25,"prior_completion_time":0},
{"oid":1374,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Zagreb (Fianchetto) Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.g3 e5 7.Nde2 b5 *","weight":113809,"prior_delay":25,"prior_completion_time":0},
{"oid":1375,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Zagreb (Fianchetto) Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.g3 e5 7.Nde2 Nbd7 *","weight":113825,"prior_delay":25,"prior_completion_time":0},
{"oid":1376,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Zagreb (Fianchetto) Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.g3 Nc6 *","weight":113460,"prior_delay":25,"prior_completion_time":0},
{"oid":1377,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Zagreb (Fianchetto) Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.g3 e5 7.Nb3 Be7 *","weight":113896,"prior_delay":25,"prior_completion_time":0},
{"oid":1378,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Zagreb (Fianchetto) Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.g3 g6 *","weight":113469,"prior_delay":25,"prior_completion_time":0},
{"oid":1379,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation, Zagreb (Fianchetto) Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.g3 e5 7.Nde2 Be7  8.Bg2 O-O *","weight":113970,"prior_delay":25,"prior_completion_time":0},
{"oid":1380,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 b5 *","weight":114327,"prior_delay":25,"prior_completion_time":0},
{"oid":1381,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.Qd3 Nc6 *","weight":115157,"prior_delay":25,"prior_completion_time":0},
{"oid":1382,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Bd7 *","weight":116055,"prior_delay":25,"prior_completion_time":0},
{"oid":1383,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 Nbd7 7.f4 b5 *","weight":114637,"prior_delay":25,"prior_completion_time":0},
{"oid":1384,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.Qf3 Be7 8.O-O-O  Nbd7 *","weight":115173,"prior_delay":25,"prior_completion_time":0},
{"oid":1385,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.Qf3 h6 *","weight":115185,"prior_delay":25,"prior_completion_time":0},
{"oid":1386,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Nbd7 8.Qe2  Qc7 *","weight":116285,"prior_delay":25,"prior_completion_time":0},
{"oid":1387,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.Qe2 *","weight":115162,"prior_delay":25,"prior_completion_time":0},
{"oid":1388,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.a4 e5 *","weight":113347,"prior_delay":25,"prior_completion_time":0},
{"oid":1389,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Nbd7 8.Qf3  Qc7 9.O-O-O b5 10.e5 *","weight":116601,"prior_delay":25,"prior_completion_time":0},
{"oid":1390,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.Be2 *","weight":115154,"prior_delay":25,"prior_completion_time":0},
{"oid":1391,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 h6 8.Bh4 Qb6 *","weight":116324,"prior_delay":25,"prior_completion_time":0},
{"oid":1392,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.Qf3 Nbd7 *","weight":115174,"prior_delay":25,"prior_completion_time":0},
{"oid":1393,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.f3 *","weight":113632,"prior_delay":25,"prior_completion_time":0},
{"oid":1394,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Nbd7 8.Bc4 *","weight":116216,"prior_delay":25,"prior_completion_time":0},
{"oid":1395,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 Nbd7 7.Bc4 e6 8.O-O *","weight":114662,"prior_delay":25,"prior_completion_time":0},
{"oid":1396,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qc7 8.Qf3 b5  9.O-O-O *","weight":116213,"prior_delay":25,"prior_completion_time":0},
{"oid":1397,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qc7 8.Qf3 b5  9.Bxf6 *","weight":116219,"prior_delay":25,"prior_completion_time":0},
{"oid":1398,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 Nbd7 7.Bc4 Qa5 8.Qd2  e6 9.O-O-O *","weight":114676,"prior_delay":25,"prior_completion_time":0},
{"oid":1399,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Nbd7 8.Qf3  Qc7 9.O-O-O b5 10.Bd3 *","weight":116637,"prior_delay":25,"prior_completion_time":0},
{"oid":1400,"gid":240,"fullname":"Sicilian Defense, Najdorf Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 a6 6.Bg5 e6 7.f4 Qc7 8.Bxf6 *","weight":116229,"prior_delay":25,"prior_completion_time":0},
{"oid":1401,"gid":241,"fullname":"Sicilian Defense, Nimzo-American Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 d5 *","weight":57059,"prior_delay":25,"prior_completion_time":0},
{"oid":1402,"gid":242,"fullname":"Sicilian Defense, Nimzowitsch Variation, Advance Variation","main_line":"1.e4 c5 2.Nf3 Nf6 3.e5 Nd5 4.d4 *","weight":37912,"prior_delay":25,"prior_completion_time":0},
{"oid":1403,"gid":242,"fullname":"Sicilian Defense, Nimzowitsch Variation, Closed Variation","main_line":"1.e4 c5 2.Nf3 Nf6 3.Nc3 d5 4.exd5 *","weight":37867,"prior_delay":25,"prior_completion_time":0},
{"oid":1404,"gid":242,"fullname":"Sicilian Defense, Nimzowitsch Variation, Exchange Variation","main_line":"1.e4 c5 2.Nf3 Nf6 3.e5 Nd5 4.Nc3 Nxc3 *","weight":37998,"prior_delay":25,"prior_completion_time":0},
{"oid":1405,"gid":242,"fullname":"Sicilian Defense, Nimzowitsch Variation, Main Line","main_line":"1.e4 c5 2.Nf3 Nf6 3.e5 Nd5 4.Nc3 e6 5.Nxd5 exd5 6.d4 Nc6 7.dxc5 *","weight":38072,"prior_delay":25,"prior_completion_time":0},
{"oid":1406,"gid":243,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack, Gurgenidze Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 g6 4.O-O Bg7 5.Re1 e5 6.b4 *","weight":48779,"prior_delay":25,"prior_completion_time":0},
{"oid":1407,"gid":243,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 Qb6 *","weight":47116,"prior_delay":25,"prior_completion_time":0},
{"oid":1408,"gid":243,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 Nf6 *","weight":47306,"prior_delay":25,"prior_completion_time":0},
{"oid":1409,"gid":243,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 e6 4.O-O Nge7 5.c3 a6 *","weight":49011,"prior_delay":25,"prior_completion_time":0},
{"oid":1410,"gid":243,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 e6 4.Nc3 *","weight":47765,"prior_delay":25,"prior_completion_time":0},
{"oid":1411,"gid":243,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 e6 4.O-O Nge7 5.b3 *","weight":48762,"prior_delay":25,"prior_completion_time":0},
{"oid":1412,"gid":243,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 e6 4.b3 *","weight":47765,"prior_delay":25,"prior_completion_time":0},
{"oid":1413,"gid":243,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 e6 4.Bxc6 *","weight":47979,"prior_delay":25,"prior_completion_time":0},
{"oid":1414,"gid":243,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 e6 4.O-O Nge7 5.Nc3 *","weight":48752,"prior_delay":25,"prior_completion_time":0},
{"oid":1415,"gid":243,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 e6 4.O-O Nge7 5.Re1 *","weight":48916,"prior_delay":25,"prior_completion_time":0},
{"oid":1416,"gid":244,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 g6 4.O-O Bg7 5.c3 e5 *","weight":48697,"prior_delay":25,"prior_completion_time":0},
{"oid":1417,"gid":244,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 g6 4.O-O Bg7 5.Re1 e5 6.c3 *","weight":48782,"prior_delay":25,"prior_completion_time":0},
{"oid":1418,"gid":244,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 g6 4.Nc3 *","weight":47845,"prior_delay":25,"prior_completion_time":0},
{"oid":1419,"gid":244,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 g6 4.Bxc6 dxc6 5.d3 Bg7 6.h3 *","weight":49554,"prior_delay":25,"prior_completion_time":0},
{"oid":1420,"gid":244,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 g6 4.O-O Bg7 5.Re1 Nf6 6.c3 O-O 7.d4 *","weight":48900,"prior_delay":25,"prior_completion_time":0},
{"oid":1421,"gid":244,"fullname":"Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation","main_line":"1.e4 c5 2.Nf3 Nc6 3.Bb5 g6 4.O-O Bg7 5.Re1 Nf6 6.c3 O-O 7.h3 *","weight":48920,"prior_delay":25,"prior_completion_time":0},
{"oid":1422,"gid":245,"fullname":"Sicilian Defense, O'Kelly Variation, Maroczy Bind, Paulsen Line","main_line":"1.e4 c5 2.Nf3 a6 3.c4 e6 *","weight":37863,"prior_delay":25,"prior_completion_time":0},
{"oid":1423,"gid":245,"fullname":"Sicilian Defense, O'Kelly Variation, Normal System","main_line":"1.e4 c5 2.Nf3 a6 3.d4 cxd4 4.Nxd4 Nf6 *","weight":37827,"prior_delay":25,"prior_completion_time":0},
{"oid":1424,"gid":245,"fullname":"Sicilian Defense, O'Kelly Variation, Venice System","main_line":"1.e4 c5 2.Nf3 a6 3.c3 e6 *","weight":37927,"prior_delay":25,"prior_completion_time":0},
{"oid":1425,"gid":245,"fullname":"Sicilian Defense, O'Kelly Variation, Venice System","main_line":"1.e4 c5 2.Nf3 a6 3.c3 d5 *","weight":37938,"prior_delay":25,"prior_completion_time":0},
{"oid":1426,"gid":246,"fullname":"Sicilian Defense, Old Sicilian","main_line":"1.e4 c5 2.Nf3 Nc6 3.Nc3 Nf6 *","weight":45636,"prior_delay":25,"prior_completion_time":0},
{"oid":1427,"gid":246,"fullname":"Sicilian Defense, Old Sicilian","main_line":"1.e4 c5 2.Nf3 Nc6 3.Nc3 g6 *","weight":45743,"prior_delay":25,"prior_completion_time":0},
{"oid":1428,"gid":246,"fullname":"Sicilian Defense, Old Sicilian","main_line":"1.e4 c5 2.Nf3 Nc6 3.Nc3 e5 *","weight":45729,"prior_delay":25,"prior_completion_time":0},
{"oid":1429,"gid":246,"fullname":"Sicilian Defense, Old Sicilian","main_line":"1.e4 c5 2.Nf3 Nc6 3.g3 e6 4.d3 d5 *","weight":44487,"prior_delay":25,"prior_completion_time":0},
{"oid":1430,"gid":247,"fullname":"Sicilian Defense, Open","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 d6 *","weight":57061,"prior_delay":25,"prior_completion_time":0},
{"oid":1431,"gid":247,"fullname":"Sicilian Defense, Open","main_line":"1.e4 c5 2.Nf3 Nc6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Qb6 6.Nb3 e6 7.Bd3 *","weight":61485,"prior_delay":25,"prior_completion_time":0},
{"oid":1432,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation, English Attack","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Qd2 Nf6 8.f3 *","weight":75001,"prior_delay":25,"prior_completion_time":0},
{"oid":1433,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 Nf6 *","weight":73281,"prior_delay":25,"prior_completion_time":0},
{"oid":1434,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.g3 a6 7.Bg2 Nf6 8.O-O Bc5 *","weight":73287,"prior_delay":25,"prior_completion_time":0},
{"oid":1435,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Be2 Nf6 8.O-O b5 *","weight":74176,"prior_delay":25,"prior_completion_time":0},
{"oid":1436,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Bd3 Nf6  8.O-O h5 *","weight":74332,"prior_delay":25,"prior_completion_time":0},
{"oid":1437,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Bd3 Nf6  8.O-O Bd6 *","weight":74341,"prior_delay":25,"prior_completion_time":0},
{"oid":1438,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be2 a6 7.O-O b5 *","weight":73807,"prior_delay":25,"prior_completion_time":0},
{"oid":1439,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Bd3 Nf6  8.O-O Nxd4 *","weight":74358,"prior_delay":25,"prior_completion_time":0},
{"oid":1440,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be2 Nf6 *","weight":72840,"prior_delay":25,"prior_completion_time":0},
{"oid":1441,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Be2 Nf6  8.O-O Bb4 9.Na4 O-O *","weight":74618,"prior_delay":25,"prior_completion_time":0},
{"oid":1442,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Be2 Nf6  8.O-O Be7 *","weight":74395,"prior_delay":25,"prior_completion_time":0},
{"oid":1443,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be2 a6 7.O-O Nf6 8.Kh1  Be7 *","weight":74452,"prior_delay":25,"prior_completion_time":0},
{"oid":1444,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.f4 b5 *","weight":73955,"prior_delay":25,"prior_completion_time":0},
{"oid":1445,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Bd3 Nf6  8.O-O b5 *","weight":74347,"prior_delay":25,"prior_completion_time":0},
{"oid":1446,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be2 a6 7.O-O Nf6 8.a3 *","weight":74212,"prior_delay":25,"prior_completion_time":0},
{"oid":1447,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Be2 Nf6 8.a3 b5 *","weight":74110,"prior_delay":25,"prior_completion_time":0},
{"oid":1448,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Bd3 Nf6  8.O-O Ne5 9.h3 Bc5 10.Kh1 *","weight":74455,"prior_delay":25,"prior_completion_time":0},
{"oid":1449,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Be2 Nf6 8.a3 Be7  9.O-O *","weight":74110,"prior_delay":25,"prior_completion_time":0},
{"oid":1450,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be2 a6 7.O-O Nf6 8.Kh1  Nxd4 9.Qxd4 Bc5 10.Qd3 b5 11.f4 *","weight":74697,"prior_delay":25,"prior_completion_time":0},
{"oid":1451,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Bd3 b5 8.O-O *","weight":74116,"prior_delay":25,"prior_completion_time":0},
{"oid":1452,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Bd3 b5 8.Nxc6 *","weight":74106,"prior_delay":25,"prior_completion_time":0},
{"oid":1453,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Be2 b5 8.Nxc6 *","weight":74068,"prior_delay":25,"prior_completion_time":0},
{"oid":1454,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.g3 a6 7.Bg2 d6 8.O-O Bd7  9.Re1 *","weight":73222,"prior_delay":25,"prior_completion_time":0},
{"oid":1455,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be2 a6 7.f4 *","weight":73133,"prior_delay":25,"prior_completion_time":0},
{"oid":1456,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Ndb5 *","weight":71911,"prior_delay":25,"prior_completion_time":0},
{"oid":1457,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.f4 a6 7.Nxc6 *","weight":72267,"prior_delay":25,"prior_completion_time":0},
{"oid":1458,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.g3 a6 7.Bg2 Nf6 8.O-O Be7  9.Re1 *","weight":73317,"prior_delay":25,"prior_completion_time":0},
{"oid":1459,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Nxc6 *","weight":71905,"prior_delay":25,"prior_completion_time":0},
{"oid":1460,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.a3 *","weight":73946,"prior_delay":25,"prior_completion_time":0},
{"oid":1461,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Be2 Nf6  8.O-O Bb4 9.Nxc6 *","weight":74361,"prior_delay":25,"prior_completion_time":0},
{"oid":1462,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Bastrikov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Qc7 6.Be3 a6 7.Be2 Nf6  8.O-O Bb4 9.Na4 Be7 10.Nxc6 bxc6 11.Nb6 *","weight":75023,"prior_delay":25,"prior_completion_time":0},
{"oid":1463,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Gary Gambit","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nb5 d6 6.c4 Nf6 7.N1c3 a6 8.Na3 d5 *","weight":67825,"prior_delay":25,"prior_completion_time":0},
{"oid":1464,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Modern Line","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nb5 d6 6.c4 Nf6 7.N1c3 a6 8.Na3 Be7  9.Be2 O-O 10.O-O b6 11.Be3 Bd7 *","weight":68254,"prior_delay":25,"prior_completion_time":0},
{"oid":1465,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Modern Line","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nb5 d6 6.c4 Nf6 7.N1c3 a6 8.Na3 Be7  9.Be2 O-O 10.O-O b6 11.Be3 Ne5 *","weight":68271,"prior_delay":25,"prior_completion_time":0},
{"oid":1466,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Modern Line","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nb5 d6 6.c4 Nf6 7.N1c3 a6 8.Na3 Be7  9.Be2 O-O 10.O-O b6 11.Be3 Bb7 12.Qb3 *","weight":68334,"prior_delay":25,"prior_completion_time":0},
{"oid":1467,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Normal Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 Bb4 *","weight":70109,"prior_delay":25,"prior_completion_time":0},
{"oid":1468,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Normal Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 d6 6.g4 *","weight":70535,"prior_delay":25,"prior_completion_time":0},
{"oid":1469,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Sz\u00c3\u00a9n Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nb5 d6 6.c4 Nf6 7.N1c3 a6 8.Na3 Be7  9.Be2 b6 *","weight":67994,"prior_delay":25,"prior_completion_time":0},
{"oid":1470,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Sz\u00c3\u00a9n Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nb5 d6 6.c4 Nf6 7.N1c3 a6 8.Na3 b6 *","weight":67851,"prior_delay":25,"prior_completion_time":0},
{"oid":1471,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Sz\u00c3\u00a9n Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nb5 d6 6.c4 a6 *","weight":67305,"prior_delay":25,"prior_completion_time":0},
{"oid":1472,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Sz\u00c3\u00a9n Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nb5 d6 6.Bf4 e5 7.Be3 a6 *","weight":67333,"prior_delay":25,"prior_completion_time":0},
{"oid":1473,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Sz\u00c3\u00a9n Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nb5 d6 6.Bf4 e5 7.Be3 Nf6 *","weight":67331,"prior_delay":25,"prior_completion_time":0},
{"oid":1474,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Sz\u00c3\u00a9n Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nb5 d6 6.c4 Nf6 7.N5c3 *","weight":67457,"prior_delay":25,"prior_completion_time":0},
{"oid":1475,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Taimanov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 a6 6.Be2 Nge7 7.O-O *","weight":71171,"prior_delay":25,"prior_completion_time":0},
{"oid":1476,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation, Taimanov Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 a6 6.Be2 Nge7 7.Be3 *","weight":71165,"prior_delay":25,"prior_completion_time":0},
{"oid":1477,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 a6 6.Be2 Nf6 *","weight":71139,"prior_delay":25,"prior_completion_time":0},
{"oid":1478,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 a6 6.Be3 Nge7 *","weight":71028,"prior_delay":25,"prior_completion_time":0},
{"oid":1479,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 a6 6.Be2 d6 *","weight":71266,"prior_delay":25,"prior_completion_time":0},
{"oid":1480,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 a6 6.g3 Nge7 *","weight":70967,"prior_delay":25,"prior_completion_time":0},
{"oid":1481,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 a6 6.Be3 d6 *","weight":71024,"prior_delay":25,"prior_completion_time":0},
{"oid":1482,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 a6 6.g3 d6 *","weight":71012,"prior_delay":25,"prior_completion_time":0},
{"oid":1483,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 a6 6.Be3 Nf6 *","weight":71150,"prior_delay":25,"prior_completion_time":0},
{"oid":1484,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 a6 6.Nxc6 bxc6 7.Bd3 d5 *","weight":71916,"prior_delay":25,"prior_completion_time":0},
{"oid":1485,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.c4 Nf6 6.Nc3 Bb4 7.Nxc6 bxc6 *","weight":66782,"prior_delay":25,"prior_completion_time":0},
{"oid":1486,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.g3 *","weight":66688,"prior_delay":25,"prior_completion_time":0},
{"oid":1487,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nxc6 bxc6 6.Bd3 *","weight":66707,"prior_delay":25,"prior_completion_time":0},
{"oid":1488,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Nc3 a6 6.f4 *","weight":70874,"prior_delay":25,"prior_completion_time":0},
{"oid":1489,"gid":248,"fullname":"Sicilian Defense, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nc6 5.Be2 *","weight":66696,"prior_delay":25,"prior_completion_time":0},
{"oid":1490,"gid":249,"fullname":"Sicilian Defense, Paulsen-Basman Defense","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Bc5 *","weight":64211,"prior_delay":25,"prior_completion_time":0},
{"oid":1491,"gid":250,"fullname":"Sicilian Defense, Pin Variation, Koch Variation","main_line":"1.e4 c5 2.Nf3 e6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Bb4 6.e5 *","weight":65851,"prior_delay":25,"prior_completion_time":0},
{"oid":1492,"gid":251,"fullname":"Sicilian Defense, Prins Variation, Venice Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.f3 e5 6.Bb5+ Bd7 *","weight":94806,"prior_delay":25,"prior_completion_time":0},
{"oid":1493,"gid":251,"fullname":"Sicilian Defense, Prins Variation, Venice Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.f3 e5 6.Bb5+ Nbd7 7.Nf5 d5 *","weight":94823,"prior_delay":25,"prior_completion_time":0},
{"oid":1494,"gid":251,"fullname":"Sicilian Defense, Prins Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.f3 e6 *","weight":94713,"prior_delay":25,"prior_completion_time":0},
{"oid":1495,"gid":251,"fullname":"Sicilian Defense, Prins Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.f3 Nc6 6.c4 Qb6 *","weight":94823,"prior_delay":25,"prior_completion_time":0},
{"oid":1496,"gid":251,"fullname":"Sicilian Defense, Prins Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.f3 e5 6.Nb3 Be6 *","weight":94949,"prior_delay":25,"prior_completion_time":0},
{"oid":1497,"gid":251,"fullname":"Sicilian Defense, Prins Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.f3 Nc6 6.c4 Nxd4 *","weight":94807,"prior_delay":25,"prior_completion_time":0},
{"oid":1498,"gid":251,"fullname":"Sicilian Defense, Prins Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.f3 a6 *","weight":94720,"prior_delay":25,"prior_completion_time":0},
{"oid":1499,"gid":251,"fullname":"Sicilian Defense, Prins Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.f3 e5 6.Nb3 d5 *","weight":94940,"prior_delay":25,"prior_completion_time":0},
{"oid":1500,"gid":252,"fullname":"Sicilian Defense, Richter-Rauzer","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd3 *","weight":110946,"prior_delay":25,"prior_completion_time":0},
{"oid":1501,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Be7 8.O-O-O  O-O 9.Nb3 a5 *","weight":113921,"prior_delay":25,"prior_completion_time":0},
{"oid":1502,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Be7 8.O-O-O  Nxd4 9.Qxd4 O-O *","weight":113966,"prior_delay":25,"prior_completion_time":0},
{"oid":1503,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Be7 8.O-O-O  O-O 9.f4 h6 10.Bh4 Bd7 *","weight":114164,"prior_delay":25,"prior_completion_time":0},
{"oid":1504,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Be7 8.O-O-O  O-O 9.f4 Nxd4 10.Qxd4 h6 *","weight":114251,"prior_delay":25,"prior_completion_time":0},
{"oid":1505,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Be7 8.O-O-O  O-O 9.f4 h6 10.Bh4 e5 *","weight":114171,"prior_delay":25,"prior_completion_time":0},
{"oid":1506,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Be7 8.O-O-O  O-O 9.Nb3 a6 *","weight":113928,"prior_delay":25,"prior_completion_time":0},
{"oid":1507,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Be7 8.O-O-O  O-O 9.f4 Nxd4 10.Qxd4 Qa5 11.Kb1 *","weight":114420,"prior_delay":25,"prior_completion_time":0},
{"oid":1508,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Be7 8.O-O-O  O-O 9.f3 *","weight":113884,"prior_delay":25,"prior_completion_time":0},
{"oid":1509,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Be7 8.O-O-O  O-O 9.Nb3 Qb6 10.f3 Rd8 11.Kb1 *","weight":114035,"prior_delay":25,"prior_completion_time":0},
{"oid":1510,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Be7 8.O-O-O  O-O 9.f4 Nxd4 10.Qxd4 Qa5 11.Bc4 Bd7 12.e5 *","weight":114605,"prior_delay":25,"prior_completion_time":0},
{"oid":1511,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Dragon Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 g6 *","weight":109656,"prior_delay":25,"prior_completion_time":0},
{"oid":1512,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Ivanov Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Qb6 8.Nb3 a6  9.O-O-O Be7 *","weight":112857,"prior_delay":25,"prior_completion_time":0},
{"oid":1513,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 Bd7 7.Qd2 a6 *","weight":109932,"prior_delay":25,"prior_completion_time":0},
{"oid":1514,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 Bd7 7.Qd2 Nxd4 *","weight":109945,"prior_delay":25,"prior_completion_time":0},
{"oid":1515,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 Bd7 7.Be2 a6 *","weight":109797,"prior_delay":25,"prior_completion_time":0},
{"oid":1516,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 Bd7 7.Be2 Qa5 *","weight":109808,"prior_delay":25,"prior_completion_time":0},
{"oid":1517,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 Bd7 7.Nb3 *","weight":109777,"prior_delay":25,"prior_completion_time":0},
{"oid":1518,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 Bd7 7.Qd2 Rc8  8.O-O-O Nxd4 9.Qxd4 Qa5 10.Bd2 *","weight":110152,"prior_delay":25,"prior_completion_time":0},
{"oid":1519,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 Bd7 7.Bxf6 *","weight":109773,"prior_delay":25,"prior_completion_time":0},
{"oid":1520,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 Bd7 7.Qd2 Rc8  8.f4 *","weight":110034,"prior_delay":25,"prior_completion_time":0},
{"oid":1521,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 Bd7 7.Qd2 Rc8  8.O-O-O Nxd4 9.Qxd4 Qa5 10.f4 *","weight":110166,"prior_delay":25,"prior_completion_time":0},
{"oid":1522,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation, Early Deviations","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  h6 9.Be3 Qc7 *","weight":115216,"prior_delay":25,"prior_completion_time":0},
{"oid":1523,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation, Early Deviations","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  h6 9.Be3 Be7 10.f4 Nxd4 *","weight":115339,"prior_delay":25,"prior_completion_time":0},
{"oid":1524,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation, Early Deviations","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Be7 *","weight":114766,"prior_delay":25,"prior_completion_time":0},
{"oid":1525,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation, Early Deviations","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Nxd4 9.Qxd4 Be7 *","weight":114825,"prior_delay":25,"prior_completion_time":0},
{"oid":1526,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation, Early Deviations","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  h6 9.Bf4 Bd7 10.Nxc6 Bxc6 11.f3 *","weight":115139,"prior_delay":25,"prior_completion_time":0},
{"oid":1527,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation, Early Deviations","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.Be2 *","weight":113535,"prior_delay":25,"prior_completion_time":0},
{"oid":1528,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation, Early Deviations","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  h6 9.Be3 Bd7 10.f3 *","weight":115248,"prior_delay":25,"prior_completion_time":0},
{"oid":1529,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation, Early Deviations","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  h6 9.Be3 Be7 10.f3 Nxd4 11.Bxd4 *","weight":115353,"prior_delay":25,"prior_completion_time":0},
{"oid":1530,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation, Nyezhmetdinov Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O Bd7 9.f4 Be7 10.Nf3 b5 11.Bxf6 gxf6 12.f5 *","weight":115801,"prior_delay":25,"prior_completion_time":0},
{"oid":1531,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation, Nyezhmetdinov Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O Bd7 9.f4 Be7 10.Nf3 b5 11.Bxf6 gxf6 12.Kb1 *","weight":115818,"prior_delay":25,"prior_completion_time":0},
{"oid":1532,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Bd7 9.f4 h6 10.Bh4 g5 *","weight":115718,"prior_delay":25,"prior_completion_time":0},
{"oid":1533,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Bd7 9.f4 Be7 10.Be2 Qc7 *","weight":115605,"prior_delay":25,"prior_completion_time":0},
{"oid":1534,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Bd7 9.f3 Rc8 *","weight":115424,"prior_delay":25,"prior_completion_time":0},
{"oid":1535,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Bd7 9.f4 b5 10.Nxc6 *","weight":115783,"prior_delay":25,"prior_completion_time":0},
{"oid":1536,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Bd7 9.Be2 *","weight":115155,"prior_delay":25,"prior_completion_time":0},
{"oid":1537,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Bd7 9.f4 Be7 10.Nf3 b5 11.e5 *","weight":115715,"prior_delay":25,"prior_completion_time":0},
{"oid":1538,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Bd7 9.f4 b5 10.Bxf6 gxf6 11.Nxc6 Bxc6 12.Qe1 *","weight":116271,"prior_delay":25,"prior_completion_time":0},
{"oid":1539,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Bd7 9.f4 b5 10.Bxf6 gxf6 11.Kb1 Qb6 12.Nxc6 *","weight":116497,"prior_delay":25,"prior_completion_time":0},
{"oid":1540,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Bd7 9.f3 Be7 10.h4 *","weight":115532,"prior_delay":25,"prior_completion_time":0},
{"oid":1541,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Neo-Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 a6 8.O-O-O  Bd7 9.f4 b5 10.Bxf6 gxf6 11.f5 *","weight":116179,"prior_delay":25,"prior_completion_time":0},
{"oid":1542,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Podebrady Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Nb3 *","weight":110925,"prior_delay":25,"prior_completion_time":0},
{"oid":1543,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Traditional Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 Nxd4 *","weight":112727,"prior_delay":25,"prior_completion_time":0},
{"oid":1544,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Traditional Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Qd2 h6  8.Bxf6 gxf6 9.O-O-O a6 10.f4 *","weight":112772,"prior_delay":25,"prior_completion_time":0},
{"oid":1545,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation, Vitolins Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 e6 7.Bb5 Bd7 8.Bxc6 *","weight":110948,"prior_delay":25,"prior_completion_time":0},
{"oid":1546,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 Qa5 *","weight":109643,"prior_delay":25,"prior_completion_time":0},
{"oid":1547,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 Qb6 *","weight":109692,"prior_delay":25,"prior_completion_time":0},
{"oid":1548,"gid":253,"fullname":"Sicilian Defense, Richter-Rauzer Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bg5 a6 *","weight":109657,"prior_delay":25,"prior_completion_time":0},
{"oid":1549,"gid":254,"fullname":"Sicilian Defense, Scheveningen, Classical Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Be7 8.f4 O-O  9.Be3 Nc6 10.Qe1 Qc7 11.Qg3 *","weight":108015,"prior_delay":25,"prior_completion_time":0},
{"oid":1550,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation, Paulsen Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Qc7 8.f4 Nc6 *","weight":107149,"prior_delay":25,"prior_completion_time":0},
{"oid":1551,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 Be7 *","weight":106784,"prior_delay":25,"prior_completion_time":0},
{"oid":1552,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Qc7 8.a4 Nc6 *","weight":107117,"prior_delay":25,"prior_completion_time":0},
{"oid":1553,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Nc6 *","weight":107087,"prior_delay":25,"prior_completion_time":0},
{"oid":1554,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Qc7 8.Kh1 Nc6 *","weight":107100,"prior_delay":25,"prior_completion_time":0},
{"oid":1555,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Be7 8.f4 O-O  9.Be3 Nc6 10.a4 Bd7 *","weight":107935,"prior_delay":25,"prior_completion_time":0},
{"oid":1556,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Be7 8.f4 O-O  9.Be3 Nc6 10.Kh1 Qc7 *","weight":107927,"prior_delay":25,"prior_completion_time":0},
{"oid":1557,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Be7 8.f4 Qc7  9.Be3 Nc6 *","weight":107687,"prior_delay":25,"prior_completion_time":0},
{"oid":1558,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Qc7 8.Be3 Nc6 *","weight":107101,"prior_delay":25,"prior_completion_time":0},
{"oid":1559,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Be7 8.f4 O-O  9.Be3 Nc6 10.Kh1 Bd7 *","weight":107929,"prior_delay":25,"prior_completion_time":0},
{"oid":1560,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Be7 8.f4 O-O  9.Be3 Qc7 *","weight":107826,"prior_delay":25,"prior_completion_time":0},
{"oid":1561,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Be7 8.f4 Qc7  9.Kh1 *","weight":107713,"prior_delay":25,"prior_completion_time":0},
{"oid":1562,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.O-O Be7 8.f4 O-O  9.Be3 Nc6 10.a4 Qc7 11.Kh1 *","weight":108451,"prior_delay":25,"prior_completion_time":0},
{"oid":1563,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.a4 *","weight":106774,"prior_delay":25,"prior_completion_time":0},
{"oid":1564,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.Be3 *","weight":106739,"prior_delay":25,"prior_completion_time":0},
{"oid":1565,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Classical Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 a6 7.f4 *","weight":106789,"prior_delay":25,"prior_completion_time":0},
{"oid":1566,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Delayed Keres Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g4 a6 7.Be3 h6 8.f4 *","weight":106957,"prior_delay":25,"prior_completion_time":0},
{"oid":1567,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Delayed Keres Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g4 a6 7.Be3 e5 *","weight":106910,"prior_delay":25,"prior_completion_time":0},
{"oid":1568,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, English Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be3 a6 7.f3 Nc6 8.Qd2 Be7 *","weight":107189,"prior_delay":25,"prior_completion_time":0},
{"oid":1569,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, English Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be3 a6 7.f3 b5 8.Qd2 Nbd7 *","weight":107803,"prior_delay":25,"prior_completion_time":0},
{"oid":1570,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, English Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be3 a6 7.Qd2 *","weight":106737,"prior_delay":25,"prior_completion_time":0},
{"oid":1571,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, English Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be3 a6 7.f3 b5 8.Qd2 Bb7  9.g4 *","weight":107713,"prior_delay":25,"prior_completion_time":0},
{"oid":1572,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Keres Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g4 h6 7.h4 Nc6 8.Rg1 h5 *","weight":107434,"prior_delay":25,"prior_completion_time":0},
{"oid":1573,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Keres Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g4 h6 7.h4 Be7 *","weight":107255,"prior_delay":25,"prior_completion_time":0},
{"oid":1574,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Keres Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g4 Nc6 7.g5 Nd7 8.Be3 Be7 *","weight":106937,"prior_delay":25,"prior_completion_time":0},
{"oid":1575,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Keres Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g4 e5 *","weight":106853,"prior_delay":25,"prior_completion_time":0},
{"oid":1576,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Keres Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g4 h6 7.h3 Nc6 8.Bg2 *","weight":107206,"prior_delay":25,"prior_completion_time":0},
{"oid":1577,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Keres Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g4 h6 7.g5 *","weight":107115,"prior_delay":25,"prior_completion_time":0},
{"oid":1578,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Keres Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g4 h6 7.h3 a6 8.Bg2 *","weight":107272,"prior_delay":25,"prior_completion_time":0},
{"oid":1579,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Keres Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g4 h6 7.Rg1 *","weight":107115,"prior_delay":25,"prior_completion_time":0},
{"oid":1580,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Matanovic Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.f4 Qb6 *","weight":106476,"prior_delay":25,"prior_completion_time":0},
{"oid":1581,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Matanovic Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.f4 Nc6 7.Be3 Qc7 *","weight":106523,"prior_delay":25,"prior_completion_time":0},
{"oid":1582,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Matanovic Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.f4 Be7 *","weight":106485,"prior_delay":25,"prior_completion_time":0},
{"oid":1583,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Matanovic Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.f4 a6 7.Be3 b5 *","weight":106543,"prior_delay":25,"prior_completion_time":0},
{"oid":1584,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 Nc6 7.O-O Be7  8.Be3 O-O 9.f4 e5 10.Nb3 exf4 11.Bxf4 *","weight":107329,"prior_delay":25,"prior_completion_time":0},
{"oid":1585,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 Nc6 7.O-O Be7  8.Be3 O-O 9.f4 Bd7 10.Kh1 *","weight":107229,"prior_delay":25,"prior_completion_time":0},
{"oid":1586,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 Nc6 7.O-O Be7  8.Be3 O-O 9.f4 Bd7 10.Nb3 a6 11.a4 *","weight":107263,"prior_delay":25,"prior_completion_time":0},
{"oid":1587,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Modern Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be2 Nc6 7.O-O Be7  8.Be3 O-O 9.f4 Bd7 10.Qe1 *","weight":107223,"prior_delay":25,"prior_completion_time":0},
{"oid":1588,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Tal Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.f4 Nc6 7.Be3 Be7 8.Qf3 e5 *","weight":106620,"prior_delay":25,"prior_completion_time":0},
{"oid":1589,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation, Tal Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.f4 Nc6 7.Be3 Be7  8.Qf3 Qc7 *","weight":106607,"prior_delay":25,"prior_completion_time":0},
{"oid":1590,"gid":255,"fullname":"Sicilian Defense, Scheveningen Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Be3 Nc6 7.f3 *","weight":106735,"prior_delay":25,"prior_completion_time":0},
{"oid":1591,"gid":256,"fullname":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g3 a6 *","weight":106446,"prior_delay":25,"prior_completion_time":0},
{"oid":1592,"gid":256,"fullname":"Sicilian Defense, Scheveningen Variation,Fianchetto Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.g3 Nc6 7.Bg2 a6 8.O-O Bd7 *","weight":106545,"prior_delay":25,"prior_completion_time":0},
{"oid":1593,"gid":257,"fullname":"Sicilian Defense, Smith-Morra Gambit","main_line":"1.e4 c5 2.d4 cxd4 3.c3 dxc3 4.Nxc3 Nc6 5.Nf3 d6 6.Bc4 a6 *","weight":21829,"prior_delay":25,"prior_completion_time":0},
{"oid":1594,"gid":258,"fullname":"Sicilian Defense, Smith-Morra Gambit Accepted, PaulsenFormation","main_line":"1.e4 c5 2.d4 cxd4 3.c3 dxc3 4.Nxc3 Nc6 5.Nf3 e6 6.Bc4 a6 *","weight":21837,"prior_delay":25,"prior_completion_time":0},
{"oid":1595,"gid":259,"fullname":"Sicilian Defense, Smith-Morra Gambit Declined, AlapinFormation","main_line":"1.e4 c5 2.d4 cxd4 3.c3 Nf6 *","weight":21820,"prior_delay":25,"prior_completion_time":0},
{"oid":1596,"gid":259,"fullname":"Sicilian Defense, Smith-Morra Gambit Declined, Push Variation","main_line":"1.e4 c5 2.d4 cxd4 3.c3 d3 *","weight":21816,"prior_delay":25,"prior_completion_time":0},
{"oid":1597,"gid":259,"fullname":"Sicilian Defense, Smith-Morra Gambit Declined, ScandinavianFormation","main_line":"1.e4 c5 2.d4 cxd4 3.c3 d5 *","weight":21817,"prior_delay":25,"prior_completion_time":0},
{"oid":1598,"gid":260,"fullname":"Sicilian Defense, Sozin, not Scheveningen","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 Nc6 6.Bc4 Bd7 7.O-O *","weight":108029,"prior_delay":25,"prior_completion_time":0},
{"oid":1599,"gid":261,"fullname":"Sicilian Defense, Sozin Attack, Leonhardt Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Bc4 Nc6 7.O-O *","weight":106415,"prior_delay":25,"prior_completion_time":0},
{"oid":1600,"gid":261,"fullname":"Sicilian Defense, Sozin Attack, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Bc4 Nc6 7.Be3 a6 8.Qe2 *","weight":106722,"prior_delay":25,"prior_completion_time":0},
{"oid":1601,"gid":261,"fullname":"Sicilian Defense, Sozin Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Bc4 a6 7.Bb3 Be7 *","weight":106721,"prior_delay":25,"prior_completion_time":0},
{"oid":1602,"gid":262,"fullname":"Sicilian Defense, Sozin Attack,Flank Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Bc4 a6 7.Bb3 b5  8.O-O Be7 9.f4 *","weight":107015,"prior_delay":25,"prior_completion_time":0},
{"oid":1603,"gid":262,"fullname":"Sicilian Defense, Sozin Attack,Flank Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Bc4 a6 7.Bb3 b5  8.O-O Be7 9.Qf3 *","weight":107103,"prior_delay":25,"prior_completion_time":0},
{"oid":1604,"gid":263,"fullname":"Sicilian Defense, Sozin,Fischer Variation","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Bc4 Nc6 7.Bb3 Be7  8.Be3 O-O 9.f4 *","weight":106502,"prior_delay":25,"prior_completion_time":0},
{"oid":1605,"gid":264,"fullname":"Sicilian Defense, Staunton-Cochrane Variation","main_line":"1.e4 c5 2.c4 Nc6 3.Nc3 g6 *","weight":21867,"prior_delay":25,"prior_completion_time":0},
{"oid":1606,"gid":265,"fullname":"Sicilian Defense, Velimirovic Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e6 6.Bc4 Nc6 7.Be3 Be7  8.Qe2 O-O 9.O-O-O Qc7 10.Bb3 a6 *","weight":106823,"prior_delay":25,"prior_completion_time":0},
{"oid":1607,"gid":266,"fullname":"Sicilian Defense, Venice Attack","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 Nf6 5.Nc3 e5 6.Bb5+ *","weight":105945,"prior_delay":25,"prior_completion_time":0},
{"oid":1608,"gid":234,"fullname":"Sicilian Defense, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 a6 *","weight":84980,"prior_delay":25,"prior_completion_time":0},
{"oid":1609,"gid":234,"fullname":"Sicilian Defense, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.d4 cxd4 4.Nxd4 e5 *","weight":84972,"prior_delay":25,"prior_completion_time":0},
{"oid":1610,"gid":234,"fullname":"Sicilian Defense, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.Nc3 e6 *","weight":50936,"prior_delay":25,"prior_completion_time":0},
{"oid":1611,"gid":234,"fullname":"Sicilian Defense, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.Nc3 Nf6 *","weight":51066,"prior_delay":25,"prior_completion_time":0},
{"oid":1612,"gid":234,"fullname":"Sicilian Defense, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.b3 e6 *","weight":50682,"prior_delay":25,"prior_completion_time":0},
{"oid":1613,"gid":234,"fullname":"Sicilian Defense, Main Line","main_line":"1.e4 c5 2.Nf3 d6 3.Bc4 Nf6 4.d3 *","weight":51020,"prior_delay":25,"prior_completion_time":0},
{"oid":1614,"gid":268,"fullname":"Three Knights Opening, Steinitz Defense","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 g6 4.d4 exd4 5.Nxd4 Bg7 6.Be3 Nf6 *","weight":49414,"prior_delay":25,"prior_completion_time":0},
{"oid":1615,"gid":267,"fullname":"Three Knights Opening, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 d6 4.d4 *","weight":49340,"prior_delay":25,"prior_completion_time":0},
{"oid":1616,"gid":267,"fullname":"Three Knights Opening, Main Line","main_line":"1.e4 e5 2.Nf3 Nc6 3.Nc3 Bc5 4.Nxe5 *","weight":49355,"prior_delay":25,"prior_completion_time":0},
{"oid":1617,"gid":269,"fullname":"Vienna Game, Frankenstein-Dracula Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Nxe4 4.Nxe4 *","weight":20918,"prior_delay":25,"prior_completion_time":0},
{"oid":1618,"gid":269,"fullname":"Vienna Game, Frankenstein-Dracula Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Nxe4 4.Qh5 Nd6 5.Qxe5+ *","weight":20939,"prior_delay":25,"prior_completion_time":0},
{"oid":1619,"gid":270,"fullname":"Vienna Game, Mieses Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.g3 Bc5 4.Bg2 d6 *","weight":21150,"prior_delay":25,"prior_completion_time":0},
{"oid":1620,"gid":270,"fullname":"Vienna Game, Mieses Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.g3 Bc5 4.Bg2 O-O *","weight":21156,"prior_delay":25,"prior_completion_time":0},
{"oid":1621,"gid":270,"fullname":"Vienna Game, Mieses Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.g3 d5 4.exd5 Nxd5 5.Bg2 Nxc3 6.bxc3 Bd6 *","weight":21609,"prior_delay":25,"prior_completion_time":0},
{"oid":1622,"gid":270,"fullname":"Vienna Game, Mieses Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.g3 Nc6 4.Bg2 *","weight":21105,"prior_delay":25,"prior_completion_time":0},
{"oid":1623,"gid":270,"fullname":"Vienna Game, Mieses Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.g3 Bc5 4.Bg2 Nc6 5.Nge2 *","weight":21192,"prior_delay":25,"prior_completion_time":0},
{"oid":1624,"gid":271,"fullname":"Vienna Game, Paulsen Variation","main_line":"1.e4 e5 2.Nc3 Nc6 3.g3 Bc5 *","weight":20762,"prior_delay":25,"prior_completion_time":0},
{"oid":1625,"gid":272,"fullname":"Vienna Game, Stanley Variation, Alekhine Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Nxe4 4.Qh5 Nd6 5.Bb3 Be7 6.Nf3 Nc6 7.Nxe5 *","weight":20957,"prior_delay":25,"prior_completion_time":0},
{"oid":1626,"gid":272,"fullname":"Vienna Game, Stanley Variation, Reversed Spanish","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Bb4 4.Nf3 O-O *","weight":20909,"prior_delay":25,"prior_completion_time":0},
{"oid":1627,"gid":272,"fullname":"Vienna Game, Stanley Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Bc5 4.f4 *","weight":20916,"prior_delay":25,"prior_completion_time":0},
{"oid":1628,"gid":272,"fullname":"Vienna Game, Stanley Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.Bc4 Bc5 4.Nf3 *","weight":20915,"prior_delay":25,"prior_completion_time":0},
{"oid":1629,"gid":273,"fullname":"Vienna Game, Vienna Gambit, Breyer Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.f4 d5 4.fxe5 Nxe4 5.Nf3 Be7 6.Qe2 Nxc3 7.dxc3 *","weight":21042,"prior_delay":25,"prior_completion_time":0},
{"oid":1630,"gid":273,"fullname":"Vienna Game, Vienna Gambit, Kaufmann Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.f4 d5 4.fxe5 Nxe4 5.Nf3 Bg4 6.Qe2 *","weight":21007,"prior_delay":25,"prior_completion_time":0},
{"oid":1631,"gid":273,"fullname":"Vienna Game, Vienna Gambit, Main Line","main_line":"1.e4 e5 2.Nc3 Nf6 3.f4 d5 4.exd5 exf4 *","weight":20941,"prior_delay":25,"prior_completion_time":0},
{"oid":1632,"gid":273,"fullname":"Vienna Game, Vienna Gambit, Main Line","main_line":"1.e4 e5 2.Nc3 Nf6 3.f4 d5 4.fxe5 Nxe4 5.Nf3 Nc6 *","weight":21009,"prior_delay":25,"prior_completion_time":0},
{"oid":1633,"gid":273,"fullname":"Vienna Game, Vienna Gambit, Modern Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.f4 d5 4.fxe5 Nxe4 5.d3 Bb4 6.dxe4 Qh4+ 7.Ke2 *","weight":20998,"prior_delay":25,"prior_completion_time":0},
{"oid":1634,"gid":273,"fullname":"Vienna Game, Vienna Gambit, Modern Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.f4 d5 4.fxe5 Nxe4 5.d3 Nxc3 6.bxc3 d4 *","weight":20993,"prior_delay":25,"prior_completion_time":0},
{"oid":1635,"gid":273,"fullname":"Vienna Game, Vienna Gambit, Paulsen Attack","main_line":"1.e4 e5 2.Nc3 Nf6 3.f4 d5 4.fxe5 Nxe4 5.Qf3 Nc6 *","weight":20992,"prior_delay":25,"prior_completion_time":0},
{"oid":1636,"gid":273,"fullname":"Vienna Game, Vienna Gambit, Steinitz Variation","main_line":"1.e4 e5 2.Nc3 Nf6 3.f4 d5 4.d3 *","weight":20933,"prior_delay":25,"prior_completion_time":0},
{"oid":1637,"gid":273,"fullname":"Vienna Game, Vienna Gambit","main_line":"1.e4 e5 2.Nc3 Nf6 3.f4 d6 4.Nf3 *","weight":20899,"prior_delay":25,"prior_completion_time":0},
{"oid":1638,"gid":274,"fullname":"Bird Opening, Dutch Variation","main_line":"1.f4 d5 2.Nf3 Nf6 3.g3 g6 4.Bg2 Bg7 5.d3 *","weight":316,"prior_delay":25,"prior_completion_time":0},
{"oid":1639,"gid":274,"fullname":"Bird Opening, Dutch Variation","main_line":"1.f4 d5 2.Nf3 g6 3.g3 Bg7 4.Bg2 *","weight":369,"prior_delay":25,"prior_completion_time":0},
{"oid":1640,"gid":274,"fullname":"Bird Opening, Dutch Variation","main_line":"1.f4 d5 2.Nf3 Nf6 3.e3 Bg4 *","weight":250,"prior_delay":25,"prior_completion_time":0},
{"oid":1641,"gid":274,"fullname":"Bird Opening, Dutch Variation","main_line":"1.f4 d5 2.Nf3 Nf6 3.g3 g6 4.Bg2 Bg7 5.O-O O-O 6.d3 c5 *","weight":434,"prior_delay":25,"prior_completion_time":0},
{"oid":1642,"gid":274,"fullname":"Bird Opening, Dutch Variation","main_line":"1.f4 d5 2.Nf3 Nf6 3.e3 g6 *","weight":254,"prior_delay":25,"prior_completion_time":0},
{"oid":1643,"gid":274,"fullname":"Bird Opening, Dutch Variation","main_line":"1.f4 d5 2.Nf3 Nf6 3.g3 g6 4.Bg2 Bg7 5.O-O O-O 6.d3 c6 *","weight":411,"prior_delay":25,"prior_completion_time":0},
{"oid":1644,"gid":275,"fullname":"Polish Opening, Main Line","main_line":"1.b4 e5 2.Bb2 Bxb4 3.Bxe5 Nf6 4.Nf3 *","weight":35,"prior_delay":25,"prior_completion_time":0},
{"oid":1645,"gid":275,"fullname":"Polish Opening, Main Line","main_line":"1.b4 e5 2.Bb2 Bxb4 3.Bxe5 Nf6 4.c4 *","weight":36,"prior_delay":25,"prior_completion_time":0},
{"oid":1646,"gid":276,"fullname":"Benko Gambit, Fianchetto Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.Nf3 g6 8.g3 Bg7  9.Bh3 *","weight":35090,"prior_delay":25,"prior_completion_time":0},
{"oid":1647,"gid":277,"fullname":"Benko Gambit, Nd2 Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.Nf3 g6 8.Nd2 Bg7 *","weight":35020,"prior_delay":25,"prior_completion_time":0},
{"oid":1648,"gid":278,"fullname":"Benko Gambit, Nescafe Frappe Attack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.Nc3 axb5 6.e4 b4 7.Nb5 d6 8.Bc4 *","weight":34545,"prior_delay":25,"prior_completion_time":0},
{"oid":1649,"gid":279,"fullname":"Benko Gambit, Zaitsev Variation, Nescafe Frappe Attack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.Nc3 axb5 6.e4 b4 7.Nb5 d6 8.Nf3 *","weight":34542,"prior_delay":25,"prior_completion_time":0},
{"oid":1650,"gid":279,"fullname":"Benko Gambit, Zaitsev Variation, Nescafe Frappe Attack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.Nc3 axb5 6.e4 b4 7.Nb5 d6 8.Bf4 g5 *","weight":34545,"prior_delay":25,"prior_completion_time":0},
{"oid":1651,"gid":280,"fullname":"Benko Gambit Accepted, Dlugy Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.f3 e6 *","weight":34552,"prior_delay":25,"prior_completion_time":0},
{"oid":1652,"gid":280,"fullname":"Benko Gambit Accepted, Dlugy Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.f3 axb5 *","weight":34548,"prior_delay":25,"prior_completion_time":0},
{"oid":1653,"gid":281,"fullname":"Benko Gambit Accepted, Fianchetto Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.Nf3 g6 8.g3 Bg7  9.Bg2 O-O 10.O-O Nbd7 11.Qc2 Qb6 *","weight":35190,"prior_delay":25,"prior_completion_time":0},
{"oid":1654,"gid":281,"fullname":"Benko Gambit Accepted, Fianchetto Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.Nf3 g6 8.g3 Bg7  9.Bg2 Nbd7 10.O-O Nb6 *","weight":35241,"prior_delay":25,"prior_completion_time":0},
{"oid":1655,"gid":281,"fullname":"Benko Gambit Accepted, Fianchetto Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.Nf3 g6 8.g3 Bg7  9.Bg2 O-O 10.O-O Nbd7 11.Re1 *","weight":35178,"prior_delay":25,"prior_completion_time":0},
{"oid":1656,"gid":281,"fullname":"Benko Gambit Accepted, Fianchetto Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.Nf3 g6 8.g3 Bg7  9.Bg2 O-O 10.O-O Nbd7 11.Rb1 *","weight":35193,"prior_delay":25,"prior_completion_time":0},
{"oid":1657,"gid":282,"fullname":"Benko Gambit Accepted, Fully Accepted Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 g6 *","weight":34974,"prior_delay":25,"prior_completion_time":0},
{"oid":1658,"gid":282,"fullname":"Benko Gambit Accepted, Fully Accepted Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 g6 *","weight":35126,"prior_delay":25,"prior_completion_time":0},
{"oid":1659,"gid":282,"fullname":"Benko Gambit Accepted, Fully Accepted Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.g3 g6 *","weight":34987,"prior_delay":25,"prior_completion_time":0},
{"oid":1660,"gid":283,"fullname":"Benko Gambit Accepted, King Walk Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.e4 Bxf1  8.Kxf1 g6 9.g3 Bg7 10.Kg2 O-O 11.Nf3 Nbd7 12.Re1 Qa5 *","weight":35257,"prior_delay":25,"prior_completion_time":0},
{"oid":1661,"gid":283,"fullname":"Benko Gambit Accepted, King Walk Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.e4 Bxf1  8.Kxf1 g6 9.g3 Bg7 10.Kg2 O-O 11.Nf3 Nbd7 12.Re1 Ng4 *","weight":35253,"prior_delay":25,"prior_completion_time":0},
{"oid":1662,"gid":283,"fullname":"Benko Gambit Accepted, King Walk Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.e4 Bxf1  8.Kxf1 g6 9.g3 Bg7 10.Kg2 O-O 11.Nf3 Nbd7 12.h3 Ra6 13.Re1 *","weight":35272,"prior_delay":25,"prior_completion_time":0},
{"oid":1663,"gid":283,"fullname":"Benko Gambit Accepted, King Walk Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.e4 Bxf1  8.Kxf1 g6 9.g3 Bg7 10.Kg2 O-O 11.Nf3 Nbd7 12.h3 Qb6 13.Re1 *","weight":35255,"prior_delay":25,"prior_completion_time":0},
{"oid":1664,"gid":283,"fullname":"Benko Gambit Accepted, King Walk Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.e4 Bxf1  8.Kxf1 g6 9.g3 Bg7 10.Kg2 O-O 11.Nf3 Nbd7 12.h3 Qa5 13.Re1 *","weight":35265,"prior_delay":25,"prior_completion_time":0},
{"oid":1665,"gid":284,"fullname":"Benko Gambit Accepted, Modern Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.e3 g6 6.Nc3 d6 *","weight":34641,"prior_delay":25,"prior_completion_time":0},
{"oid":1666,"gid":284,"fullname":"Benko Gambit Accepted, Modern Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.e3 g6 6.Nc3 Bg7 *","weight":34669,"prior_delay":25,"prior_completion_time":0},
{"oid":1667,"gid":285,"fullname":"Benko Gambit Accepted, Pawn Return Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.b6 d6 *","weight":34625,"prior_delay":25,"prior_completion_time":0},
{"oid":1668,"gid":285,"fullname":"Benko Gambit Accepted, Pawn Return Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.b6 e6 *","weight":34610,"prior_delay":25,"prior_completion_time":0},
{"oid":1669,"gid":285,"fullname":"Benko Gambit Accepted, Pawn Return Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.b6 Qxb6 *","weight":34609,"prior_delay":25,"prior_completion_time":0},
{"oid":1670,"gid":286,"fullname":"Benko Gambit Accepted, Yugoslav","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.e4 Bxf1  8.Kxf1 g6 9.Nf3 Bg7 10.h3 Nbd7 *","weight":35104,"prior_delay":25,"prior_completion_time":0},
{"oid":1671,"gid":286,"fullname":"Benko Gambit Accepted, Yugoslav","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.e4 Bxf1  8.Kxf1 g6 9.g4 *","weight":35005,"prior_delay":25,"prior_completion_time":0},
{"oid":1672,"gid":286,"fullname":"Benko Gambit Accepted, Yugoslav","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.cxb5 a6 5.bxa6 Bxa6 6.Nc3 d6 7.e4 Bxf1  8.Kxf1 g6 9.Nge2 *","weight":35003,"prior_delay":25,"prior_completion_time":0},
{"oid":1673,"gid":287,"fullname":"Benko Gambit Declined, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.Nf3 bxc4 *","weight":33401,"prior_delay":25,"prior_completion_time":0},
{"oid":1674,"gid":287,"fullname":"Benko Gambit Declined, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.Nf3 g6 5.cxb5 a6 *","weight":33545,"prior_delay":25,"prior_completion_time":0},
{"oid":1675,"gid":287,"fullname":"Benko Gambit Declined, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.Nf3 Bb7 5.a4 *","weight":33440,"prior_delay":25,"prior_completion_time":0},
{"oid":1676,"gid":288,"fullname":"Benko Gambit Declined, Quiet Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.Nd2 *","weight":33299,"prior_delay":25,"prior_completion_time":0},
{"oid":1677,"gid":289,"fullname":"Benko Gambit Declined, Sosonko Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 b5 4.a4 *","weight":33267,"prior_delay":25,"prior_completion_time":0},
{"oid":1678,"gid":290,"fullname":"Benoni Defense, Benoni-Indian Defense, Kingside Move Order","main_line":"1.d4 c5 2.d5 Nf6 3.Nf3 e6 4.Nc3 *","weight":12027,"prior_delay":25,"prior_completion_time":0},
{"oid":1679,"gid":290,"fullname":"Benoni Defense, Benoni-Indian Defense, Kingside Move Order","main_line":"1.d4 c5 2.d5 Nf6 3.Nf3 g6 4.Nc3 *","weight":12048,"prior_delay":25,"prior_completion_time":0},
{"oid":1680,"gid":291,"fullname":"Benoni Defense, Classical","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.Bf4 b5 *","weight":35264,"prior_delay":25,"prior_completion_time":0},
{"oid":1681,"gid":291,"fullname":"Benoni Defense, Classical","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.Bg5 h6 10.Bh4 g5 11.Bg3 Nh5 12.Nd2 *","weight":35279,"prior_delay":25,"prior_completion_time":0},
{"oid":1682,"gid":291,"fullname":"Benoni Defense, Classical","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.Nd2 *","weight":35267,"prior_delay":25,"prior_completion_time":0},
{"oid":1683,"gid":292,"fullname":"Benoni Defense, Classical Variation, Argentine Counterattack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O a6 10.a4 Bg4 11.Bf4 Re8 12.Nd2 Bxe2 13.Qxe2 Nh5 *","weight":35392,"prior_delay":25,"prior_completion_time":0},
{"oid":1684,"gid":292,"fullname":"Benoni Defense, Classical Variation, Argentine Counterattack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O a6 10.a4 Bg4 11.Bf4 Qe7 *","weight":35370,"prior_delay":25,"prior_completion_time":0},
{"oid":1685,"gid":292,"fullname":"Benoni Defense, Classical Variation, Argentine Counterattack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O a6 10.a4 Bg4 11.Bf4 Bxf3 12.Bxf3 Qe7 13.Re1 Nbd7 14.a5 *","weight":35430,"prior_delay":25,"prior_completion_time":0},
{"oid":1686,"gid":292,"fullname":"Benoni Defense, Classical Variation, Argentine Counterattack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O a6 10.a4 Bg4 11.Nd2 *","weight":35365,"prior_delay":25,"prior_completion_time":0},
{"oid":1687,"gid":292,"fullname":"Benoni Defense, Classical Variation, Argentine Counterattack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O a6 10.a4 Bg4 11.h3 *","weight":35363,"prior_delay":25,"prior_completion_time":0},
{"oid":1688,"gid":292,"fullname":"Benoni Defense, Classical Variation, Averbakh-Grivas Attack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Bg5 h6  9.Bh4 g5 *","weight":35196,"prior_delay":25,"prior_completion_time":0},
{"oid":1689,"gid":292,"fullname":"Benoni Defense, Classical Variation, Averbakh-Grivas Attack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Bg5 h6  9.Bh4 a6 10.Nd2 b5 11.Be2 *","weight":35205,"prior_delay":25,"prior_completion_time":0},
{"oid":1690,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense, Tal Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 a6 *","weight":35361,"prior_delay":25,"prior_completion_time":0},
{"oid":1691,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense, Tal Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Nbd7 11.a4 a6 12.Qc2 *","weight":35467,"prior_delay":25,"prior_completion_time":0},
{"oid":1692,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense, Tal Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Nbd7 11.a4 Ne5 12.Qc2 *","weight":35483,"prior_delay":25,"prior_completion_time":0},
{"oid":1693,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense, Tal Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Nbd7 11.a4 Ne5 12.Re1 *","weight":35487,"prior_delay":25,"prior_completion_time":0},
{"oid":1694,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense, Tal Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Nbd7 11.a4 Ne5 12.Ra3 *","weight":35493,"prior_delay":25,"prior_completion_time":0},
{"oid":1695,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense, Tal Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Nbd7 11.a4 a6 12.h3 *","weight":35455,"prior_delay":25,"prior_completion_time":0},
{"oid":1696,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense, Tal Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Nbd7 11.a4 a6 12.Ra3 *","weight":35462,"prior_delay":25,"prior_completion_time":0},
{"oid":1697,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense, Tal Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Nbd7 11.a4 Ne5 12.Ndb1 *","weight":35478,"prior_delay":25,"prior_completion_time":0},
{"oid":1698,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Na6 11.f3 Nc7 12.a4 Nd7 *","weight":35450,"prior_delay":25,"prior_completion_time":0},
{"oid":1699,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Na6 11.f4 Nc7 *","weight":35381,"prior_delay":25,"prior_completion_time":0},
{"oid":1700,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Na6 11.f3 Nc7 12.a4 b6 13.Kh1 *","weight":35454,"prior_delay":25,"prior_completion_time":0},
{"oid":1701,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Na6 11.Re1 *","weight":35389,"prior_delay":25,"prior_completion_time":0},
{"oid":1702,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Na6 11.Kh1 *","weight":35388,"prior_delay":25,"prior_completion_time":0},
{"oid":1703,"gid":292,"fullname":"Benoni Defense, Classical Variation, Czerniak Defense","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Re8 10.Nd2 Na6 11.f3 Nc7 12.a4 b6 13.Nc4 *","weight":35458,"prior_delay":25,"prior_completion_time":0},
{"oid":1704,"gid":292,"fullname":"Benoni Defense, Classical Variation, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Nbd7 *","weight":35309,"prior_delay":25,"prior_completion_time":0},
{"oid":1705,"gid":292,"fullname":"Benoni Defense, Classical Variation, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Na6 10.Nd2 Nc7 *","weight":35328,"prior_delay":25,"prior_completion_time":0},
{"oid":1706,"gid":292,"fullname":"Benoni Defense, Classical Variation, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Bg4 10.Bg5 *","weight":35329,"prior_delay":25,"prior_completion_time":0},
{"oid":1707,"gid":292,"fullname":"Benoni Defense, Classical Variation, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 O-O  9.O-O Bg4 10.Bf4 *","weight":35332,"prior_delay":25,"prior_completion_time":0},
{"oid":1708,"gid":292,"fullname":"Benoni Defense, Classical Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Be2 a6 *","weight":35227,"prior_delay":25,"prior_completion_time":0},
{"oid":1709,"gid":292,"fullname":"Benoni Defense, Classical Variation, York Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.h3 O-O  9.Bd3 b5 10.Nxb5 Nxe4 *","weight":35687,"prior_delay":25,"prior_completion_time":0},
{"oid":1710,"gid":292,"fullname":"Benoni Defense, Classical Variation, York Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.h3 O-O  9.Bd3 Na6 *","weight":35534,"prior_delay":25,"prior_completion_time":0},
{"oid":1711,"gid":292,"fullname":"Benoni Defense, Classical Variation, York Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.h3 O-O  9.Bd3 a6 10.a4 Nbd7 11.O-O Re8 *","weight":35731,"prior_delay":25,"prior_completion_time":0},
{"oid":1712,"gid":292,"fullname":"Benoni Defense, Classical Variation, York Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.h3 O-O  9.Bd3 Bd7 *","weight":35533,"prior_delay":25,"prior_completion_time":0},
{"oid":1713,"gid":292,"fullname":"Benoni Defense, Classical Variation, York Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.h3 O-O  9.Bd3 Re8 10.O-O c4 *","weight":35586,"prior_delay":25,"prior_completion_time":0},
{"oid":1714,"gid":292,"fullname":"Benoni Defense, Classical Variation, York Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.h3 O-O  9.Bd3 a6 10.O-O b5 *","weight":35586,"prior_delay":25,"prior_completion_time":0},
{"oid":1715,"gid":292,"fullname":"Benoni Defense, Classical Variation, York Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.h3 O-O  9.Bd3 b5 10.Nxb5 Re8 11.O-O Nxe4 *","weight":35762,"prior_delay":25,"prior_completion_time":0},
{"oid":1716,"gid":292,"fullname":"Benoni Defense, Classical Variation, York Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.h3 O-O  9.Bd3 b5 10.Bxb5 *","weight":35666,"prior_delay":25,"prior_completion_time":0},
{"oid":1717,"gid":292,"fullname":"Benoni Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Bd3 O-O  9.O-O *","weight":35192,"prior_delay":25,"prior_completion_time":0},
{"oid":1718,"gid":292,"fullname":"Benoni Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 Bg7 8.Bf4 O-O  9.Nd2 *","weight":35187,"prior_delay":25,"prior_completion_time":0},
{"oid":1719,"gid":292,"fullname":"Benoni Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Nf3 a6 8.a4 Bg4  9.Be2 *","weight":35234,"prior_delay":25,"prior_completion_time":0},
{"oid":1720,"gid":293,"fullname":"Benoni Defense, Czech Benoni Defense","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e5 4.Nc3 d6 5.e4 Be7 6.g3 O-O 7.Bg2 Ne8 *","weight":33379,"prior_delay":25,"prior_completion_time":0},
{"oid":1721,"gid":293,"fullname":"Benoni Defense, Czech Benoni Defense","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e5 4.Nc3 d6 5.e4 Be7 6.Bd3 *","weight":33327,"prior_delay":25,"prior_completion_time":0},
{"oid":1722,"gid":293,"fullname":"Benoni Defense, Czech Benoni Defense","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e5 4.Nc3 d6 5.e4 Be7 6.Nf3 O-O 7.h3 *","weight":33329,"prior_delay":25,"prior_completion_time":0},
{"oid":1723,"gid":293,"fullname":"Benoni Defense, Czech Benoni Defense","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e5 4.Nc3 d6 5.e4 Be7 6.Nf3 O-O 7.Be2 *","weight":33330,"prior_delay":25,"prior_completion_time":0},
{"oid":1724,"gid":294,"fullname":"Benoni Defense, Fianchetto Variation, Hastings Defense, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.g3 Bg7 8.Bg2 O-O  9.O-O Nbd7 10.Nd2 a6 11.a4 Re8 12.h3 Rb8 13.Nc4 Nb6 *","weight":36872,"prior_delay":25,"prior_completion_time":0},
{"oid":1725,"gid":294,"fullname":"Benoni Defense, Fianchetto Variation, Hastings Defense, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.g3 Bg7 8.Bg2 O-O  9.O-O Nbd7 10.Nd2 a6 11.a4 Re8 12.a5 *","weight":36684,"prior_delay":25,"prior_completion_time":0},
{"oid":1726,"gid":294,"fullname":"Benoni Defense, Fianchetto Variation, Hastings Defense, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.g3 Bg7 8.Bg2 O-O  9.O-O Nbd7 10.Nd2 a6 11.a4 Re8 12.h3 Rb8 13.Nc4 Ne5 *","weight":36903,"prior_delay":25,"prior_completion_time":0},
{"oid":1727,"gid":294,"fullname":"Benoni Defense, Fianchetto Variation, Hastings Defense, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.g3 Bg7 8.Bg2 O-O  9.O-O Nbd7 10.Nd2 a6 11.a4 Re8 12.Nc4 Ne5 *","weight":36695,"prior_delay":25,"prior_completion_time":0},
{"oid":1728,"gid":294,"fullname":"Benoni Defense, Fianchetto Variation, Hastings Defense","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.g3 Bg7 8.Bg2 O-O  9.O-O Nbd7 10.Nd2 Re8 *","weight":36651,"prior_delay":25,"prior_completion_time":0},
{"oid":1729,"gid":294,"fullname":"Benoni Defense, Fianchetto Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.g3 Bg7 8.Bg2 O-O  9.O-O Na6 10.Nd2 Nc7 *","weight":36636,"prior_delay":25,"prior_completion_time":0},
{"oid":1730,"gid":294,"fullname":"Benoni Defense, Fianchetto Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.g3 Bg7 8.Bg2 O-O  9.O-O a6 10.a4 Nbd7 *","weight":36855,"prior_delay":25,"prior_completion_time":0},
{"oid":1731,"gid":294,"fullname":"Benoni Defense, Fianchetto Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.g3 Bg7 8.Bg2 O-O  9.O-O Re8 *","weight":36815,"prior_delay":25,"prior_completion_time":0},
{"oid":1732,"gid":295,"fullname":"Benoni Defense, Four Pawns Attack, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.Nf3 O-O  9.Be2 Re8 10.e5 dxe5 11.fxe5 Ng4 12.Bg5 f6 *","weight":35261,"prior_delay":25,"prior_completion_time":0},
{"oid":1733,"gid":295,"fullname":"Benoni Defense, Four Pawns Attack, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.Nf3 O-O  9.Be2 Re8 10.e5 dxe5 11.fxe5 Ng4 12.Bg5 Qb6 13.O-O Nxe5 14.Nxe5 *","weight":35273,"prior_delay":25,"prior_completion_time":0},
{"oid":1734,"gid":295,"fullname":"Benoni Defense, Four Pawns Attack, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.Nf3 O-O  9.Be2 Re8 10.e5 dxe5 11.fxe5 Ng4 12.e6 *","weight":35260,"prior_delay":25,"prior_completion_time":0},
{"oid":1735,"gid":295,"fullname":"Benoni Defense, Four Pawns Attack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.Nf3 O-O  9.Bd3 *","weight":35195,"prior_delay":25,"prior_completion_time":0},
{"oid":1736,"gid":295,"fullname":"Benoni Defense, Four Pawns Attack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.Nf3 O-O  9.Be2 b5 10.e5 dxe5 11.fxe5 Ng4 12.Bg5 *","weight":35209,"prior_delay":25,"prior_completion_time":0},
{"oid":1737,"gid":295,"fullname":"Benoni Defense, Four Pawns Attack","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.Nf3 O-O  9.Be2 Bg4 10.O-O Nbd7 11.h3 Bxf3 12.Bxf3 Re8 13.Re1 *","weight":35366,"prior_delay":25,"prior_completion_time":0},
{"oid":1738,"gid":296,"fullname":"Benoni Defense, Hromodka System","main_line":"1.d4 Nf6 2.c4 c5 3.d5 d6 4.Nc3 g6 5.e4 Bg7 6.f3 *","weight":32976,"prior_delay":25,"prior_completion_time":0},
{"oid":1739,"gid":296,"fullname":"Benoni Defense, Hromodka System","main_line":"1.d4 Nf6 2.c4 c5 3.d5 d6 4.Nc3 g6 5.e4 Bg7 6.Bd3 *","weight":33041,"prior_delay":25,"prior_completion_time":0},
{"oid":1740,"gid":297,"fullname":"Benoni Defense, King's Indian System","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e5 4.Nc3 d6 5.e4 g6 *","weight":33234,"prior_delay":25,"prior_completion_time":0},
{"oid":1741,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 Be7 *","weight":34851,"prior_delay":25,"prior_completion_time":0},
{"oid":1742,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Bd3 Bg7 8.Nge2 O-O  9.O-O Na6 *","weight":35133,"prior_delay":25,"prior_completion_time":0},
{"oid":1743,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f3 Bg7 8.Bg5 h6  9.Be3 O-O 10.Qd2 a6 11.a4 Nbd7 *","weight":35117,"prior_delay":25,"prior_completion_time":0},
{"oid":1744,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Bd3 Bg7 8.Nge2 O-O  9.O-O a6 *","weight":35144,"prior_delay":25,"prior_completion_time":0},
{"oid":1745,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f3 Bg7 8.Bg5 h6  9.Be3 O-O 10.Qd2 a6 11.a4 h5 *","weight":35117,"prior_delay":25,"prior_completion_time":0},
{"oid":1746,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Bd3 Bg7 8.Nge2 O-O  9.O-O b6 *","weight":35134,"prior_delay":25,"prior_completion_time":0},
{"oid":1747,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f3 Bg7 8.Bg5 h6  9.Be3 O-O 10.Qd2 Re8 *","weight":35104,"prior_delay":25,"prior_completion_time":0},
{"oid":1748,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f3 Bg7 8.Bg5 O-O  9.Qd2 a6 *","weight":35106,"prior_delay":25,"prior_completion_time":0},
{"oid":1749,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f3 Bg7 8.Bg5 h6  9.Be3 O-O 10.Nge2 *","weight":35087,"prior_delay":25,"prior_completion_time":0},
{"oid":1750,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f3 Bg7 8.Bg5 h6  9.Be3 O-O 10.Qd2 a6 11.a4 Re8 12.Nge2 Nbd7 *","weight":35146,"prior_delay":25,"prior_completion_time":0},
{"oid":1751,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Bf4 a6 8.Nf3 b5  9.Qe2 *","weight":35077,"prior_delay":25,"prior_completion_time":0},
{"oid":1752,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f3 Bg7 8.Nge2 *","weight":35077,"prior_delay":25,"prior_completion_time":0},
{"oid":1753,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.Bd3 Bg7 8.h3 *","weight":35124,"prior_delay":25,"prior_completion_time":0},
{"oid":1754,"gid":298,"fullname":"Benoni Defense, King's Pawn Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.h3 *","weight":35088,"prior_delay":25,"prior_completion_time":0},
{"oid":1755,"gid":299,"fullname":"Benoni Defense, Knight's Tour Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.Nd2 Nbd7 *","weight":35637,"prior_delay":25,"prior_completion_time":0},
{"oid":1756,"gid":299,"fullname":"Benoni Defense, Knight's Tour Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.Nd2 Bg7 8.Nc4 O-O  9.Bg5 *","weight":35717,"prior_delay":25,"prior_completion_time":0},
{"oid":1757,"gid":299,"fullname":"Benoni Defense, Knight's Tour Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.Nd2 Bg7 8.Nc4 O-O  9.Bf4 *","weight":35714,"prior_delay":25,"prior_completion_time":0},
{"oid":1758,"gid":301,"fullname":"Benoni Defense, Mikenas Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.e5 Nfd7  9.Nb5 dxe5 10.Nd6+ Ke7 11.Nxc8+ Qxc8 12.Nf3 *","weight":35197,"prior_delay":25,"prior_completion_time":0},
{"oid":1759,"gid":302,"fullname":"Benoni Defense, Modern Variation, Snake Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 Bd6 6.Nf3 Bc7 *","weight":34322,"prior_delay":25,"prior_completion_time":0},
{"oid":1760,"gid":302,"fullname":"Benoni Defense, Modern Variation, Snake Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 Bd6 6.e4 O-O 7.Nf3 *","weight":34293,"prior_delay":25,"prior_completion_time":0},
{"oid":1761,"gid":302,"fullname":"Benoni Defense, Modern Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 g6 *","weight":34268,"prior_delay":25,"prior_completion_time":0},
{"oid":1762,"gid":302,"fullname":"Benoni Defense, Modern Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nf3 exd5 5.cxd5 Bd6 *","weight":33653,"prior_delay":25,"prior_completion_time":0},
{"oid":1763,"gid":302,"fullname":"Benoni Defense, Modern Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.g3 exd5 5.cxd5 b5 *","weight":33582,"prior_delay":25,"prior_completion_time":0},
{"oid":1764,"gid":302,"fullname":"Benoni Defense, Modern Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.Nxd5 *","weight":33670,"prior_delay":25,"prior_completion_time":0},
{"oid":1765,"gid":303,"fullname":"Benoni Defense, Old Benoni, Schmid Variation","main_line":"1.d4 c5 2.d5 d6 3.Nc3 g6 *","weight":11973,"prior_delay":25,"prior_completion_time":0},
{"oid":1766,"gid":303,"fullname":"Benoni Defense, Old Benoni","main_line":"1.d4 c5 2.d5 d6 3.Nf3 Nf6 *","weight":11964,"prior_delay":25,"prior_completion_time":0},
{"oid":1767,"gid":303,"fullname":"Benoni Defense, Old Benoni","main_line":"1.d4 c5 2.d5 d6 3.e4 g6 4.Nc3 Bg7 *","weight":11991,"prior_delay":25,"prior_completion_time":0},
{"oid":1768,"gid":303,"fullname":"Benoni Defense, Old Benoni","main_line":"1.d4 c5 2.d5 d6 3.Nc3 Nf6 *","weight":11968,"prior_delay":25,"prior_completion_time":0},
{"oid":1769,"gid":303,"fullname":"Benoni Defense, Old Benoni","main_line":"1.d4 c5 2.d5 d6 3.e4 Nf6 4.Nc3 g6 5.Nf3 Bg7 6.Be2 O-O 7.O-O e6 *","weight":12181,"prior_delay":25,"prior_completion_time":0},
{"oid":1770,"gid":303,"fullname":"Benoni Defense, Old Benoni","main_line":"1.d4 c5 2.d5 d6 3.e4 Nf6 4.Nc3 g6 5.Nf3 Bg7 6.h3 O-O *","weight":12070,"prior_delay":25,"prior_completion_time":0},
{"oid":1771,"gid":303,"fullname":"Benoni Defense, Old Benoni","main_line":"1.d4 c5 2.d5 d6 3.e4 Nf6 4.Nc3 g6 5.Nf3 Bg7 6.Be2 O-O 7.O-O Na6 8.h3 *","weight":12201,"prior_delay":25,"prior_completion_time":0},
{"oid":1772,"gid":303,"fullname":"Benoni Defense, Old Benoni","main_line":"1.d4 c5 2.d5 d6 3.e4 Nf6 4.Nc3 g6 5.Nf3 Bg7 6.Bb5+ *","weight":12095,"prior_delay":25,"prior_completion_time":0},
{"oid":1773,"gid":304,"fullname":"Benoni Defense, Taimanov Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.Bb5+ Nfd7  9.a4 O-O 10.Nf3 Na6 11.O-O Nc7 *","weight":35414,"prior_delay":25,"prior_completion_time":0},
{"oid":1774,"gid":304,"fullname":"Benoni Defense, Taimanov Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.Bb5+ Nfd7  9.a4 Qh4+ *","weight":35358,"prior_delay":25,"prior_completion_time":0},
{"oid":1775,"gid":304,"fullname":"Benoni Defense, Taimanov Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.Bb5+ Nfd7  9.a4 a6 10.Bd3 *","weight":35353,"prior_delay":25,"prior_completion_time":0},
{"oid":1776,"gid":304,"fullname":"Benoni Defense, Taimanov Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.Bb5+ Nfd7  9.a4 a6 10.Be2 *","weight":35351,"prior_delay":25,"prior_completion_time":0},
{"oid":1777,"gid":304,"fullname":"Benoni Defense, Taimanov Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.e4 g6 7.f4 Bg7 8.Bb5+ Nbd7  9.e5 dxe5 10.fxe5 Nh5 11.e6 *","weight":35316,"prior_delay":25,"prior_completion_time":0},
{"oid":1778,"gid":305,"fullname":"Benoni Defense, Uhlmann Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.Bg5 h6 *","weight":35440,"prior_delay":25,"prior_completion_time":0},
{"oid":1779,"gid":305,"fullname":"Benoni Defense, Uhlmann Variation","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.Bg5 Bg7 *","weight":35442,"prior_delay":25,"prior_completion_time":0},
{"oid":1780,"gid":306,"fullname":"Benoni Defense, Woozle","main_line":"1.d4 c5 2.d5 Nf6 3.Nc3 Qa5 *","weight":11968,"prior_delay":25,"prior_completion_time":0},
{"oid":1781,"gid":300,"fullname":"Benoni Defense, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.Bf4 a6 *","weight":35749,"prior_delay":25,"prior_completion_time":0},
{"oid":1782,"gid":300,"fullname":"Benoni Defense, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 a6 *","weight":32613,"prior_delay":25,"prior_completion_time":0},
{"oid":1783,"gid":300,"fullname":"Benoni Defense, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.e3 e6 *","weight":30785,"prior_delay":25,"prior_completion_time":0},
{"oid":1784,"gid":300,"fullname":"Benoni Defense, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.e3 g6 4.Nc3 *","weight":30813,"prior_delay":25,"prior_completion_time":0},
{"oid":1785,"gid":300,"fullname":"Benoni Defense, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.h3 *","weight":35540,"prior_delay":25,"prior_completion_time":0},
{"oid":1786,"gid":300,"fullname":"Benoni Defense, Main Line","main_line":"1.d4 Nf6 2.c4 c5 3.d5 e6 4.Nc3 exd5 5.cxd5 d6 6.Nf3 g6 7.Bf4 Bg7 8.Qa4+ *","weight":35766,"prior_delay":25,"prior_completion_time":0},
{"oid":1787,"gid":307,"fullname":"Blumenfeld Countergambit, Dus-Khotimirsky Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 c5 4.d5 b5 5.Bg5 h6 *","weight":56573,"prior_delay":25,"prior_completion_time":0},
{"oid":1788,"gid":307,"fullname":"Blumenfeld Countergambit, Dus-Khotimirsky Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 c5 4.d5 b5 5.Bg5 Qa5+ *","weight":56579,"prior_delay":25,"prior_completion_time":0},
{"oid":1789,"gid":309,"fullname":"Blumenfeld Countergambit, Spielmann Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 c5 4.d5 b5 5.Bg5 exd5 6.cxd5 h6 *","weight":56670,"prior_delay":25,"prior_completion_time":0},
{"oid":1790,"gid":308,"fullname":"Blumenfeld Countergambit, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 c5 4.e3 b6 *","weight":55532,"prior_delay":25,"prior_completion_time":0},
{"oid":1791,"gid":308,"fullname":"Blumenfeld Countergambit, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 c5 4.e3 cxd4 *","weight":55557,"prior_delay":25,"prior_completion_time":0},
{"oid":1792,"gid":310,"fullname":"Blumenfeld Countergambit Accepted, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 c5 4.d5 b5 5.dxe6 fxe6 6.cxb5 d5 *","weight":56752,"prior_delay":25,"prior_completion_time":0},
{"oid":1793,"gid":311,"fullname":"Bogo-Indian Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Bd2 Bxd2+ 5.Nbxd2 *","weight":58013,"prior_delay":25,"prior_completion_time":0},
{"oid":1794,"gid":311,"fullname":"Bogo-Indian Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Bd2 Bxd2+ 5.Qxd2 O-O 6.Nc3 d5 7.e3 *","weight":58518,"prior_delay":25,"prior_completion_time":0},
{"oid":1795,"gid":312,"fullname":"Bogo-Indian Defense, Grunfeld Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Nbd2 O-O 5.a3 Bxd2+ *","weight":58301,"prior_delay":25,"prior_completion_time":0},
{"oid":1796,"gid":312,"fullname":"Bogo-Indian Defense, Grunfeld Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Nbd2 b6 5.a3 Bxd2+ 6.Bxd2 Bb7 *","weight":58587,"prior_delay":25,"prior_completion_time":0},
{"oid":1797,"gid":312,"fullname":"Bogo-Indian Defense, Grunfeld Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Nbd2 d5 *","weight":57601,"prior_delay":25,"prior_completion_time":0},
{"oid":1798,"gid":312,"fullname":"Bogo-Indian Defense, Grunfeld Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Nbd2 O-O 5.a3 Be7 6.e4 d5 *","weight":58859,"prior_delay":25,"prior_completion_time":0},
{"oid":1799,"gid":313,"fullname":"Bogo-Indian Defense, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Bd2 Qe7 5.g3 Nc6 6.Bg2 Bxd2+ 7.Nbxd2 d6  8.O-O O-O *","weight":59109,"prior_delay":25,"prior_completion_time":0},
{"oid":1800,"gid":313,"fullname":"Bogo-Indian Defense, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Bd2 Qe7 5.g3 Nc6 6.Bg2 Bxd2+ 7.Nbxd2 d6  8.O-O a5 9.e4 e5 10.d5 Nb8 *","weight":59214,"prior_delay":25,"prior_completion_time":0},
{"oid":1801,"gid":313,"fullname":"Bogo-Indian Defense, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Bd2 Qe7 5.g3 O-O 6.Bg2 *","weight":58618,"prior_delay":25,"prior_completion_time":0},
{"oid":1802,"gid":313,"fullname":"Bogo-Indian Defense, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Bd2 Qe7 5.Nc3 *","weight":58161,"prior_delay":25,"prior_completion_time":0},
{"oid":1803,"gid":313,"fullname":"Bogo-Indian Defense, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Bd2 Qe7 5.g3 Nc6 6.Nc3 Bxc3 7.Bxc3 Ne4 8.Rc1 *","weight":59466,"prior_delay":25,"prior_completion_time":0},
{"oid":1804,"gid":314,"fullname":"Bogo-Indian Defense, Retreat Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Bd2 Be7 5.g3 d5 6.Bg2 O-O 7.O-O c6 8.Qc2 b6  9.Bf4 *","weight":61979,"prior_delay":25,"prior_completion_time":0},
{"oid":1805,"gid":315,"fullname":"Bogo-Indian Defense, Vitolins Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Bd2 c5 5.Bxb4 cxb4 6.g3 *","weight":58648,"prior_delay":25,"prior_completion_time":0},
{"oid":1806,"gid":316,"fullname":"Bogo-Indian Defense, Wade-Smyslov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Bd2 a5 5.Nc3 *","weight":58181,"prior_delay":25,"prior_completion_time":0},
{"oid":1807,"gid":316,"fullname":"Bogo-Indian Defense, Wade-Smyslov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Bb4+ 4.Bd2 a5 5.g3 *","weight":58321,"prior_delay":25,"prior_completion_time":0},
{"oid":1808,"gid":317,"fullname":"Budapest Defense, Adler Variation","main_line":"1.d4 Nf6 2.c4 e5 3.dxe5 Ng4 4.Nf3 Bc5 5.e3 Nc6 6.Be2 *","weight":30693,"prior_delay":25,"prior_completion_time":0},
{"oid":1809,"gid":318,"fullname":"Budapest Defense, Alekhine Variation, Abonyi Variation","main_line":"1.d4 Nf6 2.c4 e5 3.dxe5 Ng4 4.e4 Nxe5 5.f4 Nec6 *","weight":30530,"prior_delay":25,"prior_completion_time":0},
{"oid":1810,"gid":320,"fullname":"Budapest Defense, Rubinstein Variation","main_line":"1.d4 Nf6 2.c4 e5 3.dxe5 Ng4 4.Bf4 Bb4+ *","weight":30590,"prior_delay":25,"prior_completion_time":0},
{"oid":1811,"gid":320,"fullname":"Budapest Defense, Rubinstein Variation","main_line":"1.d4 Nf6 2.c4 e5 3.dxe5 Ng4 4.Bf4 Nc6 5.Nf3 Bb4+ 6.Nbd2 Qe7 7.e3 *","weight":30756,"prior_delay":25,"prior_completion_time":0},
{"oid":1812,"gid":319,"fullname":"Budapest Defense, Main Line","main_line":"1.d4 Nf6 2.c4 e5 3.dxe5 Ng4 4.e3 *","weight":30518,"prior_delay":25,"prior_completion_time":0},
{"oid":1813,"gid":321,"fullname":"Catalan Opening, Closed, Spassky Gambit","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.b3 b6  9.Rd1 Bb7 10.Nc3 b5 *","weight":47552,"prior_delay":25,"prior_completion_time":0},
{"oid":1814,"gid":321,"fullname":"Catalan Opening, Closed","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.b3 b6  9.Rd1 Bb7 10.Nc3 Rc8 11.e4 dxe4 *","weight":47594,"prior_delay":25,"prior_completion_time":0},
{"oid":1815,"gid":321,"fullname":"Catalan Opening, Closed","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.b3 b5 *","weight":47422,"prior_delay":25,"prior_completion_time":0},
{"oid":1816,"gid":321,"fullname":"Catalan Opening, Closed","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.b3 b6  9.Rd1 Ba6 10.Nbd2 *","weight":47587,"prior_delay":25,"prior_completion_time":0},
{"oid":1817,"gid":322,"fullname":"Catalan Opening, Closed Variation, Botvinnik Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Nc3 c6 8.Qd3 *","weight":47237,"prior_delay":25,"prior_completion_time":0},
{"oid":1818,"gid":322,"fullname":"Catalan Opening, Closed Variation, Rabinovich Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.Nbd2 b5 *","weight":47437,"prior_delay":25,"prior_completion_time":0},
{"oid":1819,"gid":322,"fullname":"Catalan Opening, Closed Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.Nbd2 b6  9.e4 Bb7 10.b3 Rc8 11.Bb2 Qc7 *","weight":47616,"prior_delay":25,"prior_completion_time":0},
{"oid":1820,"gid":322,"fullname":"Catalan Opening, Closed Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.Nbd2 b6  9.e4 Bb7 10.b3 Rc8 11.Bb2 c5 *","weight":47616,"prior_delay":25,"prior_completion_time":0},
{"oid":1821,"gid":322,"fullname":"Catalan Opening, Closed Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.Nbd2 b6  9.b3 Bb7 10.Bb2 Rc8 *","weight":47508,"prior_delay":25,"prior_completion_time":0},
{"oid":1822,"gid":322,"fullname":"Catalan Opening, Closed Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.Nbd2 b6  9.e4 Ba6 10.b3 *","weight":47589,"prior_delay":25,"prior_completion_time":0},
{"oid":1823,"gid":322,"fullname":"Catalan Opening, Closed Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.Nbd2 b6  9.e4 Bb7 10.e5 *","weight":47646,"prior_delay":25,"prior_completion_time":0},
{"oid":1824,"gid":322,"fullname":"Catalan Opening, Closed Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.Nbd2 b6  9.e4 dxe4 10.Nxe4 Nxe4 11.Qxe4 *","weight":47580,"prior_delay":25,"prior_completion_time":0},
{"oid":1825,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 b6 *","weight":47301,"prior_delay":25,"prior_completion_time":0},
{"oid":1826,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c5 *","weight":47300,"prior_delay":25,"prior_completion_time":0},
{"oid":1827,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.Bf4 Nh5 *","weight":47424,"prior_delay":25,"prior_completion_time":0},
{"oid":1828,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.Bf4 Ne4 *","weight":47389,"prior_delay":25,"prior_completion_time":0},
{"oid":1829,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qc2 c6 8.Bf4 b6 *","weight":47494,"prior_delay":25,"prior_completion_time":0},
{"oid":1830,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O c6 7.Qc2 b6 *","weight":47457,"prior_delay":25,"prior_completion_time":0},
{"oid":1831,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 c6 5.Nf3 Nbd7 *","weight":43837,"prior_delay":25,"prior_completion_time":0},
{"oid":1832,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Bb4+ 5.Nd2 O-O *","weight":44232,"prior_delay":25,"prior_completion_time":0},
{"oid":1833,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.Qc2 *","weight":45892,"prior_delay":25,"prior_completion_time":0},
{"oid":1834,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Nc3 dxc4 8.e4 *","weight":47240,"prior_delay":25,"prior_completion_time":0},
{"oid":1835,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Nc3 c6 8.Qb3 *","weight":47221,"prior_delay":25,"prior_completion_time":0},
{"oid":1836,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O c6 7.Nbd2 *","weight":47296,"prior_delay":25,"prior_completion_time":0},
{"oid":1837,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Bb4+ 5.Bd2 *","weight":44242,"prior_delay":25,"prior_completion_time":0},
{"oid":1838,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.b3 c6 8.Bb2 *","weight":47222,"prior_delay":25,"prior_completion_time":0},
{"oid":1839,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Qd3 *","weight":47210,"prior_delay":25,"prior_completion_time":0},
{"oid":1840,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O Nbd7 7.Nc3 c6 8.b3 *","weight":47257,"prior_delay":25,"prior_completion_time":0},
{"oid":1841,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O c6 7.b3 *","weight":47296,"prior_delay":25,"prior_completion_time":0},
{"oid":1842,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 c5 5.Nf3 *","weight":43808,"prior_delay":25,"prior_completion_time":0},
{"oid":1843,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O c5 7.cxd5 *","weight":47182,"prior_delay":25,"prior_completion_time":0},
{"oid":1844,"gid":322,"fullname":"Catalan Opening, Closed Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 Be7 5.Nf3 O-O 6.O-O c6 7.Nc3 b6 8.Ne5 *","weight":47320,"prior_delay":25,"prior_completion_time":0},
{"oid":1845,"gid":323,"fullname":"Catalan Opening, Hungarian Gambit","main_line":"1.d4 Nf6 2.c4 e6 3.g3 e5 *","weight":41507,"prior_delay":25,"prior_completion_time":0},
{"oid":1846,"gid":325,"fullname":"Catalan Opening, Open Defense, Alekhine Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Qa4+ Nbd7 6.Qxc4 a6 7.Qc2 *","weight":44142,"prior_delay":25,"prior_completion_time":0},
{"oid":1847,"gid":325,"fullname":"Catalan Opening, Open Defense, Classical Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Be7 6.O-O O-O 7.Qc2 a6 8.a4 Bd7  9.Qxc4 Bc6 10.Bg5 Bd5 *","weight":49008,"prior_delay":25,"prior_completion_time":0},
{"oid":1848,"gid":325,"fullname":"Catalan Opening, Open Defense, Classical Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Be7 6.O-O O-O 7.Qc2 a6 8.a4 Bd7  9.Qxc4 Bc6 10.Bg5 a5 *","weight":48895,"prior_delay":25,"prior_completion_time":0},
{"oid":1849,"gid":325,"fullname":"Catalan Opening, Open Defense, Classical Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Be7 6.O-O O-O 7.Qc2 a6 8.Qxc4 b5  9.Qc2 Bb7 10.Bd2 Be4 *","weight":48918,"prior_delay":25,"prior_completion_time":0},
{"oid":1850,"gid":325,"fullname":"Catalan Opening, Open Defense, Classical Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Be7 6.O-O O-O 7.Qc2 a6 8.Qxc4 b5  9.Qc2 Bb7 10.Bf4 Nc6 11.Rd1 *","weight":48424,"prior_delay":25,"prior_completion_time":0},
{"oid":1851,"gid":325,"fullname":"Catalan Opening, Open Defense, Classical Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Be7 6.O-O O-O 7.Qc2 a6 8.Qxc4 b5  9.Qc2 Bb7 10.Bg5 *","weight":48373,"prior_delay":25,"prior_completion_time":0},
{"oid":1852,"gid":325,"fullname":"Catalan Opening, Open Defense, Classical Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Be7 6.O-O O-O 7.Ne5 Nc6 8.Nxc6 *","weight":44698,"prior_delay":25,"prior_completion_time":0},
{"oid":1853,"gid":325,"fullname":"Catalan Opening, Open Defense, Classical Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Be7 6.Nc3 O-O 7.Ne5 *","weight":44394,"prior_delay":25,"prior_completion_time":0},
{"oid":1854,"gid":325,"fullname":"Catalan Opening, Open Defense, Modern Sharp Variation","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Nc6 6.Qa4 Bb4+ 7.Bd2 Nd5 *","weight":45258,"prior_delay":25,"prior_completion_time":0},
{"oid":1855,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Bd7 *","weight":44376,"prior_delay":25,"prior_completion_time":0},
{"oid":1856,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 c5 6.O-O Nc6 7.Qa4 cxd4 *","weight":45066,"prior_delay":25,"prior_completion_time":0},
{"oid":1857,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Bb4+ *","weight":44457,"prior_delay":25,"prior_completion_time":0},
{"oid":1858,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Qa4+ Nbd7 6.Qxc4 a6 7.Nf3 b5 8.Qc2 *","weight":44172,"prior_delay":25,"prior_completion_time":0},
{"oid":1859,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 b5 *","weight":44317,"prior_delay":25,"prior_completion_time":0},
{"oid":1860,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 c5 6.O-O Nc6 7.Qa4 Bd7 8.Qxc4 b5 *","weight":45228,"prior_delay":25,"prior_completion_time":0},
{"oid":1861,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Qa4+ Nbd7 6.Qxc4 a6 7.Nf3 b5 8.Qc6 *","weight":44185,"prior_delay":25,"prior_completion_time":0},
{"oid":1862,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Nc6 6.O-O Rb8 7.Nc3 *","weight":44756,"prior_delay":25,"prior_completion_time":0},
{"oid":1863,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Qa4+ Nbd7 6.Qxc4 c5 7.Nf3 a6 8.O-O *","weight":44150,"prior_delay":25,"prior_completion_time":0},
{"oid":1864,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Qa4+ Nbd7 6.Qxc4 c5 7.Nf3 a6 8.Qc2 b6 *","weight":44176,"prior_delay":25,"prior_completion_time":0},
{"oid":1865,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 Nbd7 *","weight":44312,"prior_delay":25,"prior_completion_time":0},
{"oid":1866,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Qa4+ Nbd7 6.Nf3 a6 7.Nc3 *","weight":44208,"prior_delay":25,"prior_completion_time":0},
{"oid":1867,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 c5 6.O-O Nc6 7.Ne5 Bd7 8.Na3 cxd4  9.Naxc4 *","weight":45367,"prior_delay":25,"prior_completion_time":0},
{"oid":1868,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 a6 6.O-O Nc6 7.e3 *","weight":45234,"prior_delay":25,"prior_completion_time":0},
{"oid":1869,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Qa4+ Bd7 6.Qxc4 *","weight":44097,"prior_delay":25,"prior_completion_time":0},
{"oid":1870,"gid":325,"fullname":"Catalan Opening, Open Defense","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Bg2 dxc4 5.Nf3 a6 6.O-O b5 7.Ne5 Nd5 8.a4 *","weight":44977,"prior_delay":25,"prior_completion_time":0},
{"oid":1871,"gid":324,"fullname":"Catalan Opening, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Nf3 c5 *","weight":43558,"prior_delay":25,"prior_completion_time":0},
{"oid":1872,"gid":324,"fullname":"Catalan Opening, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Nf3 Be7 *","weight":44320,"prior_delay":25,"prior_completion_time":0},
{"oid":1873,"gid":324,"fullname":"Catalan Opening, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Nf3 c6 *","weight":43572,"prior_delay":25,"prior_completion_time":0},
{"oid":1874,"gid":324,"fullname":"Catalan Opening, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 Bb4+ 4.Bd2 Bxd2+ *","weight":42816,"prior_delay":25,"prior_completion_time":0},
{"oid":1875,"gid":324,"fullname":"Catalan Opening, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 c6 *","weight":41509,"prior_delay":25,"prior_completion_time":0},
{"oid":1876,"gid":324,"fullname":"Catalan Opening, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 Bb4+ 4.Bd2 Be7 *","weight":42929,"prior_delay":25,"prior_completion_time":0},
{"oid":1877,"gid":324,"fullname":"Catalan Opening, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Nf3 dxc4 5.Qa4+ Nbd7 *","weight":44664,"prior_delay":25,"prior_completion_time":0},
{"oid":1878,"gid":324,"fullname":"Catalan Opening, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 Bb4+ 4.Nd2 *","weight":42157,"prior_delay":25,"prior_completion_time":0},
{"oid":1879,"gid":324,"fullname":"Catalan Opening, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 c5 4.Nf3 *","weight":42178,"prior_delay":25,"prior_completion_time":0},
{"oid":1880,"gid":324,"fullname":"Catalan Opening, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 Bb4+ 4.Bd2 Qe7 5.Bg2 *","weight":42900,"prior_delay":25,"prior_completion_time":0},
{"oid":1881,"gid":324,"fullname":"Catalan Opening, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.g3 d5 4.Nf3 Bb4+ 5.Bd2 *","weight":45746,"prior_delay":25,"prior_completion_time":0},
{"oid":1882,"gid":326,"fullname":"Colle System, Traditional Colle","main_line":"1.d4 d5 2.Nf3 Nf6 3.e3 e6 4.Bd3 c5 5.c3 Nc6 6.Nbd2 Bd6 7.O-O O-O  8.dxc5 Bxc5 *","weight":21446,"prior_delay":25,"prior_completion_time":0},
{"oid":1883,"gid":326,"fullname":"Colle System, Traditional Colle","main_line":"1.d4 d5 2.Nf3 Nf6 3.e3 e6 4.Bd3 c5 5.c3 Nbd7 *","weight":21412,"prior_delay":25,"prior_completion_time":0},
{"oid":1884,"gid":327,"fullname":"Dutch Defense, Alekhine Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O Ne4 7.d5 *","weight":12997,"prior_delay":25,"prior_completion_time":0},
{"oid":1885,"gid":327,"fullname":"Dutch Defense, Alekhine Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O Ne4 7.Nbd2 *","weight":12999,"prior_delay":25,"prior_completion_time":0},
{"oid":1886,"gid":328,"fullname":"Dutch Defense, Blackburne Variation","main_line":"1.d4 f5 2.g3 Nf6 3.Bg2 e6 4.Nh3 *","weight":13919,"prior_delay":25,"prior_completion_time":0},
{"oid":1887,"gid":329,"fullname":"Dutch Defense, Blackmar's Second Gambit","main_line":"1.d4 f5 2.e4 fxe4 3.Nc3 Nf6 4.f3 d5 5.fxe4 *","weight":12008,"prior_delay":25,"prior_completion_time":0},
{"oid":1888,"gid":330,"fullname":"Dutch Defense, Classical Variation, Buenos Aires Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d6 7.Nc3 a5 8.Re1 *","weight":13078,"prior_delay":25,"prior_completion_time":0},
{"oid":1889,"gid":330,"fullname":"Dutch Defense, Classical Variation, Buenos Aires Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d6 7.Nc3 a5 8.Qc2 *","weight":13076,"prior_delay":25,"prior_completion_time":0},
{"oid":1890,"gid":330,"fullname":"Dutch Defense, Classical Variation, Buenos Aires Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d6 7.Nc3 a5 8.b3 *","weight":13076,"prior_delay":25,"prior_completion_time":0},
{"oid":1891,"gid":330,"fullname":"Dutch Defense, Classical Variation, Ilyin-Zhenevsky Variation, Alatortsev-Lisitsyn Line","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d6 7.Nc3 Qe8 8.Qc2 Qh5 *","weight":13085,"prior_delay":25,"prior_completion_time":0},
{"oid":1892,"gid":330,"fullname":"Dutch Defense, Classical Variation, Ilyin-Zhenevsky Variation, Alatortsev-Lisitsyn Line","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d6 7.Nc3 Qe8 8.Qc2 Nc6 *","weight":13085,"prior_delay":25,"prior_completion_time":0},
{"oid":1893,"gid":330,"fullname":"Dutch Defense, Classical Variation, Ilyin-Zhenevsky Variation, Modern Main Line","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d6 7.Nc3 Qe8 8.b3 a5  9.Ba3 *","weight":13083,"prior_delay":25,"prior_completion_time":0},
{"oid":1894,"gid":330,"fullname":"Dutch Defense, Classical Variation, Ilyin-Zhenevsky Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d6 7.Nc3 Qe8 8.b4 *","weight":13085,"prior_delay":25,"prior_completion_time":0},
{"oid":1895,"gid":330,"fullname":"Dutch Defense, Classical Variation, Stonewall Variation, Botvinnik Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d5 7.b3 Nc6 *","weight":13044,"prior_delay":25,"prior_completion_time":0},
{"oid":1896,"gid":330,"fullname":"Dutch Defense, Classical Variation, Stonewall Variation, Botvinnik Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d5 7.b3 b6 *","weight":13038,"prior_delay":25,"prior_completion_time":0},
{"oid":1897,"gid":330,"fullname":"Dutch Defense, Classical Variation, Stonewall Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d5 7.b3 c6 8.Ba3 Bd7 *","weight":13049,"prior_delay":25,"prior_completion_time":0},
{"oid":1898,"gid":330,"fullname":"Dutch Defense, Classical Variation, Stonewall Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d5 7.b3 c6 8.Ba3 Nbd7  9.Bxe7 *","weight":13054,"prior_delay":25,"prior_completion_time":0},
{"oid":1899,"gid":330,"fullname":"Dutch Defense, Classical Variation, Stonewall Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d5 7.Nc3 c6 8.Bf4 *","weight":13027,"prior_delay":25,"prior_completion_time":0},
{"oid":1900,"gid":330,"fullname":"Dutch Defense, Classical Variation, Stonewall Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d5 7.Nc3 c6 8.Bg5 *","weight":13027,"prior_delay":25,"prior_completion_time":0},
{"oid":1901,"gid":330,"fullname":"Dutch Defense, Classical Variation, Stonewall Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d5 7.b3 c6 8.Ba3 Bxa3  9.Nxa3 Qe7 10.Qc1 *","weight":13055,"prior_delay":25,"prior_completion_time":0},
{"oid":1902,"gid":330,"fullname":"Dutch Defense, Classical Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.Nc3 d6 *","weight":12944,"prior_delay":25,"prior_completion_time":0},
{"oid":1903,"gid":330,"fullname":"Dutch Defense, Classical Variation","main_line":"1.d4 f5 2.c4 e6 3.Nf3 Nf6 *","weight":12321,"prior_delay":25,"prior_completion_time":0},
{"oid":1904,"gid":330,"fullname":"Dutch Defense, Classical Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 c6 5.Nf3 d5 6.Qc2 Bd6 *","weight":13089,"prior_delay":25,"prior_completion_time":0},
{"oid":1905,"gid":330,"fullname":"Dutch Defense, Classical Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 d5 6.O-O c6 *","weight":12944,"prior_delay":25,"prior_completion_time":0},
{"oid":1906,"gid":330,"fullname":"Dutch Defense, Classical Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O c6 7.b3 a5 *","weight":13001,"prior_delay":25,"prior_completion_time":0},
{"oid":1907,"gid":330,"fullname":"Dutch Defense, Classical Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 d5 5.Nf3 *","weight":12923,"prior_delay":25,"prior_completion_time":0},
{"oid":1908,"gid":330,"fullname":"Dutch Defense, Classical Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.d5 *","weight":12943,"prior_delay":25,"prior_completion_time":0},
{"oid":1909,"gid":330,"fullname":"Dutch Defense, Classical Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 c6 5.Nf3 d5 6.Nc3 *","weight":13076,"prior_delay":25,"prior_completion_time":0},
{"oid":1910,"gid":330,"fullname":"Dutch Defense, Classical Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d6 7.b3 Qe8 8.Bb2 *","weight":13032,"prior_delay":25,"prior_completion_time":0},
{"oid":1911,"gid":330,"fullname":"Dutch Defense, Classical Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 d5 5.Nh3 *","weight":12908,"prior_delay":25,"prior_completion_time":0},
{"oid":1912,"gid":330,"fullname":"Dutch Defense, Classical Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 c6 5.Nh3 *","weight":12970,"prior_delay":25,"prior_completion_time":0},
{"oid":1913,"gid":331,"fullname":"Dutch Defense, Fianchetto Attack","main_line":"1.d4 f5 2.g3 e6 3.Nf3 Nf6 *","weight":12708,"prior_delay":25,"prior_completion_time":0},
{"oid":1914,"gid":332,"fullname":"Dutch Defense, Fianchetto Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Nf3 d5 *","weight":12500,"prior_delay":25,"prior_completion_time":0},
{"oid":1915,"gid":332,"fullname":"Dutch Defense, Fianchetto Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Nc3 d5 *","weight":12473,"prior_delay":25,"prior_completion_time":0},
{"oid":1916,"gid":333,"fullname":"Dutch Defense, Ilyin-Zhenevsky, Winter Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d6 7.Nc3 Qe8 8.Re1 Qg6  9.e4 fxe4 *","weight":13097,"prior_delay":25,"prior_completion_time":0},
{"oid":1917,"gid":333,"fullname":"Dutch Defense, Ilyin-Zhenevsky, Winter Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d6 7.Nc3 Qe8 8.Re1 Ne4 *","weight":13096,"prior_delay":25,"prior_completion_time":0},
{"oid":1918,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Matulovic Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 Nc6 8.b3 *","weight":13638,"prior_delay":25,"prior_completion_time":0},
{"oid":1919,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Matulovic Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 Nc6 8.d5 Ne5  9.Nxe5 dxe5 10.e4 *","weight":13715,"prior_delay":25,"prior_completion_time":0},
{"oid":1920,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Matulovic Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 Nc6 8.d5 Na5  9.Qd3 *","weight":13722,"prior_delay":25,"prior_completion_time":0},
{"oid":1921,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Matulovic Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 Nc6 8.d5 Na5  9.Nd2 *","weight":13714,"prior_delay":25,"prior_completion_time":0},
{"oid":1922,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Matulovic Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 Nc6 8.d5 Ne5  9.Nxe5 dxe5 10.Qb3 *","weight":13719,"prior_delay":25,"prior_completion_time":0},
{"oid":1923,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Warsaw Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 c6 8.d5 Qa5 *","weight":13897,"prior_delay":25,"prior_completion_time":0},
{"oid":1924,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Warsaw Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 c6 8.d5 cxd5 *","weight":13896,"prior_delay":25,"prior_completion_time":0},
{"oid":1925,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Warsaw Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 c6 8.d5 Bd7 *","weight":13902,"prior_delay":25,"prior_completion_time":0},
{"oid":1926,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Warsaw Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 c6 8.b3 Qa5 *","weight":13900,"prior_delay":25,"prior_completion_time":0},
{"oid":1927,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Warsaw Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 c6 8.d5 Qe8 *","weight":13896,"prior_delay":25,"prior_completion_time":0},
{"oid":1928,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Warsaw Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 c6 8.b3 Na6 *","weight":13913,"prior_delay":25,"prior_completion_time":0},
{"oid":1929,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Warsaw Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 c6 8.d5 e5  9.dxe6 Bxe6 10.Qd3 *","weight":14037,"prior_delay":25,"prior_completion_time":0},
{"oid":1930,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Warsaw Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 c6 8.Qc2 *","weight":13833,"prior_delay":25,"prior_completion_time":0},
{"oid":1931,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Warsaw Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 c6 8.Re1 *","weight":13838,"prior_delay":25,"prior_completion_time":0},
{"oid":1932,"gid":334,"fullname":"Dutch Defense, Leningrad Variation, Warsaw Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 c6 8.d5 e5  9.dxe6 Bxe6 10.b3 *","weight":14047,"prior_delay":25,"prior_completion_time":0},
{"oid":1933,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 e6 *","weight":13610,"prior_delay":25,"prior_completion_time":0},
{"oid":1934,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.d5 c5 *","weight":13193,"prior_delay":25,"prior_completion_time":0},
{"oid":1935,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.b3 c6 *","weight":13195,"prior_delay":25,"prior_completion_time":0},
{"oid":1936,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Nc3 Bg7 *","weight":12511,"prior_delay":25,"prior_completion_time":0},
{"oid":1937,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 Na6 *","weight":13607,"prior_delay":25,"prior_completion_time":0},
{"oid":1938,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.Nc3 d6 *","weight":12913,"prior_delay":25,"prior_completion_time":0},
{"oid":1939,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.d5 c6 *","weight":13195,"prior_delay":25,"prior_completion_time":0},
{"oid":1940,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 *","weight":12939,"prior_delay":25,"prior_completion_time":0},
{"oid":1941,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 d6 *","weight":12547,"prior_delay":25,"prior_completion_time":0},
{"oid":1942,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 Qe8 8.d5 a5 *","weight":13916,"prior_delay":25,"prior_completion_time":0},
{"oid":1943,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 Qe8 8.Nd5 *","weight":13794,"prior_delay":25,"prior_completion_time":0},
{"oid":1944,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nc3 O-O 6.Nh3 *","weight":12848,"prior_delay":25,"prior_completion_time":0},
{"oid":1945,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nh3 *","weight":12672,"prior_delay":25,"prior_completion_time":0},
{"oid":1946,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Nf3 Bg7 5.Nc3 *","weight":12556,"prior_delay":25,"prior_completion_time":0},
{"oid":1947,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nc3 d6 6.Nh3 *","weight":12777,"prior_delay":25,"prior_completion_time":0},
{"oid":1948,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 Qe8 8.d5 Na6  9.Rb1 *","weight":13987,"prior_delay":25,"prior_completion_time":0},
{"oid":1949,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 Qe8 8.Re1 *","weight":13806,"prior_delay":25,"prior_completion_time":0},
{"oid":1950,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 d6 6.Nc3 *","weight":12732,"prior_delay":25,"prior_completion_time":0},
{"oid":1951,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nc3 d6 6.d5 *","weight":12781,"prior_delay":25,"prior_completion_time":0},
{"oid":1952,"gid":334,"fullname":"Dutch Defense, Leningrad Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 g6 4.Bg2 Bg7 5.Nf3 O-O 6.O-O d6 7.Nc3 Qe8 8.b3 *","weight":13788,"prior_delay":25,"prior_completion_time":0},
{"oid":1953,"gid":335,"fullname":"Dutch Defense, Nimzo-Dutch Variation, Alekhine Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Bb4+ 5.Bd2 Be7 *","weight":12843,"prior_delay":25,"prior_completion_time":0},
{"oid":1954,"gid":335,"fullname":"Dutch Defense, Nimzo-Dutch Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Bb4+ 5.Nc3 *","weight":12826,"prior_delay":25,"prior_completion_time":0},
{"oid":1955,"gid":335,"fullname":"Dutch Defense, Nimzo-Dutch Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Bb4+ 5.Nd2 *","weight":12846,"prior_delay":25,"prior_completion_time":0},
{"oid":1956,"gid":336,"fullname":"Dutch Defense, Queen's Knight Variation","main_line":"1.d4 f5 2.c4 Nf6 3.Nc3 e6 4.e3 d5 *","weight":12593,"prior_delay":25,"prior_completion_time":0},
{"oid":1957,"gid":336,"fullname":"Dutch Defense, Queen's Knight Variation","main_line":"1.d4 f5 2.c4 Nf6 3.Nc3 d6 *","weight":12588,"prior_delay":25,"prior_completion_time":0},
{"oid":1958,"gid":336,"fullname":"Dutch Defense, Queen's Knight Variation","main_line":"1.d4 f5 2.c4 Nf6 3.Nc3 e6 4.e3 Bb4 *","weight":12594,"prior_delay":25,"prior_completion_time":0},
{"oid":1959,"gid":336,"fullname":"Dutch Defense, Queen's Knight Variation","main_line":"1.d4 f5 2.c4 Nf6 3.Nc3 g6 4.f3 *","weight":12579,"prior_delay":25,"prior_completion_time":0},
{"oid":1960,"gid":336,"fullname":"Dutch Defense, Queen's Knight Variation","main_line":"1.d4 f5 2.c4 Nf6 3.Nc3 e6 4.Qc2 *","weight":12594,"prior_delay":25,"prior_completion_time":0},
{"oid":1961,"gid":336,"fullname":"Dutch Defense, Queen's Knight Variation","main_line":"1.d4 f5 2.c4 Nf6 3.Nc3 e6 4.Bg5 *","weight":12560,"prior_delay":25,"prior_completion_time":0},
{"oid":1962,"gid":336,"fullname":"Dutch Defense, Queen's Knight Variation","main_line":"1.d4 f5 2.c4 Nf6 3.Nc3 e6 4.Nf3 *","weight":12584,"prior_delay":25,"prior_completion_time":0},
{"oid":1963,"gid":336,"fullname":"Dutch Defense, Queen's Knight Variation","main_line":"1.d4 f5 2.c4 Nf6 3.Nc3 g6 4.Bg5 *","weight":12576,"prior_delay":25,"prior_completion_time":0},
{"oid":1964,"gid":336,"fullname":"Dutch Defense, Queen's Knight Variation","main_line":"1.d4 f5 2.c4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.e3 *","weight":12599,"prior_delay":25,"prior_completion_time":0},
{"oid":1965,"gid":336,"fullname":"Dutch Defense, Queen's Knight Variation","main_line":"1.d4 f5 2.c4 Nf6 3.Nc3 e6 4.a3 *","weight":12557,"prior_delay":25,"prior_completion_time":0},
{"oid":1966,"gid":336,"fullname":"Dutch Defense, Queen's Knight Variation","main_line":"1.d4 f5 2.c4 Nf6 3.Nc3 g6 4.Nf3 Bg7 5.Bf4 *","weight":12603,"prior_delay":25,"prior_completion_time":0},
{"oid":1967,"gid":337,"fullname":"Dutch Defense, Raphael Variation","main_line":"1.d4 f5 2.Nc3 Nf6 3.Bg5 e6 *","weight":12461,"prior_delay":25,"prior_completion_time":0},
{"oid":1968,"gid":337,"fullname":"Dutch Defense, Raphael Variation","main_line":"1.d4 f5 2.Nc3 Nf6 3.Bg5 d5 4.Bxf6 exf6 5.e3 c6 *","weight":12767,"prior_delay":25,"prior_completion_time":0},
{"oid":1969,"gid":338,"fullname":"Dutch Defense, Semi-Leningrad Variation","main_line":"1.d4 f5 2.g3 Nf6 3.Bg2 g6 4.Nf3 *","weight":14478,"prior_delay":25,"prior_completion_time":0},
{"oid":1970,"gid":339,"fullname":"Dutch Defense, Staunton Gambit","main_line":"1.d4 f5 2.e4 fxe4 3.Nc3 Nf6 4.Bg5 Nc6 5.f3 *","weight":12019,"prior_delay":25,"prior_completion_time":0},
{"oid":1971,"gid":339,"fullname":"Dutch Defense, Staunton Gambit","main_line":"1.d4 f5 2.e4 fxe4 3.Nc3 Nf6 4.Bg5 Nc6 5.d5 Ne5 6.Qd4 *","weight":12029,"prior_delay":25,"prior_completion_time":0},
{"oid":1972,"gid":339,"fullname":"Dutch Defense, Staunton Gambit","main_line":"1.d4 f5 2.e4 fxe4 3.Nc3 Nf6 4.Bg5 e6 5.Nxe4 Be7 6.Bxf6 Bxf6 7.Nf3 *","weight":12026,"prior_delay":25,"prior_completion_time":0},
{"oid":1973,"gid":340,"fullname":"Dutch Defense, Stonewall Variation, Modern Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 c6 5.Nf3 d5 6.O-O Bd6 7.b3 *","weight":13441,"prior_delay":25,"prior_completion_time":0},
{"oid":1974,"gid":340,"fullname":"Dutch Defense, Stonewall Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d5 7.Nbd2 c6 *","weight":13010,"prior_delay":25,"prior_completion_time":0},
{"oid":1975,"gid":340,"fullname":"Dutch Defense, Stonewall Variation","main_line":"1.d4 f5 2.c4 Nf6 3.g3 e6 4.Bg2 Be7 5.Nf3 O-O 6.O-O d5 7.Qc2 c6 8.Nbd2 *","weight":13020,"prior_delay":25,"prior_completion_time":0},
{"oid":1976,"gid":341,"fullname":"East Indian Defense, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.e3 c5 *","weight":25750,"prior_delay":25,"prior_completion_time":0},
{"oid":1977,"gid":341,"fullname":"East Indian Defense, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.c3 Bg7 *","weight":25871,"prior_delay":25,"prior_completion_time":0},
{"oid":1978,"gid":341,"fullname":"East Indian Defense, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.e3 Bg7 *","weight":25771,"prior_delay":25,"prior_completion_time":0},
{"oid":1979,"gid":342,"fullname":"Grunfeld Defense, Botvinnik Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.e3 O-O 6.Qb3 e6 7.Bd2 *","weight":52394,"prior_delay":25,"prior_completion_time":0},
{"oid":1980,"gid":343,"fullname":"Grunfeld Defense, Brinckmann Attack, Grunfeld Gambit, Botvinnik Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Bf4 Bg7 5.e3 O-O 6.Rc1 c5 7.dxc5 Be6 *","weight":50647,"prior_delay":25,"prior_completion_time":0},
{"oid":1981,"gid":343,"fullname":"Grunfeld Defense, Brinckmann Attack, Grunfeld Gambit","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Bf4 Bg7 5.e3 O-O 6.Qb3 *","weight":50490,"prior_delay":25,"prior_completion_time":0},
{"oid":1982,"gid":343,"fullname":"Grunfeld Defense, Brinckmann Attack, Grunfeld Gambit Accepted","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Bf4 Bg7 5.e3 O-O 6.cxd5 Nxd5 7.Nxd5 Qxd5  8.Bxc7 Bf5 *","weight":50661,"prior_delay":25,"prior_completion_time":0},
{"oid":1983,"gid":343,"fullname":"Grunfeld Defense, Brinckmann Attack, Grunfeld Gambit Accepted","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Bf4 Bg7 5.e3 O-O 6.cxd5 Nxd5 7.Nxd5 Qxd5  8.Bxc7 Nc6 9.Ne2 Bg4 *","weight":50667,"prior_delay":25,"prior_completion_time":0},
{"oid":1984,"gid":343,"fullname":"Grunfeld Defense, Brinckmann Attack, Grunfeld Gambit Accepted","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Bf4 Bg7 5.e3 O-O 6.cxd5 Nxd5 7.Nxd5 Qxd5  8.Bxc7 Na6 9.Bxa6 Qxg2 *","weight":50750,"prior_delay":25,"prior_completion_time":0},
{"oid":1985,"gid":343,"fullname":"Grunfeld Defense, Brinckmann Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Bf4 Bg7 5.e3 c5 6.dxc5 Qa5 7.Qa4+ *","weight":50754,"prior_delay":25,"prior_completion_time":0},
{"oid":1986,"gid":343,"fullname":"Grunfeld Defense, Brinckmann Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Bf4 Bg7 5.e3 c5 6.dxc5 Qa5 7.Rc1 *","weight":50848,"prior_delay":25,"prior_completion_time":0},
{"oid":1987,"gid":344,"fullname":"Grunfeld Defense, Counterthrust Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 c5 *","weight":35988,"prior_delay":25,"prior_completion_time":0},
{"oid":1988,"gid":344,"fullname":"Grunfeld Defense, Counterthrust Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.Nc3 *","weight":36024,"prior_delay":25,"prior_completion_time":0},
{"oid":1989,"gid":344,"fullname":"Grunfeld Defense, Counterthrust Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 dxc4 6.Na3 *","weight":36196,"prior_delay":25,"prior_completion_time":0},
{"oid":1990,"gid":344,"fullname":"Grunfeld Defense, Counterthrust Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 c6 6.cxd5 cxd5 7.Nc3 *","weight":36578,"prior_delay":25,"prior_completion_time":0},
{"oid":1991,"gid":345,"fullname":"Grunfeld Defense, Exchange, Larsen Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 Qd7 9.O-O b6 *","weight":61040,"prior_delay":25,"prior_completion_time":0},
{"oid":1992,"gid":345,"fullname":"Grunfeld Defense, Exchange, Simagin's Lesser Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 b6 9.O-O *","weight":61030,"prior_delay":25,"prior_completion_time":0},
{"oid":1993,"gid":345,"fullname":"Grunfeld Defense, Exchange, Simagin's Lesser Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 b6 9.h4 *","weight":61039,"prior_delay":25,"prior_completion_time":0},
{"oid":1994,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Classical Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 b6 *","weight":60836,"prior_delay":25,"prior_completion_time":0},
{"oid":1995,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Classical Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 c5 *","weight":61206,"prior_delay":25,"prior_completion_time":0},
{"oid":1996,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Rb1 O-O 9.Be2 b6 *","weight":62927,"prior_delay":25,"prior_completion_time":0},
{"oid":1997,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 O-O *","weight":60916,"prior_delay":25,"prior_completion_time":0},
{"oid":1998,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Be2 O-O 9.O-O Nc6 *","weight":61656,"prior_delay":25,"prior_completion_time":0},
{"oid":1999,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Be2 O-O 9.O-O b6 *","weight":61663,"prior_delay":25,"prior_completion_time":0},
{"oid":2000,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Be3 Nc6 *","weight":62021,"prior_delay":25,"prior_completion_time":0},
{"oid":2001,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Rb1 O-O 9.Be2 Qa5 *","weight":62850,"prior_delay":25,"prior_completion_time":0},
{"oid":2002,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Be3 Bg4 *","weight":62044,"prior_delay":25,"prior_completion_time":0},
{"oid":2003,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Rb1 O-O 9.Be2 Nc6 10.d5 Ne5 11.Nxe5 Bxe5 12.Qd2 e6 *","weight":63303,"prior_delay":25,"prior_completion_time":0},
{"oid":2004,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Be3 O-O 9.Rc1 Qa5 10.Qd2 cxd4 11.cxd4 Qxd2+ 12.Nxd2 *","weight":62174,"prior_delay":25,"prior_completion_time":0},
{"oid":2005,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Be3 Qa5 9.Qd2 Nc6 10.Rc1 *","weight":62688,"prior_delay":25,"prior_completion_time":0},
{"oid":2006,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Bb5+ *","weight":61683,"prior_delay":25,"prior_completion_time":0},
{"oid":2007,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Rb1 O-O 9.Be2 cxd4 10.cxd4 Qa5+ 11.Qd2 *","weight":63370,"prior_delay":25,"prior_completion_time":0},
{"oid":2008,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Be3 O-O 9.Rc1 Qa5 10.Qd2 cxd4 11.cxd4 Qxd2+ 12.Kxd2 *","weight":62152,"prior_delay":25,"prior_completion_time":0},
{"oid":2009,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Modern Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Nf3 c5  8.Rb1 O-O 9.Be2 cxd4 10.cxd4 Qa5+ 11.Bd2 Qxa2 12.O-O Bg4 13.Bg5 *","weight":64073,"prior_delay":25,"prior_completion_time":0},
{"oid":2010,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Nadanian Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.Na4 *","weight":53524,"prior_delay":25,"prior_completion_time":0},
{"oid":2011,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Seville Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 Bg4 11.f3 Na5 12.Bxf7+ *","weight":61799,"prior_delay":25,"prior_completion_time":0},
{"oid":2012,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Simagin's Improved Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 Nc6 9.O-O b6 *","weight":61083,"prior_delay":25,"prior_completion_time":0},
{"oid":2013,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Simagin's Improved Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 Nc6 9.O-O e5 *","weight":61075,"prior_delay":25,"prior_completion_time":0},
{"oid":2014,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Simagin's Improved Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 Nc6 9.Be3 *","weight":61045,"prior_delay":25,"prior_completion_time":0},
{"oid":2015,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Sokolsky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 cxd4 11.cxd4 Bg4 12.f3 Na5 13.Bd3 Be6 14.d5 Bxa1  15.Qxa1 f6 16.Bh6 *","weight":61674,"prior_delay":25,"prior_completion_time":0},
{"oid":2016,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Sokolsky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 cxd4 11.cxd4 Bg4 12.f3 Na5 13.Bd3 Be6 14.d5 Bxa1  15.Qxa1 f6 16.Rb1 *","weight":61657,"prior_delay":25,"prior_completion_time":0},
{"oid":2017,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Spassky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 Na5 *","weight":61522,"prior_delay":25,"prior_completion_time":0},
{"oid":2018,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Spassky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.Be3 Nc6 *","weight":61074,"prior_delay":25,"prior_completion_time":0},
{"oid":2019,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Spassky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 cxd4 11.cxd4 Bg4 12.f3 Na5 13.Bd3 Be6 14.Rc1 Bxa2  15.Qa4 Be6 16.d5 Bd7 17.Qb4 b6 *","weight":61590,"prior_delay":25,"prior_completion_time":0},
{"oid":2020,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Spassky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 cxd4 11.cxd4 Bg4 12.f3 Na5 13.Bd3 Be6 14.Rc1 Bxa2  15.Qa4 Be6 16.d5 Bd7 17.Qb4 e6 *","weight":61593,"prior_delay":25,"prior_completion_time":0},
{"oid":2021,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Spassky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 cxd4 11.cxd4 Bg4 12.f3 Na5 13.Bd5 *","weight":61524,"prior_delay":25,"prior_completion_time":0},
{"oid":2022,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Spassky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 Bg4 11.f3 Na5 12.Bd5 *","weight":61781,"prior_delay":25,"prior_completion_time":0},
{"oid":2023,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Spassky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 cxd4 11.cxd4 Na5 12.Bd3 *","weight":61505,"prior_delay":25,"prior_completion_time":0},
{"oid":2024,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Spassky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 Qc7 11.Rc1 Rd8 12.Qd2 *","weight":61661,"prior_delay":25,"prior_completion_time":0},
{"oid":2025,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Spassky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 Qc7 11.Rc1 Rd8 12.Bf4 *","weight":61668,"prior_delay":25,"prior_completion_time":0},
{"oid":2026,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation, Spassky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bc4 O-O  8.Ne2 c5 9.O-O Nc6 10.Be3 cxd4 11.cxd4 Bg4 12.f3 Na5 13.Bxf7+ *","weight":61524,"prior_delay":25,"prior_completion_time":0},
{"oid":2027,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 c5 *","weight":58805,"prior_delay":25,"prior_completion_time":0},
{"oid":2028,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Ba3 *","weight":60295,"prior_delay":25,"prior_completion_time":0},
{"oid":2029,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Be3 c5  8.Qd2 Qa5 9.Rb1 b6 *","weight":61138,"prior_delay":25,"prior_completion_time":0},
{"oid":2030,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Bb5+ c6  8.Ba4 O-O *","weight":60653,"prior_delay":25,"prior_completion_time":0},
{"oid":2031,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Be3 c5  8.Qd2 Qa5 9.Rc1 *","weight":61116,"prior_delay":25,"prior_completion_time":0},
{"oid":2032,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.g3 *","weight":53548,"prior_delay":25,"prior_completion_time":0},
{"oid":2033,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.e4 Nxc3 6.bxc3 Bg7 7.Be3 c5  8.Qd2 O-O 9.Rc1 *","weight":61032,"prior_delay":25,"prior_completion_time":0},
{"oid":2034,"gid":346,"fullname":"Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.cxd5 Nxd5 5.Bd2 Bg7 6.e4 Nb6 7.Be3 *","weight":54518,"prior_delay":25,"prior_completion_time":0},
{"oid":2035,"gid":347,"fullname":"Grunfeld Defense, Flohr Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qa4+ *","weight":52039,"prior_delay":25,"prior_completion_time":0},
{"oid":2036,"gid":348,"fullname":"Grunfeld Defense, Lutikov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.f3 *","weight":49251,"prior_delay":25,"prior_completion_time":0},
{"oid":2037,"gid":350,"fullname":"Grunfeld Defense, Makogonov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.e3 O-O 6.b4 *","weight":52381,"prior_delay":25,"prior_completion_time":0},
{"oid":2038,"gid":351,"fullname":"Grunfeld Defense, Opocensky Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.e3 O-O 6.Bd2 *","weight":52380,"prior_delay":25,"prior_completion_time":0},
{"oid":2039,"gid":352,"fullname":"Grunfeld Defense, Russian, Keres Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 Bg4  8.Be3 Nfd7 9.Be2 Nb6 10.Qd3 Nc6 11.O-O-O *","weight":55214,"prior_delay":25,"prior_completion_time":0},
{"oid":2040,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Accelerated Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Qb3 dxc4 5.Qxc4 Be6 6.Qb5+ Bd7 *","weight":49577,"prior_delay":25,"prior_completion_time":0},
{"oid":2041,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Accelerated Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Qb3 dxc4 5.Qxc4 Bg7 6.e4 O-O *","weight":49752,"prior_delay":25,"prior_completion_time":0},
{"oid":2042,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Byrne (Simagin) Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 Nc6 8.Be2 *","weight":55109,"prior_delay":25,"prior_completion_time":0},
{"oid":2043,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Hungarian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 a6 8.e5 b5  9.Qb3 Nfd7 10.Be3 *","weight":55524,"prior_delay":25,"prior_completion_time":0},
{"oid":2044,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Hungarian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 a6 8.Be2 b5  9.Qb3 *","weight":55609,"prior_delay":25,"prior_completion_time":0},
{"oid":2045,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Hungarian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 a6 8.Qb3 *","weight":55225,"prior_delay":25,"prior_completion_time":0},
{"oid":2046,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Levenfish Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 b6 *","weight":54982,"prior_delay":25,"prior_completion_time":0},
{"oid":2047,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Prins Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 Na6 8.Be2 c5  9.d5 e6 10.O-O exd5 11.exd5 Bf5 *","weight":55833,"prior_delay":25,"prior_completion_time":0},
{"oid":2048,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Prins Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 Na6 8.Bf4 *","weight":55102,"prior_delay":25,"prior_completion_time":0},
{"oid":2049,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Smyslov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 Bg4  8.Be3 Nfd7 9.Qb3 Nb6 10.Rd1 Nc6 11.d5 Ne5 *","weight":55379,"prior_delay":25,"prior_completion_time":0},
{"oid":2050,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Smyslov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 Bg4  8.Be3 Nfd7 9.Qb3 Nb6 10.Rd1 e6 *","weight":55339,"prior_delay":25,"prior_completion_time":0},
{"oid":2051,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Smyslov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 Bg4  8.Be3 Nfd7 9.Rd1 Nc6 *","weight":55231,"prior_delay":25,"prior_completion_time":0},
{"oid":2052,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Smyslov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 Bg4  8.Be3 Nfd7 9.O-O-O *","weight":55222,"prior_delay":25,"prior_completion_time":0},
{"oid":2053,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Smyslov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 Bg4  8.Be2 Nc6 9.d5 *","weight":55107,"prior_delay":25,"prior_completion_time":0},
{"oid":2054,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Szabo (Boleslavsky)","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 c6 8.Be2 *","weight":55006,"prior_delay":25,"prior_completion_time":0},
{"oid":2055,"gid":353,"fullname":"Grunfeld Defense, Russian Variation, Yugoslav Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 Bg4  8.Be3 Nfd7 9.Qb3 c5 *","weight":55263,"prior_delay":25,"prior_completion_time":0},
{"oid":2056,"gid":353,"fullname":"Grunfeld Defense, Russian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 c6 6.cxd5 Nxd5 *","weight":52666,"prior_delay":25,"prior_completion_time":0},
{"oid":2057,"gid":353,"fullname":"Grunfeld Defense, Russian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 c6 6.cxd5 cxd5 *","weight":52667,"prior_delay":25,"prior_completion_time":0},
{"oid":2058,"gid":353,"fullname":"Grunfeld Defense, Russian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.e4 Nfd7 *","weight":54988,"prior_delay":25,"prior_completion_time":0},
{"oid":2059,"gid":353,"fullname":"Grunfeld Defense, Russian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 O-O 7.Bf4 c6 *","weight":54393,"prior_delay":25,"prior_completion_time":0},
{"oid":2060,"gid":353,"fullname":"Grunfeld Defense, Russian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 dxc4 6.Qxc4 a6 *","weight":53840,"prior_delay":25,"prior_completion_time":0},
{"oid":2061,"gid":353,"fullname":"Grunfeld Defense, Russian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Qb3 c6 6.Bf4 *","weight":52652,"prior_delay":25,"prior_completion_time":0},
{"oid":2062,"gid":354,"fullname":"Grunfeld Defense, Stockholm Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Bg5 Ne4 5.Bh4 Nxc3 6.bxc3 Bg7 *","weight":50705,"prior_delay":25,"prior_completion_time":0},
{"oid":2063,"gid":354,"fullname":"Grunfeld Defense, Stockholm Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Bg5 Ne4 5.Bf4 *","weight":50017,"prior_delay":25,"prior_completion_time":0},
{"oid":2064,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Burille Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.e3 O-O 6.Be2 c5 *","weight":52391,"prior_delay":25,"prior_completion_time":0},
{"oid":2065,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Burille Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.e3 O-O 6.cxd5 Nxd5 7.Bc4 *","weight":52490,"prior_delay":25,"prior_completion_time":0},
{"oid":2066,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Burille Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.e3 c6 6.Bd3 *","weight":52332,"prior_delay":25,"prior_completion_time":0},
{"oid":2067,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Burille Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.e3 c6 6.Be2 O-O 7.O-O *","weight":53396,"prior_delay":25,"prior_completion_time":0},
{"oid":2068,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Hungarian Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bf4 c6 *","weight":52204,"prior_delay":25,"prior_completion_time":0},
{"oid":2069,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Hungarian Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bf4 O-O 6.Rc1 dxc4 *","weight":52652,"prior_delay":25,"prior_completion_time":0},
{"oid":2070,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Hungarian Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bf4 O-O 6.Rc1 c5 *","weight":52577,"prior_delay":25,"prior_completion_time":0},
{"oid":2071,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Hungarian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bf4 O-O 6.e3 c5 7.dxc5 Qa5 8.Rc1 dxc4  9.Bxc4 *","weight":52829,"prior_delay":25,"prior_completion_time":0},
{"oid":2072,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Hungarian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bf4 O-O 6.e3 c6 7.Qb3 *","weight":52475,"prior_delay":25,"prior_completion_time":0},
{"oid":2073,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Hungarian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bf4 O-O 6.e3 c6 7.Rc1 *","weight":52481,"prior_delay":25,"prior_completion_time":0},
{"oid":2074,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Petrosian System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bg5 c6 *","weight":52174,"prior_delay":25,"prior_completion_time":0},
{"oid":2075,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Petrosian System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bg5 Ne4 6.cxd5 Nxg5 7.Nxg5 c6 *","weight":52596,"prior_delay":25,"prior_completion_time":0},
{"oid":2076,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Petrosian System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bg5 Ne4 6.cxd5 Nxg5 7.Nxg5 e6  8.Qd2 exd5 9.Qe3+ Kf8 10.Qf4 Bf6 11.h4 h6 *","weight":52768,"prior_delay":25,"prior_completion_time":0},
{"oid":2077,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Petrosian System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bg5 Ne4 6.Bh4 Nxc3 7.bxc3 dxc4 *","weight":52537,"prior_delay":25,"prior_completion_time":0},
{"oid":2078,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Petrosian System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bg5 Ne4 6.cxd5 Nxg5 7.Nxg5 e6  8.Nf3 exd5 9.e3 O-O 10.Bd3 c6 *","weight":52760,"prior_delay":25,"prior_completion_time":0},
{"oid":2079,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Petrosian System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bg5 Ne4 6.Bf4 *","weight":52422,"prior_delay":25,"prior_completion_time":0},
{"oid":2080,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Petrosian System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bg5 Ne4 6.cxd5 Nxg5 7.Nxg5 e6  8.Nf3 exd5 9.e3 O-O 10.Be2 c6 *","weight":52762,"prior_delay":25,"prior_completion_time":0},
{"oid":2081,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Petrosian System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bg5 Ne4 6.cxd5 Nxg5 7.Nxg5 e6  8.Nf3 exd5 9.e3 O-O 10.b4 c6 *","weight":52781,"prior_delay":25,"prior_completion_time":0},
{"oid":2082,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Petrosian System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.Bg5 dxc4 6.e4 *","weight":52211,"prior_delay":25,"prior_completion_time":0},
{"oid":2083,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Vienna Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.e3 O-O 6.Qb3 dxc4 7.Bxc4 Nc6 *","weight":52365,"prior_delay":25,"prior_completion_time":0},
{"oid":2084,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation, Vienna Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.e3 O-O 6.Qb3 c6 7.Bd2 e6 8.Bd3 *","weight":52392,"prior_delay":25,"prior_completion_time":0},
{"oid":2085,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.g3 *","weight":51965,"prior_delay":25,"prior_completion_time":0},
{"oid":2086,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.cxd5 Nxd5 6.Qb3 *","weight":52734,"prior_delay":25,"prior_completion_time":0},
{"oid":2087,"gid":355,"fullname":"Grunfeld Defense, Three Knights Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 Bg7 5.cxd5 Nxd5 6.Bd2 O-O 7.Rc1 *","weight":52777,"prior_delay":25,"prior_completion_time":0},
{"oid":2088,"gid":356,"fullname":"Grunfeld Defense, Zaitsev Gambit","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.h4 *","weight":49251,"prior_delay":25,"prior_completion_time":0},
{"oid":2089,"gid":349,"fullname":"Grunfeld Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.e3 Bg7 5.Qb3 *","weight":49510,"prior_delay":25,"prior_completion_time":0},
{"oid":2090,"gid":357,"fullname":"Indian Game, Anti-Grunfeld, Adorjan Gambit","main_line":"1.d4 Nf6 2.c4 g6 3.d5 b5 *","weight":34396,"prior_delay":25,"prior_completion_time":0},
{"oid":2091,"gid":358,"fullname":"Indian Game, Anti-Nimzo-Indian","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 Be7 *","weight":54462,"prior_delay":25,"prior_completion_time":0},
{"oid":2092,"gid":359,"fullname":"Indian Game, Dzindzi-Indian Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 a6 4.Nc3 c5 *","weight":54534,"prior_delay":25,"prior_completion_time":0},
{"oid":2093,"gid":360,"fullname":"Indian Game, King's Indian Variation, Fianchetto Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 Bg7 4.Bg2 O-O *","weight":38190,"prior_delay":25,"prior_completion_time":0},
{"oid":2094,"gid":361,"fullname":"Indian Game, Knights Variation","main_line":"1.d4 Nf6 2.Nf3 e6 3.g3 c5 4.Bg2 Nc6 *","weight":26901,"prior_delay":25,"prior_completion_time":0},
{"oid":2095,"gid":361,"fullname":"Indian Game, Knights Variation","main_line":"1.d4 Nf6 2.Nf3 e6 3.g3 c5 4.Bg2 cxd4 *","weight":26928,"prior_delay":25,"prior_completion_time":0},
{"oid":2096,"gid":361,"fullname":"Indian Game, Knights Variation","main_line":"1.d4 Nf6 2.Nf3 e6 3.g3 c5 4.Bg2 Qa5+ *","weight":26880,"prior_delay":25,"prior_completion_time":0},
{"oid":2097,"gid":361,"fullname":"Indian Game, Knights Variation","main_line":"1.d4 Nf6 2.Nf3 e6 3.g3 c5 4.Bg2 Qc7 *","weight":26879,"prior_delay":25,"prior_completion_time":0},
{"oid":2098,"gid":361,"fullname":"Indian Game, Knights Variation","main_line":"1.d4 Nf6 2.Nf3 e6 3.c3 b6 4.Bg5 *","weight":26092,"prior_delay":25,"prior_completion_time":0},
{"oid":2099,"gid":361,"fullname":"Indian Game, Knights Variation","main_line":"1.d4 Nf6 2.Nf3 e6 3.g3 b5 4.Bg2 Bb7 5.O-O *","weight":27208,"prior_delay":25,"prior_completion_time":0},
{"oid":2100,"gid":362,"fullname":"Indian Game, London System","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bf4 c5 4.e3 Qb6 *","weight":26486,"prior_delay":25,"prior_completion_time":0},
{"oid":2101,"gid":362,"fullname":"Indian Game, London System","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bf4 c5 4.c3 *","weight":26376,"prior_delay":25,"prior_completion_time":0},
{"oid":2102,"gid":363,"fullname":"Indian Game, Normal Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 c5 *","weight":49410,"prior_delay":25,"prior_completion_time":0},
{"oid":2103,"gid":363,"fullname":"Indian Game, Normal Variation","main_line":"1.d4 Nf6 2.c4 e6 3.a3 d5 *","weight":38570,"prior_delay":25,"prior_completion_time":0},
{"oid":2104,"gid":364,"fullname":"Indian Game, Przepiorka Variation","main_line":"1.d4 Nf6 2.Nf3 g6 3.g3 Bg7 4.Bg2 O-O 5.O-O d6 *","weight":29090,"prior_delay":25,"prior_completion_time":0},
{"oid":2105,"gid":364,"fullname":"Indian Game, Przepiorka Variation","main_line":"1.d4 Nf6 2.Nf3 g6 3.g3 Bg7 4.Bg2 O-O 5.b3 d6 6.Bb2 *","weight":28344,"prior_delay":25,"prior_completion_time":0},
{"oid":2106,"gid":365,"fullname":"Indian Game, Pseudo-Benko","main_line":"1.d4 c5 2.d5 Nf6 3.Nf3 b5 4.Bg5 Ne4 *","weight":12218,"prior_delay":25,"prior_completion_time":0},
{"oid":2107,"gid":365,"fullname":"Indian Game, Pseudo-Benko","main_line":"1.d4 c5 2.d5 Nf6 3.Nf3 b5 4.Bg5 d6 *","weight":12197,"prior_delay":25,"prior_completion_time":0},
{"oid":2108,"gid":366,"fullname":"Indian Game, Spielmann-Indian","main_line":"1.d4 Nf6 2.Nf3 c5 3.c3 b6 *","weight":23152,"prior_delay":25,"prior_completion_time":0},
{"oid":2109,"gid":366,"fullname":"Indian Game, Spielmann-Indian","main_line":"1.d4 Nf6 2.Nf3 c5 3.c3 g6 *","weight":23153,"prior_delay":25,"prior_completion_time":0},
{"oid":2110,"gid":366,"fullname":"Indian Game, Spielmann-Indian","main_line":"1.d4 Nf6 2.Nf3 c5 3.g3 cxd4 4.Nxd4 *","weight":23119,"prior_delay":25,"prior_completion_time":0},
{"oid":2111,"gid":366,"fullname":"Indian Game, Spielmann-Indian","main_line":"1.d4 Nf6 2.Nf3 c5 3.c3 cxd4 *","weight":23150,"prior_delay":25,"prior_completion_time":0},
{"oid":2112,"gid":366,"fullname":"Indian Game, Spielmann-Indian","main_line":"1.d4 Nf6 2.Nf3 c5 3.e3 cxd4 *","weight":23166,"prior_delay":25,"prior_completion_time":0},
{"oid":2113,"gid":366,"fullname":"Indian Game, Spielmann-Indian","main_line":"1.d4 Nf6 2.Nf3 c5 3.c3 e6 *","weight":23171,"prior_delay":25,"prior_completion_time":0},
{"oid":2114,"gid":367,"fullname":"Indian Game, Tartakower Attack","main_line":"1.d4 Nf6 2.g3 c5 3.d5 b5 *","weight":21096,"prior_delay":25,"prior_completion_time":0},
{"oid":2115,"gid":368,"fullname":"Indian Game, Wade-Tartakower Defense","main_line":"1.d4 Nf6 2.Nf3 d6 3.Bg5 Nbd7 *","weight":22871,"prior_delay":25,"prior_completion_time":0},
{"oid":2116,"gid":369,"fullname":"Kangaroo Defense, Main Line","main_line":"1.d4 e6 2.c4 Bb4+ 3.Bd2 Bxd2+ 4.Qxd2 b6 5.Nc3 Bb7 6.e4 Nh6 *","weight":15660,"prior_delay":25,"prior_completion_time":0},
{"oid":2117,"gid":369,"fullname":"Kangaroo Defense, Main Line","main_line":"1.d4 e6 2.c4 Bb4+ 3.Bd2 a5 *","weight":15622,"prior_delay":25,"prior_completion_time":0},
{"oid":2118,"gid":370,"fullname":"King's Indian, Averbakh, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 e6 8.Qd2 exd5  9.exd5 Re8 10.Nf3 Bg4 *","weight":61646,"prior_delay":25,"prior_completion_time":0},
{"oid":2119,"gid":370,"fullname":"King's Indian, Averbakh, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 e6 8.Nf3 h6  9.Bf4 *","weight":61611,"prior_delay":25,"prior_completion_time":0},
{"oid":2120,"gid":370,"fullname":"King's Indian, Averbakh, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 e6 8.Nf3 h6  9.Bh4 exd5 10.cxd5 *","weight":61622,"prior_delay":25,"prior_completion_time":0},
{"oid":2121,"gid":370,"fullname":"King's Indian, Averbakh, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 e6 8.Nf3 exd5  9.exd5 *","weight":61614,"prior_delay":25,"prior_completion_time":0},
{"oid":2122,"gid":370,"fullname":"King's Indian, Averbakh, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 e6 8.Nf3 h6  9.Bd2 *","weight":61611,"prior_delay":25,"prior_completion_time":0},
{"oid":2123,"gid":370,"fullname":"King's Indian, Averbakh, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 e6 8.Nf3 h6  9.Be3 *","weight":61613,"prior_delay":25,"prior_completion_time":0},
{"oid":2124,"gid":371,"fullname":"King's Indian, Fianchetto, Classical Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 a6 *","weight":51960,"prior_delay":25,"prior_completion_time":0},
{"oid":2125,"gid":371,"fullname":"King's Indian, Fianchetto, Classical Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 exd4  9.Nxd4 Nc5 *","weight":52250,"prior_delay":25,"prior_completion_time":0},
{"oid":2126,"gid":371,"fullname":"King's Indian, Fianchetto, Classical Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 Re8 *","weight":51883,"prior_delay":25,"prior_completion_time":0},
{"oid":2127,"gid":371,"fullname":"King's Indian, Fianchetto, Classical Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.b3 *","weight":52059,"prior_delay":25,"prior_completion_time":0},
{"oid":2128,"gid":371,"fullname":"King's Indian, Fianchetto, Classical Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.Rb1 *","weight":52018,"prior_delay":25,"prior_completion_time":0},
{"oid":2129,"gid":372,"fullname":"King's Indian, Orthodox","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Re1 c6  9.Bf1 exd4 10.Nxd4 Ng4 *","weight":74125,"prior_delay":25,"prior_completion_time":0},
{"oid":2130,"gid":372,"fullname":"King's Indian, Orthodox","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Re1 h6 *","weight":73978,"prior_delay":25,"prior_completion_time":0},
{"oid":2131,"gid":372,"fullname":"King's Indian, Orthodox","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Re1 c6  9.Bf1 Re8 *","weight":74089,"prior_delay":25,"prior_completion_time":0},
{"oid":2132,"gid":372,"fullname":"King's Indian, Orthodox","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Re1 a5 *","weight":73975,"prior_delay":25,"prior_completion_time":0},
{"oid":2133,"gid":372,"fullname":"King's Indian, Orthodox","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Re1 c6  9.Bf1 exd4 10.Nxd4 Re8 *","weight":74133,"prior_delay":25,"prior_completion_time":0},
{"oid":2134,"gid":372,"fullname":"King's Indian, Orthodox","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Re1 exd4  9.Nxd4 Nc5 *","weight":73984,"prior_delay":25,"prior_completion_time":0},
{"oid":2135,"gid":372,"fullname":"King's Indian, Orthodox","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Re1 Re8  9.Bf1 *","weight":74016,"prior_delay":25,"prior_completion_time":0},
{"oid":2136,"gid":372,"fullname":"King's Indian, Orthodox","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Re1 c6  9.Rb1 *","weight":74019,"prior_delay":25,"prior_completion_time":0},
{"oid":2137,"gid":373,"fullname":"King's Indian, Samisch, Panno Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nc6 7.Nge2 a6 8.Qd2 Rb8  9.h4 b5 *","weight":62458,"prior_delay":25,"prior_completion_time":0},
{"oid":2138,"gid":373,"fullname":"King's Indian, Samisch, Panno Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nc6 7.Nge2 a6 8.Qd2 Rb8  9.Nc1 e5 10.Nb3 *","weight":62462,"prior_delay":25,"prior_completion_time":0},
{"oid":2139,"gid":373,"fullname":"King's Indian, Samisch, Panno Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nc6 7.Nge2 a6 8.Qd2 Rb8  9.h4 h5 10.O-O-O b5 11.Bh6 *","weight":62479,"prior_delay":25,"prior_completion_time":0},
{"oid":2140,"gid":373,"fullname":"King's Indian, Samisch, Panno Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nc6 7.Nge2 a6 8.Qd2 Rb8  9.Rb1 *","weight":62430,"prior_delay":25,"prior_completion_time":0},
{"oid":2141,"gid":373,"fullname":"King's Indian, Samisch, Panno Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nc6 7.Nge2 a6 8.Qd2 Rb8  9.a3 *","weight":62416,"prior_delay":25,"prior_completion_time":0},
{"oid":2142,"gid":373,"fullname":"King's Indian, Samisch, Panno Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nc6 7.Nge2 a6 8.Qd2 Rb8  9.Nc1 e5 10.d5 Nd4 11.Nb3 *","weight":62492,"prior_delay":25,"prior_completion_time":0},
{"oid":2143,"gid":374,"fullname":"King's Indian Defense, Accelerated Averbakh Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Bg5 h6 *","weight":59292,"prior_delay":25,"prior_completion_time":0},
{"oid":2144,"gid":374,"fullname":"King's Indian Defense, Accelerated Averbakh Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Bg5 O-O 6.Qd2 *","weight":59355,"prior_delay":25,"prior_completion_time":0},
{"oid":2145,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Benoni Defense, Advance Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 a6 8.a4 Qa5 *","weight":61610,"prior_delay":25,"prior_completion_time":0},
{"oid":2146,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Benoni Defense, Advance Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 Qa5 *","weight":61585,"prior_delay":25,"prior_completion_time":0},
{"oid":2147,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Benoni Defense, Advance Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 h6 8.Bf4 e6 *","weight":61639,"prior_delay":25,"prior_completion_time":0},
{"oid":2148,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Benoni Defense, Advance Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 h6 8.Be3 e6  9.Qd2 *","weight":61647,"prior_delay":25,"prior_completion_time":0},
{"oid":2149,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Benoni Defense, Advance Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 a6 8.Qd2 *","weight":61596,"prior_delay":25,"prior_completion_time":0},
{"oid":2150,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Benoni Defense, Advance Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.d5 b5 8.cxb5 a6  9.a4 *","weight":61609,"prior_delay":25,"prior_completion_time":0},
{"oid":2151,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Benoni Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c5 7.dxc5 Qa5 8.Bd2 Qxc5  9.Nf3 Bg4 *","weight":61531,"prior_delay":25,"prior_completion_time":0},
{"oid":2152,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Flexible Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 h6 7.Be3 e5 8.d5 c6 *","weight":61593,"prior_delay":25,"prior_completion_time":0},
{"oid":2153,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Flexible Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 h6 7.Be3 e5 8.d5 Nbd7 *","weight":61591,"prior_delay":25,"prior_completion_time":0},
{"oid":2154,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Flexible Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 h6 7.Be3 c5 *","weight":61586,"prior_delay":25,"prior_completion_time":0},
{"oid":2155,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Geller Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 Nbd7 7.Qd2 e5 8.d5 Nc5 *","weight":61524,"prior_delay":25,"prior_completion_time":0},
{"oid":2156,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Modern Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 Na6 7.Qd2 e5 8.d5 c6 *","weight":61759,"prior_delay":25,"prior_completion_time":0},
{"oid":2157,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Modern Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 Na6 7.Qd2 e5 8.d5 Qe8 *","weight":61740,"prior_delay":25,"prior_completion_time":0},
{"oid":2158,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation, Modern Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 Na6 7.h4 *","weight":61612,"prior_delay":25,"prior_completion_time":0},
{"oid":2159,"gid":375,"fullname":"King's Indian Defense, Averbakh Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Bg5 c6 7.Qd2 Nbd7 *","weight":61474,"prior_delay":25,"prior_completion_time":0},
{"oid":2160,"gid":376,"fullname":"King's Indian Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.dxe5 dxe5  8.Qxd8 Rxd8 9.Bg5 c6 *","weight":71824,"prior_delay":25,"prior_completion_time":0},
{"oid":2161,"gid":376,"fullname":"King's Indian Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.dxe5 dxe5  8.Qxd8 Rxd8 9.Bg5 Re8 10.Nd5 *","weight":71924,"prior_delay":25,"prior_completion_time":0},
{"oid":2162,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Fianchetto","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.h3 c6 *","weight":51610,"prior_delay":25,"prior_completion_time":0},
{"oid":2163,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Fianchetto","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.h3 Re8 *","weight":51578,"prior_delay":25,"prior_completion_time":0},
{"oid":2164,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Fianchetto","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.Qc2 c6 *","weight":51424,"prior_delay":25,"prior_completion_time":0},
{"oid":2165,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Fianchetto","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.d5 *","weight":51381,"prior_delay":25,"prior_completion_time":0},
{"oid":2166,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Fianchetto","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.dxe5 *","weight":51382,"prior_delay":25,"prior_completion_time":0},
{"oid":2167,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 Re8 10.Re1 exd4 11.Nxd4 a5 *","weight":52152,"prior_delay":25,"prior_completion_time":0},
{"oid":2168,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 a6 *","weight":52118,"prior_delay":25,"prior_completion_time":0},
{"oid":2169,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 exd4 10.Nxd4 Re8 *","weight":52164,"prior_delay":25,"prior_completion_time":0},
{"oid":2170,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 a5 *","weight":52115,"prior_delay":25,"prior_completion_time":0},
{"oid":2171,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 Qb6 10.Re1 Re8 *","weight":52211,"prior_delay":25,"prior_completion_time":0},
{"oid":2172,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 Qb6 10.Re1 exd4 11.Nxd4 Re8 *","weight":52277,"prior_delay":25,"prior_completion_time":0},
{"oid":2173,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 Qb6 10.d5 *","weight":52192,"prior_delay":25,"prior_completion_time":0},
{"oid":2174,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 Re8 10.Re1 a5 11.Be3 *","weight":52138,"prior_delay":25,"prior_completion_time":0},
{"oid":2175,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 Qa5 10.Re1 exd4 11.Nxd4 *","weight":52338,"prior_delay":25,"prior_completion_time":0},
{"oid":2176,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 Re8 10.b3 *","weight":52125,"prior_delay":25,"prior_completion_time":0},
{"oid":2177,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 Re8 10.Re1 exd4 11.Nxd4 Nc5 12.Rb1 *","weight":52156,"prior_delay":25,"prior_completion_time":0},
{"oid":2178,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 Re8 10.Re1 exd4 11.Nxd4 Nc5 12.b3 *","weight":52156,"prior_delay":25,"prior_completion_time":0},
{"oid":2179,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 Qb6 10.c5 *","weight":52212,"prior_delay":25,"prior_completion_time":0},
{"oid":2180,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Classical Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 c6  9.h3 Re8 10.Be3 exd4 11.Nxd4 *","weight":52149,"prior_delay":25,"prior_completion_time":0},
{"oid":2181,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Debrecen Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O c6 *","weight":50848,"prior_delay":25,"prior_completion_time":0},
{"oid":2182,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Delayed Fianchetto","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c6 7.O-O a6 *","weight":50608,"prior_delay":25,"prior_completion_time":0},
{"oid":2183,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Double Fianchetto Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.b3 *","weight":51413,"prior_delay":25,"prior_completion_time":0},
{"oid":2184,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Karlsbad Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.O-O Rb8 *","weight":51289,"prior_delay":25,"prior_completion_time":0},
{"oid":2185,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Karlsbad Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.d5 *","weight":50664,"prior_delay":25,"prior_completion_time":0},
{"oid":2186,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Kavalek Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c6 7.O-O Qa5 8.e4 Bg4 *","weight":50907,"prior_delay":25,"prior_completion_time":0},
{"oid":2187,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Kavalek Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c6 7.O-O Qa5 8.h3 *","weight":50795,"prior_delay":25,"prior_completion_time":0},
{"oid":2188,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Larsen Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c6 7.O-O Bf5 8.b3 *","weight":50729,"prior_delay":25,"prior_completion_time":0},
{"oid":2189,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Lesser Simagin (Spassky)","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.O-O Bf5 8.d5 *","weight":51331,"prior_delay":25,"prior_completion_time":0},
{"oid":2190,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Long Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nbd7 7.O-O e5 8.e4 exd4  9.Nxd4 Re8 10.h3 Nc5 11.Re1 a5 *","weight":52705,"prior_delay":25,"prior_completion_time":0},
{"oid":2191,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Panno Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.O-O a6 8.d5 Na5 *","weight":51831,"prior_delay":25,"prior_completion_time":0},
{"oid":2192,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Panno Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.O-O a6 8.b3 Rb8  9.Bb2 *","weight":51839,"prior_delay":25,"prior_completion_time":0},
{"oid":2193,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Panno Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.O-O a6 8.Re1 *","weight":51642,"prior_delay":25,"prior_completion_time":0},
{"oid":2194,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Panno Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.O-O a6 8.h3 Rb8  9.Be3 *","weight":51773,"prior_delay":25,"prior_completion_time":0},
{"oid":2195,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Panno Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.O-O a6 8.e4 *","weight":51633,"prior_delay":25,"prior_completion_time":0},
{"oid":2196,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Panno Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.O-O a6 8.b3 Rb8  9.Nd5 *","weight":51910,"prior_delay":25,"prior_completion_time":0},
{"oid":2197,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Panno Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.O-O a6 8.h3 Rb8  9.e4 *","weight":51826,"prior_delay":25,"prior_completion_time":0},
{"oid":2198,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Simagin Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.O-O Bg4 8.d5 *","weight":51216,"prior_delay":25,"prior_completion_time":0},
{"oid":2199,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Uhlmann-Szabo System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 Nc6 7.O-O e5 8.d5 Ne7  9.e4 *","weight":51373,"prior_delay":25,"prior_completion_time":0},
{"oid":2200,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav System","main_line":"1.d4 Nf6 2.c4 g6 3.Nf3 Bg7 4.g3 O-O 5.Bg2 d6 6.O-O c5 7.dxc5 *","weight":44523,"prior_delay":25,"prior_completion_time":0},
{"oid":2201,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav Variation, Advance Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c5 7.O-O Nc6 8.d5 Na5  9.Nd2 a6 10.Qc2 Rb8 11.b3 b5 12.Bb2 bxc4 *","weight":50812,"prior_delay":25,"prior_completion_time":0},
{"oid":2202,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav Variation, Advance Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c5 7.O-O Nc6 8.d5 Na5  9.Nd2 e5 *","weight":50604,"prior_delay":25,"prior_completion_time":0},
{"oid":2203,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav Variation, Advance Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c5 7.O-O Nc6 8.d5 Na5  9.Nd2 a6 10.Qc2 Rb8 11.b3 b5 12.Bb2 e5 *","weight":50817,"prior_delay":25,"prior_completion_time":0},
{"oid":2204,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav Variation, Advance Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c5 7.O-O Nc6 8.d5 Na5  9.Nd2 a6 10.Rb1 Rb8 *","weight":50628,"prior_delay":25,"prior_completion_time":0},
{"oid":2205,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav Variation, Exchange Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c5 7.O-O Nc6 8.dxc5 dxc5  9.Bf4 *","weight":50612,"prior_delay":25,"prior_completion_time":0},
{"oid":2206,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav Variation, Exchange Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c5 7.O-O Nc6 8.dxc5 dxc5  9.Be3 *","weight":50639,"prior_delay":25,"prior_completion_time":0},
{"oid":2207,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav Variation, Rare Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c5 7.d5 Na6 *","weight":50366,"prior_delay":25,"prior_completion_time":0},
{"oid":2208,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav Variation, Rare Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c5 7.d5 e6 *","weight":50444,"prior_delay":25,"prior_completion_time":0},
{"oid":2209,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav Variation, Rare Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c5 7.dxc5 *","weight":50348,"prior_delay":25,"prior_completion_time":0},
{"oid":2210,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c5 7.O-O Nbd7 *","weight":50383,"prior_delay":25,"prior_completion_time":0},
{"oid":2211,"gid":377,"fullname":"King's Indian Defense, Fianchetto Variation, Yugoslav Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.g3 O-O 6.Bg2 c5 7.O-O cxd4 *","weight":50389,"prior_delay":25,"prior_completion_time":0},
{"oid":2212,"gid":378,"fullname":"King's Indian Defense, Four Pawns Attack, Dynamic Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f4 O-O 6.Nf3 c5 7.d5 a6 *","weight":59725,"prior_delay":25,"prior_completion_time":0},
{"oid":2213,"gid":378,"fullname":"King's Indian Defense, Four Pawns Attack, Dynamic Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f4 O-O 6.Nf3 c5 7.d5 e6 8.dxe6 *","weight":59800,"prior_delay":25,"prior_completion_time":0},
{"oid":2214,"gid":378,"fullname":"King's Indian Defense, Four Pawns Attack, Dynamic Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f4 O-O 6.Nf3 c5 7.d5 b5 8.cxb5 a6  9.a4 *","weight":59748,"prior_delay":25,"prior_completion_time":0},
{"oid":2215,"gid":378,"fullname":"King's Indian Defense, Four Pawns Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f4 O-O 6.Nf3 Bg4 *","weight":59610,"prior_delay":25,"prior_completion_time":0},
{"oid":2216,"gid":378,"fullname":"King's Indian Defense, Four Pawns Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f4 O-O 6.Nf3 Na6 7.Bd3 e5 *","weight":59673,"prior_delay":25,"prior_completion_time":0},
{"oid":2217,"gid":378,"fullname":"King's Indian Defense, Four Pawns Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f4 O-O 6.Nf3 c5 7.dxc5 Qa5 8.Bd3 Qxc5  9.Qe2 Nc6 *","weight":59719,"prior_delay":25,"prior_completion_time":0},
{"oid":2218,"gid":378,"fullname":"King's Indian Defense, Four Pawns Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f4 O-O 6.Nf3 Na6 7.e5 *","weight":59639,"prior_delay":25,"prior_completion_time":0},
{"oid":2219,"gid":378,"fullname":"King's Indian Defense, Four Pawns Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f4 c5 6.dxc5 *","weight":59394,"prior_delay":25,"prior_completion_time":0},
{"oid":2220,"gid":378,"fullname":"King's Indian Defense, Four Pawns Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f4 c5 6.d5 *","weight":59418,"prior_delay":25,"prior_completion_time":0},
{"oid":2221,"gid":378,"fullname":"King's Indian Defense, Four Pawns Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f4 O-O 6.Nf3 Na6 7.Be2 *","weight":59641,"prior_delay":25,"prior_completion_time":0},
{"oid":2222,"gid":379,"fullname":"King's Indian Defense, Kazakh Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 Na6 7.O-O c5 *","weight":68843,"prior_delay":25,"prior_completion_time":0},
{"oid":2223,"gid":379,"fullname":"King's Indian Defense, Kazakh Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 Na6 7.O-O c6 *","weight":68852,"prior_delay":25,"prior_completion_time":0},
{"oid":2224,"gid":379,"fullname":"King's Indian Defense, Kazakh Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 Na6 7.Bg5 *","weight":68649,"prior_delay":25,"prior_completion_time":0},
{"oid":2225,"gid":380,"fullname":"King's Indian Defense, Kramer Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nge2 O-O 6.Ng3 e5 7.d5 *","weight":59468,"prior_delay":25,"prior_completion_time":0},
{"oid":2226,"gid":381,"fullname":"King's Indian Defense, Larsen Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be3 c5 *","weight":64658,"prior_delay":25,"prior_completion_time":0},
{"oid":2227,"gid":381,"fullname":"King's Indian Defense, Larsen Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be3 e5 7.dxe5 dxe5 8.Qxd8+ *","weight":64708,"prior_delay":25,"prior_completion_time":0},
{"oid":2228,"gid":383,"fullname":"King's Indian Defense, Makogonov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.h3 O-O 6.Bg5 c5 7.d5 b5 *","weight":60018,"prior_delay":25,"prior_completion_time":0},
{"oid":2229,"gid":383,"fullname":"King's Indian Defense, Makogonov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.h3 c5 *","weight":59555,"prior_delay":25,"prior_completion_time":0},
{"oid":2230,"gid":383,"fullname":"King's Indian Defense, Makogonov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.h3 O-O 6.Bg5 h6 *","weight":59951,"prior_delay":25,"prior_completion_time":0},
{"oid":2231,"gid":383,"fullname":"King's Indian Defense, Makogonov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.h3 O-O 6.Bg5 c6 *","weight":59962,"prior_delay":25,"prior_completion_time":0},
{"oid":2232,"gid":383,"fullname":"King's Indian Defense, Makogonov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.h3 O-O 6.Bg5 Na6 7.Bd3 e5 *","weight":60028,"prior_delay":25,"prior_completion_time":0},
{"oid":2233,"gid":383,"fullname":"King's Indian Defense, Makogonov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.h3 Nbd7 *","weight":59562,"prior_delay":25,"prior_completion_time":0},
{"oid":2234,"gid":383,"fullname":"King's Indian Defense, Makogonov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.h3 O-O 6.Bg5 Nbd7 *","weight":59967,"prior_delay":25,"prior_completion_time":0},
{"oid":2235,"gid":383,"fullname":"King's Indian Defense, Makogonov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.h3 O-O 6.Be3 e5 *","weight":59933,"prior_delay":25,"prior_completion_time":0},
{"oid":2236,"gid":383,"fullname":"King's Indian Defense, Makogonov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.h3 O-O 6.Bg5 c5 7.d5 e6 *","weight":60038,"prior_delay":25,"prior_completion_time":0},
{"oid":2237,"gid":384,"fullname":"King's Indian Defense, Normal Variation, King's Knight Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nf3 Bg7 4.g3 O-O 5.Bg2 d6 6.O-O c6 *","weight":44629,"prior_delay":25,"prior_completion_time":0},
{"oid":2238,"gid":384,"fullname":"King's Indian Defense, Normal Variation, King's Knight Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nf3 Bg7 4.g3 O-O 5.Bg2 d6 6.O-O Nbd7 7.Qc2 e5 8.Rd1 Re8 *","weight":45009,"prior_delay":25,"prior_completion_time":0},
{"oid":2239,"gid":384,"fullname":"King's Indian Defense, Normal Variation, King's Knight Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nf3 Bg7 4.g3 O-O 5.Bg2 d6 6.O-O Nc6 *","weight":44963,"prior_delay":25,"prior_completion_time":0},
{"oid":2240,"gid":384,"fullname":"King's Indian Defense, Normal Variation, King's Knight Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nf3 d6 *","weight":35899,"prior_delay":25,"prior_completion_time":0},
{"oid":2241,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.h3 c5 7.d5 e6 8.Bd3 Na6  9.O-O Nc7 *","weight":65569,"prior_delay":25,"prior_completion_time":0},
{"oid":2242,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 c5 6.d5 O-O *","weight":61912,"prior_delay":25,"prior_completion_time":0},
{"oid":2243,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.h3 c5 7.d5 e6 8.Bd3 exd5  9.exd5 Re8 *","weight":65615,"prior_delay":25,"prior_completion_time":0},
{"oid":2244,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 Bg4 *","weight":61837,"prior_delay":25,"prior_completion_time":0},
{"oid":2245,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 Nbd7 6.Be2 e5 *","weight":61835,"prior_delay":25,"prior_completion_time":0},
{"oid":2246,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.h3 e5 7.d5 a5 *","weight":65998,"prior_delay":25,"prior_completion_time":0},
{"oid":2247,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.h3 e5 7.dxe5 dxe5 8.Qxd8+ *","weight":65586,"prior_delay":25,"prior_completion_time":0},
{"oid":2248,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.h3 Nbd7 7.Bg5 *","weight":65230,"prior_delay":25,"prior_completion_time":0},
{"oid":2249,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.h3 e5 7.d5 Na6 8.Be3 *","weight":65947,"prior_delay":25,"prior_completion_time":0},
{"oid":2250,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.h3 e5 7.d5 Na6 8.Bg5 *","weight":65967,"prior_delay":25,"prior_completion_time":0},
{"oid":2251,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.h3 c5 7.Be3 *","weight":65234,"prior_delay":25,"prior_completion_time":0},
{"oid":2252,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.h3 e5 7.d5 Nbd7 8.Bg5 *","weight":65907,"prior_delay":25,"prior_completion_time":0},
{"oid":2253,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.h3 Na6 7.Bg5 *","weight":65379,"prior_delay":25,"prior_completion_time":0},
{"oid":2254,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Rare Defenses","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.h3 e5 7.d5 Nbd7 8.Be3 *","weight":65903,"prior_delay":25,"prior_completion_time":0},
{"oid":2255,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Standard Development","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 Nbd7 *","weight":60258,"prior_delay":25,"prior_completion_time":0},
{"oid":2256,"gid":384,"fullname":"King's Indian Defense, Normal Variation, Standard Development","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.g4 *","weight":61034,"prior_delay":25,"prior_completion_time":0},
{"oid":2257,"gid":384,"fullname":"King's Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Bd3 e5 *","weight":59455,"prior_delay":25,"prior_completion_time":0},
{"oid":2258,"gid":384,"fullname":"King's Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Bd3 O-O 6.Nge2 Nc6 7.O-O e5 *","weight":60084,"prior_delay":25,"prior_completion_time":0},
{"oid":2259,"gid":384,"fullname":"King's Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Bd3 O-O 6.Nge2 e5 7.d5 *","weight":59891,"prior_delay":25,"prior_completion_time":0},
{"oid":2260,"gid":384,"fullname":"King's Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 O-O 5.Nf3 *","weight":55842,"prior_delay":25,"prior_completion_time":0},
{"oid":2261,"gid":384,"fullname":"King's Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Bd3 O-O 6.Nge2 c5 7.d5 e6 8.O-O *","weight":60046,"prior_delay":25,"prior_completion_time":0},
{"oid":2262,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Aronin-Taimanov Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.Be3 Ng4  9.Bg5 f6 10.Bc1 *","weight":75213,"prior_delay":25,"prior_completion_time":0},
{"oid":2263,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Aronin-Taimanov Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Bg5 *","weight":77454,"prior_delay":25,"prior_completion_time":0},
{"oid":2264,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Aronin-Taimanov Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.dxe5 *","weight":74969,"prior_delay":25,"prior_completion_time":0},
{"oid":2265,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Bayonet Attack, Sokolov's Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.b4 Nh5 10.Re1 a5 *","weight":78758,"prior_delay":25,"prior_completion_time":0},
{"oid":2266,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Bayonet Attack, Sokolov's Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.b4 Nh5 10.Re1 f5 11.Ng5 Nf6 12.Bf3 c6 *","weight":79357,"prior_delay":25,"prior_completion_time":0},
{"oid":2267,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Bayonet Attack, Sokolov's Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.b4 Nh5 10.Re1 Nf4 11.Bf1 a5 12.bxa5 *","weight":78796,"prior_delay":25,"prior_completion_time":0},
{"oid":2268,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Bayonet Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.b4 a5 10.Ba3 axb4 11.Bxb4 Nd7 *","weight":78417,"prior_delay":25,"prior_completion_time":0},
{"oid":2269,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Bayonet Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.b4 Kh8 *","weight":78075,"prior_delay":25,"prior_completion_time":0},
{"oid":2270,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Bayonet Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.b4 Nh5 10.g3 *","weight":78443,"prior_delay":25,"prior_completion_time":0},
{"oid":2271,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Bayonet Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.b4 Nh5 10.c5 *","weight":78402,"prior_delay":25,"prior_completion_time":0},
{"oid":2272,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Bayonet Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.b4 a5 10.bxa5 *","weight":78224,"prior_delay":25,"prior_completion_time":0},
{"oid":2273,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Bayonet Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.b4 Ne8 10.c5 *","weight":78111,"prior_delay":25,"prior_completion_time":0},
{"oid":2274,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Classical System, Benko Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Ne1 Nd7 10.f3 f5 11.g4 Kh8 *","weight":78127,"prior_delay":25,"prior_completion_time":0},
{"oid":2275,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Classical System, Benko Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Ne1 Nd7 10.f3 f5 11.g4 Nf6 12.Nd3 *","weight":78137,"prior_delay":25,"prior_completion_time":0},
{"oid":2276,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Classical System, Neo-Classical Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Ne1 Nd7 10.Be3 f5 *","weight":78199,"prior_delay":25,"prior_completion_time":0},
{"oid":2277,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Classical System, Traditional Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Ne1 Nd7 10.f3 f5 11.Be3 f4 12.Bf2 g5 13.a4 Ng6 *","weight":78418,"prior_delay":25,"prior_completion_time":0},
{"oid":2278,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Classical System, Traditional Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Ne1 Nd7 10.f3 f5 11.Nd3 f4 *","weight":78095,"prior_delay":25,"prior_completion_time":0},
{"oid":2279,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Classical System, Traditional Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Ne1 Nd7 10.f3 f5 11.Nd3 Nf6 12.Bd2 f4 13.c5 *","weight":78155,"prior_delay":25,"prior_completion_time":0},
{"oid":2280,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Classical System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Ne1 c5 *","weight":77780,"prior_delay":25,"prior_completion_time":0},
{"oid":2281,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Classical System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Ne1 Ne8 10.Be3 f5 11.f3 f4 *","weight":77961,"prior_delay":25,"prior_completion_time":0},
{"oid":2282,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Classical System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Ne1 Nd7 10.Nd3 f5 11.Bd2 Kh8 *","weight":78313,"prior_delay":25,"prior_completion_time":0},
{"oid":2283,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Classical System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Ne1 Ne8 10.Nd3 f5 *","weight":77863,"prior_delay":25,"prior_completion_time":0},
{"oid":2284,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Classical System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Ne1 Nd7 10.Nd3 f5 11.Bd2 Nf6 *","weight":78339,"prior_delay":25,"prior_completion_time":0},
{"oid":2285,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Donner Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O c6 8.Be3 *","weight":73892,"prior_delay":25,"prior_completion_time":0},
{"oid":2286,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Donner Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O c6 8.d5 *","weight":73924,"prior_delay":25,"prior_completion_time":0},
{"oid":2287,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Glek Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Na6 8.Be3 Ng4  9.Bg5 Qe8 10.dxe5 dxe5 11.h3 h6 *","weight":75425,"prior_delay":25,"prior_completion_time":0},
{"oid":2288,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Glek Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Na6 8.Re1 c6  9.Bf1 *","weight":74521,"prior_delay":25,"prior_completion_time":0},
{"oid":2289,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Gligoric-Taimanov System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.Be3 Ng4 8.Bg5 f6  9.Bh4 Nc6 *","weight":73035,"prior_delay":25,"prior_completion_time":0},
{"oid":2290,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Gligoric-Taimanov System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.Be3 Qe7 *","weight":71963,"prior_delay":25,"prior_completion_time":0},
{"oid":2291,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Gligoric-Taimanov System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.Be3 c6 *","weight":72056,"prior_delay":25,"prior_completion_time":0},
{"oid":2292,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Gligoric-Taimanov System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.Be3 exd4 *","weight":72046,"prior_delay":25,"prior_completion_time":0},
{"oid":2293,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Gligoric-Taimanov System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.Be3 Ng4 8.Bg5 f6  9.Bc1 *","weight":72793,"prior_delay":25,"prior_completion_time":0},
{"oid":2294,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Korchnoi Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Bd2 *","weight":77452,"prior_delay":25,"prior_completion_time":0},
{"oid":2295,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Modern System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Nd2 a5 10.a3 Bd7 *","weight":78096,"prior_delay":25,"prior_completion_time":0},
{"oid":2296,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Modern System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Nd2 c5 *","weight":77747,"prior_delay":25,"prior_completion_time":0},
{"oid":2297,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Modern System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Nd2 a5 10.a3 Nd7 11.Rb1 f5 12.b4 *","weight":78365,"prior_delay":25,"prior_completion_time":0},
{"oid":2298,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Modern System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Nd2 a5 10.Rb1 *","weight":77922,"prior_delay":25,"prior_completion_time":0},
{"oid":2299,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Modern System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Nd2 Nd7 10.b4 *","weight":77739,"prior_delay":25,"prior_completion_time":0},
{"oid":2300,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Modern System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nc6 8.d5 Ne7  9.Nd2 Ne8 10.b4 *","weight":77761,"prior_delay":25,"prior_completion_time":0},
{"oid":2301,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Positional Defense, Closed Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.d5 Nc5  9.Qc2 a5 *","weight":73933,"prior_delay":25,"prior_completion_time":0},
{"oid":2302,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Positional Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Re1 c6  9.Bf1 a5 10.dxe5 dxe5 *","weight":74111,"prior_delay":25,"prior_completion_time":0},
{"oid":2303,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Positional Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Re1 c6  9.Bf1 a5 10.Rb1 Re8 11.d5 Nc5 12.b3 *","weight":74135,"prior_delay":25,"prior_completion_time":0},
{"oid":2304,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Positional Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Re1 c6  9.Bf1 a5 10.h3 *","weight":74097,"prior_delay":25,"prior_completion_time":0},
{"oid":2305,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Positional Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Be3 c6  9.d5 c5 *","weight":74156,"prior_delay":25,"prior_completion_time":0},
{"oid":2306,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Positional Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Qc2 Re8 *","weight":73952,"prior_delay":25,"prior_completion_time":0},
{"oid":2307,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Positional Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Be3 Ng4 *","weight":74058,"prior_delay":25,"prior_completion_time":0},
{"oid":2308,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Positional Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Qc2 c6 *","weight":73974,"prior_delay":25,"prior_completion_time":0},
{"oid":2309,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation, Positional Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O Nbd7 8.Be3 Re8 *","weight":74090,"prior_delay":25,"prior_completion_time":0},
{"oid":2310,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 c5 7.O-O Nc6 *","weight":68853,"prior_delay":25,"prior_completion_time":0},
{"oid":2311,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 a5 *","weight":68459,"prior_delay":25,"prior_completion_time":0},
{"oid":2312,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 a6 *","weight":68458,"prior_delay":25,"prior_completion_time":0},
{"oid":2313,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 Nbd7 7.O-O c6 *","weight":68681,"prior_delay":25,"prior_completion_time":0},
{"oid":2314,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 Nbd7 7.e5 *","weight":68565,"prior_delay":25,"prior_completion_time":0},
{"oid":2315,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 c5 7.d5 e6 8.O-O Re8  9.Nd2 *","weight":68953,"prior_delay":25,"prior_completion_time":0},
{"oid":2316,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 Bg4 7.Be3 Nfd7 8.Ng1 *","weight":68850,"prior_delay":25,"prior_completion_time":0},
{"oid":2317,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 Nbd7 7.Bg5 *","weight":68565,"prior_delay":25,"prior_completion_time":0},
{"oid":2318,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O exd4 8.Nxd4 Re8  9.f3 c6 10.Kh1 *","weight":74599,"prior_delay":25,"prior_completion_time":0},
{"oid":2319,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 Bg4 7.Be3 Nfd7 8.Rc1 *","weight":68888,"prior_delay":25,"prior_completion_time":0},
{"oid":2320,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.O-O exd4 8.Nxd4 Re8  9.f3 Nc6 10.Be3 Nh5 11.Qd2 *","weight":74739,"prior_delay":25,"prior_completion_time":0},
{"oid":2321,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 c6 7.O-O *","weight":68538,"prior_delay":25,"prior_completion_time":0},
{"oid":2322,"gid":385,"fullname":"King's Indian Defense, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 Nc6 7.d5 *","weight":68469,"prior_delay":25,"prior_completion_time":0},
{"oid":2323,"gid":386,"fullname":"King's Indian Defense, Petrosian Variation, Normal Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.d5 Nbd7 8.Be3 Ng4 *","weight":71518,"prior_delay":25,"prior_completion_time":0},
{"oid":2324,"gid":386,"fullname":"King's Indian Defense, Petrosian Variation, Stein Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.d5 a5 8.Bg5 h6  9.Bh4 Na6 10.Nd2 Qe8 11.O-O Nh7 *","weight":72036,"prior_delay":25,"prior_completion_time":0},
{"oid":2325,"gid":386,"fullname":"King's Indian Defense, Petrosian Variation, Stein Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.d5 a5 8.Bg5 h6  9.Bh4 Na6 10.Nd2 Qe8 11.O-O Bd7 *","weight":72027,"prior_delay":25,"prior_completion_time":0},
{"oid":2326,"gid":386,"fullname":"King's Indian Defense, Petrosian Variation, Stein Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.d5 a5 8.h3 *","weight":71673,"prior_delay":25,"prior_completion_time":0},
{"oid":2327,"gid":386,"fullname":"King's Indian Defense, Petrosian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Nf3 O-O 6.Be2 e5 7.d5 c5 8.Bg5 *","weight":71486,"prior_delay":25,"prior_completion_time":0},
{"oid":2328,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Bobotsov-Korchnoi-Petrosian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Nge2 e5 *","weight":61388,"prior_delay":25,"prior_completion_time":0},
{"oid":2329,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Bobotsov-Korchnoi-Petrosian Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Nge2 c5 7.d5 *","weight":61581,"prior_delay":25,"prior_completion_time":0},
{"oid":2330,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Byrne Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 c6 7.Bd3 a6 *","weight":61897,"prior_delay":25,"prior_completion_time":0},
{"oid":2331,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Closed Variation, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.d5 c6 8.Nge2 cxd5  9.cxd5 Nbd7 10.Qd2 a6 11.g4 h5 12.h3 *","weight":62188,"prior_delay":25,"prior_completion_time":0},
{"oid":2332,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Closed Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.d5 c5 *","weight":62062,"prior_delay":25,"prior_completion_time":0},
{"oid":2333,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Closed Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.d5 c6 8.Bd3 cxd5  9.cxd5 Nh5 *","weight":62205,"prior_delay":25,"prior_completion_time":0},
{"oid":2334,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Closed Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.d5 Nbd7 *","weight":62062,"prior_delay":25,"prior_completion_time":0},
{"oid":2335,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Closed Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.d5 Nh5 8.Qd2 f5  9.O-O-O f4 *","weight":62158,"prior_delay":25,"prior_completion_time":0},
{"oid":2336,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Closed Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.d5 c6 8.Bd3 b5 *","weight":62143,"prior_delay":25,"prior_completion_time":0},
{"oid":2337,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Closed Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.d5 Nh5 8.Qd2 Qh4+  9.Bf2 *","weight":62152,"prior_delay":25,"prior_completion_time":0},
{"oid":2338,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Closed Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.d5 Nh5 8.Qd2 f5  9.O-O-O Nd7 10.Bd3 *","weight":62173,"prior_delay":25,"prior_completion_time":0},
{"oid":2339,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Closed Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.d5 c6 8.Qd2 cxd5  9.cxd5 a6 10.Bd3 *","weight":62148,"prior_delay":25,"prior_completion_time":0},
{"oid":2340,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Closed Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.d5 Nh5 8.Qd2 Qh4+  9.g3 *","weight":62149,"prior_delay":25,"prior_completion_time":0},
{"oid":2341,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Double Fianchetto","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 b6 7.Bd3 a6 8.Nge2 c5  9.d5 *","weight":61878,"prior_delay":25,"prior_completion_time":0},
{"oid":2342,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Double Fianchetto","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 b6 7.Bd3 a6 8.Nge2 c5  9.e5 *","weight":61881,"prior_delay":25,"prior_completion_time":0},
{"oid":2343,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Normal Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nbd7 7.Qd2 c5 8.Nge2 a6 *","weight":61925,"prior_delay":25,"prior_completion_time":0},
{"oid":2344,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Normal Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nbd7 7.Qd2 c5 8.d5 *","weight":61918,"prior_delay":25,"prior_completion_time":0},
{"oid":2345,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Normal Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 c5 7.dxc5 dxc5 8.Qxd8+ *","weight":62215,"prior_delay":25,"prior_completion_time":0},
{"oid":2346,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Normal Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 c5 7.d5 *","weight":62058,"prior_delay":25,"prior_completion_time":0},
{"oid":2347,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Normal Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 a6 7.Qd2 *","weight":61967,"prior_delay":25,"prior_completion_time":0},
{"oid":2348,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Normal Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 c5 7.Nge2 Nc6 8.d5 *","weight":62418,"prior_delay":25,"prior_completion_time":0},
{"oid":2349,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Orthodox Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.Nge2 exd4 *","weight":62043,"prior_delay":25,"prior_completion_time":0},
{"oid":2350,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Panno Formation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nc6 7.Nge2 a6 8.Qd2 Re8 *","weight":62318,"prior_delay":25,"prior_completion_time":0},
{"oid":2351,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Panno Formation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nc6 7.Nge2 a6 8.a3 *","weight":62166,"prior_delay":25,"prior_completion_time":0},
{"oid":2352,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Panno Formation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nc6 7.Nge2 a6 8.Nc1 *","weight":62168,"prior_delay":25,"prior_completion_time":0},
{"oid":2353,"gid":387,"fullname":"King's Indian Defense, Samisch Variation, Yates Defense","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 Nc6 7.Qd2 a6 8.O-O-O *","weight":62007,"prior_delay":25,"prior_completion_time":0},
{"oid":2354,"gid":387,"fullname":"King's Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 c5 6.dxc5 dxc5 7.Qxd8+ Kxd8 *","weight":60359,"prior_delay":25,"prior_completion_time":0},
{"oid":2355,"gid":387,"fullname":"King's Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 Nbd7 *","weight":60326,"prior_delay":25,"prior_completion_time":0},
{"oid":2356,"gid":387,"fullname":"King's Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 Nc6 *","weight":60332,"prior_delay":25,"prior_completion_time":0},
{"oid":2357,"gid":387,"fullname":"King's Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.Nge2 c6 8.Qd2 Nbd7  9.O-O-O a6 10.Kb1 *","weight":62268,"prior_delay":25,"prior_completion_time":0},
{"oid":2358,"gid":387,"fullname":"King's Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 c6 6.Be3 a6 7.Bd3 *","weight":60364,"prior_delay":25,"prior_completion_time":0},
{"oid":2359,"gid":387,"fullname":"King's Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 e5 6.d5 *","weight":60338,"prior_delay":25,"prior_completion_time":0},
{"oid":2360,"gid":387,"fullname":"King's Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 c6 6.Be3 a6 7.a4 *","weight":60364,"prior_delay":25,"prior_completion_time":0},
{"oid":2361,"gid":387,"fullname":"King's Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 a6 6.Be3 *","weight":60383,"prior_delay":25,"prior_completion_time":0},
{"oid":2362,"gid":387,"fullname":"King's Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.Nge2 c6 8.Qd2 Nbd7  9.d5 *","weight":62248,"prior_delay":25,"prior_completion_time":0},
{"oid":2363,"gid":387,"fullname":"King's Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Be3 e5 7.Nge2 c6 8.d5 *","weight":62104,"prior_delay":25,"prior_completion_time":0},
{"oid":2364,"gid":387,"fullname":"King's Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 e5 6.Nge2 *","weight":60353,"prior_delay":25,"prior_completion_time":0},
{"oid":2365,"gid":388,"fullname":"King's Indian Defense, Semi-Averbakh System","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.Be2 O-O 6.Be3 *","weight":61095,"prior_delay":25,"prior_completion_time":0},
{"oid":2366,"gid":389,"fullname":"King's Indian Defense, Semi-Classical Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 O-O 5.e3 d6 6.Be2 c5 7.O-O *","weight":50565,"prior_delay":25,"prior_completion_time":0},
{"oid":2367,"gid":390,"fullname":"King's Indian Defense, Smyslov Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.Bg5 h6 *","weight":50076,"prior_delay":25,"prior_completion_time":0},
{"oid":2368,"gid":391,"fullname":"King's Indian Defense, Steiner Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Bg5 c5 7.d5 a6 *","weight":61456,"prior_delay":25,"prior_completion_time":0},
{"oid":2369,"gid":391,"fullname":"King's Indian Defense, Steiner Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Bg5 c5 7.d5 h6 *","weight":61460,"prior_delay":25,"prior_completion_time":0},
{"oid":2370,"gid":391,"fullname":"King's Indian Defense, Steiner Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Bg5 Nc6 *","weight":61315,"prior_delay":25,"prior_completion_time":0},
{"oid":2371,"gid":391,"fullname":"King's Indian Defense, Steiner Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Bg5 a6 *","weight":61339,"prior_delay":25,"prior_completion_time":0},
{"oid":2372,"gid":391,"fullname":"King's Indian Defense, Steiner Attack","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.e4 d6 5.f3 O-O 6.Bg5 c5 7.d5 e6 8.Qd2 *","weight":61558,"prior_delay":25,"prior_completion_time":0},
{"oid":2373,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 c5 *","weight":45152,"prior_delay":25,"prior_completion_time":0},
{"oid":2374,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 c6 *","weight":45150,"prior_delay":25,"prior_completion_time":0},
{"oid":2375,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d6 *","weight":45159,"prior_delay":25,"prior_completion_time":0},
{"oid":2376,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 O-O 5.Bg5 d6 6.e3 c6 *","weight":50793,"prior_delay":25,"prior_completion_time":0},
{"oid":2377,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 O-O 5.Bg5 d6 6.e3 c5 *","weight":50806,"prior_delay":25,"prior_completion_time":0},
{"oid":2378,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Bg5 O-O 5.e3 d6 *","weight":49682,"prior_delay":25,"prior_completion_time":0},
{"oid":2379,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.g3 O-O 5.Bg2 d6 6.e3 *","weight":50268,"prior_delay":25,"prior_completion_time":0},
{"oid":2380,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 O-O 5.g3 *","weight":50650,"prior_delay":25,"prior_completion_time":0},
{"oid":2381,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 O-O 5.Bf4 d6 6.h3 *","weight":50566,"prior_delay":25,"prior_completion_time":0},
{"oid":2382,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 d6 5.e3 *","weight":50022,"prior_delay":25,"prior_completion_time":0},
{"oid":2383,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 O-O 5.Bg5 c5 6.e3 *","weight":50699,"prior_delay":25,"prior_completion_time":0},
{"oid":2384,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Bf4 *","weight":49626,"prior_delay":25,"prior_completion_time":0},
{"oid":2385,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.Nf3 O-O 5.Bf4 d6 6.e3 *","weight":50577,"prior_delay":25,"prior_completion_time":0},
{"oid":2386,"gid":382,"fullname":"King's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 Bg7 4.g3 d6 5.Bg2 *","weight":49738,"prior_delay":25,"prior_completion_time":0},
{"oid":2387,"gid":392,"fullname":"London System, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.Bf4 Bg7 4.Nbd2 *","weight":26353,"prior_delay":25,"prior_completion_time":0},
{"oid":2388,"gid":392,"fullname":"London System, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.Bf4 Bg7 4.c3 *","weight":26408,"prior_delay":25,"prior_completion_time":0},
{"oid":2389,"gid":392,"fullname":"London System, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.Bf4 Bg7 4.e3 O-O 5.Be2 d6 6.h3 *","weight":26889,"prior_delay":25,"prior_completion_time":0},
{"oid":2390,"gid":393,"fullname":"Mexican Defense, Main Line","main_line":"1.d4 Nf6 2.c4 Nc6 3.Nf3 d6 *","weight":30383,"prior_delay":25,"prior_completion_time":0},
{"oid":2391,"gid":393,"fullname":"Mexican Defense, Main Line","main_line":"1.d4 Nf6 2.c4 Nc6 3.Nf3 e6 4.a3 *","weight":30499,"prior_delay":25,"prior_completion_time":0},
{"oid":2392,"gid":393,"fullname":"Mexican Defense, Main Line","main_line":"1.d4 Nf6 2.c4 Nc6 3.Nf3 e6 4.Nc3 *","weight":30492,"prior_delay":25,"prior_completion_time":0},
{"oid":2393,"gid":394,"fullname":"Modern Defense, Averbakh System","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 e5 5.Nf3 Nd7 6.Be2 Ne7 *","weight":12836,"prior_delay":25,"prior_completion_time":0},
{"oid":2394,"gid":394,"fullname":"Modern Defense, Averbakh System","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 e5 5.d5 Nd7 *","weight":12756,"prior_delay":25,"prior_completion_time":0},
{"oid":2395,"gid":394,"fullname":"Modern Defense, Averbakh System","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 e5 5.Nge2 *","weight":12761,"prior_delay":25,"prior_completion_time":0},
{"oid":2396,"gid":394,"fullname":"Modern Defense, Averbakh System","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 e5 5.dxe5 dxe5 6.Qxd8+ Kxd8 7.f4 *","weight":12843,"prior_delay":25,"prior_completion_time":0},
{"oid":2397,"gid":394,"fullname":"Modern Defense, Averbakh System","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 c6 5.Be3 *","weight":12661,"prior_delay":25,"prior_completion_time":0},
{"oid":2398,"gid":394,"fullname":"Modern Defense, Averbakh System","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 Nd7 5.Nf3 *","weight":12684,"prior_delay":25,"prior_completion_time":0},
{"oid":2399,"gid":395,"fullname":"Modern Defense, Beefeater Variation","main_line":"1.d4 g6 2.c4 Bg7 3.Nc3 c5 4.d5 Bxc3+ 5.bxc3 f5 *","weight":13279,"prior_delay":25,"prior_completion_time":0},
{"oid":2400,"gid":396,"fullname":"Modern Defense, Kotov Variation","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 Nc6 5.Be3 e5 6.Nge2 *","weight":12772,"prior_delay":25,"prior_completion_time":0},
{"oid":2401,"gid":396,"fullname":"Modern Defense, Kotov Variation","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 Nc6 5.Be3 e5 6.d5 Nce7 7.g4 *","weight":12801,"prior_delay":25,"prior_completion_time":0},
{"oid":2402,"gid":396,"fullname":"Modern Defense, Kotov Variation","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 Nc6 5.Nge2 *","weight":12708,"prior_delay":25,"prior_completion_time":0},
{"oid":2403,"gid":396,"fullname":"Modern Defense, Kotov Variation","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 Nc6 5.Be3 e5 6.d5 Nce7 7.c5 *","weight":12800,"prior_delay":25,"prior_completion_time":0},
{"oid":2404,"gid":396,"fullname":"Modern Defense, Kotov Variation","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 Nc6 5.d5 *","weight":12731,"prior_delay":25,"prior_completion_time":0},
{"oid":2405,"gid":398,"fullname":"Modern Defense, Randspringer Variation","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 f5 5.Nf3 *","weight":12655,"prior_delay":25,"prior_completion_time":0},
{"oid":2406,"gid":397,"fullname":"Modern Defense, Main Line","main_line":"1.d4 g6 2.c4 Bg7 3.Nf3 c5 *","weight":13112,"prior_delay":25,"prior_completion_time":0},
{"oid":2407,"gid":397,"fullname":"Modern Defense, Main Line","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.Nf3 Bg4 5.e3 Nc6 *","weight":12744,"prior_delay":25,"prior_completion_time":0},
{"oid":2408,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Modern Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O dxc4 7.Na3 Nc6  8.Nxc4 Be6 9.b3 Bd5 *","weight":36684,"prior_delay":25,"prior_completion_time":0},
{"oid":2409,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Modern Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O dxc4 7.Na3 Na6 *","weight":36498,"prior_delay":25,"prior_completion_time":0},
{"oid":2410,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Modern Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O dxc4 7.Na3 c3 8.bxc3 c5  9.e3 *","weight":36943,"prior_delay":25,"prior_completion_time":0},
{"oid":2411,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Original Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.Nbd2 Nbd7 *","weight":36353,"prior_delay":25,"prior_completion_time":0},
{"oid":2412,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Original Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.Qb3 Qb6 *","weight":36495,"prior_delay":25,"prior_completion_time":0},
{"oid":2413,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Original Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.b3 Ne4 *","weight":36440,"prior_delay":25,"prior_completion_time":0},
{"oid":2414,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Original Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.Na3 *","weight":36292,"prior_delay":25,"prior_completion_time":0},
{"oid":2415,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Original Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.Nbd2 Bf5 *","weight":36413,"prior_delay":25,"prior_completion_time":0},
{"oid":2416,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Original Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.b3 Bf5 *","weight":36450,"prior_delay":25,"prior_completion_time":0},
{"oid":2417,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Original Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.Ne5 *","weight":36298,"prior_delay":25,"prior_completion_time":0},
{"oid":2418,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Original Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.Nbd2 Ne4 *","weight":36368,"prior_delay":25,"prior_completion_time":0},
{"oid":2419,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Original Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.Qa4 *","weight":36308,"prior_delay":25,"prior_completion_time":0},
{"oid":2420,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Original Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.Qb3 dxc4 8.Qxc4 *","weight":36482,"prior_delay":25,"prior_completion_time":0},
{"oid":2421,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Original Defense","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.Nc3 dxc4 *","weight":36291,"prior_delay":25,"prior_completion_time":0},
{"oid":2422,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation, Polgar Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O Nc6 *","weight":36156,"prior_delay":25,"prior_completion_time":0},
{"oid":2423,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O e6 *","weight":36129,"prior_delay":25,"prior_completion_time":0},
{"oid":2424,"gid":399,"fullname":"Neo-Grunfeld Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c5 7.dxc5 dxc4 *","weight":36135,"prior_delay":25,"prior_completion_time":0},
{"oid":2425,"gid":400,"fullname":"Neo-Grunfeld Defense, Delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.cxd5 Nxd5 7.O-O Na6 *","weight":36491,"prior_delay":25,"prior_completion_time":0},
{"oid":2426,"gid":400,"fullname":"Neo-Grunfeld Defense, Delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.cxd5 Nxd5 7.O-O Nb6  8.Nc3 Nc6 9.e3 e5 10.d5 Na5 *","weight":37188,"prior_delay":25,"prior_completion_time":0},
{"oid":2427,"gid":400,"fullname":"Neo-Grunfeld Defense, Delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.cxd5 Nxd5 7.O-O c5  8.dxc5 Na6 *","weight":36541,"prior_delay":25,"prior_completion_time":0},
{"oid":2428,"gid":400,"fullname":"Neo-Grunfeld Defense, Delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.cxd5 Nxd5 7.O-O c6 *","weight":36491,"prior_delay":25,"prior_completion_time":0},
{"oid":2429,"gid":400,"fullname":"Neo-Grunfeld Defense, Delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.cxd5 Nxd5 7.O-O c5 8.e4 Nf6  9.e5 Nd5 *","weight":36555,"prior_delay":25,"prior_completion_time":0},
{"oid":2430,"gid":400,"fullname":"Neo-Grunfeld Defense, Delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.cxd5 Nxd5 7.O-O c5  8.Nc3 Nxc3 9.bxc3 cxd4 *","weight":36575,"prior_delay":25,"prior_completion_time":0},
{"oid":2431,"gid":400,"fullname":"Neo-Grunfeld Defense, Delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.cxd5 Nxd5 7.O-O Nc6 *","weight":36510,"prior_delay":25,"prior_completion_time":0},
{"oid":2432,"gid":400,"fullname":"Neo-Grunfeld Defense, Delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.cxd5 Nxd5 7.O-O Nb6  8.Nc3 Nc6 9.d5 Na5 10.e4 c6 11.Bg5 *","weight":37264,"prior_delay":25,"prior_completion_time":0},
{"oid":2433,"gid":400,"fullname":"Neo-Grunfeld Defense, Delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.cxd5 Nxd5 7.O-O Nb6  8.Nc3 Nc6 9.e3 Re8 10.d5 *","weight":37593,"prior_delay":25,"prior_completion_time":0},
{"oid":2434,"gid":401,"fullname":"Neo-Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.cxd5 Nxd5 6.e4 Nb6 7.Ne2 Nc6 *","weight":36896,"prior_delay":25,"prior_completion_time":0},
{"oid":2435,"gid":401,"fullname":"Neo-Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.cxd5 Nxd5 6.e4 Nb6 7.Ne2 e5 *","weight":36899,"prior_delay":25,"prior_completion_time":0},
{"oid":2436,"gid":401,"fullname":"Neo-Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.cxd5 Nxd5 6.e4 Nb6 7.Ne2 c5 *","weight":36970,"prior_delay":25,"prior_completion_time":0},
{"oid":2437,"gid":401,"fullname":"Neo-Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.cxd5 Nxd5 6.Nc3 Nxc3 7.bxc3 c5 *","weight":36649,"prior_delay":25,"prior_completion_time":0},
{"oid":2438,"gid":401,"fullname":"Neo-Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.cxd5 Nxd5 6.Nc3 Nb6 *","weight":36552,"prior_delay":25,"prior_completion_time":0},
{"oid":2439,"gid":401,"fullname":"Neo-Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.cxd5 Nxd5 6.e4 Nb4 7.d5 *","weight":36738,"prior_delay":25,"prior_completion_time":0},
{"oid":2440,"gid":401,"fullname":"Neo-Grunfeld Defense, Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.cxd5 Nxd5 6.e4 Nb6 7.Ne2 O-O 8.O-O *","weight":36926,"prior_delay":25,"prior_completion_time":0},
{"oid":2441,"gid":402,"fullname":"Neo-Grunfeld Defense, Goglidze Attack","main_line":"1.d4 Nf6 2.c4 g6 3.f3 d5 4.cxd5 Nxd5 5.e4 Nb6 6.Nc3 Bg7 7.Be3 O-O *","weight":36413,"prior_delay":25,"prior_completion_time":0},
{"oid":2442,"gid":404,"fullname":"Neo-Grunfeld Defense, Non- or Delayed Fianchetto","main_line":"1.d4 Nf6 2.c4 g6 3.Nf3 d5 *","weight":35891,"prior_delay":25,"prior_completion_time":0},
{"oid":2443,"gid":405,"fullname":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.cxd5 cxd5 8.Nc3 Nc6  9.Ne5 e6 *","weight":36812,"prior_delay":25,"prior_completion_time":0},
{"oid":2444,"gid":405,"fullname":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.cxd5 cxd5 8.Nc3 e6 *","weight":36763,"prior_delay":25,"prior_completion_time":0},
{"oid":2445,"gid":405,"fullname":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.cxd5 cxd5 8.Ne5 e6  9.Nc3 Nfd7 10.f4 Nc6 11.Be3 f6 *","weight":37439,"prior_delay":25,"prior_completion_time":0},
{"oid":2446,"gid":405,"fullname":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.cxd5 cxd5 8.Nc3 Ne4  9.Nxe4 *","weight":36829,"prior_delay":25,"prior_completion_time":0},
{"oid":2447,"gid":405,"fullname":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.cxd5 cxd5 8.Ne5 e6  9.Nc3 Nfd7 10.f4 Nc6 11.Be3 Nb6 12.Bf2 *","weight":37539,"prior_delay":25,"prior_completion_time":0},
{"oid":2448,"gid":405,"fullname":"Neo-Grunfeld Defense, Ultra-delayed Exchange Variation","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 Bg7 5.Nf3 O-O 6.O-O c6 7.cxd5 cxd5 8.Nc3 Ne4  9.Ne5 *","weight":36847,"prior_delay":25,"prior_completion_time":0},
{"oid":2449,"gid":403,"fullname":"Neo-Grunfeld Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.Bg2 c6 *","weight":35961,"prior_delay":25,"prior_completion_time":0},
{"oid":2450,"gid":403,"fullname":"Neo-Grunfeld Defense, Main Line","main_line":"1.d4 Nf6 2.c4 g6 3.g3 d5 4.cxd5 *","weight":35986,"prior_delay":25,"prior_completion_time":0},
{"oid":2451,"gid":406,"fullname":"Nimzo-Indian Defense, Classical, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.cxd5 exd5 6.Bg5 c5 *","weight":62858,"prior_delay":25,"prior_completion_time":0},
{"oid":2452,"gid":406,"fullname":"Nimzo-Indian Defense, Classical, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.cxd5 exd5 6.Bg5 h6 7.Bh4 c5 *","weight":63033,"prior_delay":25,"prior_completion_time":0},
{"oid":2453,"gid":406,"fullname":"Nimzo-Indian Defense, Classical, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.cxd5 exd5 6.a3 *","weight":62510,"prior_delay":25,"prior_completion_time":0},
{"oid":2454,"gid":406,"fullname":"Nimzo-Indian Defense, Classical, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.cxd5 exd5 6.Bg5 h6 7.Bxf6 *","weight":62953,"prior_delay":25,"prior_completion_time":0},
{"oid":2455,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation, Pirc Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.dxc5 O-O 6.Nf3 Bxc5 *","weight":62226,"prior_delay":25,"prior_completion_time":0},
{"oid":2456,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation, Pirc Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.dxc5 O-O 6.Nf3 Na6 7.a3 *","weight":62408,"prior_delay":25,"prior_completion_time":0},
{"oid":2457,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation, Pirc Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.dxc5 O-O 6.a3 Bxc5 7.Nf3 Nc6 *","weight":62849,"prior_delay":25,"prior_completion_time":0},
{"oid":2458,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation, Pirc Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.dxc5 O-O 6.Bf4 *","weight":62167,"prior_delay":25,"prior_completion_time":0},
{"oid":2459,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation, Pirc Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.dxc5 O-O 6.Nf3 Na6 7.g3 *","weight":62522,"prior_delay":25,"prior_completion_time":0},
{"oid":2460,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation, Pirc Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.dxc5 O-O 6.Nf3 Na6 7.Bd2 *","weight":62415,"prior_delay":25,"prior_completion_time":0},
{"oid":2461,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation, Pirc Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.dxc5 O-O 6.a3 Bxc5 7.Nf3 b6 8.Bg5 *","weight":63024,"prior_delay":25,"prior_completion_time":0},
{"oid":2462,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.dxc5 Bxc5 6.Nf3 Qb6 *","weight":62091,"prior_delay":25,"prior_completion_time":0},
{"oid":2463,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.dxc5 Qc7 *","weight":61976,"prior_delay":25,"prior_completion_time":0},
{"oid":2464,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.dxc5 Nc6 *","weight":61934,"prior_delay":25,"prior_completion_time":0},
{"oid":2465,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.e3 *","weight":61347,"prior_delay":25,"prior_completion_time":0},
{"oid":2466,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Berlin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 c5 5.dxc5 Na6 6.a3 Bxc3+ 7.Qxc3 Nxc5  8.b4 Nce4 9.Qd4 d5 10.c5 *","weight":62136,"prior_delay":25,"prior_completion_time":0},
{"oid":2467,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Keres Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 O-O 5.a3 Bxc3+ 6.Qxc3 b6 7.Bg5 Ba6 *","weight":68342,"prior_delay":25,"prior_completion_time":0},
{"oid":2468,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Keres Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 O-O 5.a3 Bxc3+ 6.Qxc3 b6 7.Bg5 Bb7 8.e3 d6 *","weight":69195,"prior_delay":25,"prior_completion_time":0},
{"oid":2469,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Keres Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 O-O 5.a3 Bxc3+ 6.Qxc3 b6 7.Bg5 Bb7 8.f3 h6  9.Bh4 *","weight":69218,"prior_delay":25,"prior_completion_time":0},
{"oid":2470,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Keres Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 O-O 5.a3 Bxc3+ 6.Qxc3 b6 7.Nf3 *","weight":67642,"prior_delay":25,"prior_completion_time":0},
{"oid":2471,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Milner-Barry Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 Nc6 5.Nf3 d6 6.Bd2 O-O *","weight":61308,"prior_delay":25,"prior_completion_time":0},
{"oid":2472,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Milner-Barry Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 Nc6 5.Nf3 d6 6.a3 Bxc3+ 7.Qxc3 *","weight":61269,"prior_delay":25,"prior_completion_time":0},
{"oid":2473,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Noa Variation, Botvinnik Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.a3 Bxc3+ 6.Qxc3 Nc6 *","weight":62551,"prior_delay":25,"prior_completion_time":0},
{"oid":2474,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Noa Variation, San Remo Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.a3 Bxc3+ 6.Qxc3 Ne4 7.Qc2 Nc6  8.e3 e5 *","weight":62878,"prior_delay":25,"prior_completion_time":0},
{"oid":2475,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.a3 Be7 *","weight":62003,"prior_delay":25,"prior_completion_time":0},
{"oid":2476,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.cxd5 Qxd5 6.Nf3 c5 *","weight":62692,"prior_delay":25,"prior_completion_time":0},
{"oid":2477,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.a3 Bxc3+ 6.Qxc3 Ne4 7.Qc2 c5  8.dxc5 Nc6 *","weight":63220,"prior_delay":25,"prior_completion_time":0},
{"oid":2478,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.cxd5 Qxd5 6.e3 c5 *","weight":62729,"prior_delay":25,"prior_completion_time":0},
{"oid":2479,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.a3 Bxc3+ 6.Qxc3 dxc4 *","weight":62598,"prior_delay":25,"prior_completion_time":0},
{"oid":2480,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.a3 Bxc3+ 6.Qxc3 O-O *","weight":62588,"prior_delay":25,"prior_completion_time":0},
{"oid":2481,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.cxd5 Qxd5 6.Nf3 Qf5 7.Qb3 *","weight":62875,"prior_delay":25,"prior_completion_time":0},
{"oid":2482,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Noa Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d5 5.cxd5 Qxd5 6.Nf3 Qf5 7.Qxf5 *","weight":62907,"prior_delay":25,"prior_completion_time":0},
{"oid":2483,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Vitolins-Adorjan Gambit","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 O-O 5.a3 Bxc3+ 6.Qxc3 b5 *","weight":66917,"prior_delay":25,"prior_completion_time":0},
{"oid":2484,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation, Zurich Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 Nc6 5.Nf3 d5 *","weight":61130,"prior_delay":25,"prior_completion_time":0},
{"oid":2485,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 O-O 5.a3 Bxc3+ 6.Qxc3 d6 *","weight":67049,"prior_delay":25,"prior_completion_time":0},
{"oid":2486,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 b6 *","weight":60998,"prior_delay":25,"prior_completion_time":0},
{"oid":2487,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 O-O 5.a3 Bxc3+ 6.Qxc3 Ne4 *","weight":66906,"prior_delay":25,"prior_completion_time":0},
{"oid":2488,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 d6 *","weight":61038,"prior_delay":25,"prior_completion_time":0},
{"oid":2489,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 O-O 5.Bg5 *","weight":62597,"prior_delay":25,"prior_completion_time":0},
{"oid":2490,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 O-O 5.Nf3 *","weight":62814,"prior_delay":25,"prior_completion_time":0},
{"oid":2491,"gid":407,"fullname":"Nimzo-Indian Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qc2 O-O 5.e4 *","weight":62757,"prior_delay":25,"prior_completion_time":0},
{"oid":2492,"gid":408,"fullname":"Nimzo-Indian Defense, Fischer Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 b6 5.Ne2 Bb7 *","weight":61204,"prior_delay":25,"prior_completion_time":0},
{"oid":2493,"gid":408,"fullname":"Nimzo-Indian Defense, Fischer Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 b6 5.Ne2 Ne4 6.Bd2 *","weight":61266,"prior_delay":25,"prior_completion_time":0},
{"oid":2494,"gid":408,"fullname":"Nimzo-Indian Defense, Fischer Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 b6 5.Ne2 Ne4 6.Qc2 *","weight":61264,"prior_delay":25,"prior_completion_time":0},
{"oid":2495,"gid":409,"fullname":"Nimzo-Indian Defense, H\u00c3\u00bcbner Variation, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 c5 5.Bd3 Nc6 6.Nf3 Bxc3+ 7.bxc3 d6 8.e4 e5  9.d5 Ne7 *","weight":62247,"prior_delay":25,"prior_completion_time":0},
{"oid":2496,"gid":409,"fullname":"Nimzo-Indian Defense, H\u00c3\u00bcbner Variation, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 c5 5.Bd3 Nc6 6.Nf3 Bxc3+ 7.bxc3 d6  8.O-O e5 9.Nd2 *","weight":62205,"prior_delay":25,"prior_completion_time":0},
{"oid":2497,"gid":409,"fullname":"Nimzo-Indian Defense, H\u00c3\u00bcbner Variation, Rubinstein Variation, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 c5 5.Ne2 cxd4 6.exd4 O-O 7.a3 Be7 *","weight":62091,"prior_delay":25,"prior_completion_time":0},
{"oid":2498,"gid":409,"fullname":"Nimzo-Indian Defense, H\u00c3\u00bcbner Variation, Rubinstein Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 c5 5.Ne2 cxd4 6.exd4 d5 *","weight":61897,"prior_delay":25,"prior_completion_time":0},
{"oid":2499,"gid":409,"fullname":"Nimzo-Indian Defense, H\u00c3\u00bcbner Variation, Rubinstein Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 c5 5.Ne2 d5 *","weight":61535,"prior_delay":25,"prior_completion_time":0},
{"oid":2500,"gid":409,"fullname":"Nimzo-Indian Defense, H\u00c3\u00bcbner Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 c5 5.Bd3 Nc6 6.Ne2 *","weight":61755,"prior_delay":25,"prior_completion_time":0},
{"oid":2501,"gid":409,"fullname":"Nimzo-Indian Defense, H\u00c3\u00bcbner Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 c5 5.Nf3 *","weight":61205,"prior_delay":25,"prior_completion_time":0},
{"oid":2502,"gid":410,"fullname":"Nimzo-Indian Defense, Kmoch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.f3 d5 5.a3 Be7 *","weight":58366,"prior_delay":25,"prior_completion_time":0},
{"oid":2503,"gid":410,"fullname":"Nimzo-Indian Defense, Kmoch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.f3 c5 5.d5 *","weight":57984,"prior_delay":25,"prior_completion_time":0},
{"oid":2504,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation, Averbakh Gambit","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 b5 *","weight":57556,"prior_delay":25,"prior_completion_time":0},
{"oid":2505,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation, Benoni Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 d6 7.e3 Bxc3+ 8.bxc3 e5  9.Bd3 *","weight":57726,"prior_delay":25,"prior_completion_time":0},
{"oid":2506,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation, Benoni Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 d6 7.e3 Bxc3+ 8.bxc3 e5  9.Nf3 *","weight":57722,"prior_delay":25,"prior_completion_time":0},
{"oid":2507,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation, Benoni Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 d6 7.e3 Bxc3+ 8.bxc3 e5  9.Qc2 *","weight":57725,"prior_delay":25,"prior_completion_time":0},
{"oid":2508,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation, Benoni Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 d6 7.e3 Qe7 *","weight":57624,"prior_delay":25,"prior_completion_time":0},
{"oid":2509,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation, Benoni Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 d6 7.e3 exd5 *","weight":57621,"prior_delay":25,"prior_completion_time":0},
{"oid":2510,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation, Benoni Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 d6 7.e3 O-O *","weight":57620,"prior_delay":25,"prior_completion_time":0},
{"oid":2511,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation, Benoni Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 d6 7.e3 Bxc3+ 8.bxc3 Qe7 *","weight":57705,"prior_delay":25,"prior_completion_time":0},
{"oid":2512,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation, Benoni Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 d6 7.e3 Bxc3+ 8.bxc3 e5  9.f3 Nbd7 *","weight":57737,"prior_delay":25,"prior_completion_time":0},
{"oid":2513,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation, Benoni Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 d6 7.e3 g5 *","weight":57620,"prior_delay":25,"prior_completion_time":0},
{"oid":2514,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 c5 5.d5 exd5 *","weight":57487,"prior_delay":25,"prior_completion_time":0},
{"oid":2515,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 c5 5.d5 h6 *","weight":57515,"prior_delay":25,"prior_completion_time":0},
{"oid":2516,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 c5 5.d5 d6 6.e3 *","weight":57544,"prior_delay":25,"prior_completion_time":0},
{"oid":2517,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 Bxc3+ *","weight":57571,"prior_delay":25,"prior_completion_time":0},
{"oid":2518,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 c5 5.d5 Bxc3+ *","weight":57492,"prior_delay":25,"prior_completion_time":0},
{"oid":2519,"gid":411,"fullname":"Nimzo-Indian Defense, Leningrad Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bg5 h6 5.Bh4 c5 6.d5 exd5 7.cxd5 *","weight":57547,"prior_delay":25,"prior_completion_time":0},
{"oid":2520,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense, Exchange Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 b6 *","weight":65411,"prior_delay":25,"prior_completion_time":0},
{"oid":2521,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense, Exchange Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 Qc7 10.Qc2 *","weight":65524,"prior_delay":25,"prior_completion_time":0},
{"oid":2522,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense, Exchange Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 Qc7 10.cxd5 exd5 11.a4 Re8 *","weight":65654,"prior_delay":25,"prior_completion_time":0},
{"oid":2523,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense, Exchange Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 Qc7 10.h3 *","weight":65561,"prior_delay":25,"prior_completion_time":0},
{"oid":2524,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense, Exchange Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 Qc7 10.cxd5 exd5 11.Nh4 *","weight":65625,"prior_delay":25,"prior_completion_time":0},
{"oid":2525,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense, Exchange Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 Qc7 10.Bb2 *","weight":65558,"prior_delay":25,"prior_completion_time":0},
{"oid":2526,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 dxc4 10.Bxc4 Qc7 11.Ba2 e5 *","weight":65718,"prior_delay":25,"prior_completion_time":0},
{"oid":2527,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 dxc4 10.Bxc4 Qc7 11.a4 *","weight":65680,"prior_delay":25,"prior_completion_time":0},
{"oid":2528,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 dxc4 10.Bxc4 Qc7 11.Be2 *","weight":65679,"prior_delay":25,"prior_completion_time":0},
{"oid":2529,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 dxc4 10.Bxc4 Qc7 11.Bd3 e5 12.Qc2 Re8 13.dxe5 *","weight":65732,"prior_delay":25,"prior_completion_time":0},
{"oid":2530,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 dxc4 10.Bxc4 Qc7 11.Qc2 *","weight":65678,"prior_delay":25,"prior_completion_time":0},
{"oid":2531,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 dxc4 10.Bxc4 Qc7 11.Bb5 *","weight":65688,"prior_delay":25,"prior_completion_time":0},
{"oid":2532,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 dxc4 10.Bxc4 Qc7 11.Qe2 *","weight":65679,"prior_delay":25,"prior_completion_time":0},
{"oid":2533,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Bxc3  9.bxc3 dxc4 10.Bxc4 Qc7 11.Bb2 *","weight":65730,"prior_delay":25,"prior_completion_time":0},
{"oid":2534,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bishop Attack, Classical Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Bd3 d5 6.Ne2 c5 7.O-O cxd4 8.exd4 *","weight":65328,"prior_delay":25,"prior_completion_time":0},
{"oid":2535,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bishop Attack, Classical Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Bd3 d5 6.cxd5 *","weight":65357,"prior_delay":25,"prior_completion_time":0},
{"oid":2536,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bishop Attack, Classical Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Bd3 d5 6.Ne2 c5 7.cxd5 cxd4  8.exd4 Nxd5 9.O-O *","weight":65635,"prior_delay":25,"prior_completion_time":0},
{"oid":2537,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bishop Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Bd3 b6 *","weight":64243,"prior_delay":25,"prior_completion_time":0},
{"oid":2538,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bishop Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Bd3 d6 *","weight":64236,"prior_delay":25,"prior_completion_time":0},
{"oid":2539,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bishop Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Bd3 c5 6.Ne2 cxd4 *","weight":64752,"prior_delay":25,"prior_completion_time":0},
{"oid":2540,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Botvinnik System","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Bd3 d5 6.a3 Bxc3+ 7.bxc3 dxc4  8.Bxc4 c5 9.Ne2 *","weight":65748,"prior_delay":25,"prior_completion_time":0},
{"oid":2541,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Botvinnik System","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Bd3 d5 6.a3 Bxc3+ 7.bxc3 c5  8.cxd5 exd5 9.Ne2 *","weight":65570,"prior_delay":25,"prior_completion_time":0},
{"oid":2542,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bronstein (Byrne) Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 b6 5.Ne2 Ba6 6.Ng3 Bxc3+ *","weight":61297,"prior_delay":25,"prior_completion_time":0},
{"oid":2543,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bronstein (Byrne) Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 b6 5.Ne2 Ba6 6.Ng3 h5 *","weight":61284,"prior_delay":25,"prior_completion_time":0},
{"oid":2544,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bronstein (Byrne) Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 b6 5.Ne2 Ba6 6.a3 Bxc3+ *","weight":61313,"prior_delay":25,"prior_completion_time":0},
{"oid":2545,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Bronstein (Byrne) Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 b6 5.Ne2 Ba6 6.a3 Be7 *","weight":61310,"prior_delay":25,"prior_completion_time":0},
{"oid":2546,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 dxc4  9.Bxc4 cxd4 10.exd4 Be7 11.Re1 a6 *","weight":64992,"prior_delay":25,"prior_completion_time":0},
{"oid":2547,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 dxc4  9.Bxc4 Ba5 *","weight":65010,"prior_delay":25,"prior_completion_time":0},
{"oid":2548,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 dxc4  9.Bxc4 cxd4 10.exd4 Be7 11.Bg5 *","weight":64984,"prior_delay":25,"prior_completion_time":0},
{"oid":2549,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 dxc4  9.Bxc4 cxd4 10.exd4 Be7 11.Qd3 *","weight":64985,"prior_delay":25,"prior_completion_time":0},
{"oid":2550,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 cxd4  9.exd4 *","weight":64976,"prior_delay":25,"prior_completion_time":0},
{"oid":2551,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.a3 Ba5  9.cxd5 *","weight":65025,"prior_delay":25,"prior_completion_time":0},
{"oid":2552,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Bernstein Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nc6 8.cxd5 *","weight":64730,"prior_delay":25,"prior_completion_time":0},
{"oid":2553,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Bronstein Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O dxc4  8.Bxc4 Nbd7 9.Qe2 a6 *","weight":65417,"prior_delay":25,"prior_completion_time":0},
{"oid":2554,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Bronstein Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O dxc4  8.Bxc4 Nbd7 9.Ne2 *","weight":65320,"prior_delay":25,"prior_completion_time":0},
{"oid":2555,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Bronstein Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O dxc4  8.Bxc4 Nbd7 9.Qe2 b6 10.Rd1 *","weight":65466,"prior_delay":25,"prior_completion_time":0},
{"oid":2556,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Exchange at c4","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O dxc4  8.Bxc4 Nc6 *","weight":65188,"prior_delay":25,"prior_completion_time":0},
{"oid":2557,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Exchange at c4","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O dxc4  8.Bxc4 cxd4 9.exd4 b6 10.Bg5 Bb7 11.Qe2 *","weight":66022,"prior_delay":25,"prior_completion_time":0},
{"oid":2558,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Exchange at c4","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O dxc4  8.Bxc4 cxd4 9.exd4 a6 10.Bg5 *","weight":65352,"prior_delay":25,"prior_completion_time":0},
{"oid":2559,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Exchange at c4","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O dxc4  8.Bxc4 cxd4 9.exd4 b6 10.Bg5 Bb7 11.Re1 *","weight":66046,"prior_delay":25,"prior_completion_time":0},
{"oid":2560,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Exchange at c4","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O dxc4  8.Bxc4 cxd4 9.exd4 b6 10.Bg5 Bb7 11.Rc1 *","weight":66065,"prior_delay":25,"prior_completion_time":0},
{"oid":2561,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Exchange at c4","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O dxc4  8.Bxc4 cxd4 9.exd4 b6 10.Bg5 Bb7 11.Ne5 *","weight":66021,"prior_delay":25,"prior_completion_time":0},
{"oid":2562,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Keres Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O b6  8.cxd5 exd5 *","weight":64458,"prior_delay":25,"prior_completion_time":0},
{"oid":2563,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System, Smyslov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O dxc4  8.Bxc4 Qe7 *","weight":65137,"prior_delay":25,"prior_completion_time":0},
{"oid":2564,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Bd7 *","weight":64406,"prior_delay":25,"prior_completion_time":0},
{"oid":2565,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.O-O Nbd7 *","weight":64411,"prior_delay":25,"prior_completion_time":0},
{"oid":2566,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Gligoric System","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 c5 7.a3 *","weight":63407,"prior_delay":25,"prior_completion_time":0},
{"oid":2567,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, H\u00c3\u00bcbner Deferred","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 c5 6.Be2 *","weight":62717,"prior_delay":25,"prior_completion_time":0},
{"oid":2568,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, H\u00c3\u00bcbner Deferred","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 c5 6.Bd3 Nc6 7.O-O *","weight":62818,"prior_delay":25,"prior_completion_time":0},
{"oid":2569,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Ragozin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Be2 *","weight":62721,"prior_delay":25,"prior_completion_time":0},
{"oid":2570,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Samisch Deferred","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.a3 *","weight":62733,"prior_delay":25,"prior_completion_time":0},
{"oid":2571,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Schlechter Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 b6 7.O-O Bb7  8.cxd5 exd5 9.a3 Bd6 *","weight":63692,"prior_delay":25,"prior_completion_time":0},
{"oid":2572,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Schlechter Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 b6 7.O-O Bb7 8.a3 Bd6 *","weight":63353,"prior_delay":25,"prior_completion_time":0},
{"oid":2573,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Schlechter Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 b6 7.O-O Bb7  8.cxd5 exd5 9.Ne5 *","weight":63568,"prior_delay":25,"prior_completion_time":0},
{"oid":2574,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Taimanov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 Nc6 5.Ne2 d5 *","weight":60633,"prior_delay":25,"prior_completion_time":0},
{"oid":2575,"gid":413,"fullname":"Nimzo-Indian Defense, Normal Variation, Taimanov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 Nc6 5.Bd3 *","weight":60625,"prior_delay":25,"prior_completion_time":0},
{"oid":2576,"gid":414,"fullname":"Nimzo-Indian Defense, Ragozin Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 Nc6 7.O-O a6 *","weight":62875,"prior_delay":25,"prior_completion_time":0},
{"oid":2577,"gid":415,"fullname":"Nimzo-Indian Defense, Ragozin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 d5 6.Bd3 Nc6 7.O-O dxc4 *","weight":62871,"prior_delay":25,"prior_completion_time":0},
{"oid":2578,"gid":416,"fullname":"Nimzo-Indian Defense, Reshevsky Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Ne2 b6 *","weight":63135,"prior_delay":25,"prior_completion_time":0},
{"oid":2579,"gid":416,"fullname":"Nimzo-Indian Defense, Reshevsky Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Ne2 Re8 *","weight":63215,"prior_delay":25,"prior_completion_time":0},
{"oid":2580,"gid":416,"fullname":"Nimzo-Indian Defense, Reshevsky Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Ne2 d5 6.a3 Be7 7.cxd5 exd5 *","weight":64593,"prior_delay":25,"prior_completion_time":0},
{"oid":2581,"gid":417,"fullname":"Nimzo-Indian Defense, Romanishin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.g3 c5 5.Nf3 Nc6 *","weight":57529,"prior_delay":25,"prior_completion_time":0},
{"oid":2582,"gid":417,"fullname":"Nimzo-Indian Defense, Romanishin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.g3 c5 5.Nf3 Ne4 *","weight":57484,"prior_delay":25,"prior_completion_time":0},
{"oid":2583,"gid":417,"fullname":"Nimzo-Indian Defense, Romanishin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.g3 c5 5.Nf3 O-O *","weight":57479,"prior_delay":25,"prior_completion_time":0},
{"oid":2584,"gid":417,"fullname":"Nimzo-Indian Defense, Romanishin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.g3 c5 5.Nf3 cxd4 6.Nxd4 O-O 7.Bg2 d5 8.cxd5 *","weight":58481,"prior_delay":25,"prior_completion_time":0},
{"oid":2585,"gid":418,"fullname":"Nimzo-Indian Defense, Samisch, Romanovsky Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 c5 6.f3 d5 7.cxd5 Nxd5  8.dxc5 f5 9.e4 *","weight":57924,"prior_delay":25,"prior_completion_time":0},
{"oid":2586,"gid":418,"fullname":"Nimzo-Indian Defense, Samisch, Romanovsky Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 c5 6.f3 d5 7.cxd5 Nxd5  8.dxc5 f5 9.Nh3 *","weight":57938,"prior_delay":25,"prior_completion_time":0},
{"oid":2587,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation, Accelerated","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 b6 6.f3 *","weight":57306,"prior_delay":25,"prior_completion_time":0},
{"oid":2588,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation, Accelerated","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 d5 6.f3 *","weight":57295,"prior_delay":25,"prior_completion_time":0},
{"oid":2589,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation, Capablanca Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 O-O 6.e3 c5 7.Bd3 Nc6  8.Ne2 b6 9.e4 Ne8 10.O-O *","weight":57576,"prior_delay":25,"prior_completion_time":0},
{"oid":2590,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation, O'Kelly Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 c5 6.e3 b6 *","weight":57355,"prior_delay":25,"prior_completion_time":0},
{"oid":2591,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 O-O 6.e3 c5 7.Ne2 *","weight":57332,"prior_delay":25,"prior_completion_time":0},
{"oid":2592,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 O-O 6.f3 d5 7.e3 *","weight":57315,"prior_delay":25,"prior_completion_time":0},
{"oid":2593,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 O-O 6.e3 c5 7.Bd3 Nc6  8.Ne2 e5 *","weight":57448,"prior_delay":25,"prior_completion_time":0},
{"oid":2594,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 O-O 6.e3 b6 *","weight":57313,"prior_delay":25,"prior_completion_time":0},
{"oid":2595,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 O-O 6.e3 c5 7.Bd3 b6 *","weight":57362,"prior_delay":25,"prior_completion_time":0},
{"oid":2596,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 c5 6.e3 d6 *","weight":57357,"prior_delay":25,"prior_completion_time":0},
{"oid":2597,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 O-O 6.e3 c5 7.Bd3 Nc6  8.Ne2 d6 *","weight":57449,"prior_delay":25,"prior_completion_time":0},
{"oid":2598,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 c5 6.f3 d5 7.cxd5 Nxd5  8.Qd3 *","weight":57735,"prior_delay":25,"prior_completion_time":0},
{"oid":2599,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 c5 6.e3 Qa5 *","weight":57353,"prior_delay":25,"prior_completion_time":0},
{"oid":2600,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 O-O 6.e3 c5 7.Bd3 Nc6  8.Nf3 *","weight":57410,"prior_delay":25,"prior_completion_time":0},
{"oid":2601,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 O-O 6.f3 d5 7.cxd5 *","weight":57345,"prior_delay":25,"prior_completion_time":0},
{"oid":2602,"gid":419,"fullname":"Nimzo-Indian Defense, Samisch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.a3 Bxc3+ 5.bxc3 c5 6.e3 Nc6 7.Bd3 *","weight":57377,"prior_delay":25,"prior_completion_time":0},
{"oid":2603,"gid":420,"fullname":"Nimzo-Indian Defense, Simagin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Ne2 d5 6.a3 Bd6 *","weight":64047,"prior_delay":25,"prior_completion_time":0},
{"oid":2604,"gid":421,"fullname":"Nimzo-Indian Defense, Spielmann Variation, Karlsbad Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qb3 c5 5.dxc5 Nc6 6.Nf3 Ne4 7.Bd2 Nxd2 *","weight":57228,"prior_delay":25,"prior_completion_time":0},
{"oid":2605,"gid":421,"fullname":"Nimzo-Indian Defense, Spielmann Variation, Romanovsky Gambit","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qb3 c5 5.dxc5 Nc6 6.Bg5 *","weight":57221,"prior_delay":25,"prior_completion_time":0},
{"oid":2606,"gid":421,"fullname":"Nimzo-Indian Defense, Spielmann Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qb3 c5 5.dxc5 Na6 *","weight":57204,"prior_delay":25,"prior_completion_time":0},
{"oid":2607,"gid":421,"fullname":"Nimzo-Indian Defense, Spielmann Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qb3 Nc6 *","weight":57151,"prior_delay":25,"prior_completion_time":0},
{"oid":2608,"gid":421,"fullname":"Nimzo-Indian Defense, Spielmann Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Qb3 c5 5.Nf3 *","weight":57183,"prior_delay":25,"prior_completion_time":0},
{"oid":2609,"gid":422,"fullname":"Nimzo-Indian Defense, St. Petersburg Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 b6 5.Nf3 Bb7 6.Bd3 Ne4 7.O-O f5 *","weight":61377,"prior_delay":25,"prior_completion_time":0},
{"oid":2610,"gid":422,"fullname":"Nimzo-Indian Defense, St. Petersburg Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 b6 5.Bd3 *","weight":61060,"prior_delay":25,"prior_completion_time":0},
{"oid":2611,"gid":422,"fullname":"Nimzo-Indian Defense, St. Petersburg Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 b6 5.Nf3 Bb7 6.Bd3 O-O 7.O-O *","weight":61675,"prior_delay":25,"prior_completion_time":0},
{"oid":2612,"gid":423,"fullname":"Nimzo-Indian Defense, Three Knights Variation, Duchamp Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Nf3 b6 5.Qc2 Bb7 *","weight":58955,"prior_delay":25,"prior_completion_time":0},
{"oid":2613,"gid":423,"fullname":"Nimzo-Indian Defense, Three Knights Variation, Duchamp Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Nf3 b6 5.Qb3 a5 *","weight":58986,"prior_delay":25,"prior_completion_time":0},
{"oid":2614,"gid":423,"fullname":"Nimzo-Indian Defense, Three Knights Variation, Duchamp Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Nf3 b6 5.Bg5 Bb7 *","weight":59556,"prior_delay":25,"prior_completion_time":0},
{"oid":2615,"gid":423,"fullname":"Nimzo-Indian Defense, Three Knights Variation, Duchamp Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Nf3 b6 5.Qb3 c5 *","weight":59052,"prior_delay":25,"prior_completion_time":0},
{"oid":2616,"gid":423,"fullname":"Nimzo-Indian Defense, Three Knights Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Nf3 O-O *","weight":58453,"prior_delay":25,"prior_completion_time":0},
{"oid":2617,"gid":423,"fullname":"Nimzo-Indian Defense, Three Knights Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Nf3 Bxc3+ *","weight":58370,"prior_delay":25,"prior_completion_time":0},
{"oid":2618,"gid":412,"fullname":"Nimzo-Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.e3 O-O 5.Nf3 b6 *","weight":62691,"prior_delay":25,"prior_completion_time":0},
{"oid":2619,"gid":412,"fullname":"Nimzo-Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nc3 Bb4 4.Bd2 O-O 5.Nf3 *","weight":57168,"prior_delay":25,"prior_completion_time":0},
{"oid":2620,"gid":424,"fullname":"Old Benoni Defense, Main Line","main_line":"1.d4 c5 2.d5 e6 3.c4 exd5 4.cxd5 d6 5.Nc3 g6 6.e4 Bg7 7.Nf3 *","weight":12273,"prior_delay":25,"prior_completion_time":0},
{"oid":2621,"gid":424,"fullname":"Old Benoni Defense, Main Line","main_line":"1.d4 c5 2.d5 e6 3.c4 exd5 4.cxd5 d6 5.e4 *","weight":12136,"prior_delay":25,"prior_completion_time":0},
{"oid":2622,"gid":424,"fullname":"Old Benoni Defense, Main Line","main_line":"1.d4 c5 2.d5 e5 3.c4 d6 4.e4 g6 5.Nc3 Bg7 6.Nf3 *","weight":11955,"prior_delay":25,"prior_completion_time":0},
{"oid":2623,"gid":424,"fullname":"Old Benoni Defense, Main Line","main_line":"1.d4 c5 2.d5 e5 3.c4 d6 4.e4 Be7 5.Nc3 *","weight":11968,"prior_delay":25,"prior_completion_time":0},
{"oid":2624,"gid":425,"fullname":"Old Indian, Czech Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 c6 *","weight":30734,"prior_delay":25,"prior_completion_time":0},
{"oid":2625,"gid":425,"fullname":"Old Indian, Czech Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nf3 c6 *","weight":30641,"prior_delay":25,"prior_completion_time":0},
{"oid":2626,"gid":426,"fullname":"Old Indian Defense, Dus-Khotimirsky Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.e3 Nbd7 5.Bd3 *","weight":30823,"prior_delay":25,"prior_completion_time":0},
{"oid":2627,"gid":427,"fullname":"Old Indian Defense, Janowski Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 Bf5 *","weight":30761,"prior_delay":25,"prior_completion_time":0},
{"oid":2628,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.Be2 c6 7.O-O O-O  8.Qc2 Qc7 *","weight":31328,"prior_delay":25,"prior_completion_time":0},
{"oid":2629,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.Be2 c6 7.O-O O-O  8.Qc2 Re8 *","weight":31330,"prior_delay":25,"prior_completion_time":0},
{"oid":2630,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.Be2 c6 7.O-O O-O 8.Be3 a6 *","weight":31353,"prior_delay":25,"prior_completion_time":0},
{"oid":2631,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.Be2 c6 7.O-O a6 *","weight":31269,"prior_delay":25,"prior_completion_time":0},
{"oid":2632,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.Be2 c6 7.O-O O-O  8.Qc2 a6 *","weight":31338,"prior_delay":25,"prior_completion_time":0},
{"oid":2633,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.g3 c6 7.Bg2 O-O 8.O-O Qc7 *","weight":31112,"prior_delay":25,"prior_completion_time":0},
{"oid":2634,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.Be2 O-O *","weight":31208,"prior_delay":25,"prior_completion_time":0},
{"oid":2635,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.g3 c6 7.Bg2 O-O 8.O-O Re8 *","weight":31114,"prior_delay":25,"prior_completion_time":0},
{"oid":2636,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 g6 *","weight":31086,"prior_delay":25,"prior_completion_time":0},
{"oid":2637,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.g3 O-O *","weight":31106,"prior_delay":25,"prior_completion_time":0},
{"oid":2638,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.Be2 c6 7.O-O O-O  8.Re1 Re8 *","weight":31324,"prior_delay":25,"prior_completion_time":0},
{"oid":2639,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.Be2 c6 7.O-O O-O  8.Re1 a6 9.Bf1 *","weight":31344,"prior_delay":25,"prior_completion_time":0},
{"oid":2640,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.Be2 c6 7.O-O O-O 8.h3 *","weight":31300,"prior_delay":25,"prior_completion_time":0},
{"oid":2641,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 Be7 6.g3 c6 7.Bg2 O-O 8.O-O a6  9.a4 *","weight":31135,"prior_delay":25,"prior_completion_time":0},
{"oid":2642,"gid":429,"fullname":"Old Indian Defense, Normal Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e4 c6 6.Be2 *","weight":31169,"prior_delay":25,"prior_completion_time":0},
{"oid":2643,"gid":430,"fullname":"Old Indian Defense, Tartakower-Indian","main_line":"1.d4 Nf6 2.c4 d6 3.Nf3 Bg4 4.Qb3 *","weight":30645,"prior_delay":25,"prior_completion_time":0},
{"oid":2644,"gid":430,"fullname":"Old Indian Defense, Tartakower-Indian","main_line":"1.d4 Nf6 2.c4 d6 3.Nf3 Bg4 4.Nc3 *","weight":30660,"prior_delay":25,"prior_completion_time":0},
{"oid":2645,"gid":431,"fullname":"Old Indian Defense, Two Knights Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nc6 *","weight":30913,"prior_delay":25,"prior_completion_time":0},
{"oid":2646,"gid":431,"fullname":"Old Indian Defense, Two Knights Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 exd4 *","weight":30915,"prior_delay":25,"prior_completion_time":0},
{"oid":2647,"gid":431,"fullname":"Old Indian Defense, Two Knights Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.Bg5 Be7 6.e3 O-O 7.Qc2 c6 *","weight":31060,"prior_delay":25,"prior_completion_time":0},
{"oid":2648,"gid":431,"fullname":"Old Indian Defense, Two Knights Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.g3 g6 6.Bg2 Bg7 *","weight":31005,"prior_delay":25,"prior_completion_time":0},
{"oid":2649,"gid":431,"fullname":"Old Indian Defense, Two Knights Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.Bg5 c6 *","weight":30994,"prior_delay":25,"prior_completion_time":0},
{"oid":2650,"gid":431,"fullname":"Old Indian Defense, Two Knights Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.Bg5 Be7 6.e3 c6 *","weight":31048,"prior_delay":25,"prior_completion_time":0},
{"oid":2651,"gid":431,"fullname":"Old Indian Defense, Two Knights Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.g3 c6 6.Bg2 *","weight":31010,"prior_delay":25,"prior_completion_time":0},
{"oid":2652,"gid":431,"fullname":"Old Indian Defense, Two Knights Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.e3 *","weight":30957,"prior_delay":25,"prior_completion_time":0},
{"oid":2653,"gid":431,"fullname":"Old Indian Defense, Two Knights Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 e4 5.Ng5 *","weight":30965,"prior_delay":25,"prior_completion_time":0},
{"oid":2654,"gid":431,"fullname":"Old Indian Defense, Two Knights Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.Nf3 Nbd7 5.g3 Be7 6.Bg2 O-O 7.O-O c6 8.Qc2 *","weight":31078,"prior_delay":25,"prior_completion_time":0},
{"oid":2655,"gid":432,"fullname":"Old Indian Defense, Ukrainian Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.dxe5 dxe5 5.Qxd8+ Kxd8 6.Nf3 Nfd7 *","weight":30840,"prior_delay":25,"prior_completion_time":0},
{"oid":2656,"gid":432,"fullname":"Old Indian Defense, Ukrainian Variation","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 e5 4.d5 Be7 *","weight":30826,"prior_delay":25,"prior_completion_time":0},
{"oid":2657,"gid":428,"fullname":"Old Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 d6 3.Nf3 Nbd7 *","weight":30712,"prior_delay":25,"prior_completion_time":0},
{"oid":2658,"gid":428,"fullname":"Old Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 d6 3.Nf3 Bf5 *","weight":30624,"prior_delay":25,"prior_completion_time":0},
{"oid":2659,"gid":428,"fullname":"Old Indian Defense, Main Line","main_line":"1.d4 d6 2.c4 g6 3.e4 Bg7 *","weight":12490,"prior_delay":25,"prior_completion_time":0},
{"oid":2660,"gid":428,"fullname":"Old Indian Defense, Main Line","main_line":"1.d4 d6 2.c4 g6 3.Nf3 Bg7 4.g3 *","weight":12762,"prior_delay":25,"prior_completion_time":0},
{"oid":2661,"gid":428,"fullname":"Old Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 Nbd7 4.e4 e5 5.d5 *","weight":30956,"prior_delay":25,"prior_completion_time":0},
{"oid":2662,"gid":428,"fullname":"Old Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 Nbd7 4.e4 e5 5.Nge2 *","weight":30945,"prior_delay":25,"prior_completion_time":0},
{"oid":2663,"gid":428,"fullname":"Old Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 d6 3.Nc3 Nbd7 4.Nf3 *","weight":30865,"prior_delay":25,"prior_completion_time":0},
{"oid":2664,"gid":433,"fullname":"Pterodactyl Defense, Main Line","main_line":"1.d4 d6 2.c4 g6 3.Nc3 Bg7 4.e4 c5 5.Nf3 Qa5 *","weight":12679,"prior_delay":25,"prior_completion_time":0},
{"oid":2665,"gid":434,"fullname":"Queen's Gambit Accepted, Alekhine Defense, Alatortsev Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 a6 4.e3 Bg4 5.Bxc4 e6 6.d5 *","weight":33112,"prior_delay":25,"prior_completion_time":0},
{"oid":2666,"gid":434,"fullname":"Queen's Gambit Accepted, Alekhine Defense, Borisenko-Furman Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 a6 4.e4 *","weight":32816,"prior_delay":25,"prior_completion_time":0},
{"oid":2667,"gid":434,"fullname":"Queen's Gambit Accepted, Alekhine Defense, Haberditz Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 a6 4.e3 b5 *","weight":33126,"prior_delay":25,"prior_completion_time":0},
{"oid":2668,"gid":434,"fullname":"Queen's Gambit Accepted, Alekhine Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 a6 4.a4 *","weight":32826,"prior_delay":25,"prior_completion_time":0},
{"oid":2669,"gid":434,"fullname":"Queen's Gambit Accepted, Alekhine Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 a6 4.e3 e6 5.Bxc4 c5 6.Qe2 *","weight":33338,"prior_delay":25,"prior_completion_time":0},
{"oid":2670,"gid":434,"fullname":"Queen's Gambit Accepted, Alekhine Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 a6 4.e3 Bg4 5.Bxc4 e6 6.Nc3 *","weight":33114,"prior_delay":25,"prior_completion_time":0},
{"oid":2671,"gid":434,"fullname":"Queen's Gambit Accepted, Alekhine Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 a6 4.e3 Bg4 5.Bxc4 e6 6.h3 *","weight":33120,"prior_delay":25,"prior_completion_time":0},
{"oid":2672,"gid":435,"fullname":"Queen's Gambit Accepted, Bogoljubov Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Nc3 a6 5.e4 b5 6.e5 Nd5 7.a4 Nb4 *","weight":33742,"prior_delay":25,"prior_completion_time":0},
{"oid":2673,"gid":435,"fullname":"Queen's Gambit Accepted, Bogoljubov Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Nc3 a6 5.e4 b5 6.e5 Nd5 7.a4 Nxc3 8.bxc3 Qd5  9.g3 Bb7 *","weight":33800,"prior_delay":25,"prior_completion_time":0},
{"oid":2674,"gid":435,"fullname":"Queen's Gambit Accepted, Bogoljubov Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Nc3 a6 5.e4 b5 6.e5 Nd5 7.a4 e6 *","weight":33785,"prior_delay":25,"prior_completion_time":0},
{"oid":2675,"gid":435,"fullname":"Queen's Gambit Accepted, Bogoljubov Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Nc3 a6 5.e4 b5 6.e5 Nd5 7.a4 Bb7 *","weight":33745,"prior_delay":25,"prior_completion_time":0},
{"oid":2676,"gid":435,"fullname":"Queen's Gambit Accepted, Bogoljubov Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Nc3 a6 5.e4 b5 6.e5 Nd5 7.a4 Nxc3 8.bxc3 Bb7 *","weight":33769,"prior_delay":25,"prior_completion_time":0},
{"oid":2677,"gid":436,"fullname":"Queen's Gambit Accepted, Central Variation, Alekhine System","main_line":"1.d4 d5 2.c4 dxc4 3.e4 Nf6 4.e5 Nd5 5.Bxc4 Nb6 6.Bd3 Nc6 *","weight":34377,"prior_delay":25,"prior_completion_time":0},
{"oid":2678,"gid":436,"fullname":"Queen's Gambit Accepted, Central Variation, Alekhine System","main_line":"1.d4 d5 2.c4 dxc4 3.e4 Nf6 4.Nc3 *","weight":32693,"prior_delay":25,"prior_completion_time":0},
{"oid":2679,"gid":436,"fullname":"Queen's Gambit Accepted, Central Variation, Alekhine System","main_line":"1.d4 d5 2.c4 dxc4 3.e4 Nf6 4.e5 Nd5 5.Bxc4 Nb6 6.Bb3 Nc6 7.Ne2 *","weight":34355,"prior_delay":25,"prior_completion_time":0},
{"oid":2680,"gid":436,"fullname":"Queen's Gambit Accepted, Central Variation, McDonnell Defense","main_line":"1.d4 d5 2.c4 dxc4 3.e4 e5 4.Nf3 Bb4+ *","weight":33122,"prior_delay":25,"prior_completion_time":0},
{"oid":2681,"gid":436,"fullname":"Queen's Gambit Accepted, Central Variation, McDonnell Defense","main_line":"1.d4 d5 2.c4 dxc4 3.e4 e5 4.Nf3 exd4 5.Bxc4 Nc6 *","weight":33505,"prior_delay":25,"prior_completion_time":0},
{"oid":2682,"gid":436,"fullname":"Queen's Gambit Accepted, Central Variation, Modern Defense","main_line":"1.d4 d5 2.c4 dxc4 3.e4 Nc6 4.Be3 *","weight":32527,"prior_delay":25,"prior_completion_time":0},
{"oid":2683,"gid":436,"fullname":"Queen's Gambit Accepted, Central Variation, Modern Defense","main_line":"1.d4 d5 2.c4 dxc4 3.e4 Nc6 4.Nf3 *","weight":32596,"prior_delay":25,"prior_completion_time":0},
{"oid":2684,"gid":436,"fullname":"Queen's Gambit Accepted, Central Variation, Rubinstein Defense","main_line":"1.d4 d5 2.c4 dxc4 3.e4 c5 4.Nf3 *","weight":32462,"prior_delay":25,"prior_completion_time":0},
{"oid":2685,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bb3 Bb7  9.Rd1 Nbd7 10.e4 *","weight":38473,"prior_delay":25,"prior_completion_time":0},
{"oid":2686,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bb3 Bb7  9.Rd1 Nbd7 10.Nc3 Qb8 *","weight":38518,"prior_delay":25,"prior_completion_time":0},
{"oid":2687,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bb3 Bb7  9.a4 b4 *","weight":38448,"prior_delay":25,"prior_completion_time":0},
{"oid":2688,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bb3 Bb7  9.Nc3 *","weight":38413,"prior_delay":25,"prior_completion_time":0},
{"oid":2689,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bb3 Bb7  9.Rd1 Nbd7 10.Nc3 Qc7 *","weight":38498,"prior_delay":25,"prior_completion_time":0},
{"oid":2690,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bb3 Bb7  9.a4 Nbd7 *","weight":38439,"prior_delay":25,"prior_completion_time":0},
{"oid":2691,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bb3 Bb7  9.Rd1 Nbd7 10.a4 b4 *","weight":38476,"prior_delay":25,"prior_completion_time":0},
{"oid":2692,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bb3 Bb7  9.Rd1 Nbd7 10.Nc3 Qb6 *","weight":38511,"prior_delay":25,"prior_completion_time":0},
{"oid":2693,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System, Smyslov Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bb3 Bb7  9.Rd1 Nbd7 10.Nc3 Bd6 *","weight":38496,"prior_delay":25,"prior_completion_time":0},
{"oid":2694,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 Nc6 *","weight":38217,"prior_delay":25,"prior_completion_time":0},
{"oid":2695,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 cxd4 *","weight":38218,"prior_delay":25,"prior_completion_time":0},
{"oid":2696,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System (Except Main Line)","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bb3 Nc6  9.Nc3 Be7 *","weight":38357,"prior_delay":25,"prior_completion_time":0},
{"oid":2697,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System (Except Main Line)","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bb3 Nc6  9.Nc3 Bb7 *","weight":38360,"prior_delay":25,"prior_completion_time":0},
{"oid":2698,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Alekhine System (Except Main Line)","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Qe2 b5 8.Bd3 cxd4  9.exd4 *","weight":38368,"prior_delay":25,"prior_completion_time":0},
{"oid":2699,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Bb3 cxd4 *","weight":38565,"prior_delay":25,"prior_completion_time":0},
{"oid":2700,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Bb3 b5 *","weight":38566,"prior_delay":25,"prior_completion_time":0},
{"oid":2701,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Bd3 Nbd7 *","weight":38266,"prior_delay":25,"prior_completion_time":0},
{"oid":2702,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.a3 *","weight":38123,"prior_delay":25,"prior_completion_time":0},
{"oid":2703,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Nc3 *","weight":38162,"prior_delay":25,"prior_completion_time":0},
{"oid":2704,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.Bb3 Nc6 8.Nc3 *","weight":38723,"prior_delay":25,"prior_completion_time":0},
{"oid":2705,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.b3 *","weight":38178,"prior_delay":25,"prior_completion_time":0},
{"oid":2706,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.dxc5 Qxd1  8.Rxd1 Bxc5 9.b3 *","weight":39106,"prior_delay":25,"prior_completion_time":0},
{"oid":2707,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.dxc5 Qxd1  8.Rxd1 Bxc5 9.Nbd2 *","weight":39137,"prior_delay":25,"prior_completion_time":0},
{"oid":2708,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Rubinstein Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.a4 Nc6 8.Qe2 Qc7 *","weight":38550,"prior_delay":25,"prior_completion_time":0},
{"oid":2709,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Rubinstein Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.a4 Nc6 8.Qe2 cxd4  9.Rd1 Be7 10.exd4 O-O 11.Nc3 Nd5 *","weight":38980,"prior_delay":25,"prior_completion_time":0},
{"oid":2710,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Rubinstein Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.a4 Nc6 8.Nc3 Be7  9.Qe2 *","weight":38645,"prior_delay":25,"prior_completion_time":0},
{"oid":2711,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Russian Gambit","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O a6 7.e4 *","weight":38136,"prior_delay":25,"prior_completion_time":0},
{"oid":2712,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Steinitz Variation, Development Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O Nc6 7.Qe2 *","weight":37569,"prior_delay":25,"prior_completion_time":0},
{"oid":2713,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Steinitz Variation, Development Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O Nc6 7.Nc3 *","weight":37543,"prior_delay":25,"prior_completion_time":0},
{"oid":2714,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense, Steinitz Variation, Exchange Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.O-O cxd4 *","weight":37490,"prior_delay":25,"prior_completion_time":0},
{"oid":2715,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.Qe2 cxd4 *","weight":36712,"prior_delay":25,"prior_completion_time":0},
{"oid":2716,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.Qe2 a6 7.dxc5 Bxc5 8.O-O  Nc6 *","weight":37111,"prior_delay":25,"prior_completion_time":0},
{"oid":2717,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.Nc3 a6 *","weight":36625,"prior_delay":25,"prior_completion_time":0},
{"oid":2718,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.Qe2 a6 7.dxc5 Bxc5 8.O-O  b5 *","weight":37089,"prior_delay":25,"prior_completion_time":0},
{"oid":2719,"gid":437,"fullname":"Queen's Gambit Accepted, Classical Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 c5 6.dxc5 *","weight":36590,"prior_delay":25,"prior_completion_time":0},
{"oid":2720,"gid":438,"fullname":"Queen's Gambit Accepted, Godes Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nd7 *","weight":32591,"prior_delay":25,"prior_completion_time":0},
{"oid":2721,"gid":439,"fullname":"Queen's Gambit Accepted, Gunsberg Defense, Prianishenmo Gambit","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Nc3 c5 5.d5 e6 6.e4 exd5 7.e5 Nfd7 8.Bg5 Be7 *","weight":33542,"prior_delay":25,"prior_completion_time":0},
{"oid":2722,"gid":439,"fullname":"Queen's Gambit Accepted, Gunsberg Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 c5 4.d5 e6 5.Nc3 exd5 6.Qxd5 Qxd5 7.Nxd5 Bd6  8.Nd2 Ne7 *","weight":32844,"prior_delay":25,"prior_completion_time":0},
{"oid":2723,"gid":439,"fullname":"Queen's Gambit Accepted, Gunsberg Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 c5 4.e3 cxd4 5.Bxc4 *","weight":32753,"prior_delay":25,"prior_completion_time":0},
{"oid":2724,"gid":439,"fullname":"Queen's Gambit Accepted, Gunsberg Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 c5 4.Nc3 *","weight":32663,"prior_delay":25,"prior_completion_time":0},
{"oid":2725,"gid":439,"fullname":"Queen's Gambit Accepted, Gunsberg Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 c5 4.d5 e6 5.e4 *","weight":32718,"prior_delay":25,"prior_completion_time":0},
{"oid":2726,"gid":440,"fullname":"Queen's Gambit Accepted, Janowski-Larsen Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 Bg4 5.Bxc4 e6 6.h3 Bh5 7.Nc3 Nbd7  8.O-O Bd6 9.Be2 *","weight":34881,"prior_delay":25,"prior_completion_time":0},
{"oid":2727,"gid":440,"fullname":"Queen's Gambit Accepted, Janowski-Larsen Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 Bg4 5.Bxc4 e6 6.Qb3 *","weight":34669,"prior_delay":25,"prior_completion_time":0},
{"oid":2728,"gid":440,"fullname":"Queen's Gambit Accepted, Janowski-Larsen Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 Bg4 5.h3 *","weight":34398,"prior_delay":25,"prior_completion_time":0},
{"oid":2729,"gid":440,"fullname":"Queen's Gambit Accepted, Janowski-Larsen Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 Bg4 5.Bxc4 e6 6.O-O *","weight":34675,"prior_delay":25,"prior_completion_time":0},
{"oid":2730,"gid":440,"fullname":"Queen's Gambit Accepted, Janowski-Larsen Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 Bg4 5.Bxc4 e6 6.Nc3 *","weight":34719,"prior_delay":25,"prior_completion_time":0},
{"oid":2731,"gid":440,"fullname":"Queen's Gambit Accepted, Janowski-Larsen Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 Bg4 5.Bxc4 e6 6.h3 Bh5 7.Nc3 a6 8.g4 *","weight":34877,"prior_delay":25,"prior_completion_time":0},
{"oid":2732,"gid":440,"fullname":"Queen's Gambit Accepted, Janowski-Larsen Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 Bg4 5.Bxc4 e6 6.h3 Bh5 7.Nc3 Nbd7  8.O-O Bd6 9.e4 *","weight":34886,"prior_delay":25,"prior_completion_time":0},
{"oid":2733,"gid":441,"fullname":"Queen's Gambit Accepted, Linares Variation","main_line":"1.d4 d5 2.c4 dxc4 3.e4 c5 4.d5 Nf6 5.Nc3 b5 *","weight":32630,"prior_delay":25,"prior_completion_time":0},
{"oid":2734,"gid":443,"fullname":"Queen's Gambit Accepted, Mannheim Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Qa4+ c6 5.Qxc4 Bf5 6.g3 e6 7.Bg2 Nbd7  8.O-O Be7 9.Nc3 O-O 10.Bg5 *","weight":34950,"prior_delay":25,"prior_completion_time":0},
{"oid":2735,"gid":443,"fullname":"Queen's Gambit Accepted, Mannheim Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Qa4+ Nc6 5.Nc3 *","weight":33216,"prior_delay":25,"prior_completion_time":0},
{"oid":2736,"gid":443,"fullname":"Queen's Gambit Accepted, Mannheim Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Qa4+ c6 5.Qxc4 Bf5 6.g3 e6 7.Bg2 Nbd7  8.O-O Be7 9.Nc3 O-O 10.e3 Ne4 11.Qe2 *","weight":35025,"prior_delay":25,"prior_completion_time":0},
{"oid":2737,"gid":443,"fullname":"Queen's Gambit Accepted, Mannheim Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Qa4+ c6 5.Qxc4 Bf5 6.Nc3 *","weight":33570,"prior_delay":25,"prior_completion_time":0},
{"oid":2738,"gid":443,"fullname":"Queen's Gambit Accepted, Mannheim Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Qa4+ Nbd7 5.Nc3 e6 6.e4 *","weight":33233,"prior_delay":25,"prior_completion_time":0},
{"oid":2739,"gid":444,"fullname":"Queen's Gambit Accepted, Normal Variation, Traditional System","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 e6 5.Bxc4 a6 6.O-O b5 *","weight":36555,"prior_delay":25,"prior_completion_time":0},
{"oid":2740,"gid":444,"fullname":"Queen's Gambit Accepted, Normal Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Bg4 *","weight":32591,"prior_delay":25,"prior_completion_time":0},
{"oid":2741,"gid":444,"fullname":"Queen's Gambit Accepted, Normal Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 c5 *","weight":34286,"prior_delay":25,"prior_completion_time":0},
{"oid":2742,"gid":444,"fullname":"Queen's Gambit Accepted, Normal Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 a6 *","weight":34327,"prior_delay":25,"prior_completion_time":0},
{"oid":2743,"gid":445,"fullname":"Queen's Gambit Accepted, Old Variation","main_line":"1.d4 d5 2.c4 dxc4 3.e3 e5 4.Bxc4 exd4 5.exd4 Nf6 *","weight":32575,"prior_delay":25,"prior_completion_time":0},
{"oid":2744,"gid":445,"fullname":"Queen's Gambit Accepted, Old Variation","main_line":"1.d4 d5 2.c4 dxc4 3.e3 e6 4.Bxc4 Nf6 *","weight":32237,"prior_delay":25,"prior_completion_time":0},
{"oid":2745,"gid":445,"fullname":"Queen's Gambit Accepted, Old Variation","main_line":"1.d4 d5 2.c4 dxc4 3.e3 Nf6 *","weight":32285,"prior_delay":25,"prior_completion_time":0},
{"oid":2746,"gid":445,"fullname":"Queen's Gambit Accepted, Old Variation","main_line":"1.d4 d5 2.c4 dxc4 3.e3 c5 4.Bxc4 cxd4 5.exd4 *","weight":32118,"prior_delay":25,"prior_completion_time":0},
{"oid":2747,"gid":446,"fullname":"Queen's Gambit Accepted, Rosenthal Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 e6 4.e3 c5 *","weight":33054,"prior_delay":25,"prior_completion_time":0},
{"oid":2748,"gid":446,"fullname":"Queen's Gambit Accepted, Rosenthal Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 e6 4.Qa4+ *","weight":32739,"prior_delay":25,"prior_completion_time":0},
{"oid":2749,"gid":446,"fullname":"Queen's Gambit Accepted, Rosenthal Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 e6 4.e4 *","weight":32728,"prior_delay":25,"prior_completion_time":0},
{"oid":2750,"gid":447,"fullname":"Queen's Gambit Accepted, Showalter Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Nc3 a6 5.a4 Nc6 6.e4 *","weight":33428,"prior_delay":25,"prior_completion_time":0},
{"oid":2751,"gid":448,"fullname":"Queen's Gambit Accepted, Smyslov Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 g6 5.Bxc4 Bg7 6.Nc3 *","weight":34357,"prior_delay":25,"prior_completion_time":0},
{"oid":2752,"gid":448,"fullname":"Queen's Gambit Accepted, Smyslov Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 g6 5.Bxc4 Bg7 6.O-O O-O 7.Nc3 *","weight":34427,"prior_delay":25,"prior_completion_time":0},
{"oid":2753,"gid":449,"fullname":"Queen's Gambit Accepted, Winawer Defense","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.e3 Be6 5.Nc3 c6 *","weight":34302,"prior_delay":25,"prior_completion_time":0},
{"oid":2754,"gid":442,"fullname":"Queen's Gambit Accepted, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nc3 a6 4.a4 *","weight":31397,"prior_delay":25,"prior_completion_time":0},
{"oid":2755,"gid":442,"fullname":"Queen's Gambit Accepted, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nc3 e5 *","weight":31395,"prior_delay":25,"prior_completion_time":0},
{"oid":2756,"gid":442,"fullname":"Queen's Gambit Accepted, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nc3 Nf6 *","weight":31392,"prior_delay":25,"prior_completion_time":0},
{"oid":2757,"gid":442,"fullname":"Queen's Gambit Accepted, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.g3 *","weight":33138,"prior_delay":25,"prior_completion_time":0},
{"oid":2758,"gid":442,"fullname":"Queen's Gambit Accepted, Main Line","main_line":"1.d4 d5 2.c4 dxc4 3.Nc3 e6 4.e4 *","weight":31398,"prior_delay":25,"prior_completion_time":0},
{"oid":2759,"gid":450,"fullname":"Queen's Gambit Declined, Albin Countergambit, Balogh Variation","main_line":"1.d4 d5 2.c4 e5 3.dxe5 d4 4.Nf3 Nc6 5.Nbd2 Qe7 *","weight":29427,"prior_delay":25,"prior_completion_time":0},
{"oid":2760,"gid":451,"fullname":"Queen's Gambit Declined, Anti-Tartakower Variation, Petrosian Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bxf6 Bxf6 8.Rc1 c6  9.Bd3 Nd7 10.O-O dxc4 11.Bxc4 *","weight":40555,"prior_delay":25,"prior_completion_time":0},
{"oid":2761,"gid":451,"fullname":"Queen's Gambit Declined, Anti-Tartakower Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bxf6 Bxf6 8.Qb3 *","weight":39683,"prior_delay":25,"prior_completion_time":0},
{"oid":2762,"gid":452,"fullname":"Queen's Gambit Declined, Barmen Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Nbd7 5.Bf4 *","weight":39049,"prior_delay":25,"prior_completion_time":0},
{"oid":2763,"gid":453,"fullname":"Queen's Gambit Declined, Been-Koomen Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 c5 5.e3 *","weight":38333,"prior_delay":25,"prior_completion_time":0},
{"oid":2764,"gid":454,"fullname":"Queen's Gambit Declined, Cambridge Springs Defense, Bogoljubov Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Nbd7 5.e3 c6 6.Nf3 Qa5 7.Nd2 Bb4  8.Qc2 O-O 9.Be2 e5 *","weight":39158,"prior_delay":25,"prior_completion_time":0},
{"oid":2765,"gid":454,"fullname":"Queen's Gambit Declined, Cambridge Springs Defense, Bogoljubov Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Nbd7 5.e3 c6 6.Nf3 Qa5 7.Nd2 Bb4  8.Qc2 dxc4 *","weight":39044,"prior_delay":25,"prior_completion_time":0},
{"oid":2766,"gid":454,"fullname":"Queen's Gambit Declined, Cambridge Springs Defense, Capablanca Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Nbd7 5.e3 c6 6.Nf3 Qa5 7.Bxf6 *","weight":38767,"prior_delay":25,"prior_completion_time":0},
{"oid":2767,"gid":454,"fullname":"Queen's Gambit Declined, Cambridge Springs Defense, Rubinstein Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Nbd7 5.e3 c6 6.Nf3 Qa5 7.Nd2 dxc4 *","weight":38931,"prior_delay":25,"prior_completion_time":0},
{"oid":2768,"gid":454,"fullname":"Queen's Gambit Declined, Cambridge Springs Defense, Yugoslav Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Nbd7 5.e3 c6 6.Nf3 Qa5 7.cxd5 Nxd5 8.Qd2 *","weight":39230,"prior_delay":25,"prior_completion_time":0},
{"oid":2769,"gid":455,"fullname":"Queen's Gambit Declined, Capablanca, Anti-Cambridge Springs Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Nbd7 5.e3 c6 6.a3 *","weight":38483,"prior_delay":25,"prior_completion_time":0},
{"oid":2770,"gid":455,"fullname":"Queen's Gambit Declined, Capablanca","main_line":"1.d4 d5 2.c4 e6 3.Nf3 Nf6 4.Bg5 h6 *","weight":38706,"prior_delay":25,"prior_completion_time":0},
{"oid":2771,"gid":456,"fullname":"Queen's Gambit Declined, Capablanca Variation","main_line":"1.d4 d5 2.c4 e6 3.Nf3 Nf6 4.Bg5 Nbd7 5.e3 c6 6.Nbd2 *","weight":38655,"prior_delay":25,"prior_completion_time":0},
{"oid":2772,"gid":457,"fullname":"Queen's Gambit Declined, Charousek (Petrosian) Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Be7 4.cxd5 exd5 5.Bf4 c6 6.e3 Bf5 7.g4 *","weight":40095,"prior_delay":25,"prior_completion_time":0},
{"oid":2773,"gid":457,"fullname":"Queen's Gambit Declined, Charousek (Petrosian) Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Be7 4.Bf4 *","weight":38165,"prior_delay":25,"prior_completion_time":0},
{"oid":2774,"gid":457,"fullname":"Queen's Gambit Declined, Charousek (Petrosian) Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Be7 4.Nf3 *","weight":38848,"prior_delay":25,"prior_completion_time":0},
{"oid":2775,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.e3 Be7 7.Bd3 Ne4 *","weight":41580,"prior_delay":25,"prior_completion_time":0},
{"oid":2776,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 Be7 6.e3 Nbd7 *","weight":41037,"prior_delay":25,"prior_completion_time":0},
{"oid":2777,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.e3 Nbd7 *","weight":41306,"prior_delay":25,"prior_completion_time":0},
{"oid":2778,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 Be7 6.e3 O-O *","weight":41089,"prior_delay":25,"prior_completion_time":0},
{"oid":2779,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 Be7 6.e3 h6 *","weight":41013,"prior_delay":25,"prior_completion_time":0},
{"oid":2780,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Nf3 *","weight":40881,"prior_delay":25,"prior_completion_time":0},
{"oid":2781,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 Be7 6.Nf3 *","weight":40666,"prior_delay":25,"prior_completion_time":0},
{"oid":2782,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.e3 Be7 7.Nf3 Bf5 8.Bd3 *","weight":41489,"prior_delay":25,"prior_completion_time":0},
{"oid":2783,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.e3 Be7 7.Bd3 O-O 8.Nf3 *","weight":41597,"prior_delay":25,"prior_completion_time":0},
{"oid":2784,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 Nbd7 6.Nf3 *","weight":40491,"prior_delay":25,"prior_completion_time":0},
{"oid":2785,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.e3 Be7 7.Bd3 Nbd7 8.Nge2 *","weight":41743,"prior_delay":25,"prior_completion_time":0},
{"oid":2786,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 Nbd7 6.e3 *","weight":40544,"prior_delay":25,"prior_completion_time":0},
{"oid":2787,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Positional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.e3 Be7 7.Bd3 Nbd7 8.Nf3 *","weight":41720,"prior_delay":25,"prior_completion_time":0},
{"oid":2788,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.e3 Nbd7  8.Bd3 O-O 9.Nf3 Re8 10.O-O Nf8 11.h3 g6 *","weight":42343,"prior_delay":25,"prior_completion_time":0},
{"oid":2789,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.e3 Nbd7  8.Bd3 Nf8 *","weight":41542,"prior_delay":25,"prior_completion_time":0},
{"oid":2790,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.e3 Nbd7  8.Bd3 O-O 9.Nf3 Re8 10.O-O Nf8 11.Rab1 a5 *","weight":42254,"prior_delay":25,"prior_completion_time":0},
{"oid":2791,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Na6 *","weight":41080,"prior_delay":25,"prior_completion_time":0},
{"oid":2792,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.Nf3 Nbd7 *","weight":41142,"prior_delay":25,"prior_completion_time":0},
{"oid":2793,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.Nf3 O-O *","weight":41134,"prior_delay":25,"prior_completion_time":0},
{"oid":2794,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.Nf3 g6 8.e3 *","weight":41292,"prior_delay":25,"prior_completion_time":0},
{"oid":2795,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.e3 Nbd7  8.Bd3 O-O 9.Nf3 Re8 10.O-O-O Nf8 11.h3 *","weight":41879,"prior_delay":25,"prior_completion_time":0},
{"oid":2796,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.e3 O-O 8.Bd3 *","weight":41284,"prior_delay":25,"prior_completion_time":0},
{"oid":2797,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.e3 Nbd7  8.Bd3 O-O 9.Nf3 Re8 10.O-O h6 11.Bh4 *","weight":42032,"prior_delay":25,"prior_completion_time":0},
{"oid":2798,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.e3 Nbd7  8.Bd3 O-O 9.Nge2 Re8 10.O-O Nf8 11.f3 *","weight":41842,"prior_delay":25,"prior_completion_time":0},
{"oid":2799,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.e3 Nbd7  8.Bd3 O-O 9.Nf3 Re8 10.h3 Nf8 11.Bf4 *","weight":42157,"prior_delay":25,"prior_completion_time":0},
{"oid":2800,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.e3 Nbd7  8.Bd3 O-O 9.Nf3 Re8 10.O-O Nf8 11.Rae1 *","weight":42226,"prior_delay":25,"prior_completion_time":0},
{"oid":2801,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Reshevsky Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bg5 c6 6.Qc2 Be7 7.e3 Nbd7 8.Nf3 *","weight":41378,"prior_delay":25,"prior_completion_time":0},
{"oid":2802,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation, Samisch Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Nf3 Nbd7 6.Bf4 *","weight":39674,"prior_delay":25,"prior_completion_time":0},
{"oid":2803,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Nf3 Be7 6.Bf4 c6 7.Qc2 Nbd7 *","weight":39571,"prior_delay":25,"prior_completion_time":0},
{"oid":2804,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 Nxd5 *","weight":39022,"prior_delay":25,"prior_completion_time":0},
{"oid":2805,"gid":458,"fullname":"Queen's Gambit Declined, Exchange Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.cxd5 exd5 5.Bf4 *","weight":39543,"prior_delay":25,"prior_completion_time":0},
{"oid":2806,"gid":459,"fullname":"Queen's Gambit Declined, Harrwitz Attack, Fianchetto Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Be7 5.Bf4 O-O 6.e3 b6 *","weight":45034,"prior_delay":25,"prior_completion_time":0},
{"oid":2807,"gid":459,"fullname":"Queen's Gambit Declined, Harrwitz Attack, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Be7 5.Bf4 O-O 6.e3 c5 7.dxc5 Bxc5 8.cxd5 *","weight":46514,"prior_delay":25,"prior_completion_time":0},
{"oid":2808,"gid":459,"fullname":"Queen's Gambit Declined, Harrwitz Attack, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Be7 5.Bf4 O-O 6.e3 c5 7.dxc5 Bxc5 8.a3 *","weight":46563,"prior_delay":25,"prior_completion_time":0},
{"oid":2809,"gid":459,"fullname":"Queen's Gambit Declined, Harrwitz Attack, Orthodox Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Be7 5.Bf4 O-O 6.e3 c6 *","weight":44965,"prior_delay":25,"prior_completion_time":0},
{"oid":2810,"gid":459,"fullname":"Queen's Gambit Declined, Harrwitz Attack, Two Knights Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Be7 5.Bf4 O-O 6.e3 Nbd7 *","weight":45529,"prior_delay":25,"prior_completion_time":0},
{"oid":2811,"gid":459,"fullname":"Queen's Gambit Declined, Harrwitz Attack","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bf4 *","weight":37847,"prior_delay":25,"prior_completion_time":0},
{"oid":2812,"gid":459,"fullname":"Queen's Gambit Declined, Harrwitz Attack","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Be7 5.Bf4 O-O 6.e3 c5 7.dxc5 Bxc5 8.Qc2 Nc6  9.a3 Qa5 10.O-O-O *","weight":46837,"prior_delay":25,"prior_completion_time":0},
{"oid":2813,"gid":459,"fullname":"Queen's Gambit Declined, Harrwitz Attack","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Be7 5.Bf4 O-O 6.e3 c5 7.dxc5 Bxc5 8.Qc2 Nc6  9.a3 Qa5 10.Rd1 *","weight":46797,"prior_delay":25,"prior_completion_time":0},
{"oid":2814,"gid":460,"fullname":"Queen's Gambit Declined, Hastings Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 h6 6.Bxf6 Qxf6 7.Qb3 *","weight":44021,"prior_delay":25,"prior_completion_time":0},
{"oid":2815,"gid":461,"fullname":"Queen's Gambit Declined, Janowski Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 a6 *","weight":37045,"prior_delay":25,"prior_completion_time":0},
{"oid":2816,"gid":462,"fullname":"Queen's Gambit Declined, Lasker Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 Ne4 8.Bxe7 Qxe7  9.cxd5 Nxc3 10.bxc3 exd5 11.Qb3 Rd8 *","weight":40858,"prior_delay":25,"prior_completion_time":0},
{"oid":2817,"gid":462,"fullname":"Queen's Gambit Declined, Lasker Defense, Teichmann Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 Ne4 8.Bxe7 Qxe7  9.Qc2 c6 *","weight":40748,"prior_delay":25,"prior_completion_time":0},
{"oid":2818,"gid":462,"fullname":"Queen's Gambit Declined, Lasker Defense, Teichmann Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 Ne4 8.Bxe7 Qxe7  9.Qc2 Nxc3 *","weight":40803,"prior_delay":25,"prior_completion_time":0},
{"oid":2819,"gid":462,"fullname":"Queen's Gambit Declined, Lasker Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 Ne4 *","weight":38921,"prior_delay":25,"prior_completion_time":0},
{"oid":2820,"gid":462,"fullname":"Queen's Gambit Declined, Lasker Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 Ne4 8.Bxe7 Qxe7  9.Rc1 c6 10.Bd3 *","weight":41202,"prior_delay":25,"prior_completion_time":0},
{"oid":2821,"gid":464,"fullname":"Queen's Gambit Declined, Manhattan Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Nbd7 5.e3 Bb4 *","weight":38449,"prior_delay":25,"prior_completion_time":0},
{"oid":2822,"gid":465,"fullname":"Queen's Gambit Declined, Miles Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.Nf3 O-O 6.Qc2 Nbd7 *","weight":39246,"prior_delay":25,"prior_completion_time":0},
{"oid":2823,"gid":465,"fullname":"Queen's Gambit Declined, Miles Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.Nf3 O-O 6.Qc2 c5 *","weight":39240,"prior_delay":25,"prior_completion_time":0},
{"oid":2824,"gid":466,"fullname":"Queen's Gambit Declined, Modern, Knight Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Nbd7 5.e3 c6 6.Qc2 *","weight":38479,"prior_delay":25,"prior_completion_time":0},
{"oid":2825,"gid":467,"fullname":"Queen's Gambit Declined, Modern Variation, Normal Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 b6 7.cxd5 exd5 *","weight":39125,"prior_delay":25,"prior_completion_time":0},
{"oid":2826,"gid":467,"fullname":"Queen's Gambit Declined, Modern Variation, Normal Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Ne4 *","weight":39109,"prior_delay":25,"prior_completion_time":0},
{"oid":2827,"gid":467,"fullname":"Queen's Gambit Declined, Modern Variation, Normal Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 b6 7.cxd5 Nxd5 *","weight":39129,"prior_delay":25,"prior_completion_time":0},
{"oid":2828,"gid":467,"fullname":"Queen's Gambit Declined, Modern Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Bb4 *","weight":38324,"prior_delay":25,"prior_completion_time":0},
{"oid":2829,"gid":467,"fullname":"Queen's Gambit Declined, Modern Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 c6 *","weight":38340,"prior_delay":25,"prior_completion_time":0},
{"oid":2830,"gid":467,"fullname":"Queen's Gambit Declined, Modern Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 dxc4 *","weight":38327,"prior_delay":25,"prior_completion_time":0},
{"oid":2831,"gid":468,"fullname":"Queen's Gambit Declined, Neo-Orthodox Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Rc1 h6 7.Bh4 b6 8.cxd5 Nxd5  9.Nxd5 exd5 10.Bxe7 Qxe7 11.Be2 *","weight":39296,"prior_delay":25,"prior_completion_time":0},
{"oid":2832,"gid":469,"fullname":"Queen's Gambit Declined, Normal Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.e3 *","weight":37834,"prior_delay":25,"prior_completion_time":0},
{"oid":2833,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Alekhine Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 c6 8.Bd3 dxc4  9.Bxc4 Nd5 10.Bxe7 Qxe7 11.Ne4 e5 *","weight":39718,"prior_delay":25,"prior_completion_time":0},
{"oid":2834,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Alekhine Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 c6 8.Bd3 dxc4  9.Bxc4 Nd5 10.Bxe7 Qxe7 11.Ne4 N5f6 *","weight":39726,"prior_delay":25,"prior_completion_time":0},
{"oid":2835,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Bd3 Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 c6 8.Bd3 Re8 *","weight":39551,"prior_delay":25,"prior_completion_time":0},
{"oid":2836,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Bd3 Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 c6 8.Bd3 a6 *","weight":39561,"prior_delay":25,"prior_completion_time":0},
{"oid":2837,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Bd3 Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 c6 8.Bd3 h6  9.Bh4 dxc4 10.Bxc4 b5 *","weight":39637,"prior_delay":25,"prior_completion_time":0},
{"oid":2838,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Botvinnik Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Bd3 c6 *","weight":39297,"prior_delay":25,"prior_completion_time":0},
{"oid":2839,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Botvinnik Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Bd3 dxc4  8.Bxc4 c5 9.O-O a6 *","weight":39331,"prior_delay":25,"prior_completion_time":0},
{"oid":2840,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Botvinnik Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Bd3 dxc4  8.Bxc4 a6 *","weight":39312,"prior_delay":25,"prior_completion_time":0},
{"oid":2841,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Classical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 c6 8.Bd3 dxc4  9.Bxc4 Nd5 10.Bxe7 Qxe7 11.O-O Nxc3 12.Rxc3 e5 13.Bb3 *","weight":39758,"prior_delay":25,"prior_completion_time":0},
{"oid":2842,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Henneberger Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 a6 8.c5 c6 *","weight":39514,"prior_delay":25,"prior_completion_time":0},
{"oid":2843,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Henneberger Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 a6 8.a3 *","weight":39485,"prior_delay":25,"prior_completion_time":0},
{"oid":2844,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 Re8 *","weight":39453,"prior_delay":25,"prior_completion_time":0},
{"oid":2845,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 dxc4  8.Bxc4 a6 *","weight":39506,"prior_delay":25,"prior_completion_time":0},
{"oid":2846,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 c6 8.a3 *","weight":39510,"prior_delay":25,"prior_completion_time":0},
{"oid":2847,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 b6  8.cxd5 exd5 9.Qa4 *","weight":39453,"prior_delay":25,"prior_completion_time":0},
{"oid":2848,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 h6 8.Bh4 *","weight":39486,"prior_delay":25,"prior_completion_time":0},
{"oid":2849,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rauzer Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Qb3 c6 *","weight":39283,"prior_delay":25,"prior_completion_time":0},
{"oid":2850,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rubinstein Attack","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 c6 8.Qc2 h6 *","weight":39501,"prior_delay":25,"prior_completion_time":0},
{"oid":2851,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rubinstein Attack","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 c6 8.Qc2 Re8  9.a3 a6 *","weight":39502,"prior_delay":25,"prior_completion_time":0},
{"oid":2852,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rubinstein Attack","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 c6 8.Qc2 a6  9.cxd5 exd5 10.Bd3 Re8 11.O-O Nf8 12.Ne5 *","weight":39536,"prior_delay":25,"prior_completion_time":0},
{"oid":2853,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rubinstein Attack","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 c6 8.Qc2 a6  9.cxd5 exd5 10.Bd3 Re8 11.O-O Nf8 12.h3 *","weight":39543,"prior_delay":25,"prior_completion_time":0},
{"oid":2854,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rubinstein Variation, Flohr Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Qc2 c5  8.cxd5 Nxd5 9.Bxe7 Qxe7 10.Nxd5 exd5 11.Bd3 g6 *","weight":39587,"prior_delay":25,"prior_completion_time":0},
{"oid":2855,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rubinstein Variation, Flohr Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Qc2 c5  8.cxd5 cxd4 *","weight":39462,"prior_delay":25,"prior_completion_time":0},
{"oid":2856,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rubinstein Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Qc2 c5 8.O-O-O *","weight":39424,"prior_delay":25,"prior_completion_time":0},
{"oid":2857,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rubinstein Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Qc2 h6 8.Bh4 c5 *","weight":39438,"prior_delay":25,"prior_completion_time":0},
{"oid":2858,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rubinstein Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Qc2 c6 8.Rd1 *","weight":39397,"prior_delay":25,"prior_completion_time":0},
{"oid":2859,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rubinstein Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Qc2 c5 8.Rd1 *","weight":39440,"prior_delay":25,"prior_completion_time":0},
{"oid":2860,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Rubinstein Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Qc2 c6 8.a3 *","weight":39383,"prior_delay":25,"prior_completion_time":0},
{"oid":2861,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Swiss, Karlsbad Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 a6 8.cxd5  exd5 9.Bd3 c6 *","weight":39576,"prior_delay":25,"prior_completion_time":0},
{"oid":2862,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense, Swiss, Karlsbad Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.Rc1 a6 8.cxd5  exd5 9.Bd3 Re8 *","weight":39565,"prior_delay":25,"prior_completion_time":0},
{"oid":2863,"gid":470,"fullname":"Queen's Gambit Declined, Orthodox Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 Nbd7 7.cxd5 Nxd5 *","weight":39301,"prior_delay":25,"prior_completion_time":0},
{"oid":2864,"gid":471,"fullname":"Queen's Gambit Declined, Pseudo-Tarrasch Variation, Canal Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 c5 5.cxd5 Qb6 *","weight":38336,"prior_delay":25,"prior_completion_time":0},
{"oid":2865,"gid":471,"fullname":"Queen's Gambit Declined, Pseudo-Tarrasch Variation, Primitive Pillsbury Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 c5 5.Nf3 cxd4 6.Qxd4 *","weight":38336,"prior_delay":25,"prior_completion_time":0},
{"oid":2866,"gid":471,"fullname":"Queen's Gambit Declined, Pseudo-Tarrasch Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 c5 5.cxd5 cxd4 6.Qxd4 Be7 7.e4 Nc6 8.Qd2 *","weight":38356,"prior_delay":25,"prior_completion_time":0},
{"oid":2867,"gid":472,"fullname":"Queen's Gambit Declined, Queen's Knight Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nc6 *","weight":36978,"prior_delay":25,"prior_completion_time":0},
{"oid":2868,"gid":472,"fullname":"Queen's Gambit Declined, Queen's Knight Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Bb4 4.a3 *","weight":37290,"prior_delay":25,"prior_completion_time":0},
{"oid":2869,"gid":473,"fullname":"Queen's Gambit Declined, Ragozin Defense, Alekhine Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Bb4 5.Qa4+ Nc6 6.cxd5 exd5 7.Bg5 *","weight":40407,"prior_delay":25,"prior_completion_time":0},
{"oid":2870,"gid":473,"fullname":"Queen's Gambit Declined, Ragozin Defense, Vienna Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Bb4 5.Bg5 dxc4 6.Qa4+ *","weight":40606,"prior_delay":25,"prior_completion_time":0},
{"oid":2871,"gid":473,"fullname":"Queen's Gambit Declined, Ragozin Defense, Vienna Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Bb4 5.Bg5 dxc4 6.e4 c5 7.e5 cxd4 8.Qa4+ Nc6  9.O-O-O *","weight":41415,"prior_delay":25,"prior_completion_time":0},
{"oid":2872,"gid":473,"fullname":"Queen's Gambit Declined, Ragozin Defense, Vienna Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Bb4 5.Bg5 dxc4 6.e4 c5 7.Bxc4 cxd4 8.Nxd4  Qa5 *","weight":42019,"prior_delay":25,"prior_completion_time":0},
{"oid":2873,"gid":473,"fullname":"Queen's Gambit Declined, Ragozin Defense, Vienna Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Bb4 5.Bg5 dxc4 6.e4 c5 7.Bxc4 cxd4 8.Nxd4  Bxc3+ 9.bxc3 Qa5 10.Bb5+ *","weight":42807,"prior_delay":25,"prior_completion_time":0},
{"oid":2874,"gid":473,"fullname":"Queen's Gambit Declined, Ragozin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Bb4 5.Bg5 h6 6.Bxf6 Qxf6 7.cxd5 exd5 *","weight":41729,"prior_delay":25,"prior_completion_time":0},
{"oid":2875,"gid":473,"fullname":"Queen's Gambit Declined, Ragozin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Bb4 5.Bg5 h6 6.Bxf6 Qxf6 7.e3 O-O 8.Rc1 *","weight":42305,"prior_delay":25,"prior_completion_time":0},
{"oid":2876,"gid":473,"fullname":"Queen's Gambit Declined, Ragozin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Bb4 5.cxd5 exd5 6.Bg5 h6 7.Bh4 *","weight":42545,"prior_delay":25,"prior_completion_time":0},
{"oid":2877,"gid":473,"fullname":"Queen's Gambit Declined, Ragozin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Bb4 5.Bg5 Nbd7 6.cxd5 exd5 7.e3 *","weight":41315,"prior_delay":25,"prior_completion_time":0},
{"oid":2878,"gid":473,"fullname":"Queen's Gambit Declined, Ragozin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Bb4 5.Bg5 Nbd7 6.cxd5 exd5 7.Qc2 *","weight":41241,"prior_delay":25,"prior_completion_time":0},
{"oid":2879,"gid":474,"fullname":"Queen's Gambit Declined, Semi-Slav, Abrahams Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c6 4.Nf3 dxc4 5.a4 Bb4 6.e3 b5 7.Bd2 a5 8.axb5 Bxc3  9.Bxc3 cxb5 10.b3 Bb7 11.bxc4 b4 12.Bb2 Nf6 13.Bd3 Nbd7 14.O-O O-O *","weight":39368,"prior_delay":25,"prior_completion_time":0},
{"oid":2880,"gid":474,"fullname":"Queen's Gambit Declined, Semi-Slav, Junge Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c6 4.Nf3 dxc4 5.a4 Bb4 6.e3 b5 7.Bd2 Qb6 *","weight":38545,"prior_delay":25,"prior_completion_time":0},
{"oid":2881,"gid":474,"fullname":"Queen's Gambit Declined, Semi-Slav, Koomen Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c6 4.Nf3 dxc4 5.a4 Bb4 6.e3 b5 7.Bd2 Qe7 *","weight":38547,"prior_delay":25,"prior_completion_time":0},
{"oid":2882,"gid":475,"fullname":"Queen's Gambit Declined, Semi-Tarrasch, Kmoch Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e4 Nxc3 7.bxc3 cxd4  8.cxd4 Bb4+ 9.Bd2 Bxd2+ 10.Qxd2 O-O 11.Bb5 *","weight":42733,"prior_delay":25,"prior_completion_time":0},
{"oid":2883,"gid":475,"fullname":"Queen's Gambit Declined, Semi-Tarrasch, San Sebastian Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e4 Nxc3 7.bxc3 cxd4  8.cxd4 Bb4+ 9.Bd2 Qa5 *","weight":41856,"prior_delay":25,"prior_completion_time":0},
{"oid":2884,"gid":475,"fullname":"Queen's Gambit Declined, Semi-Tarrasch","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.g3 Nc6 7.Bg2 Be7  8.O-O O-O 9.e4 Nb6 *","weight":40105,"prior_delay":25,"prior_completion_time":0},
{"oid":2885,"gid":475,"fullname":"Queen's Gambit Declined, Semi-Tarrasch","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.g3 Nc6 7.Bg2 Be7  8.O-O O-O 9.e4 Ndb4 *","weight":40105,"prior_delay":25,"prior_completion_time":0},
{"oid":2886,"gid":475,"fullname":"Queen's Gambit Declined, Semi-Tarrasch","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 cxd4 *","weight":39422,"prior_delay":25,"prior_completion_time":0},
{"oid":2887,"gid":475,"fullname":"Queen's Gambit Declined, Semi-Tarrasch","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.g3 Nc6 7.Bg2 Be7  8.O-O O-O 9.Nxd5 exd5 10.dxc5 Bxc5 11.Bg5 *","weight":40397,"prior_delay":25,"prior_completion_time":0},
{"oid":2888,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e4 Nxc3 7.bxc3 cxd4  8.cxd4 Nc6 *","weight":41268,"prior_delay":25,"prior_completion_time":0},
{"oid":2889,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e4 Nxc3 7.bxc3 cxd4  8.cxd4 Bb4+ 9.Bd2 Bxd2+ 10.Qxd2 O-O 11.Bc4 Nc6 12.O-O b6 13.Rad1 *","weight":43162,"prior_delay":25,"prior_completion_time":0},
{"oid":2890,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 Nc6 7.Bd3 cxd4  8.exd4 Be7 9.O-O O-O 10.Re1 Ncb4 *","weight":40256,"prior_delay":25,"prior_completion_time":0},
{"oid":2891,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 Nc6 7.Bd3 cxd4  8.exd4 Be7 9.O-O O-O 10.a3 Nf6 *","weight":40148,"prior_delay":25,"prior_completion_time":0},
{"oid":2892,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 Nc6 7.Bd3 cxd4  8.exd4 Be7 9.O-O O-O 10.a3 Bf6 *","weight":40163,"prior_delay":25,"prior_completion_time":0},
{"oid":2893,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 Nc6 7.Bd3 Be7  8.O-O O-O *","weight":40009,"prior_delay":25,"prior_completion_time":0},
{"oid":2894,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 Nc6 7.Bd3 cxd4  8.exd4 Be7 9.O-O O-O 10.Re1 Nf6 11.a3 b6 12.Bc2 *","weight":40366,"prior_delay":25,"prior_completion_time":0},
{"oid":2895,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 Nc6 7.Bd3 cxd4  8.exd4 Be7 9.O-O O-O 10.Re1 Nf6 11.a3 b6 12.Be3 *","weight":40350,"prior_delay":25,"prior_completion_time":0},
{"oid":2896,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 Nc6 7.Bd3 cxd4  8.exd4 Be7 9.O-O O-O 10.Re1 Bf6 11.Be4 Nce7 12.Ne5 *","weight":40413,"prior_delay":25,"prior_completion_time":0},
{"oid":2897,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 Nc6 7.Bd3 cxd4  8.exd4 Be7 9.O-O O-O 10.Re1 Nf6 11.Bg5 *","weight":40287,"prior_delay":25,"prior_completion_time":0},
{"oid":2898,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 Nc6 7.Bd3 cxd4  8.exd4 Be7 9.O-O O-O 10.Re1 Nf6 11.a3 b6 12.Bg5 *","weight":40362,"prior_delay":25,"prior_completion_time":0},
{"oid":2899,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Pillsbury Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 cxd4 7.exd4 *","weight":39771,"prior_delay":25,"prior_completion_time":0},
{"oid":2900,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Pillsbury Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 Nc6 7.Bc4 cxd4  8.exd4 Be7 9.O-O O-O 10.Re1 Nxc3 11.bxc3 b6 12.Bd3 Bb7 13.Qc2 *","weight":40033,"prior_delay":25,"prior_completion_time":0},
{"oid":2901,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense, Pillsbury Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.cxd5 Nxd5 6.e3 Nc6 7.Bc4 cxd4  8.exd4 Be7 9.O-O O-O 10.Re1 Nxc3 11.bxc3 b6 12.Bd3 Bb7 13.h4 *","weight":40044,"prior_delay":25,"prior_completion_time":0},
{"oid":2902,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.e3 a6 6.cxd5 exd5 *","weight":38985,"prior_delay":25,"prior_completion_time":0},
{"oid":2903,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.e3 Be7 *","weight":38824,"prior_delay":25,"prior_completion_time":0},
{"oid":2904,"gid":476,"fullname":"Queen's Gambit Declined, Semi-Tarrasch Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.e3 cxd4 *","weight":38861,"prior_delay":25,"prior_completion_time":0},
{"oid":2905,"gid":477,"fullname":"Queen's Gambit Declined, Semmering Variation","main_line":"1.d4 d5 2.c4 e6 3.Nf3 Nf6 4.e3 c6 5.Nbd2 Nbd7 6.Bd3 c5 *","weight":38771,"prior_delay":25,"prior_completion_time":0},
{"oid":2906,"gid":478,"fullname":"Queen's Gambit Declined, Slav, Amsterdam Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.e3 Bf5 5.cxd5 cxd5 6.Nc3 e6 7.Ne5 Nfd7 *","weight":61031,"prior_delay":25,"prior_completion_time":0},
{"oid":2907,"gid":478,"fullname":"Queen's Gambit Declined, Slav, Dutch, Samisch Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4    8.O-O O-O 9.Qe2 Ne4 10.g4 *","weight":73600,"prior_delay":25,"prior_completion_time":0},
{"oid":2908,"gid":478,"fullname":"Queen's Gambit Declined, Slav, Dutch Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4  8.O-O Nbd7 9.Nh4 Bg6 *","weight":73721,"prior_delay":25,"prior_completion_time":0},
{"oid":2909,"gid":478,"fullname":"Queen's Gambit Declined, Slav, Dutch Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4  8.O-O O-O 9.Nh4 Bg6 *","weight":73459,"prior_delay":25,"prior_completion_time":0},
{"oid":2910,"gid":478,"fullname":"Queen's Gambit Declined, Slav, Dutch Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4  8.O-O O-O 9.Nh4 Bg4 *","weight":73461,"prior_delay":25,"prior_completion_time":0},
{"oid":2911,"gid":478,"fullname":"Queen's Gambit Declined, Slav, Dutch Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4  8.O-O Nbd7 9.Qb3 a5 *","weight":73599,"prior_delay":25,"prior_completion_time":0},
{"oid":2912,"gid":478,"fullname":"Queen's Gambit Declined, Slav, Dutch Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4  8.O-O O-O 9.Qb3 *","weight":73387,"prior_delay":25,"prior_completion_time":0},
{"oid":2913,"gid":478,"fullname":"Queen's Gambit Declined, Slav, Dutch Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4  8.O-O O-O 9.Nh4 Nbd7 10.Nxf5 exf5 11.Qc2 *","weight":73635,"prior_delay":25,"prior_completion_time":0},
{"oid":2914,"gid":478,"fullname":"Queen's Gambit Declined, Slav, Dutch Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4  8.O-O Nbd7 9.Qe2 Bg6 10.e4 *","weight":74130,"prior_delay":25,"prior_completion_time":0},
{"oid":2915,"gid":478,"fullname":"Queen's Gambit Declined, Slav, Smyslov Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Na6 6.e4 Bg4 7.Bxc4 e6 *","weight":67791,"prior_delay":25,"prior_completion_time":0},
{"oid":2916,"gid":479,"fullname":"Queen's Gambit Declined, Tarrasch Defense, Pseudo-Tarrasch","main_line":"1.d4 d5 2.c4 e6 3.Nf3 c5 4.cxd5 exd5 5.g3 Nc6 6.Bg2 Nf6 7.O-O Be7 *","weight":37301,"prior_delay":25,"prior_completion_time":0},
{"oid":2917,"gid":479,"fullname":"Queen's Gambit Declined, Tarrasch Defense, Pseudo-Tarrasch","main_line":"1.d4 d5 2.c4 e6 3.Nf3 c5 4.e3 Nf6 *","weight":36479,"prior_delay":25,"prior_completion_time":0},
{"oid":2918,"gid":479,"fullname":"Queen's Gambit Declined, Tarrasch Defense, Pseudo-Tarrasch","main_line":"1.d4 d5 2.c4 e6 3.Nf3 c5 4.cxd5 exd5 5.g3 Nf6 6.Bg2 *","weight":37033,"prior_delay":25,"prior_completion_time":0},
{"oid":2919,"gid":479,"fullname":"Queen's Gambit Declined, Tarrasch Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.g3 *","weight":37476,"prior_delay":25,"prior_completion_time":0},
{"oid":2920,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense, Makogonov Exchange Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.cxd5 Nxd5  9.Bxe7 Qxe7 10.Rc1 Bb7 11.Bd3 *","weight":40795,"prior_delay":25,"prior_completion_time":0},
{"oid":2921,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense, Makogonov Exchange Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.cxd5 Nxd5  9.Nxd5 *","weight":40613,"prior_delay":25,"prior_completion_time":0},
{"oid":2922,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense, Makogonov Exchange Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.cxd5 Nxd5  9.Bxe7 Qxe7 10.Nxd5 exd5 11.Be2 *","weight":40847,"prior_delay":25,"prior_completion_time":0},
{"oid":2923,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense, Makogonov Exchange Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.cxd5 Nxd5  9.Bxe7 Qxe7 10.Rc1 Bb7 11.Be2 *","weight":40802,"prior_delay":25,"prior_completion_time":0},
{"oid":2924,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense, Makogonov Exchange Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.cxd5 Nxd5  9.Bxe7 Qxe7 10.Rc1 Bb7 11.Nxd5 Bxd5 *","weight":40815,"prior_delay":25,"prior_completion_time":0},
{"oid":2925,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Rc1 Bb7  9.cxd5 exd5 *","weight":40778,"prior_delay":25,"prior_completion_time":0},
{"oid":2926,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Rc1 Bb7  9.Bxf6 Bxf6 10.cxd5 exd5 *","weight":40937,"prior_delay":25,"prior_completion_time":0},
{"oid":2927,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Be2 Bb7  9.Bxf6 Bxf6 10.cxd5 exd5 11.b4 c5 *","weight":41767,"prior_delay":25,"prior_completion_time":0},
{"oid":2928,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Bd3 Bb7  9.O-O Nbd7 10.Bg3 *","weight":41293,"prior_delay":25,"prior_completion_time":0},
{"oid":2929,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Bd3 Bb7  9.O-O Nbd7 10.Rc1 c5 *","weight":41267,"prior_delay":25,"prior_completion_time":0},
{"oid":2930,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Bd3 Bb7  9.O-O Nbd7 10.Qe2 c5 11.Bg3 *","weight":41420,"prior_delay":25,"prior_completion_time":0},
{"oid":2931,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Be2 Bb7  9.Bxf6 Bxf6 10.cxd5 exd5 11.b4 c6 12.O-O *","weight":41900,"prior_delay":25,"prior_completion_time":0},
{"oid":2932,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Qc2 *","weight":40483,"prior_delay":25,"prior_completion_time":0},
{"oid":2933,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Be2 Bb7  9.Bxf6 Bxf6 10.cxd5 exd5 11.O-O *","weight":41699,"prior_delay":25,"prior_completion_time":0},
{"oid":2934,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Bxf6 *","weight":40486,"prior_delay":25,"prior_completion_time":0},
{"oid":2935,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Bd3 Bb7  9.O-O Nbd7 10.Qe2 c5 11.Rfd1 *","weight":41420,"prior_delay":25,"prior_completion_time":0},
{"oid":2936,"gid":480,"fullname":"Queen's Gambit Declined, Tartakower Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.Qb3 *","weight":40516,"prior_delay":25,"prior_completion_time":0},
{"oid":2937,"gid":481,"fullname":"Queen's Gambit Declined, Tartakower Variation, Exchange Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.cxd5 exd5  9.Bd3 *","weight":40548,"prior_delay":25,"prior_completion_time":0},
{"oid":2938,"gid":481,"fullname":"Queen's Gambit Declined, Tartakower Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.cxd5 Nxd5  9.Bxe7 Qxe7 10.Nxd5 exd5 11.Rc1 Be6 12.Bd3 *","weight":40885,"prior_delay":25,"prior_completion_time":0},
{"oid":2939,"gid":481,"fullname":"Queen's Gambit Declined, Tartakower Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 O-O 6.Nf3 h6 7.Bh4 b6 8.cxd5 Nxd5  9.Bxe7 Qxe7 10.Nxd5 exd5 11.Rc1 Be6 12.Qa4 c5 *","weight":40904,"prior_delay":25,"prior_completion_time":0},
{"oid":2940,"gid":482,"fullname":"Queen's Gambit Declined, Three Knights Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Be7 5.cxd5 Nxd5 *","weight":40805,"prior_delay":25,"prior_completion_time":0},
{"oid":2941,"gid":482,"fullname":"Queen's Gambit Declined, Three Knights Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Be7 5.e3 O-O 6.Bd3 *","weight":40818,"prior_delay":25,"prior_completion_time":0},
{"oid":2942,"gid":482,"fullname":"Queen's Gambit Declined, Three Knights Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 Be7 5.e3 O-O 6.b3 *","weight":40812,"prior_delay":25,"prior_completion_time":0},
{"oid":2943,"gid":483,"fullname":"Queen's Gambit Declined, Traditional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nf3 Nf6 4.Bg5 dxc4 *","weight":38671,"prior_delay":25,"prior_completion_time":0},
{"oid":2944,"gid":483,"fullname":"Queen's Gambit Declined, Traditional Variation","main_line":"1.d4 d5 2.c4 e6 3.Nf3 Nf6 4.Bg5 Be7 5.e3 *","weight":38736,"prior_delay":25,"prior_completion_time":0},
{"oid":2945,"gid":484,"fullname":"Queen's Gambit Declined, Vienna Variation, Quiet Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Nc3 e6 5.e3 *","weight":33604,"prior_delay":25,"prior_completion_time":0},
{"oid":2946,"gid":484,"fullname":"Queen's Gambit Declined, Vienna Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Nc3 e6 5.Bg5 *","weight":33395,"prior_delay":25,"prior_completion_time":0},
{"oid":2947,"gid":484,"fullname":"Queen's Gambit Declined, Vienna Variation","main_line":"1.d4 d5 2.c4 e6 3.Nf3 Nf6 4.Bg5 Bb4+ *","weight":38662,"prior_delay":25,"prior_completion_time":0},
{"oid":2948,"gid":484,"fullname":"Queen's Gambit Declined, Vienna Variation","main_line":"1.d4 d5 2.c4 dxc4 3.Nf3 Nf6 4.Nc3 e6 5.e4 *","weight":33980,"prior_delay":25,"prior_completion_time":0},
{"oid":2949,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nf3 Nbd7 *","weight":36313,"prior_delay":25,"prior_completion_time":0},
{"oid":2950,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 h6 *","weight":39030,"prior_delay":25,"prior_completion_time":0},
{"oid":2951,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.cxd5 Nxd5 *","weight":38676,"prior_delay":25,"prior_completion_time":0},
{"oid":2952,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nf3 c6 4.Qc2 Nf6 5.Bg5 *","weight":37196,"prior_delay":25,"prior_completion_time":0},
{"oid":2953,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.Nf3 h6 6.Bh4 O-O 7.Qc2 *","weight":41750,"prior_delay":25,"prior_completion_time":0},
{"oid":2954,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.e3 Nbd7 6.Nf3 *","weight":38972,"prior_delay":25,"prior_completion_time":0},
{"oid":2955,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.cxd5 exd5 4.Nc3 *","weight":33675,"prior_delay":25,"prior_completion_time":0},
{"oid":2956,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.Nf3 h6 6.Bh4 O-O 7.Rc1 *","weight":41788,"prior_delay":25,"prior_completion_time":0},
{"oid":2957,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nf3 c6 4.e3 *","weight":37014,"prior_delay":25,"prior_completion_time":0},
{"oid":2958,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nf3 c6 4.Nbd2 *","weight":36888,"prior_delay":25,"prior_completion_time":0},
{"oid":2959,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Bg5 Be7 5.Nf3 h6 6.Bxf6 Bxf6 7.e3 *","weight":40758,"prior_delay":25,"prior_completion_time":0},
{"oid":2960,"gid":463,"fullname":"Queen's Gambit Declined, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nf3 c6 4.Qc2 Nf6 5.g3 *","weight":37167,"prior_delay":25,"prior_completion_time":0},
{"oid":2961,"gid":485,"fullname":"Queen's Gambit Refused, Albin Countergambit, Fianchetto Variation, Be6 Line","main_line":"1.d4 d5 2.c4 e5 3.dxe5 d4 4.Nf3 Nc6 5.g3 Be6 6.Nbd2 Qd7 7.Bg2 O-O-O *","weight":29442,"prior_delay":25,"prior_completion_time":0},
{"oid":2962,"gid":485,"fullname":"Queen's Gambit Refused, Albin Countergambit, Fianchetto Variation, Be6 Line","main_line":"1.d4 d5 2.c4 e5 3.dxe5 d4 4.Nf3 Nc6 5.g3 Be6 6.Nbd2 Qd7 7.Bg2 Nge7 *","weight":29441,"prior_delay":25,"prior_completion_time":0},
{"oid":2963,"gid":485,"fullname":"Queen's Gambit Refused, Albin Countergambit, Fianchetto Variation","main_line":"1.d4 d5 2.c4 e5 3.dxe5 d4 4.Nf3 Nc6 5.g3 Nge7 *","weight":29451,"prior_delay":25,"prior_completion_time":0},
{"oid":2964,"gid":485,"fullname":"Queen's Gambit Refused, Albin Countergambit, Normal Line","main_line":"1.d4 d5 2.c4 e5 3.dxe5 d4 4.Nf3 Nc6 5.a3 Be6 *","weight":29444,"prior_delay":25,"prior_completion_time":0},
{"oid":2965,"gid":485,"fullname":"Queen's Gambit Refused, Albin Countergambit","main_line":"1.d4 d5 2.c4 e5 3.dxe5 d4 4.a3 *","weight":29322,"prior_delay":25,"prior_completion_time":0},
{"oid":2966,"gid":486,"fullname":"Queen's Gambit Refused, Austrian Defense, Gusev Countergambit","main_line":"1.d4 d5 2.c4 c5 3.cxd5 Nf6 4.Nf3 cxd4 *","weight":29230,"prior_delay":25,"prior_completion_time":0},
{"oid":2967,"gid":486,"fullname":"Queen's Gambit Refused, Austrian Defense, Gusev Countergambit","main_line":"1.d4 d5 2.c4 c5 3.cxd5 Nf6 4.dxc5 *","weight":29229,"prior_delay":25,"prior_completion_time":0},
{"oid":2968,"gid":487,"fullname":"Queen's Gambit Refused, Baltic Defense","main_line":"1.d4 d5 2.c4 Bf5 3.Nf3 e6 4.Nc3 Nf6 *","weight":29384,"prior_delay":25,"prior_completion_time":0},
{"oid":2969,"gid":487,"fullname":"Queen's Gambit Refused, Baltic Defense","main_line":"1.d4 d5 2.c4 Bf5 3.Nc3 e6 *","weight":29186,"prior_delay":25,"prior_completion_time":0},
{"oid":2970,"gid":487,"fullname":"Queen's Gambit Refused, Baltic Defense","main_line":"1.d4 d5 2.c4 Bf5 3.Nf3 e6 4.Qb3 *","weight":29272,"prior_delay":25,"prior_completion_time":0},
{"oid":2971,"gid":487,"fullname":"Queen's Gambit Refused, Baltic Defense","main_line":"1.d4 d5 2.c4 Bf5 3.cxd5 Bxb1 4.Qa4+ c6 5.Rxb1 *","weight":29222,"prior_delay":25,"prior_completion_time":0},
{"oid":2972,"gid":488,"fullname":"Queen's Gambit Refused, Chigorin Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 Nc6 3.cxd5 Qxd5 4.e3 e5 5.Nc3 Bb4 6.Bd2 Bxc3 7.Bxc3 *","weight":29632,"prior_delay":25,"prior_completion_time":0},
{"oid":2973,"gid":488,"fullname":"Queen's Gambit Refused, Chigorin Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 Nc6 3.cxd5 Qxd5 4.e3 e5 5.Nc3 Bb4 6.Bd2 Bxc3 7.bxc3 *","weight":29640,"prior_delay":25,"prior_completion_time":0},
{"oid":2974,"gid":488,"fullname":"Queen's Gambit Refused, Chigorin Defense, Lazard Gambit","main_line":"1.d4 d5 2.c4 Nc6 3.Nf3 e5 *","weight":29367,"prior_delay":25,"prior_completion_time":0},
{"oid":2975,"gid":488,"fullname":"Queen's Gambit Refused, Chigorin Defense, Main Line","main_line":"1.d4 d5 2.c4 Nc6 3.Nf3 Bg4 4.cxd5 Bxf3 5.dxc6 *","weight":29485,"prior_delay":25,"prior_completion_time":0},
{"oid":2976,"gid":488,"fullname":"Queen's Gambit Refused, Chigorin Defense, Main Line","main_line":"1.d4 d5 2.c4 Nc6 3.Nf3 Bg4 4.e3 *","weight":29429,"prior_delay":25,"prior_completion_time":0},
{"oid":2977,"gid":488,"fullname":"Queen's Gambit Refused, Chigorin Defense, Main Line","main_line":"1.d4 d5 2.c4 Nc6 3.Nf3 Bg4 4.Nc3 *","weight":29483,"prior_delay":25,"prior_completion_time":0},
{"oid":2978,"gid":488,"fullname":"Queen's Gambit Refused, Chigorin Defense, Main Line","main_line":"1.d4 d5 2.c4 Nc6 3.Nf3 Bg4 4.cxd5 Bxf3 5.gxf3 *","weight":29505,"prior_delay":25,"prior_completion_time":0},
{"oid":2979,"gid":488,"fullname":"Queen's Gambit Refused, Chigorin Defense, Modern Gambit","main_line":"1.d4 d5 2.c4 Nc6 3.Nc3 dxc4 4.Nf3 Nf6 5.e4 *","weight":29520,"prior_delay":25,"prior_completion_time":0},
{"oid":2980,"gid":488,"fullname":"Queen's Gambit Refused, Chigorin Defense","main_line":"1.d4 d5 2.c4 Nc6 3.Nc3 Nf6 4.Nf3 Bg4 *","weight":29434,"prior_delay":25,"prior_completion_time":0},
{"oid":2981,"gid":488,"fullname":"Queen's Gambit Refused, Chigorin Defense","main_line":"1.d4 d5 2.c4 Nc6 3.Nc3 dxc4 4.d5 *","weight":29456,"prior_delay":25,"prior_completion_time":0},
{"oid":2982,"gid":489,"fullname":"Queen's Indian, Buerger Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 c5 6.d5 exd5 7.Ng5 *","weight":69155,"prior_delay":25,"prior_completion_time":0},
{"oid":2983,"gid":490,"fullname":"Queen's Indian Accelerated, Main Line","main_line":"1.d4 Nf6 2.c4 b6 3.Nc3 Bb7 4.Qc2 *","weight":30504,"prior_delay":25,"prior_completion_time":0},
{"oid":2984,"gid":491,"fullname":"Queen's Indian Defense, Anti-Queen's Indian System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.Nc3 O-O 7.Qc2 c5 *","weight":70638,"prior_delay":25,"prior_completion_time":0},
{"oid":2985,"gid":491,"fullname":"Queen's Indian Defense, Anti-Queen's Indian System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.Nc3 O-O 7.Qc2 d5 *","weight":70615,"prior_delay":25,"prior_completion_time":0},
{"oid":2986,"gid":491,"fullname":"Queen's Indian Defense, Anti-Queen's Indian System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.Nc3 Ne4 7.Qc2 *","weight":70662,"prior_delay":25,"prior_completion_time":0},
{"oid":2987,"gid":492,"fullname":"Queen's Indian Defense, Capablanca Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Bb4+ 6.Bd2 c5 *","weight":69568,"prior_delay":25,"prior_completion_time":0},
{"oid":2988,"gid":492,"fullname":"Queen's Indian Defense, Capablanca Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Bb4+ 6.Nbd2 O-O 7.O-O d5 *","weight":69360,"prior_delay":25,"prior_completion_time":0},
{"oid":2989,"gid":492,"fullname":"Queen's Indian Defense, Capablanca Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Bb4+ 6.Bd2 Bxd2+ 7.Nbxd2 *","weight":69600,"prior_delay":25,"prior_completion_time":0},
{"oid":2990,"gid":492,"fullname":"Queen's Indian Defense, Capablanca Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Bb4+ 6.Bd2 Bxd2+ 7.Qxd2 O-O 8.Nc3 *","weight":69799,"prior_delay":25,"prior_completion_time":0},
{"oid":2991,"gid":492,"fullname":"Queen's Indian Defense, Capablanca Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Bb4+ 6.Bd2 Bxd2+ 7.Qxd2 O-O 8.O-O *","weight":69785,"prior_delay":25,"prior_completion_time":0},
{"oid":2992,"gid":492,"fullname":"Queen's Indian Defense, Capablanca Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Bb4+ 6.Bd2 Qe7 7.O-O Bxd2 8.Qxd2 *","weight":69599,"prior_delay":25,"prior_completion_time":0},
{"oid":2993,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Polugaevsky Gambit","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.d5 exd5 8.Nh4 c6  9.cxd5 Nxd5 10.Nf5 Nc7 11.e4 *","weight":72685,"prior_delay":25,"prior_completion_time":0},
{"oid":2994,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Taimanov Gambit","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.d5 exd5 8.Nd4 *","weight":72160,"prior_delay":25,"prior_completion_time":0},
{"oid":2995,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Tiviakov Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Na6 *","weight":72613,"prior_delay":25,"prior_completion_time":0},
{"oid":2996,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Qc2 Nxc3  9.Qxc3 c5 10.Rd1 d6 11.b3 Bf6 12.Bb2 Qe7 *","weight":74273,"prior_delay":25,"prior_completion_time":0},
{"oid":2997,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Qc2 Nxc3  9.Qxc3 f5 10.b3 Bf6 11.Bb2 d6 *","weight":73943,"prior_delay":25,"prior_completion_time":0},
{"oid":2998,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Qc2 Nxc3  9.Qxc3 Be4 10.Ne1 *","weight":73853,"prior_delay":25,"prior_completion_time":0},
{"oid":2999,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Qc2 Nxc3  9.Qxc3 d6 10.b3 *","weight":73871,"prior_delay":25,"prior_completion_time":0},
{"oid":3000,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Qc2 Nxc3  9.Qxc3 c5 10.b3 *","weight":73983,"prior_delay":25,"prior_completion_time":0},
{"oid":3001,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Qc2 Nxc3  9.Qxc3 f5 10.Rd1 *","weight":73877,"prior_delay":25,"prior_completion_time":0},
{"oid":3002,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation, Nimzowitsch Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 d5 8.Ne5 Na6  9.cxd5 exd5 *","weight":72637,"prior_delay":25,"prior_completion_time":0},
{"oid":3003,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation, Nimzowitsch Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 d5 8.cxd5 exd5 *","weight":72611,"prior_delay":25,"prior_completion_time":0},
{"oid":3004,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation, Nimzowitsch Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 d5 8.Ne5 c6 *","weight":72605,"prior_delay":25,"prior_completion_time":0},
{"oid":3005,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation, Nimzowitsch Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 d5 8.Ne5 Nbd7 *","weight":72605,"prior_delay":25,"prior_completion_time":0},
{"oid":3006,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Nxe4 Bxe4  9.Ne1 Bxg2 10.Nxg2 d5 11.Qa4 Qd7 *","weight":74189,"prior_delay":25,"prior_completion_time":0},
{"oid":3007,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Bd2 d5  9.cxd5 exd5 *","weight":73571,"prior_delay":25,"prior_completion_time":0},
{"oid":3008,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Nxe4 Bxe4  9.Ne1 Bxg2 10.Nxg2 d5 11.Qa4 dxc4 *","weight":74228,"prior_delay":25,"prior_completion_time":0},
{"oid":3009,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Qc2 Nxc3  9.bxc3 *","weight":73624,"prior_delay":25,"prior_completion_time":0},
{"oid":3010,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Nxe4 Bxe4  9.Bf4 *","weight":73602,"prior_delay":25,"prior_completion_time":0},
{"oid":3011,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Nxe4 Bxe4  9.Nh4 *","weight":73637,"prior_delay":25,"prior_completion_time":0},
{"oid":3012,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Bd2 Bf6  9.Rc1 *","weight":73720,"prior_delay":25,"prior_completion_time":0},
{"oid":3013,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation, Traditional Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Nc3 Ne4 8.Bd2 f5  9.d5 Bf6 10.Rc1 *","weight":73608,"prior_delay":25,"prior_completion_time":0},
{"oid":3014,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O d5 *","weight":71024,"prior_delay":25,"prior_completion_time":0},
{"oid":3015,"gid":493,"fullname":"Queen's Indian Defense, Classical Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Qc2 *","weight":71953,"prior_delay":25,"prior_completion_time":0},
{"oid":3016,"gid":494,"fullname":"Queen's Indian Defense, Euwe Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.b3 d5 *","weight":71999,"prior_delay":25,"prior_completion_time":0},
{"oid":3017,"gid":494,"fullname":"Queen's Indian Defense, Euwe Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.b3 c5 *","weight":71994,"prior_delay":25,"prior_completion_time":0},
{"oid":3018,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Check Variation, Intermezzo Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.b3 Bb4+ 6.Bd2 Be7 7.Bg2 c6 8.Bc3 d5  9.Ne5 Nfd7 *","weight":75546,"prior_delay":25,"prior_completion_time":0},
{"oid":3019,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Check Variation, Intermezzo Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.b3 Bb4+ 6.Bd2 Be7 7.Bg2 d5  8.cxd5 exd5 9.O-O O-O *","weight":74228,"prior_delay":25,"prior_completion_time":0},
{"oid":3020,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Check Variation, Intermezzo Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.b3 Bb4+ 6.Bd2 Be7 7.Bg2 c6 8.O-O d5 *","weight":74579,"prior_delay":25,"prior_completion_time":0},
{"oid":3021,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Check Variation, Intermezzo Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.b3 Bb4+ 6.Bd2 Be7 7.Nc3 *","weight":73433,"prior_delay":25,"prior_completion_time":0},
{"oid":3022,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Kramnik Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.O-O O-O 7.Re1 d5 *","weight":72341,"prior_delay":25,"prior_completion_time":0},
{"oid":3023,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation, Nimzowitsch Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.Qa4 c6 6.Nc3 b5 *","weight":68890,"prior_delay":25,"prior_completion_time":0},
{"oid":3024,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation, Nimzowitsch Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.Qa4 Be7 *","weight":68881,"prior_delay":25,"prior_completion_time":0},
{"oid":3025,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation, Nimzowitsch Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.Qa4 c5 6.Bg2 Bb7 7.dxc5 *","weight":69190,"prior_delay":25,"prior_completion_time":0},
{"oid":3026,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation, Nimzowitsch Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.Qa4 c5 6.Bg2 Bb7 7.O-O *","weight":68983,"prior_delay":25,"prior_completion_time":0},
{"oid":3027,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation, Quiet Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.b3 Bb7 6.Bg2 Bb4+ 7.Bd2 a5  8.O-O O-O *","weight":71818,"prior_delay":25,"prior_completion_time":0},
{"oid":3028,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation, Quiet Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.b3 d5 6.cxd5 *","weight":70431,"prior_delay":25,"prior_completion_time":0},
{"oid":3029,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation, Quiet Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.b3 d5 6.Bg2 *","weight":70438,"prior_delay":25,"prior_completion_time":0},
{"oid":3030,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation, Quiet Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.b3 b5 6.cxb5 *","weight":70410,"prior_delay":25,"prior_completion_time":0},
{"oid":3031,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation, Timman's Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.Qb3 *","weight":68712,"prior_delay":25,"prior_completion_time":0},
{"oid":3032,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.Nbd2 Bb7 6.Bg2 c5 *","weight":69099,"prior_delay":25,"prior_completion_time":0},
{"oid":3033,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.Nbd2 c5 *","weight":68875,"prior_delay":25,"prior_completion_time":0},
{"oid":3034,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.Nbd2 Bb4 *","weight":68957,"prior_delay":25,"prior_completion_time":0},
{"oid":3035,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Ba6 5.Qc2 *","weight":68746,"prior_delay":25,"prior_completion_time":0},
{"oid":3036,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation, Rubinstein Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 c5 6.d5 exd5 7.Nh4 *","weight":69175,"prior_delay":25,"prior_completion_time":0},
{"oid":3037,"gid":495,"fullname":"Queen's Indian Defense, Fianchetto Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb4+ 5.Bd2 Bxd2+ 6.Qxd2 Ba6 *","weight":67121,"prior_delay":25,"prior_completion_time":0},
{"oid":3038,"gid":496,"fullname":"Queen's Indian Defense, Kasparov Variation, Botvinnik Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Nc3 Bb7 5.Bg5 h6 6.Bh4 g5 7.Bg3 Nh5 8.Qc2 *","weight":63210,"prior_delay":25,"prior_completion_time":0},
{"oid":3039,"gid":496,"fullname":"Queen's Indian Defense, Kasparov Variation, Botvinnik Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Nc3 Bb7 5.Bg5 h6 6.Bh4 g5 7.Bg3 Nh5 8.e3 *","weight":63228,"prior_delay":25,"prior_completion_time":0},
{"oid":3040,"gid":496,"fullname":"Queen's Indian Defense, Kasparov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Nc3 Bb7 5.Bg5 h6 6.Bh4 Bb4 7.e3 c5 *","weight":63196,"prior_delay":25,"prior_completion_time":0},
{"oid":3041,"gid":496,"fullname":"Queen's Indian Defense, Kasparov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Nc3 Bb7 5.Bg5 h6 6.Bh4 Be7 7.e3 Ne4 *","weight":63290,"prior_delay":25,"prior_completion_time":0},
{"oid":3042,"gid":496,"fullname":"Queen's Indian Defense, Kasparov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Nc3 Bb7 5.Bg5 h6 6.Bh4 Bb4 7.e3 Bxc3+ 8.bxc3 d6  9.Nd2 Nbd7 10.f3 Qe7 *","weight":63606,"prior_delay":25,"prior_completion_time":0},
{"oid":3043,"gid":496,"fullname":"Queen's Indian Defense, Kasparov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Nc3 Bb7 5.Bg5 h6 6.Bh4 Bb4 7.Qc2 g5 *","weight":63162,"prior_delay":25,"prior_completion_time":0},
{"oid":3044,"gid":496,"fullname":"Queen's Indian Defense, Kasparov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Nc3 Bb7 5.Bg5 h6 6.Bh4 Be7 7.e3 c5 *","weight":63275,"prior_delay":25,"prior_completion_time":0},
{"oid":3045,"gid":496,"fullname":"Queen's Indian Defense, Kasparov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Nc3 Bb7 5.Bg5 h6 6.Bh4 Bb4 7.e3 g5 8.Bg3 Ne4  9.Qc2 Bxc3+ 10.bxc3 d6 11.Bd3 f5 *","weight":63702,"prior_delay":25,"prior_completion_time":0},
{"oid":3046,"gid":496,"fullname":"Queen's Indian Defense, Kasparov Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Nc3 Bb7 5.Bg5 h6 6.Bh4 Bb4 7.Nd2 Bxc3 8.bxc3 *","weight":63229,"prior_delay":25,"prior_completion_time":0},
{"oid":3047,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Andersson Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 Ne4 *","weight":64230,"prior_delay":25,"prior_completion_time":0},
{"oid":3048,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Classical Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 d5 6.cxd5 exd5 7.Bg5 *","weight":66236,"prior_delay":25,"prior_completion_time":0},
{"oid":3049,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Classical Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 d5 6.cxd5 exd5 7.g3 *","weight":66280,"prior_delay":25,"prior_completion_time":0},
{"oid":3050,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Hedgehog Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 g6 *","weight":64278,"prior_delay":25,"prior_completion_time":0},
{"oid":3051,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Kasparov Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 d5 6.cxd5 Nxd5 7.Qc2 c5 *","weight":66990,"prior_delay":25,"prior_completion_time":0},
{"oid":3052,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Kasparov Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 d5 6.cxd5 Nxd5 7.Qc2 Be7 *","weight":67007,"prior_delay":25,"prior_completion_time":0},
{"oid":3053,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Kasparov Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 d5 6.cxd5 Nxd5 7.Qc2 Nxc3  8.bxc3 c5 8.e4 *","weight":67393,"prior_delay":25,"prior_completion_time":0},
{"oid":3054,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Kasparov Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 d5 6.cxd5 Nxd5 7.Qc2 Nxc3  8.bxc3 Be7 8.e4 *","weight":67452,"prior_delay":25,"prior_completion_time":0},
{"oid":3055,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 d5 6.Bg5 Be7 7.Qa4+ c6  8.Bxf6 Bxf6 9.cxd5 exd5 *","weight":66238,"prior_delay":25,"prior_completion_time":0},
{"oid":3056,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Marco Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 Be7 *","weight":64172,"prior_delay":25,"prior_completion_time":0},
{"oid":3057,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Petrosian Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 d5 6.cxd5 Nxd5 7.e3 g6 *","weight":66976,"prior_delay":25,"prior_completion_time":0},
{"oid":3058,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Petrosian Attack","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 d5 6.cxd5 Nxd5 7.e3 Be7 *","weight":66952,"prior_delay":25,"prior_completion_time":0},
{"oid":3059,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation, Polovodin Gambit","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 d5 6.cxd5 Nxd5 7.e4 *","weight":66648,"prior_delay":25,"prior_completion_time":0},
{"oid":3060,"gid":497,"fullname":"Queen's Indian Defense, Kasparov-Petrosian Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Bb7 5.Nc3 Bxf3 *","weight":64165,"prior_delay":25,"prior_completion_time":0},
{"oid":3061,"gid":499,"fullname":"Queen's Indian Defense, Marienbad System, Berg Variation","main_line":"1.d4 Nf6 2.Nf3 b6 3.g3 Bb7 4.Bg2 c5 5.c4 cxd4 6.Qxd4 *","weight":22988,"prior_delay":25,"prior_completion_time":0},
{"oid":3062,"gid":500,"fullname":"Queen's Indian Defense, Miles Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Bf4 Bb7 5.e3 Bb4+ 6.Nbd2 *","weight":61229,"prior_delay":25,"prior_completion_time":0},
{"oid":3063,"gid":500,"fullname":"Queen's Indian Defense, Miles Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Bf4 Bb7 5.e3 Be7 6.h3 *","weight":61234,"prior_delay":25,"prior_completion_time":0},
{"oid":3064,"gid":500,"fullname":"Queen's Indian Defense, Miles Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Bf4 Bb7 5.e3 Bb4+ 6.Nfd2 *","weight":61224,"prior_delay":25,"prior_completion_time":0},
{"oid":3065,"gid":501,"fullname":"Queen's Indian Defense, Opocensky Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.Nc3 Ne4 7.Bd2 Bf6 *","weight":70992,"prior_delay":25,"prior_completion_time":0},
{"oid":3066,"gid":501,"fullname":"Queen's Indian Defense, Opocensky Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.Nc3 Ne4 7.Bd2 f5 *","weight":70951,"prior_delay":25,"prior_completion_time":0},
{"oid":3067,"gid":501,"fullname":"Queen's Indian Defense, Opocensky Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Be7 6.Nc3 Ne4 7.Bd2 O-O *","weight":70955,"prior_delay":25,"prior_completion_time":0},
{"oid":3068,"gid":502,"fullname":"Queen's Indian Defense, Petrosian Variation, Farago Defense","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Ba6 5.Qc2 Bb7 6.Nc3 c5 7.e4 cxd4 8.Nxd4 Nc6 *","weight":64678,"prior_delay":25,"prior_completion_time":0},
{"oid":3069,"gid":502,"fullname":"Queen's Indian Defense, Petrosian Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Ba6 5.Qc2 c5 *","weight":63207,"prior_delay":25,"prior_completion_time":0},
{"oid":3070,"gid":502,"fullname":"Queen's Indian Defense, Petrosian Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 c5 5.d5 Ba6 6.Qc2 exd5 6.cxd5 g6 *","weight":62917,"prior_delay":25,"prior_completion_time":0},
{"oid":3071,"gid":502,"fullname":"Queen's Indian Defense, Petrosian Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.a3 Ba6 5.e3 *","weight":62872,"prior_delay":25,"prior_completion_time":0},
{"oid":3072,"gid":503,"fullname":"Queen's Indian Defense, Riumin Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Bb4+ 6.Bd2 Be7 7.Nc3 O-O 8.O-O d5 *","weight":69687,"prior_delay":25,"prior_completion_time":0},
{"oid":3073,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Bd3 c5 6.O-O Be7 7.Nc3 O-O *","weight":62170,"prior_delay":25,"prior_completion_time":0},
{"oid":3074,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Bd3 Bb4+ 6.Nbd2 c5 *","weight":62060,"prior_delay":25,"prior_completion_time":0},
{"oid":3075,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb4+ *","weight":61349,"prior_delay":25,"prior_completion_time":0},
{"oid":3076,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Bd3 c5 6.O-O Be7 7.Nc3 cxd4 8.exd4 d5 *","weight":62288,"prior_delay":25,"prior_completion_time":0},
{"oid":3077,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Bd3 c5 6.O-O Be7 7.b3 O-O 8.Bb2 d5 *","weight":62137,"prior_delay":25,"prior_completion_time":0},
{"oid":3078,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Bd3 d5 6.O-O Bd6 7.Nc3 *","weight":62372,"prior_delay":25,"prior_completion_time":0},
{"oid":3079,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Bd3 d5 6.O-O Bd6 7.b3 *","weight":62360,"prior_delay":25,"prior_completion_time":0},
{"oid":3080,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Nc3 d5 *","weight":61765,"prior_delay":25,"prior_completion_time":0},
{"oid":3081,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Bd3 c5 6.O-O Be7 7.b3 O-O 8.Bb2 cxd4  9.exd4 d5 *","weight":62175,"prior_delay":25,"prior_completion_time":0},
{"oid":3082,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Bd3 Be7 6.O-O O-O 7.Nc3 *","weight":62108,"prior_delay":25,"prior_completion_time":0},
{"oid":3083,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Bd3 c5 6.O-O cxd4 7.exd4 *","weight":62083,"prior_delay":25,"prior_completion_time":0},
{"oid":3084,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Bd3 Bb4+ 6.Nbd2 O-O 7.O-O d5 8.a3 Be7  9.b4 *","weight":62195,"prior_delay":25,"prior_completion_time":0},
{"oid":3085,"gid":504,"fullname":"Queen's Indian Defense, Spassky System","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.e3 Bb7 5.Bd3 c5 6.Nc3 *","weight":62035,"prior_delay":25,"prior_completion_time":0},
{"oid":3086,"gid":505,"fullname":"Queen's Indian Defense, Yates Variation","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.g3 Bb7 5.Bg2 Bb4+ 6.Bd2 a5 7.O-O O-O *","weight":69917,"prior_delay":25,"prior_completion_time":0},
{"oid":3087,"gid":498,"fullname":"Queen's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.Nf3 b6 3.Bf4 e6 4.e3 c5 *","weight":22984,"prior_delay":25,"prior_completion_time":0},
{"oid":3088,"gid":498,"fullname":"Queen's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.Nf3 b6 3.g3 Bb7 4.Bg2 e6 *","weight":22968,"prior_delay":25,"prior_completion_time":0},
{"oid":3089,"gid":498,"fullname":"Queen's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.Nf3 b6 3.e3 Bb7 4.Bd3 e6 5.O-O c5 *","weight":23143,"prior_delay":25,"prior_completion_time":0},
{"oid":3090,"gid":498,"fullname":"Queen's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.Nf3 b6 3.g3 e6 *","weight":22910,"prior_delay":25,"prior_completion_time":0},
{"oid":3091,"gid":498,"fullname":"Queen's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.Nf3 b6 3.Bf4 Bb7 4.e3 e6 *","weight":22904,"prior_delay":25,"prior_completion_time":0},
{"oid":3092,"gid":498,"fullname":"Queen's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.Nf3 b6 3.Bg5 Bb7 *","weight":22932,"prior_delay":25,"prior_completion_time":0},
{"oid":3093,"gid":498,"fullname":"Queen's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.Nf3 b6 3.e3 e6 4.Bd3 c5 *","weight":23091,"prior_delay":25,"prior_completion_time":0},
{"oid":3094,"gid":498,"fullname":"Queen's Indian Defense, Main Line","main_line":"1.d4 Nf6 2.c4 e6 3.Nf3 b6 4.Bg5 *","weight":61003,"prior_delay":25,"prior_completion_time":0},
{"oid":3095,"gid":506,"fullname":"Queen's Pawn, Main Line","main_line":"1.d4 d6 2.Nf3 g6 3.Bf4 Bg7 *","weight":13831,"prior_delay":25,"prior_completion_time":0},
{"oid":3096,"gid":506,"fullname":"Queen's Pawn, Main Line","main_line":"1.d4 d6 2.Nf3 g6 3.g3 Bg7 4.Bg2 *","weight":14243,"prior_delay":25,"prior_completion_time":0},
{"oid":3097,"gid":507,"fullname":"Queen's Pawn Game, Barry Attack, Grunfeld Variation","main_line":"1.d4 d5 2.Nc3 Nf6 3.Nf3 g6 4.Bf4 Bg7 5.e3 O-O 6.Be2 *","weight":16126,"prior_delay":25,"prior_completion_time":0},
{"oid":3098,"gid":508,"fullname":"Queen's Pawn Game, Chigorin Variation","main_line":"1.d4 d5 2.Nf3 Nc6 3.g3 Bg4 *","weight":17473,"prior_delay":25,"prior_completion_time":0},
{"oid":3099,"gid":509,"fullname":"Queen's Pawn Game, Colle System, Anti-Colle","main_line":"1.d4 d5 2.Nf3 Nf6 3.e3 Bf5 4.Bd3 e6 *","weight":21257,"prior_delay":25,"prior_completion_time":0},
{"oid":3100,"gid":509,"fullname":"Queen's Pawn Game, Colle System","main_line":"1.d4 d5 2.Nf3 Nf6 3.e3 c5 4.c3 Nc6 *","weight":21277,"prior_delay":25,"prior_completion_time":0},
{"oid":3101,"gid":509,"fullname":"Queen's Pawn Game, Colle System","main_line":"1.d4 d5 2.Nf3 Nf6 3.e3 Bg4 *","weight":21248,"prior_delay":25,"prior_completion_time":0},
{"oid":3102,"gid":509,"fullname":"Queen's Pawn Game, Colle System","main_line":"1.d4 d5 2.Nf3 Nf6 3.e3 g6 *","weight":21255,"prior_delay":25,"prior_completion_time":0},
{"oid":3103,"gid":509,"fullname":"Queen's Pawn Game, Colle System","main_line":"1.d4 d5 2.Nf3 Nf6 3.e3 c6 *","weight":21268,"prior_delay":25,"prior_completion_time":0},
{"oid":3104,"gid":510,"fullname":"Queen's Pawn Game, Levitsky Attack","main_line":"1.d4 d5 2.Bg5 h6 3.Bh4 c6 4.e3 Qb6 *","weight":16089,"prior_delay":25,"prior_completion_time":0},
{"oid":3105,"gid":511,"fullname":"Queen's Pawn Game, London System","main_line":"1.d4 d5 2.Nf3 Nf6 3.Bf4 Bf5 *","weight":21438,"prior_delay":25,"prior_completion_time":0},
{"oid":3106,"gid":511,"fullname":"Queen's Pawn Game, London System","main_line":"1.d4 d5 2.Nf3 Nf6 3.Bf4 c5 4.e3 e6 5.c3 Nc6 *","weight":21799,"prior_delay":25,"prior_completion_time":0},
{"oid":3107,"gid":512,"fullname":"Queen's Pawn Game, Symmetrical Variation, Pseudo-Catalan","main_line":"1.d4 d5 2.Nf3 Nf6 3.g3 g6 4.Bg2 Bg7 5.O-O O-O *","weight":22035,"prior_delay":25,"prior_completion_time":0},
{"oid":3108,"gid":512,"fullname":"Queen's Pawn Game, Symmetrical Variation, Pseudo-Catalan","main_line":"1.d4 d5 2.Nf3 Nf6 3.g3 c6 4.Bg2 Bg4 5.O-O Nbd7 6.Nbd2 e6 *","weight":21478,"prior_delay":25,"prior_completion_time":0},
{"oid":3109,"gid":513,"fullname":"Queen's Pawn Game, Torre Attack, Gossip Variation","main_line":"1.d4 d5 2.Nf3 Nf6 3.Bg5 Ne4 4.Bf4 *","weight":21221,"prior_delay":25,"prior_completion_time":0},
{"oid":3110,"gid":513,"fullname":"Queen's Pawn Game, Torre Attack, Grunfeld Variation, Main Line","main_line":"1.d4 d5 2.Nf3 Nf6 3.Bg5 g6 4.Nbd2 Bg7 5.e3 O-O 6.Bd3 c5 7.c3 Nbd7 *","weight":21399,"prior_delay":25,"prior_completion_time":0},
{"oid":3111,"gid":513,"fullname":"Queen's Pawn Game, Torre Attack, Grunfeld Variation, Main Line","main_line":"1.d4 d5 2.Nf3 Nf6 3.Bg5 g6 4.e3 Bg7 5.Nbd2 O-O 6.Bd3 *","weight":21283,"prior_delay":25,"prior_completion_time":0},
{"oid":3112,"gid":513,"fullname":"Queen's Pawn Game, Torre Attack, Grunfeld Variation, Main Line","main_line":"1.d4 d5 2.Nf3 Nf6 3.Bg5 g6 4.Nbd2 Bg7 5.e3 O-O 6.c3 Nbd7 7.Be2 *","weight":21496,"prior_delay":25,"prior_completion_time":0},
{"oid":3113,"gid":513,"fullname":"Queen's Pawn Game, Torre Attack, Grunfeld Variation","main_line":"1.d4 d5 2.Nf3 Nf6 3.Bg5 g6 4.c3 Bg7 *","weight":21179,"prior_delay":25,"prior_completion_time":0},
{"oid":3114,"gid":513,"fullname":"Queen's Pawn Game, Torre Attack","main_line":"1.d4 d5 2.Nf3 Nf6 3.Bg5 e6 4.e3 Nbd7 5.Nbd2 Be7 *","weight":21201,"prior_delay":25,"prior_completion_time":0},
{"oid":3115,"gid":513,"fullname":"Queen's Pawn Game, Torre Attack","main_line":"1.d4 d5 2.Nf3 Nf6 3.Bg5 e6 4.e3 c5 5.Nbd2 Be7 6.c3 Nbd7 *","weight":21254,"prior_delay":25,"prior_completion_time":0},
{"oid":3116,"gid":513,"fullname":"Queen's Pawn Game, Torre Attack","main_line":"1.d4 d5 2.Nf3 Nf6 3.Bg5 e6 4.e3 c5 5.Nbd2 Nbd7 *","weight":21223,"prior_delay":25,"prior_completion_time":0},
{"oid":3117,"gid":514,"fullname":"Queen's Pawn Game, Veresov Attack, Richter Variation","main_line":"1.d4 d5 2.Nc3 Nf6 3.Bg5 Bf5 4.f3 *","weight":15895,"prior_delay":25,"prior_completion_time":0},
{"oid":3118,"gid":514,"fullname":"Queen's Pawn Game, Veresov Attack, Two Knights System, Grunfeld Defense","main_line":"1.d4 d5 2.Nc3 Nf6 3.Bg5 Nbd7 4.Nf3 g6 *","weight":15954,"prior_delay":25,"prior_completion_time":0},
{"oid":3119,"gid":514,"fullname":"Queen's Pawn Game, Veresov Attack, Two Knights System","main_line":"1.d4 d5 2.Nc3 Nf6 3.Bg5 Nbd7 4.Nf3 h6 *","weight":15952,"prior_delay":25,"prior_completion_time":0},
{"oid":3120,"gid":514,"fullname":"Queen's Pawn Game, Veresov Attack, Veresov Variation","main_line":"1.d4 d5 2.Nc3 Nf6 3.Bg5 Bf5 4.Bxf6 *","weight":15896,"prior_delay":25,"prior_completion_time":0},
{"oid":3121,"gid":515,"fullname":"Queen's Pawn Game, Zukertort Variation","main_line":"1.d4 d5 2.Nf3 c6 3.Bf4 Qb6 *","weight":17910,"prior_delay":25,"prior_completion_time":0},
{"oid":3122,"gid":515,"fullname":"Queen's Pawn Game, Zukertort Variation","main_line":"1.d4 d5 2.Nf3 Bf5 3.Bf4 c6 *","weight":17478,"prior_delay":25,"prior_completion_time":0},
{"oid":3123,"gid":515,"fullname":"Queen's Pawn Game, Zukertort Variation","main_line":"1.d4 d5 2.Nf3 Bf5 3.e3 c6 *","weight":17471,"prior_delay":25,"prior_completion_time":0},
{"oid":3124,"gid":515,"fullname":"Queen's Pawn Game, Zukertort Variation","main_line":"1.d4 d5 2.Nf3 Bf5 3.Bf4 e6 *","weight":17489,"prior_delay":25,"prior_completion_time":0},
{"oid":3125,"gid":515,"fullname":"Queen's Pawn Game, Zukertort Variation","main_line":"1.d4 d5 2.Nf3 Nf6 3.e3 e6 4.Nbd2 c5 5.b3 *","weight":21297,"prior_delay":25,"prior_completion_time":0},
{"oid":3126,"gid":516,"fullname":"Rat Defense, English Rat","main_line":"1.d4 d6 2.c4 e5 3.Nf3 e4 *","weight":12786,"prior_delay":25,"prior_completion_time":0},
{"oid":3127,"gid":516,"fullname":"Rat Defense, English Rat","main_line":"1.d4 d6 2.c4 e5 3.dxe5 dxe5 4.Qxd8+ Kxd8 *","weight":12598,"prior_delay":25,"prior_completion_time":0},
{"oid":3128,"gid":517,"fullname":"Richter-Veresov Attack, Main Line","main_line":"1.d4 d5 2.Nc3 Nf6 3.Bg5 Nbd7 4.f3 *","weight":15937,"prior_delay":25,"prior_completion_time":0},
{"oid":3129,"gid":517,"fullname":"Richter-Veresov Attack, Main Line","main_line":"1.d4 d5 2.Nc3 Nf6 3.Bg5 e6 *","weight":15890,"prior_delay":25,"prior_completion_time":0},
{"oid":3130,"gid":517,"fullname":"Richter-Veresov Attack, Main Line","main_line":"1.d4 d5 2.Nc3 Nf6 3.Bg5 g6 *","weight":15888,"prior_delay":25,"prior_completion_time":0},
{"oid":3131,"gid":517,"fullname":"Richter-Veresov Attack, Main Line","main_line":"1.d4 d5 2.Nc3 Nf6 3.Bg5 c6 *","weight":15906,"prior_delay":25,"prior_completion_time":0},
{"oid":3132,"gid":517,"fullname":"Richter-Veresov Attack, Main Line","main_line":"1.d4 d5 2.Nc3 Nf6 3.Bg5 h6 *","weight":15890,"prior_delay":25,"prior_completion_time":0},
{"oid":3133,"gid":517,"fullname":"Richter-Veresov Attack, Main Line","main_line":"1.d4 d5 2.Nc3 Nf6 3.Bg5 c5 *","weight":15897,"prior_delay":25,"prior_completion_time":0},
{"oid":3134,"gid":518,"fullname":"Robatsch Defense, Main Line","main_line":"1.d4 d6 2.c4 g6 3.Nf3 Bg7 4.e4 Bg4 *","weight":12820,"prior_delay":25,"prior_completion_time":0},
{"oid":3135,"gid":519,"fullname":"Rubinstein Opening, Main Line","main_line":"1.d4 d5 2.Nf3 Nf6 3.e3 e6 4.Bd3 c5 5.b3 Nc6 *","weight":21512,"prior_delay":25,"prior_completion_time":0},
{"oid":3136,"gid":520,"fullname":"Semi-Benoni, Main Line","main_line":"1.d4 c5 2.d5 e5 3.e4 d6 4.Nc3 Be7 5.Nf3 Bg4 *","weight":12023,"prior_delay":25,"prior_completion_time":0},
{"oid":3137,"gid":520,"fullname":"Semi-Benoni, Main Line","main_line":"1.d4 c5 2.d5 e5 3.e4 d6 4.Nc3 g6 *","weight":12009,"prior_delay":25,"prior_completion_time":0},
{"oid":3138,"gid":520,"fullname":"Semi-Benoni, Main Line","main_line":"1.d4 c5 2.d5 e5 3.e4 d6 4.Nc3 a6 5.a4 Be7 *","weight":12015,"prior_delay":25,"prior_completion_time":0},
{"oid":3139,"gid":520,"fullname":"Semi-Benoni, Main Line","main_line":"1.d4 c5 2.d5 e5 3.e4 d6 4.Nc3 Nf6 *","weight":12010,"prior_delay":25,"prior_completion_time":0},
{"oid":3140,"gid":520,"fullname":"Semi-Benoni, Main Line","main_line":"1.d4 c5 2.d5 e5 3.e4 d6 4.Nc3 Be7 5.Bb5+ *","weight":12019,"prior_delay":25,"prior_completion_time":0},
{"oid":3141,"gid":520,"fullname":"Semi-Benoni, Main Line","main_line":"1.d4 c5 2.d5 e5 3.e4 d6 4.Nf3 *","weight":11990,"prior_delay":25,"prior_completion_time":0},
{"oid":3142,"gid":520,"fullname":"Semi-Benoni, Main Line","main_line":"1.d4 c5 2.d5 e5 3.e4 d6 4.Bb5+ *","weight":11989,"prior_delay":25,"prior_completion_time":0},
{"oid":3143,"gid":521,"fullname":"Semi-Slav Defense, Accelerated Meran Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 a6 *","weight":41932,"prior_delay":25,"prior_completion_time":0},
{"oid":3144,"gid":522,"fullname":"Semi-Slav Defense, Accelerated Move Order","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c6 4.e3 Nf6 *","weight":38916,"prior_delay":25,"prior_completion_time":0},
{"oid":3145,"gid":522,"fullname":"Semi-Slav Defense, Accelerated Move Order","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c6 4.cxd5 exd5 5.Nf3 Bf5 *","weight":38050,"prior_delay":25,"prior_completion_time":0},
{"oid":3146,"gid":522,"fullname":"Semi-Slav Defense, Accelerated Move Order","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c6 4.cxd5 exd5 5.Bf4 *","weight":38074,"prior_delay":25,"prior_completion_time":0},
{"oid":3147,"gid":523,"fullname":"Semi-Slav Defense, Anti-Moscow Gambit","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 h6 6.Bh4 Be7 7.e3 O-O *","weight":43181,"prior_delay":25,"prior_completion_time":0},
{"oid":3148,"gid":523,"fullname":"Semi-Slav Defense, Anti-Moscow Gambit","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 h6 6.Bh4 dxc4 7.e4 g5 8.Bg3 b5  9.Be2 Bb7 *","weight":45595,"prior_delay":25,"prior_completion_time":0},
{"oid":3149,"gid":524,"fullname":"Semi-Slav Defense, Bogoljubov Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Be7 7.O-O O-O 8.b3 *","weight":46181,"prior_delay":25,"prior_completion_time":0},
{"oid":3150,"gid":525,"fullname":"Semi-Slav Defense, Botvinnik System, Alatortsev System","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 dxc4 6.e4 b5 7.e5 h6 8.Bh4 g5  9.Nxg5 Nd5 *","weight":43661,"prior_delay":25,"prior_completion_time":0},
{"oid":3151,"gid":525,"fullname":"Semi-Slav Defense, Botvinnik System, Ekstrom Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 dxc4 6.e4 b5 7.e5 h6 8.Bh4 g5  9.exf6 gxh4 10.Ne5 *","weight":43470,"prior_delay":25,"prior_completion_time":0},
{"oid":3152,"gid":525,"fullname":"Semi-Slav Defense, Botvinnik System, Lilienthal Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 dxc4 6.e4 b5 7.e5 h6 8.Bh4 g5  9.Nxg5 hxg5 10.Bxg5 Nbd7 11.g3 Qa5 *","weight":44369,"prior_delay":25,"prior_completion_time":0},
{"oid":3153,"gid":525,"fullname":"Semi-Slav Defense, Botvinnik System, Lilienthal Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 dxc4 6.e4 b5 7.e5 h6 8.Bh4 g5  9.Nxg5 hxg5 10.Bxg5 Nbd7 11.g3 Bb7 *","weight":44396,"prior_delay":25,"prior_completion_time":0},
{"oid":3154,"gid":525,"fullname":"Semi-Slav Defense, Botvinnik System","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 dxc4 6.e4 b5 7.e5 h6 8.Bh4 g5  9.Nxg5 hxg5 10.Bxg5 Nbd7 11.exf6 Bb7 12.g3 c5 13.d5 Qb6 *","weight":44705,"prior_delay":25,"prior_completion_time":0},
{"oid":3155,"gid":526,"fullname":"Semi-Slav Defense, Botvinnik Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 dxc4 6.e4 b5 7.a4 *","weight":42451,"prior_delay":25,"prior_completion_time":0},
{"oid":3156,"gid":527,"fullname":"Semi-Slav Defense, Chigorin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Bd6 7.O-O O-O 8.e4 dxe4  9.Nxe4 Nxe4 10.Bxe4 h6 11.Bc2 e5 *","weight":46433,"prior_delay":25,"prior_completion_time":0},
{"oid":3157,"gid":527,"fullname":"Semi-Slav Defense, Chigorin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Bd6 7.O-O O-O 8.e4 dxc4  9.Bxc4 e5 *","weight":46376,"prior_delay":25,"prior_completion_time":0},
{"oid":3158,"gid":527,"fullname":"Semi-Slav Defense, Chigorin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Bd6 7.Qc2 O-O 8.O-O dxc4  9.Bxc4 e5 *","weight":48625,"prior_delay":25,"prior_completion_time":0},
{"oid":3159,"gid":527,"fullname":"Semi-Slav Defense, Chigorin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Bd6 7.Qc2 O-O 8.O-O dxc4  9.Bxc4 a6 10.Rd1 b5 *","weight":49216,"prior_delay":25,"prior_completion_time":0},
{"oid":3160,"gid":527,"fullname":"Semi-Slav Defense, Chigorin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Bd6 7.Qc2 O-O 8.O-O dxc4  9.Bxc4 Qe7 10.Bd2 *","weight":48640,"prior_delay":25,"prior_completion_time":0},
{"oid":3161,"gid":527,"fullname":"Semi-Slav Defense, Chigorin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Bd6 7.Qc2 O-O 8.O-O dxc4  9.Bxc4 Qe7 10.a3 *","weight":48647,"prior_delay":25,"prior_completion_time":0},
{"oid":3162,"gid":527,"fullname":"Semi-Slav Defense, Chigorin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Bd6 7.Qc2 O-O 8.O-O dxc4  9.Bxc4 Qe7 10.h3 *","weight":48660,"prior_delay":25,"prior_completion_time":0},
{"oid":3163,"gid":527,"fullname":"Semi-Slav Defense, Chigorin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Bd6 7.Qc2 O-O 8.O-O dxc4  9.Bxc4 b5 10.Be2 Bb7 11.Rd1 *","weight":49340,"prior_delay":25,"prior_completion_time":0},
{"oid":3164,"gid":527,"fullname":"Semi-Slav Defense, Chigorin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Bd6 7.Qc2 dxc4 8.Bxc4 *","weight":46380,"prior_delay":25,"prior_completion_time":0},
{"oid":3165,"gid":527,"fullname":"Semi-Slav Defense, Chigorin Defense","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Bd6 7.e4 dxe4 8.Nxe4 *","weight":46311,"prior_delay":25,"prior_completion_time":0},
{"oid":3166,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 a6 *","weight":46134,"prior_delay":25,"prior_completion_time":0},
{"oid":3167,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Bd6 *","weight":41574,"prior_delay":25,"prior_completion_time":0},
{"oid":3168,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Be7 *","weight":41575,"prior_delay":25,"prior_completion_time":0},
{"oid":3169,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 Be7 6.e3 O-O *","weight":41681,"prior_delay":25,"prior_completion_time":0},
{"oid":3170,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 h6 6.Bxf6 Qxf6 7.e3 Nd7 8.Bd3  dxc4 9.Bxc4 g6 10.O-O Bg7 11.b4 *","weight":46348,"prior_delay":25,"prior_completion_time":0},
{"oid":3171,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.g3 *","weight":39815,"prior_delay":25,"prior_completion_time":0},
{"oid":3172,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 h6 6.Bxf6 Qxf6 7.g3 *","weight":43982,"prior_delay":25,"prior_completion_time":0},
{"oid":3173,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 h6 6.Bxf6 Qxf6 7.Qc2 *","weight":43973,"prior_delay":25,"prior_completion_time":0},
{"oid":3174,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Qb3 dxc4 6.Qxc4 *","weight":39802,"prior_delay":25,"prior_completion_time":0},
{"oid":3175,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Qd3 *","weight":39626,"prior_delay":25,"prior_completion_time":0},
{"oid":3176,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 h6 6.Bxf6 Qxf6 7.e3 Nd7 8.Bd3  dxc4 9.Bxc4 g6 10.O-O Bg7 11.Rc1 *","weight":46350,"prior_delay":25,"prior_completion_time":0},
{"oid":3177,"gid":528,"fullname":"Semi-Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 Be7 6.e3 Nbd7 7.Bd3 *","weight":41713,"prior_delay":25,"prior_completion_time":0},
{"oid":3178,"gid":529,"fullname":"Semi-Slav Defense, Marshall Gambit, Forgotten Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c6 4.e4 dxe4 5.Nxe4 Bb4+ 6.Nc3 *","weight":38585,"prior_delay":25,"prior_completion_time":0},
{"oid":3179,"gid":529,"fullname":"Semi-Slav Defense, Marshall Gambit, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c6 4.e4 dxe4 5.Nxe4 Bb4+ 6.Bd2 Qxd4 7.Bxb4 Qxe4+  8.Be2 Na6 *","weight":39234,"prior_delay":25,"prior_completion_time":0},
{"oid":3180,"gid":529,"fullname":"Semi-Slav Defense, Marshall Gambit, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c6 4.e4 dxe4 5.Nxe4 Bb4+ 6.Bd2 Qxd4 7.Bxb4 Qxe4+  8.Ne2 *","weight":39071,"prior_delay":25,"prior_completion_time":0},
{"oid":3181,"gid":529,"fullname":"Semi-Slav Defense, Marshall Gambit","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c6 4.e4 Bb4 *","weight":38156,"prior_delay":25,"prior_completion_time":0},
{"oid":3182,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Blumenfeld Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5  8.Bd3 a6 9.e4 c5 10.e5 cxd4 11.Nxb5 axb5 12.exf6 gxf6 *","weight":51759,"prior_delay":25,"prior_completion_time":0},
{"oid":3183,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Blumenfeld Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5  8.Bd3 a6 9.e4 c5 10.e5 cxd4 11.Nxb5 axb5 12.exf6 Qb6 *","weight":51733,"prior_delay":25,"prior_completion_time":0},
{"oid":3184,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Lundin Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 b4  9.Ne4 Nxe4 *","weight":51071,"prior_delay":25,"prior_completion_time":0},
{"oid":3185,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Lundin Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 b4  9.Na4 *","weight":50891,"prior_delay":25,"prior_completion_time":0},
{"oid":3186,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Lundin Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 b4  9.Ne4 Be7 10.O-O *","weight":51032,"prior_delay":25,"prior_completion_time":0},
{"oid":3187,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Old Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 a6  9.e4 c5 10.e5 Ng4 *","weight":51472,"prior_delay":25,"prior_completion_time":0},
{"oid":3188,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Rabinovich Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5  8.Bd3 a6 9.e4 c5 10.e5 cxd4 11.Nxb5 Ng4 12.Qa4 Ngxe5 *","weight":51673,"prior_delay":25,"prior_completion_time":0},
{"oid":3189,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Reynolds' Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 a6  9.e4 c5 10.d5 Qc7 *","weight":51494,"prior_delay":25,"prior_completion_time":0},
{"oid":3190,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Reynolds' Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 a6  9.e4 c5 10.d5 c4 11.dxe6 fxe6 12.Bc2 Qc7 13.O-O *","weight":51593,"prior_delay":25,"prior_completion_time":0},
{"oid":3191,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Sozin Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5  8.Bd3 a6 9.e4 c5 10.e5 cxd4 11.Nxb5 Nxe5 12.Nxe5 axb5 13.O-O *","weight":51691,"prior_delay":25,"prior_completion_time":0},
{"oid":3192,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Sozin Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5  8.Bd3 a6 9.e4 c5 10.e5 cxd4 11.Nxb5 Nxe5 12.Nxe5 axb5 13.Bxb5+ *","weight":51698,"prior_delay":25,"prior_completion_time":0},
{"oid":3193,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Wade Variation, Larsen Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 Bb7  9.e4 b4 10.Na4 c5 11.e5 Nd5 12.Nxc5 *","weight":52142,"prior_delay":25,"prior_completion_time":0},
{"oid":3194,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Wade Variation, Larsen Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 Bb7  9.e4 b4 10.Na4 c5 11.e5 Nd5 12.O-O *","weight":52201,"prior_delay":25,"prior_completion_time":0},
{"oid":3195,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Wade Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 Bb7  9.O-O b4 10.Ne4 Be7 11.Nxf6+ Nxf6 12.e4 *","weight":51712,"prior_delay":25,"prior_completion_time":0},
{"oid":3196,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation, Wade Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 Bb7  9.a3 *","weight":51359,"prior_delay":25,"prior_completion_time":0},
{"oid":3197,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 a6  9.e4 Bb7 *","weight":51190,"prior_delay":25,"prior_completion_time":0},
{"oid":3198,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Be2 a6 *","weight":49858,"prior_delay":25,"prior_completion_time":0},
{"oid":3199,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 Bd6 *","weight":50949,"prior_delay":25,"prior_completion_time":0},
{"oid":3200,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 a6  9.O-O c5 10.Qe2 *","weight":51007,"prior_delay":25,"prior_completion_time":0},
{"oid":3201,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Be2 b4 *","weight":49854,"prior_delay":25,"prior_completion_time":0},
{"oid":3202,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 a6  9.O-O Bb7 10.e4 c5 11.d5 Qc7 12.dxe6 fxe6 *","weight":51542,"prior_delay":25,"prior_completion_time":0},
{"oid":3203,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bb3 b4 *","weight":49724,"prior_delay":25,"prior_completion_time":0},
{"oid":3204,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Be2 Bb7  9.O-O a6 *","weight":50044,"prior_delay":25,"prior_completion_time":0},
{"oid":3205,"gid":530,"fullname":"Semi-Slav Defense, Meran Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 dxc4 7.Bxc4 b5 8.Bd3 a6  9.a4 *","weight":51013,"prior_delay":25,"prior_completion_time":0},
{"oid":3206,"gid":531,"fullname":"Semi-Slav Defense, Normal Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.a3 *","weight":44742,"prior_delay":25,"prior_completion_time":0},
{"oid":3207,"gid":532,"fullname":"Semi-Slav Defense, Romih Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Bd3 Bb4 7.O-O O-O *","weight":46158,"prior_delay":25,"prior_completion_time":0},
{"oid":3208,"gid":533,"fullname":"Semi-Slav Defense, Rubinstein (Anti-Meran) System","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Ne5 *","weight":44736,"prior_delay":25,"prior_completion_time":0},
{"oid":3209,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation, Center Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.e4 *","weight":48463,"prior_delay":25,"prior_completion_time":0},
{"oid":3210,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation, Shabalov Attack","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.g4 Bb4 *","weight":48709,"prior_delay":25,"prior_completion_time":0},
{"oid":3211,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation, Shabalov Attack","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.g4 dxc4 *","weight":48735,"prior_delay":25,"prior_completion_time":0},
{"oid":3212,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation, Shabalov Attack","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.g4 h6 *","weight":48785,"prior_delay":25,"prior_completion_time":0},
{"oid":3213,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation, Shabalov Attack","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.g4 Nxg4 *","weight":48709,"prior_delay":25,"prior_completion_time":0},
{"oid":3214,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.Be2 O-O 8.O-O e5 *","weight":49333,"prior_delay":25,"prior_completion_time":0},
{"oid":3215,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Be7 *","weight":46824,"prior_delay":25,"prior_completion_time":0},
{"oid":3216,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 b6 *","weight":46954,"prior_delay":25,"prior_completion_time":0},
{"oid":3217,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.Be2 O-O 8.O-O Qe7 *","weight":49334,"prior_delay":25,"prior_completion_time":0},
{"oid":3218,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.Be2 O-O 8.O-O Re8 *","weight":49350,"prior_delay":25,"prior_completion_time":0},
{"oid":3219,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.b3 O-O 8.Be2 b6 *","weight":49601,"prior_delay":25,"prior_completion_time":0},
{"oid":3220,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.b3 O-O 8.Be2 Re8 *","weight":49439,"prior_delay":25,"prior_completion_time":0},
{"oid":3221,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.b3 O-O 8.Bb2 *","weight":49221,"prior_delay":25,"prior_completion_time":0},
{"oid":3222,"gid":534,"fullname":"Semi-Slav Defense, Stoltz Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.e3 Nbd7 6.Qc2 Bd6 7.Bd2 *","weight":48470,"prior_delay":25,"prior_completion_time":0},
{"oid":3223,"gid":535,"fullname":"Semi-Slav Defense Accepted, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c6 5.Bg5 dxc4 6.a4 *","weight":41943,"prior_delay":25,"prior_completion_time":0},
{"oid":3224,"gid":536,"fullname":"Slav Defense, Alapin Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 a5 *","weight":67636,"prior_delay":25,"prior_completion_time":0},
{"oid":3225,"gid":537,"fullname":"Slav Defense, Alekhine Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.e3 b5 6.a4 b4 7.Nb1 *","weight":65000,"prior_delay":25,"prior_completion_time":0},
{"oid":3226,"gid":538,"fullname":"Slav Defense, Breyer Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nbd2 *","weight":56282,"prior_delay":25,"prior_completion_time":0},
{"oid":3227,"gid":539,"fullname":"Slav Defense, Chameleon Variation, Advance System","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 a6 5.c5 Nbd7 *","weight":64869,"prior_delay":25,"prior_completion_time":0},
{"oid":3228,"gid":539,"fullname":"Slav Defense, Chameleon Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 a6 5.Bg5 *","weight":64099,"prior_delay":25,"prior_completion_time":0},
{"oid":3229,"gid":539,"fullname":"Slav Defense, Chameleon Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 a6 5.Ne5 *","weight":64132,"prior_delay":25,"prior_completion_time":0},
{"oid":3230,"gid":539,"fullname":"Slav Defense, Chameleon Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 a6 5.e3 b5 6.b3 *","weight":65529,"prior_delay":25,"prior_completion_time":0},
{"oid":3231,"gid":539,"fullname":"Slav Defense, Chameleon Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 a6 5.a4 e6 6.Bg5 *","weight":64998,"prior_delay":25,"prior_completion_time":0},
{"oid":3232,"gid":540,"fullname":"Slav Defense, Czech Variation, Bled Attack","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.Nh4 Bc8 7.e3 *","weight":69787,"prior_delay":25,"prior_completion_time":0},
{"oid":3233,"gid":540,"fullname":"Slav Defense, Czech Variation, Bled Attack","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.Nh4 e6 7.Nxf5 exf5 8.e3 *","weight":69997,"prior_delay":25,"prior_completion_time":0},
{"oid":3234,"gid":540,"fullname":"Slav Defense, Czech Variation, Carlsbad Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.Ne5 Nbd7 7.Nxc4 Qc7  8.g3 e5 9.dxe5 Nxe5 10.Bf4 Nfd7 11.Bg2 f6 *","weight":74375,"prior_delay":25,"prior_completion_time":0},
{"oid":3235,"gid":540,"fullname":"Slav Defense, Czech Variation, Classical System, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4    8.O-O O-O 9.Qe2 Bg4 *","weight":73580,"prior_delay":25,"prior_completion_time":0},
{"oid":3236,"gid":540,"fullname":"Slav Defense, Czech Variation, Classical System, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4    8.O-O O-O 9.Qe2 Nbd7 10.e4 Bg6 11.Bd3 h6 *","weight":74183,"prior_delay":25,"prior_completion_time":0},
{"oid":3237,"gid":540,"fullname":"Slav Defense, Czech Variation, Classical System, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4    8.O-O O-O 9.Qe2 Nbd7 10.e4 Bg4 *","weight":73793,"prior_delay":25,"prior_completion_time":0},
{"oid":3238,"gid":540,"fullname":"Slav Defense, Czech Variation, Classical System, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4    8.O-O O-O 9.Qe2 Nbd7 10.e4 Bg6 11.Bd3 Re8 *","weight":74160,"prior_delay":25,"prior_completion_time":0},
{"oid":3239,"gid":540,"fullname":"Slav Defense, Czech Variation, Classical System, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4    8.O-O O-O 9.Qe2 Bg6 10.Rd1 Nbd7 *","weight":73630,"prior_delay":25,"prior_completion_time":0},
{"oid":3240,"gid":540,"fullname":"Slav Defense, Czech Variation, Classical System, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4    8.O-O O-O 9.Qe2 Bg6 10.Ne5 Nbd7 11.Nxg6 hxg6 12.Rd1 *","weight":73948,"prior_delay":25,"prior_completion_time":0},
{"oid":3241,"gid":540,"fullname":"Slav Defense, Czech Variation, Classical System, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.e3 e6 7.Bxc4 Bb4    8.O-O O-O 9.Qe2 Nbd7 10.e4 Bg6 11.Bd3 Bh5 12.e5 *","weight":74413,"prior_delay":25,"prior_completion_time":0},
{"oid":3242,"gid":540,"fullname":"Slav Defense, Czech Variation, Krause Attack","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.Ne5 Na6 7.f3 *","weight":70574,"prior_delay":25,"prior_completion_time":0},
{"oid":3243,"gid":540,"fullname":"Slav Defense, Czech Variation, Wiesbaden Variation, Sharp Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.Ne5 e6 7.f3 Bb4 8.e4 Bxe4  9.fxe4 Nxe4 10.Bd2 Qxd4 11.Nxe4 Qxe4+ 12.Qe2 Bxd2+ 13.Kxd2 Qd5+ 14.Kc2 Na6  15.Nxc4 O-O-O *","weight":72184,"prior_delay":25,"prior_completion_time":0},
{"oid":3244,"gid":540,"fullname":"Slav Defense, Czech Variation, Wiesbaden Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.Ne5 e6 7.f3 Bb4 8.Bg5 *","weight":71073,"prior_delay":25,"prior_completion_time":0},
{"oid":3245,"gid":540,"fullname":"Slav Defense, Czech Variation, Wiesbaden Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.Ne5 e6 7.f3 c5 *","weight":70999,"prior_delay":25,"prior_completion_time":0},
{"oid":3246,"gid":540,"fullname":"Slav Defense, Czech Variation, Wiesbaden Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bf5 6.Ne5 e6 7.f3 Bb4 8.Nxc4 *","weight":71092,"prior_delay":25,"prior_completion_time":0},
{"oid":3247,"gid":541,"fullname":"Slav Defense, Exchange Variation, Symmetrical Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 Nc6 6.Bf4 Bf5 7.e3 a6 *","weight":59445,"prior_delay":25,"prior_completion_time":0},
{"oid":3248,"gid":541,"fullname":"Slav Defense, Exchange Variation, Symmetrical Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 Nc6 6.Bf4 Bf5 7.e3 e6 8.Ne5 *","weight":59839,"prior_delay":25,"prior_completion_time":0},
{"oid":3249,"gid":541,"fullname":"Slav Defense, Exchange Variation, Symmetrical Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 Nc6 6.Bf4 Bf5 7.e3 e6  8.Bd3 Bxd3 9.Qxd3 Bd6 10.Bxd6 Qxd6 11.O-O O-O 12.Rac1 *","weight":61385,"prior_delay":25,"prior_completion_time":0},
{"oid":3250,"gid":541,"fullname":"Slav Defense, Exchange Variation, Symmetrical Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 Nc6 6.Bf4 Bf5 7.e3 e6  8.Bd3 Bxd3 9.Qxd3 Bd6 10.Bxd6 Qxd6 11.O-O O-O 12.Rfc1 Rfc8 *","weight":61489,"prior_delay":25,"prior_completion_time":0},
{"oid":3251,"gid":541,"fullname":"Slav Defense, Exchange Variation, Symmetrical Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 Nc6 6.Bf4 Bf5 7.e3 e6  8.Bb5 Nd7 9.Qa4 *","weight":59927,"prior_delay":25,"prior_completion_time":0},
{"oid":3252,"gid":541,"fullname":"Slav Defense, Exchange Variation, Trifunovic Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 Nc6 6.Bf4 Bf5 7.e3 e6  8.Qb3 Bb4 *","weight":60003,"prior_delay":25,"prior_completion_time":0},
{"oid":3253,"gid":541,"fullname":"Slav Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 Nc6 6.Bf4 e6 7.e3 Be7 *","weight":58826,"prior_delay":25,"prior_completion_time":0},
{"oid":3254,"gid":541,"fullname":"Slav Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 c6 3.cxd5 cxd5 4.Nc3 Nf6 5.Bf4 Nc6 6.e3 a6 *","weight":44763,"prior_delay":25,"prior_completion_time":0},
{"oid":3255,"gid":541,"fullname":"Slav Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 Nc6 6.Bf4 e6 7.e3 Bd6  8.Bxd6 Qxd6 *","weight":58872,"prior_delay":25,"prior_completion_time":0},
{"oid":3256,"gid":541,"fullname":"Slav Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 e6 6.Bf4 *","weight":57385,"prior_delay":25,"prior_completion_time":0},
{"oid":3257,"gid":541,"fullname":"Slav Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 Nc6 6.Bf4 a6 7.e3 Bg4 8.Be2 *","weight":59253,"prior_delay":25,"prior_completion_time":0},
{"oid":3258,"gid":541,"fullname":"Slav Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 a6 6.Ne5 *","weight":57391,"prior_delay":25,"prior_completion_time":0},
{"oid":3259,"gid":541,"fullname":"Slav Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.cxd5 cxd5 5.Nc3 a6 6.Bf4 *","weight":57497,"prior_delay":25,"prior_completion_time":0},
{"oid":3260,"gid":541,"fullname":"Slav Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 c6 3.cxd5 cxd5 4.Bf4 *","weight":42426,"prior_delay":25,"prior_completion_time":0},
{"oid":3261,"gid":541,"fullname":"Slav Defense, Exchange Variation","main_line":"1.d4 d5 2.c4 c6 3.cxd5 cxd5 4.Nf3 *","weight":42330,"prior_delay":25,"prior_completion_time":0},
{"oid":3262,"gid":542,"fullname":"Slav Defense, Geller Gambit","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.e4 b5 6.Qc2 *","weight":64845,"prior_delay":25,"prior_completion_time":0},
{"oid":3263,"gid":542,"fullname":"Slav Defense, Geller Gambit","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.e4 b5 6.e5 Nd5 7.a4 e6 *","weight":64978,"prior_delay":25,"prior_completion_time":0},
{"oid":3264,"gid":544,"fullname":"Slav Defense, Modern","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.g3 Bg4 5.Bg2 e6 6.O-O Nbd7 7.Nbd2 Bd6 *","weight":56359,"prior_delay":25,"prior_completion_time":0},
{"oid":3265,"gid":544,"fullname":"Slav Defense, Modern","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.g3 Bf5 5.Bg2 e6  6.O-O h6 7.Nbd2 *","weight":56358,"prior_delay":25,"prior_completion_time":0},
{"oid":3266,"gid":544,"fullname":"Slav Defense, Modern","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.g3 Bg4 5.Bg2 e6 6.O-O Nbd7 7.b3 Bd6 8.Bb2 O-O  9.Nbd2 *","weight":56385,"prior_delay":25,"prior_completion_time":0},
{"oid":3267,"gid":544,"fullname":"Slav Defense, Modern","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.g3 Bg4 5.Bg2 e6 6.O-O Nbd7 7.Nbd2 Be7 *","weight":56362,"prior_delay":25,"prior_completion_time":0},
{"oid":3268,"gid":544,"fullname":"Slav Defense, Modern","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.g3 Bf5 5.Bg2 e6  6.O-O Nbd7 *","weight":56381,"prior_delay":25,"prior_completion_time":0},
{"oid":3269,"gid":544,"fullname":"Slav Defense, Modern","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.g3 Bf5 5.Bg2 e6  6.O-O h6 7.b3 *","weight":56358,"prior_delay":25,"prior_completion_time":0},
{"oid":3270,"gid":544,"fullname":"Slav Defense, Modern","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.g3 Bf5 5.Bg2 e6  6.O-O Be7 *","weight":56359,"prior_delay":25,"prior_completion_time":0},
{"oid":3271,"gid":544,"fullname":"Slav Defense, Modern","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Qc2 g6 5.Bf4 *","weight":56804,"prior_delay":25,"prior_completion_time":0},
{"oid":3272,"gid":544,"fullname":"Slav Defense, Modern","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Qb3 *","weight":56617,"prior_delay":25,"prior_completion_time":0},
{"oid":3273,"gid":544,"fullname":"Slav Defense, Modern","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.g3 Bf5 5.Bg2 e6  6.O-O h6 7.Nc3 *","weight":56371,"prior_delay":25,"prior_completion_time":0},
{"oid":3274,"gid":545,"fullname":"Slav Defense, Modern Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Bg4 *","weight":48973,"prior_delay":25,"prior_completion_time":0},
{"oid":3275,"gid":545,"fullname":"Slav Defense, Modern Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 dxc4 4.e3 Be6 *","weight":49219,"prior_delay":25,"prior_completion_time":0},
{"oid":3276,"gid":545,"fullname":"Slav Defense, Modern Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 dxc4 4.e3 b5 5.a4 e6 *","weight":49214,"prior_delay":25,"prior_completion_time":0},
{"oid":3277,"gid":545,"fullname":"Slav Defense, Modern Line","main_line":"1.d4 d5 2.c4 c6 3.Nf3 dxc4 4.Nc3 *","weight":49054,"prior_delay":25,"prior_completion_time":0},
{"oid":3278,"gid":546,"fullname":"Slav Defense, Quiet Variation, Pin Defense","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.e3 Bg4 5.Nc3 *","weight":60639,"prior_delay":25,"prior_completion_time":0},
{"oid":3279,"gid":546,"fullname":"Slav Defense, Quiet Variation, Schallopp Defense","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.e3 Bf5 5.Nc3 e6 6.Nh4 Bg4 *","weight":64003,"prior_delay":25,"prior_completion_time":0},
{"oid":3280,"gid":546,"fullname":"Slav Defense, Quiet Variation, Schallopp Defense","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.e3 Bf5 5.Bd3 Bxd3 *","weight":61016,"prior_delay":25,"prior_completion_time":0},
{"oid":3281,"gid":546,"fullname":"Slav Defense, Quiet Variation, Schallopp Defense","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.e3 Bf5 5.Nc3 e6 6.Bd3 Bxd3 7.Qxd3 Nbd7 *","weight":63172,"prior_delay":25,"prior_completion_time":0},
{"oid":3282,"gid":546,"fullname":"Slav Defense, Quiet Variation, Schallopp Defense","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.e3 Bf5 5.Nc3 e6 6.Nh4 Be4 *","weight":64336,"prior_delay":25,"prior_completion_time":0},
{"oid":3283,"gid":546,"fullname":"Slav Defense, Quiet Variation, Schallopp Defense","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.e3 Bf5 5.cxd5 cxd5 6.Qb3 Qc7 *","weight":61078,"prior_delay":25,"prior_completion_time":0},
{"oid":3284,"gid":546,"fullname":"Slav Defense, Quiet Variation, Schallopp Defense","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.e3 Bf5 5.Nc3 e6 6.Be2 *","weight":62988,"prior_delay":25,"prior_completion_time":0},
{"oid":3285,"gid":546,"fullname":"Slav Defense, Quiet Variation, Schallopp Defense","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.e3 Bf5 5.Qb3 *","weight":60836,"prior_delay":25,"prior_completion_time":0},
{"oid":3286,"gid":546,"fullname":"Slav Defense, Quiet Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.e3 g6 *","weight":59798,"prior_delay":25,"prior_completion_time":0},
{"oid":3287,"gid":546,"fullname":"Slav Defense, Quiet Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.e3 a6 *","weight":60078,"prior_delay":25,"prior_completion_time":0},
{"oid":3288,"gid":552,"fullname":"Slav Defense, S\u00c3\u00bcchting Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 Qb6 *","weight":62533,"prior_delay":25,"prior_completion_time":0},
{"oid":3289,"gid":547,"fullname":"Slav Defense, Schlechter Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 c6 5.cxd5 cxd5 *","weight":50759,"prior_delay":25,"prior_completion_time":0},
{"oid":3290,"gid":547,"fullname":"Slav Defense, Schlechter Variation","main_line":"1.d4 Nf6 2.c4 g6 3.Nc3 d5 4.Nf3 c6 5.Qb3 *","weight":50723,"prior_delay":25,"prior_completion_time":0},
{"oid":3291,"gid":548,"fullname":"Slav Defense, Slav Gambit, Alekhine Attack","main_line":"1.d4 d5 2.c4 c6 3.Nc3 dxc4 4.e4 b5 5.a4 *","weight":45432,"prior_delay":25,"prior_completion_time":0},
{"oid":3292,"gid":549,"fullname":"Slav Defense, Smyslov Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Na6 6.e3 Bg4 *","weight":67711,"prior_delay":25,"prior_completion_time":0},
{"oid":3293,"gid":549,"fullname":"Slav Defense, Smyslov Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Na6 6.Ne5 *","weight":67672,"prior_delay":25,"prior_completion_time":0},
{"oid":3294,"gid":550,"fullname":"Slav Defense, Soultanbeieff Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 e6 *","weight":67972,"prior_delay":25,"prior_completion_time":0},
{"oid":3295,"gid":551,"fullname":"Slav Defense, Steiner Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bg4 6.Ne5 Bh5 7.h3 *","weight":67803,"prior_delay":25,"prior_completion_time":0},
{"oid":3296,"gid":551,"fullname":"Slav Defense, Steiner Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bg4 6.Ne5 Bh5 7.g3 *","weight":67806,"prior_delay":25,"prior_completion_time":0},
{"oid":3297,"gid":551,"fullname":"Slav Defense, Steiner Variation","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.a4 Bg4 6.Ne5 Bh5 7.f3 Nfd7  8.Nxc4 e5 9.Ne4 *","weight":67888,"prior_delay":25,"prior_completion_time":0},
{"oid":3298,"gid":553,"fullname":"Slav Defense, Two Knights Attack","main_line":"1.d4 d5 2.c4 c6 3.Nf3 Nf6 4.Nc3 dxc4 5.Ne5 *","weight":64776,"prior_delay":25,"prior_completion_time":0},
{"oid":3299,"gid":554,"fullname":"Slav Defense, Winawer Countergambit","main_line":"1.d4 d5 2.c4 c6 3.Nc3 e5 4.cxd5 cxd5 5.Nf3 *","weight":45059,"prior_delay":25,"prior_completion_time":0},
{"oid":3300,"gid":554,"fullname":"Slav Defense, Winawer Countergambit","main_line":"1.d4 d5 2.c4 c6 3.Nc3 e5 4.dxe5 d4 5.Ne4 Qa5+ 6.Nd2 *","weight":45167,"prior_delay":25,"prior_completion_time":0},
{"oid":3301,"gid":554,"fullname":"Slav Defense, Winawer Countergambit","main_line":"1.d4 d5 2.c4 c6 3.Nc3 e5 4.dxe5 d4 5.Ne4 Qa5+ 6.Bd2 *","weight":45163,"prior_delay":25,"prior_completion_time":0},
{"oid":3302,"gid":554,"fullname":"Slav Defense, Winawer Countergambit","main_line":"1.d4 d5 2.c4 c6 3.Nc3 e5 4.cxd5 cxd5 5.dxe5 *","weight":45060,"prior_delay":25,"prior_completion_time":0},
{"oid":3303,"gid":543,"fullname":"Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nc3 Nf6 4.e3 a6 5.Qc2 b5 *","weight":51884,"prior_delay":25,"prior_completion_time":0},
{"oid":3304,"gid":543,"fullname":"Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nc3 Nf6 4.e3 Bf5 *","weight":50960,"prior_delay":25,"prior_completion_time":0},
{"oid":3305,"gid":543,"fullname":"Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 c6 3.e3 Nf6 *","weight":41460,"prior_delay":25,"prior_completion_time":0},
{"oid":3306,"gid":543,"fullname":"Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 c6 3.e3 Bf5 *","weight":41161,"prior_delay":25,"prior_completion_time":0},
{"oid":3307,"gid":543,"fullname":"Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nc3 dxc4 4.a4 *","weight":45162,"prior_delay":25,"prior_completion_time":0},
{"oid":3308,"gid":543,"fullname":"Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nc3 Nf6 4.e3 a6 5.Bd3 *","weight":51610,"prior_delay":25,"prior_completion_time":0},
{"oid":3309,"gid":543,"fullname":"Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nc3 Nf6 4.Bg5 dxc4 *","weight":48356,"prior_delay":25,"prior_completion_time":0},
{"oid":3310,"gid":543,"fullname":"Slav Defense, Main Line","main_line":"1.d4 d5 2.c4 c6 3.Nc3 dxc4 4.e3 *","weight":45233,"prior_delay":25,"prior_completion_time":0},
{"oid":3311,"gid":555,"fullname":"Tarrasch Defense, Classical Variation, Advance Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be7  8.O-O O-O 9.Bg5 c4 10.Ne5 Be6 11.Nxc6 *","weight":39458,"prior_delay":25,"prior_completion_time":0},
{"oid":3312,"gid":555,"fullname":"Tarrasch Defense, Classical Variation, Classical Tarrasch Gambit","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be7  8.O-O O-O 9.dxc5 d4 *","weight":39192,"prior_delay":25,"prior_completion_time":0},
{"oid":3313,"gid":555,"fullname":"Tarrasch Defense, Classical Variation, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be7  8.O-O O-O 9.Bg5 cxd4 10.Nxd4 h6 11.Be3 Re8 12.Rc1 Bf8 13.Nxc6 *","weight":39928,"prior_delay":25,"prior_completion_time":0},
{"oid":3314,"gid":555,"fullname":"Tarrasch Defense, Classical Variation, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be7  8.O-O O-O 9.Bg5 cxd4 10.Nxd4 h6 11.Be3 Re8 12.Qb3 *","weight":39854,"prior_delay":25,"prior_completion_time":0},
{"oid":3315,"gid":555,"fullname":"Tarrasch Defense, Classical Variation, Reti Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be7  8.O-O O-O 9.dxc5 Bxc5 10.Na4 *","weight":39324,"prior_delay":25,"prior_completion_time":0},
{"oid":3316,"gid":555,"fullname":"Tarrasch Defense, Classical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be7  8.O-O O-O 9.b3 Ne4 *","weight":39068,"prior_delay":25,"prior_completion_time":0},
{"oid":3317,"gid":555,"fullname":"Tarrasch Defense, Classical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be7  8.O-O O-O 9.dxc5 Bxc5 10.Bg5 d4 11.Bxf6 Qxf6 12.Nd5 Qd8 13.Nd2 Re8 *","weight":39594,"prior_delay":25,"prior_completion_time":0},
{"oid":3318,"gid":555,"fullname":"Tarrasch Defense, Classical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be7  8.O-O O-O 9.Bf4 *","weight":39060,"prior_delay":25,"prior_completion_time":0},
{"oid":3319,"gid":555,"fullname":"Tarrasch Defense, Classical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be7  8.O-O O-O 9.Be3 *","weight":39060,"prior_delay":25,"prior_completion_time":0},
{"oid":3320,"gid":555,"fullname":"Tarrasch Defense, Classical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be7  8.O-O O-O 9.dxc5 Bxc5 10.Bg5 Be6 11.Bxf6 *","weight":39350,"prior_delay":25,"prior_completion_time":0},
{"oid":3321,"gid":557,"fullname":"Tarrasch Defense, Prague Variation, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be7 8.O-O Be6 *","weight":38815,"prior_delay":25,"prior_completion_time":0},
{"oid":3322,"gid":557,"fullname":"Tarrasch Defense, Prague Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Be6 *","weight":38416,"prior_delay":25,"prior_completion_time":0},
{"oid":3323,"gid":558,"fullname":"Tarrasch Defense, Rubinstein System","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 cxd4 *","weight":38060,"prior_delay":25,"prior_completion_time":0},
{"oid":3324,"gid":559,"fullname":"Tarrasch Defense, Swedish Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 c4 7.Bg2 Bb4  8.O-O Nge7 9.e4 dxe4 *","weight":38144,"prior_delay":25,"prior_completion_time":0},
{"oid":3325,"gid":559,"fullname":"Tarrasch Defense, Swedish Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 c4 7.Bg2 Bb4  8.O-O Nge7 9.Bd2 *","weight":38136,"prior_delay":25,"prior_completion_time":0},
{"oid":3326,"gid":559,"fullname":"Tarrasch Defense, Swedish Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 c4 7.Bg2 Bb4  8.O-O Nge7 9.Ne5 *","weight":38131,"prior_delay":25,"prior_completion_time":0},
{"oid":3327,"gid":559,"fullname":"Tarrasch Defense, Swedish Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 c4 7.Bg2 Bb4  8.O-O Nge7 9.a3 *","weight":38132,"prior_delay":25,"prior_completion_time":0},
{"oid":3328,"gid":560,"fullname":"Tarrasch Defense, Symmetrical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.e3 Nc6 6.a3 a6 7.dxc5 Bxc5 8.b4 Ba7 *","weight":39480,"prior_delay":25,"prior_completion_time":0},
{"oid":3329,"gid":560,"fullname":"Tarrasch Defense, Symmetrical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.e3 Nc6 6.a3 Ne4 *","weight":39127,"prior_delay":25,"prior_completion_time":0},
{"oid":3330,"gid":560,"fullname":"Tarrasch Defense, Symmetrical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.e3 Nc6 6.a3 dxc4 *","weight":39126,"prior_delay":25,"prior_completion_time":0},
{"oid":3331,"gid":560,"fullname":"Tarrasch Defense, Symmetrical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.e3 Nc6 6.a3 cxd4 7.exd4 Be7 8.Bd3 *","weight":39226,"prior_delay":25,"prior_completion_time":0},
{"oid":3332,"gid":560,"fullname":"Tarrasch Defense, Symmetrical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.e3 Nc6 6.a3 a6 7.b3 *","weight":39217,"prior_delay":25,"prior_completion_time":0},
{"oid":3333,"gid":560,"fullname":"Tarrasch Defense, Symmetrical Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 Nf6 4.Nf3 c5 5.e3 Nc6 6.a3 cxd4 7.exd4 Be7 8.c5 *","weight":39225,"prior_delay":25,"prior_completion_time":0},
{"oid":3334,"gid":561,"fullname":"Tarrasch Defense, Tarrasch Gambit","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.dxc5 d4 6.Na4 b5 *","weight":37487,"prior_delay":25,"prior_completion_time":0},
{"oid":3335,"gid":562,"fullname":"Tarrasch Defense, Two Knights Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.Bg5 Be7 *","weight":37856,"prior_delay":25,"prior_completion_time":0},
{"oid":3336,"gid":562,"fullname":"Tarrasch Defense, Two Knights Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.e3 Nf6 7.Be2 cxd4  8.Nxd4 Bd6 9.O-O O-O 10.b3 *","weight":38007,"prior_delay":25,"prior_completion_time":0},
{"oid":3337,"gid":562,"fullname":"Tarrasch Defense, Two Knights Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.Bf4 Nf6 *","weight":37866,"prior_delay":25,"prior_completion_time":0},
{"oid":3338,"gid":562,"fullname":"Tarrasch Defense, Two Knights Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.e3 Nf6 7.Bb5 *","weight":37933,"prior_delay":25,"prior_completion_time":0},
{"oid":3339,"gid":563,"fullname":"Tarrasch Defense, Wagner Variation","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.cxd5 exd5 5.Nf3 Nc6 6.g3 Nf6 7.Bg2 Bg4 *","weight":38418,"prior_delay":25,"prior_completion_time":0},
{"oid":3340,"gid":556,"fullname":"Tarrasch Defense, Main Line","main_line":"1.d4 d5 2.c4 e6 3.Nc3 c5 4.e3 *","weight":37129,"prior_delay":25,"prior_completion_time":0},
{"oid":3341,"gid":564,"fullname":"Torre Attack, Classical Defense, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 h6 4.Bxf6 Qxf6 5.e4 d6 6.Nc3 Nd7 7.Qd2 *","weight":26659,"prior_delay":25,"prior_completion_time":0},
{"oid":3342,"gid":564,"fullname":"Torre Attack, Classical Defense, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 h6 4.Bh4 g5 *","weight":26585,"prior_delay":25,"prior_completion_time":0},
{"oid":3343,"gid":564,"fullname":"Torre Attack, Classical Defense, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 h6 4.Bxf6 Qxf6 5.e4 b6 *","weight":26560,"prior_delay":25,"prior_completion_time":0},
{"oid":3344,"gid":564,"fullname":"Torre Attack, Classical Defense, Nimzowitsch Variation","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 h6 4.Bxf6 Qxf6 5.e4 d6 6.Nc3 g6 *","weight":26651,"prior_delay":25,"prior_completion_time":0},
{"oid":3345,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.Bg5 Ne4 *","weight":26284,"prior_delay":25,"prior_completion_time":0},
{"oid":3346,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.Bg5 Bg7 4.Nbd2 O-O 5.e4 d5 *","weight":27436,"prior_delay":25,"prior_completion_time":0},
{"oid":3347,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 b6 *","weight":26340,"prior_delay":25,"prior_completion_time":0},
{"oid":3348,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 c5 4.e3 cxd4 *","weight":26648,"prior_delay":25,"prior_completion_time":0},
{"oid":3349,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 Be7 *","weight":26349,"prior_delay":25,"prior_completion_time":0},
{"oid":3350,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.Bg5 Bg7 4.Nbd2 O-O 5.e4 d6 *","weight":27432,"prior_delay":25,"prior_completion_time":0},
{"oid":3351,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 c5 4.c3 Qb6 *","weight":26524,"prior_delay":25,"prior_completion_time":0},
{"oid":3352,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 c5 4.e3 Be7 *","weight":26680,"prior_delay":25,"prior_completion_time":0},
{"oid":3353,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.Bg5 Bg7 4.Nbd2 O-O 5.c3 d6 6.e4 c5 *","weight":27804,"prior_delay":25,"prior_completion_time":0},
{"oid":3354,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 c5 4.c3 h6 5.Bh4 *","weight":26530,"prior_delay":25,"prior_completion_time":0},
{"oid":3355,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 c5 4.e3 Qb6 *","weight":26660,"prior_delay":25,"prior_completion_time":0},
{"oid":3356,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.Bg5 Bg7 4.Nc3 *","weight":26755,"prior_delay":25,"prior_completion_time":0},
{"oid":3357,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 e6 3.Bg5 c5 4.e3 h6 5.Bh4 *","weight":26699,"prior_delay":25,"prior_completion_time":0},
{"oid":3358,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.Bg5 Bg7 4.c3 *","weight":26833,"prior_delay":25,"prior_completion_time":0},
{"oid":3359,"gid":565,"fullname":"Torre Attack, Main Line","main_line":"1.d4 Nf6 2.Nf3 g6 3.Bg5 Bg7 4.e3 *","weight":26796,"prior_delay":25,"prior_completion_time":0},
{"oid":3360,"gid":566,"fullname":"Trompowsky Attack, Classical Defense, Big Center Variation","main_line":"1.d4 Nf6 2.Bg5 e6 3.e4 h6 4.Bxf6 Qxf6 5.Nc3 d6 6.Qd2 g5 *","weight":22369,"prior_delay":25,"prior_completion_time":0},
{"oid":3361,"gid":566,"fullname":"Trompowsky Attack, Classical Defense, Big Center Variation","main_line":"1.d4 Nf6 2.Bg5 e6 3.e4 h6 4.Bxf6 Qxf6 5.c3 *","weight":22317,"prior_delay":25,"prior_completion_time":0},
{"oid":3362,"gid":566,"fullname":"Trompowsky Attack, Classical Defense, Big Center Variation","main_line":"1.d4 Nf6 2.Bg5 e6 3.e4 h6 4.Bxf6 Qxf6 5.Nc3 Bb4 *","weight":22338,"prior_delay":25,"prior_completion_time":0},
{"oid":3363,"gid":567,"fullname":"Trompowsky Attack, Edge Variation","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.Bh4 d5 *","weight":21745,"prior_delay":25,"prior_completion_time":0},
{"oid":3364,"gid":567,"fullname":"Trompowsky Attack, Edge Variation","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.Bh4 c5 4.f3 *","weight":21759,"prior_delay":25,"prior_completion_time":0},
{"oid":3365,"gid":567,"fullname":"Trompowsky Attack, Edge Variation","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.Bh4 g5 *","weight":21740,"prior_delay":25,"prior_completion_time":0},
{"oid":3366,"gid":569,"fullname":"Trompowsky Attack, Poisoned Pawn Variation","main_line":"1.d4 Nf6 2.Bg5 c5 3.d5 Qb6 4.Nc3 *","weight":21547,"prior_delay":25,"prior_completion_time":0},
{"oid":3367,"gid":570,"fullname":"Trompowsky Attack, Raptor Variation","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.h4 c5 4.dxc5 *","weight":21773,"prior_delay":25,"prior_completion_time":0},
{"oid":3368,"gid":570,"fullname":"Trompowsky Attack, Raptor Variation","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.h4 d5 *","weight":21759,"prior_delay":25,"prior_completion_time":0},
{"oid":3369,"gid":570,"fullname":"Trompowsky Attack, Raptor Variation","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.h4 c5 4.d5 *","weight":21775,"prior_delay":25,"prior_completion_time":0},
{"oid":3370,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 d5 3.Bxf6 exf6 4.e3 Bf5 *","weight":21968,"prior_delay":25,"prior_completion_time":0},
{"oid":3371,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.Bf4 c5 4.d5 Qb6 *","weight":22359,"prior_delay":25,"prior_completion_time":0},
{"oid":3372,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.Bf4 d5 4.e3 c5 *","weight":22372,"prior_delay":25,"prior_completion_time":0},
{"oid":3373,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 d5 3.e3 e6 *","weight":22027,"prior_delay":25,"prior_completion_time":0},
{"oid":3374,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 g6 3.Bxf6 exf6 4.e3 *","weight":21506,"prior_delay":25,"prior_completion_time":0},
{"oid":3375,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 c5 3.Bxf6 gxf6 4.d5 Qb6 5.Qc1 f5 6.e3 Bg7 *","weight":21755,"prior_delay":25,"prior_completion_time":0},
{"oid":3376,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.Bf4 d5 4.f3 Nf6 *","weight":22265,"prior_delay":25,"prior_completion_time":0},
{"oid":3377,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 d5 3.Bxf6 exf6 4.e3 c6 *","weight":21980,"prior_delay":25,"prior_completion_time":0},
{"oid":3378,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 d5 3.Bxf6 gxf6 4.e3 c5 *","weight":21870,"prior_delay":25,"prior_completion_time":0},
{"oid":3379,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 d5 3.Bxf6 exf6 4.e3 Be6 *","weight":21976,"prior_delay":25,"prior_completion_time":0},
{"oid":3380,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 d5 3.Bxf6 exf6 4.e3 Bd6 *","weight":22011,"prior_delay":25,"prior_completion_time":0},
{"oid":3381,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.Bf4 c5 4.f3 Qa5+ 5.c3 Nf6 6.Nd2 *","weight":22918,"prior_delay":25,"prior_completion_time":0},
{"oid":3382,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 c5 3.Bxf6 gxf6 4.d5 Qb6 5.Qc1 f5 6.c4 *","weight":21773,"prior_delay":25,"prior_completion_time":0},
{"oid":3383,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 c5 3.Bxf6 gxf6 4.d5 Qb6 5.Qc1 f5 6.g3 *","weight":21764,"prior_delay":25,"prior_completion_time":0},
{"oid":3384,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.Bf4 d5 4.Nd2 *","weight":22229,"prior_delay":25,"prior_completion_time":0},
{"oid":3385,"gid":568,"fullname":"Trompowsky Attack, Main Line","main_line":"1.d4 Nf6 2.Bg5 Ne4 3.Bf4 c5 4.f3 Qa5+ 5.c3 Nf6 6.d5 *","weight":22951,"prior_delay":25,"prior_completion_time":0},
{"oid":3386,"gid":571,"fullname":"Wade Defense, Main Line","main_line":"1.d4 d6 2.Nf3 Bg4 3.e4 Nf6 *","weight":13779,"prior_delay":25,"prior_completion_time":0},
{"oid":3387,"gid":571,"fullname":"Wade Defense, Main Line","main_line":"1.d4 d6 2.Nf3 Bg4 3.c4 e5 4.Nc3 Nc6 *","weight":13805,"prior_delay":25,"prior_completion_time":0},
{"oid":3388,"gid":571,"fullname":"Wade Defense, Main Line","main_line":"1.d4 d6 2.Nf3 Bg4 3.c4 Nd7 4.Nc3 e5 *","weight":13926,"prior_delay":25,"prior_completion_time":0},
{"oid":3389,"gid":571,"fullname":"Wade Defense, Main Line","main_line":"1.d4 d6 2.Nf3 Bg4 3.c4 Bxf3 *","weight":13847,"prior_delay":25,"prior_completion_time":0},
{"oid":3390,"gid":571,"fullname":"Wade Defense, Main Line","main_line":"1.d4 d6 2.Nf3 Bg4 3.e3 Nf6 *","weight":13625,"prior_delay":25,"prior_completion_time":0},
{"oid":3391,"gid":571,"fullname":"Wade Defense, Main Line","main_line":"1.d4 d6 2.Nf3 Bg4 3.e3 Nd7 *","weight":13630,"prior_delay":25,"prior_completion_time":0},
{"oid":3392,"gid":572,"fullname":"Yusupov-Rubinstein System, Main Line","main_line":"1.d4 Nf6 2.Nf3 e6 3.e3 c5 4.Bd3 *","weight":26635,"prior_delay":25,"prior_completion_time":0},
{"oid":3393,"gid":573,"fullname":"King's Indian Attack, French Variation","main_line":"1.Nf3 d5 2.g3 c5 3.Bg2 Nc6 4.O-O e6 5.d3 Nf6 6.Nbd2 g6 7.e4 Bg7 *","weight":13852,"prior_delay":25,"prior_completion_time":0},
{"oid":3394,"gid":573,"fullname":"King's Indian Attack, French Variation","main_line":"1.Nf3 d5 2.g3 c5 3.Bg2 Nc6 4.O-O e6 5.d3 Nf6 6.Nbd2 b6 7.e4 *","weight":13829,"prior_delay":25,"prior_completion_time":0},
{"oid":3395,"gid":573,"fullname":"King's Indian Attack, French Variation","main_line":"1.Nf3 d5 2.g3 c5 3.Bg2 Nc6 4.d4 *","weight":13856,"prior_delay":25,"prior_completion_time":0},
{"oid":3396,"gid":574,"fullname":"King's Indian Attack, Keres Variation","main_line":"1.Nf3 d5 2.g3 Bg4 3.Bg2 Nd7 *","weight":14644,"prior_delay":25,"prior_completion_time":0},
{"oid":3397,"gid":576,"fullname":"King's Indian Attack, Pachman System","main_line":"1.Nf3 d5 2.g3 g6 3.Bg2 Bg7 4.O-O e5 5.d3 Ne7 *","weight":15119,"prior_delay":25,"prior_completion_time":0},
{"oid":3398,"gid":577,"fullname":"King's Indian Attack, Sicilian Variation","main_line":"1.Nf3 d5 2.g3 c5 3.Bg2 Nf6 4.O-O Nc6 *","weight":13831,"prior_delay":25,"prior_completion_time":0},
{"oid":3399,"gid":577,"fullname":"King's Indian Attack, Sicilian Variation","main_line":"1.Nf3 d5 2.g3 c5 3.Bg2 g6 4.O-O Bg7 5.d3 Nf6 6.Nbd2 O-O *","weight":13694,"prior_delay":25,"prior_completion_time":0},
{"oid":3400,"gid":577,"fullname":"King's Indian Attack, Sicilian Variation","main_line":"1.Nf3 d5 2.g3 c5 3.Bg2 Nc6 4.O-O e6 5.d3 Nf6 6.Nbd2 Be7 7.e4 O-O 8.Re1 *","weight":14027,"prior_delay":25,"prior_completion_time":0},
{"oid":3401,"gid":578,"fullname":"King's Indian Attack, Smyslov Variation","main_line":"1.Nf3 Nf6 2.g3 g6 3.b4 Bg7 5.Bb2 *","weight":17115,"prior_delay":25,"prior_completion_time":0},
{"oid":3402,"gid":579,"fullname":"King's Indian Attack, Symmetrical Defense","main_line":"1.Nf3 Nf6 2.g3 g6 3.Bg2 Bg7 4.O-O O-O 5.d3 d6 *","weight":18877,"prior_delay":25,"prior_completion_time":0},
{"oid":3403,"gid":579,"fullname":"King's Indian Attack, Symmetrical Defense","main_line":"1.Nf3 Nf6 2.g3 g6 3.Bg2 Bg7 4.O-O O-O 5.d3 c5 *","weight":18886,"prior_delay":25,"prior_completion_time":0},
{"oid":3404,"gid":580,"fullname":"King's Indian Attack, Yugoslav Variation","main_line":"1.Nf3 d5 2.g3 Nf6 3.Bg2 c6 4.O-O Bg4 5.d3 Nbd7 6.Nbd2 e6 *","weight":17929,"prior_delay":25,"prior_completion_time":0},
{"oid":3405,"gid":580,"fullname":"King's Indian Attack, Yugoslav Variation","main_line":"1.Nf3 d5 2.g3 Nf6 3.Bg2 c6 4.O-O Bg4 5.d3 Nbd7 6.Nbd2 e5 *","weight":17994,"prior_delay":25,"prior_completion_time":0},
{"oid":3406,"gid":575,"fullname":"King's Indian Attack, Main Line","main_line":"1.Nf3 d5 2.g3 Nf6 3.Bg2 e6 *","weight":16024,"prior_delay":25,"prior_completion_time":0},
{"oid":3407,"gid":575,"fullname":"King's Indian Attack, Main Line","main_line":"1.Nf3 d5 2.g3 Nf6 3.Bg2 Bf5 *","weight":15756,"prior_delay":25,"prior_completion_time":0},
{"oid":3408,"gid":575,"fullname":"King's Indian Attack, Main Line","main_line":"1.Nf3 d5 2.g3 Nf6 3.Bg2 g6 *","weight":15836,"prior_delay":25,"prior_completion_time":0},
{"oid":3409,"gid":575,"fullname":"King's Indian Attack, Main Line","main_line":"1.Nf3 d5 2.g3 Nc6 3.Bg2 e5 4.d3 Nf6 5.O-O Be7 *","weight":13633,"prior_delay":25,"prior_completion_time":0},
{"oid":3410,"gid":575,"fullname":"King's Indian Attack, Main Line","main_line":"1.Nf3 d5 2.g3 Nf6 3.Bg2 c6 4.O-O Bf5 5.d3 e6 6.Nbd2 *","weight":17528,"prior_delay":25,"prior_completion_time":0},
{"oid":3411,"gid":575,"fullname":"King's Indian Attack, Main Line","main_line":"1.Nf3 d5 2.g3 c6 3.Bg2 Bg4 4.O-O Nd7 5.d3 *","weight":16421,"prior_delay":25,"prior_completion_time":0},
{"oid":3412,"gid":581,"fullname":"Nimzo-Larsen Attack, Classical Variation","main_line":"1.Nf3 d5 2.b3 Nf6 3.Bb2 e6 4.e3 *","weight":10752,"prior_delay":25,"prior_completion_time":0},
{"oid":3413,"gid":581,"fullname":"Nimzo-Larsen Attack, Classical Variation","main_line":"1.Nf3 d5 2.b3 Bg4 3.Bb2 Nd7 4.e3 *","weight":10723,"prior_delay":25,"prior_completion_time":0},
{"oid":3414,"gid":582,"fullname":"Reti Opening, Advance Variation","main_line":"1.Nf3 d5 2.c4 d4 3.g3 g6 4.Bg2 Bg7 *","weight":12248,"prior_delay":25,"prior_completion_time":0},
{"oid":3415,"gid":582,"fullname":"Reti Opening, Advance Variation","main_line":"1.Nf3 d5 2.c4 d4 3.b4 g6 *","weight":12183,"prior_delay":25,"prior_completion_time":0},
{"oid":3416,"gid":582,"fullname":"Reti Opening, Advance Variation","main_line":"1.Nf3 d5 2.c4 d4 3.e3 c5 *","weight":12080,"prior_delay":25,"prior_completion_time":0},
{"oid":3417,"gid":582,"fullname":"Reti Opening, Advance Variation","main_line":"1.Nf3 d5 2.c4 d4 3.e3 Nc6 4.exd4 Nxd4 *","weight":12196,"prior_delay":25,"prior_completion_time":0},
{"oid":3418,"gid":582,"fullname":"Reti Opening, Advance Variation","main_line":"1.Nf3 d5 2.c4 d4 3.g3 c5 4.Bg2 Nc6 5.d3 e5 *","weight":12217,"prior_delay":25,"prior_completion_time":0},
{"oid":3419,"gid":582,"fullname":"Reti Opening, Advance Variation","main_line":"1.Nf3 d5 2.c4 d4 3.g3 Nc6 4.Bg2 e5 *","weight":12344,"prior_delay":25,"prior_completion_time":0},
{"oid":3420,"gid":584,"fullname":"Reti Opening, Reti Gambit Accepted","main_line":"1.Nf3 d5 2.c4 dxc4 3.e3 Nf6 4.Bxc4 e6 *","weight":11941,"prior_delay":25,"prior_completion_time":0},
{"oid":3421,"gid":584,"fullname":"Reti Opening, Reti Gambit Accepted","main_line":"1.Nf3 d5 2.c4 dxc4 3.Na3 c5 *","weight":11807,"prior_delay":25,"prior_completion_time":0},
{"oid":3422,"gid":584,"fullname":"Reti Opening, Reti Gambit Accepted","main_line":"1.Nf3 d5 2.c4 dxc4 3.Na3 a6 *","weight":11812,"prior_delay":25,"prior_completion_time":0},
{"oid":3423,"gid":584,"fullname":"Reti Opening, Reti Gambit Accepted, Keres Variation","main_line":"1.Nf3 d5 2.c4 dxc4 3.e3 Be6 *","weight":11855,"prior_delay":25,"prior_completion_time":0},
{"oid":3424,"gid":583,"fullname":"Reti Opening, Main Line","main_line":"1.Nf3 Nf6 2.g3 c5 3.Bg2 Nc6 *","weight":16599,"prior_delay":25,"prior_completion_time":0},
{"oid":3425,"gid":583,"fullname":"Reti Opening, Main Line","main_line":"1.Nf3 Nf6 2.g3 b6 3.Bg2 Bb7 *","weight":17049,"prior_delay":25,"prior_completion_time":0},
{"oid":3426,"gid":585,"fullname":"Zukertort Opening, Lisitsyn Gambit","main_line":"1.Nf3 f5 2.e4 fxe4 3.Ng5 d5 *","weight":4328,"prior_delay":25,"prior_completion_time":0},
{"oid":3427,"gid":586,"fullname":"Zukertort Opening, Sicilian Invitation","main_line":"1.Nf3 c5 2.g3 b6 3.Bg2 Bb7 *","weight":7188,"prior_delay":25,"prior_completion_time":0},
{"oid":3428,"gid":586,"fullname":"Zukertort Opening, Sicilian Invitation","main_line":"1.Nf3 c5 2.g3 g6 3.Bg2 Bg7 4.O-O Nc6 5.d3 Nf6 6.e4 *","weight":7707,"prior_delay":25,"prior_completion_time":0},
{"oid":3429,"gid":586,"fullname":"Zukertort Opening, Sicilian Invitation","main_line":"1.Nf3 c5 2.g3 g6 3.Bg2 Bg7 4.O-O Nc6 5.d3 d6 6.e4 *","weight":7712,"prior_delay":25,"prior_completion_time":0},
{"oid":3430,"gid":586,"fullname":"Zukertort Opening, Sicilian Invitation","main_line":"1.Nf3 c5 2.g3 g6 3.Bg2 Bg7 4.O-O Nc6 5.d3 e6 6.e4 *","weight":7696,"prior_delay":25,"prior_completion_time":0}]
});
