var tree_data = [{id : "1e4", name : "King's Pawn Openings (1.e4)", children : [{id : "Alekhine Defense", name : "Alekhine Defense", children : [{id : 16, name : "Balogh Variation"}, 
		{id : 17, name : "Exchange Variation"}, 
		{id : 18, name : "Four Pawns Attack"}, 
		{id : 19, name : "Kmoch Variation"}, 
		{id : 21, name : "Modern Variation"}, 
		{id : 22, name : "Modern,Flohr Variation"}, 
		{id : 23, name : "Samisch Attack"}, 
		{id : 24, name : "Scandinavian Variation"}, 
		{id : 25, name : "Spielmann Gambit"}, 
		{id : 26, name : "Two Pawn Attack"}, 
		{id : 20, name : "Main Line"}]}, 
	{id : "Bishop's Opening", name : "Bishop's Opening", children : [{id : 27, name : "Berlin Defense"}, 
		{id : 28, name : "Urusov Gambit"}, 
		{id : 29, name : "Vienna Hybrid"}]}, 
	{id : "Caro-Kann Defense", name : "Caro-Kann Defense", children : [{id : 30, name : "Accelerated Panov Attack"}, 
		{id : 31, name : "Advance"}, 
		{id : 32, name : "Advance Variation"}, 
		{id : 33, name : "Breyer Variation"}, 
		{id : 34, name : "Bronstein-Larsen Variation"}, 
		{id : 35, name : "Classical"}, 
		{id : 36, name : "Classical Variation"}, 
		{id : 37, name : "Edinburgh Variation"}, 
		{id : 38, name : "Exchange Variation"}, 
		{id : 39, name : "Forgacs Variation"}, 
		{id : 40, name : "Gurgenidze Counterattack"}, 
		{id : 41, name : "Gurgenidze System"}, 
		{id : 42, name : "Karpov Variation"}, 
		{id : 43, name : "Main Line"}, 
		{id : 44, name : "Maroczy Variation"}, 
		{id : 45, name : "Modern Variation"}, 
		{id : 46, name : "Panov Attack"}, 
		{id : 47, name : "Panov Attack,Fianchetto Defense"}, 
		{id : 48, name : "Panov Attack,Fianchetto Defense,Fianchetto Gambit"}, 
		{id : 49, name : "Panov-Botvinnik"}, 
		{id : 50, name : "Rasa-Studier Gambit"}, 
		{id : 51, name : "Tartakower Variation"}, 
		{id : 52, name : "Two Knights Attack"}, 
		{id : 53, name : "von Hennig Gambit"}]}, 
	{id : "Czech Defense", name : "Czech Defense", children : [{id : 54, name : "Main Line"}]}, 
	{id : "Four Knights Game", name : "Four Knights Game", children : [{id : 55, name : "Bardeleben Variation"}, 
		{id : 56, name : "Gunsberg Variation"}, 
		{id : 57, name : "Italian Variation"}, 
		{id : 58, name : "Janowski Variation"}, 
		{id : 60, name : "Marshall Variation"}, 
		{id : 61, name : "Nimzowitsch (Paulsen)"}, 
		{id : 62, name : "Rubinstein Countergambit"}, 
		{id : 63, name : "Scotch Variation"}, 
		{id : 64, name : "Scotch Variation Accepted"}, 
		{id : 65, name : "Spanish Variation"}, 
		{id : 66, name : "Symmetrical"}, 
		{id : 59, name : "Main Line"}]}, 
	{id : "French Defense", name : "French Defense", children : [{id : 67, name : "Advance Variation"}, 
		{id : 68, name : "Alekhine-Chatard Attack"}, 
		{id : 69, name : "Burn Variation"}, 
		{id : 70, name : "Classical"}, 
		{id : 71, name : "Classical Variation"}, 
		{id : 72, name : "Exchange Variation"}, 
		{id : 73, name : "King's Indian Attack"}, 
		{id : 74, name : "MacCutcheon Variation"}, 
		{id : 75, name : "Paulsen Variation"}, 
		{id : 76, name : "Rubinstein Variation"}, 
		{id : 77, name : "Steinitz Variation"}, 
		{id : 78, name : "Tarrasch"}, 
		{id : 79, name : "Tarrasch Variation"}, 
		{id : 80, name : "Winawer Variation"}, 
		{id : 81, name : "Wing Gambit"}]}, 
	{id : "Giuoco Piano", name : "Giuoco Piano", children : [{id : 82, name : "Main Line"}]}, 
	{id : "Italian Game", name : "Italian Game", children : [{id : 83, name : "Classical Variation"}, 
		{id : 84, name : "Evans Gambit"}, 
		{id : 85, name : "Evans Gambit Declined"}, 
		{id : 86, name : "Giuoco Pianissimo"}, 
		{id : 87, name : "Giuoco Piano"}, 
		{id : 88, name : "Hungarian Defense"}, 
		{id : 90, name : "Schilling-Kostic Gambit"}, 
		{id : 91, name : "Scotch Gambit"}, 
		{id : 92, name : "Two Knights Defense"}, 
		{id : 89, name : "Main Line"}]}, 
	{id : "King's Gambit", name : "King's Gambit", children : [{id : 93, name : "Falkbeer Countergambit"}]}, 
	{id : "King's Gambit Accepted", name : "King's Gambit Accepted", children : [{id : 94, name : "Abbazia Defense"}, 
		{id : 95, name : "Becker Defense"}, 
		{id : 96, name : "Bishop's Gambit"}, 
		{id : 97, name : "Bonsch-Osmolovsky Variation"}, 
		{id : 98, name : "Cunningham Defense"}, 
		{id : 99, name : "Fischer Defense"}, 
		{id : 100, name : "Kieseritzky Gambit"}, 
		{id : 101, name : "Modern Defense"}, 
		{id : 102, name : "Schallopp Defense"}, 
		{id : 103, name : "Traditional Variation"}]}, 
	{id : "King's Gambit Declined", name : "King's Gambit Declined", children : [{id : 104, name : "Classical Variation"}, 
		{id : 105, name : "Falkbeer"}, 
		{id : 106, name : "Keene Defense"}]}, 
	{id : "King's Knight Opening", name : "King's Knight Opening", children : [{id : 107, name : "Normal Variation"}]}, 
	{id : "King's Pawn Game", name : "King's Pawn Game", children : [{id : 108, name : "Maroczy Defense"}, 
		{id : 109, name : "Tayler Opening"}]}, 
	{id : "Lion Defense", name : "Lion Defense", children : [{id : 110, name : "Lion's Jaw"}]}, 
	{id : "Modern Defense", name : "Modern Defense", children : [{id : 111, name : "Geller's System"}, 
		{id : 112, name : "Gurgenidze Defense"}, 
		{id : 113, name : "Lizard Defense"}, 
		{id : 115, name : "Modern Pterodactyl"}, 
		{id : 116, name : "Pseudo-Austrian Attack"}, 
		{id : 117, name : "Semi-Averbakh Variation"}, 
		{id : 118, name : "Standard Defense"}, 
		{id : 114, name : "Main Line"}]}, 
	{id : "Nimzo-Indian Defense", name : "Nimzo-Indian Defense (1.b3)", children : [{id : 119, name : "Panov Attack"}]}, 
	{id : "Nimzowitsch Defense", name : "Nimzowitsch Defense (1.b3)", children : [{id : 120, name : "Scandinavian Variation"}, 
		{id : 121, name : "Williams Variation"}]}, 
	{id : "Owen Defense", name : "Owen Defense", children : [{id : 122, name : "Main Line"}]}, 
	{id : "Philidor Defense", name : "Philidor Defense", children : [{id : 123, name : "Exchange Variation"}, 
		{id : 124, name : "Hanham Variation"}, 
		{id : 125, name : "Larsen Variation"}, 
		{id : 126, name : "Lion Variation"}, 
		{id : 127, name : "Nimzowitsch"}, 
		{id : 128, name : "Nimzowitsch Variation"}]}, 
	{id : "Pirc Defense", name : "Pirc Defense", children : [{id : 129, name : "Austrian Attack"}, 
		{id : 130, name : "Bayonet Attack"}, 
		{id : 131, name : "Byrne Variation"}, 
		{id : 132, name : "Chinese Variation"}, 
		{id : 133, name : "Classical Variation"}, 
		{id : 134, name : "Kholmov System"}, 
		{id : 136, name : "Sveshnikov System"}, 
		{id : 135, name : "Main Line"}]}, 
	{id : "Ponziani Opening", name : "Ponziani Opening", children : [{id : 137, name : "Caro Gambit"}, 
		{id : 138, name : "Jaenisch Counterattack"}, 
		{id : 139, name : "Leonhardt Variation"}, 
		{id : 140, name : "Romanishin Variation"}, 
		{id : 141, name : "Steinitz Variation"}, 
		{id : 142, name : "Vukovic Gambit"}]}, 
	{id : "Pterodactyl Defense", name : "Pterodactyl Defense", children : [{id : 143, name : "Sicilian"}, 
		{id : 144, name : "Western"}]}, 
	{id : "Rat Defense", name : "Rat Defense", children : [{id : 145, name : "Harmonist"}]}, 
	{id : "Robatsch (Modern) Defense", name : "Robatsch (Modern) Defense", children : [{id : 146, name : "Main Line"}]}, 
	{id : "Russian Game", name : "Russian Game", children : [{id : 147, name : "Classical Attack"}, 
		{id : 148, name : "Cochrane Gambit"}, 
		{id : 149, name : "Cozio (Lasker) Attack"}, 
		{id : 150, name : "Damiano Variation"}, 
		{id : 151, name : "French Attack"}, 
		{id : 152, name : "Kaufmann Attack"}, 
		{id : 153, name : "Modern Attack"}, 
		{id : 154, name : "Nimzowitsch Attack"}, 
		{id : 155, name : "Paulsen Attack"}, 
		{id : 156, name : "Three Knights Game"}]}, 
	{id : "Ruy Lopez", name : "Ruy Lopez", children : [{id : 157, name : "Alapin Defense"}, 
		{id : 158, name : "Berlin Defense"}, 
		{id : 159, name : "Bird Variation"}, 
		{id : 160, name : "Classical Variation"}, 
		{id : 161, name : "Closed"}, 
		{id : 162, name : "Closed Variations"}, 
		{id : 163, name : "Columbus Variation"}, 
		{id : 164, name : "Cozio Defense"}, 
		{id : 165, name : "Exchange"}, 
		{id : 166, name : "Exchange Variation"}, 
		{id : 167, name : "Fianchetto Defense"}, 
		{id : 168, name : "Marshall"}, 
		{id : 169, name : "Marshall Attack"}, 
		{id : 170, name : "Morphy Defense"}, 
		{id : 171, name : "Open"}, 
		{id : 172, name : "Open Berlin Defense"}, 
		{id : 173, name : "Open Variations"}, 
		{id : 174, name : "Schliemann Defense"}, 
		{id : 175, name : "Steinitz Defense"}, 
		{id : 176, name : "Steinitz Defense Deferred"}, 
		{id : 177, name : "Wormald Attack"}]}, 
	{id : "Scandinavian Defense", name : "Scandinavian Defense", children : [{id : 178, name : "Bronstein Variation"}, 
		{id : 179, name : "Classical Variation"}, 
		{id : 180, name : "Grunfeld Variation"}, 
		{id : 181, name : "Icelandic-Palme Gambit"}, 
		{id : 182, name : "Lasker Variation"}, 
		{id : 183, name : "Main Line"}, 
		{id : 184, name : "Marshall Variation"}, 
		{id : 185, name : "Mieses-Kotroc Variation"}, 
		{id : 186, name : "Modern Variation"}, 
		{id : 187, name : "Panov Transfer"}, 
		{id : 188, name : "Portuguese Variation"}, 
		{id : 189, name : "Richter Variation"}]}, 
	{id : "Scotch Game", name : "Scotch Game", children : [{id : 190, name : "Blumenfeld Attack"}, 
		{id : 191, name : "Classical Variation"}, 
		{id : 192, name : "Göring Gambit"}, 
		{id : 193, name : "Haxo Gambit"}, 
		{id : 195, name : "Malaniuk Variation"}, 
		{id : 196, name : "Meitner Variation"}, 
		{id : 197, name : "Mieses Variation"}, 
		{id : 198, name : "Romanishin Variation"}, 
		{id : 199, name : "Schmidt Variation"}, 
		{id : 200, name : "Scotch Gambit"}, 
		{id : 201, name : "Tartakower Variation"}, 
		{id : 194, name : "Main Line"}]}, 
	{id : "Sicilian Defense", name : "Sicilian Defense", children : [{id : 202, name : "Accelerated Dragon"}, 
		{id : 203, name : "AcceleratedFianchetto"}, 
		{id : 204, name : "Alapin Variation"}, 
		{id : 205, name : "Boleslavsky Variation"}, 
		{id : 206, name : "Canal Attack"}, 
		{id : 207, name : "Chekhover Variation"}, 
		{id : 208, name : "Classical Variation"}, 
		{id : 209, name : "Classical Variation,Fianchetto Variation"}, 
		{id : 210, name : "Closed"}, 
		{id : 211, name : "Closed Variation"}, 
		{id : 212, name : "Closed Variation,Fianchetto Variation"}, 
		{id : 213, name : "Delayed Alapin"}, 
		{id : 214, name : "Delayed Alapin Variation"}, 
		{id : 215, name : "Dragon"}, 
		{id : 216, name : "Dragon Variation"}, 
		{id : 217, name : "Dragon Variation,Fianchetto Variation"}, 
		{id : 218, name : "Flohr Variation"}, 
		{id : 219, name : "Four Knights Variation"}, 
		{id : 220, name : "French Variation"}, 
		{id : 221, name : "Godiva Variation"}, 
		{id : 222, name : "Grand Prix Attack"}, 
		{id : 223, name : "Heidenfeld Variation"}, 
		{id : 224, name : "Hyperaccelerated Dragon"}, 
		{id : 225, name : "Hyperaccelerated Pterodactyl"}, 
		{id : 226, name : "HyperacceleratedFianchetto"}, 
		{id : 227, name : "Kalashnikov Variation"}, 
		{id : 228, name : "Kan"}, 
		{id : 229, name : "Kan Variation"}, 
		{id : 230, name : "Kupreichik Variation"}, 
		{id : 231, name : "Kveinis Variation"}, 
		{id : 233, name : "Löwenthal Variation"}, 
		{id : 232, name : "Lasker-Pelikan Variation"}, 
		{id : 235, name : "Marshall Counterattack"}, 
		{id : 236, name : "McDonnell Attack"}, 
		{id : 237, name : "Modern Variations"}, 
		{id : 238, name : "Moscow Variation"}, 
		{id : 239, name : "Najdorf"}, 
		{id : 240, name : "Najdorf Variation"}, 
		{id : 241, name : "Nimzo-American Variation"}, 
		{id : 242, name : "Nimzowitsch Variation"}, 
		{id : 243, name : "Nyezhmetdinov-Rossolimo Attack"}, 
		{id : 244, name : "Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation"}, 
		{id : 245, name : "O'Kelly Variation"}, 
		{id : 246, name : "Old Sicilian"}, 
		{id : 247, name : "Open"}, 
		{id : 248, name : "Paulsen Variation"}, 
		{id : 249, name : "Paulsen-Basman Defense"}, 
		{id : 250, name : "Pin Variation"}, 
		{id : 251, name : "Prins Variation"}, 
		{id : 252, name : "Richter-Rauzer"}, 
		{id : 253, name : "Richter-Rauzer Variation"}, 
		{id : 254, name : "Scheveningen"}, 
		{id : 255, name : "Scheveningen Variation"}, 
		{id : 256, name : "Scheveningen Variation,Fianchetto Variation"}, 
		{id : 257, name : "Smith-Morra Gambit"}, 
		{id : 258, name : "Smith-Morra Gambit Accepted"}, 
		{id : 259, name : "Smith-Morra Gambit Declined"}, 
		{id : 260, name : "Sozin"}, 
		{id : 261, name : "Sozin Attack"}, 
		{id : 262, name : "Sozin Attack,Flank Variation"}, 
		{id : 263, name : "Sozin,Fischer Variation"}, 
		{id : 264, name : "Staunton-Cochrane Variation"}, 
		{id : 265, name : "Velimirovic Attack"}, 
		{id : 266, name : "Venice Attack"}, 
		{id : 234, name : "Main Line"}]}, 
	{id : "Three Knights Opening", name : "Three Knights Opening", children : [{id : 268, name : "Steinitz Defense"}, 
		{id : 267, name : "Main Line"}]}, 
	{id : "Vienna Game", name : "Vienna Game", children : [{id : 269, name : "Frankenstein-Dracula Variation"}, 
		{id : 270, name : "Mieses Variation"}, 
		{id : 271, name : "Paulsen Variation"}, 
		{id : 272, name : "Stanley Variation"}, 
		{id : 273, name : "Vienna Gambit"}]}]}, 
{id : "1d4", name : "Queen's Pawn Openings (1.d4)", children : [{id : "Benko Gambit", name : "Benko Gambit", children : [{id : 276, name : "Fianchetto Variation"}, 
		{id : 277, name : "Nd2 Variation"}, 
		{id : 278, name : "Nescafe Frappe Attack"}, 
		{id : 279, name : "Zaitsev Variation"}]}, 
	{id : "Benko Gambit Accepted", name : "Benko Gambit Accepted", children : [{id : 280, name : "Dlugy Variation"}, 
		{id : 281, name : "Fianchetto Variation"}, 
		{id : 282, name : "Fully Accepted Variation"}, 
		{id : 283, name : "King Walk Variation"}, 
		{id : 284, name : "Modern Variation"}, 
		{id : 285, name : "Pawn Return Variation"}, 
		{id : 286, name : "Yugoslav"}]}, 
	{id : "Benko Gambit Declined", name : "Benko Gambit Declined", children : [{id : 287, name : "Main Line"}, 
		{id : 288, name : "Quiet Line"}, 
		{id : 289, name : "Sosonko Variation"}]}, 
	{id : "Benoni Defense", name : "Benoni Defense", children : [{id : 290, name : "Benoni-Indian Defense"}, 
		{id : 291, name : "Classical"}, 
		{id : 292, name : "Classical Variation"}, 
		{id : 293, name : "Czech Benoni Defense"}, 
		{id : 294, name : "Fianchetto Variation"}, 
		{id : 295, name : "Four Pawns Attack"}, 
		{id : 296, name : "Hromodka System"}, 
		{id : 297, name : "King's Indian System"}, 
		{id : 298, name : "King's Pawn Line"}, 
		{id : 299, name : "Knight's Tour Variation"}, 
		{id : 301, name : "Mikenas Variation"}, 
		{id : 302, name : "Modern Variation"}, 
		{id : 303, name : "Old Benoni"}, 
		{id : 304, name : "Taimanov Variation"}, 
		{id : 305, name : "Uhlmann Variation"}, 
		{id : 306, name : "Woozle"}, 
		{id : 300, name : "Main Line"}]}, 
	{id : "Blumenfeld Countergambit", name : "Blumenfeld Countergambit", children : [{id : 307, name : "Dus-Khotimirsky Variation"}, 
		{id : 309, name : "Spielmann Variation"}, 
		{id : 308, name : "Main Line"}]}, 
	{id : "Blumenfeld Countergambit Accepted", name : "Blumenfeld Countergambit Accepted", children : [{id : 310, name : "Main Line"}]}, 
	{id : "Bogo-Indian Defense", name : "Bogo-Indian Defense", children : [{id : 311, name : "Exchange Variation"}, 
		{id : 312, name : "Grunfeld Variation"}, 
		{id : 313, name : "Nimzowitsch Variation"}, 
		{id : 314, name : "Retreat Variation"}, 
		{id : 315, name : "Vitolins Variation"}, 
		{id : 316, name : "Wade-Smyslov Variation"}]}, 
	{id : "Budapest Defense", name : "Budapest Defense", children : [{id : 317, name : "Adler Variation"}, 
		{id : 318, name : "Alekhine Variation"}, 
		{id : 320, name : "Rubinstein Variation"}, 
		{id : 319, name : "Main Line"}]}, 
	{id : "Catalan Opening", name : "Catalan Opening", children : [{id : 321, name : "Closed"}, 
		{id : 322, name : "Closed Variation"}, 
		{id : 323, name : "Hungarian Gambit"}, 
		{id : 325, name : "Open Defense"}, 
		{id : 324, name : "Main Line"}]}, 
	{id : "Colle System", name : "Colle System", children : [{id : 326, name : "Traditional Colle"}]}, 
	{id : "Dutch Defense", name : "Dutch Defense", children : [{id : 327, name : "Alekhine Variation"}, 
		{id : 328, name : "Blackburne Variation"}, 
		{id : 329, name : "Blackmar's Second Gambit"}, 
		{id : 330, name : "Classical Variation"}, 
		{id : 331, name : "Fianchetto Attack"}, 
		{id : 332, name : "Fianchetto Variation"}, 
		{id : 333, name : "Ilyin-Zhenevsky"}, 
		{id : 334, name : "Leningrad Variation"}, 
		{id : 335, name : "Nimzo-Dutch Variation"}, 
		{id : 336, name : "Queen's Knight Variation"}, 
		{id : 337, name : "Raphael Variation"}, 
		{id : 338, name : "Semi-Leningrad Variation"}, 
		{id : 339, name : "Staunton Gambit"}, 
		{id : 340, name : "Stonewall Variation"}]}, 
	{id : "East Indian Defense", name : "East Indian Defense", children : [{id : 341, name : "Main Line"}]}, 
	{id : "Grunfeld Defense", name : "Grunfeld Defense", children : [{id : 342, name : "Botvinnik Variation"}, 
		{id : 343, name : "Brinckmann Attack"}, 
		{id : 344, name : "Counterthrust Variation"}, 
		{id : 345, name : "Exchange"}, 
		{id : 346, name : "Exchange Variation"}, 
		{id : 347, name : "Flohr Variation"}, 
		{id : 348, name : "Lutikov Variation"}, 
		{id : 350, name : "Makogonov Variation"}, 
		{id : 351, name : "Opocensky Variation"}, 
		{id : 352, name : "Russian"}, 
		{id : 353, name : "Russian Variation"}, 
		{id : 354, name : "Stockholm Variation"}, 
		{id : 355, name : "Three Knights Variation"}, 
		{id : 356, name : "Zaitsev Gambit"}, 
		{id : 349, name : "Main Line"}]}, 
	{id : "Indian Game", name : "Indian Game", children : [{id : 357, name : "Anti-Grunfeld"}, 
		{id : 358, name : "Anti-Nimzo-Indian"}, 
		{id : 359, name : "Dzindzi-Indian Defense"}, 
		{id : 360, name : "King's Indian Variation"}, 
		{id : 361, name : "Knights Variation"}, 
		{id : 362, name : "London System"}, 
		{id : 363, name : "Normal Variation"}, 
		{id : 364, name : "Przepiorka Variation"}, 
		{id : 365, name : "Pseudo-Benko"}, 
		{id : 366, name : "Spielmann-Indian"}, 
		{id : 367, name : "Tartakower Attack"}, 
		{id : 368, name : "Wade-Tartakower Defense"}]}, 
	{id : "Kangaroo Defense", name : "Kangaroo Defense", children : [{id : 369, name : "Main Line"}]}, 
	{id : "King's Indian", name : "King's Indian", children : [{id : 370, name : "Averbakh"}, 
		{id : 371, name : "Fianchetto"}, 
		{id : 372, name : "Orthodox"}, 
		{id : 373, name : "Samisch"}]}, 
	{id : "King's Indian Defense", name : "King's Indian Defense", children : [{id : 374, name : "Accelerated Averbakh Variation"}, 
		{id : 375, name : "Averbakh Variation"}, 
		{id : 376, name : "Exchange Variation"}, 
		{id : 377, name : "Fianchetto Variation"}, 
		{id : 378, name : "Four Pawns Attack"}, 
		{id : 379, name : "Kazakh Variation"}, 
		{id : 380, name : "Kramer Variation"}, 
		{id : 381, name : "Larsen Variation"}, 
		{id : 383, name : "Makogonov Variation"}, 
		{id : 384, name : "Normal Variation"}, 
		{id : 385, name : "Orthodox Variation"}, 
		{id : 386, name : "Petrosian Variation"}, 
		{id : 387, name : "Samisch Variation"}, 
		{id : 388, name : "Semi-Averbakh System"}, 
		{id : 389, name : "Semi-Classical Variation"}, 
		{id : 390, name : "Smyslov Variation"}, 
		{id : 391, name : "Steiner Attack"}, 
		{id : 382, name : "Main Line"}]}, 
	{id : "London System", name : "London System", children : [{id : 392, name : "Main Line"}]}, 
	{id : "Mexican Defense", name : "Mexican Defense", children : [{id : 393, name : "Main Line"}]}, 
	{id : "Modern Defense", name : "Modern Defense", children : [{id : 394, name : "Averbakh System"}, 
		{id : 395, name : "Beefeater Variation"}, 
		{id : 396, name : "Kotov Variation"}, 
		{id : 398, name : "Randspringer Variation"}, 
		{id : 397, name : "Main Line"}]}, 
	{id : "Neo-Grunfeld Defense", name : "Neo-Grunfeld Defense", children : [{id : 399, name : "Classical Variation"}, 
		{id : 400, name : "Delayed Exchange Variation"}, 
		{id : 401, name : "Exchange Variation"}, 
		{id : 402, name : "Goglidze Attack"}, 
		{id : 404, name : "Non- or Delayed Fianchetto"}, 
		{id : 405, name : "Ultra-delayed Exchange Variation"}, 
		{id : 403, name : "Main Line"}]}, 
	{id : "Nimzo-Indian Defense", name : "Nimzo-Indian Defense (1.b3)", children : [{id : 406, name : "Classical"}, 
		{id : 407, name : "Classical Variation"}, 
		{id : 408, name : "Fischer Variation"}, 
		{id : 409, name : "Hübner Variation"}, 
		{id : 410, name : "Kmoch Variation"}, 
		{id : 411, name : "Leningrad Variation"}, 
		{id : 413, name : "Normal Variation"}, 
		{id : 414, name : "Ragozin Defense"}, 
		{id : 415, name : "Ragozin Variation"}, 
		{id : 416, name : "Reshevsky Variation"}, 
		{id : 417, name : "Romanishin Variation"}, 
		{id : 418, name : "Samisch"}, 
		{id : 419, name : "Samisch Variation"}, 
		{id : 420, name : "Simagin Variation"}, 
		{id : 421, name : "Spielmann Variation"}, 
		{id : 422, name : "St. Petersburg Variation"}, 
		{id : 423, name : "Three Knights Variation"}, 
		{id : 412, name : "Main Line"}]}, 
	{id : "Old Benoni Defense", name : "Old Benoni Defense", children : [{id : 424, name : "Main Line"}]}, 
	{id : "Old Indian", name : "Old Indian", children : [{id : 425, name : "Czech Variation"}]}, 
	{id : "Old Indian Defense", name : "Old Indian Defense", children : [{id : 426, name : "Dus-Khotimirsky Variation"}, 
		{id : 427, name : "Janowski Variation"}, 
		{id : 429, name : "Normal Variation"}, 
		{id : 430, name : "Tartakower-Indian"}, 
		{id : 431, name : "Two Knights Variation"}, 
		{id : 432, name : "Ukrainian Variation"}, 
		{id : 428, name : "Main Line"}]}, 
	{id : "Pterodactyl Defense", name : "Pterodactyl Defense", children : [{id : 433, name : "Main Line"}]}, 
	{id : "Queen's Gambit Accepted", name : "Queen's Gambit Accepted", children : [{id : 434, name : "Alekhine Defense"}, 
		{id : 435, name : "Bogoljubov Defense"}, 
		{id : 436, name : "Central Variation"}, 
		{id : 437, name : "Classical Defense"}, 
		{id : 438, name : "Godes Variation"}, 
		{id : 439, name : "Gunsberg Defense"}, 
		{id : 440, name : "Janowski-Larsen Variation"}, 
		{id : 441, name : "Linares Variation"}, 
		{id : 443, name : "Mannheim Variation"}, 
		{id : 444, name : "Normal Variation"}, 
		{id : 445, name : "Old Variation"}, 
		{id : 446, name : "Rosenthal Variation"}, 
		{id : 447, name : "Showalter Variation"}, 
		{id : 448, name : "Smyslov Variation"}, 
		{id : 449, name : "Winawer Defense"}, 
		{id : 442, name : "Main Line"}]}, 
	{id : "Queen's Gambit Declined", name : "Queen's Gambit Declined", children : [{id : 450, name : "Albin Countergambit"}, 
		{id : 451, name : "Anti-Tartakower Variation"}, 
		{id : 452, name : "Barmen Variation"}, 
		{id : 453, name : "Been-Koomen Variation"}, 
		{id : 454, name : "Cambridge Springs Defense"}, 
		{id : 455, name : "Capablanca"}, 
		{id : 456, name : "Capablanca Variation"}, 
		{id : 457, name : "Charousek (Petrosian) Variation"}, 
		{id : 458, name : "Exchange Variation"}, 
		{id : 459, name : "Harrwitz Attack"}, 
		{id : 460, name : "Hastings Variation"}, 
		{id : 461, name : "Janowski Variation"}, 
		{id : 462, name : "Lasker Defense"}, 
		{id : 464, name : "Manhattan Variation"}, 
		{id : 465, name : "Miles Variation"}, 
		{id : 466, name : "Modern"}, 
		{id : 467, name : "Modern Variation"}, 
		{id : 468, name : "Neo-Orthodox Variation"}, 
		{id : 469, name : "Normal Defense"}, 
		{id : 470, name : "Orthodox Defense"}, 
		{id : 471, name : "Pseudo-Tarrasch Variation"}, 
		{id : 472, name : "Queen's Knight Variation"}, 
		{id : 473, name : "Ragozin Defense"}, 
		{id : 474, name : "Semi-Slav"}, 
		{id : 475, name : "Semi-Tarrasch"}, 
		{id : 476, name : "Semi-Tarrasch Defense"}, 
		{id : 477, name : "Semmering Variation"}, 
		{id : 478, name : "Slav"}, 
		{id : 479, name : "Tarrasch Defense"}, 
		{id : 480, name : "Tartakower Defense"}, 
		{id : 481, name : "Tartakower Variation"}, 
		{id : 482, name : "Three Knights Variation"}, 
		{id : 483, name : "Traditional Variation"}, 
		{id : 484, name : "Vienna Variation"}, 
		{id : 463, name : "Main Line"}]}, 
	{id : "Queen's Gambit Refused", name : "Queen's Gambit Refused", children : [{id : 485, name : "Albin Countergambit"}, 
		{id : 486, name : "Austrian Defense"}, 
		{id : 487, name : "Baltic Defense"}, 
		{id : 488, name : "Chigorin Defense"}]}, 
	{id : "Queen's Indian", name : "Queen's Indian", children : [{id : 489, name : "Buerger Variation"}]}, 
	{id : "Queen's Indian Accelerated", name : "Queen's Indian Accelerated", children : [{id : 490, name : "Main Line"}]}, 
	{id : "Queen's Indian Defense", name : "Queen's Indian Defense", children : [{id : 491, name : "Anti-Queen's Indian System"}, 
		{id : 492, name : "Capablanca Variation"}, 
		{id : 493, name : "Classical Variation"}, 
		{id : 494, name : "Euwe Variation"}, 
		{id : 495, name : "Fianchetto Variation"}, 
		{id : 496, name : "Kasparov Variation"}, 
		{id : 497, name : "Kasparov-Petrosian Variation"}, 
		{id : 499, name : "Marienbad System"}, 
		{id : 500, name : "Miles Variation"}, 
		{id : 501, name : "Opocensky Variation"}, 
		{id : 502, name : "Petrosian Variation"}, 
		{id : 503, name : "Riumin Variation"}, 
		{id : 504, name : "Spassky System"}, 
		{id : 505, name : "Yates Variation"}, 
		{id : 498, name : "Main Line"}]}, 
	{id : "Queen's Pawn", name : "Queen's Pawn", children : [{id : 506, name : "Main Line"}]}, 
	{id : "Queen's Pawn Game", name : "Queen's Pawn Game", children : [{id : 507, name : "Barry Attack"}, 
		{id : 508, name : "Chigorin Variation"}, 
		{id : 509, name : "Colle System"}, 
		{id : 510, name : "Levitsky Attack"}, 
		{id : 511, name : "London System"}, 
		{id : 512, name : "Symmetrical Variation"}, 
		{id : 513, name : "Torre Attack"}, 
		{id : 514, name : "Veresov Attack"}, 
		{id : 515, name : "Zukertort Variation"}]}, 
	{id : "Rat Defense", name : "Rat Defense", children : [{id : 516, name : "English Rat"}]}, 
	{id : "Richter-Veresov Attack", name : "Richter-Veresov Attack", children : [{id : 517, name : "Main Line"}]}, 
	{id : "Robatsch Defense", name : "Robatsch Defense", children : [{id : 518, name : "Main Line"}]}, 
	{id : "Rubinstein Opening", name : "Rubinstein Opening", children : [{id : 519, name : "Main Line"}]}, 
	{id : "Semi-Benoni", name : "Semi-Benoni", children : [{id : 520, name : "Main Line"}]}, 
	{id : "Semi-Slav Defense", name : "Semi-Slav Defense", children : [{id : 521, name : "Accelerated Meran Variation"}, 
		{id : 522, name : "Accelerated Move Order"}, 
		{id : 523, name : "Anti-Moscow Gambit"}, 
		{id : 524, name : "Bogoljubov Variation"}, 
		{id : 525, name : "Botvinnik System"}, 
		{id : 526, name : "Botvinnik Variation"}, 
		{id : 527, name : "Chigorin Defense"}, 
		{id : 528, name : "Main Line"}, 
		{id : 529, name : "Marshall Gambit"}, 
		{id : 530, name : "Meran Variation"}, 
		{id : 531, name : "Normal Variation"}, 
		{id : 532, name : "Romih Variation"}, 
		{id : 533, name : "Rubinstein (Anti-Meran) System"}, 
		{id : 534, name : "Stoltz Variation"}]}, 
	{id : "Semi-Slav Defense Accepted", name : "Semi-Slav Defense Accepted", children : [{id : 535, name : "Main Line"}]}, 
	{id : "Slav Defense", name : "Slav Defense", children : [{id : 536, name : "Alapin Variation"}, 
		{id : 537, name : "Alekhine Variation"}, 
		{id : 538, name : "Breyer Variation"}, 
		{id : 539, name : "Chameleon Variation"}, 
		{id : 540, name : "Czech Variation"}, 
		{id : 541, name : "Exchange Variation"}, 
		{id : 542, name : "Geller Gambit"}, 
		{id : 544, name : "Modern"}, 
		{id : 545, name : "Modern Line"}, 
		{id : 546, name : "Quiet Variation"}, 
		{id : 552, name : "Süchting Variation"}, 
		{id : 547, name : "Schlechter Variation"}, 
		{id : 548, name : "Slav Gambit"}, 
		{id : 549, name : "Smyslov Variation"}, 
		{id : 550, name : "Soultanbeieff Variation"}, 
		{id : 551, name : "Steiner Variation"}, 
		{id : 553, name : "Two Knights Attack"}, 
		{id : 554, name : "Winawer Countergambit"}, 
		{id : 543, name : "Main Line"}]}, 
	{id : "Tarrasch Defense", name : "Tarrasch Defense", children : [{id : 555, name : "Classical Variation"}, 
		{id : 557, name : "Prague Variation"}, 
		{id : 558, name : "Rubinstein System"}, 
		{id : 559, name : "Swedish Variation"}, 
		{id : 560, name : "Symmetrical Variation"}, 
		{id : 561, name : "Tarrasch Gambit"}, 
		{id : 562, name : "Two Knights Variation"}, 
		{id : 563, name : "Wagner Variation"}, 
		{id : 556, name : "Main Line"}]}, 
	{id : "Torre Attack", name : "Torre Attack", children : [{id : 564, name : "Classical Defense"}, 
		{id : 565, name : "Main Line"}]}, 
	{id : "Trompowsky Attack", name : "Trompowsky Attack", children : [{id : 566, name : "Classical Defense"}, 
		{id : 567, name : "Edge Variation"}, 
		{id : 569, name : "Poisoned Pawn Variation"}, 
		{id : 570, name : "Raptor Variation"}, 
		{id : 568, name : "Main Line"}]}, 
	{id : "Wade Defense", name : "Wade Defense", children : [{id : 571, name : "Main Line"}]}, 
	{id : "Yusupov-Rubinstein System", name : "Yusupov-Rubinstein System", children : [{id : 572, name : "Main Line"}]}]}, 
{id : "1c4", name : "English Openings (1.c4)", children : [{id : "English Opening", name : "English Opening", children : [{id : 0, name : "Agincourt Defense"}, 
		{id : 1, name : "Anglo-Dutch Defense"}, 
		{id : 2, name : "Anglo-Grunfeld Defense"}, 
		{id : 3, name : "Anglo-Indian Defense"}, 
		{id : 5, name : "Carls-Bremen System"}, 
		{id : 6, name : "Caro-Kann Defensive System"}, 
		{id : 7, name : "Closed"}, 
		{id : 8, name : "Four Knights System"}, 
		{id : 9, name : "King's English Variation"}, 
		{id : 11, name : "Mikenas-Carls Variation"}, 
		{id : 12, name : "Neo-Catalan"}, 
		{id : 13, name : "Romanishin Gambit"}, 
		{id : 14, name : "Symmetrical"}, 
		{id : 15, name : "Symmetrical Variation"}, 
		{id : 10, name : "Main Line"}, 
		{id : 4, name : "Anglo-Slav Variation"}]}]}, 
{id : "1nf3", name : "Zuckertort Openings (1.Nf3)", children : [{id : "King's Indian Attack", name : "King's Indian Attack", children : [{id : 573, name : "French Variation"}, 
		{id : 574, name : "Keres Variation"}, 
		{id : 576, name : "Pachman System"}, 
		{id : 577, name : "Sicilian Variation"}, 
		{id : 578, name : "Smyslov Variation"}, 
		{id : 579, name : "Symmetrical Defense"}, 
		{id : 580, name : "Yugoslav Variation"}, 
		{id : 575, name : "Main Line"}]}, 
	{id : "Nimzo-Larsen Attack", name : "Nimzo-Larsen Attack (1.b3)", children : [{id : 581, name : "Classical Variation"}]}, 
	{id : "Reti Opening", name : "Reti Opening", children : [{id : 582, name : "Advance Variation"}, 
		{id : 584, name : "Reti Gambit Accepted"}, 
		{id : 583, name : "Main Line"}]}, 
	{id : "Zukertort Opening", name : "Zukertort Opening", children : [{id : 585, name : "Lisitsyn Gambit"}, 
		{id : 586, name : "Sicilian Invitation"}]}]}, 
{id : "other", name : "Other Openings", children : [{id : "Bird Opening", name : "Bird's Opening (1.f4)", children : [{id : 274, name : "Dutch Variation"}]}, 
	{id : "Polish Opening", name : "Polish Opening (1.b4)", children : [{id : 275, name : "Main Line"}]}]}]
export { tree_data };
